import React from 'react';

const DashboardTab = () => {
    return (
        <div className="tab-pane fade show active" role="tabpanel">
            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h6>Overall Total Transactions:</h6>
                            <h4>KES 0.00</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h6>Current Month Transactions:</h6>
                            <h4>KES 0.00</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h6>Installed Meters:</h6>
                            <div>
                                On Meters: 3
                                <br />
                                Off Meters: 0
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-4">
                <div className="card-body">
                    <h6 className="mb-3">PostPaid Maximum Amount Limit ( KES: 10,000 )</h6>
                    <p className="text-muted small">All customers that have exceeded the maximum amount limit.</p>
                    <div className="text-center py-3">No data found.</div>
                </div>
            </div>

            <div className="card mb-4">
                <div className="card-body">
                    <h6 className="mb-3">Prepaid Minimum Amount Limit ( KES: 0 )</h6>
                    <p className="text-muted small">All customers that have exceeded the minimum amount limit.</p>
                    <div className="text-center py-3">No data found.</div>
                </div>
            </div>
        </div>
    );
};

export default DashboardTab;