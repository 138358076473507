import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../component/layout';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { get_leases } from '../../../../utils/urls';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { jsPDF } from "jspdf";

const ViewLease = () => {
    const { leaseId } = useParams();
    const [lease, setLease] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchLeaseDetails = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            const response = await makeRequest2(`${get_leases}/${facilityId}`, "GET");
            
            if (response?.success && Array.isArray(response.data.leaseAgreements)) {
                const leaseDetails = response.data.leaseAgreements.find(
                    (lease) => lease._id === leaseId
                );
                
                if (leaseDetails) {
                    setLease(leaseDetails);
                } else {
                    toastify("Lease not found", "error");
                }
            } else {
                throw new Error("Failed to fetch lease details");
            }
        } catch (error) {
            toastify(error.message, "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeaseDetails();
    }, [leaseId]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const content = document.getElementById('lease-content');
        doc.html(content, {
            callback: function(pdf) {
                pdf.save('lease-agreement.pdf');
            },
            x: 15,
            y: 15,
            width: 170,
            windowWidth: 650
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!lease) {
        return <div>Lease not found</div>;
    }

    return (
        <Layout>
            <div className="container mt-4 mb-4">
                <Card>
                    <div className="d-flex justify-content-end mb-3">
                        <Button 
                            icon="pi pi-download" 
                            label="Download PDF" 
                            onClick={downloadPDF}
                            className="p-button-primary"
                        />
                    </div>
                    
                    <div id="lease-content" className="p-4">
                        <h2 className="text-center mb-4">LEASE AGREEMENT</h2>
                        
                        <p>This Lease Agreement ("Agreement") is made and entered into on {formatDate(lease.createdAt)}, by and between:</p>

                        <div className="row mb-4">
                            <div className="col-md-6">
                                <h4>LANDLORD:</h4>
                                <ul className="list-unstyled">
                                    <li>Name: {lease.landlordInfo?.fullName || 'N/A'}</li>
                                    <li>Identification/Registration No.: {lease.landlordInfo?.idNumber || 'N/A'}</li>
                                    <li>Address: {lease.landlordInfo?.address || 'N/A'}</li>
                                    <li>Contact Information:</li>
                                    <li>Phone: {lease.landlordInfo?.phone || 'N/A'}</li>
                                    <li>Email: {lease.landlordInfo?.email || 'N/A'}</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h4>TENANT:</h4>
                                <ul className="list-unstyled">
                                    <li>Name: {lease.tenantInfo?.fullName || 'N/A'}</li>
                                    <li>Identification/Registration No.: {lease.tenantInfo?.idNumber || 'N/A'}</li>
                                    <li>Address: {lease.tenantInfo?.address || 'N/A'}</li>
                                    <li>Contact Information:</li>
                                    <li>Phone: {lease.tenantInfo?.phone || 'N/A'}</li>
                                    <li>Email: {lease.tenantInfo?.email || 'N/A'}</li>
                                </ul>
                            </div>
                        </div>

                        <section className="mb-4">
                            <h4>1. PREMISES</h4>
                            <p>The Landlord hereby leases to the Tenant the following property ("Premises") in accordance with the laws of the Republic of Kenya:</p>
                            <ul className="list-unstyled">
                                <li>Type of Property: {lease.propertyType || 'N/A'}</li>
                                <li>Unit/Apartment Number: {lease.unitInfo?.unitName || 'N/A'}</li>
                                <li>Address: {lease.propertyAddress || 'N/A'}</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h4>2. TERM</h4>
                            <ul className="list-unstyled">
                                <li>Commencement Date: {formatDate(lease.leaseTerms?.startDate)}</li>
                                <li>Expiration Date: {formatDate(lease.leaseTerms?.endDate)}</li>
                                <li>Duration: {lease.leaseTerms?.duration} months</li>
                            </ul>
                            <p>Unless terminated earlier in accordance with this Agreement, the Lease shall {lease.leaseTerms?.autoRenewal ? 'automatically renew under the same terms and conditions' : 'not automatically renew'}.</p>
                        </section>

                        <section className="mb-4">
                            <h4>3. RENT</h4>
                            <ul className="list-unstyled">
                                <li>Monthly Rent Amount: Kenya Shillings (KES) {lease.financialTerms?.monthlyRent}</li>
                                <li>Payment Due Date: {lease.financialTerms?.paymentDueDate}</li>
                                <li>Payment Method: {lease.financialTerms?.paymentMethod}</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h4>4. LEASE ESCALATION</h4>
                            <p>Annual Rent Increase: The monthly rent shall be subject to an annual escalation as per the agreement terms.</p>
                            <p>Notice of Increase: The Landlord shall provide the Tenant with at least 30 days' written notice prior to implementing the rent increase.</p>
                        </section>

                        <section className="mb-4">
                            <h4>5. SERVICE CHARGE</h4>
                            <p>Monthly Service Charge: As per the current facility rates</p>
                            <p>Purpose: Covers costs of shared services and amenities, including but not limited to security, cleaning of common areas, garbage collection, and maintenance of shared facilities.</p>
                            <p>Payment: The service charge is payable together with the monthly rent.</p>
                        </section>

                        <section className="mb-4">
                            <h4>6. SECURITY DEPOSIT</h4>
                            <ul className="list-unstyled">
                                <li>Amount: KES {lease.financialTerms?.securityDeposit}</li>
                                <li>Payment: Payable upon signing this Agreement.</li>
                                <li>Purpose: Held as security for the performance of the Tenant's obligations.</li>
                                <li>Refund: The deposit (less any lawful deductions) shall be refunded to the Tenant within 30 days after the termination of this Lease.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h4>7. MAINTENANCE AND REPAIRS</h4>
                            <h5>7.1. Tenant's Responsibilities:</h5>
                            <ul>
                                <li>Keep the Premises clean, sanitary, and in good condition.</li>
                                <li>Responsible for minor repairs and maintenance.</li>
                                <li>Promptly notify the Landlord in writing of any damages or necessary repairs.</li>
                            </ul>

                            <h5>7.2. Landlord's Responsibilities:</h5>
                            <ul>
                                <li>Maintain the structural integrity of the Premises.</li>
                                <li>Ensure all essential services are in working order.</li>
                                <li>Responsible for major repairs unless caused by the Tenant's negligence.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h4>8. UTILITIES</h4>
                            <p>The Tenant shall be responsible for the payment of utilities consumed on the Premises as specified in the agreement.</p>
                        </section>

                        <section className="mb-4">
                            <h4>9. BILLING CYCLE</h4>
                            <ul className="list-unstyled">
                                <li>Frequency: {lease.billingCycle?.frequency}</li>
                                <li>Next Invoice Date: {formatDate(lease.billingCycle?.nextInvoiceDate)}</li>
                                <li>Auto Send: {lease.billingCycle?.autoSend ? 'Yes' : 'No'}</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h4>10. USE OF PREMISES</h4>
                            <p>The Premises shall be used solely for the purpose specified in the agreement and in compliance with all applicable laws and regulations.</p>
                        </section>

                        <section className="mb-4">
                            <h4>11. TERMINATION</h4>
                            <p>Either Party may terminate this Lease by providing written notice as per the agreement terms. Current Status: {lease.status}</p>
                        </section>

                        <div className="row mt-5">
                            <div className="col-md-6">
                                <h4>LANDLORD:</h4>
                                <p>Signature: _____________________________</p>
                                <p>Name: {lease.landlordInfo?.fullName || 'N/A'}</p>
                                <p>Date: {formatDate(lease.createdAt)}</p>
                            </div>
                            <div className="col-md-6">
                                <h4>TENANT:</h4>
                                <p>Signature: _____________________________</p>
                                <p>Name: {lease.tenantInfo?.fullName || 'N/A'}</p>
                                <p>Date: {formatDate(lease.createdAt)}</p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default ViewLease;