import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";

import { useFacilityStore } from "../../../../app/z-store/store";
import InvoiceManagement from "./invoice_management/InvoiceManagement";
function RentInvoicing() {
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  return (
    <Layout>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/facility/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">Levy Management</li>
                        <li className="breadcrumb-item">Levy Invoices</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h5>Levy Invoices</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="tab-content">
                        <div className="row">

                          <div className="col-lg-12 col-xxl-12">
                            <InvoiceManagement
                              facilityId={selectedFacilityId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RentInvoicing;
