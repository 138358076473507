import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { 
  get_lease, 
  get_lease_penalties, 
  get_lease_reminders,
  GET_CURRENCIES_URL
} from '../../../../../utils/urls';
import { toastify } from '../../../../../utils/toast';
import { getItem } from '../../../../../utils/localStorage';

const PopulatedLeaseTemplate = ({ leaseId, visible, onHide }) => {
  const [loading, setLoading] = useState(true);
  const [populatedContent, setPopulatedContent] = useState('');

  const fetchAndPopulateTemplate = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      
      if (!facilityId) {
        throw new Error('Facility ID is required');
      }
  
      // Fetch lease data with all related information
      const response = await makeRequest2(
        `${get_lease}/${facilityId}/${leaseId}`,
        'GET'
      );
  
      if (!response.success) {
        throw new Error(response.error || 'Failed to fetch lease data');
      }
  
      // Extract lease data
      const lease = response.data.data;
      console.log('Lease data:', lease); // Debug log
      
      if (!lease || !lease.leaseTemplate?.templateContent) {
        throw new Error('No template content found');
      }
  
      let content = lease.leaseTemplate.templateContent;
      
      // Fetch currency details (using the same endpoint as in AddLeaseAgreement)
      let currencyDetails = null;
      try {
        const currencyResponse = await makeRequest2(
          `${GET_CURRENCIES_URL}/${facilityId}`,
          'GET'
        );
        
        console.log('Currency response:', currencyResponse); // Debug log
        
        if (currencyResponse.success) {
          const currencyData = currencyResponse.data?.data || currencyResponse.data;
          const currenciesArray = Array.isArray(currencyData) ? currencyData : [currencyData];
          
          // Find the specific currency used in this lease
          if (lease.currency) {
            const currencyId = typeof lease.currency === 'object' ? 
              lease.currency._id : lease.currency;
              
            currencyDetails = currenciesArray.find(
              curr => curr._id.toString() === currencyId.toString()
            );
          }
          
          console.log('Found currency details:', currencyDetails); // Debug log
        }
      } catch (error) {
        console.error('Error fetching currency:', error);
      }
  
      // Fetch penalty details (using the same endpoint as in AddLeaseAgreement)
      let penaltyDetails = null;
      if (lease.financialTerms?.penaltyId) {
        try {
          const penaltiesResponse = await makeRequest2(
            `${get_lease_penalties}/${facilityId}`,
            'GET'
          );
          
          console.log('Penalties response:', penaltiesResponse); // Debug log
          
          if (penaltiesResponse.success) {
            const penaltiesData = penaltiesResponse.data?.data || penaltiesResponse.data;
            
            if (Array.isArray(penaltiesData)) {
              const penaltyId = typeof lease.financialTerms.penaltyId === 'object' ?
                lease.financialTerms.penaltyId._id.toString() :
                lease.financialTerms.penaltyId.toString();
              
              penaltyDetails = penaltiesData.find(
                penalty => penalty._id.toString() === penaltyId
              );
              
              console.log('Found penalty details:', penaltyDetails); // Debug log
            }
          }
        } catch (error) {
          console.error('Error fetching penalties:', error);
        }
      }

      // Fetch reminder details (using the same endpoint as in AddLeaseAgreement)
      let reminderDetails = null;
      if (lease.reminders && lease.reminders.length > 0) {
        try {
          const reminderResponse = await makeRequest2(
            `${get_lease_reminders}/${facilityId}`,
            'GET'
          );
          
          console.log('Reminders response:', reminderResponse); // Debug log
          
          if (reminderResponse.success) {
            const remindersData = reminderResponse.data?.data || reminderResponse.data;
            
            if (Array.isArray(remindersData)) {
              const reminderId = typeof lease.reminders[0].reminderId === 'object' ?
                lease.reminders[0].reminderId._id.toString() :
                lease.reminders[0].reminderId.toString();
              
              reminderDetails = remindersData.find(
                reminder => reminder._id.toString() === reminderId
              );
              
              console.log('Found reminder details:', reminderDetails); // Debug log
            }
          }
        } catch (error) {
          console.error('Error fetching reminders:', error);
        }
      }

      // Format date strings properly
      const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        try {
          return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric', 
            month: 'long', 
            day: 'numeric'
          });
        } catch (e) {
          return 'N/A';
        }
      };

      // Set up currency values based on found data
      let currencyName = 'N/A';
      let currencyShortCode = 'N/A';

      if (currencyDetails) {
        // Use currency from lookup
        currencyName = currencyDetails.currencyName || 'N/A';
        currencyShortCode = currencyDetails.currencyShortCode || 'N/A';
      } else if (lease.currency) {
        // Try direct currency object
        if (typeof lease.currency === 'object') {
          currencyName = lease.currency.currencyName || 'N/A';
          currencyShortCode = lease.currency.currencyShortCode || 'N/A';
        }
      } else if (lease.currencyInfo) {
        // Try currency info object
        currencyName = lease.currencyInfo.currencyName || 'N/A';
        currencyShortCode = lease.currencyInfo.currencyShortCode || 'N/A';
      }
      
      // Prepare the template data - only including fields from AddLeaseAgreement
      const templateData = {
        tenant: {
          firstName: lease.tenant?.firstName || 'N/A',
          lastName: lease.tenant?.lastName || 'N/A',
          phoneNumber: lease.tenant?.phoneNumber || 'N/A',
          email: lease.tenant?.email || 'N/A',
          idNumber: lease.tenant?.idNumber || 'N/A'
        },
        landlord: {
          firstName: lease.landlord?.firstName || 'N/A',
          lastName: lease.landlord?.lastName || 'N/A',
          phoneNumber: lease.landlord?.phoneNumber || 'N/A',
          email: lease.landlord?.email || 'N/A',
          idNumber: lease.landlord?.idNumber || 'N/A'
        },
        unit: {
          name: lease.unitNumber?.name || 'N/A',
          floorUnitNo: lease.unitNumber?.floorUnitNo || 'N/A'
        },
        currency: {
          currencyName: currencyName,
          currencyShortCode: currencyShortCode
        },
        lease: {
          status: lease.status || 'N/A',
          leaseTerms: {
            startDate: formatDate(lease.leaseTerms?.startDate),
            endDate: formatDate(lease.leaseTerms?.endDate),
            duration: lease.leaseTerms?.duration || 'N/A'
          },
          financialTerms: {
            monthlyRent: lease.financialTerms?.monthlyRent?.toLocaleString() || 'N/A',
            securityDeposit: lease.financialTerms?.securityDeposit?.toLocaleString() || 'N/A',
            paymentDueDate: lease.financialTerms?.paymentDueDate || 'N/A',
            paymentMethods: lease.financialTerms?.paymentMethods?.map((method, index) => ({
              type: method.type || 'N/A',
              details: method.details || {},
              isPrimary: method.isPrimary || false
            })) || [],
            mpesaEnabled: lease.financialTerms?.mpesaEnabled ? 'Yes' : 'No',
            mpesaDetails: lease.financialTerms?.mpesaDetails ? {
              businessNumber: lease.financialTerms.mpesaDetails.businessNumber || 'N/A',
              accountNumber: lease.financialTerms.mpesaDetails.accountNumber || 'N/A',
              phoneNumber: lease.financialTerms.mpesaDetails.phoneNumber || 'N/A'
            } : null
          },
          billingCycle: {
            frequency: lease.billingCycle?.frequency || 'N/A',
            nextInvoiceDate: formatDate(lease.billingCycle?.nextInvoiceDate)
          }
        }
      };
      
      // Add penalty data from the fetched penalty details
      if (penaltyDetails) {
        templateData.penalty = {
          name: penaltyDetails.name || 'N/A',
          type: penaltyDetails.type || 'N/A',
          amount: penaltyDetails.type === 'fixed' ? penaltyDetails.amount?.toLocaleString() : 'N/A',
          percentage: penaltyDetails.type === 'percentage' ? penaltyDetails.percentage : 'N/A'
        };
      } else {
        // Default values if no penalty found
        templateData.penalty = {
          name: 'N/A',
          type: 'N/A',
          amount: 'N/A',
          percentage: 'N/A'
        };
      }
      
      // Add reminder data from the fetched reminder details
      if (reminderDetails) {
        templateData.reminder = {
          name: reminderDetails.name || 'N/A',
          notificationTypes: Array.isArray(reminderDetails.notificationTypes) ? 
            reminderDetails.notificationTypes.join(', ') : 'N/A'
        };
      } else {
        // Default values if no reminder found
        templateData.reminder = {
          name: 'N/A',
          notificationTypes: 'N/A'
        };
      }
      
      console.log('Template data prepared for replacement:', templateData); // Debug log
      
      // Now perform all the replacements using precise regex patterns
      
      // 1. Currency replacements
      content = content.replace(/\{currency\.currencyName\}/g, templateData.currency.currencyName);
      content = content.replace(/\{currency\.currencyShortCode\}/g, templateData.currency.currencyShortCode);
      
      // Handle combined currency pattern
      content = content.replace(
        /\{currency\.currencyName\} \(\{currency\.currencyShortCode\}\)/g, 
        `${templateData.currency.currencyName} (${templateData.currency.currencyShortCode})`
      );
      
      // 2. Monetary values with currency
      content = content.replace(
        /\{currency\.currencyShortCode\} \{lease\.financialTerms\.monthlyRent\}/g,
        `${templateData.currency.currencyShortCode} ${templateData.lease.financialTerms.monthlyRent}`
      );
      
      content = content.replace(
        /\{currency\.currencyShortCode\} \{lease\.financialTerms\.securityDeposit\}/g,
        `${templateData.currency.currencyShortCode} ${templateData.lease.financialTerms.securityDeposit}`
      );
      
      // 3. Tenant fields
      Object.entries(templateData.tenant).forEach(([key, value]) => {
        content = content.replace(new RegExp(`\\{tenant\\.${key}\\}`, 'g'), value);
      });
      
      // 4. Landlord fields
      Object.entries(templateData.landlord).forEach(([key, value]) => {
        content = content.replace(new RegExp(`\\{landlord\\.${key}\\}`, 'g'), value);
      });
      
      // 5. Unit fields
      Object.entries(templateData.unit).forEach(([key, value]) => {
        content = content.replace(new RegExp(`\\{unit\\.${key}\\}`, 'g'), value);
      });
      
      // 6. Lease status
      content = content.replace(/\{lease\.status\}/g, templateData.lease.status);
      
      // 7. Lease terms
      Object.entries(templateData.lease.leaseTerms).forEach(([key, value]) => {
        content = content.replace(new RegExp(`\\{lease\\.leaseTerms\\.${key}\\}`, 'g'), value);
      });
      
      // 8. Financial terms
      content = content.replace(/\{lease\.financialTerms\.monthlyRent\}/g, templateData.lease.financialTerms.monthlyRent);
      content = content.replace(/\{lease\.financialTerms\.securityDeposit\}/g, templateData.lease.financialTerms.securityDeposit);
      content = content.replace(/\{lease\.financialTerms\.paymentDueDate\}/g, templateData.lease.financialTerms.paymentDueDate);
      content = content.replace(/\{lease\.financialTerms\.mpesaEnabled\}/g, templateData.lease.financialTerms.mpesaEnabled);
      
      // 9. M-Pesa details
      if (templateData.lease.financialTerms.mpesaDetails) {
        Object.entries(templateData.lease.financialTerms.mpesaDetails).forEach(([key, value]) => {
          content = content.replace(new RegExp(`\\{lease\\.financialTerms\\.mpesaDetails\\.${key}\\}`, 'g'), value);
        });
      }
      
      // 10. Billing cycle
      Object.entries(templateData.lease.billingCycle).forEach(([key, value]) => {
        content = content.replace(new RegExp(`\\{lease\\.billingCycle\\.${key}\\}`, 'g'), value);
      });
      
      // 11. Penalty fields - Direct replacement
      if (templateData.penalty) {
        content = content.replace(/\{penalty\.name\}/g, templateData.penalty.name);
        content = content.replace(/\{penalty\.type\}/g, templateData.penalty.type);
        content = content.replace(/\{penalty\.amount\}/g, templateData.penalty.amount);
        content = content.replace(/\{penalty\.percentage\}/g, templateData.penalty.percentage);
      }
      
      // 12. Reminder fields - Direct replacement
      if (templateData.reminder) {
        content = content.replace(/\{reminder\.name\}/g, templateData.reminder.name);
        content = content.replace(/\{reminder\.notificationTypes\}/g, templateData.reminder.notificationTypes);
      }
      
      // 13. Payment methods - More complex handling
      if (templateData.lease.financialTerms.paymentMethods.length > 0) {
        // Find primary payment method or use first one
        const primaryMethod = templateData.lease.financialTerms.paymentMethods.find(m => m.isPrimary) || 
                             templateData.lease.financialTerms.paymentMethods[0];
        
        // Handle basic fields                    
        content = content.replace(/\{lease\.financialTerms\.paymentMethods\[0\]\.type\}/g, primaryMethod.type);
        content = content.replace(/\{lease\.financialTerms\.paymentMethods\[0\]\.isPrimary\}/g, primaryMethod.isPrimary ? 'Yes' : 'No');
        
        // Handle method-specific details
        if (primaryMethod.details) {
          // Handle all possible detail fields
          const detailFields = ['bankName', 'accountName', 'accountNumber', 'branch', 'swiftCode', 
                               'preferredCashLocation', 'bankToDraft', 'chequeAccountNumber'];
          
          detailFields.forEach(field => {
            const pattern = new RegExp(`\\{lease\\.financialTerms\\.paymentMethods\\[0\\]\\.details\\.${field}\\}`, 'g');
            const value = primaryMethod.details[field] || 'N/A';
            content = content.replace(pattern, value);
          });
        }
        
        // Create consolidated payment methods display for wildcard
        const paymentMethodsText = templateData.lease.financialTerms.paymentMethods.map((method, index) => {
          let text = `Payment Method ${index + 1}:\n`;
          text += `Type: ${method.type}\n`;
          text += `Primary: ${method.isPrimary ? 'Yes' : 'No'}\n`;
          
          if (method.details) {
            if (method.type === 'Bank Transfer') {
              text += `Bank Name: ${method.details.bankName || 'N/A'}\n`;
              text += `Account Name: ${method.details.accountName || 'N/A'}\n`;
              text += `Account Number: ${method.details.accountNumber || 'N/A'}\n`;
              text += `Branch: ${method.details.branch || 'N/A'}\n`;
              text += `Swift Code: ${method.details.swiftCode || 'N/A'}\n`;
            } else if (method.type === 'Cash') {
              text += `Preferred Location: ${method.details.preferredCashLocation || 'N/A'}\n`;
            } else if (method.type === 'Cheque') {
              text += `Bank to Draft: ${method.details.bankToDraft || 'N/A'}\n`;
              text += `Cheque Account Number: ${method.details.chequeAccountNumber || 'N/A'}\n`;
            } else if (method.type === 'Mobile Money') {
              text += `Provider: ${method.details.provider || 'N/A'}\n`;
              text += `Phone Number: ${method.details.phoneNumber || 'N/A'}\n`;
              text += `Account Name: ${method.details.accountName || 'N/A'}\n`;
            }
          }
          
          return text;
        }).join('\n\n');
        
        // Replace the wildcard placeholder
        content = content.replace(/\{lease\.financialTerms\.paymentMethods\[\*\]\.type\}/g, paymentMethodsText);
      }
      
      // 14. Handle conditional expressions
      content = content.replace(
        /\{lease\.leaseTerms\.autoRenewal \? 'Yes' : 'No'\}/g, 
        templateData.lease.leaseTerms.autoRenewal ? 'Yes' : 'No'
      );
      
      content = content.replace(
        /\{lease\.billingCycle\.autoSend \? 'Yes' : 'No'\}/g, 
        templateData.lease.billingCycle.autoSend ? 'Yes' : 'No'
      );
      
      content = content.replace(
        /\{penalty\.type === 'fixed' \? penalty\.amount : penalty\.percentage \+ '%'\}/g,
        templateData.penalty.type === 'fixed' ? 
          templateData.penalty.amount : 
          `${templateData.penalty.percentage}%`
      );
      
      content = content.replace(
        /\{reminder\.notificationTypes\.join\(', '\)}/g,
        templateData.reminder.notificationTypes
      );

      setPopulatedContent(content);
      setLoading(false);
    } catch (error) {
      console.error('Error in fetchAndPopulateTemplate:', error);
      toastify(error.message || 'Error loading template', 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && leaseId) {
      fetchAndPopulateTemplate();
    } else {
      setPopulatedContent('');
      setLoading(true);
    }
  }, [visible, leaseId]);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    
    if (!printWindow) {
      toastify('Please allow pop-ups to print this document', 'error');
      return;
    }
    
    // Create print-friendly document
    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Lease Agreement</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              margin: 2cm;
              font-size: 12pt;
            }
            .header {
              text-align: center;
              margin-bottom: 2em;
            }
            .content {
              margin-bottom: 1em;
            }
            h1, h2, h3 {
              margin-top: 1.5em;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 1em 0;
            }
            td, th {
              border: 1px solid #ddd;
              padding: 8px;
            }
          </style>
        </head>
        <body>
          <div class="content">${populatedContent}</div>
        </body>
      </html>
    `);
    
    printWindow.document.close();
    printWindow.focus();
    
    // Slight delay to ensure content is loaded
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  return (
    <Dialog
      header="Lease Agreement Document"
      visible={visible}
      style={{ width: '80vw', maxHeight: '90vh' }}
      onHide={onHide}
      className="populated-lease-template"
      maximizable
    >
      <div className="p-4">
        {loading ? (
          <div className="text-center py-4">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
            <div className="mt-2">Loading template...</div>
          </div>
        ) : (
          <div>
            <div className="mb-4 text-end">
              <Button
                icon="pi pi-download"
                label="Download PDF"
                className="p-button-secondary mr-2"
                onClick={handlePrint}
              />
              <Button
                icon="pi pi-print"
                label="Print"
                className="p-button-primary mr-2"
                onClick={handlePrint}
              />
              <Button
                icon="pi pi-times"
                label="Close"
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="template-content bg-white p-4 border rounded">
              <ReactQuill
                value={populatedContent}
                readOnly
                theme="bubble"
                modules={{ toolbar: false }}
              />
            </div>
          </div>
        )}
      </div>
      
      <style>{`
        .populated-lease-template .p-dialog-content {
          overflow-y: auto;
        }

        .template-content {
          min-height: 50vh;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        }

        .ql-container {
          font-size: 14px;
          line-height: 1.6;
        }

        .ql-editor {
          padding: 20px;
        }

        @media print {
          .p-dialog-header,
          .mb-4.text-end {
            display: none !important;
          }
          
          .p-dialog {
            box-shadow: none !important;
          }
          
          .template-content {
            padding: 0 !important;
            border: none !important;
            box-shadow: none !important;
          }

          .ql-editor {
            padding: 0 !important;
          }

          .ql-container {
            font-size: 12pt;
          }
        }

        @page {
          margin: 2cm;
        }
      `}</style>
    </Dialog>
  );
};

export default PopulatedLeaseTemplate;