"use client"

import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { makeRequest } from "../../../../../../utils/makeRequest"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import styles from "./invoice.module.css"

const UniversalInvoicePage = () => {
  // State management
  const [invoice, setInvoice] = useState(null)
  const [loading, setLoading] = useState(true)
  const [companyDetails, setCompanyDetails] = useState(null)
  const [facilityLogo, setFacilityLogo] = useState("")
  const [error, setError] = useState(null)

  // Zoom functionality
  const [zoomLevel, setZoomLevel] = useState(1) // Initial zoom level is 1 (100%)
  const invoiceViewerRef = useRef(null)

  // Min and max zoom levels
  const MIN_ZOOM = 0.5 // 50%
  const MAX_ZOOM = 2 // 200%
  const ZOOM_STEP = 0.1 // 10% increments

  // Get URL parameters
  const { facilityId, invoiceId, type } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    fetchInvoiceData()
    fetchCompanyDetails()
  }, [invoiceId, facilityId, type])

  // Handle zoom in
  const handleZoomIn = () => {
    if (zoomLevel < MAX_ZOOM) {
      setZoomLevel((prevZoom) => Math.min(prevZoom + ZOOM_STEP, MAX_ZOOM))
    }
  }

  // Handle zoom out
  const handleZoomOut = () => {
    if (zoomLevel > MIN_ZOOM) {
      setZoomLevel((prevZoom) => Math.max(prevZoom - ZOOM_STEP, MIN_ZOOM))
    }
  }

  // Center the invoice after zoom change
  useEffect(() => {
    if (invoiceViewerRef.current) {
      // Reset the scroll position to the top center after zooming
      const container = invoiceViewerRef.current
      const scrollWidth = container.scrollWidth
      const clientWidth = container.clientWidth

      if (scrollWidth > clientWidth) {
        // Center horizontally
        container.scrollLeft = (scrollWidth - clientWidth) / 2
      }
    }
  }, [zoomLevel])

  // Calculate zoom percentage for display
  const zoomPercentage = Math.round(zoomLevel * 100)

  const fetchCompanyDetails = async () => {
    try {
      const response = await makeRequest(`/api/public/company_information/${facilityId}`, "GET")
      if (response.success) {
        console.log("Company details:", response)
        setCompanyDetails(response.data.data)

        // Try to set facility logo if available
        if (response.data.logo) {
          const fullLogoUrl = `${process.env.APP_BASE_URL}/${response.data.logo}`
          setFacilityLogo(fullLogoUrl)
        }
      } else {
        console.error("Failed to fetch company details:", response.message)
      }
    } catch (error) {
      console.error("Error fetching company details:", error)
    }
  }

  const fetchInvoiceData = async () => {
    try {
      setLoading(true)

      // Use the type parameter if available
      const typeParam = type ? `/${type}` : ""

      console.log(`Fetching invoice with params:`, {
        facilityId,
        invoiceId,
        type: typeParam || "none",
      })

      // Make the API request to get invoice data
      const response = await makeRequest(`/api/public/invoice/${facilityId}/${invoiceId}${typeParam}`, "GET")

      console.log("Invoice response:", response)

      // Extract the invoice data - note the extra data nesting
      if (response.success && response.data) {
        // Handle the nested data structure properly
        const invoiceData = response.data.data?.data || response.data.data

        if (invoiceData) {
          setInvoice(invoiceData)
          // Validate and redirect if necessary to ensure correct URL type
          validateInvoiceType(invoiceData)
        } else {
          setError("Invoice data structure is invalid")
          console.error("Invalid invoice data structure:", response)
        }
      } else {
        // More detailed error logging
        console.error("Failed to fetch invoice:", {
          status: response.status,
          message: response.error || response.message,
          facilityId,
          invoiceId,
          type,
        })

        // Set error state with more specific message
        setError(response.error || response.message || "Failed to fetch invoice")
      }
    } catch (error) {
      console.error("Error fetching invoice:", {
        message: error.message,
        facilityId,
        invoiceId,
        type,
        stack: error.stack,
      })

      setError(error.message || "An error occurred")
    } finally {
      setLoading(false)
    }
  }

  // Helper function to determine invoice type
  const determineInvoiceType = (invoice) => {
    if (!invoice || !invoice.whatFor) return "levy"

    if (invoice.whatFor.invoiceType) {
      const typeValue = invoice.whatFor.invoiceType.toLowerCase()
      // Be explicit about case handling
      if (typeValue === "lease") {
        return "lease"
      } else if (typeValue === "contract" || typeValue === "levy" || typeValue === "service") {
        return "levy"
      }
    }

    // Default to levy if type can't be determined
    return "levy"
  }

  // Validate the invoice type against the URL and redirect if needed
  const validateInvoiceType = (invoiceData) => {
    if (!invoiceData || !invoiceData.whatFor) return

    // Determine the correct type based on the invoice data
    const actualType = determineInvoiceType(invoiceData)

    // If type param is missing or doesn't match, redirect to the correct URL
    if (!type || type.toLowerCase() !== actualType.toLowerCase()) {
      console.log(`Invoice type mismatch. URL: ${type || "none"}, Actual: ${actualType}. Redirecting...`)

      // Use replace:true to avoid adding to browser history
      navigate(`/invoice/${facilityId}/${invoiceData._id}/${actualType}`, { replace: true })
    }
  }

  const handleDownloadPDF = async () => {
    if (!invoice) return

    const element = document.getElementById("printable-invoice")
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-")
    const filename = `Invoice_${invoice.invoiceNumber}_${timestamp}.pdf`

    try {
      setLoading(true)
      console.log("Generating PDF...")

      // Create a clone of the element with zoom reset for PDF generation
      const cloneElement = element.cloneNode(true)
      cloneElement.style.transform = "none"
      cloneElement.style.position = "absolute"
      cloneElement.style.left = "-9999px"
      document.body.appendChild(cloneElement)

      const canvas = await html2canvas(cloneElement, {
        scale: 2,
        useCORS: true,
        backgroundColor: null, // Ensures transparency
        logging: false,
      })

      // Remove the clone after capturing
      document.body.removeChild(cloneElement)

      const imgData = canvas.toDataURL("image/jpeg", 1.0)
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      })

      const imgWidth = 210 // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width // Maintain aspect ratio

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST") // Start at y=0
      pdf.save(filename)

      console.log("PDF downloaded successfully!")
    } catch (error) {
      console.error("PDF generation error:", error)
    } finally {
      setLoading(false)
    }
  }

  // Helper functions
  const formatDate = (date) => {
    if (!date) return ""
    return new Date(date).toLocaleDateString()
  }

  const formatAmount = (amount) => {
    if (amount === undefined || amount === null) return "0.00"
    const currencyCode = invoice?.currency?.code || "KES"
    return `${currencyCode} ${Number.parseFloat(amount).toFixed(2)}`
  }

  // Get invoice title
  const getInvoiceTitle = () => {
    if (invoice?.whatFor?.invoiceType?.toLowerCase() === "lease") {
      return "LEASE INVOICE"
    }
    return "INVOICE"
  }

  // Balance calculation methods
  const hasBalanceBroughtForward = () => {
    return (
      invoice?.balanceBroughtForward !== 0 &&
      invoice?.balanceBroughtForward !== null &&
      invoice?.balanceBroughtForward !== undefined
    )
  }

  // Determine if balanceBroughtForward is a credit or debit
  const isCredit = invoice?.balanceBroughtForward < 0

  // Calculate total balance (invoice amount + balance brought forward)
  const calculateTotalBalance = () => {
    if (!invoice) return 0

    const totalAmount = invoice.totalAmount || 0
    const balanceBroughtForward = invoice.balanceBroughtForward || 0

    // Total balance is invoice amount + balance brought forward
    return totalAmount + balanceBroughtForward
  }

  // Calculate total due (total balance - amount paid)
  const calculateTotalDue = () => {
    if (!invoice) return 0

    const totalBalance = calculateTotalBalance()
    const amountPaid = invoice.amountPaid || 0

    // Total due is total balance minus payments
    return totalBalance - amountPaid
  }

  // Check if final amount is credit or debit
  const isFinalCredit = calculateTotalDue() < 0

  // Format the balance brought forward
  const formatBalanceBroughtForward = () => {
    if (!invoice || !invoice.balanceBroughtForward || invoice.balanceBroughtForward === 0) {
      return formatAmount(0)
    }

    // Always show the absolute value, let the display logic handle presentation
    return formatAmount(Math.abs(invoice.balanceBroughtForward))
  }

  // Render loading state
  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "70vh" }}>
        <div className="spinner-border text-primary mb-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p>Loading invoice details...</p>
      </div>
    )
  }

  // Render error state
  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading">Error!</h4>
          <p>{error}</p>
          <hr />
          <p className="mb-0">Please try refreshing the page or contact support if the issue persists.</p>
        </div>
      </div>
    )
  }

  if (!invoice) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
        <div className="alert alert-info" role="alert">
          <h4 className="alert-heading">Invoice Not Found</h4>
          <p>The requested invoice could not be found or has been removed.</p>
          <hr />
          <p className="mb-0">Please check the invoice number and try again.</p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.pageContainer}>
      {/* Action Button - With better padding and layout */}
      <div className={styles.actionCard}>
        <div className={styles.actionCardLeft}>
          <h5 style={{ margin: 0, fontWeight: 500 }}>Invoice Viewer</h5>
        </div>
        <div className={styles.actionCardRight}>
          <button className="btn btn-primary" onClick={handleDownloadPDF} disabled={loading}>
            Download Invoice
          </button>
        </div>
      </div>

      {/* Zoom Controls - Improved layout */}
      <div className={styles.zoomControls}>
        <button className={styles.zoomBtn} onClick={handleZoomOut} disabled={zoomLevel <= MIN_ZOOM} title="Zoom Out">
          -
        </button>
        <div className={styles.zoomLevel}>{zoomPercentage}%</div>
        <button className={styles.zoomBtn} onClick={handleZoomIn} disabled={zoomLevel >= MAX_ZOOM} title="Zoom In">
          +
        </button>
      </div>

      {/* Invoice Content in Scrollable Container */}
      <div className={styles.invoiceViewerContainer} ref={invoiceViewerRef}>
        <div className={styles.invoiceContainer} style={{ transform: `scale(${zoomLevel})` }}>
          <div id="printable-invoice" className={styles.invoiceContent}>
            {/* Status banner positioned to match reference image */}
            <div className={styles.statusBanner} data-status={invoice?.status?.toLowerCase() || "unpaid"}></div>

            <div className={styles.invoiceHeader}>
              <div className={styles.invoiceHeaderContent}>
                {/* Invoice title now above the logo */}
                <div className={styles.headerLeft}>
                  <h1 className={styles.invoiceTitleInHeader}>INVOICE</h1>
                  {facilityLogo && (
                    <div className={styles.logoContainer}>
                      <img src={facilityLogo || "/placeholder.svg"} alt="Company Logo" className={styles.companyLogo} />
                    </div>
                  )}
                </div>
                <div className={styles.headerDetails}>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE NO.</span>
                    <span className={styles.value}>{invoice?.invoiceNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>ACCOUNT NO.</span>
                    <span className={styles.value}>{invoice?.accountNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE DATE</span>
                    <span className={styles.value}>{formatDate(invoice?.issueDate)}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>DUE DATE</span>
                    <span className={styles.value}>{formatDate(invoice?.dueDate)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.invoiceBody}>
              <div className={styles.addressSection}>
                <div className={styles.addressLayout}>
                  <div className={styles.fromSection}>
                    <h2 className={styles.sectionTitle}>FROM</h2>
                    <div className={styles.addressContent}>
                      <div>{companyDetails?.name || "Company Name"}</div>
                      <div>{companyDetails?.address || "Company Address"}</div>
                      <div>{companyDetails?.country || "Country"}</div>
                    </div>
                  </div>
                  <div className={styles.billToSection}>
                    <h2 className={styles.sectionTitle}>BILL TO</h2>
                    <div className={styles.addressContent}>
                      {invoice?.client ? (
                        <div>
                          {`${invoice.client.firstName || ""} ${invoice.client.lastName || ""}`.trim() || "Client"}
                        </div>
                      ) : (
                        <div>Client Information Unavailable</div>
                      )}
                      <div>Unit: {invoice?.unit?.name || "N/A"}</div>
                    </div>
                  </div>
                  <div className={styles.totalSection}>
                    <h2 className={styles.sectionTitle}>TOTAL DUE</h2>
                    <div className={`${styles.totalAmount} ${isFinalCredit ? styles.creditAmount : ""}`}>
                      {isFinalCredit ? formatAmount(Math.abs(calculateTotalDue())) : formatAmount(calculateTotalDue())}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.itemsGrid}>
                <div className={styles.gridHeader}>
                  <span className={styles.columnHeader}>DESCRIPTION</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>UNIT PRICE</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>QTY</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>AMOUNT</span>
                </div>

                {invoice?.items?.map((item, index) => (
                  <div key={index} className={styles.gridRow}>
                    <span className={styles.value}>{item.description}</span>
                    <span className={`${styles.value} ${styles.right}`}>{formatAmount(item.unitPrice)}</span>
                    <span className={`${styles.value} ${styles.right}`}>{item.quantity}</span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.quantity * item.unitPrice)}
                    </span>
                  </div>
                ))}

                <div className={styles.calculations}>
                  <div className={styles.calculationsGrid}>
                    {/* Invoice Calculation Section */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>SUBTOTAL</span>
                      <span className={styles.calcValue}>{formatAmount(invoice?.subTotal)}</span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>
                        TAX ({((invoice?.tax / invoice?.subTotal) * 100).toFixed(0) || 0}%)
                      </span>
                      <span className={styles.calcValue}>{formatAmount(invoice?.tax)}</span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>INVOICE TOTAL</span>
                      <span className={styles.calcValue}>{formatAmount(invoice?.totalAmount)}</span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Balance Brought Forward - modified to make values (not labels) have the colors */}
                    {hasBalanceBroughtForward() && (
                      <div className={styles.calculationsRow}>
                        <span className={styles.calcLabel}>BALANCE B/FORWARD</span>
                        <span className={`${styles.calcValue} ${isCredit ? styles.creditAmount : styles.debitAmount}`}>
                          {isCredit ? "(" + formatBalanceBroughtForward() + ")" : formatBalanceBroughtForward()}
                        </span>
                      </div>
                    )}

                    {/* Total Balance */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>TOTAL BALANCE</span>
                      <span className={styles.calcValue}>{formatAmount(calculateTotalBalance())}</span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Amount Paid */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>PAYMENT</span>
                      <span className={styles.calcValue}>{formatAmount(invoice?.amountPaid)}</span>
                    </div>

                    {/* Total Due - show in green if credit, red if debit */}
                    <div className={`${styles.calculationsRow} ${styles.balance}`}>
                      <span className={styles.calcLabel}>TOTAL DUE</span>
                      <span
                        className={`${styles.calcValue} ${isFinalCredit ? styles.creditAmount : styles.debitAmount}`}
                      >
                        {isFinalCredit
                          ? "(" + formatAmount(Math.abs(calculateTotalDue())) + ")"
                          : formatAmount(calculateTotalDue())}
                      </span>
                    </div>
                  </div>

                  <div className={styles.totalRow}>
                    <span className={styles.totalLabel}>TOTAL DUE</span>
                    <span
                      className={`${styles.totalValue} ${isFinalCredit ? styles.creditAmount : styles.debitAmount}`}
                    >
                      {isFinalCredit
                        ? "(" + formatAmount(Math.abs(calculateTotalDue())) + ")"
                        : formatAmount(calculateTotalDue())}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.footer} style={{ backgroundColor: "#f0f0f0" }}>
                <div className="row">
                  <div className="col-sm-6 col-12 mt-2 px-3 px-sm-5">
                    {/* Payment Details on Left Margin */}
                    <div className={styles.paymentDetails}>
                      <h6 className={styles.detailsTitle}>Payment Details:</h6>
                      <h6 className={styles.detailsText}>Bank: Mpesa</h6>
                      <h6 className={styles.detailsText}>
                        Paybill: {invoice?.paymentDetails?.paymentMethod?.split(" - ")[1] || "N/A"}
                      </h6>
                      <h6 className={styles.detailsText}>AccountNumber: {invoice?.accountNumber}</h6>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12 mt-3 mt-sm-0">
                    {/* Signature Placeholder on Right Margin */}
                    <div className={styles.signatureSection}>
                      <div className={styles.signatureBox}>
                        <p style={{ textAlign: "center", marginTop: "20px" }}>___________________________</p>
                        <p style={{ textAlign: "center" }}>Authorized Signature</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pt-2">
                    <div className={styles.centeredText}>
                      <p style={{ textAlign: "center" }}>
                        To complete your payment, kindly use the paybill number
                        <strong> {invoice?.paymentDetails?.paymentMethod?.split(" - ")[1] || "N/A"} </strong>, followed
                        by the account number <strong> {invoice?.accountNumber || "N/A"} </strong>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UniversalInvoicePage

