import React, { useState, useEffect } from 'react';
import Layout from '../component/layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage';
import ConfirmDialog from '../component/confirmDialog';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getCustomerURL, getUnitsForFacility, getCustomerUnits, addUnoccupiedUnitToCustomer, finishDeactivationUrl, updateCustomer } from '../../../utils/urls';


const ViewCustomer = () => {
    const navigate = useNavigate()

    const { customerId } = useParams();
    const [customer, setCustomer] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [customerType, setCustomerType] = useState('')
    const [status, setStatus] = useState('')
    const [units, setUnits] = useState([])
    const [customerUnits, setCustomerUnits] = useState([])
    const [selectedUnits, setSelectedUnits] = useState([])
    const [addUnitDialog, setAddUnitDialogVisible] = useState(false)
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [confirmAction, setConfirmAction] = useState("");
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [selectedUnitId, setSelectedUnitId] = useState(null);



    const addUnoccupiedUnit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const data = {
                units: selectedUnits,
            };

            const response = await makeRequest2(`${addUnoccupiedUnitToCustomer}/${facilityId}/${customerId}`, 'POST', data);


            if (response.success) {
                toastify('Unit added successfully', 'success');
                setSelectedUnits([]);
                setAddUnitDialogVisible(false)
                getUnitsForCustomer();
                fetchUnits()
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    }

    const fetchUnits = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getUnitsForFacility}/${facilityId}`, 'GET', {});

            if (response.success) {
                const filteredUnits = response.data.filter(unit => {
                    const isFullyOccupied = unit.homeOwnerId && unit.tenantId && unit.residentId;
                    const homeownerLivesThere = unit.homeOwnerId && unit.residentId;
                    const noHomeOwnerOrNoTenant = !unit.homeOwnerId || !unit.tenantId;
                    const homeownerWithoutTenantOrResident = unit.homeOwnerId && !unit.tenantId && !unit.residentId;

                    return !isFullyOccupied && !homeownerLivesThere && (noHomeOwnerOrNoTenant || homeownerWithoutTenantOrResident);
                });

                // Apply additional filtering based on customerType
                let finalUnits = filteredUnits;

                if (customerType === "home owner") {
                    // Show all unoccupied units and occupied units that have tenantId but no homeownerId
                    finalUnits = filteredUnits.filter(unit => !unit.homeOwnerId || unit.tenantId);
                } else if (customerType === "tenant") {
                    // Show only units with a homeownerId where the homeowner is non-resident
                    finalUnits = filteredUnits.filter(unit => {
                        return unit.homeOwnerId && !unit.tenantId && !unit.residentId;
                    });
                }

                setUnits(finalUnits);
            } else {
                throw new Error('Error fetching units');
            }
        } catch (err) {
            console.error('Error fetching units:', err);
        }
    };




    const getCustomer = async () => {
        try {
            const response = await makeRequest2(getCustomerURL + '/' + customerId, 'GET')

            if (response.success === true) {
                const customer = response.data;
                setCustomer(customer)
                setFirstName(customer.firstName);
                setLastName(customer.lastName);
                setPhoneNumber(customer.phoneNumber);
                setEmail(customer.email);
                setCustomerType(customer.customerType);
                setStatus(customer.status);
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const getUnitsForCustomer = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getCustomerUnits}/${facilityId}/${customerId}`, 'GET', {});
            if (response.success) {
                setCustomerUnits(response.data);
            }
        }
        catch (error) {
            toastify('Error fetching units: ' + error.message);
        }
    };

    const actionTemplate = (rowData) => {
        return rowData.tenantId ? (
            <Button
                label="Proceed to Handover"
                icon="pi pi-arrow-right"
                className="p-button-primary"
                style={{ borderRadius: "20px", padding: "8px 16px" }}
            />
        ) : (
            <Button
                label="Finish Deactivation"
                icon="pi pi-check"
                className="p-button-danger"
                style={{ borderRadius: "20px", padding: "8px 16px" }}
                onClick={() => handleFinishDeactivation(rowData._id)}
            />
        );
    };



    const statusTemplate = (customer) => {
        const statusStyles = {
            Active: { color: '#28a745', backgroundColor: '#e9fbe9' }, // Green for Active
            Inactive: { color: '#dc3545', backgroundColor: '#fde9e9' } // Red for Inactive
        };

        const style = statusStyles[customer.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {customer.status}
            </span>
        );
    };

    const actionBodyTemplate = (customer) => {
        return (
            <div className="d-flex justify-content-center gap-3 mt-4">
                <button
                    className={`btn ${customer.status === "Inactive" ? "btn-success" : "btn-danger"} px-4 py-2`}
                    onClick={() => {
                        setSelectedCustomerId(customer._id);
                        setConfirmAction(customer.status === "Inactive" ? "activate" : "deactivate");
                        setConfirmDialogVisible(true);
                    }}
                >
                    {customer.status === "Inactive" ? "Activate" : "Deactivate"}
                </button>
            </div>
        );
    };

    const handleConfirmAction = async () => {
        setConfirmDialogVisible(false);

        if (confirmAction === "deactivate") {
            await handleDeactivate(selectedCustomerId);
        }
    };


    const handleDeactivate = async (customerId) => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getCustomerUnits}/${facilityId}/${customerId}`, 'GET', {});
            if (response.success) {
                setCustomerUnits(response.data);
                setShowDialog(true);
            }
        }
        catch (error) {
            toastify('Error fetching units: ' + error.message);
        }
    }

    const handleFinishDeactivation = async (unitId) => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const selectedUnit = customerUnits.find(unit => unit._id === unitId);

            if (selectedUnit && !selectedUnit.tenantId) {
                const response = await makeRequest2(
                    `${finishDeactivationUrl}/${facilityId}/${selectedUnit._id}`,
                    'POST',
                    { customerId: selectedCustomerId }
                );

                if (response.success) {
                    toastify('Unit deactivated successfully', 'success');

                    // Refresh the customer units list immediately
                    const updatedUnitsResponse = await makeRequest2(
                        `${getCustomerUnits}/${facilityId}/${selectedCustomerId}`,
                        'GET',
                        {}
                    );

                    if (updatedUnitsResponse.success) {
                        setCustomerUnits(updatedUnitsResponse.data);

                        // Close the modal if no more active units remain
                        if (updatedUnitsResponse.data.length === 0) {
                            setShowDialog(false);
                        }
                    }
                } else {
                    toastify('Error finishing deactivation: ' + response.message);
                }
            }
        } catch (err) {
            toastify('Error: ' + err.message);
        }
    };

    const HandleCustomerUpdate = async () => {
        try {
            const updatedCustomer = {
                firstName,
                lastName,
                phoneNumber,
                email,
            };

            const response = await makeRequest2(`${updateCustomer}/${customerId}`, 'POST', updatedCustomer);

            if (response.success) {
                toastify(response.data, 'success');
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setEmail('');
                getCustomer();
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            console.log(err);
            toastify(err.message, 'error');
        }
    };



    useEffect(() => {
        getCustomer(); // Fetch customer first
    }, []); 
    
    useEffect(() => {
        if (customerType) { // Only fetch units when customerType is available
            fetchUnits();
            getUnitsForCustomer();
        }
    }, [customerType]); // Runs fetchUnits when customerType updates
    

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/customer_management/customers"}>Customer  Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Customer</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body py-0">
                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                <i className="ti ti-building-bank me-2"></i>Info
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                <i className="ti ti-building-warehouse me-2"></i>Units
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-5" role="tab" aria-selected="true">
                                                <i className="ti ti-settings me-2"></i>Settings
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                    <div className="row">
                                        <div className="col-lg-4 col-xxl-3">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="text-center mt-3">
                                                        <div className="chat-avtar d-inline-flex mx-auto">
                                                            <i className="ti ti-user me-2" style={{ fontSize: 100 }}></i>
                                                        </div>
                                                        <h5 className="mb-0"></h5>
                                                        <h2 className="text-muted mt-2">{customer.firstName} {customer.lastName}</h2>

                                                        <hr className="my-3 border border-secondary-subtle" />

                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Email: </strong>{customer.email}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Phone No.: </strong>{customer.phoneNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>ID No: </strong>{customer.idNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Type: </strong>{customer.customerType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Resident: </strong>{customer.residentType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Status: </strong>{statusTemplate(customer)}</p>
                                                        </div>

                                                        {/* Button for Deactivate/Activate */}
                                                        {actionBodyTemplate(customer)}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">

                                    <div className="card">

                                        <div className="card-header">

                                            <div>
                                                {/* {customer?.customerType === 'home owner' && (
                                                    <div style={{ float: 'right' }}>
                                                        <button className="btn btn-primary"
                                                            onClick={() => { setAddUnitDialogVisible(true) }}
                                                        ><i className="ti ti-circle-plus me-2"></i> Add Unit</button>
                                                    </div>
                                                )} */}
                                                <div style={{ float: 'right' }}>
                                                    <button className="btn btn-primary"
                                                        onClick={() => { setAddUnitDialogVisible(true) }}
                                                    ><i className="ti ti-circle-plus me-2"></i> Add Unit</button>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="card-body">
                                            <div className="tab-content" id="nav-tabContent">

                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <DataTable value={customerUnits} emptyMessage="No units found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field="name" sortable header="Name"></Column>
                                                                <Column field="unitType" header="Type"></Column>
                                                                <Column field="floorUnitNo" header="Floor/Unit"></Column>
                                                                <Column field="lettableFloorArea" header="LFA"></Column>
                                                                <Column field="landRateNumber" header="LRN"></Column>
                                                                <Column field="grossArea" header="GA"></Column>
                                                                <Column field="netLettableArea" header="NLA"></Column>

                                                            </DataTable>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tab-pane fade" id="profile-5" role="tabpanel" aria-labelledby="profile-tab-5">
                                    <div className="card">

                                        <div className="card-body py-0">
                                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">


                                                <li className="nav-item">
                                                    <a className="nav-link active" id="setting-tab-1" data-bs-toggle="tab" href="#setting-1" role="tab" aria-selected="true">
                                                        <i className="ti ti-edit me-2"></i>Edits
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="tab-content">

                                            <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                                <div className="row">
                                                    <div className="col-lg-4">

                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <b>Update Customer</b>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>First Name</label>
                                                                <input className="form-control mt-2" type="text"
                                                                    value={firstName} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setFirstName(value)
                                                                    }}
                                                                ></input>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>Last Name</label>
                                                                <input className="form-control mt-2" type="text"
                                                                    value={lastName} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setLastName(value)
                                                                    }}
                                                                ></input>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>Phone Number</label>
                                                                <input className="form-control mt-2" type="text"
                                                                    value={phoneNumber} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setPhoneNumber(value)
                                                                    }}
                                                                ></input>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>Email</label>
                                                                <input className="form-control mt-2" type="text"
                                                                    value={email} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setEmail(value)
                                                                    }}
                                                                ></input>
                                                            </div>




                                                            <div className='mt-4 mb-2' style={{ marginRight: '5px' }}>
                                                                <button className="btn btn-primary" onClick={HandleCustomerUpdate} style={{ float: 'right' }}>Save Changes</button>
                                                            </div>


                                                        </div>


                                                    </div>
                                                    <div className="col-lg-4">

                                                    </div>

                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Confirm dialog */}
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onHide={() =>
                    setConfirmDialogVisible(false)
                }
                message={`Are you sure you want to ${confirmAction} this Customer?`}
                onConfirm={handleConfirmAction}
            />

            <Dialog
                header={'Add Unit To Customer'}
                visible={addUnitDialog}

                onHide={() => {
                    if (addUnitDialog) setAddUnitDialogVisible(false);
                }}
            >
                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="mb-3">
                            <label className="form-label">Unit<span style={{ color: 'red' }}>*</span></label>
                            <Autocomplete
                                multiple
                                options={units} // `units` is already filtered based on customerType
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedUnits}
                                onChange={(event, newValue) => setSelectedUnits(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Units"
                                        variant="outlined"
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={option._id}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />


                        </div>


                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary" onClick={addUnoccupiedUnit}>
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog>


            {/* Dialog for Customer Units */}
            <Dialog
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                header="Homeowner Units"
                style={{ width: "30vw" }}
                modal
            >
                <DataTable value={customerUnits} responsiveLayout="scroll">
                    <Column field="name" header="Unit Name" />
                    <Column field="tenantId" header="Tenant" body={(rowData) => (rowData.tenantId ? "Yes" : "No")} />
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
            </Dialog>
        </Layout>
    )
}

export default ViewCustomer