import React, { useState } from 'react';
import AnalogMeterList from './AnalogMetersManagementTab';
import AnalogMeterHistory from './AnalogMeterHistoryTab';

const AnalogMetersTab = () => {
  const [activeSubTab, setActiveSubTab] = useState('list');

  const renderTabContent = () => {
    switch (activeSubTab) {
      case 'list':
        return <AnalogMeterList />;
      case 'history':
        return <AnalogMeterHistory />;
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Sub-Tabs for Analog Meters */}
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeSubTab === 'list' ? 'active' : ''}`}
            onClick={() => setActiveSubTab('list')}
          >
            Analog Meters
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeSubTab === 'history' ? 'active' : ''}`}
            onClick={() => setActiveSubTab('history')}
          >
            Readings
          </button>
        </li>
      </ul>
      <div className="tab-content mt-3">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default AnalogMetersTab;
