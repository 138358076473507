import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toastify } from "../../../../../utils/toast";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import { create_lease_template } from "../../../../../utils/urls";
import { getItem } from "../../../../../utils/localStorage";

// Updated lease fields to match exactly what's in AddLeaseAgreement
const leaseFields = {
  basicInformation: [
    { label: "Unit", placeholder: "{unit.name}", description: "Name of the unit" },
    { label: "Unit Floor Number", placeholder: "{unit.floorUnitNo}", description: "Floor and unit number" },
    { label: "Status", placeholder: "{lease.status}", description: "Status of the lease agreement" }
  ],
  tenantDetails: [
    { label: "Tenant First Name", placeholder: "{tenant.firstName}", description: "First name of the tenant" },
    { label: "Tenant Last Name", placeholder: "{tenant.lastName}", description: "Last name of the tenant" },
    { label: "Tenant Phone", placeholder: "{tenant.phoneNumber}", description: "Tenant's phone number" },
    { label: "Tenant Email", placeholder: "{tenant.email}", description: "Tenant's email address" },
    { label: "Tenant ID", placeholder: "{tenant.idNumber}", description: "Tenant's identification number" }
  ],
  landlordDetails: [
    { label: "Landlord First Name", placeholder: "{landlord.firstName}", description: "First name of the landlord" },
    { label: "Landlord Last Name", placeholder: "{landlord.lastName}", description: "Last name of the landlord" },
    { label: "Landlord Phone", placeholder: "{landlord.phoneNumber}", description: "Landlord's phone number" },
    { label: "Landlord Email", placeholder: "{landlord.email}", description: "Landlord's email address" },
  ],
  leaseTerms: [
    { label: "Start Date", placeholder: "{lease.leaseTerms.startDate}", description: "When the lease begins" },
    { label: "End Date", placeholder: "{lease.leaseTerms.endDate}", description: "When the lease ends" },
    { label: "Lease Duration (Months)", placeholder: "{lease.leaseTerms.duration}", description: "Total duration of the lease in months" }
  ],
  financialTerms: [
    { label: "Currency Name", placeholder: "{currency.currencyName}", description: "Name of the currency" },
    { label: "Currency Code", placeholder: "{currency.currencyShortCode}", description: "Currency short code" },
    { label: "Monthly Rent", placeholder: "{lease.financialTerms.monthlyRent}", description: "Monthly rental amount" },
    { label: "Security Deposit", placeholder: "{lease.financialTerms.securityDeposit}", description: "Required security deposit" },
    { label: "Payment Due Date", placeholder: "{lease.financialTerms.paymentDueDate}th of each month", description: "When rent is due" }
  ],
  billingCycle: [
    { label: "Billing Frequency", placeholder: "{lease.billingCycle.frequency}", description: "How often billing occurs" },
    { label: "Next Invoice Date", placeholder: "{lease.billingCycle.nextInvoiceDate}", description: "Date of the next invoice" }
  ],
  paymentMethods: [
    { label: "Payment Method Type", placeholder: "{lease.financialTerms.paymentMethods[0].type}", description: "Type of payment method" },
    { label: "Is Primary Payment Method", placeholder: "{lease.financialTerms.paymentMethods[0].isPrimary}", description: "Whether this is the primary payment method" },
    { label: "Bank Name", placeholder: "{lease.financialTerms.paymentMethods[0].details.bankName}", description: "Bank name for transfer" },
    { label: "Account Name", placeholder: "{lease.financialTerms.paymentMethods[0].details.accountName}", description: "Account holder name" },
    { label: "Account Number", placeholder: "{lease.financialTerms.paymentMethods[0].details.accountNumber}", description: "Account number for payment" },
    { label: "Branch", placeholder: "{lease.financialTerms.paymentMethods[0].details.branch}", description: "Bank branch" },
    { label: "Swift Code", placeholder: "{lease.financialTerms.paymentMethods[0].details.swiftCode}", description: "Swift code for international transfers" },
    { label: "All Payment Methods", placeholder: "{lease.financialTerms.paymentMethods[*].type}", description: "Displays all payment methods" }
  ],
  mobilePayment: [
    { label: "Mobile Payment Enabled", placeholder: "{lease.financialTerms.mpesaEnabled}", description: "Whether mobile payment is enabled" },
    { label: "Business Number", placeholder: "{lease.financialTerms.mpesaDetails.businessNumber}", description: "M-Pesa business number" },
    { label: "Account Number", placeholder: "{lease.financialTerms.mpesaDetails.accountNumber}", description: "M-Pesa account number" },
    { label: "Phone Number", placeholder: "{lease.financialTerms.mpesaDetails.phoneNumber}", description: "M-Pesa phone number" }
  ],
  penaltiesAndReminders: [
    { label: "Penalty Name", placeholder: "{penalty.name}", description: "Name of the penalty" },
    { label: "Penalty Type", placeholder: "{penalty.type}", description: "Type of penalty (fixed or percentage)" },
    { label: "Penalty Amount", placeholder: "{penalty.amount}", description: "Fixed amount of the penalty" },
    { label: "Penalty Percentage", placeholder: "{penalty.percentage}", description: "Percentage value of the penalty" },
    { label: "Reminder Name", placeholder: "{reminder.name}", description: "Name of the reminder" },
    { label: "Notification Types", placeholder: "{reminder.notificationTypes}", description: "Types of notifications for reminders" }
  ]
};

const CreateLeaseTemplate = ({ visible, onHide, onTemplateCreated }) => {
  const [templateName, setTemplateName] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [userId, setUserId] = useState(null);
  const quillRef = useRef(null);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem("APPUSER");
      let currentUser = typeof currentUserValue === "string" ? JSON.parse(currentUserValue) : currentUserValue;
      if (currentUser?.user?.userId) {
        setUserId(currentUser.user.userId);
      }
    } catch (err) {
      console.error("Error getting user:", err.message);
    }
  };

  const insertTextAtCursor = (text) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const range = editor.getSelection(true);
      editor.insertText(range.index, text);
      editor.setSelection(range.index + text.length);
    }
  };

  const handleSaveTemplate = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      if (!templateName || !templateContent || !templateDescription || !userId) {
        toastify("Please fill in all required fields.", "error");
        return;
      }

      const response = await makeRequest2(
        `${create_lease_template}/${facilityId}`,
        "POST",
        {
          name: templateName,
          description: templateDescription,
          templateContent,
          createdBy: userId,
        }
      );

      if (response.success) {
        toastify("Lease template created successfully!", "success");
        onTemplateCreated();
        resetForm();
        onHide();
      } else {
        toastify(response.error || "Failed to create lease template.", "error");
      }
    } catch (err) {
      toastify(err.message || "An unexpected error occurred.", "error");
    }
  };

  const resetForm = () => {
    setTemplateName("");
    setTemplateDescription("");
    setTemplateContent("");
  };

  return (
    <Dialog
      header="Create Lease Template"
      visible={visible}
      style={{ 
        width: "95vw", 
        maxWidth: "1400px",
        height: '95vh',
      }}
      onHide={() => {
        onHide();
        resetForm();
      }}
      className="lease-template-dialog"
      maximizable
    >
      <div className="template-form p-3">
        {/* Header Section - Keep compact */}
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Template Name</label>
              <input
                className="form-control"
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Enter template name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Template Description</label>
              <input
                className="form-control"
                type="text"
                value={templateDescription}
                onChange={(e) => setTemplateDescription(e.target.value)}
                placeholder="Enter template description"
              />
            </div>
          </div>
        </div>

        {/* Main Content Section with Fixed Height */}
        <div className="row" style={{ height: 'calc(100vh - 280px)' }}>
          {/* Editor Section */}
          <div className="col-md-8 h-100 d-flex flex-column">
            <ReactQuill
              ref={quillRef}
              value={templateContent}
              onChange={setTemplateContent}
              placeholder="Start typing your template content here..."
              style={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ align: [] }],
                  ['clean']
                ]
              }}
            />
          </div>

          {/* Properties Section */}
          <div className="col-md-4 h-100">
            <div className="properties-section h-100">
              {Object.entries(leaseFields).map(([category, fields]) => (
                <div key={category} className="field-category mb-4">
                  <h6 className="text-uppercase mb-3 fw-bold" style={{ color: '#2196f3' }}>
                    {category.replace(/([A-Z])/g, ' $1').trim()}
                  </h6>
                  {fields.map((field, index) => (
                    <div key={index} className="mb-2">
                      <button
                        className="btn btn-light btn-sm w-100 text-start position-relative"
                        onClick={() => insertTextAtCursor(field.placeholder)}
                        style={{
                          borderLeft: '3px solid #2196f3',
                          transition: 'all 0.2s ease',
                          padding: '10px 15px'
                        }}
                        title={field.description}
                      >
                        <span className="d-block" style={{ fontSize: '0.9rem' }}>{field.label}</span>
                        <small className="text-muted d-block" style={{ fontSize: '0.75rem' }}>
                          {field.placeholder}
                        </small>
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Footer Actions - Fixed at Bottom */}
        <div className="action-buttons mt-3" style={{ 
          borderTop: '1px solid #dee2e6',
          paddingTop: '1rem',
          backgroundColor: '#fff',
          textAlign: 'right'
        }}>
          <Button 
            label="Cancel" 
            className="p-button-secondary p-button-outlined me-2" 
            onClick={() => {
              onHide();
              resetForm();
            }}
          />
          <Button 
            label="Add Template" 
            icon="pi pi-save"
            className="p-button-primary" 
            onClick={handleSaveTemplate}
          />
        </div>
      </div>

      <style>{`
        .lease-template-dialog .p-dialog-content {
          padding: 0;
          overflow: hidden;
          height: calc(95vh - 6rem) !important;
          position: relative;
        }
        
        .ql-container {
          flex: 1;
          overflow-y: auto !important;
          min-height: 300px;
        }

        .ql-editor {
          min-height: 100%;
        }

        .properties-section {
          overflow-y: auto;
          padding: 15px;
          background-color: #f8f9fa;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }

        .properties-section::-webkit-scrollbar {
          width: 6px;
        }
        
        .properties-section::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        
        .properties-section::-webkit-scrollbar-thumb {
          background: #2196f3;
          border-radius: 3px;
        }

        .template-form {
          height: 100%;
          overflow-y: auto;
          padding-bottom: 80px; /* Space for footer */
        }

        .lease-template-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 1rem;
          background: #fff;
          border-top: 1px solid #dee2e6;
          z-index: 1000;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 70px;
        }
      `}</style>
    </Dialog>
  );
};

export default CreateLeaseTemplate;