export const customers = [
    {
      fullname: "John Doe",
      address: `Toll - Unit `,
      mobile_phone: "+254 712 345 678",
      email: "johndoe@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Jane Smith",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 723 456 789",
      email: "janesmith@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "Michael Otieno",
      address: `Toll - Unit `,
      mobile_phone: "+254 734 567 890",
      email: "michaelotieno@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Alice Njeri",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 745 678 901",
      email: "alicenjeri@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "Brian Mwangi",
      address: `Toll - Unit `,
      mobile_phone: "+254 756 789 012",
      email: "brianmwangi@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Grace Wanjiku",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 767 890 123",
      email: "gracewanjiku@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "David Kamau",
      address: `Toll - Unit `,
      mobile_phone: "+254 778 901 234",
      email: "davidkamau@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
    },
    {
      fullname: "Emily Achieng",
      address: `Knights Bridge - Unit `,
      mobile_phone: "+254 789 012 345",
      email: "emilyachieng@example.com",
      idno: '00000',
      status: "inactive",
      type: "business"
    },
    {
      fullname: "Charles Njoroge",
      address: `Toll - Unit `,
      mobile_phone: "+254 790 123 456",
      email: "charlesnjoroge@example.com",
      idno: '00000',
      status: "active",
      type: "individual"
}]
export const units = [
    {
      unit: 1,
      block: "A",
      floor: 1
    },
    {
      unit: 2,
      block: "B",
      floor: 2
    },
    {
      unit: 3,
      block: "C",
      floor: 3
    },
    {
      unit: 4,
      block: "D",
      floor: 4
    },
    {
      unit: 5,
      block: "E",
      floor: 5
    },
    {
      unit: 6,
      block: "F",
      floor: 6
    },
    {
      unit: 7,
      block: "G",
      floor: 7
    },
    {
      unit: 8,
      block: "H",
      floor: 8
    },
    {
      unit: 9,
      block: "I",
      floor: 9
    },
    {
      unit: 10,
      block: "J",
      floor: 10
    }
  ];
export const watermeters = [
    {
      "meter_sn": "MTR001",
      "unit": "Unit 101",
      "current_reading": 123.45,
      "initial_reading": 120.45,
      "protocol": "TCP",
      "status": "Open",
      "last_updated": "2024-08-01T14:23:00Z"
    },
    {
      "meter_sn": "MTR002",
      "unit": "Unit 202",
      "current_reading": 453.95,
      "initial_reading": 449.45,
      "protocol": "TCP",
      "status": "Closed",
      "last_updated": "2024-07-31T09:12:00Z"
    },
    {
      "meter_sn": "MTR003",
      "unit": "Unit 303",
      "current_reading": 345.65,
      "initial_reading": 342.45,
      "protocol": "GPRS API",
      "status": "Open",
      "last_updated": "2024-07-30T16:45:00Z"
    },
    {
      "meter_sn": "MTR004",
      "unit": "Unit 404",
      "current_reading": 89.20,
      "initial_reading": 88.23,
      "protocol": "GPRS API",
      "status": "Closed",
      "last_updated": "2024-07-29T10:30:00Z"
    },
    {
      "meter_sn": "MTR005",
      "unit": "Unit 505",
      "current_reading": 454.35,
      "initial_reading": 450.45,
      "protocol": "TCP",
      "status": "Open",
      "last_updated": "2024-08-01T08:00:00Z"
    }
  ]
export const powerMeters = [
    {
      "meter_sn": "MTR006",
      "unit": "Unit 106",
      "meter_type": "Three Phase",
      "initial_reading": 456.78,
      "current_reading": 456.78,
      "meter_status": "installed",
      "consumption_type": "Domestic Lifeline",
      "status": "On",
      "last_updated": "2024-08-01T15:00:00Z"
    },
    {
      "meter_sn": "MTR007",
      "unit": "Unit 207",
      "meter_type": "Three Phase",
      "initial_reading": 456.78,
      "current_reading": 459.78,
      "meter_status": "installed",
      "consumption_type": "Domestic Lifeline",
      "status": "Off",
      "last_updated": "2024-07-31T10:00:00Z"
    },
    {
      "meter_sn": "MTR008",
      "unit": "Unit 308",
      "meter_type": "Three Phase",
      "initial_reading": 402.78,
      "current_reading": 430.78,
      "meter_status": "installed",
      "consumption_type": "Domestic Lifeline",
      "status": "On",
      "last_updated": "2024-07-30T17:00:00Z"
    },
    {
      "meter_sn": "MTR009",
      "unit": "Unit 409",
      "meter_type": "Three Phase",
      "initial_reading": 456.78,
      "current_reading": 456.78,
      "meter_status": "installed",
      "consumption_type": "Domestic Lifeline",
      "status": "Off",
      "last_updated": "2024-07-29T11:00:00Z"
    }];

    export const invoices = [
      {
        invoice_no: "INV-2024-001",
        invoice_date: "2024-01-15",
        customer: "John Doe",
        unit: "Toll - Unit 1",
        invoice_amount: 15000.00,
        paid_amount: 15000.00,
        balance: 0.00,
        status: "paid",
        due_date: "2024-02-15",
        month: "January",
        actions: null
      },
      {
        invoice_no: "INV-2024-002",
        invoice_date: "2024-01-15",
        customer: "Jane Smith",
        unit: "Knights Bridge - Unit 2",
        invoice_amount: 18500.00,
        paid_amount: 10000.00,
        balance: 8500.00,
        status: "partial",
        due_date: "2024-02-15",
        month: "January",
        actions: null
      },
      {
        invoice_no: "INV-2024-003",
        invoice_date: "2024-01-15",
        customer: "Michael Otieno",
        unit: "Toll - Unit 3",
        invoice_amount: 12000.00,
        paid_amount: 0.00,
        balance: 12000.00,
        status: "unpaid",
        due_date: "2024-02-15",
        month: "January",
        actions: null
      },
      {
        invoice_no: "INV-2024-004",
        invoice_date: "2024-01-15",
        customer: "Alice Njeri",
        unit: "Knights Bridge - Unit 4",
        invoice_amount: 20000.00,
        paid_amount: 20000.00,
        balance: 0.00,
        status: "paid",
        due_date: "2024-02-15",
        month: "January",
        actions: null
      }
    ];

    export const customers1 = [
      {
        fullname: "John Doe",
        phone: "+254 712 345 678",
        meter_no: "WM-2024-001",
        unit: "Toll - Unit 1",
        payment_type: "PostPaid",
        balance_bf: 12500.00,
        acc_balance: 15000.00,
        neg_balance: 0.00,
        status: "active",
        activity: null
      },
      {
        fullname: "Jane Smith",
        phone: "+254 723 456 789",
        meter_no: "WM-2024-002",
        unit: "Knights Bridge - Unit 2",
        payment_type: "PrePaid",
        balance_bf: 0.00,
        acc_balance: 5000.00,
        neg_balance: 0.00,
        status: "active",
        activity: null
      },
      {
        fullname: "Michael Otieno",
        phone: "+254 734 567 890",
        meter_no: "WM-2024-003",
        unit: "Toll - Unit 3",
        payment_type: "PostPaid",
        balance_bf: 8000.00,
        acc_balance: 0.00,
        neg_balance: -3000.00,
        status: "suspended",
        activity: null
      },
      {
        fullname: "Alice Njeri",
        phone: "+254 745 678 901",
        meter_no: "WM-2024-004",
        unit: "Knights Bridge - Unit 4",
        payment_type: "PrePaid",
        balance_bf: 0.00,
        acc_balance: 7500.00,
        neg_balance: 0.00,
        status: "active",
        activity: null
      },
      {
        fullname: "Brian Mwangi",
        phone: "+254 756 789 012",
        meter_no: "WM-2024-005",
        unit: "Toll - Unit 5",
        payment_type: "PostPaid",
        balance_bf: 15000.00,
        acc_balance: 0.00,
        neg_balance: -5000.00,
        status: "inactive",
        activity: null
      }
    ];

    export const transactions = [
      {
          transaction_ref: "TRX-2024-001",
          customer: "John Doe",
          unit: "Toll - Unit 1",
          payment_method: "M-PESA",
          total_amount: 15000.00,
          added_on: "2024-01-15T09:30:00Z"
      },
      {
          transaction_ref: "TRX-2024-002",
          customer: "Jane Smith",
          unit: "Knights Bridge - Unit 2",
          payment_method: "Bank Transfer",
          total_amount: 10000.00,
          added_on: "2024-01-15T10:45:00Z"
      },
      {
          transaction_ref: "TRX-2024-003",
          customer: "Michael Otieno",
          unit: "Toll - Unit 3",
          payment_method: "Cash",
          total_amount: 12000.00,
          added_on: "2024-01-16T11:20:00Z"
      },
      {
          transaction_ref: "TRX-2024-004",
          customer: "Alice Njeri",
          unit: "Knights Bridge - Unit 4",
          payment_method: "M-PESA",
          total_amount: 20000.00,
          added_on: "2024-01-16T14:15:00Z"
      },
      {
          transaction_ref: "TRX-2024-005",
          customer: "Brian Mwangi",
          unit: "Toll - Unit 5",
          payment_method: "Bank Transfer",
          total_amount: 18500.00,
          added_on: "2024-01-17T09:00:00Z"
      },
      {
          transaction_ref: "TRX-2024-006",
          customer: "Grace Wanjiku",
          unit: "Knights Bridge - Unit 6",
          payment_method: "Cash",
          total_amount: 13000.00,
          added_on: "2024-01-17T11:30:00Z"
      },
      {
          transaction_ref: "TRX-2024-007",
          customer: "David Kamau",
          unit: "Toll - Unit 7",
          payment_method: "M-PESA",
          total_amount: 16500.00,
          added_on: "2024-01-18T13:45:00Z"
      },
      {
          transaction_ref: "TRX-2024-012",
          customer: "Michael Otieno",
          unit: "Toll - Unit 3",
          payment_method: "Bank Transfer",
          total_amount: 14500.00,
          added_on: "2024-01-20T11:45:00Z"
      }
  ];
  
  
  
