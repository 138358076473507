import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import ConfirmDialog from '../component/confirmDialog';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import {
    getCashPayments,
    approveCashPayment,
    rejectCashPayment,
    voidCashPayment,
    getUnpaidInvoices,
    applyOverpayment,
    get_credit_invoices
} from '../../../utils/urls';
import { toastify } from '../../../utils/toast';

function FacilityPayments() {
    // Tab Management
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    // Cash Payment States
    const [cashPayments, setCashPayments] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState(null);

    // Search filter state
    const [globalFilter, setGlobalFilter] = useState('');

    // Dialog States
    const [approveDialogVisible, setApproveDialogVisible] = useState(false);
    const [rejectDialogVisible, setRejectDialogVisible] = useState(false);
    const [voidDialogVisible, setVoidDialogVisible] = useState(false);
    const [comments, setComments] = useState('');

    // Overpayment related states
    const [overpaymentDialogVisible, setOverpaymentDialogVisible] = useState(false);
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [overpaymentAmount, setOverpaymentAmount] = useState(0);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    const [remainingOverpayment, setRemainingOverpayment] = useState(0);

    useEffect(() => {
        fetchCashPayments();
    }, []);

    const fetchCashPayments = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getCashPayments}/${facilityId}`, 'GET');

            if (response.success) {
                const payments = response.data?.data?.payments || [];
                setCashPayments(payments);

                if (payments.length === 0) {
                    toastify('No cash payments found for this facility', 'info');
                }
            } else {
                setCashPayments([]);
                toastify(response.message || 'Unable to fetch cash payments', 'error');
            }
        } catch (error) {
            console.error('Error fetching cash payments:', error);
            setCashPayments([]);
            toastify(error.message || 'Error fetching cash payments', 'error');
        } finally {
            setLoading(false);
        }
    };

    const fetchInvoiceDetails = async (invoiceId, accountNumber) => {
        console.log('=== Start: fetchInvoiceDetails ===');
        console.log(`Params - invoiceId: ${invoiceId}, accountNumber: ${accountNumber}`);

        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            console.log(`facilityId from localStorage: ${facilityId}`);

            // First try to get using invoice ID
            if (invoiceId) {
                console.log(`Attempting to fetch invoice by ID: ${invoiceId}`);
                try {
                    const byIdUrl = `/api/app/levy_management/get_invoice_by_id/${facilityId}/${invoiceId}`;
                    console.log(`GET request to: ${byIdUrl}`);

                    const response = await makeRequest2(byIdUrl, 'GET');
                    console.log(`Response status: ${response ? 'success' : 'failed'}`);
                    console.log(`Response data: ${JSON.stringify(response)}`);

                    if (response && response.success && response.data) {
                        console.log(`Successfully found invoice by ID. Invoice number: ${response.data.invoiceNumber}`);
                        console.log('=== End: fetchInvoiceDetails ===');
                        return response.data;
                    } else {
                        console.log(`Invoice not found by ID or response invalid. Success: ${response?.success}, Has data: ${!!response?.data}`);
                    }
                } catch (err) {
                    console.error(`Error fetching by ID: ${err.message}`);
                    console.error(`Error stack: ${err.stack}`);
                    console.log("Could not fetch by ID, trying account number");
                }
            } else {
                console.log('No invoiceId provided, skipping fetch by ID');
            }

            // Fallback: try to get using account number if available
            if (accountNumber) {
                console.log(`Attempting to fetch invoice by account number: ${accountNumber}`);
                try {
                    const byAccountUrl = `/api/app/levy_management/get_invoice_by_account/${facilityId}/${accountNumber}`;
                    console.log(`GET request to: ${byAccountUrl}`);

                    const response = await makeRequest2(byAccountUrl, 'GET');
                    console.log(`Response status: ${response ? 'success' : 'failed'}`);
                    console.log(`Response data structure: ${response ? Object.keys(response).join(', ') : 'null'}`);

                    if (response && response.success && response.data) {
                        console.log(`Successfully found invoice by account number. Invoice number: ${response.data.invoiceNumber}`);
                        console.log('=== End: fetchInvoiceDetails ===');
                        return response.data;
                    } else {
                        const statusMsg = response?.status || 'unknown status';
                        const successMsg = response?.success || false;
                        console.log(`Invoice not found by account number or response invalid. Status: ${statusMsg}, Success: ${successMsg}`);

                        // Check if there's an error message in the response
                        if (response?.message) {
                            console.log(`Error message: ${response.message}`);
                        }

                        // Check for redirect issues
                        if (response?.status === 302) {
                            console.error('Received 302 redirect. This might indicate improper API routing or authentication issues');
                        }
                    }
                } catch (err) {
                    console.error(`Error fetching by account number: ${err.message}`);
                    console.error(`Error stack: ${err.stack}`);
                    console.error("Could not fetch invoice by account number either");
                }
            } else {
                console.log('No accountNumber provided, skipping fetch by account number');
            }

            console.log('Failed to fetch invoice through any method');
            console.log('=== End: fetchInvoiceDetails ===');
            return null;
        } catch (error) {
            console.error(`General error in fetchInvoiceDetails: ${error.message}`);
            console.error(`Error stack: ${error.stack}`);
            console.log('=== End: fetchInvoiceDetails with error ===');
            return null;
        } finally {
            setLoading(false);
        }
    };

    const handleApprovePayment = async () => {
        try {
            setLoading(true);
            if (!selectedPayment?._id) {
                throw new Error('No payment selected for approval');
            }

            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `${approveCashPayment}/${facilityId}/${selectedPayment._id}`,
                'POST',
                { comments }
            );

            if (response.success) {
                // Check if this payment resulted in an overpayment
                if (response.data?.reconciliationStatus === 'Overpaid') {
                    // Set the selected payment with the updated data
                    setSelectedPayment(response.data);

                    // Calculate overpayment amount from reconciliation details
                    const overpayAmount = response.data.reconciliationDetails?.overpayAmount || 0;
                    setOverpaymentAmount(overpayAmount);
                    setRemainingOverpayment(overpayAmount);

                    // Fetch unpaid invoices for this customer
                    await fetchUnpaidInvoices(response.data.client.clientId);

                    // Show the overpayment dialog
                    setOverpaymentDialogVisible(true);
                } else {
                    await fetchCashPayments();
                    toastify('Payment approved successfully', 'success');
                    setApproveDialogVisible(false);
                    setSelectedPayment(null);
                    setComments('');
                }
            } else {
                throw new Error(response.message || 'Failed to approve payment');
            }
        } catch (error) {
            console.error('Approve payment error:', error);
            toastify(error.message, 'error');
            setApproveDialogVisible(false);
            setSelectedPayment(null);
            setComments('');
        } finally {
            setLoading(false);
        }
    };

    const fetchUnpaidInvoices = async (clientId) => {
        console.log('=== Start: fetchUnpaidInvoices ===');
        console.log(`Fetching unpaid invoices for clientId: ${clientId}`);

        try {
            setLoadingInvoices(true);

            if (!clientId) {
                console.error('Error: Missing clientId parameter');
                toastify('Client ID is required', 'error');
                setUnpaidInvoices([]);
                return;
            }

            const facilityId = await getItem('selectedFacilityId');
            console.log(`facilityId from localStorage: ${facilityId}`);

            if (!facilityId) {
                console.error('Error: Missing facilityId');
                toastify('Facility ID is required', 'error');
                setUnpaidInvoices([]);
                return;
            }

            const apiUrl = `${getUnpaidInvoices}/${facilityId}/${clientId}`;
            console.log(`Making GET request to: ${apiUrl}`);

            const response = await makeRequest2(apiUrl, 'GET');

            console.log(`Response received - success: ${response?.success}`);
            console.log(`Full response structure: ${JSON.stringify(response)}`);

            // Ensure we're properly parsing the response structure
            let invoicesData = [];

            if (response && response.success) {
                // Check where the actual array data is in the response
                if (Array.isArray(response.data)) {
                    console.log('Data is already an array');
                    invoicesData = response.data;
                } else if (response.data && Array.isArray(response.data.data)) {
                    console.log('Data is nested in data.data');
                    invoicesData = response.data.data;
                } else if (response.data && typeof response.data === 'object') {
                    console.log('Data is an object, checking for arrays');
                    // Look for any array property that might contain the invoices
                    const arrayProps = Object.keys(response.data).filter(key =>
                        Array.isArray(response.data[key])
                    );

                    if (arrayProps.length > 0) {
                        console.log(`Found array in property: ${arrayProps[0]}`);
                        invoicesData = response.data[arrayProps[0]];
                    } else {
                        console.log('No arrays found in response data, defaulting to empty array');
                    }
                }

                // Final safety check to guarantee we always have an array
                if (!Array.isArray(invoicesData)) {
                    console.log('Converted non-array response to empty array for safety');
                    invoicesData = [];
                }

                console.log(`Found ${invoicesData.length} unpaid invoices`);
                setUnpaidInvoices(invoicesData);

                if (invoicesData.length === 0) {
                    console.log('No unpaid invoices found');
                    toastify('No unpaid invoices found for this customer', 'info');
                }
            } else {
                console.error(`Error response: ${JSON.stringify(response)}`);
                setUnpaidInvoices([]);
                toastify(response?.message || 'Unable to fetch unpaid invoices', 'error');
            }
        } catch (error) {
            console.error(`Error fetching unpaid invoices: ${error.message}`);
            console.error(`Error stack: ${error.stack}`);

            // Always ensure we set to an empty array if there's an error
            setUnpaidInvoices([]);
            toastify(error.message || 'Error fetching unpaid invoices', 'error');
        } finally {
            setLoadingInvoices(false);
            console.log('=== End: fetchUnpaidInvoices ===');
        }
    };

    const handleRejectPayment = async () => {
        try {
            setLoading(true);
            if (!selectedPayment?._id) {
                throw new Error('No payment selected for rejection');
            }

            if (!comments.trim()) {
                throw new Error('Please provide a reason for rejection');
            }

            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `${rejectCashPayment}/${facilityId}/${selectedPayment._id}`,
                'POST',
                { comments }
            );

            if (response.success) {
                await fetchCashPayments();
                toastify('Payment rejected successfully', 'success');
            } else {
                throw new Error(response.message || 'Failed to reject payment');
            }
        } catch (error) {
            console.error('Reject payment error:', error);
            toastify(error.message, 'error');
        } finally {
            setRejectDialogVisible(false);
            setSelectedPayment(null);
            setComments('');
            setLoading(false);
        }
    };

    const handleVoidPayment = async () => {
        try {
            setLoading(true);
            if (!selectedPayment?._id) {
                throw new Error('No payment selected for voiding');
            }

            if (!comments.trim()) {
                throw new Error('Please provide a reason for voiding');
            }

            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `${voidCashPayment}/${facilityId}/${selectedPayment._id}`,
                'POST',
                { voidReason: comments }
            );

            if (response.success) {
                await fetchCashPayments();
                toastify('Payment voided successfully', 'success');
            } else {
                throw new Error(response.message || 'Failed to void payment');
            }
        } catch (error) {
            console.error('Void payment error:', error);
            toastify(error.message, 'error');
        } finally {
            setVoidDialogVisible(false);
            setSelectedPayment(null);
            setComments('');
            setLoading(false);
        }
    };

    const handleInvoiceSelect = (e, invoice) => {
        console.log(`=== Invoice Selection Change for ${invoice._id} ===`);
        console.log(`Invoice: ${invoice.invoiceNumber}, Checked: ${e.checked}`);
        console.log(`Current remaining overpayment: ${remainingOverpayment}`);

        // Calculate amount to pay for this invoice
        const totalAmount = invoice.totalAmount || 0;
        const amountPaid = invoice.amountPaid || 0;
        const amountToPay = totalAmount - amountPaid;

        console.log(`Invoice details - totalAmount: ${totalAmount}, amountPaid: ${amountPaid}`);
        console.log(`Amount to pay: ${amountToPay}`);

        let updatedSelection;
        if (e.checked) {
            // Check if adding this invoice would exceed the remaining overpayment
            console.log(`Checking if ${amountToPay} exceeds remaining ${remainingOverpayment}`);

            if (amountToPay > remainingOverpayment) {
                console.log(`Warning: Invoice amount exceeds remaining overpayment`);
                toastify('This invoice exceeds the remaining overpayment amount', 'warn');
                return;
            }

            // Add to selection if within budget
            console.log('Adding invoice to selection');
            updatedSelection = [...selectedInvoices, invoice];

            // Reduce remaining overpayment
            const newRemainingAmount = remainingOverpayment - amountToPay;
            console.log(`Reducing remaining overpayment to: ${newRemainingAmount}`);
            setRemainingOverpayment(newRemainingAmount);
        } else {
            // If unchecking, restore the amount to remaining overpayment
            const newRemainingAmount = remainingOverpayment + amountToPay;
            console.log(`Increasing remaining overpayment to: ${newRemainingAmount}`);
            setRemainingOverpayment(newRemainingAmount);

            // Remove from selection
            console.log('Removing invoice from selection');
            updatedSelection = selectedInvoices.filter(item => item._id !== invoice._id);
        }

        console.log(`Updated selection count: ${updatedSelection.length}`);
        console.log(`Updated selection IDs: ${updatedSelection.map(inv => inv._id).join(', ')}`);
        setSelectedInvoices(updatedSelection);
        console.log('=== End: Invoice Selection Change ===');
    };

    const handleApplyOverpayment = async () => {
        console.log('=== Start: handleApplyOverpayment ===');
        try {
            setLoading(true);
            console.log(`Selected invoices count: ${selectedInvoices.length}`);
            console.log(`Selected invoices IDs: ${selectedInvoices.map(inv => inv._id).join(', ')}`);
            console.log(`Overpayment amount: ${overpaymentAmount}`);

            if (selectedInvoices.length === 0) {
                console.log('Error: No invoices selected');
                throw new Error('No invoices selected to apply overpayment');
            }

            console.log('Getting facilityId from localStorage');
            const facilityId = await getItem('selectedFacilityId');
            console.log(`facilityId: ${facilityId}`);

            // Debug the entire selectedPayment object
            console.log('Selected payment full object:', JSON.stringify(selectedPayment));

            // Extract the source invoice ID with better error handling
            let sourceInvoiceId = null;

            // Check all possible locations for the invoice ID
            if (selectedPayment && selectedPayment.invoice) {
                // Check for direct _id
                if (selectedPayment.invoice._id) {
                    sourceInvoiceId = selectedPayment.invoice._id;
                    console.log(`Found source invoice ID in invoice._id: ${sourceInvoiceId}`);
                }
                // Check for invoiceId property
                else if (selectedPayment.invoice.invoiceId) {
                    sourceInvoiceId = selectedPayment.invoice.invoiceId;
                    console.log(`Found source invoice ID in invoice.invoiceId: ${sourceInvoiceId}`);
                }
                // Check for nested data
                else if (selectedPayment.invoice.data && selectedPayment.invoice.data._id) {
                    sourceInvoiceId = selectedPayment.invoice.data._id;
                    console.log(`Found source invoice ID in invoice.data._id: ${sourceInvoiceId}`);
                }
            }

            // If still not found, try to find in the root of selectedPayment
            if (!sourceInvoiceId && selectedPayment._id) {
                sourceInvoiceId = selectedPayment._id;
                console.log(`Using payment ID as fallback: ${sourceInvoiceId}`);
            }

            if (!sourceInvoiceId) {
                console.error('Could not determine source invoice ID from payment data');
                console.log('Selected payment keys:', Object.keys(selectedPayment || {}).join(', '));
                if (selectedPayment && selectedPayment.invoice) {
                    console.log('Invoice keys:', Object.keys(selectedPayment.invoice).join(', '));
                }
                throw new Error('Could not determine source invoice ID');
            }

            console.log(`Using sourceInvoiceId: ${sourceInvoiceId}`);

            // Prepare request payload
            const payload = {
                sourceInvoiceId: sourceInvoiceId,
                selectedInvoices: selectedInvoices.map(inv => inv._id),
                overpaymentAmount: overpaymentAmount
            };
            console.log(`Request payload: ${JSON.stringify(payload)}`);

            // Make the request
            console.log(`Making POST request to: ${applyOverpayment}/${facilityId}`);
            const response = await makeRequest2(
                `${applyOverpayment}/${facilityId}`,
                'POST',
                payload
            );

            console.log(`Response received: ${JSON.stringify(response)}`);

            if (response.success) {
                console.log('Overpayment application successful');

                if (response.data && response.data.results) {
                    console.log('Results summary:');
                    const successCount = response.data.results.filter(r => r.success).length;
                    const failCount = response.data.results.filter(r => !r.success).length;
                    console.log(`- Success: ${successCount}, Failed: ${failCount}`);

                    // Log any failures
                    const failures = response.data.results.filter(r => !r.success);
                    if (failures.length > 0) {
                        console.log('Failed transactions:');
                        failures.forEach(f => {
                            console.log(`- Invoice ${f.invoiceId}: ${f.message}`);
                        });
                    }
                }

                await fetchCashPayments();
                toastify('Overpayment applied successfully', 'success');
            } else {
                console.error(`Error response: ${JSON.stringify(response)}`);
                throw new Error(response.message || 'Failed to apply overpayment');
            }
        } catch (error) {
            console.error(`Apply overpayment error: ${error.message}`);
            console.error(`Error stack: ${error.stack}`);
            toastify(error.message, 'error');
        } finally {
            console.log('Cleaning up state');
            setOverpaymentDialogVisible(false);
            setSelectedPayment(null);
            setSelectedInvoices([]);
            setUnpaidInvoices([]);
            setOverpaymentAmount(0);
            setRemainingOverpayment(0);
            setLoading(false);
            console.log('=== End: handleApplyOverpayment ===');
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-GB');
    };

    const formatCurrency = (amount, currencyCode = 'KES') => {
        return `${currencyCode} ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    };

    const statusTemplate = (rowData) => {
        let statusClass = '';
        switch (rowData.approvalStatus) {
            case 'Approved':
                statusClass = 'bg-success';
                break;
            case 'Rejected':
                statusClass = 'bg-danger';
                break;
            default:
                statusClass = 'bg-warning';
                break;
        }

        return (
            <span className={`badge ${statusClass}`}>
                {rowData.approvalStatus}
            </span>
        );
    };

    const paymentActionTemplate = (rowData) => {
        const isPending = rowData.approvalStatus === 'Pending';
        const isVoided = rowData.isVoided;
        const isApproved = rowData.approvalStatus === 'Approved';
        const isOverpaid = rowData.reconciliationStatus === 'Overpaid';

        // Show overpayment button for approved payments that are marked as overpaid
        const showOverpayButton = isApproved && isOverpaid && !isVoided;

        return (
            <div className="actions">
                {isPending && !isVoided && (
                    <>
                        <Button
                            icon="ti ti-check"
                            className="p-button-rounded p-button-success mr-2"
                            onClick={() => {
                                setSelectedPayment(rowData);
                                setApproveDialogVisible(true);
                            }}
                            tooltip="Approve Payment"
                            disabled={loading}
                        />
                        <Button
                            icon="ti ti-x"
                            className="p-button-rounded p-button-danger mr-2"
                            onClick={() => {
                                setSelectedPayment(rowData);
                                setRejectDialogVisible(true);
                            }}
                            tooltip="Reject Payment"
                            disabled={loading}
                        />
                    </>
                )}

                {showOverpayButton && (
                    <Button
                        icon="ti ti-exchange"
                        className="p-button-rounded p-button-info mr-2"
                        onClick={async () => {
                            try {
                                console.log('=== Start: Handle Overpayment Button Click ===');
                                setLoading(true);

                                // First, save the payment data
                                console.log('Setting selected payment from row data:', rowData);
                                setSelectedPayment(rowData);

                                // Get the full invoice details since the cash payment only has minimal info
                                const invoiceId = rowData.invoice?.invoiceId;
                                const accountNumber = rowData.invoice?.accountNumber;

                                console.log(`Attempting to fetch invoice details - invoiceId: ${invoiceId}, accountNumber: ${accountNumber}`);

                                // Fetch the full invoice to get balanceBroughtForward and other details
                                const fullInvoice = await fetchInvoiceDetails(invoiceId, accountNumber);

                                if (fullInvoice) {
                                    console.log('Full invoice retrieved:', fullInvoice);

                                    // Check if the invoice has a negative balanceBroughtForward (required by backend)
                                    if (fullInvoice.balanceBroughtForward >= 0 && !(fullInvoice.data && fullInvoice.data.balanceBroughtForward < 0)) {
                                        console.warn('Invoice does not have a negative balanceBroughtForward, which is required for overpayment application');
                                        console.log(`balanceBroughtForward: ${fullInvoice.balanceBroughtForward}`);

                                        // Check if this is a payment with overpayment status but no negative balance
                                        if (rowData.reconciliationStatus === 'Overpaid' && rowData.reconciliationDetails?.overpayAmount > 0) {
                                            // We need to find the invoice that actually has the negative balance
                                            toastify("Fetching invoices with credit balance... This may take a moment.", "info");

                                            try {
                                                // Call your API to find invoices with negative balance for this client
                                                const facilityId = await getItem('selectedFacilityId');
                                                const response = await makeRequest2(
                                                    `${get_credit_invoices}/${facilityId}/${rowData.client.clientId}`,
                                                    'GET'
                                                );

                                                if (response.success && response.data && response.data.length > 0) {
                                                    // Use the first invoice with a negative balance
                                                    const creditInvoice = response.data[0];
                                                    console.log('Found credit invoice:', creditInvoice);

                                                    // Use this credit invoice as our source
                                                    const enhancedPayment = {
                                                        ...rowData,
                                                        invoice: creditInvoice
                                                    };

                                                    setSelectedPayment(enhancedPayment);
                                                    setOverpaymentAmount(rowData.reconciliationDetails.overpayAmount);
                                                    setRemainingOverpayment(rowData.reconciliationDetails.overpayAmount);

                                                    // Continue with unpaid invoices
                                                    await fetchUnpaidInvoices(rowData.client.clientId);
                                                    setOverpaymentDialogVisible(true);
                                                    return;
                                                } else {
                                                    // If we can't find an invoice with credit, show a fallback message
                                                    console.error('No invoices with credit balance found');
                                                    toastify('This payment shows an overpayment, but no corresponding invoice with a credit balance was found. Please contact your administrator.', 'error');
                                                    return;
                                                }
                                            } catch (error) {
                                                console.error('Error finding credit invoices:', error);
                                                toastify('Unable to locate invoices with credit balance', 'error');
                                                return;
                                            }
                                        } else {
                                            toastify('This invoice does not have a credit balance to apply to other invoices', 'error');
                                            return;
                                        }
                                    }

                                    // Use appropriate overpayment amount
                                    let overpayAmount = 0;

                                    if (fullInvoice.balanceBroughtForward < 0) {
                                        // If the invoice has a negative balance brought forward, use that as the overpayment
                                        overpayAmount = Math.abs(fullInvoice.balanceBroughtForward);
                                        console.log(`Using negative balanceBroughtForward as overpayment: ${overpayAmount}`);
                                    }
                                    else if (rowData.reconciliationDetails?.overpayAmount) {
                                        // If payment has reconciliation details with overpayment, use that
                                        overpayAmount = rowData.reconciliationDetails.overpayAmount;
                                        console.log(`Using overpayAmount from reconciliationDetails: ${overpayAmount}`);
                                    }
                                    else if (fullInvoice.overpay) {
                                        // If invoice has overpay field, use that
                                        overpayAmount = fullInvoice.overpay;
                                        console.log(`Using overpay from invoice: ${overpayAmount}`);
                                    }
                                    else {
                                        console.log('No overpayment amount found in expected fields, using default');
                                        overpayAmount = 0;
                                    }

                                    if (overpayAmount <= 0) {
                                        toastify('No overpayment amount available to apply', 'error');
                                        return;
                                    }

                                    console.log(`Setting overpayment amount: ${overpayAmount}`);
                                    setOverpaymentAmount(overpayAmount);
                                    setRemainingOverpayment(overpayAmount);

                                    // Create a merged payment object with the full invoice data
                                    const enhancedPayment = {
                                        ...rowData,
                                        invoice: fullInvoice
                                    };

                                    console.log('Setting enhanced payment with full invoice data');
                                    setSelectedPayment(enhancedPayment);

                                    // Fetch unpaid invoices for this customer
                                    await fetchUnpaidInvoices(rowData.client.clientId);

                                    // Show the dialog
                                    setOverpaymentDialogVisible(true);
                                } else {
                                    console.error('Failed to fetch full invoice details');
                                    toastify('Could not fetch full invoice details', 'error');
                                }
                            } catch (error) {
                                console.error('Error preparing overpayment dialog:', error);
                                toastify('Error preparing overpayment application: ' + error.message, 'error');
                            } finally {
                                setLoading(false);
                                console.log('=== End: Handle Overpayment Button Click ===');
                            }
                        }}
                        tooltip="Apply Overpayment"
                        disabled={loading}
                    />
                )}

                {!isVoided && (
                    <Button
                        icon="ti ti-trash"
                        className="p-button-rounded p-button-warning"
                        onClick={() => {
                            setSelectedPayment(rowData);
                            setVoidDialogVisible(true);
                        }}
                        tooltip="Void Payment"
                        disabled={loading}
                    />
                )}
            </div>
        );
    };

    return (
        <Layout>
            <div className="page-header">
                <div className="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/app/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="">Finance</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="">Facility Payments</Link>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="card">
                <div className="card-body py-0">
                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeIndex === 0 ? 'active' : ''}`}
                                onClick={() => setActiveIndex(0)}
                                role="tab"
                            >
                                <i className="fa fa-money-bill mx-2"></i>
                                Cash Payments
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeIndex === 1 ? 'active' : ''}`}
                                onClick={() => setActiveIndex(1)}
                                role="tab"
                            >
                                <i className="fa fa-university mx-2"></i>
                                Bank Payments
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="tab-content">
                {/* Cash Payments Tab */}
                <div className={`tab-pane fade ${activeIndex === 0 ? 'show active' : ''}`}>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Cash Payments</h5>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <div className="card-body">
                                    {/* Search input outside DataTable but inside card */}
                                    <div className="mb-3">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText
                                                value={globalFilter}
                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                                placeholder="Search payments..."
                                                className="form-control"
                                                style={{ width: '250px' }}
                                            />
                                        </span>
                                    </div>

                                    <DataTable
                                        value={cashPayments}
                                        paginator
                                        rows={10}
                                        stripedRows
                                        emptyMessage="No cash payments found"
                                        loading={loading}
                                        globalFilter={globalFilter}
                                        filterDelay={300}
                                    >
                                        <Column field="paymentReference" header="Payment Ref" />
                                        <Column field="invoice.invoiceNumber" header="Invoice Number" />
                                        <Column
                                            field="client.firstName"
                                            header="Client"
                                            body={(rowData) => `${rowData.client.firstName} ${rowData.client.lastName}`}
                                        />
                                        <Column
                                            field="paymentAmount"
                                            header="Amount"
                                            body={(rowData) => formatCurrency(rowData.paymentAmount, rowData.currency.code)}
                                        />
                                        <Column field="receiptNumber" header="Receipt No." />
                                        <Column
                                            field="paymentDate"
                                            header="Date"
                                            body={(rowData) => formatDate(rowData.paymentDate)}
                                        />
                                        <Column
                                            field="approvalStatus"
                                            header="Status"
                                            body={statusTemplate}
                                        />
                                        <Column header="Actions" body={paymentActionTemplate} style={{ width: '200px' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bank Payments Tab */}
                <div className={`tab-pane fade ${activeIndex === 1 ? 'show active' : ''}`}>
                    {/* Bank payments content would go here */}
                </div>
            </div>

            {/* Approve Payment Dialog */}
            <Dialog
                header="Approve Payment"
                visible={approveDialogVisible}
                onHide={() => {
                    setApproveDialogVisible(false);
                    setComments('');
                }}
                className="w-50"
            >
                <div className="p-3">
                    <div className="mb-4">
                        <label className="form-label">Comments (Optional)</label>
                        <InputTextarea
                            className="form-control"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            rows={4}
                            autoResize
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => {
                                setApproveDialogVisible(false);
                                setComments('');
                            }}
                            style={{ marginRight: '10px' }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleApprovePayment}
                            disabled={loading}
                        >
                            Approve Payment
                        </button>
                    </div>
                </div>
            </Dialog>

            {/* Reject Payment Dialog */}
            <Dialog
                header="Reject Payment"
                visible={rejectDialogVisible}
                onHide={() => {
                    setRejectDialogVisible(false);
                    setComments('');
                }}
                className="w-50"
            >
                <div className="p-3">
                    <div className="mb-4">
                        <label className="form-label">Reason for Rejection <span className="text-danger">*</span></label>
                        <InputTextarea
                            className="form-control"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            rows={4}
                            autoResize
                            required
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => {
                                setRejectDialogVisible(false);
                                setComments('');
                            }}
                            style={{ marginRight: '10px' }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleRejectPayment}
                            disabled={loading || !comments.trim()}
                        >
                            Reject Payment
                        </button>
                    </div>
                </div>
            </Dialog>

            {/* Void Payment Dialog */}
            <Dialog
                header="Void Payment"
                visible={voidDialogVisible}
                onHide={() => {
                    setVoidDialogVisible(false);
                    setComments('');
                }}
                className="w-50"
            >
                <div className="p-3">
                    <div className="mb-4">
                        <label className="form-label">Reason for Voiding <span className="text-danger">*</span></label>
                        <InputTextarea
                            className="form-control"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            rows={4}
                            autoResize
                            required
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => {
                                setVoidDialogVisible(false);
                                setComments('');
                            }}
                            style={{ marginRight: '10px' }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={handleVoidPayment}
                            disabled={loading || !comments.trim()}
                        >
                            Void Payment
                        </button>
                    </div>
                </div>
            </Dialog>

            {/* Overpayment Dialog */}
            <Dialog
                header="Apply Overpayment to Unpaid Invoices"
                visible={overpaymentDialogVisible}
                onHide={() => {
                    setOverpaymentDialogVisible(false);
                    setSelectedInvoices([]);
                    setUnpaidInvoices([]);
                    setOverpaymentAmount(0);
                    setRemainingOverpayment(0);
                }}
                className="w-75"
            >
                <div className="p-3">
                    {loadingInvoices ? (
                        <div className="text-center">
                            <span className="spinner-border text-primary"></span>
                            <p className="mt-2">Loading invoices...</p>
                        </div>
                    ) : (
                        <>
                            <div className="mb-4">
                                <p>
                                    <strong>Client:</strong> {selectedPayment?.client?.firstName} {selectedPayment?.client?.lastName}
                                </p>
                                <p>
                                    <strong>Overpayment Amount:</strong> {formatCurrency(overpaymentAmount, selectedPayment?.currency?.code || 'USD')}
                                </p>
                                <p>
                                    <strong>Remaining Amount:</strong> {formatCurrency(remainingOverpayment, selectedPayment?.currency?.code || 'USD')}
                                </p>
                            </div>

                            {/* Replace the existing unpaid invoices rendering with this version */}
                            <div className="mb-4">
                                <h6>Select Invoices to Apply Overpayment:</h6>

                                {loadingInvoices ? (
                                    <div className="text-center">
                                        <span className="spinner-border text-primary"></span>
                                        <p className="mt-2">Loading invoices...</p>
                                    </div>
                                ) : (
                                    <>
                                        {/* Type safety check to ensure unpaidInvoices is always an array */}
                                        {!Array.isArray(unpaidInvoices) ? (
                                            <div className="alert alert-warning">
                                                Error: Invalid invoice data format. Please try again.
                                            </div>
                                        ) : unpaidInvoices.length === 0 ? (
                                            <div className="alert alert-info">
                                                No unpaid invoices found for this client.
                                            </div>
                                        ) : (
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '50px' }}></th>
                                                            <th>Invoice Number</th>
                                                            <th>Issue Date</th>
                                                            <th>Due Date</th>
                                                            <th>Amount</th>
                                                            <th>Paid</th>
                                                            <th>Balance</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {unpaidInvoices.map((invoice) => {
                                                            const amountToPay = (invoice.totalAmount || 0) - (invoice.amountPaid || 0);
                                                            const canSelect = amountToPay <= remainingOverpayment + (selectedInvoices.find(inv => inv._id === invoice._id) ? amountToPay : 0);

                                                            return (
                                                                <tr key={invoice._id || `invoice-${Math.random()}`} className={canSelect ? '' : 'text-muted'}>
                                                                    <td>
                                                                        <Checkbox
                                                                            checked={selectedInvoices.some(inv => inv._id === invoice._id)}
                                                                            onChange={(e) => handleInvoiceSelect(e, invoice)}
                                                                            disabled={!canSelect && !selectedInvoices.some(inv => inv._id === invoice._id)}
                                                                        />
                                                                    </td>
                                                                    <td>{invoice.invoiceNumber || 'N/A'}</td>
                                                                    <td>{formatDate(invoice.issueDate) || 'N/A'}</td>
                                                                    <td>{formatDate(invoice.dueDate) || 'N/A'}</td>
                                                                    <td>{formatCurrency(invoice.totalAmount || 0, invoice.currency?.code)}</td>
                                                                    <td>{formatCurrency(invoice.amountPaid || 0, invoice.currency?.code)}</td>
                                                                    <td>{formatCurrency(amountToPay, invoice.currency?.code)}</td>
                                                                    <td>
                                                                        <span className={`badge ${invoice.status === 'Overdue' ? 'bg-danger' : 'bg-warning'}`}>
                                                                            {invoice.status || 'Unknown'}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-2"
                                    onClick={() => {
                                        setOverpaymentDialogVisible(false);
                                        setSelectedInvoices([]);
                                        setUnpaidInvoices([]);
                                        setOverpaymentAmount(0);
                                        setRemainingOverpayment(0);
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleApplyOverpayment}
                                    disabled={loading || selectedInvoices.length === 0}
                                >
                                    Apply Overpayment
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </Dialog>
        </Layout>
    );
}

export default FacilityPayments;