// TaxManagementTab.js
import React, { useState, useEffect } from "react";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import Select from "react-select";
import { countries } from "countries-list";
import { Button } from "primereact/button";
import { addTaxRate, getTaxRates, deleteTaxRate } from "../../../utils/urls";

const TaxManagementTab = ({ facilityId }) => {
  const [taxRate, setTaxRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [country, setCountry] = useState(null);
  const [taxType, setTaxType] = useState("");
  const [fetchedTaxRates, setFetchedTaxRates] = useState([]);
  const [editingTax, setEditingTax] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [taxToDelete, setTaxToDelete] = useState(null);

  const TAX_TYPES = [
    { value: "rental_income", label: "Rental Income Tax" },
    { value: "capital_gains", label: "Capital Gains Tax (CGT)" },
    { value: "vat", label: "Value Added Tax (VAT)" },
    { value: "land_rates", label: "Land Rates" },
    { value: "land_rent", label: "Land Rent" },
    { value: "withholding", label: "Withholding Tax on Rent (WHT)" },
  ];

  const countryList = Object.keys(countries).map((code) => ({
    label: countries[code].name,
    value: code,
    currency: countries[code].currency,
  }));

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    setCurrency(selectedOption ? selectedOption.currency[0] : "");
  };

  const fetchTaxRates = async () => {
    try {
      const response = await makeRequest2(
        `${getTaxRates}/${facilityId}`,
        "GET"
      );
      if (response.success && response.data) {
        const taxData = response.data.data || response.data;
        const taxRatesArray = Array.isArray(taxData) ? taxData : [taxData];
        setFetchedTaxRates(taxRatesArray);
      }
    } catch (error) {
      console.error("Error fetching tax rates:", error);
      toastify("Error fetching tax rates", "error");
    }
  };

  const handleTaxRateSubmit = async () => {
    if (!taxRate || !country || !currency || !taxType) {
      toastify("Please enter all required fields", "error");
      return;
    }

    try {
      const data = {
        taxRate: parseFloat(taxRate),
        currency: currency,
        country: country.value,
        taxType: taxType,
      };

      let response;
      if (editingTax) {
        response = await makeRequest2(
          `${addTaxRate}/${facilityId}/${editingTax._id}`,
          "PUT",
          data
        );
      } else {
        response = await makeRequest2(
          `${addTaxRate}/${facilityId}`,
          "POST",
          data
        );
      }

      // Special handling for 201 status in error message
      if (response?.error?.includes("201")) {
        toastify(
          editingTax
            ? "Tax rate updated successfully"
            : "Tax rate added successfully",
          "success"
        );
        await fetchTaxRates();
        resetForm();
      } else if (response && response.success === true) {
        toastify(
          editingTax
            ? "Tax rate updated successfully"
            : "Tax rate added successfully",
          "success"
        );
        await fetchTaxRates();
        resetForm();
      } else {
        const errorMessage =
          response?.message || response?.error || "Operation failed";
        if (!errorMessage.includes("201")) {
          // Only show error if it's not a 201 status
          toastify(errorMessage, "error");
        }
        await fetchTaxRates();
      }
    } catch (error) {
      console.error("Submit error:", error);
      toastify(error.message || "Error submitting tax rate", "error");
      await fetchTaxRates();
    }
  };

  const handleEdit = (tax) => {
    setEditingTax(tax);
    setTaxRate(tax.taxRate.toString());
    setTaxType(tax.taxType);

    const countryData = {
      value: tax.country,
      label: countries[tax.country]?.name || tax.country,
      currency: countries[tax.country]?.currency || [tax.currency],
    };
    setCountry(countryData);
    setCurrency(tax.currency);
  };

  const handleDelete = async (tax) => {
    try {
      const response = await makeRequest2(
        `${deleteTaxRate}/${facilityId}/${tax._id}`,
        "DELETE"
      );

      if (response.success) {
        toastify("Tax rate deleted successfully", "success");
        await fetchTaxRates();
      } else {
        toastify(response.message || "Failed to delete tax rate", "error");
      }
    } catch (error) {
      console.error("Delete error:", error);
      toastify("Error deleting tax rate", "error");
    }
  };

  const resetForm = () => {
    setTaxRate("");
    setTaxType("");
    setCountry(null);
    setCurrency("");
    setEditingTax(null);
  };

  useEffect(() => {
    if (facilityId) {
      fetchTaxRates();
    }
  }, [facilityId]);

  return (
    <div className="row p-3">
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h5>{editingTax ? "Update" : "Add"} Tax Rate</h5>

            <div className="mt-2">
              <label>Tax Type:</label>
              <select
                className="form-control mt-2"
                value={taxType}
                onChange={(e) => setTaxType(e.target.value)}
                required
              >
                <option value="">Select Tax Type</option>
                {TAX_TYPES.map((tax) => (
                  <option key={tax.value} value={tax.value}>
                    {tax.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-2">
              <label>Tax Rate (%):</label>
              <input
                required
                className="form-control mt-2"
                type="number"
                value={taxRate}
                onChange={(e) => setTaxRate(e.target.value)}
                placeholder="Enter tax rate"
              />
            </div>

            <div className="mt-2">
              <label>Country:</label>
              <Select
                required
                className="mt-2"
                options={countryList}
                value={country}
                onChange={handleCountryChange}
                placeholder="Select a country"
              />
            </div>

            <div className="mt-2">
              <label>Currency:</label>
              <input
                required
                className="form-control mt-2"
                type="text"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                placeholder="Currency will be auto-filled"
                disabled
              />
            </div>

            <div className="mt-4" style={{ textAlign: "right" }}>
              {editingTax && (
                <button
                  className="btn btn-secondary me-2"
                  onClick={resetForm}
                >
                  Cancel
                </button>
              )}
              <button
                className="btn btn-primary"
                onClick={handleTaxRateSubmit}
              >
                {editingTax ? "Update" : "Add"} Tax Rate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h5 className="mb-4">Current Tax Rates</h5>
            <div className="table-responsive">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">TAX TYPE</th>
                    <th scope="col">RATE</th>
                    <th scope="col">CURRENCY</th>
                    <th scope="col" className="text-end">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchedTaxRates.length > 0 ? (
                    fetchedTaxRates.map((tax) => (
                      <tr key={tax._id}>
                        <td>
                          {TAX_TYPES.find(
                            (t) => t.value === tax.taxType
                          )?.label || tax.taxType}
                        </td>
                        <td>{tax.taxRate}%</td>
                        <td>{tax.currency}</td>
                        <td className="text-end">
                          <Button
                            icon="ti ti-pencil"
                            className="p-button-rounded p-button-warning me-2"
                            onClick={() => handleEdit(tax)}
                            tooltip="Edit"
                          />
                          <Button
                            icon="ti ti-trash"
                            className="p-button-rounded p-button-danger"
                            onClick={() => handleDelete(tax)}
                            tooltip="Delete"
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        <div className="text-muted">
                          <i
                            className="ti ti-inbox mb-2"
                            style={{ fontSize: "24px" }}
                          ></i>
                          <p className="mb-0">No tax rates available</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxManagementTab;