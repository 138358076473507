import React, { useState, useEffect } from 'react'
import Layout from '../../component/layout'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { GuardRegistrationURL } from '../../../../utils/urls';
import { getEntriesAndExitsForFacility } from '../../../../utils/urls';

const RegisterGuard = () => {
    const selectedFacilityId = getItem('selectedFacilityId');
    const facilityId = selectedFacilityId ? selectedFacilityId : null;

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [entryPoint, setEntryPoint] = useState("");
    const [startTime, setStartTime] = useState("");
    const [email,setEmail] = useState('')
    const [endTime, setEndTime] = useState("");
    const [selectedEntryPoints, setSelectedEntryPoints] = useState([])

    const [entryPoints, setEntryPoints] = useState([]);


    const fetchEntriesAndExits = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                let filter = response.data.filter((x) => {
                    return x.disabled === false
                })
                setEntryPoints(filter); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };


    const handleGuardSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const guardData = {
                firstName,
                lastName,
                phoneNumber,
                email,
                selectedEntryPoints,
                startTime,
                endTime
            };


            if (!guardData.firstName) throw new Error('First name is required.');
            if (!guardData.phoneNumber) throw new Error('Phone number is required.');
            if (guardData.selectedEntryPoints.length === 0) throw new Error('Entry points is required.');
            if (!guardData.startTime) throw new Error('Start time is required.');
            if (!guardData.endTime) throw new Error('Start time is required.');
            if (!guardData.lastName) throw new Error('Last name is required.');


            const response = await makeRequest2(GuardRegistrationURL + '/' + facilityId, 'POST', guardData);


            if (response.success) {
                toastify('Guard added successfully', 'success');
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setSelectedEntryPoints([])
                setStartTime('');
                setEndTime('');
                navigate('/facility/visitor_access_management/guard_list')
            }
            else {
                throw new Error(response.error);
            }
        }
        catch (err) {
           
           toastify(err.message, 'error')
        }
    }

    const handleGuardClear = () => {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEntryPoint('');
        setStartTime('');
        setEndTime('');
    };

    useEffect(() => {
        fetchEntriesAndExits();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Guard Registration</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h5>Guard Registration</h5>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">

                                        <div className="card-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="card">

                                                        <div className="card-body">
                                                            <div className='container'>
                                                                <div className='row'>
                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">First Name<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                value={firstName}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setFirstName(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                value={lastName}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setLastName(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Mobile Number<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                placeholder="e.g. +254 712 345 678"
                                                                                value={phoneNumber}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setPhoneNumber(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Email</label>
                                                                            <input
                                                                                type="email"
                                                                                className="mb-3 form-control"
                                                                                placeholder=""
                                                                                value={email}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setEmail(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3 pt-4">

                                                                            <Autocomplete
                                                                                multiple // Enable multiple select
                                                                                options={entryPoints}
                                                                                getOptionLabel={(option) => option.name} // Define how each option is displayed
                                                                                value={selectedEntryPoints}
                                                                                onChange={(event, newValue) => setSelectedEntryPoints(newValue)} // Handle selection
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} label="Select Entry / Exit"
                                                                                        value={entryPoints}
                                                                                        onChange={(e) => {
                                                                                            const value = e.target.value;
                                                                                            setSelectedEntryPoints(value)
                                                                                        }}
                                                                                        variant="outlined" />
                                                                                )}
                                                                                renderTags={(value, getTagProps) =>
                                                                                    value.map((option, index) => (
                                                                                        <Chip
                                                                                            key={option._id}
                                                                                            label={option.name}
                                                                                            {...getTagProps({ index })}
                                                                                        />
                                                                                    ))
                                                                                } // Display selected units as chips
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Start Time<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="time"
                                                                                className="mb-3 form-control"
                                                                                value={startTime}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setStartTime(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">End Time<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="time"
                                                                                className="mb-3 form-control"
                                                                                value={endTime}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setEndTime(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="text-end">
                                                                <button type="button" className="btn btn-outline-secondary" onClick={handleGuardClear}>Clear</button>
                                                                &nbsp;
                                                                <button type="button" className="btn btn-primary" onClick={handleGuardSubmit}>Submit</button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >
    )
}

export default RegisterGuard