
import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { Chip } from 'primereact/chip';
import Layout from '../component/layout';
import { getItem } from '../../../utils/localStorage';
import { getFacilityUnits } from '../../../utils/urls';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';

const BookingManager = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedUnit = location.state?.selectedUnit;

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [newAmenity, setNewAmenity] = useState('');
  const [newRule, setNewRule] = useState('');
  const [listingDetails, setListingDetails] = useState({
    title: '',
    description: '',
    basePrice: 0,
    maxGuests: 1,
    amenities: [],
    houseRules: [],
    checkIn: {
      date: new Date(),
      time: '14:00'
    },
    checkOut: {
      date: new Date(),
      time: '11:00'
    }
  });

  const [selectedPlatforms, setSelectedPlatforms] = useState({
    'booking.com': false,
    'airbnb': false,
    'trip.com': false
  });

  useEffect(() => {
    if (!selectedUnit) {
      toastify('error', 'No unit selected');
      navigate('/facility/booking_management/unit_listing');
    } else {
      setListingDetails(prev => ({
        ...prev,
        title: selectedUnit.name,
        basePrice: selectedUnit.basePrice || 0,
        maxGuests: selectedUnit.maxOccupancy || 2
      }));
    }
  }, [selectedUnit, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setListingDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePlatformToggle = (platform) => {
    setSelectedPlatforms(prev => ({
      ...prev,
      [platform]: !prev[platform]
    }));
  };

  const handleAddAmenity = (e) => {
    e.preventDefault();
    if (newAmenity.trim()) {
      setListingDetails(prev => ({
        ...prev,
        amenities: [...prev.amenities, newAmenity.trim()]
      }));
      setNewAmenity('');
    }
  };

  const handleRemoveAmenity = (index) => {
    setListingDetails(prev => ({
      ...prev,
      amenities: prev.amenities.filter((_, i) => i !== index)
    }));
  };

  const handleAddRule = (e) => {
    e.preventDefault();
    if (newRule.trim()) {
      setListingDetails(prev => ({
        ...prev,
        houseRules: [...prev.houseRules, newRule.trim()]
      }));
      setNewRule('');
    }
  };

  const handleRemoveRule = (index) => {
    setListingDetails(prev => ({
      ...prev,
      houseRules: prev.houseRules.filter((_, i) => i !== index)
    }));
  };

  const onImageUpload = async (event) => {
    const files = event.files;
    const newImages = [];

    for (let file of files) {
      try {
        const reader = new FileReader();
        const base64 = await new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });

        newImages.push({
          name: file.name,
          type: file.type,
          data: base64
        });
      } catch (error) {
        console.error('Error processing image:', error);
      }
    }

    setImages([...images, ...newImages]);
    event.options.clear();
  };

  const handleListUnit = async () => {
    try {
      setLoading(true);

      if (!Object.values(selectedPlatforms).some(Boolean)) {
        toastify('error', 'Please select at least one booking platform');
        return;
      }

      const platforms = Object.entries(selectedPlatforms)
        .filter(([_, isSelected]) => isSelected)
        .map(([platform]) => platform);

      const listingData = {
        unitId: selectedUnit.id,
        platforms,
        images,
        ...listingDetails,
        checkInTime: `${listingDetails.checkIn.time}`,
        checkOutTime: `${listingDetails.checkOut.time}`,
      };

      const response = await makeRequest2('POST', `${getFacilityUnits}/${selectedUnit.id}/listings`, {
        body: JSON.stringify(listingData)
      });

      if (response.success) {
        toastify('success', 'Unit successfully listed on selected platforms');
        navigate('/facility/booking_management/unit_listing');
      } else {
        throw new Error(response.message || 'Failed to list unit');
      }
    } catch (error) {
      toastify('error', error.message || 'Failed to list unit on platforms');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="card">
        <div className="card-header">
          <Link to={"#"} onClick={() => navigate('/facility/booking_management/unit_listing')} ><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            {/* Unit Details Column */}
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h6 className="mb-0">Unit Details</h6>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label className="form-label">Unit Name</label>
                    <div className="text-muted">{selectedUnit?.name}</div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Type</label>
                    <div className="text-muted">{selectedUnit?.unitType}</div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Floor</label>
                    <div className="text-muted">{selectedUnit?.floorUnitNo}</div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Size</label>
                    <div className="text-muted">{selectedUnit?.grossArea?.toLocaleString()} sq ft</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Listing Details Column */}
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <h6 className="mb-0">Listing Details</h6>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <label className="form-label">Select Platforms to List On</label>
                      <div className="d-flex gap-4">
                        <div className="d-flex align-items-center gap-2">
                          <Checkbox
                            checked={selectedPlatforms['booking.com']}
                            onChange={() => handlePlatformToggle('booking.com')}
                          />
                          <label>Booking.com</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <Checkbox
                            checked={selectedPlatforms['trip.com']}
                            onChange={() => handlePlatformToggle('trip.com')}
                          />
                          <label>Trip.com</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Listing Title</label>
                      <InputText
                        name="title"
                        value={listingDetails.title}
                        onChange={handleInputChange}
                        className="w-100"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Description</label>
                      <InputTextarea
                        name="description"
                        value={listingDetails.description}
                        onChange={handleInputChange}
                        rows={3}
                        className="w-100"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Maximum Guests</label>
                      <InputNumber
                        name="maxGuests"
                        value={listingDetails.maxGuests}
                        onValueChange={(e) => handleInputChange({ target: { name: 'maxGuests', value: e.value } })}
                        min={1}
                        className="w-100"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Base Price Per Night</label>
                      <InputNumber
                        name="basePrice"
                        value={listingDetails.basePrice}
                        onValueChange={(e) => handleInputChange({ target: { name: 'basePrice', value: e.value } })}
                        mode="currency"
                        currency="KES"
                        className="w-100"
                      />
                    </div>

                    {/* Amenities Section */}
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Amenities</label>
                      <form onSubmit={handleAddAmenity} className="mb-2">
                        <div className="d-flex gap-2">
                          <InputText
                            value={newAmenity}
                            onChange={(e) => setNewAmenity(e.target.value)}
                            placeholder="Add an amenity (e.g., WiFi, AC)"
                            className="w-100"
                          />
                          <Button
                            type="submit"
                            icon="pi pi-plus"
                            className="p-button-primary"
                          />
                        </div>
                      </form>
                      <div className="d-flex flex-wrap gap-2 mt-2">
                        {listingDetails.amenities.map((amenity, index) => (
                          <Chip
                            key={index}
                            label={amenity}
                            removable
                            onRemove={() => handleRemoveAmenity(index)}
                          />
                        ))}
                      </div>
                    </div>

                    {/* House Rules Section */}
                    <div className="col-md-12 mb-3">
                      <label className="form-label">House Rules</label>
                      <form onSubmit={handleAddRule} className="mb-2">
                        <div className="d-flex gap-2">
                          <InputText
                            value={newRule}
                            onChange={(e) => setNewRule(e.target.value)}
                            placeholder="Add a house rule"
                            className="w-100"
                          />
                          <Button
                            type="submit"
                            icon="pi pi-plus"
                            className="p-button-primary"
                          />
                        </div>
                      </form>
                      <div className="d-flex flex-wrap gap-2 mt-2">
                        {listingDetails.houseRules.map((rule, index) => (
                          <Chip
                            key={index}
                            label={rule}
                            removable
                            onRemove={() => handleRemoveRule(index)}
                          />
                        ))}
                      </div>
                    </div>

                    {/* Check-in/out Times */}
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Check-in Time</label>
                      <div className="d-flex gap-2">
                        <Calendar
                          value={listingDetails.checkIn.date}
                          onChange={(e) => setListingDetails(prev => ({
                            ...prev,
                            checkIn: { ...prev.checkIn, date: e.value }
                          }))}
                          showTime
                          showSeconds={false}
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Check-out Time</label>
                      <div className="d-flex gap-2">
                        <Calendar
                          value={listingDetails.checkOut.date}
                          onChange={(e) => setListingDetails(prev => ({
                            ...prev,
                            checkOut: { ...prev.checkOut, date: e.value }
                          }))}
                          showTime
                          showSeconds={false}
                          className="w-100"
                        />
                      </div>
                    </div>

                    {/* Image Upload */}
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Unit Images</label>
                      <FileUpload
                        name="images"
                        url="/api/upload"
                        multiple
                        accept="image/*"
                        maxFileSize={1000000}
                        emptyTemplate={<p className="m-0">Drag and drop images here to upload.</p>}
                        onUpload={onImageUpload}
                        auto
                        customUpload
                        uploadHandler={onImageUpload}
                        className="w-100"
                      />
                      {images.length > 0 && (
                        <>
                          <div className="mt-2">
                            <small className="text-muted">
                              {images.length} image{images.length !== 1 ? 's' : ''} selected
                            </small>
                          </div>
                          <div className="mt-3 d-flex flex-wrap gap-3">
                            {images.map((image, index) => (
                              <div key={index} className="position-relative" style={{ width: '150px' }}>
                                <img
                                  src={image.data}
                                  alt={`Preview ${index + 1}`}
                                  className="img-fluid rounded"
                                  style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                />
                                <Button
                                  icon="pi pi-times"
                                  className="p-button-rounded p-button-danger p-button-sm position-absolute"
                                  onClick={() => {
                                    setImages(images.filter((_, i) => i !== index));
                                  }}
                                  style={{
                                    top: '-10px',
                                    right: '-10px',
                                    width: '24px',
                                    height: '24px'
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                  </div>

                  <div className="d-flex justify-content-end gap-2 mt-4">
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-button-secondary"
                      onClick={() => navigate('/facility/booking_management/unit_listing')}
                    />
                    <Button
                      label="List Unit"
                      icon="pi pi-check"
                      className="p-button-primary"
                      onClick={handleListUnit}
                      loading={loading}
                      disabled={!Object.values(selectedPlatforms).some(Boolean)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingManager;