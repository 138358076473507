import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../component/layout';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { getItem } from '../../../utils/localStorage';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getTicketsURL } from '../../../utils/urls';

const Tickets = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [tickets, setTickets] = useState([]);

    const getTickets = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getTicketsURL}/${facilityId}`, 'GET', {});
    
            if (response.success) {
                // Map the tickets data to include both customer and user info
                const tickets = response.data.tickets.map(ticket => ({
                    ...ticket,
                    fullName: ticket.CustomerInfo?.fullName || ticket.UserInfo?.fullName || 'Unknown',
                    phoneNumber: ticket.CustomerInfo?.phoneNumber || ticket.UserInfo?.phoneNumber || '',
                    email: ticket.CustomerInfo?.email || ticket.UserInfo?.email || '',
                    isStaff: !!ticket.UserInfo // Check if ticket was raised by a staff member
                }));
    
                setTickets(tickets);
                console.log('Tickets:', tickets);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };
    



    const totalTicketsCount = tickets.length;

    const allTickets = tickets;
    const ResolvedTickets = tickets.filter((ticket) => ticket.status === 'resolved');
    const resolvedCount = tickets.filter(ticket => ticket.status === 'resolved').length;
    const openTickets = tickets.filter((ticket) => ticket.status === 'open');
    const openTicketsCount = tickets.filter(ticket => ticket.status === 'open').length;
    const onHoldTickets = tickets.filter((ticket) => ticket.status === 'on hold');
    const onHoldTicketsCount = tickets.filter(ticket => ticket.status === 'on hold').length;
    const ongoingTickets = tickets.filter((ticket) => ticket.status === 'ongoing');
    const ongoingTicketsCount = tickets.filter(ticket => ticket.status === 'ongoing').length;
    const cancelledTickets = tickets.filter((ticket) => ticket.status === 'cancelled');
    const cancelledTicketsCount = tickets.filter(ticket => ticket.status === 'cancelled').length;
    const closedTickets = tickets.filter((ticket) => ticket.status === 'closed');
    const closedTicketsCount = tickets.filter(ticket => ticket.status === 'closed').length;


    const HandeFilter = async (status) => {
        try {
            if (status === 'All') {
                getTickets()
            }
            else {

                const results = await tickets.filter((x) => { return x.status === status })
                setTickets(results);
            }
        }
        catch (error) {
            toastify(error.message, 'error')
        }
    }


    const statusTemplate = (rowData) => {
        const statusStyles = {
            open: { color: '#28a745', backgroundColor: '#e9fbe9' },
            closed: { color: '#007bff', backgroundColor: '#e0f7ff' },
            resolved: { color: '#17a2b8', backgroundColor: '#e0f4f7' },
            cancelled: { color: '#dc3545', backgroundColor: '#fde9e9' },
            ongoing: { color: '#ffc107', backgroundColor: '#fff8e1' },
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.status}
            </span>
        );
    };


    const priorityTemplate = (rowData) => {
        const priorityStyles = {
            low: { color: '#28a745', backgroundColor: '#e9fbe9' },
            medium: { color: '#ffc107', backgroundColor: '#fff8e1' },
            high: { color: '#dc3545', backgroundColor: '#fde9e9' },
        };

        const style = priorityStyles[rowData.priority.toLowerCase()] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1',
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block',
                }}
            >
                {rowData.priority || 'N/A'}
            </span>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <Link
                to={`/facility/ticket_management/view_ticket/${rowData._id}`}
                style={{
                    color: '#007bff',
                    textDecoration: 'none',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                title="View Ticket"
            >
                <i className="ti ti-eye"></i>
            </Link>
        );
    };


    const filterTicketsByNumber = (tickets, searchTerm) => {
        return tickets.filter(ticket =>
            ticket.ticketNumber.toString().includes(searchTerm)
        );
    };

    const handleInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                getTickets();
            } else {
                const results = filterTicketsByNumber(tickets, value);
                setTickets(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };


    useEffect(() => {
        getTickets();
    }, [])

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Tickets</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/ticket_management/tickets"}>View Tickets</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="col-md-3 col-xs-12 mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search tickets with ticket number"
                                        value={searchTerm}
                                        onChange={(e) => { handleInputSearch(e.target.value) }}
                                    />
                                </div>
                                <div className="col-3" >
                                    <div className="btn-group-dropdown" >
                                        <button
                                            className="btn btn-outline-default dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="ti ti-filter"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('All') }}>
                                                All
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('open') }}>
                                                Open
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('on hold') }}>
                                                On Hold
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('ongoing') }}>
                                                Ongoing
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('resolved') }}>
                                                Resolved
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('cancelled') }}>
                                                Cancelled
                                            </Link>
                                            <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('closed') }}>
                                                Closed
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 text-md-end">
                                    <button className="btn btn-primary" onClick={() => navigate('/facility/ticket_management/raise_ticket')}>
                                        Raise Ticket
                                    </button>
                                </div>
                            </div>

                            <ul className="nav nav-tabs invoice-tab border-bottom mb-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane"
                                        aria-selected="true">
                                        <span className="d-flex align-items-center gap-2">All <span
                                            className="avtar rounded-circle bg-light-primary">{totalTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-2" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-2-pane" type="button" role="tab" aria-controls="analytics-tab-2-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Open <span
                                            className="avtar rounded-circle bg-light-success">{openTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-3" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-3-pane" type="button" role="tab" aria-controls="analytics-tab-3-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">On hold <span
                                            className="avtar rounded-circle bg-light-success">{onHoldTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-4" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-4-pane" type="button" role="tab" aria-controls="analytics-tab-4-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Ongoing <span
                                            className="avtar rounded-circle bg-light-warning">{ongoingTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-5" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-5-pane" type="button" role="tab" aria-controls="analytics-tab-5-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Resolved <span
                                            className="avtar rounded-circle bg-light-info">{resolvedCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-6" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-6-pane" type="button" role="tab" aria-controls="analytics-tab-6-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Cancelled <span
                                            className="avtar rounded-circle bg-light-danger">{cancelledTicketsCount}</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-7" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-7-pane" type="button" role="tab" aria-controls="analytics-tab-7-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Closed <span
                                            className="avtar rounded-circle bg-light-danger">{closedTicketsCount}</span></span>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active mt-2" id="analytics-tab-1-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-1" tabindex="0">
                                    <div className="table-responsive">
                                        {/* <DataTable
                                            value={allTickets}
                                            emptyMessage="No tickets found."
                                            sortMode="multiple"
                                            paginator
                                            rows={10}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="ticketNumber" header="Ticket Number" />
                                            <Column field="ticketType" header="Ticket Type" />
                                            <Column field="subject" header="Subject" />
                                            <Column field="priority" header="Priority" body={priorityTemplate} />
                                            <Column
                                                field="fullName"
                                                header="Raised by"
                                                body={(rowData) => (
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span>{rowData.CustomerInfo?.fullName || 'Property Manager'}</span>
                                                        {rowData.ticketSource === 'property_manager' && (
                                                            <span className="badge bg-success">Staff</span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                            <Column field="status" header="Status" body={statusTemplate} />
                                            <Column
                                                field="date"
                                                header="Added On"
                                                body={(rowData) => {
                                                    const date = rowData.date || rowData.createdAt;
                                                    return new Date(date).toISOString().split('T')[0];
                                                }}
                                            />
                                            <Column header="Actions" body={actionTemplate} />
                                        </DataTable> */}
                                        <DataTable
                                            value={allTickets}
                                            emptyMessage="No tickets found."
                                            sortMode="multiple"
                                            paginator
                                            rows={10}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="ticketNumber" header="Ticket Number" />
                                            <Column field="ticketType" header="Ticket Type" />
                                            <Column field="subject" header="Subject" />
                                            <Column field="priority" header="Priority" body={priorityTemplate} />

                                            {/* Updated Raised By Column to Distinguish Between Customer & Staff */}
                                            <Column
                                                field="fullName"
                                                header="Raised by"
                                                body={(rowData) => (
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span>{rowData.fullName}</span>
                                                        {rowData.isStaff && <span className="badge bg-success">Staff</span>}
                                                    </div>
                                                )}
                                            />

                                            <Column field="status" header="Status" body={statusTemplate} />
                                            <Column
                                                field="date"
                                                header="Added On"
                                                body={(rowData) => {
                                                    const date = rowData.date || rowData.createdAt;
                                                    return new Date(date).toISOString().split('T')[0];
                                                }}
                                            />
                                            <Column header="Actions" body={actionTemplate} />
                                        </DataTable>

                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-2-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-2" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={openTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="fullName" header="Raised by"></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                            <Column header="Actions" body={actionTemplate}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-3-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-3" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={onHoldTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="fullName" header="Raised by"></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                            <Column header="Actions" body={actionTemplate}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-4-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-4" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={ongoingTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="fullName" header="Raised by"></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                            <Column header="Actions" body={actionTemplate}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-5-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-5" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable
                                            value={ResolvedTickets}
                                            emptyMessage="No resolved tickets found."
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="fullName" header="Raised by"></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date().toISOString().split('T')[0]} ></Column>
                                            <Column field="date" header="Resolved On" body={(rowData) => new Date().toISOString().split('T')[0]} ></Column>
                                        </DataTable>
                                    </div>
                                </div>

                                <div className="tab-pane fade mt-2" id="analytics-tab-6-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-6" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={cancelledTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="fullName" header="Raised by"></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                            <Column field="date" header="Cancelled On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                        </DataTable>
                                    </div>
                                </div>

                                <div className="tab-pane fade mt-2" id="analytics-tab-7-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-7" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={closedTickets} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketNumber" header="Ticket Number"></Column>
                                            <Column field="ticketType" header="Ticket Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="priority" header="Priority" body={priorityTemplate}></Column>
                                            <Column field="fullName" header="Raised by"></Column>
                                            <Column field="status" header="Status" body={statusTemplate}></Column>
                                            <Column field="date" header="Added On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                            <Column field="date" header="Closed On" body={(rowData) => new Date(rowData.date).toISOString().split('T')[0]} ></Column>
                                        </DataTable>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Tickets