import React, { useState, useEffect } from 'react';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { addSmsSettings } from '../../../utils/urls';
import { Button } from 'primereact/button';

const SMSTab = ({ facilityId }) => {
  const [smsSettings, setSmsSettings] = useState({
    user: '',
    senderId: '',
    apiKey: ''
  });
  const [smsList, setSmsList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (facilityId) {
      fetchSMSSettings();
    }
  }, [facilityId]);

  const fetchSMSSettings = async () => {
    try {
      const response = await makeRequest2(`${addSmsSettings}/${facilityId}`, 'GET');
      if (response.success) {
        setSmsList(Array.isArray(response.data) ? response.data : [response.data].filter(Boolean));
      }
    } catch (error) {
      console.error('Error fetching SMS settings:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${addSmsSettings}/${facilityId}${isEditing ? `/${smsSettings._id}` : ''}`;
      const method = isEditing ? 'PUT' : 'POST';

      const response = await makeRequest2(url, method, smsSettings);

      if (response.success) {
        toastify(`SMS settings ${isEditing ? 'updated' : 'added'} successfully`, 'success');
        fetchSMSSettings();
        resetForm();
      } else {
        toastify(response.message || 'Operation failed', 'error');
      }
    } catch (error) {
      toastify('Error saving SMS settings', 'error');
    }
  };

  const handleEdit = (sms) => {
    setSmsSettings(sms);
    setIsEditing(true);
  };

  const resetForm = () => {
    setSmsSettings({
      user: '',
      senderId: '',
      apiKey: ''
    });
    setIsEditing(false);
  };

  return (
    <div className="row p-3">
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h5>{isEditing ? 'Update' : 'Add'} SMS Settings</h5>
            <form onSubmit={handleSubmit}>
              <div className="form-group mt-3">
                <label>User*</label>
                <input
                  type="text"
                  className="form-control"
                  value={smsSettings.user}
                  onChange={(e) => setSmsSettings({ ...smsSettings, user: e.target.value })}
                  required
                />
              </div>

              <div className="form-group mt-3">
                <label>Sender ID*</label>
                <input
                  type="text"
                  className="form-control"
                  value={smsSettings.senderId}
                  onChange={(e) => setSmsSettings({ ...smsSettings, senderId: e.target.value })}
                  required
                />
              </div>

              <div className="form-group mt-3">
                <label>API Key*</label>
                <input
                  type="password"
                  className="form-control"
                  value={smsSettings.apiKey}
                  onChange={(e) => setSmsSettings({ ...smsSettings, apiKey: e.target.value })}
                  required
                />
              </div>

              <div className="mt-4 text-end">
                {isEditing && (
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={resetForm}
                  >
                    Cancel
                  </button>
                )}
                <button type="submit" className="btn btn-primary">
                  {isEditing ? 'Update' : 'Add'} SMS Settings
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h5>Current SMS Settings</h5>
            <div className="table-responsive">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Sender ID</th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {smsList.length > 0 ? (
                    smsList.map((sms) => (
                      <tr key={sms._id}>
                        <td>{sms.user}</td>
                        <td>{sms.senderId}</td>
                        <td className="text-end">
                          <Button
                            icon="ti ti-pencil"
                            className="p-button-rounded p-button-warning me-2"
                            onClick={() => handleEdit(sms)}
                            tooltip="Edit"
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-4">
                        <div className="text-muted">
                          <i className="ti ti-inbox mb-2" style={{ fontSize: "24px" }}></i>
                          <p className="mb-0">No SMS settings available</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSTab;