import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../component/layout';
import { Button } from 'primereact';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getLevyTypes, addLevy, GET_CURRENCIES_URL, fetchFacilityPaymentDetails } from '../../../../utils/urls';

const INVOICE_DAY_OPTIONS = [
    { value: "5days", label: "5 days to end of month" },
    { value: "3days", label: "3 days to end of month" },
    { value: "1day", label: "1 day to end of month" },
    { value: "endmonth", label: "End of month" }
];

const DUE_DATE_OPTIONS = [
    { value: "1st", label: "1st of next month" },
    { value: "5th", label: "5th of next month" },
    { value: "10th", label: "10th of next month" }
];

const COLLECTION_FREQUENCY_OPTIONS = [
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Semi-annually", label: "Semi-annually" },
    { value: "Annually", label: "Annually" }
];

const LEVY_APPLICANT_OPTIONS = [
    { value: "home owner", label: "Home Owner" },
    { value: "tenant", label: "Tenant" }
];

const NOTIFICATION_TYPES = [
    { value: "SMS", label: "SMS" },
    { value: "EMAIL", label: "Email" }
];

const OVERDUE_REMINDER_OPTIONS = [
    { value: 1, label: "1 day after due date" },
    { value: 3, label: "3 days after due date" },
    { value: 7, label: "7 days after due date" }
];

function AddLevy() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        levyName: '',
        levyType: '',
        amount: '',
        dueDate: '',
        levyApplicant: '',
        collectionFrequency: '',
        invoiceDay: '',
        // New fields added from levy contract
        currency: '',
        mobilePayment: false,
        paymentMethodId: '',
        reminder: {
            type: 'standard',
            module: 'levy',
            time: '09:00',
            invoiceDate: true,
            dueDate: false,
            notificationTypes: ['SMS', 'EMAIL'],
            afterOverdue: {
                enabled: false,
                days: []
            },
            message: ''
        },
        penalty: {
            name: '',
            type: '',
            effectDays: '',
            percentage: '',
            amount: '',
            isActive: true
        }
    });

    // New state variables for the new fields
    const [currencies, setCurrencies] = useState([]);
    const [paymentSettings, setPaymentSettings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [levyTypes, setLevyTypes] = useState([]);

    const handleInputChange = (field, value) => {
        const updateNestedField = (prev, path, newValue) => {
            const keys = path.split('.');
            const updatedState = { ...prev };
            let current = updatedState;

            for (let i = 0; i < keys.length - 1; i++) {
                current = current[keys[i]];
            }

            current[keys[keys.length - 1]] = newValue;
            return updatedState;
        };

        setFormData(prev => {
            // Special handling for specific fields
            if (field === 'reminder.dueDate') {
                return updateNestedField(prev, 'reminder.dueDate', !!value);
            }

            if (field === 'reminder.afterOverdue.enabled') {
                return updateNestedField(prev, 'reminder.afterOverdue', {
                    enabled: !!value,
                    days: !!value ? [] : []
                });
            }

            if (field === 'reminder.afterOverdue.days') {
                return updateNestedField(prev, 'reminder.afterOverdue.days',
                    Array.isArray(value) ? value : [value].filter(Boolean)
                );
            }

            // Handle nested and top-level fields
            if (field.includes('.')) {
                return updateNestedField(prev, field, value);
            }

            // Handle top-level fields
            return {
                ...prev,
                [field]: value
            };
        });
    };

    const handleNotificationTypeChange = (type) => {
        setFormData(prev => {
            const types = prev.reminder.notificationTypes;
            const newTypes = types.includes(type)
                ? types.filter(t => t !== type)
                : [...types, type];

            return {
                ...prev,
                reminder: {
                    ...prev.reminder,
                    notificationTypes: newTypes
                }
            };
        });
    };

    const clearForm = () => {
        setFormData({
            levyName: '',
            levyType: '',
            amount: '',
            dueDate: '',
            levyApplicant: '',
            collectionFrequency: '',
            invoiceDay: '',
            // Clear new fields too
            currency: '',
            mobilePayment: false,
            paymentMethodId: '',
            reminder: {
                type: 'standard',
                module: 'levy',
                time: '09:00',
                invoiceDate: true,
                dueDate: false,
                notificationTypes: ['SMS', 'EMAIL'],
                afterOverdue: {
                    enabled: false,
                    days: []
                },
                message: ''
            },
            penalty: {
                name: '',
                type: '',
                effectDays: '',
                percentage: '',
                amount: '',
                isActive: true
            }
        });
    };

    const fetchLevyTypes = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getLevyTypes + '/' + facilityId, 'GET', {});

            if (response.success) {
                const levyTypesData = Array.isArray(response.data) ? response.data :
                    (response.data?.data ? response.data.data : []);
                setLevyTypes(levyTypesData);
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            console.error('Error fetching levy types:', err);
            toastify(err.message, 'error');
        }
    };

    // Fetch currencies
    const fetchCurrencies = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            const response = await makeRequest2(`${GET_CURRENCIES_URL}/${facilityId}`, "GET");

            if (response.success) {
                let currencyData = [];

                if (Array.isArray(response.data)) {
                    currencyData = response.data;
                } else if (response.data?.currencies && Array.isArray(response.data.currencies)) {
                    currencyData = response.data.currencies;
                } else if (response.data?.data && Array.isArray(response.data.data)) {
                    currencyData = response.data.data;
                } else if (typeof response.data === 'object' && response.data !== null) {
                    currencyData = [response.data];
                }

                const processedCurrencies = currencyData.map(curr => ({
                    _id: curr._id,
                    code: curr.code || 'Unknown',
                    name: curr.name || curr.currencyName || 'Unknown Currency'
                }));

                setCurrencies(processedCurrencies);
            } else {
                throw new Error(response.error || "Failed to fetch currencies");
            }
        } catch (error) {
            console.error('Error fetching currencies:', error);
            toastify(error.message || "Error fetching currencies", "error");
            setCurrencies([]);
        }
    };

    // Fetch payment settings
    const fetchPaymentSettings = async () => {
        setLoading(true);
        try {
            const facilityId = await getItem("selectedFacilityId");
            const url = `${fetchFacilityPaymentDetails}/${facilityId}`;
            const response = await makeRequest2(url, "GET");

            if (response.success && response.data?.length > 0) {
                const formattedData = response.data.map(item => ({
                    shortCode: item.shortCode,
                    module: item.module,
                    _id: item._id
                }));
                setPaymentSettings(formattedData);
            } else {
                setPaymentSettings([]);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            toastify("Error fetching payment details", "error");
            setPaymentSettings([]);
        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        const {
            levyName,
            levyType,
            amount,
            levyApplicant,
            collectionFrequency,
            invoiceDay,
            dueDate,
            reminder,
            penalty,
            mobilePayment,
            paymentMethodId,
            currency
        } = formData;

        // Validate core levy fields
        if (!levyName) throw new Error('Levy name is required');
        if (!levyType) throw new Error('Levy type is required');
        if (!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0)
            throw new Error('Valid amount is required');
        if (!levyApplicant) throw new Error('Levy applicant is required');
        if (!collectionFrequency) throw new Error('Collection frequency is required');
        if (!invoiceDay) throw new Error('Invoice day is required');
        if (!dueDate) throw new Error('Due date is required');

        // Validate new fields
        if (!currency) throw new Error('Currency is required');
        if (mobilePayment && !paymentMethodId) throw new Error('Payment method is required when mobile payment is enabled');

        // Validate reminder settings
        if (!reminder.notificationTypes.length) {
            throw new Error('At least one notification type is required');
        }

        // Validate penalty settings if type is selected
        if (penalty?.type) {
            if (!penalty.name) throw new Error('Penalty name is required');
            if (!penalty.effectDays) throw new Error('Effect days is required');

            if (penalty.type === 'fixed' && !penalty.amount) {
                throw new Error('Amount is required for fixed penalty type');
            }

            if (penalty.type === 'percentage' && !penalty.percentage) {
                throw new Error('Percentage is required for percentage penalty type');
            }
        }

        // Ensure at least one reminder type is enabled
        const hasReminderEnabled = reminder.invoiceDate ||
            reminder.dueDate ||
            (reminder.afterOverdue?.enabled && reminder.afterOverdue.days.length > 0);

        if (!hasReminderEnabled) {
            reminder.invoiceDate = true;
        }

        if (reminder.afterOverdue?.enabled &&
            (!reminder.afterOverdue.days || reminder.afterOverdue.days.length === 0)) {
            throw new Error('Please select an overdue reminder option');
        }

        if (reminder.message && reminder.message.length > 500) {
            throw new Error('Reminder message cannot exceed 500 characters');
        }
    };

    const handleLevySubmit = async () => {
        try {
            setLoading(true);

            validateForm();

            const facilityId = await getItem('selectedFacilityId');

            const reminderPayload = {
                name: `${formData.levyName} Reminder`,
                type: 'standard',
                module: 'levy',
                time: formData.reminder.time || '09:00',
                notificationTypes: formData.reminder.notificationTypes || ['SMS', 'EMAIL'],
                remindOn: {
                    invoiceDate: !!formData.reminder.invoiceDate,
                    dueDate: !!formData.reminder.dueDate,
                    afterOverdue: {
                        enabled: !!formData.reminder.afterOverdue?.enabled,
                        days: formData.reminder.afterOverdue?.days || []
                    }
                },
                message: formData.reminder.message || ''
            };

            const penaltyPayload = formData.penalty.type ? {
                name: formData.penalty.name,
                type: formData.penalty.type,
                effectDays: formData.penalty.effectDays,
                percentage: formData.penalty.type === 'percentage' ? formData.penalty.percentage : null,
                amount: formData.penalty.type === 'fixed' ? formData.penalty.amount : null,
                isActive: formData.penalty.isActive
            } : null;

            const payload = {
                levyName: formData.levyName,
                levyType: formData.levyType,
                amount: formData.amount,
                dueDate: formData.dueDate,
                levyApplicant: formData.levyApplicant,
                collectionFrequency: formData.collectionFrequency,
                invoiceDay: formData.invoiceDay,
                // Include new fields
                currency: formData.currency,
                mobilePayment: formData.mobilePayment,
                paymentMethodId: formData.paymentMethodId,
                reminder: reminderPayload,
                penalty: penaltyPayload
            };

            const response = await makeRequest2(addLevy + '/' + facilityId, 'POST', payload);

            if (response.success) {
                toastify('Levy added successfully with reminder and penalty settings', 'success');
                clearForm();
                navigate("/facility/levy_management");
            } else {
                throw new Error(response.error || 'Failed to add levy');
            }
        } catch (err) {
            toastify(err.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLevyTypes();
        fetchCurrencies();
        fetchPaymentSettings();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/facility/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/facility/levy_management">Levy Management</Link>
                                </li>
                                <li className="breadcrumb-item">Add New Levy</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="#" onClick={() => navigate(-1)}>
                                <span><i className="ti ti-arrow-narrow-left"></i> Back</span>
                            </Link>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h5>Add New Levy</h5>
                        </div>
                        <div className='card-body'>
                            <div className='container'>
                                <div className='row'>
                                    {/* Basic Levy Information */}
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Name<span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.levyName}
                                                onChange={(e) => handleInputChange('levyName', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Type<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={formData.levyType}
                                                onChange={(e) => handleInputChange('levyType', e.target.value)}
                                            >
                                                <option value="">-- Select --</option>
                                                {levyTypes.map((type) => (
                                                    <option key={type._id} value={type._id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Amount<span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={formData.amount}
                                                onChange={(e) => handleInputChange('amount', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* New field: Currency */}
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Currency<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={formData.currency}
                                                onChange={(e) => handleInputChange('currency', e.target.value)}
                                            >
                                                <option value="">-- Select Currency --</option>
                                                {Array.isArray(currencies) && currencies.length > 0 ? (
                                                    currencies.map((curr) => (
                                                        <option key={curr._id} value={curr._id}>
                                                            {curr.name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="" disabled>No currencies available</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    {/* New field: Mobile Payment */}
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Mobile Payment</label>
                                            <div className="d-flex align-items-center">
                                                <i
                                                    className={`ti ${!formData.mobilePayment
                                                        ? "ti-toggle-left text-danger"
                                                        : "ti-toggle-right text-primary"
                                                        }`}
                                                    style={{ fontSize: "34px", cursor: "pointer" }}
                                                    onClick={() => handleInputChange('mobilePayment', !formData.mobilePayment)}
                                                />
                                                <span className="ms-2">{formData.mobilePayment ? "Enabled" : "Disabled"}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* New field: Payment Method (conditional based on mobilePayment) */}
                                    {formData.mobilePayment && (
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label">Payment Method<span className="text-danger">*</span></label>
                                                <select
                                                    className="form-control"
                                                    value={formData.paymentMethodId}
                                                    onChange={(e) => handleInputChange('paymentMethodId', e.target.value)}
                                                    disabled={loading}
                                                >
                                                    <option value="">-- Select Payment Setting --</option>
                                                    {paymentSettings.map((setting, index) => (
                                                        <option key={index} value={setting._id}>
                                                            {setting.module} - {setting.shortCode}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Collection Frequency<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={formData.collectionFrequency}
                                                onChange={(e) => handleInputChange('collectionFrequency', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {COLLECTION_FREQUENCY_OPTIONS.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Levy Applicant<span className="text-danger">*</span> <em>(who pays)</em>
                                            </label>
                                            <select
                                                className="form-control"
                                                value={formData.levyApplicant}
                                                onChange={(e) => handleInputChange('levyApplicant', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {LEVY_APPLICANT_OPTIONS.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Invoice Day<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={formData.invoiceDay}
                                                onChange={(e) => handleInputChange('invoiceDay', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {INVOICE_DAY_OPTIONS.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Due Date<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={formData.dueDate}
                                                onChange={(e) => handleInputChange('dueDate', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {DUE_DATE_OPTIONS.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Reminder Settings Section */}
                                    <div className="col-12">
                                        <hr className="my-4" />
                                        <h6 className="mb-4">Reminder Settings</h6>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Reminder Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                value={formData.reminder.time}
                                                onChange={(e) => handleInputChange('reminder.time', e.target.value)}
                                            />
                                            <small className="text-muted">Default: 9:00 AM</small>
                                        </div>
                                    </div>

                                    {/* Overdue Reminder Section */}
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Overdue Reminder</label>
                                            <div className="form-check mb-2">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="overdueEnabled"
                                                    checked={formData.reminder.afterOverdue.enabled}
                                                    onChange={(e) => handleInputChange('reminder.afterOverdue.enabled', e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="overdueEnabled">
                                                    Enable Overdue Reminder
                                                </label>
                                            </div>

                                            {formData.reminder.afterOverdue.enabled && (
                                                <select
                                                    className="form-control mt-2"
                                                    value={formData.reminder.afterOverdue.days[0] || ""}
                                                    onChange={(e) => {
                                                        const value = e.target.value ? [parseInt(e.target.value)] : [];
                                                        handleInputChange('reminder.afterOverdue.days', value);
                                                    }}
                                                >
                                                    <option value="">Select days after due date</option>
                                                    {OVERDUE_REMINDER_OPTIONS.map(option => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Send Reminders On</label>
                                            <div className="d-flex flex-column gap-2">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="invoiceDate"
                                                        checked={formData.reminder.invoiceDate}
                                                        onChange={(e) => handleInputChange('reminder.invoiceDate', e.target.checked)}
                                                    />
                                                    <label className="form-check-label ms-2" htmlFor="invoiceDate">
                                                        Invoice Generation Date
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="dueDate"
                                                        checked={formData.reminder.dueDate}
                                                        onChange={(e) => handleInputChange('reminder.dueDate', e.target.checked)}
                                                    />
                                                    <label className="form-check-label ms-2" htmlFor="dueDate">
                                                        Due Date
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Notification Methods</label>
                                            <div className="d-flex gap-3">
                                                {NOTIFICATION_TYPES.map(type => (
                                                    <div key={type.value} className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id={`notification-${type.value}`}
                                                            checked={formData.reminder.notificationTypes.includes(type.value)}
                                                            onChange={() => handleNotificationTypeChange(type.value)}
                                                        />
                                                        <label className="form-check-label ms-2" htmlFor={`notification-${type.value}`}>
                                                            {type.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Penalty Settings Section */}
                                    <div className="col-12">
                                        <hr className="my-4" />
                                        <h6 className="mb-4">Penalty Settings</h6>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Penalty Name<span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.penalty?.name || ''}
                                                onChange={(e) => handleInputChange('penalty.name', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Penalty Type<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={formData.penalty?.type || ''}
                                                onChange={(e) => handleInputChange('penalty.type', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="percentage">Percentage</option>
                                                <option value="fixed">Fixed Amount</option>
                                            </select>
                                        </div>
                                    </div>

                                    {formData.penalty?.type === 'percentage' && (
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label">Percentage<span className="text-danger">*</span></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={formData.penalty?.percentage || ''}
                                                    onChange={(e) => handleInputChange('penalty.percentage', parseFloat(e.target.value))}
                                                    min="0"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {formData.penalty?.type === 'fixed' && (
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label">Fixed Amount<span className="text-danger">*</span></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={formData.penalty?.amount || ''}
                                                    onChange={(e) => handleInputChange('penalty.amount', parseFloat(e.target.value))}
                                                    min="0"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Effect Days<span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={formData.penalty?.effectDays || ''}
                                                onChange={(e) => handleInputChange('penalty.effectDays', parseInt(e.target.value))}
                                                min="1"
                                            />
                                            <small className="text-muted">Number of days after which the penalty takes effect</small>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Status</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="penaltyActive"
                                                    checked={formData.penalty?.isActive ?? true}
                                                    onChange={(e) => handleInputChange('penalty.isActive', e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="penaltyActive">
                                                    Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="text-end">
                                            <Button
                                                type="button"
                                                className="btn btn-outline-secondary me-2"
                                                onClick={clearForm}
                                                disabled={loading}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleLevySubmit}
                                                disabled={loading}
                                            >
                                                {loading ? 'Submitting...' : 'Submit'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AddLevy;