import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  LineChart,
  Line,
  AreaChart,
  Area,
} from "recharts";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";

function ReportsAndAnalysis() {
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Sample data for charts
  const monthlyData = [
    { month: "Jan", expenses: 12500, budget: 15000 },
    { month: "Feb", expenses: 14200, budget: 15000 },
    { month: "Mar", expenses: 13800, budget: 15000 },
    { month: "Apr", expenses: 15600, budget: 15000 },
    { month: "May", expenses: 14900, budget: 15000 },
    { month: "Jun", expenses: 13200, budget: 15000 },
  ];

  const categoryData = [
    { name: "Utilities", value: 35000 },
    { name: "Office Supplies", value: 25000 },
    { name: "Maintenance", value: 20000 },
    { name: "Equipment", value: 15000 },
    { name: "Others", value: 5000 },
  ];

  useEffect(() => {
    // Initialize Bootstrap tabs
    const triggerTabList = document.querySelectorAll("#myTab button");
    triggerTabList.forEach((triggerEl) => {
      // eslint-disable-next-line no-undef
      const tabTrigger = new bootstrap.Tab(triggerEl);
      triggerEl.addEventListener("click", (event) => {
        event.preventDefault();
        tabTrigger.show();
      });
    });
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Expense Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/expense_management/reports"}>
                    Reports & Analysis
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body py-0">
              <ul
                className="nav nav-tabs profile-tabs"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    id="profile-tab-1"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-1"
                    type="button"
                    role="tab"
                    aria-controls="profile-1"
                    aria-selected="true"
                  >
                    <i className="ti ti-rotate-2 me-2"></i>
                    Comprehensive breakdown of costs
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    id="profile-tab-2"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-2"
                    type="button"
                    role="tab"
                    aria-controls="profile-2"
                    aria-selected="false"
                  >
                    <i className="ti ti-report-money me-2"></i>
                    Budget report
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    id="profile-tab-3"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-3"
                    type="button"
                    role="tab"
                    aria-controls="profile-3"
                    aria-selected="false"
                  >
                    <i className="ti ti-file-minus me-2"></i>
                    Summaries per expense category
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    id="profile-tab-4"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-4"
                    type="button"
                    role="tab"
                    aria-controls="profile-4"
                    aria-selected="false"
                  >
                    <i className="ti ti-file-analytics me-2"></i>
                    Graphs & Dashboards
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            {/* Cost Breakdown Tab */}
            <div
              className="tab-pane fade show active"
              id="profile-1"
              role="tabpanel"
              aria-labelledby="profile-tab-1"
            >
              <div className="row">
                <div className="col-lg-12 col-xxl-12">
                  <div className="card">
                    <div className="card-body position-relative">
                      <div style={{ height: "400px" }} className="mb-4">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart data={monthlyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="expenses"
                              fill="#0d6efd"
                              name="Expenses"
                            />
                            <Bar
                              dataKey="budget"
                              fill="#dc3545"
                              name="Budget"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="card bg-primary text-white">
                            <div className="card-body">
                              <h6>Total Expenses</h6>
                              <h3>$84,200</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card bg-success text-white">
                            <div className="card-body">
                              <h6>Monthly Average</h6>
                              <h3>$14,033</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card bg-warning text-white">
                            <div className="card-body">
                              <h6>YoY Growth</h6>
                              <h3>8.5%</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card bg-info text-white">
                            <div className="card-body">
                              <h6>Efficiency Score</h6>
                              <h3>85%</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Budget Report Tab */}
            <div
              className="tab-pane fade"
              id="profile-2"
              role="tabpanel"
              aria-labelledby="profile-tab-2"
            >
              <div className="row">
                <div className="col-lg-12 col-xxl-12">
                  <div className="card">
                    <div className="card-body position-relative">
                      <div style={{ height: "400px" }} className="mb-4">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={monthlyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="expenses"
                              stroke="#0d6efd"
                              name="Actual"
                            />
                            <Line
                              type="monotone"
                              dataKey="budget"
                              stroke="#dc3545"
                              name="Budget"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="card bg-primary text-white">
                            <div className="card-body">
                              <h6>Total Budget</h6>
                              <h3>$90,000</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card bg-success text-white">
                            <div className="card-body">
                              <h6>Used Budget</h6>
                              <h3>$84,200</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card bg-warning text-white">
                            <div className="card-body">
                              <h6>Remaining</h6>
                              <h3>$5,800</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card bg-info text-white">
                            <div className="card-body">
                              <h6>Variance</h6>
                              <h3>6.4%</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Category Summaries Tab */}
            <div
              className="tab-pane fade"
              id="profile-3"
              role="tabpanel"
              aria-labelledby="profile-tab-3"
            >
              <div className="row">
                <div className="col-lg-12 col-xxl-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-3 col-xs-12 mt-3">
                          <input
                            className="form-control"
                            placeholder="Search here"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                        <div className="col-md-8 col-xs-12 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-xs-5 mt-2">
                              <label>Start Date</label>
                              <input
                                className="form-control"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                            </div>
                            <div className="col-md-4 col-xs-5 mt-2">
                              <label>End Date</label>
                              <input
                                className="form-control"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div style={{ height: "400px" }} className="mb-4">
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={categoryData}
                              dataKey="value"
                              nameKey="name"
                              cx="50%"
                              cy="50%"
                              outerRadius={150}
                              fill="#8884d8"
                              label
                            />
                            <Tooltip />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="row">
                        {categoryData.map((category, index) => (
                          <div key={index} className="col-md-3 mb-4">
                            <div
                              className={`card bg-${
                                index === 0
                                  ? "primary"
                                  : index === 1
                                  ? "success"
                                  : index === 2
                                  ? "warning"
                                  : "info"
                              } text-white`}
                            >
                              <div className="card-body">
                                <h6>{category.name}</h6>
                                <h3>${category.value.toLocaleString()}</h3>
                                <small>
                                  {(
                                    (category.value /
                                      categoryData.reduce(
                                        (acc, curr) => acc + curr.value,
                                        0
                                      )) *
                                    100
                                  ).toFixed(1)}
                                  % of total
                                </small>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Graphs & Dashboards Tab */}
            <div
              className="tab-pane fade"
              id="profile-4"
              role="tabpanel"
              aria-labelledby="profile-tab-4"
            >
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="card">
                    <div className="card-header">
                      <h5>Monthly Trends</h5>
                    </div>
                    <div className="card-body">
                      <div style={{ height: "300px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={monthlyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="expenses"
                              stroke="#0d6efd"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card">
                    <div className="card-header">
                      <h5>Category Distribution</h5>
                    </div>
                    <div className="card-body">
                      <div style={{ height: "300px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={categoryData}
                              dataKey="value"
                              nameKey="name"
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              fill="#8884d8"
                              label
                            />
                            <Tooltip />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Budget vs Actual Analysis</h5>
                    </div>
                    <div className="card-body">
                      <div style={{ height: "300px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart data={monthlyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="expenses"
                              fill="#0d6efd"
                              name="Actual"
                            />
                            <Bar
                              dataKey="budget"
                              fill="#dc3545"
                              name="Budget"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ReportsAndAnalysis;
