import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { makeRequest2 } from '../../../../../../utils/makeRequest';
import { useFacilityStore } from '../../../../../../app/z-store/store';
import { toastify } from '../../../../../../utils/toast';
import { customer_account_base_url } from '../../../../../../utils/urls';

const CustomersTab = () => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);

    useEffect(() => {
        if (selectedFacilityId) {
            fetchAccounts(selectedFacilityId);
        }
    }, [selectedFacilityId]);

    const fetchAccounts = async (facilityId) => {
        try {
            setLoading(true);
            const idToUse = facilityId || selectedFacilityId;
            if (!idToUse) {
                throw new Error('No facility ID available');
            }
            const response = await makeRequest2(
                `${customer_account_base_url}/all/${idToUse}`,
                'GET',
                null
            );
            if (response.success) {
                const accountsData = Array.isArray(response.data.data)
                    ? response.data.data
                    : [];

                console.log("Raw accounts data:", accountsData);

                const transformedData = accountsData.map((account) => ({
                    // Try to get the ID from any possible field
                    id: account._id || account.id,
                    CustomerInfo: {
                        fullName: account.customerName,
                    },
                    meterNumber: account.meterNumber || 'N/A',
                    UnitInfo: { name: account.unitName || 'N/A' },
                    accountNumber: account.account_no,
                    userType: account.payment_type,
                    accountBalance: 0,
                    negativeBalance: 0,
                    currentReading: account.currentReading,
                    status: account.status,
                    // Keep a reference to the original data if needed
                    originalData: account
                }));

                console.log("Transformed data:", transformedData);
                setAccounts(transformedData);
            } else {
                toastify('Failed to fetch customers', 'error');
            }
        } catch (error) {
            toastify('Error fetching customers', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleView = (rowData) => {
        // Log the entire rowData to see what's actually there
        console.log("Row data:", rowData);

        // Extract the account ID from the row data
        // Looking at your UserAccount model, we need to check if _id is being used instead of id
        const accountId = rowData._id || rowData.id;
        console.log("Using accountId:", accountId);

        // Navigate to the view page with the ID
        navigate(
            `/facility/utility_management/meter_management/water_meters/view_customer_account`,
            { state: { accountId: accountId } }
        );
    };

    const actionBodyTemplate = (rowData) => (
        <div className="flex gap-2">
            <Button
                icon="ti ti-eye"
                className="p-button-rounded p-button-text"
                onClick={() => handleView(rowData)}
                tooltip="View"
            />
        </div>
    );

    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            active: 'success',
            inactive: 'danger'
        };

        const status = rowData.status ? rowData.status.toLowerCase() : 'n/a';
        const color = statusColors[status] || 'secondary';

        return (
            <span className={`badge rounded-pill text-bg-${color}`}>
                {rowData.status
                    ? rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)
                    : 'N/A'}
            </span>
        );
    };

    const paymentTypeTemplate = (rowData) => {
        const typeColors = {
            postpaid: 'warning',
            prepaid: 'success'
        };
        const userType = rowData.userType ? rowData.userType.toLowerCase() : 'n/a';
        const color = typeColors[userType] || 'secondary';

        return (
            <span className={`badge rounded-pill text-bg-${color}`}>
                {rowData.userType
                    ? rowData.userType.charAt(0).toUpperCase() + rowData.userType.slice(1)
                    : 'N/A'}
            </span>
        );
    };

    const currencyTemplate = (value) => {
        return value
            ? new Intl.NumberFormat('en-KE', {
                style: 'currency',
                currency: 'KES'
            }).format(value)
            : 'KES 0.00';
    };

    const filteredAccounts = accounts.filter((account) =>
        Object.values(account).some((val) =>
            val?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div className="card">
            <div className="card-body">
                <div className="col-md-3 col-xs-12 mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search customers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="dt-responsive table-responsive">
                    <DataTable
                        value={filteredAccounts}
                        emptyMessage="No customers found."
                        sortMode="multiple"
                        paginator
                        rows={5}
                        stripedRows
                        loading={loading}
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column
                            field="fullname"
                            header="Customer"
                            sortable
                            body={(rowData) =>
                                rowData.CustomerInfo ? rowData.CustomerInfo.fullName : 'N/A'
                            }
                        />
                        <Column field="meterNumber" header="Meter S/N" sortable />
                        <Column
                            field="UnitInfo"
                            header="Unit"
                            sortable
                            body={(rowData) =>
                                rowData.UnitInfo ? rowData.UnitInfo.name : 'N/A'
                            }
                        />
                        <Column field="accountNumber" header="Account No" sortable />
                        <Column field="userType" header="Payment Type" sortable body={paymentTypeTemplate} />
                        <Column
                            field="accountBalance"
                            header="Account Bal"
                            sortable
                            body={(rowData) => currencyTemplate(rowData.accountBalance)}
                        />
                        <Column
                            field="negativeBalance"
                            header="Negative Bal"
                            sortable
                            body={(rowData) => currencyTemplate(rowData.negativeBalance)}
                        />
                        {/* <Column field="currentReading" header="Current Reading (m³)" sortable /> */}
                        <Column
                            field="status"
                            header="Status"
                            body={statusBodyTemplate}
                            sortable
                        />
                        <Column header="Actions" body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default CustomersTab;