import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext"; // Ensure correct import
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import Select from "react-select";
import {
  getUnitsForFacility,
  getCustomersURL,
  add_lease,
  get_lease_templates,
  get_leases,
  get_lease_penalties,
  get_lease_reminders,
  GET_CURRENCIES_URL,
  showLevySettings,
  fetchFacilityPaymentDetails,
} from "../../../../utils/urls";
import QuickCreateDialog from "../penalty_reminders/quick_dialog";

const AddLeaseAgreement = () => {
  const navigate = useNavigate();

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [showQuickCreateDialog, setShowQuickCreateDialog] = useState(false);
  const [quickCreateType, setQuickCreateType] = useState(null);

  // Basic Information State
  const [unitNumber, setUnitNumber] = useState("");
  const [landlordId, setLandlordId] = useState("");
  const [tenant, setTenant] = useState("");
  const [status, setStatus] = useState("Pending");

  // Lease Terms State
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("");
  const [autoRenewal, setAutoRenewal] = useState(false);

  // Financial Terms State
  const [monthlyRent, setMonthlyRent] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([
    { type: "", details: {}, isPrimary: true },
  ]);
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [balanceBroughtForward, setBalanceBroughtForward] = useState("0");
  const [requireLandlordApproval, setRequireLandlordApproval] = useState(false);

  // Billing Cycle State
  const [billingFrequency, setBillingFrequency] = useState("Monthly");
  const [nextInvoiceDate, setNextInvoiceDate] = useState("");
  const [autoSend, setAutoSend] = useState(false);

  // Lease Template State
  const [leaseTemplate, setLeaseTemplate] = useState("");

  // Penalty & Reminder State
  const [selectedPenaltyId, setSelectedPenaltyId] = useState(null);
  const [selectedReminderId, setSelectedReminderId] = useState(null);
  const [depositMonths, setDepositMonths] = useState(2);
  const [manualDepositEntry, setManualDepositEntry] = useState(false);

  const [penalties, setPenalties] = useState([]);
  const [reminders, setReminders] = useState([]);

  // Dropdown Options State
  const [mpesaEnabled, setMpesaEnabled] = useState(false);
  const [mobilePayment, setMobilePayment] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [paymentSettings, setPaymentSettings] = useState([]);
  const [units, setUnits] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [landlords, setLandlords] = useState([]);
  const [leaseTemplates, setLeaseTemplates] = useState([]);
  const [levySettings, setLevySettings] = useState({
    bankName: "",
    accountNumber: "",
  });

  const handleMpesaToggle = () => {
    setMpesaEnabled(prev => !prev);
  };

  const handleQuickCreateSuccess = () => {
    fetchPenaltiesAndReminders();
  };

  const fetchPaymentSettings = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const url = `${fetchFacilityPaymentDetails}/${facilityId}`;
      const response = await makeRequest2(url, "GET");

      if (response.success && response.data?.length > 0) {
        const formattedData = response.data.map(item => ({
          shortCode: item.shortCode,
          module: item.module,
          _id: item._id
        }));
        setPaymentSettings(formattedData);
      } else {
        setPaymentSettings([]);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      toastify("Error fetching payment details", "error");
      setPaymentSettings([]);
    }
  };

  const fetchLevySettings = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${showLevySettings}/${facilityId}`,
        "GET"
      );

      if (response.success && response.data?.settings) {
        setLevySettings(response.data.settings);
      }
    } catch (error) {
      console.error("Error fetching levy settings:", error);
    }
  };

  const validateRequiredFields = () => {
    // Validate basic information
    if (!unitNumber?.trim()) {
      toastify("Unit is required", "error");
      return false;
    }
    if (!landlordId?.trim()) {
      toastify("Landlord is required", "error");
      return false;
    }
    if (!tenant?.trim()) {
      toastify("Tenant is required", "error");
      return false;
    }

    // Validate lease terms
    if (!selectedCurrency) {
      toastify("Please select a currency", "error");
      return false;
    }
    if (!startDate) {
      toastify("Start date is required", "error");
      return false;
    }
    if (!endDate) {
      toastify("End date is required", "error");
      return false;
    }
    if (!leaseTemplate) {
      toastify("Lease template is required", "error");
      return false;
    }

    // Validate financial terms
    if (!monthlyRent || parseFloat(monthlyRent) <= 0) {
      toastify("Valid monthly rent is required", "error");
      return false;
    }
    if (!paymentDueDate) {
      toastify("Payment due date is required", "error");
      return false;
    }
    if (!securityDeposit || parseFloat(securityDeposit) <= 0) {
      toastify("Valid security deposit is required", "error");
      return false;
    }

    // Validate balance brought forward
    if (balanceBroughtForward === "" || isNaN(parseFloat(balanceBroughtForward))) {
      toastify("Balance brought forward is required and must be a number", "error");
      return false;
    }

    // Validate payment methods
    if (paymentMethods.length === 0) {
      toastify("At least one payment method is required", "error");
      return false;
    }

    // Validate each payment method
    const validPaymentMethod = paymentMethods.every(method => {
      if (!method.type) {
        toastify("Payment method type is required", "error");
        return false;
      }

      if (method.type === "Bank Transfer") {
        if (!method.details?.bankName || !method.details?.accountNumber) {
          toastify("Bank name and account number are required for bank transfer", "error");
          return false;
        }
      }

      if (method.type === "Mobile Money") {
        if (!method.details?.provider || !method.details?.phoneNumber) {
          toastify("Provider and phone number are required for mobile money", "error");
          return false;
        }
      }

      return true;
    });

    if (!validPaymentMethod) return false;

    // Validate billing cycle
    if (!billingFrequency) {
      toastify("Billing frequency is required", "error");
      return false;
    }

    return true;
  };

  const handleAddPaymentMethod = () => {
    setPaymentMethods([
      ...paymentMethods,
      {
        type: "",
        details: {},
        isPrimary: paymentMethods.length === 0,
      },
    ]);
  };

  const handlePaymentMethodChange = (index, field, value) => {
    const updatedMethods = [...paymentMethods];
    if (field === "type") {
      updatedMethods[index] = {
        ...updatedMethods[index],
        type: value,
        details: {},
        isPrimary: updatedMethods[index].isPrimary
      };
    } else if (field === "details") {
      updatedMethods[index] = {
        ...updatedMethods[index],
        details: value,
      };
    }
    setPaymentMethods(updatedMethods);
  };

  const handleRemovePaymentMethod = (index) => {
    const updatedMethods = paymentMethods.filter((_, i) => i !== index);
    if (paymentMethods[index].isPrimary && updatedMethods.length > 0) {
      updatedMethods[0].isPrimary = true;
    }
    setPaymentMethods(updatedMethods);
  };

  const handleSetPrimary = (index) => {
    const updatedMethods = paymentMethods.map((method, i) => ({
      ...method,
      isPrimary: i === index,
    }));
    setPaymentMethods(updatedMethods);
  };

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const monthDiff =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());
    return monthDiff;
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setStartDate(value);
      if (endDate) {
        setLeaseDuration(calculateDuration(value, endDate));
      }
    } else {
      setEndDate(value);
      if (startDate) {
        setLeaseDuration(calculateDuration(startDate, value));
      }
    }
  };

  const handleUnitSelection = (selectedUnitId) => {
  setUnitNumber(selectedUnitId);

  if (!selectedUnitId) {
    setTenant("");
    setLandlordId("");
    return;
  }

  const selectedUnit = units.find((unit) => unit._id === selectedUnitId);
  if (!selectedUnit) return;

  console.log("Selected unit:", {
    id: selectedUnit._id,
    name: selectedUnit.name,
    tenantId: selectedUnit.tenantId,
    homeOwnerId: selectedUnit.homeOwnerId
  });

  // Find assigned tenant
  const assignedTenant = tenants.find(
    (t) => t._id?.toString() === selectedUnit.tenantId?.toString()
  );

  if (assignedTenant) {
    console.log(`Found tenant: ${assignedTenant.firstName} ${assignedTenant.lastName} (${assignedTenant._id})`);
    setTenant(assignedTenant._id);
  } else {
    console.warn(`No matching tenant found for ID: ${selectedUnit.tenantId}`);
    setTenant("");
  }

  // Find assigned landlord
  const assignedLandlord = landlords.find(
    (l) => l._id?.toString() === selectedUnit.homeOwnerId?.toString()
  );

  if (assignedLandlord) {
    console.log(`Found landlord: ${assignedLandlord.firstName} ${assignedLandlord.lastName} (${assignedLandlord._id})`);
    setLandlordId(assignedLandlord._id);
  } else {
    console.warn(`No matching landlord found for ID: ${selectedUnit.homeOwnerId}`);
    setLandlordId("");
  }

  // Special handling for landlord - debug all landlords to check for issues
  if (selectedUnit.homeOwnerId && !assignedLandlord) {
    console.log("All available landlords:", landlords.map(l => ({
      id: l._id, 
      name: `${l.firstName} ${l.lastName}`
    })));
    
    // Try a case-insensitive search in case of string format issues
    const possibleMatches = landlords.filter(l => 
      l._id.toString().toLowerCase() === selectedUnit.homeOwnerId.toString().toLowerCase()
    );
    
    if (possibleMatches.length > 0) {
      console.log("Found possible landlord matches with case-insensitive search:", 
        possibleMatches.map(l => `${l.firstName} ${l.lastName} (${l._id})`)
      );
      // Use the first match
      setLandlordId(possibleMatches[0]._id);
    }
  }
};

  const fetchUnitsForFacility = async () => {
  try {
    const facilityId = await getItem("selectedFacilityId");

    const [unitsResponse, leasesResponse, customersResponse] = await Promise.all([
      makeRequest2(`${getUnitsForFacility}/${facilityId}`, "GET", {}),
      makeRequest2(`${get_leases}/${facilityId}`, "GET"),
      makeRequest2(`${getCustomersURL}/${facilityId}`, "GET", {})
    ]);

    if (unitsResponse.success && leasesResponse.success && customersResponse.success) {
      // Create sets of valid customer IDs for faster lookup
      const validLandlordIds = new Set();
      const validTenantIds = new Set();
      
      // Log all customers to help with debugging
      console.log("All customers in facility:", customersResponse.data.map(c => ({
        id: c._id,
        name: `${c.firstName} ${c.lastName}`,
        type: c.customerType
      })));
      
      // Extract customer IDs by type
      customersResponse.data.forEach(customer => {
        if (customer.customerType?.toLowerCase() === "home owner") {
          validLandlordIds.add(customer._id.toString());
        } else if (customer.customerType?.toLowerCase() === "tenant") {
          validTenantIds.add(customer._id.toString());
        }
      });
      
      console.log(`Found ${validLandlordIds.size} landlords and ${validTenantIds.size} tenants in the facility`);

      // Get units that already have leases
      const leasedUnitIds = new Set(
        leasesResponse.data.leaseAgreements
          ?.filter(
            (lease) => lease.status === "Active" || lease.status === "Pending"
          )
          .map((lease) => lease.unitNumber?.toString())
      );

      // Log all units for debugging
      console.log("All units:", unitsResponse.data.map(u => ({
        id: u._id,
        name: u.name,
        tenantId: u.tenantId,
        homeOwnerId: u.homeOwnerId,
        isLeased: leasedUnitIds.has(u._id.toString())
      })));

      // First set all available units (not leased)
      let availableUnits = unitsResponse.data.filter(
        (unit) => !leasedUnitIds.has(unit._id.toString())
      );
      
      // Log filtered units without landlord/tenant verification
      console.log(`Found ${availableUnits.length} units that are not currently leased`);

      // Now apply additional filters for valid landlords and tenants
      availableUnits = availableUnits.filter(unit => {
        const hasValidTenant = unit.tenantId && validTenantIds.has(unit.tenantId.toString());
        const hasValidLandlord = unit.homeOwnerId && validLandlordIds.has(unit.homeOwnerId.toString());
        
        // For debugging: log details about units that are failing the filter
        if (!hasValidTenant || !hasValidLandlord) {
          console.log(`Unit ${unit.name} (${unit._id}) filtered out:`, {
            tenantId: unit.tenantId,
            validTenant: hasValidTenant,
            homeOwnerId: unit.homeOwnerId,
            validLandlord: hasValidLandlord
          });
        }
        
        return hasValidTenant && hasValidLandlord;
      });

      console.log(`Final filtered units: ${availableUnits.length} with both valid landlord and tenant`);
      
      // If we ended up with no valid units, keep the original units but log a warning
      if (availableUnits.length === 0 && unitsResponse.data.length > 0) {
        console.warn("No units with valid landlord and tenant found. Using all available units instead.");
        availableUnits = unitsResponse.data.filter(
          (unit) => !leasedUnitIds.has(unit._id.toString())
        );
      }

      setUnits(availableUnits);
      
      // Also update the tenant and landlord lists
      setTenants(customersResponse.data.filter(c => c.customerType?.toLowerCase() === "tenant"));
      setLandlords(customersResponse.data.filter(c => c.customerType?.toLowerCase() === "home owner"));
    } else {
      if (!unitsResponse.success) {
        throw new Error("Failed to fetch units.");
      }
      if (!leasesResponse.success) {
        throw new Error("Failed to fetch lease information.");
      }
      if (!customersResponse.success) {
        throw new Error("Failed to fetch customers.");
      }
    }
  } catch (error) {
    console.error("Error fetching units:", error);
    toastify(error.message, "error");
  }
};


  const fetchPenaltiesAndReminders = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      if (!facilityId) {
        toastify("Facility ID not found", "error");
        return;
      }

      const [penaltiesRes, remindersRes] = await Promise.all([
        makeRequest2(`${get_lease_penalties}/${facilityId}`, "GET"),
        makeRequest2(`${get_lease_reminders}/${facilityId}`, "GET")
      ]);

      // Debug log to see the full reminders response
      console.log('Full reminders response:', remindersRes);

      if (penaltiesRes.success) {
        const penaltiesData = penaltiesRes.data?.data || [];
        setPenalties(penaltiesData.filter(p => p.isActive));
      }

      if (remindersRes.success) {
        // Don't filter for isActive since we want to use all reminders
        const remindersData = remindersRes.data?.data || [];
        console.log('All available reminders:', remindersData);
        setReminders(remindersData); // Remove the filter
      }

    } catch (error) {
      console.error('Error fetching penalties and reminders:', error);
      toastify('Error loading penalties and reminders', 'error');
    }
  };

  const fetchCustomersForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getCustomersURL}/${facilityId}`,
        "GET",
        {}
      );

      //console.log("Customers Response:", response);

      if (response.success) {
        const tenantCustomers = response.data.filter(
          (customer) => customer.customerType?.toLowerCase() === "tenant"
        );
        const landlordCustomers = response.data.filter(
          (customer) => customer.customerType?.toLowerCase() === "home owner"
        );

        setTenants(tenantCustomers);
        setLandlords(landlordCustomers);
      } else {
        throw new Error("Failed to fetch customers.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const fetchLeaseTemplates = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${get_lease_templates}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setLeaseTemplates(response.data);
      } else {
        throw new Error("Failed to fetch lease templates.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const handleReminderSelect = (reminderId) => {
    setSelectedReminderId(reminderId);
  };

  const handleLeaseAgreementSubmit = async () => {
    try {
      if (!validateRequiredFields()) {
        return;
      }
  
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("Facility ID not found", "error");
        return;
      }
  
      // Format payment methods for the backend
      const formattedPaymentMethods = paymentMethods.map(method => {
        // Handle Mobile Money as Bank Transfer format for backend compatibility
        if (method.type === "Mobile Money") {
          return {
            type: "Bank Transfer",
            isPrimary: Boolean(method.isPrimary),
            details: {
              bankName: method.details.provider || "",
              accountNumber: method.details.phoneNumber || "",
              accountName: method.details.accountName || "",
              branch: "",
              swiftCode: ""
            }
          };
        }
  
        // Handle other payment types
        return {
          type: method.type,
          isPrimary: Boolean(method.isPrimary),
          details: method.type === "Bank Transfer"
            ? {
                bankName: method.details.bankName || "",
                accountName: method.details.accountName || "",
                accountNumber: method.details.accountNumber || "",
                branch: method.details.branch || "",
                swiftCode: method.details.swiftCode || ""
              }
            : method.type === "Cash"
                ? { preferredCashLocation: method.details.preferredCashLocation || "" }
                : method.type === "Cheque"
                  ? {
                      bankToDraft: method.details.bankToDraft || "",
                      chequeAccountNumber: method.details.chequeAccountNumber || ""
                    }
                  : {}
        };
      });
  
      // Prepare financial terms including mpesaEnabled and mpesaDetails if enabled
      const financialTerms = {
        monthlyRent: Number(monthlyRent),
        paymentDueDate: Number(paymentDueDate),
        paymentMethods: formattedPaymentMethods,
        securityDeposit: Number(securityDeposit),
        depositMonths: Number(depositMonths || 2),
        balanceBroughtForward: Number(balanceBroughtForward || 0),
        mpesaEnabled: Boolean(mobilePayment)
      };
  
      // Only add penaltyId if one is selected
      if (selectedPenaltyId) {
        financialTerms.penaltyId = selectedPenaltyId;
      }
  
      // Add M-Pesa details if mobile payment is enabled
      if (mobilePayment) {
        // Get the payment method setting for M-Pesa if selected
        const selectedPaymentSetting = paymentSettings.find(s => s._id === paymentMethodId);
  
        financialTerms.mpesaDetails = {
          businessNumber: selectedPaymentSetting?.shortCode || levySettings.accountNumber || "",
          accountNumber: selectedPaymentSetting?.shortCode || levySettings.accountNumber || "",
          phoneNumber: selectedPaymentSetting?.shortCode || levySettings.accountNumber || ""
        };
      }
  
      // Build the lease data with the structure expected by the updated endpoint
      const leaseData = {
        unitNumber,
        landlord: landlordId,
        tenant,
        currency: selectedCurrency?.value,
        
        leaseTerms: {
          startDate,
          endDate,
          duration: parseInt(leaseDuration),
          autoRenewal: Boolean(autoRenewal)
        },
        
        financialTerms: financialTerms,
        
        billingCycle: {
          frequency: billingFrequency,
          nextInvoiceDate: nextInvoiceDate || null,
          autoSend: Boolean(autoSend)
        },
        
        leaseTemplate,
        status: status || "Pending",
        requireLandlordApproval: Boolean(requireLandlordApproval),
        reminders: selectedReminderId ? [{ 
          reminderId: selectedReminderId, 
          status: 'Pending' 
        }] : []
      };
  
      // Also add required fields at the root level for backward compatibility with validation
      leaseData.monthlyRent = Number(monthlyRent);
      leaseData.paymentDueDate = Number(paymentDueDate);
      leaseData.securityDeposit = Number(securityDeposit);
      leaseData.depositMonths = Number(depositMonths || 2);
      leaseData.frequency = billingFrequency;
  
      // Debug log
      console.log('Submitting lease data:', JSON.stringify(leaseData, null, 2));
  
      try {
        const response = await makeRequest2(
          `${add_lease}/${facilityId}`,
          "POST",
          leaseData
        );
  
        // Log the response for debugging
        console.log('API Response:', response);
  
        // Case 1: Standard success
        if (response.success === true) {
          toastify("Lease agreement created successfully", "success");
          navigate("/facility/lease_management/leases");
          return;
        }
  
        // Case 2: Error message indicates a 201 status (Created)
        // This is actually a success case due to how makeRequest2 handles non-200 responses
        if (response.error && response.error.includes("201")) {
          console.log("Detected 201 status in error - this is actually a success");
          toastify("Lease agreement created successfully", "success");
          navigate("/facility/lease_management/leases");
          return;
        }
  
        // Case 3: Any other error
        throw new Error(response.error || "Failed to create lease agreement");
  
      } catch (requestError) {
        console.error("API Request Error:", requestError);
        
        // Extract error message
        let errorMessage = requestError.message || "An unexpected error occurred";
        
        // Special case: If the error contains a 201 status code, it's actually a success
        if (errorMessage.includes("201")) {
          console.log("Success detected in error message (201 status)");
          toastify("Lease agreement created successfully", "success");
          navigate("/facility/lease_management/leases");
          return;
        }
        
        toastify(errorMessage, "error");
      }
    } catch (err) {
      console.error("Error in lease submission:", err);
      toastify(err.message || "An unexpected error occurred", "error");
    }
  };

  const handleClear = () => {
    setUnitNumber("");
    setLandlordId("");
    setTenant("");
    setStatus("Pending");
    setStartDate("");
    setEndDate("");
    setLeaseDuration("");
    setAutoRenewal(false);
    setMonthlyRent("");
    setPaymentDueDate("");
    setPaymentMethods([
      {
        type: "",
        details: {},
        isPrimary: true,
      },
    ]);
    setSecurityDeposit("");
    setBalanceBroughtForward("0"); // Reset balance brought forward to 0
    setBillingFrequency("Monthly");
    setNextInvoiceDate("");
    setAutoSend(false);
    setLeaseTemplate("");
    setSelectedCurrency(null);
    setMobilePayment(false);
    setPaymentMethodId("");
  };

  const fetchAvailableCurrencies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(`${GET_CURRENCIES_URL}/${facilityId}`, "GET");

      if (response.success && response.data) {
        const currencyData = response.data.data || response.data;
        const currenciesArray = Array.isArray(currencyData) ? currencyData : [currencyData];
        setAvailableCurrencies(currenciesArray);

        // Automatically select the default currency if it exists
        const defaultCurrency = currenciesArray.find(curr => curr.isDefaultCurrency);
        if (defaultCurrency) {
          setSelectedCurrency({
            value: defaultCurrency._id,
            label: `${defaultCurrency.currencyName} (${defaultCurrency.currencyShortCode})`,
            currencyShortCode: defaultCurrency.currencyShortCode
          });
        }
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
      toastify("Error fetching currencies", "error");
    }
  };

  const renderCurrencySection = () => (
    <div className="row mb-3">
      <div className="col-12">
        <label className="form-label">Currency <span className="text-danger">*</span></label>
        {availableCurrencies.length > 0 ? (
          <Select
            value={selectedCurrency}
            onChange={(selected) => setSelectedCurrency(selected)}
            options={availableCurrencies.map(curr => ({
              value: curr._id,
              label: `${curr.currencyName} (${curr.currencyShortCode})`,
              currencyShortCode: curr.currencyShortCode
            }))}
            isSearchable={false}
            className="react-select"
            classNamePrefix="select"
            placeholder="Select currency"
          />
        ) : (
          <div className="alert alert-warning">
            <i className="ti ti-alert-circle me-2"></i>
            No currencies available. Please{" "}
            <Link to="/facility/settings">add currencies</Link> in the settings first.
          </div>
        )}
      </div>
    </div>
  );

  const renderMonetaryInput = (label, value, onChange) => (
    <div className="col-sm-6 mb-3">
      <label className="form-label">{label}</label>
      <div className="input-group">
        {selectedCurrency && (
          <span className="input-group-text">
            {selectedCurrency.currencyShortCode}
          </span>
        )}
        <InputText
          type="number"
          className="form-control"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );

  const renderMpesaSection = () => (
    <div className="col-12 mt-3 border-top pt-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h6 className="mb-0">M-Pesa Integration</h6>
        <div className="d-flex align-items-center">
          <span className="me-2">{mpesaEnabled ? "Enabled" : "Disabled"}</span>
          <i
            className={`ti ${mpesaEnabled ? 'ti-toggle-right text-primary' : 'ti-toggle-left text-danger'} fs-4`}
            onClick={handleMpesaToggle}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      {mpesaEnabled && (
        <div className="row">
          <div className="col-md-4 mb-3">
            <label className="form-label">Business Name</label>
            <input
              type="text"
              className="form-control"
              value={levySettings.bankName || ""}
              disabled
            />
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">Business Number</label>
            <input
              type="text"
              className="form-control"
              value={levySettings.accountNumber || ""}
              disabled
            />
          </div>
          <div className="col-md-4 mb-3">
            <small className="text-muted">
              M-Pesa payments will be processed through the facility's configured M-Pesa account.
              To change these details, please update them in Facility Settings.
            </small>
          </div>
        </div>
      )}
    </div>
  );

  const renderMobilePaymentSection = () => (
    <div className="row mt-3 pt-3 border-top">
      <div className="col-sm-6">
        <div className="mb-3">
          <label className="form-label">Mobile Payment</label>
          <div className="d-flex align-items-center">
            <i
              className={`ti ${!mobilePayment ? "ti-toggle-left text-danger" : "ti-toggle-right text-primary"}`}
              style={{ fontSize: "34px", cursor: "pointer" }}
              onClick={() => setMobilePayment(!mobilePayment)}
            />
            <span className="ms-2">{mobilePayment ? "Enabled" : "Disabled"}</span>
          </div>
        </div>
      </div>

      {mobilePayment && (
        <div className="col-sm-6">
          <div className="mb-3">
            <label className="form-label">Payment Method<span className="text-danger">*</span></label>
            <select
              className="form-control"
              value={paymentMethodId}
              onChange={(e) => setPaymentMethodId(e.target.value)}
            >
              <option value="">-- Select Payment Setting --</option>
              {paymentSettings.map((setting, index) => (
                <option key={index} value={setting._id}>
                  {setting.module} - {setting.shortCode}
                </option>
              ))}
            </select>
            {paymentSettings.length === 0 && (
              <small className="text-muted">
                No payment settings found. Please configure payment settings in the facility settings.
              </small>
            )}
          </div>
        </div>
      )}
    </div>
  );

  // Load data on component mount
  useEffect(() => {
    fetchUnitsForFacility();
    fetchCustomersForFacility();
    fetchLeaseTemplates();
    fetchPenaltiesAndReminders();
    fetchAvailableCurrencies();
    fetchLevySettings();
    fetchPaymentSettings();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/lease_management/leases">
                    Lease Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Add New Lease Agreement</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Lease Agreement</h5>
            </div>

            <div className="row">
              <div className="col-sm-12">
                {penalties.length === 0 && (
                  <div className="alert alert-info d-flex align-items-center" role="alert">
                    <i className="ti ti-info-circle me-3 fs-4"></i>
                    <div>
                      No penalties are currently set up. You can{" "}
                      <Link
                        to="/facility/lease_management/penalties/create"
                        className="alert-link"
                      >
                        create penalties
                      </Link>{" "}
                      or continue without them.
                    </div>
                  </div>
                )}

                {/* Rest of the existing component content */}
                <div className="card">
                  {/* ... */}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="container">
                {/* Basic Information Card */}
                <div className="card mb-4">
                  <div className="card-header">
                    <h6 className="mb-0">Basic Information</h6>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-label">Unit <span className="text-danger">*</span></label>
                        <select
                          className="form-control"
                          value={unitNumber}
                          onChange={(e) => handleUnitSelection(e.target.value)}
                        >
                          <option value="">-- Select Unit --</option>
                          {units.map((unit) => (
                            <option key={unit._id} value={unit._id}>
                              {unit.name}{" "}
                              {unit.floorUnitNo ? `(${unit.floorUnitNo})` : ""}
                            </option>
                          ))}
                        </select>

                        {units.length === 0 && (
                          <small className="text-danger mt-2 d-block">
                            <i className="ti ti-alert-circle me-1"></i>
                            No available units found with both landlord and tenant assigned.
                            Please assign both to units before creating a lease.
                          </small>
                        )}
                      </div>

                      <div className="col-sm-6">
                        <label className="form-label">Landlord</label>
                        <select
                          className="form-control"
                          value={landlordId}
                          onChange={(e) => setLandlordId(e.target.value)}
                          disabled={true}
                        >
                          <option value="">-- Select Landlord --</option>
                          {landlords
                            .filter((l) => l._id === landlordId)
                            .map((landlord) => (
                              <option key={landlord._id} value={landlord._id}>
                                {`${landlord.firstName} ${landlord.lastName}`}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">Tenant</label>
                        <select
                          className="form-control"
                          value={tenant}
                          onChange={(e) => setTenant(e.target.value)}
                          disabled={true}
                        >
                          <option value="">-- Select Tenant --</option>
                          {tenants
                            .filter((t) => t._id === tenant)
                            .map((tenant) => (
                              <option key={tenant._id} value={tenant._id}>
                                {`${tenant.firstName} ${tenant.lastName}`}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">Status</label>
                        <select
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Active">Active</option>
                          <option value="Expired">Expired</option>
                          <option value="Terminated">Terminated</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Lease Terms Card */}
                <div className="card mb-4">
                  <div className="card-header">
                    <h6 className="mb-0">Lease Terms</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-label">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e.target.value, "start")
                          }
                        />
                      </div>

                      <div className="col-sm-6">
                        <label className="form-label">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e.target.value, "end")
                          }
                        />
                      </div>

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">
                          Lease Duration (Months)
                        </label>
                        <InputText
                          className="form-control"
                          value={leaseDuration}
                          readOnly
                          placeholder="Duration will be calculated automatically"
                        />
                      </div>

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">Lease Template</label>
                        <select
                          className="form-control"
                          value={leaseTemplate}
                          onChange={(e) => setLeaseTemplate(e.target.value)}
                        >
                          <option value="">-- Select Lease Template --</option>
                          {leaseTemplates.map((template) => (
                            <option key={template._id} value={template._id}>
                              {template.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* <div className="col-sm-6 mt-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={autoRenewal}
              onChange={(e) => setAutoRenewal(e.target.checked)}
            />
            <label className="form-check-label">Enable Auto Renewal</label>
          </div>
        </div> */}
                    </div>
                  </div>
                </div>

                {/* Financial Terms Card */}
                <div className="card mb-4">
                  <div className="card-header">
                    <h6 className="mb-0">Financial Terms</h6>
                  </div>
                  <div className="card-body">
                    {renderCurrencySection()}

                    <div className="row">
                      {renderMonetaryInput("Monthly Rent", monthlyRent, (e) => {
                        const rent = parseFloat(e.target.value);
                        setMonthlyRent(e.target.value);
                        // Automatically update security deposit if it's using default calculation
                        if (depositMonths && (!securityDeposit || parseFloat(securityDeposit) === monthlyRent * depositMonths)) {
                          setSecurityDeposit((rent * depositMonths).toFixed(2));
                        }
                      })}

                      <div className="col-sm-6 ">
                        <label className="form-label">Deposit Months</label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            value={depositMonths}
                            onChange={(e) => {
                              const months = parseInt(e.target.value);
                              setDepositMonths(months);
                              // Automatically calculate security deposit
                              setSecurityDeposit((monthlyRent * months).toFixed(2));
                            }}
                          >
                            <option value="1">1 Month</option>
                            <option value="2">2 Months</option>
                            <option value="3">3 Months</option>
                            <option value="4">4 Months</option>
                            <option value="5">5 Months</option>
                            <option value="6">6 Months</option>
                          </select>
                          <div className="input-group-text">
                            <input
                              type="checkbox"
                              className="form-check-input mt-0 me-2"
                              checked={manualDepositEntry}
                              onChange={(e) => setManualDepositEntry(e.target.checked)}
                            />
                            Manual Entry
                          </div>
                        </div>
                      </div>

                      {renderMonetaryInput(
                        "Security Deposit",
                        securityDeposit,
                        (e) => {
                          setSecurityDeposit(e.target.value);
                          // If manual entry is checked, allow custom input
                          // Otherwise, reset to calculated value
                          if (!manualDepositEntry) {
                            const calculatedDeposit = (monthlyRent * depositMonths).toFixed(2);
                            setSecurityDeposit(calculatedDeposit);
                          }
                        },
                        {
                          // Disable input if not in manual entry mode
                          disabled: !manualDepositEntry
                        }
                      )}
                      {renderMonetaryInput("Balance Brought Forward", balanceBroughtForward, (e) => setBalanceBroughtForward(e.target.value))}

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">Payment Due Date</label>
                        <select
                          className="form-control"
                          value={paymentDueDate}
                          onChange={(e) => setPaymentDueDate(e.target.value)}
                        >
                          <option value="">-- Select Due Date --</option>
                          <option value="1">1st of the month</option>
                          <option value="5">5th of the month</option>
                          <option value="15">15th of the month</option>
                          <option value="30">30th of the month</option>
                        </select>
                      </div>

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">Billing Frequency</label>
                        <select
                          className="form-control"
                          value={billingFrequency}
                          onChange={(e) => setBillingFrequency(e.target.value)}
                        >
                          <option value="">-- Select Frequency --</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Annually">Annually</option>
                        </select>
                      </div>

                      <div className="col-sm-6 mt-3">
                        <label className="form-label">Next Invoice Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={nextInvoiceDate}
                          onChange={(e) => setNextInvoiceDate(e.target.value)}
                        />
                      </div>

                      {/* <div className="col-sm-6 mt-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={autoSend}
              onChange={(e) => setAutoSend(e.target.checked)}
            />
            <label className="form-check-label">Auto Send Invoices</label>
          </div>
        </div> */}
                    </div>
                    {renderMobilePaymentSection()}
                  </div>
                </div>

                {/* Payment Method Card */}
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">Payment Methods</h6>
                    <Button
                      label="Add Payment Method"
                      icon="ti ti-plus"
                      className="p-button-sm"
                      onClick={handleAddPaymentMethod}
                    />
                  </div>
                  <div className="card-body">
                    {paymentMethods.map((method, index) => (
                      <div
                        key={index}
                        className="payment-method-section border rounded p-3 mb-3"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h6 className="mb-0">Payment Method {index + 1}</h6>
                          <div>
                            <Button
                              icon="ti ti-star"
                              className={`p-button-sm me-2 ${method.isPrimary
                                ? "p-button-success"
                                : "p-button-outlined"
                                }`}
                              onClick={() => handleSetPrimary(index)}
                              tooltip="Set as Primary"
                            />
                            <Button
                              icon="ti ti-trash"
                              className="p-button-sm p-button-danger"
                              onClick={() => handleRemovePaymentMethod(index)}
                              disabled={paymentMethods.length === 1}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-3">
                            <label className="form-label">
                              Payment Method Type
                            </label>
                            <select
                              className="form-control"
                              value={method.type}
                              onChange={(e) =>
                                handlePaymentMethodChange(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">
                                -- Select Payment Method --
                              </option>
                              <option value="Bank Transfer">
                                Bank Transfer
                              </option>
                              <option value="Mobile Money">Mobile Money</option>
                              <option value="Cash">Cash</option>
                              <option value="Cheque">Cheque</option>
                            </select>
                          </div>

                          {method.type === "Bank Transfer" && (
                            <div className="row">
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Bank Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.bankName || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(
                                      index,
                                      "details",
                                      {
                                        ...method.details,
                                        bankName: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="e.g., Equity Bank"
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">
                                  Account Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.accountName || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(
                                      index,
                                      "details",
                                      {
                                        ...method.details,
                                        accountName: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="Account holder's name"
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">
                                  Account Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.accountNumber || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(
                                      index,
                                      "details",
                                      {
                                        ...method.details,
                                        accountNumber: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="Bank account number"
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Branch</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.branch || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(
                                      index,
                                      "details",
                                      {
                                        ...method.details,
                                        branch: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="Bank branch"
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Swift Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.swiftCode || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(
                                      index,
                                      "details",
                                      {
                                        ...method.details,
                                        swiftCode: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="Bank swift code"
                                />
                              </div>
                            </div>
                          )}

                          {method.type === "Mobile Money" && (
                            <div className="row">
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Provider</label>
                                <select
                                  className="form-control"
                                  value={method.details?.provider || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(index, "details", {
                                      ...method.details,
                                      provider: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Select Provider</option>
                                  <option value="Mpesa">M-Pesa</option>
                                  <option value="Airtel">Airtel Money</option>
                                  <option value="Equitel">Equitel</option>
                                  <option value="Tkash">T-Kash</option>
                                </select>
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Phone Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.phoneNumber || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(index, "details", {
                                      ...method.details,
                                      phoneNumber: e.target.value,
                                    })
                                  }
                                  placeholder="Enter phone number"
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Account Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={method.details?.accountName || ""}
                                  onChange={(e) =>
                                    handlePaymentMethodChange(index, "details", {
                                      ...method.details,
                                      accountName: e.target.value,
                                    })
                                  }
                                  placeholder="Enter account name"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Penalties and Reminders Card */}
                <div className="card mb-4">
                  <div className="card-header">
                    <h6 className="mb-0">Penalties, Reminders, and Approvals</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <label className="form-label">
                          Penalty {/* Removed the required asterisk */}
                        </label>
                        <div className="d-flex gap-2">
                          <select
                            className="form-control"
                            value={selectedPenaltyId || ''}
                            onChange={(e) => setSelectedPenaltyId(e.target.value)}
                          >
                            <option value="">-- Select Penalty (Optional) --</option>
                            {penalties.map((penalty) => (
                              <option key={penalty._id} value={penalty._id}>
                                {penalty.name} ({penalty.type === 'fixed' ?
                                  `${penalty.amount} fixed` :
                                  `${penalty.percentage}%`})
                              </option>
                            ))}
                          </select>
                        </div>
                        {penalties.length === 0 && (
                          <small className="text-muted">
                            No active penalties found. Click{" "}
                            <Link
                              to="/facility/lease_management/penalties/create"
                              className="text-primary"
                            >
                              here
                            </Link>{" "}
                            to create one.
                          </small>
                        )}
                      </div>

                      <div className="col-sm-6 mb-3">
                        <label className="form-label">
                          Reminder {/* Removed the required asterisk */}
                        </label>
                        <div className="d-flex gap-2">
                          <select
                            className="form-control"
                            value={selectedReminderId || ''}
                            onChange={(e) => setSelectedReminderId(e.target.value)}
                          >
                            <option value="">-- Select Reminder (Required) --</option>
                            {reminders.map((reminder) => (
                              <option key={reminder._id} value={reminder._id}>
                                {reminder.name} ({reminder.notificationTypes.join(', ')})
                              </option>
                            ))}
                          </select>
                        </div>
                        {reminders.length === 0 && (
                          <small className="text-muted">
                            No reminders found. Click{" "}
                            <Link
                              to="/facility/lease_management/reminders/create"
                              className="text-primary"
                            >
                              here
                            </Link>{" "}
                            to create one.
                          </small>
                        )}
                      </div>

                      {/* Landlord Approval Notification Toggle */}
                      <div className="col-sm-12 mb-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="landlordApprovalToggle"
                            checked={requireLandlordApproval}
                            onChange={(e) => setRequireLandlordApproval(e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor="landlordApprovalToggle">
                            Require Landlord Approval
                            <small className="text-muted ms-2">
                              {requireLandlordApproval
                                ? "Lease requires landlord's confirmation before activation"
                                : "Lease will be automatically activated"}
                            </small>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLeaseAgreementSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
        <QuickCreateDialog
          visible={showQuickCreateDialog}
          type={quickCreateType}
          onHide={() => {
            setShowQuickCreateDialog(false);
            setQuickCreateType(null);
          }}
          onSuccess={handleQuickCreateSuccess}
        />
      </div>
    </Layout>
  );
};

export default AddLeaseAgreement;