import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate, Route, Routes } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import {
  get_all_handovers,
} from '../../../utils/urls';
import { toastify } from '../../../utils/toast';
import Layout from '../component/layout';
// import CompareHandovers from './compare_handover';
import EditHandover from './update_handover';

const HandoverManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'moveIn');
  const [handovers, setHandovers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewDetailsDialog, setViewDetailsDialog] = useState(false);
  const [selectedHandover, setSelectedHandover] = useState(null);

  // State for popup management
  const [showCompareDialog, setShowCompareDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedHandoverId, setSelectedHandoverId] = useState(null);

  // Search and filter state
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: null,
    startDate: null,
    endDate: null
  });

  // Status options for dropdown
  const statusOptions = [
    { label: 'All Statuses', value: null },
    { label: 'Draft', value: 'Draft' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Disputed', value: 'Disputed' },
    { label: 'Resolved', value: 'Resolved' }
  ];

  // Pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const fetchHandovers = useCallback(async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("No facility selected", "error");
        return;
      }
      console.log('Facility ID from localStorage:', facilityId);

      // Make a simple request without query parameters first
      const response = await makeRequest2(`${get_all_handovers}/${facilityId}`, "GET");
      console.log('API Response:', response);

      if (response?.success && response.data) {
        console.log('Full response data:', response.data);

        // Extract handovers from response
        let allHandovers = [];

        // Try to handle different possible response formats
        if (Array.isArray(response.data)) {
          allHandovers = response.data;
        } else if (response.data.handovers && Array.isArray(response.data.handovers)) {
          allHandovers = response.data.handovers;
        } else if (typeof response.data === 'object') {
          // Try to find any arrays in the response
          Object.keys(response.data).forEach(key => {
            if (Array.isArray(response.data[key])) {
              allHandovers = [...allHandovers, ...response.data[key]];
            }
          });
        }

        // Filter handovers based on active tab
        const filteredHandovers = allHandovers.filter(
          handover => handover.handoverType === (activeTab === 'moveIn' ? 'MoveIn' : 'MoveOut')
        );

        console.log(`Filtered ${activeTab} handovers:`, filteredHandovers.length);

        if (filteredHandovers.length > 0) {
          console.log('Sample handover:', filteredHandovers[0]);
        }

        // Set filtered handovers to state
        setHandovers(filteredHandovers || []);
        setTotalRecords(filteredHandovers?.length || 0);
      } else {
        toastify(`Error: ${response?.error || 'Could not fetch handovers'}`, 'error');
        setHandovers([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error('Exception when fetching handovers:', error);
      toastify(`Error: ${error.message || 'Could not fetch handovers'}`, 'error');
      setHandovers([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  }, [activeTab]);

  // Reset pagination when tab changes
  useEffect(() => {
    console.log('Tab changed to:', activeTab);
    setFirst(0);
    setCurrentPage(1);
    fetchHandovers(1, rows);
  }, [activeTab, fetchHandovers, rows]);

  // Handle filter changes
  useEffect(() => {
    // Debounced fetch to avoid too many requests
    const handler = setTimeout(() => {
      setFirst(0);
      setCurrentPage(1);
      fetchHandovers(1, rows);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm, filters, fetchHandovers, rows]);

  useEffect(() => {
    console.log("Current handovers data count:", handovers?.length || 0);
    console.log("Total records:", totalRecords);
  }, [handovers, totalRecords]);

  const onPageChange = (event) => {
    if (!event) return;
    
    setFirst(event.first || 0);
    const page = Math.floor((event.first || 0) / (event.rows || 10)) + 1;
    setCurrentPage(page);
    setRows(event.rows || 10);
  };

  const handleViewDetails = (handover) => {
    console.log('Viewing details for handover:', handover._id);
    setSelectedHandover(handover);
    setViewDetailsDialog(true);
  };

  // Updated to use popup dialog instead of navigation
  const handleEditHandover = (handover) => {
    console.log('Editing handover:', handover._id);
    setSelectedHandover(handover);
    setSelectedHandoverId(handover._id);
    setShowEditDialog(true);
  };

  // Updated to use popup dialog instead of navigation
  // const handleCompareHandovers = (handover) => {
  //   console.log('Comparing handover with move-in handover:', handover._id);
  //   setSelectedHandover(handover);
  //   setSelectedHandoverId(handover._id);
  //   setShowCompareDialog(true);
  // };

  // Callback for when dialogs are closed - refreshes data
  const handleDialogClose = () => {
    // Refresh data when dialog is closed
    fetchHandovers(currentPage, rows);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } catch (e) {
      console.error('Error formatting date:', dateString, e);
      return 'Invalid Date';
    }
  };

  const statusBodyTemplate = (rowData) => {
    const statusClass = {
      'Draft': 'bg-warning',
      'Completed': 'bg-success',
      'Disputed': 'bg-danger',
      'Resolved': 'bg-info'
    };

    return (
      <span className={`badge ${statusClass[rowData.status] || 'bg-secondary'}`}>
        {rowData.status || 'Unknown'}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex gap-2">
        <Button
          icon="ti ti-eye"
          className="p-button-sm p-button-info"
          tooltip="View Details"
          onClick={() => handleViewDetails(rowData)}
        />
        <Button
          icon="ti ti-pencil"
          className="p-button-sm p-button-warning"
          tooltip="Edit"
          onClick={() => handleEditHandover(rowData)}
        />
        {/* {activeTab === 'moveOut' && rowData.relatedHandoverId && (
          <Button
            icon="ti ti-arrows-split"
            className="p-button-sm p-button-help"
            tooltip="Compare with Move-In"
            onClick={() => handleCompareHandovers(rowData)}
          />
        )} */}
      </div>
    );
  };

  const customerTemplate = (rowData) => {
    if (!rowData.customerId) {
      console.log('Missing customerId for row:', rowData._id);
      return 'Unknown Customer';
    }

    // Check if customerId is already an object with firstName/lastName
    const firstName = typeof rowData.customerId === 'object' ? (rowData.customerId.firstName || '') : '';
    const lastName = typeof rowData.customerId === 'object' ? (rowData.customerId.lastName || '') : '';

    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim();
    } else if (typeof rowData.customerId === 'object') {
      console.log('CustomerId is object but missing name fields:', rowData.customerId);
    } else {
      console.log('CustomerId not in expected format:', rowData.customerId);
    }

    return 'Unknown Customer';
  };

  const unitTemplate = (rowData) => {
    if (!rowData.unitId) {
      console.log('Missing unitId for row:', rowData._id);
      return 'Unknown Unit';
    }

    // Check if unitId is already an object with name
    if (typeof rowData.unitId === 'object' && rowData.unitId.name) {
      const floorUnitNo = rowData.unitId.floorUnitNo ? ` (${rowData.unitId.floorUnitNo})` : '';
      return `${rowData.unitId.name}${floorUnitNo}`;
    } else if (typeof rowData.unitId === 'object') {
      console.log('UnitId is object but missing name:', rowData.unitId);
    } else {
      console.log('UnitId not in expected format:', rowData.unitId);
    }

    return 'Unknown Unit';
  };

  const renderViewDetailsDialog = () => {
    if (!selectedHandover) return null;

    return (
      <Dialog
        header={`${selectedHandover.handoverType === 'MoveIn' ? 'Move-In' : 'Move-Out'} Handover Details`}
        visible={viewDetailsDialog}
        style={{ width: '80vw' }}
        modal
        onHide={() => setViewDetailsDialog(false)}
      >
        <div className="p-3">
          <div className="row mb-4">
            <div className="col-md-6">
              <h5>Basic Information</h5>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Unit:</div>
                <div className="col-md-8">
                  {unitTemplate(selectedHandover)}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Customer:</div>
                <div className="col-md-8">
                  {customerTemplate(selectedHandover)}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Handover Date:</div>
                <div className="col-md-8">{formatDate(selectedHandover.handoverDate)}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Status:</div>
                <div className="col-md-8">
                  {statusBodyTemplate(selectedHandover)}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Keys Handed Over:</div>
                <div className="col-md-8">{selectedHandover.keysHandedOver || 0}</div>
              </div>
            </div>

            <div className="col-md-6">
              <h5>Meter Readings</h5>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Electricity:</div>
                <div className="col-md-8">
                  {selectedHandover.meterReadings?.electricity?.reading || 'N/A'}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Water:</div>
                <div className="col-md-8">
                  {selectedHandover.meterReadings?.water?.reading || 'N/A'}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Gas:</div>
                <div className="col-md-8">
                  {selectedHandover.meterReadings?.gas?.reading || 'N/A'}
                </div>
              </div>

              {selectedHandover.handoverType === 'MoveOut' && selectedHandover.securityDeposit && (
                <>
                  <h5 className="mt-4">Security Deposit</h5>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">Deposit Amount:</div>
                    <div className="col-md-8">{selectedHandover.securityDeposit.amount || 0}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">Deductions:</div>
                    <div className="col-md-8">
                      {selectedHandover.securityDeposit.deductions?.length ?
                        selectedHandover.securityDeposit.deductions.reduce((total, deduction) =>
                          total + (deduction.amount || 0), 0) :
                        'None'}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">Refund Amount:</div>
                    <div className="col-md-8">{selectedHandover.securityDeposit.refundAmount || 0}</div>
                  </div>
                </>
              )}
            </div>
          </div>

          <h5>Inventory Items</h5>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Category</th>
                  <th>Condition</th>
                  <th>Quantity</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {selectedHandover.items && selectedHandover.items.length > 0 ? (
                  selectedHandover.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name || 'Unnamed Item'}</td>
                      <td>{item.category || 'Uncategorized'}</td>
                      <td>
                        <span className={`badge ${item.condition === 'Excellent' || item.condition === 'Good' ? 'bg-success' :
                          item.condition === 'Fair' ? 'bg-warning' : 'bg-danger'
                          }`}>
                          {item.condition || 'Unknown'}
                        </span>
                      </td>
                      <td>{item.quantity || 1}</td>
                      <td>{item.notes || ''}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">No items recorded</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <h5>Notes</h5>
            <p>{selectedHandover.notes || 'No additional notes'}</p>
          </div>

          <div className="mt-4">
            <h5>Attachments</h5>
            {selectedHandover.attachments && selectedHandover.attachments.length > 0 ? (
              <ul className="list-group">
                {selectedHandover.attachments.map((attachment, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {attachment.name || `Attachment ${index + 1}`}
                    {attachment.fileUrl && (
                      <a href={attachment.fileUrl} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary">
                        View
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No attachments</p>
            )}
          </div>

          <div className="mt-4">
            <h5>Signatures</h5>
            <div className="row">
              <div className="col-md-6">
                <h6>Property Manager</h6>
                {selectedHandover.signatures?.propertyManager?.signature ? (
                  <div>
                    <img src={selectedHandover.signatures.propertyManager.signature} alt="Property Manager Signature" style={{ maxWidth: '100%', height: 'auto' }} />
                    <p>Date: {selectedHandover.signatures.propertyManager.date ? formatDate(selectedHandover.signatures.propertyManager.date) : 'Not signed'}</p>
                  </div>
                ) : (
                  <p>Not signed</p>
                )}
              </div>
              <div className="col-md-6">
                <h6>Customer</h6>
                {selectedHandover.signatures?.customer?.signature ? (
                  <div>
                    <img src={selectedHandover.signatures.customer.signature} alt="Customer Signature" style={{ maxWidth: '100%', height: 'auto' }} />
                    <p>Date: {selectedHandover.signatures.customer.date ? formatDate(selectedHandover.signatures.customer.date) : 'Not signed'}</p>
                    <p>Agreement: {selectedHandover.signatures?.customer?.agreement ? 'Agreed' : 'Not agreed'}</p>
                  </div>
                ) : (
                  <p>Not signed</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Property Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Handover Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="m-0">Handover Management</h5>
          <Link
            to={`/facility/handover_management/add_${activeTab === 'moveIn' ? 'move_in' : 'move_out'}_handover`}
            className="btn btn-primary btn-sm"
          >
            <i className="ti ti-plus me-2"></i> Add {activeTab === 'moveIn' ? 'Move-In' : 'Move-Out'} Handover
          </Link>
        </div>
        <div className="card-body py-0">
          <ul className="nav nav-tabs profile-tabs" role="tablist">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'moveIn' ? 'active' : ''}`}
                onClick={() => setActiveTab('moveIn')}
                role="tab"
                type="button"
              >
                <i className="ti ti-login me-2"></i>Move-In Handovers
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'moveOut' ? 'active' : ''}`}
                onClick={() => setActiveTab('moveOut')}
                role="tab"
                type="button"
              >
                <i className="ti ti-logout me-2"></i>Move-Out Handovers
              </button>
            </li>
          </ul>

          <div className="tab-content">
            <div className={`tab-pane ${activeTab === 'moveIn' || activeTab === 'moveOut' ? 'show active' : ''}`}>

              <DataTable
                value={handovers || []}
                emptyMessage={`No ${activeTab === 'moveIn' ? 'move-in' : 'move-out'} handovers found.`}
                stripedRows
                loading={loading}
                tableStyle={{ minWidth: '50rem' }}
                paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                onPage={onPageChange}
                rowsPerPageOptions={[10, 25, 50]}
              >
                <Column field="unitId.name" header="Unit" body={unitTemplate} sortable></Column>
                <Column
                  field="customerId"
                  header="Customer"
                  body={customerTemplate}
                  sortable
                ></Column>
                <Column
                  field="handoverDate"
                  header="Handover Date"
                  body={(rowData) => formatDate(rowData.handoverDate)}
                  sortable
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  sortable
                ></Column>
                <Column body={actionBodyTemplate} header="Actions" style={{ width: '15rem' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      {/* Render the detail dialog */}
      {renderViewDetailsDialog()}

      {/* Render Compare Handovers popup */}
      {/* {showCompareDialog && selectedHandover && (
        <CompareHandovers 
          id={selectedHandoverId} 
          location={{ state: { handover: selectedHandover } }}
          onClose={() => {
            setShowCompareDialog(false);
            handleDialogClose();
          }}
        />
      )} */}

      {/* Render Edit Handover popup */}
      {showEditDialog && selectedHandover && (
        <EditHandover
          id={selectedHandoverId}
          location={{ state: { handover: selectedHandover } }}
          onClose={() => {
            setShowEditDialog(false);
            handleDialogClose();
          }}
        />
      )}
    </Layout>
  );
};

export default HandoverManagement;