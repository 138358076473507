import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { getItem, clearStorage } from "../../../utils/localStorage";
import { makeRequest2 } from "../../../utils/makeRequest";
import { getFacilityURL } from "../../../utils/urls";
import { useFacilityStore } from "../../../app/z-store/store";
function Nav() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [userName, setFullname] = useState("");
  const [userRole, setRole] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const navbarContentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  // Add these state variables at the top with other state declarations
  const [facilityModules, setFacilityModules] = useState({
    visitor: false,
    levy: false,
    maintenance: false,
    lease: false,
    vas: false,
    tickets: false,
    utility: false,
    booking: false,
    handover: false,
    expense: false,
    campaign: false,
  });

  const [facilityLogo, setFacilityLogo] = useState(
    () => localStorage.getItem("selectedFacilityLogo") || ""
  );

  const HandleLogOut = async () => {
    await clearStorage();
    localStorage.removeItem("selectedFacilityLogo");
    navigate("/");
  };

  // Save scroll position before navigation
  const handleLinkClick = (e) => {
    if (navbarContentRef.current) {
      setScrollPosition(navbarContentRef.current.scrollTop);
    }
  };

  // Restore scroll position after navigation
  useEffect(() => {
    if (navbarContentRef.current) {
      navbarContentRef.current.scrollTop = scrollPosition;
    }
  }, [currentPath, scrollPosition]);

  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem("APPUSER");

      let currentUser;
      // Check if the value needs to be parsed
      if (typeof currentUserValue === "string") {
        try {
          currentUser = JSON.parse(currentUserValue);
        } catch (err) {
          console.error("Error parsing APPUSER:", err);
        }
      } else {
        currentUser = currentUserValue; // Assume it's already an object
      }

      // Safely access and set user details
      if (currentUser?.user) {
        const fullName = currentUser.user.fullName;
        const role = currentUser.user.role;
        setFullname(fullName);
        setRole(role);
      } else {
        console.error("Invalid user structure:", currentUser);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getFacilityLogo = async () => {
    try {
      const facilityFromState = location.state?.facility;

      if (facilityFromState?.logo) {
        const fullLogoUrl = `${process.env.REACT_APP_BASE_URL}/${facilityFromState.logo}`;
        localStorage.setItem("selectedFacilityLogo", fullLogoUrl);
        setFacilityLogo(fullLogoUrl);
      } else {
        const storedLogo = localStorage.getItem("selectedFacilityLogo");
        if (storedLogo) {
          setFacilityLogo(storedLogo);
        } else {
          setFacilityLogo(""); // Set empty string if no logo exists
        }
      }
    } catch (error) {
      console.error("Error fetching facility logo:", error);
      setFacilityLogo(""); // Set empty string on error
    }
  };

  const facilityId = useFacilityStore((state) => state.facilityId);
  const getFacilityModules = async () => {
    try {
      if (!facilityId) {
        console.log("No facility ID found");
        return;
      }

      const response = await makeRequest2(
        `${getFacilityURL}/${facilityId}`,
        "GET"
      );

      console.log("API Response:", response);
      console.log("Facility Modules:", response?.data?.facility?.modules);

      if (response?.success && response?.data?.facility?.modules) {
        setFacilityModules(response.data.facility.modules);
      } else {
        console.warn("Invalid or missing modules data in response");
      }
    } catch (error) {
      console.error("Error fetching facility modules:", error);
    }
  };

  const getInitialActiveIndex = (path) => {
    if (path.includes("visitor_access_management")) return 0;
    if (path.includes("levy_management")) return 1;
    if (path.includes("maintenance")) return 2;
    if (path.includes("lease_management")) return 3;
    if (path.includes("value_added_services")) return 4;
    if (path.includes("ticket_management")) return 5;
    if (path.includes("utility_management")) return 6; // Update this index
    if (path.includes("booking_management")) return 7; // And adjust these indices
    if (path.includes("handover_management")) return 8;
    if (path.includes("expense_management")) return 9;
    if (path.includes("campaigns")) return 10;
    return null;
  };

  useEffect(() => {
    getCurrentUser();
    getFacilityLogo();
    getFacilityModules();
  }, [location]);

  useEffect(() => {
    setActiveIndex(getInitialActiveIndex(currentPath));
  }, [currentPath]);

  const handleAccordionChange = (e) => {
    setActiveIndex(e.index);
  };

  return (
    <nav className="pc-sidebar">
      <div className="navbar-wrapper">
        <div className="m-header">
          <Link to={"/facility/"} className="b-brand text-primary">
            <img
              src={facilityLogo}
              className="img-fluid logo-lg"
              alt="logo"
              style={{ width: 150, height: 70 }}
            />
            <span className="badge bg-light-success rounded-pill ms-2 theme-version">
              v1.0
            </span>
          </Link>
        </div>
        <div className="navbar-content" style={{ overflowY: "scroll" }}>
          <div className="card pc-user-card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img
                    src="/assets/images/user/avatar-1.jpg"
                    alt="user-image"
                    className="user-avtar wid-45 rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 ms-3 me-2">
                  <h6 className="mb-0">{userName}</h6>
                </div>
                <Link
                  className="btn btn-icon btn-link-secondary avtar"
                  data-bs-toggle="collapse"
                  to="#pc_sidebar_userlink"
                >
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-sort-outline" />
                  </svg>
                </Link>
              </div>

              <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                <div className="pt-3">
                  <Link to={"/facility/settings_management/settings"}>
                    <i className="ti ti-settings" />
                    <span>Settings</span>
                  </Link>
                  <Link
                    to={"#!"}
                    onClick={() => {
                      HandleLogOut();
                    }}
                  >
                    <i className="ti ti-power" />
                    <span>Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ul className="pc-navbar mb-5">
            {userRole !== "guard" && (
              <li
                className={`pc-item ${currentPath === "/app/facilities/" ? "active" : ""
                  }`}
              >
                <Link to={"/app/facilities"} className="pc-link">
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-logout" />
                    </svg>
                  </span>

                  <span className="pc-mtext">Home Page</span>
                </Link>
              </li>
            )}

            {userRole === "guard" && (
              <li
                className={`pc-item ${currentPath === "/app/access_points" ? "active" : ""
                  }`}
              >
                <Link to={"/app/access_points"} className="pc-link">
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-logout" />
                    </svg>
                  </span>

                  <span className="pc-mtext">Home Page</span>
                </Link>
              </li>
            )}

            {userRole !== "guard" && (
              <li
                className={`pc-item ${currentPath === "/facility" ? "active" : ""
                  }`}
              >
                <Link to={"/facility/"} className="pc-link">
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-home" />
                    </svg>
                  </span>

                  <span className="pc-mtext">Dashboard</span>
                </Link>
              </li>
            )}
            {userRole !== "guard" && (
              <li
                className={`pc-item ${currentPath === "/facility/unit_management" ? "active" : ""
                  }`}
              >
                <Link to={"/facility/unit_management"} className="pc-link">
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-element-plus" />
                    </svg>
                  </span>

                  <span className="pc-mtext">Unit Management</span>
                </Link>
              </li>
            )}
            {userRole !== "guard" && (
              <li
                className={`pc-item ${currentPath === "/facility/customer_management/customers" ||
                  currentPath ===
                  "/facility/customer_management/add_new_customer"
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  to={"/facility/customer_management/customers"}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-profile-2user-outline" />
                    </svg>
                  </span>

                  <span className="pc-mtext">Customer Management</span>
                </Link>
              </li>
            )}
            {userRole !== "guard" && (
              <li
                className={`pc-item ${currentPath === "/facility/payment_management/" + facilityId
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  to={`/facility/payment_management/${facilityId}`}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-keyboard" />
                    </svg>
                  </span>
                  <span className="pc-mtext">Finance Management</span>
                </Link>
              </li>
            )}


            <Accordion
              activeIndex={activeIndex}
              onTabChange={handleAccordionChange}
            >
              {facilityModules.visitor && (
                <AccordionTab
                  header={
                    <div className="flex items-center text-dark">
                      <span>Visitor & Access </span>
                    </div>
                  }
                >
                  {userRole === "guard" && (
                    <li
                      className={`pc-item ${currentPath ===
                        "/facility/visitor_access_management/home/" ||
                        currentPath ===
                        "/facility/visitor_access_management/home"
                        ? "active"
                        : ""
                        }`}
                    >
                      <Link
                        to={"/facility/visitor_access_management/home/"}
                        className="pc-link"
                        onClick={handleLinkClick}
                      >
                        <span className="pc-micon">
                          <svg className="pc-icon">
                            <use xlinkHref="#custom-home" />
                          </svg>
                        </span>

                        <span className="pc-mtext">Home</span>
                      </Link>
                    </li>
                  )}

                  {userRole !== "guard" && (
                    <>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_access_management/digital_logs/"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/digital_logs/"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-keyboard" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Digital Logs</span>
                        </Link>
                      </li>
                    </>
                  )}

                  {userRole === "guard" && (
                    <>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_access_management/scan_qr_code/"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/scan_qr_code/"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-cpu-charge" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Scan QR Code</span>
                        </Link>
                      </li>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_access_management/verify_code/"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/verify_code/"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-24-support" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Verify Code</span>
                        </Link>
                      </li>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_management/gate_registration" ||
                          currentPath ===
                          "/facility/visitor_access_management/visitor_registration/gate_registration"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/visitor_registration/gate_registration"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-user-add" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Gate Registration</span>
                        </Link>
                      </li>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_management/deliveries" ||
                          currentPath ===
                          "/facility/visitor_access_management/visitor_registration/deliveries"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/visitor_registration/deliveries"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-shopping-bag" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Delivery</span>
                        </Link>
                      </li>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_access_management/waiting_list" ||
                          currentPath ===
                          "/facility/visitor_access_management/waiting_list"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/waiting_list"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-flag" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Waiting List</span>
                        </Link>
                      </li>
                    </>
                  )}

                  {userRole !== "guard" && (
                    <>
                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_access_management/guard_list" ||
                          currentPath ===
                          "/facility/visitor_access_management/guard_registration/register_guard"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={"/facility/visitor_access_management/guard_list"}
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-security-safe" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Guard Management</span>
                        </Link>
                      </li>

                      <li
                        className={`pc-item ${currentPath ===
                          "/facility/visitor_access_management/entries_and_exits" ||
                          currentPath ===
                          "/facility/visitor_access_management/entries_and_exits/add_entry_and_exit"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to={
                            "/facility/visitor_access_management/entries_and_exits"
                          }
                          className="pc-link"
                          onClick={handleLinkClick}
                        >
                          <span className="pc-micon">
                            <svg className="pc-icon">
                              <use xlinkHref="#custom-data" />
                            </svg>
                          </span>

                          <span className="pc-mtext">Entries & Exits</span>
                        </Link>
                      </li>
                    </>
                  )}
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.levy && (
                <AccordionTab
                  header={
                    <div className="flex items-center text-dark">
                      <span>Levy </span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath === "/facility/levy_management" ||
                      currentPath ===
                      "/facility/levy_management/levies/add_levy"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/levy_management"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-graph" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Levy List</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath === "/facility/levy_management/contracts" ||
                      currentPath ===
                      "/facility/levy_management/contracts/add_contract"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/levy_management/contracts"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-link" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Service Agreement</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath ===
                      "/facilitiy/levy_management/levy_invoicing"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facilitiy/levy_management/levy_invoicing"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-keyboard" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Levy Invoicing</span>
                    </Link>
                  </li>

                  {/* <li
                    className={`pc-item ${
                      currentPath === "/facilitiy/levy_management/reports"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      to={"/facilitiy/levy_management/reports"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-presentation-chart" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Reports</span>
                    </Link>
                  </li> */}

                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.maintenance && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Maintenance</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/maintenance/work_order_management"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/maintenance/work_order_management"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-simcard-2" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Work Order Management</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath === "/facilitiy/maintenance/sla"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facilitiy/maintenance/sla"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-note-1" />
                        </svg>
                      </span>
                      <span className="pc-mtext">SLA Management</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath === "/facilitiy/maintenance/asset"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facilitiy/maintenance/asset"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-layer" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Asset Management</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath ===
                      "/facilitiy/maintenance/stock_and_spare_parts"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facilitiy/maintenance/stock_and_spare_parts"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-clipboard" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Stock & Spare Parts</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath ===
                      "/facilitiy/maintenance/employee_management"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facilitiy/maintenance/employee_management"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-profile-2user-outline" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Staff Management</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.lease && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Lease</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/lease_management/lease_templates"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/lease_management/lease_templates"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-shapes" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Lease Templates</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath === "/facility/lease_management/leases"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/lease_management/leases"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-note-1" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Lease Agreements</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/lease_management/lease_invoices"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/lease_management/lease_invoices"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-direct-inbox" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Lease Invoices</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/lease_management/lease_penalties"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/lease_management/lease_penalties"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-keyboard" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Lease Penalties</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.vas && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>VAS</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/value_added_services/service_list"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/value_added_services/service_list"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <i className="fas fa-briefcase"></i>{" "}
                        {/* Font Awesome icon for services to display */}
                      </span>
                      <span className="pc-mtext">Added Services</span>
                    </Link>
                  </li>
                  {/* <li className={`pc-item ${currentPath === '/facility/value_added_services/service_requests' ? 'active' : ''}`}>
                  <Link to={'/facility/value_added_services/service_requests'} className="pc-link">
                    <span className="pc-micon">
                      <i className="fas fa-handshake"></i> {
                    </span>
                    <span className="pc-mtext">Service Requests</span>
                  </Link>
                </li> */}

                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/value_added_services/service_vendors"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/value_added_services/service_vendors"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <i className="fas fa-users"></i>{" "}
                        {/* Icon for Service Vendors */}
                      </span>
                      <span className="pc-mtext">Service Vendors</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/value_added_services/vas_invoices"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/value_added_services/vas_invoices"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <i className="fas fa-file-invoice"></i>{" "}
                        {/* Icon for Vas Invoices */}
                      </span>
                      <span className="pc-mtext">Vas Invoices</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.tickets && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Tickets</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/ticket_management/raise_ticket" ||
                      currentPath === "/facility/ticket_management/raise_ticket"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/ticket_management/raise_ticket"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-text-block" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Raise Ticket</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath === "/facility/ticket_management/tickets" ||
                      currentPath === "/facility/ticket_management/tickets"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/ticket_management/tickets"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-text-align-justify-center" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Raised Tickets</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.utility && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Utility</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath === "/facility/utility_management/" ||
                      currentPath === "/facility/utility_management/"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={
                        "/facility/utility_management/meter_types/"
                      }
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-cpu-charge" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Meters Management</span>
                    </Link>
                    {/* <Link
                      to={"/facility/utility_management/billing/"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-dollar-square" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Billing Management</span>
                    </Link> */}
                    {/* <Link
                      to={"/facility/utility_management/reports/"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-presentation-chart" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Reports Management</span>
                    </Link> */}
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.booking && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Booking</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath ===
                      "/facility/booking_management/unit_listing"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/booking_management/unit_listing"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-crop" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Unit Listing</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath === "#" ? "active" : ""}`}
                  >
                    <Link
                      to={"#"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-note-1" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Booking</span>
                    </Link>
                  </li>

                  <li
                    className={`pc-item ${currentPath === "#" ? "active" : ""}`}
                  >
                    <Link
                      to={"#"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-direct-inbox" />
                        </svg>
                      </span>

                      <span className="pc-mtext">Revenue</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.handover && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Handover</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath === "/facility/handover_management/handovers"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/handover_management/handovers/"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-row-vertical" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Handovers</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.expense && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Expense & Budget</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath === "/facility/expense_management" ||
                      currentPath === "/facility/expense_management"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/expense_management"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-dollar-square" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Expense</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath === "/facility/expense_management/reports" ||
                      currentPath === "/facility/expense_management/reports"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/expense_management/reports"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-fatrows" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Reports & Analytics</span>
                    </Link>
                  </li>
                  <li
                    className={`pc-item ${currentPath === "/facility/expense_management/budget" ||
                      currentPath === "/facility/expense_management/budget"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/expense_management/budget"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-status-up" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Budget</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

              {userRole !== "guard" && facilityModules.campaign && (
                <AccordionTab
                  header={
                    <div className="flex align-items-center text-dark">
                      <span>Campaigns</span>
                    </div>
                  }
                >
                  <li
                    className={`pc-item ${currentPath === "/facility/campaigns"
                      ? "active"
                      : ""
                      }`}
                  >
                    <Link
                      to={"/facility/campaigns"}
                      className="pc-link"
                      onClick={handleLinkClick}
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-row-vertical" />
                        </svg>
                      </span>
                      <span className="pc-mtext">Campaigns</span>
                    </Link>
                  </li>
                </AccordionTab>
              )}

            </Accordion>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Nav;
