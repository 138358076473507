import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import Layout from '../component/layout';
import { useFacilityServiceRequests } from './hooks/use_facility_service_requests';

function VasInvoices() {
    const [searchKeyword, setSearchKeyword] = useState("");
    const navigate = useNavigate();
    const toast = useRef(null);

    // Destructuring to match the hook's structure
    const {
        states: { loading, invoices, error },
        handlers: { downloadInvoicePDF, fetchInvoices }
    } = useFacilityServiceRequests();

    useEffect(() => {
        const loadInvoices = async () => {
            try {
                console.log('Loading VAS invoices...');
                await fetchInvoices();
            } catch (error) {
                console.error('Error in loadInvoices:', error);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.message || 'Failed to fetch invoices'
                });
            }
        };

        loadInvoices();
    }, []);

    useEffect(() => {
        console.log('Invoices state updated:', invoices);
    }, [invoices]);

    const statusTemplate = (rowData) => {
        const getSeverity = (status) => {
            switch (status?.toLowerCase()) {
                case "paid":
                    return { color: "#4caf50", bgColor: "#e8f5e9" };
                case "pending":
                    return { color: "#ff9800", bgColor: "#fff3e0" };
                case "overdue":
                    return { color: "#f44336", bgColor: "#ffebee" };
                case "cancelled":
                    return { color: "#757575", bgColor: "#f5f5f5" };
                default:
                    return { color: "#2196f3", bgColor: "#e3f2fd" };
            }
        };

        const statusStyle = getSeverity(rowData.status);

        return (
            <span
                style={{
                    fontWeight: "bold",
                    color: statusStyle.color,
                    padding: "5px 10px",
                    borderRadius: "15px",
                    backgroundColor: statusStyle.bgColor,
                }}
            >
                {rowData.status}
            </span>
        );
    };

    const actionTemplate = (rowData) => {
        const handleDownload = async (e) => {
            e.stopPropagation();
            try {
                await downloadInvoicePDF('printable-invoice', rowData);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Invoice downloaded successfully!'
                });
            } catch (error) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to download invoice'
                });
            }
        };

        return (
            <div className="flex gap-2">
                <Button
                    icon="ti ti-eye"
                    className="p-button-rounded p-button-success"
                    onClick={() => navigate(`/facility/value_added_services/invoice/${rowData._id}`)}
                    tooltip="View Details"
                />
                {/* <Button
                    icon="ti ti-download"
                    className="p-button-rounded p-button-primary"
                    onClick={handleDownload}
                    tooltip="Download Invoice"
                /> */}
            </div>
        );
    };

    const header = (
        <div className="flex align-items-center">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    placeholder="Search invoices..."
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    style={{ height: "50px" }}
                />
            </span>
        </div>
    );

    const filteredInvoices = invoices?.filter((invoice) => {
        if (!searchKeyword.trim()) return true;

        const searchFields = [
            invoice.customerName,
            invoice.serviceName,
            invoice.invoiceNumber,
            invoice.accountNumber,
            invoice.status,
            invoice.formattedAmount,
            invoice.formattedDate
        ];

        const searchString = searchFields.filter(Boolean).join(' ').toLowerCase();
        return searchString.includes(searchKeyword.toLowerCase());
    }) || [];

    return (
        <Layout>
            <Toast ref={toast} />
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/app/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">VAS Management</li>
                                <li className="breadcrumb-item">Invoices</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Service Invoices</h5>
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row py-2 mb-2">
                                            {header}
                                        </div>
                                        <DataTable
                                            value={filteredInvoices}
                                            loading={loading}
                                            emptyMessage="No invoices found."
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: "50rem" }}
                                            scrollable
                                            responsiveLayout="scroll"
                                        >
                                            <Column
                                                field="customerName"
                                                header="Customer"
                                            />
                                            <Column
                                                field="serviceName"
                                                header="Service"
                                            />
                                            <Column
                                                field="formattedAmount"
                                                header="Amount"
                                                align="right"
                                            />
                                            <Column
                                                field="formattedDate"
                                                header="Date"
                                            />
                                            <Column
                                                field="status"
                                                header="Status"
                                                body={statusTemplate}
                                            />
                                            <Column
                                                body={actionTemplate}
                                                header="Actions"
                                                style={{ width: '15%' }}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default VasInvoices;