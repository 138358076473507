import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const TransactionsTab = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [transactions, setTransactions] = useState([]); // Transactions state (empty by default)

    // Example: Replace this useEffect with your actual API call to fetch transactions
    useEffect(() => {
        // fetch('/api/transactions')
        //     .then(response => response.json())
        //     .then(data => setTransactions(data))
        //     .catch(err => console.error("Error fetching transactions:", err));
    }, []);

    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            paid: 'bg-success',
            partial: 'bg-warning',
            unpaid: 'bg-danger'
        };

        return (
            <span className={`badge ${statusColors[rowData.status]}`}>
                {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
            </span>
        );
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-KE', {
            style: 'currency',
            currency: 'KES'
        }).format(value);
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="col-md-3 col-xs-12 mb-3">
                    <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="dt-responsive table-responsive">
                    <DataTable
                        value={transactions}
                        emptyMessage="No transactions found."
                        sortMode="multiple"
                        paginator
                        rows={5}
                        stripedRows
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="transaction_ref" header="Transaction Ref" />
                        <Column field="customer" header="Customer" />
                        <Column field="unit" header="Unit No" />
                        <Column field="payment_method" header="Payment Method" />
                        <Column 
                            field="total_amount" 
                            header="Total Amount" 
                            body={(data) => formatCurrency(data.total_amount)} 
                        />
                        <Column field="added_on" header="Added On" />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default TransactionsTab;
