import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useParams } from 'react-router-dom';
import { makeRequest2 } from '../../../../../../utils/makeRequest';
import { toastify } from '../../../../../../utils/toast';
import { useFacilityStore } from '../../../../../../app/z-store/store';
import { smart_meters_base_url, customer_account_base_url } from "../../../../../../utils/urls";

const CustomerDetailsTab = ({ customer, setMeterData }) => {
    const [formData, setFormData] = useState({
        customerId: '',
        valveType: '',
        userType: '',
        meterValueType: '',
        meterValue: 0,
        balanceBF: 0
    });
    const { meterId } = useParams();
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);

    const valveOptions = [
        { label: 'Automatic', value: 'automatic' },
        { label: 'Manual', value: 'manual' }
    ];
    const paymentOptions = [
        { label: 'Prepaid', value: 'prepaid' },
        { label: 'Postpaid', value: 'postpaid' }
    ];

    // Prepopulate the form when assigning a customer
    useEffect(() => {
        if (showAssignModal && customer) {
            setFormData({
                customerId: customer.customerId || '',
                valveType: customer.valveType || '',
                userType: customer.userType || '',
                meterValueType: 'current',
                meterValue: customer.currentReading || 0,
                balanceBF: customer.balanceBF || 0
            });
        }
    }, [showAssignModal, customer]);

    useEffect(() => {
        if (showAssignModal && selectedFacilityId) {
            fetchCustomers();
        }
    }, [showAssignModal, selectedFacilityId]);

    // Fetch list of customers for the facility
    const fetchCustomers = async () => {
        try {
            setLoading(true);
            const response = await makeRequest2(
                `/api/app/customer_management/get_customers/${selectedFacilityId}`,
                'GET'
            );
            if (response.success) {
                const customersData = response.data?.customers || response.data || [];
                setCustomers(customersData);
            } else {
                toastify('Failed to fetch customers', 'error');
            }
        } catch (error) {
            toastify('Error loading customers', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Fetch updated smart meter data
    const fetchMeterData = async () => {
        try {
            setLoading(true);
            const response = await makeRequest2(
                `${smart_meters_base_url}/${meterId}`,
                'GET'
            );
            if (response.success) {
                // Update the parent or local state with the new meter data
                setMeterData && setMeterData(response.data);
            } else {
                toastify('Failed to fetch updated meter data', 'error');
            }
        } catch (error) {
            toastify('Error fetching meter data', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        if (!formData.customerId) {
            toastify('Please select a customer', 'error');
            return;
        }
        if (!formData.valveType) {
            toastify('Please select valve type', 'error');
            return;
        }
        if (!formData.userType) {
            toastify('Please select payment method', 'error');
            return;
        }
        setLoading(true);
        try {
            // 1. Update the smart meter with the assignment data
            const meterResponse = await makeRequest2(
                `${smart_meters_base_url}/${meterId}`,
                'PUT',
                formData
            );
            if (!meterResponse.success) {
                toastify(meterResponse.error || 'Failed to update smart meter', 'error');
                return;
            }
            toastify('Smart meter updated successfully', 'success');
    
            // 2. Build account data using the meter response and customer details
            const selectedCustomer = customers.find(cust => cust._id === formData.customerId);
            const accountData = {
                facilityId: selectedFacilityId,
                account_no: meterResponse.data.accountNumber,
                customerId: formData.customerId,
                customerName: selectedCustomer
                    ? (selectedCustomer.fullName || `${selectedCustomer.firstName} ${selectedCustomer.lastName}`)
                    : 'Unknown',
                phoneNumber: selectedCustomer ? selectedCustomer.phoneNumber : 'N/A',
                payment_type: formData.userType === 'prepaid' ? 'Prepaid' : 'Postpaid',
                previousReading: meterResponse.data.initialReading || 0,
                currentReading: formData.meterValue,
                meter_id: meterId
            };
    
            // 3. Create the customer account
            const accountResponse = await makeRequest2(
                `${customer_account_base_url}/add`,
                'POST',
                accountData
            );
            if (!accountResponse.success) {
                // If account creation fails, revert the smart meter update.
                await makeRequest2(
                    `${smart_meters_base_url}/${meterId}`,
                    'PUT',
                    { customerId: null }
                );
                toastify(
                    accountResponse.error || 'Failed to create customer account. Changes have been reverted.',
                    'error'
                );
                return;
            }
            handleCloseModal();
            // Re-fetch updated meter data after successful operations
            fetchMeterData();
        } catch (error) {
            toastify('Error assigning customer', 'error');
        } finally {
            setLoading(false);
        }
    };
    
    const handleCloseModal = () => {
        setFormData({
            customerId: '',
            valveType: '',
            userType: '',
            meterValueType: '',
            meterValue: 0,
            balanceBF: 0
        });
        setShowAssignModal(false);
    };

    // Function to handle deactivating an account.
    const handleDeactivateAccount = async () => {
        setLoading(true);
        try {
            // 1. Deactivate the customer account
            const accountResponse = await makeRequest2(
                `${customer_account_base_url}/deactivate/${meterId}`,
                'PUT'
            );
            if (!accountResponse.success) {
                toastify(accountResponse.error || 'Failed to deactivate account', 'error');
                return;
            }

            const meterUpdateData = { customerId: null }; 
            const meterResponse = await makeRequest2(
                `${smart_meters_base_url}/${meterId}`,
                'PUT',
                meterUpdateData
            );
            if (!meterResponse.success) {
                toastify(meterResponse.error || 'Failed to update smart meter', 'error');
                return;
            }

            toastify('Account deactivated successfully', 'success');
            // Re-fetch meter data after successful updates
            fetchMeterData();
        } catch (error) {
            toastify('Error during deactivation process', 'error');
        } finally {
            setLoading(false);
            setShowDeactivateDialog(false);
        }
    };

    const formatCustomerName = (cust) => {
        if (cust.firstName && cust.lastName) return `${cust.firstName} ${cust.lastName}`;
        if (cust.fullName) return cust.fullName;
        if (cust.name) return cust.name;
        return 'Unnamed Customer';
    };

    // Render card views for Account Balance and Valve Type
    const renderCustomerCardViews = () => (
        <div className="row mb-3">
            <div className="col-md-6 mb-3">
                <div className="card">
                    <div className="card-body text-center">
                        <h6>Account Balance (KES)</h6>
                        <p className="mb-0">
                            {customer?.accountBalance
                                ? new Intl.NumberFormat('en-KE', {
                                      style: 'currency',
                                      currency: 'KES'
                                  }).format(customer.accountBalance)
                                : 'KES 0.00'}
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card">
                    <div className="card-body text-center">
                        <h6>Valve Type</h6>
                        <p className="mb-0">{customer?.valveType || 'N/A'}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    // Updated function: Renders customer details in a horizontal table format
    const renderCustomerInfo = () => (
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Phone Number</th>
                        <th>House No</th>
                        <th>Payment Type</th>
                        <th>Balance B/F</th>
                        <th>Account Balance (KES)</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{customer?.CustomerInfo?.fullName || 'N/A'}</td>
                        <td>{customer?.CustomerInfo?.phoneNumber || 'N/A'}</td>
                        <td>{customer?.UnitInfo?.name || 'N/A'}</td>
                        <td>{customer?.userType || 'N/A'}</td>
                        <td>
                            {customer?.balanceBF
                                ? new Intl.NumberFormat('en-KE', {
                                      style: 'currency',
                                      currency: 'KES'
                                  }).format(customer.balanceBF)
                                : 'KES 0.00'}
                        </td>
                        <td>
                            {customer?.accountBalance
                                ? new Intl.NumberFormat('en-KE', {
                                      style: 'currency',
                                      currency: 'KES'
                                  }).format(customer.accountBalance)
                                : 'KES 0.00'}
                        </td>
                        <td>{customer?.status || 'N/A'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    const renderAssignModal = () => (
        <Dialog
            header="Assign Customer"
            visible={showAssignModal}
            onHide={handleCloseModal}
            style={{ width: '50vw' }}
            modal
            className="p-fluid assign-customer-dialog"
        >
            <div className="container py-2">
                {/* Customer selection */}
                <div className="row mb-2">
                    <div className="col-12">
                        <label className="form-label text-sm mb-1">Customer</label>
                        <select
                            className="form-select form-select-sm"
                            value={formData.customerId}
                            onChange={(e) => handleInputChange('customerId', e.target.value)}
                            disabled={loading}
                        >
                            <option value="">-- Select --</option>
                            {customers.map((cust) => (
                                <option key={cust._id} value={cust._id}>
                                    {formatCustomerName(cust)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Valve and Payment Method */}
                <div className="row mb-2">
                    <div className="col-md-6">
                        <label className="form-label text-sm mb-1">Close/Open Valve</label>
                        <select
                            className="form-select form-select-sm"
                            value={formData.valveType}
                            onChange={(e) => handleInputChange('valveType', e.target.value)}
                            disabled={loading}
                        >
                            <option value="">Choose one option</option>
                            {valveOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label text-sm mb-1">Choose Billing Method</label>
                        <select
                            className="form-select form-select-sm"
                            value={formData.userType}
                            onChange={(e) => handleInputChange('userType', e.target.value)}
                            disabled={loading}
                        >
                            <option value="">Choose one option</option>
                            {paymentOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Meter Value Section */}
                <div className="row mb-2">
                    <div className="col-md-6">
                        <label className="form-label text-sm mb-1">Meter Value Type</label>
                        <select
                            className="form-select form-select-sm"
                            value={formData.meterValueType}
                            onChange={(e) => handleInputChange('meterValueType', e.target.value)}
                            disabled={loading}
                        >
                            <option value="">-- Select --</option>
                            <option value="current">Current Value</option>
                            <option value="initial">Initial Value</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label text-sm mb-1">Meter Value</label>
                        <InputText
                            type="number"
                            className="form-control form-control-sm"
                            value={formData.meterValue}
                            onChange={(e) => handleInputChange('meterValue', e.target.value)}
                            disabled={loading}
                        />
                    </div>
                </div>

                {/* Balance B/F */}
                <div className="row mb-2">
                    <div className="col-md-6">
                        <label className="form-label text-sm mb-1">Balance B/F</label>
                        <InputText
                            type="number"
                            className="form-control form-control-sm"
                            value={formData.balanceBF}
                            onChange={(e) => handleInputChange('balanceBF', e.target.value)}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end gap-2 mt-3">
                    <Button
                        label="Close"
                        className="btn btn-outline-secondary btn-sm"
                        onClick={handleCloseModal}
                        disabled={loading}
                    />
                    <Button
                        label={loading ? 'Submitting...' : 'Submit'}
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit}
                        disabled={loading}
                    />
                </div>
            </div>
        </Dialog>
    );

    // Confirmation dialog for deactivation
    const renderDeactivateDialog = () => (
        <Dialog
            header="Confirm Deactivation"
            visible={showDeactivateDialog}
            onHide={() => setShowDeactivateDialog(false)}
            style={{ width: '30vw' }}
            modal
        >
            <div>
                <p>Are you sure you want to deactivate this account?</p>
                <div className="d-flex justify-content-end gap-2 mt-3">
                    <Button
                        label="Cancel"
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() => setShowDeactivateDialog(false)}
                        disabled={loading}
                    />
                    <Button
                        label={loading ? 'Processing...' : 'Confirm'}
                        className="btn btn-danger btn-sm"
                        onClick={handleDeactivateAccount}
                        disabled={loading}
                    />
                </div>
            </div>
        </Dialog>
    );

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2">
                            {customer && customer.CustomerInfo ? (
                                <Button
                                    label="Deactivate Account"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => setShowDeactivateDialog(true)}
                                />
                            ) : (
                                <Button
                                    label="Assign Customer"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => setShowAssignModal(true)}
                                />
                            )}
                        </div>
                    </div>
                    <div className="card-body">
                        {/* Card views for Account Balance and Valve Type */}
                        {renderCustomerCardViews()}
                        {/* Table with remaining customer details */}
                        {renderCustomerInfo()}
                    </div>
                </div>
            </div>
            {renderAssignModal()}
            {renderDeactivateDialog()}
        </div>
    );
};

export default CustomerDetailsTab;
