import React, { useState, useEffect } from "react";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { toastify } from "../../../../utils/toast";
import { Button } from "primereact/button";
import { getItem } from "../../../../utils/localStorage";
import { terminate_lease } from "../../../../utils/urls";

const TerminateLease = ({ leaseId, initialStatus, fetchLeases }) => {
    const [status, setStatus] = useState(initialStatus);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setStatus(initialStatus);
    }, [initialStatus]);

    const canTerminate = (currentStatus) => {
        // Can only terminate active leases
        return currentStatus === 'Active';
    };

    const handleTerminateLease = async () => {
        try {
            if (!canTerminate(status)) {
                toastify(
                    status === 'Expired' 
                        ? "Cannot terminate an expired lease" 
                        : "Lease cannot be terminated in its current status",
                    "error"
                );
                return;
            }

            setIsLoading(true);
            const facilityId = await getItem("selectedFacilityId");
            if (!facilityId) {
                throw new Error("Facility ID not found. Please select a facility first.");
            }

            const response = await makeRequest2(
                `${terminate_lease}/${facilityId}/${leaseId}`,
                "POST",
                {}
            );

            if (response.success || response.message) {
                setStatus('Terminated');
                toastify(response.message || "Lease terminated successfully.", "success");
                await fetchLeases();
            } else {
                throw new Error(response.error || "Failed to terminate lease.");
            }
        } catch (err) {
            //console.error("Error terminating lease:", err);
            toastify(err.message || "An error occurred while terminating the lease.", "error");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            icon="ti ti-lock"
            className={`p-button-rounded ${
                status === "Terminated" ? "p-button-danger" : "p-button-warning"
            } mr-2`}
            onClick={handleTerminateLease}
            disabled={isLoading || !canTerminate(status)}
            tooltip={
                status === "Terminated" 
                    ? "Terminated" 
                    : !canTerminate(status) 
                        ? `Cannot terminate ${status.toLowerCase()} lease`
                        : "Terminate Lease"
            }
        />
    );
};

export default TerminateLease;
