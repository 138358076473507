// components/lease/reminders/EditReminder.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { 
  get_lease_reminder,
  update_lease_reminder,
  toggle_lease_reminder_status 
} from "../../../../utils/urls";
import moment from 'moment-timezone';

const EditReminder = () => {
  const navigate = useNavigate();
  const { reminderId } = useParams();
  const timezones = moment.tz.names();

  const [loading, setLoading] = useState(true);
  const [reminderData, setReminderData] = useState({
    name: "",
    type: "standard",
    remindOn: {
      invoiceDate: true,
      dueDate: false,
      afterOverdue: {
        enabled: false,
        days: []
      }
    },
    time: "09:00",
    notificationTypes: [],
    message: "",
    timezone: "UTC",
    isActive: true
  });

  useEffect(() => {
    fetchReminderData();
  }, [reminderId]);

  const fetchReminderData = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${get_lease_reminder}/${facilityId}/${reminderId}`,
        "GET"
      );

      if (response.success) {
        const reminder = response.data;
        setReminderData({
          name: reminder.name,
          type: reminder.type,
          remindOn: reminder.remindOn,
          time: reminder.time,
          notificationTypes: reminder.notificationTypes,
          message: reminder.message || "",
          timezone: reminder.timezone,
          isActive: reminder.isActive
        });
      } else {
        toastify("Failed to fetch reminder details", "error");
        navigate(-1);
      }
    } catch (error) {
      //console.error("Error fetching reminder:", error);
      toastify(error.message || "An unexpected error occurred", "error");
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReminderData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNotificationTypeChange = (type) => {
    setReminderData(prev => {
      const currentTypes = [...prev.notificationTypes];
      const index = currentTypes.indexOf(type);
      
      if (index === -1) {
        currentTypes.push(type);
      } else {
        currentTypes.splice(index, 1);
      }

      return {
        ...prev,
        notificationTypes: currentTypes
      };
    });
  };

  const handleRemindOnChange = (field, value) => {
    setReminderData(prev => ({
      ...prev,
      remindOn: {
        ...prev.remindOn,
        [field]: value
      }
    }));
  };

  const handleOverdueDaysChange = (day) => {
    setReminderData(prev => {
      const currentDays = [...prev.remindOn.afterOverdue.days];
      const index = currentDays.indexOf(day);
      
      if (index === -1) {
        currentDays.push(day);
      } else {
        currentDays.splice(index, 1);
      }

      return {
        ...prev,
        remindOn: {
          ...prev.remindOn,
          afterOverdue: {
            ...prev.remindOn.afterOverdue,
            days: currentDays.sort((a, b) => a - b)
          }
        }
      };
    });
  };

  const validateForm = () => {
    if (!reminderData.name.trim()) {
      toastify("Reminder name is required", "error");
      return false;
    }

    if (reminderData.notificationTypes.length === 0) {
      toastify("At least one notification type is required", "error");
      return false;
    }

    if (reminderData.remindOn.afterOverdue.enabled && 
        reminderData.remindOn.afterOverdue.days.length === 0) {
      toastify("Please select at least one overdue reminder day", "error");
      return false;
    }

    if (!reminderData.time.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
      toastify("Please enter a valid time in HH:mm format", "error");
      return false;
    }

    if (reminderData.message && reminderData.message.length > 500) {
      toastify("Message cannot exceed 500 characters", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const facilityId = await getItem("selectedFacilityId");

      const response = await makeRequest2(
        `${update_lease_reminder}/${facilityId}/${reminderId}`,
        "PUT",
        reminderData
      );

      if (response.success) {
        toastify("Reminder updated successfully", "success");
        navigate(-1);
      } else {
        toastify(response.error || "Failed to update reminder", "error");
      }
    } catch (error) {
      //console.error("Error updating reminder:", error);
      toastify(error.message || "An unexpected error occurred", "error");
    }
  };

  const handleToggleStatus = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      
      const response = await makeRequest2(
        `${toggle_lease_reminder_status}/${facilityId}/${reminderId}`,
        "PATCH",
        { isActive: !reminderData.isActive }
      );

      if (response.success) {
        setReminderData(prev => ({
          ...prev,
          isActive: !prev.isActive
        }));
        toastify(`Reminder ${!reminderData.isActive ? 'activated' : 'deactivated'} successfully`, "success");
      } else {
        toastify(response.error || "Failed to update reminder status", "error");
      }
    } catch (error) {
      //console.error("Error toggling reminder status:", error);
      toastify(error.message || "An unexpected error occurred", "error");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/lease_management/reminders">
                    Lease Reminders
                  </Link>
                </li>
                <li className="breadcrumb-item">Edit Reminder</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Edit Reminder</h5>
              <Button
                type="button"
                className={`btn ${reminderData.isActive ? 'btn-danger' : 'btn-success'}`}
                onClick={handleToggleStatus}
              >
                {reminderData.isActive ? 'Deactivate' : 'Activate'} Reminder
              </Button>
            </div>
            <div className="card-body">
              {/* Same form fields as CreateReminder */}
              <div className="row">
                <div className="col-sm-6">
                  <label className="form-label">Reminder Name</label>
                  <InputText
                    className="form-control"
                    name="name"
                    value={reminderData.name}
                    onChange={handleInputChange}
                    placeholder="Enter reminder name"
                  />
                </div>

                <div className="col-sm-6">
                  <label className="form-label">Reminder Type</label>
                  <select
                    className="form-control"
                    name="type"
                    value={reminderData.type}
                    onChange={handleInputChange}
                  >
                    <option value="standard">Standard</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div className="col-sm-6 mt-3">
                  <label className="form-label">Notification Types</label>
                  <div className="d-flex gap-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={reminderData.notificationTypes.includes('SMS')}
                        onChange={() => handleNotificationTypeChange('SMS')}
                        id="smsCheck"
                      />
                      <label className="form-check-label" htmlFor="smsCheck">
                        SMS
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={reminderData.notificationTypes.includes('EMAIL')}
                        onChange={() => handleNotificationTypeChange('EMAIL')}
                        id="emailCheck"
                      />
                      <label className="form-check-label" htmlFor="emailCheck">
                        Email
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <label className="form-label">Time</label>
                  <InputText
                    type="time"
                    className="form-control"
                    name="time"
                    value={reminderData.time}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-sm-6 mt-3">
                  <label className="form-label">Timezone</label>
                  <select
                    className="form-control"
                    name="timezone"
                    value={reminderData.timezone}
                    onChange={handleInputChange}
                  >
                    {timezones.map(tz => (
                      <option key={tz} value={tz}>{tz}</option>
                    ))}
                  </select>
                </div>

                <div className="col-12 mt-4">
                  <h6 className="mb-3">Remind On</h6>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={reminderData.remindOn.invoiceDate}
                          onChange={(e) => handleRemindOnChange('invoiceDate', e.target.checked)}
                          id="invoiceDateCheck"
                        />
                        <label className="form-check-label" htmlFor="invoiceDateCheck">
                          Invoice Date
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={reminderData.remindOn.dueDate}
                          onChange={(e) => handleRemindOnChange('dueDate', e.target.checked)}
                          id="dueDateCheck"
                        />
                        <label className="form-check-label" htmlFor="dueDateCheck">
                          Due Date
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={reminderData.remindOn.afterOverdue.enabled}
                          onChange={(e) => handleRemindOnChange('afterOverdue', {
                            enabled: e.target.checked,
                            days: e.target.checked ? reminderData.remindOn.afterOverdue.days : []
                          })}
                          id="overdueCheck"
                        />
                        <label className="form-check-label" htmlFor="overdueCheck">
                          After Overdue
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {reminderData.remindOn.afterOverdue.enabled && (
                  <div className="col-12 mt-3">
                    <label className="form-label">Overdue Reminder Days</label>
                    <div className="d-flex gap-3">
                      {[1, 3, 7].map(day => (
                        <div className="form-check" key={day}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={reminderData.remindOn.afterOverdue.days.includes(day)}
                            onChange={() => handleOverdueDaysChange(day)}
                            id={`day${day}Check`}
                          />
                          <label className="form-check-label" htmlFor={`day${day}Check`}>
                            {day} {day === 1 ? 'Day' : 'Days'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="col-12 mt-3">
                  <label className="form-label">Custom Message (Optional)</label>
                  <textarea
                    className="form-control"
                    name="message"
                    value={reminderData.message}
                    onChange={handleInputChange}
                    rows="3"
                    maxLength="500"
                    placeholder="Enter custom reminder message"
                  />
                  <small className="text-muted">
                    {reminderData.message.length}/500 characters
                  </small>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Update Reminder
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditReminder;