import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import Select from "react-select";
import {
  getUnitsForFacility,
  getCustomersURL,
  update_lease,
  get_lease_templates,
  get_lease_penalties,
  get_lease_reminders,
  GET_CURRENCIES_URL,
  showLevySettings
} from "../../../../utils/urls";

const UpdateLease = ({ visible, onHide, leaseData, onUpdate }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);

  // Basic Information State
  const [unitNumber, setUnitNumber] = useState("");
  const [landlordId, setLandlordId] = useState("");
  const [tenant, setTenant] = useState("");
  const [status, setStatus] = useState("Active");

  // Lease Terms State
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [leaseDuration, setLeaseDuration] = useState("");
  const [autoRenewal, setAutoRenewal] = useState(false);

  // Financial Terms State
  const [monthlyRent, setMonthlyRent] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([
    { type: "", details: {}, isPrimary: true }
  ]);
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [balanceBroughtForward, setBalanceBroughtForward] = useState("0"); // Added for balance brought forward

  // Billing Cycle State
  const [billingFrequency, setBillingFrequency] = useState("Monthly");
  const [nextInvoiceDate, setNextInvoiceDate] = useState(null);
  const [autoSend, setAutoSend] = useState(false);

  // Lease Template State
  const [leaseTemplate, setLeaseTemplate] = useState("");

  // Penalty & Reminder State
  const [selectedPenaltyId, setSelectedPenaltyId] = useState(null);
  const [selectedReminderId, setSelectedReminderId] = useState(null);

  // Dropdown Options State
  const [units, setUnits] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [landlords, setLandlords] = useState([]);
  const [leaseTemplates, setLeaseTemplates] = useState([]);
  const [penalties, setPenalties] = useState([]);
  const [reminders, setReminders] = useState([]);

  const [levySettings, setLevySettings] = useState({
    bankName: "",
    accountNumber: "",
  });

  // Loading State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && leaseData) {
      initializeFormData();
      fetchDropdownData();
    }
  }, [visible, leaseData]);

  const initializeFormData = () => {
    if (!leaseData) return;
  
    //Set currency
    if (leaseData.currency) {
      setSelectedCurrency({
        value: leaseData.currency._id,
        label: `${leaseData.currency.currencyName} (${leaseData.currency.currencyShortCode})`,
        currencyShortCode: leaseData.currency.currencyShortCode
      });
    }
  
    // Set basic information
    setUnitNumber(leaseData.unitNumber || "");
    setLandlordId(leaseData.landlord || "");
    setTenant(leaseData.tenant || "");
    setStatus(leaseData.status || "Active");
  
    // Set lease terms
    setStartDate(leaseData.leaseTerms?.startDate ? new Date(leaseData.leaseTerms.startDate) : null);
    setEndDate(leaseData.leaseTerms?.endDate ? new Date(leaseData.leaseTerms.endDate) : null);
    setLeaseDuration(leaseData.leaseTerms?.duration?.toString() || "");
    setAutoRenewal(leaseData.leaseTerms?.autoRenewal || false);
  
    // Set financial terms
    setMonthlyRent(leaseData.financialTerms?.monthlyRent?.toString() || "");
    setPaymentDueDate(leaseData.financialTerms?.paymentDueDate?.toString() || "");
    setSecurityDeposit(leaseData.financialTerms?.securityDeposit?.toString() || "");
    
    // Set balance brought forward - use 0 as default if not available
    setBalanceBroughtForward(leaseData.financialTerms?.balanceBroughtForward?.toString() || "0");
    
    // Initialize payment methods with special handling for M-Pesa
    if (leaseData.financialTerms?.paymentMethods && leaseData.financialTerms.paymentMethods.length > 0) {
      const initialPaymentMethods = leaseData.financialTerms.paymentMethods.map(method => {
        // Check if this is an M-Pesa payment method
        const isMpesa = method.type === "Bank Transfer" && 
                       (method.details?.bankName === "M-Pesa" || 
                        method.details?.bankName === "Mpesa") &&
                       leaseData.financialTerms?.mpesaEnabled;
        
        if (isMpesa) {
          return {
            type: "Mobile Money",
            details: {
              isMpesaEnabled: true,
              provider: "Mpesa",
              phoneNumber: method.details.accountNumber || "",
              accountName: method.details.accountName || ""
            },
            isPrimary: method.isPrimary
          };
        }
        
        // For other payment methods, keep them as is
        return method;
      });
      
      setPaymentMethods(initialPaymentMethods);
    } else {
      setPaymentMethods([{ type: "", details: {}, isPrimary: true }]);
    }
    
    setSelectedPenaltyId(leaseData.financialTerms?.penaltyId || null);
  
    // Set billing cycle
    setBillingFrequency(leaseData.billingCycle?.frequency || "Monthly");
    setNextInvoiceDate(leaseData.billingCycle?.nextInvoiceDate ? new Date(leaseData.billingCycle.nextInvoiceDate) : null);
    setAutoSend(leaseData.billingCycle?.autoSend || false);
  
    // Set template and reminder
    setLeaseTemplate(leaseData.leaseTemplate || "");
    setSelectedReminderId(leaseData.reminders?.[0]?.reminderId || null);
  };

  const fetchDropdownData = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      
      const [
        unitsResponse,
        customersResponse,
        templatesResponse,
        penaltiesResponse,
        remindersResponse,
        currenciesResponse,
        levySettingsResponse  // Add this
      ] = await Promise.all([
        makeRequest2(`${getUnitsForFacility}/${facilityId}`, "GET"),
        makeRequest2(`${getCustomersURL}/${facilityId}`, "GET"),
        makeRequest2(`${get_lease_templates}/${facilityId}`, "GET"),
        makeRequest2(`${get_lease_penalties}/${facilityId}`, "GET"),
        makeRequest2(`${get_lease_reminders}/${facilityId}`, "GET"),
        makeRequest2(`${GET_CURRENCIES_URL}/${facilityId}`, "GET"),
        makeRequest2(`${showLevySettings}/${facilityId}`, "GET")  // Add this
      ]);

      // Add the missing code for handling other responses
if (unitsResponse.success) {
  setUnits(unitsResponse.data);
}

if (customersResponse.success) {
  const tenantCustomers = customersResponse.data.filter(
    customer => customer.customerType?.toLowerCase() === "tenant"
  );
  const landlordCustomers = customersResponse.data.filter(
    customer => customer.customerType?.toLowerCase() === "home owner"
  );
  setTenants(tenantCustomers);
  setLandlords(landlordCustomers);
}

if (templatesResponse.success) {
  setLeaseTemplates(templatesResponse.data);
}

if (penaltiesResponse.success) {
  setPenalties(penaltiesResponse.data?.data?.filter(p => p.isActive) || []);
}

if (remindersResponse.success) {
  setReminders(remindersResponse.data?.data?.filter(r => r.isActive) || []);
}
  
      // Add levy settings handling
      if (levySettingsResponse.success && levySettingsResponse.data?.settings) {
        setLevySettings(levySettingsResponse.data.settings);
      }
  
      if (currenciesResponse.success) {
        const currencyData = currenciesResponse.data.data || currenciesResponse.data;
        const currenciesArray = Array.isArray(currencyData) ? currencyData : [currencyData];
        setAvailableCurrencies(currenciesArray);
      }
  
      // Rest of your existing code...
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      toastify("Error loading form data", "error");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!leaseData?._id) {
      toastify("Invalid lease data", "error");
      return false;
    }

    if (!selectedCurrency) {
      toastify("Currency is required", "error");
      return false;
    }

    if (!monthlyRent || parseFloat(monthlyRent) <= 0) {
      toastify("Valid monthly rent is required", "error");
      return false;
    }

    if (!paymentDueDate) {
      toastify("Payment due date is required", "error");
      return false;
    }

    if (!securityDeposit || parseFloat(securityDeposit) <= 0) {
      toastify("Valid security deposit is required", "error");
      return false;
    }

    // Validate balance brought forward - it must be a valid number
    if (balanceBroughtForward === "" || isNaN(parseFloat(balanceBroughtForward))) {
      toastify("Balance brought forward is required and must be a number", "error");
      return false;
    }

    if (!startDate || !endDate) {
      toastify("Lease start and end dates are required", "error");
      return false;
    }

    if (new Date(startDate) >= new Date(endDate)) {
      toastify("End date must be after start date", "error");
      return false;
    }

    // Validate payment methods
    if (paymentMethods.length === 0) {
      toastify("At least one payment method is required", "error");
      return false;
    }

    // Add the new payment method validation here
    const validPaymentMethod = paymentMethods.every(method => {
      if (!method.type) {
        toastify("Payment method type is required", "error");
        return false;
      }

      if (method.type === "Bank Transfer") {
        if (!method.details?.bankName || !method.details?.accountNumber) {
          toastify("Bank name and account number are required for bank transfer", "error");
          return false;
        }
      }

      if (method.type === "Mobile Money" && !method.details?.isMpesaEnabled) {
        if (!method.details?.provider || !method.details?.phoneNumber) {
          toastify("Provider and phone number are required for mobile money", "error");
          return false;
        }
      }

      return true;
    });

    if (!validPaymentMethod) return false;

    return true;
};

  

  const handleAddPaymentMethod = () => {
    setPaymentMethods([
      ...paymentMethods,
      { type: "", details: {}, isPrimary: paymentMethods.length === 0 }
    ]);
  };

  const handleRemovePaymentMethod = (index) => {
    const updatedMethods = paymentMethods.filter((_, i) => i !== index);
    if (paymentMethods[index].isPrimary && updatedMethods.length > 0) {
      updatedMethods[0].isPrimary = true;
    }
    setPaymentMethods(updatedMethods);
  };

  const handlePaymentMethodChange = (index, field, value) => {
    const updatedMethods = [...paymentMethods];
    if (field === "type") {
      updatedMethods[index] = {
        ...updatedMethods[index],
        type: value,
        details: {}
      };
    } else if (field === "details") {
      updatedMethods[index] = {
        ...updatedMethods[index],
        details: value
      };
    }
    setPaymentMethods(updatedMethods);
  };

  const handleSetPrimary = (index) => {
    const updatedMethods = paymentMethods.map((method, i) => ({
      ...method,
      isPrimary: i === index
    }));
    setPaymentMethods(updatedMethods);
  };

  const calculateDuration = (start, end) => {
    if (!start || !end) return "";
    const startDate = new Date(start);
    const endDate = new Date(end);
    const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());
    return monthDiff.toString();
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setStartDate(value);
      if (endDate) {
        setLeaseDuration(calculateDuration(value, endDate));
      }
    } else {
      setEndDate(value);
      if (startDate) {
        setLeaseDuration(calculateDuration(startDate, value));
      }
    }
  };

  const handleMpesaToggle = (index) => {
    const updatedMethods = [...paymentMethods];
    const currentMethod = updatedMethods[index];
  
    if (currentMethod.type === "Mobile Money") {
      const isMpesaEnabled = !currentMethod.details?.isMpesaEnabled;
      
      updatedMethods[index] = {
        ...currentMethod,
        details: {
          ...currentMethod.details,
          isMpesaEnabled,
          provider: isMpesaEnabled ? "Mpesa" : currentMethod.details?.provider || "",
          phoneNumber: isMpesaEnabled ? levySettings.accountNumber : currentMethod.details?.phoneNumber || "",
          accountName: isMpesaEnabled ? levySettings.bankName : currentMethod.details?.accountName || ""
        }
      };
      
      setPaymentMethods(updatedMethods);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) return;
  
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
  
      // Format payment methods
      const formattedPaymentMethods = paymentMethods.map(method => {
        let formattedMethod = {
          isPrimary: Boolean(method.isPrimary)
        };
      
        if (method.type === "Mobile Money") {
          formattedMethod.type = "Bank Transfer";
          formattedMethod.details = {
            bankName: method.details.isMpesaEnabled ? "M-Pesa" : method.details.provider,
            accountNumber: method.details.isMpesaEnabled ? levySettings.accountNumber : method.details.phoneNumber,
            accountName: method.details.isMpesaEnabled ? levySettings.bankName : method.details.accountName,
            branch: "",
            swiftCode: ""
          };
        } else {
          formattedMethod.type = method.type;
          formattedMethod.details = { ...method.details };
        }
      
        return formattedMethod;
      });
  
      // Check if any payment method is using M-Pesa
      const hasMpesaPayment = paymentMethods.some(
        method => method.type === "Mobile Money" && method.details?.isMpesaEnabled
      );
  
      const updateData = {
        currency: selectedCurrency.value,
        leaseTerms: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          duration: parseInt(leaseDuration),
          autoRenewal
        },
        financialTerms: {
          monthlyRent: parseFloat(monthlyRent),
          paymentDueDate: parseInt(paymentDueDate),
          paymentMethods: formattedPaymentMethods,
          securityDeposit: parseFloat(securityDeposit),
          depositMonths: parseInt(leaseData.financialTerms?.depositMonths || 2), // Use existing or default to 2
          balanceBroughtForward: parseFloat(balanceBroughtForward), // Add balance brought forward
          penaltyId: selectedPenaltyId,
          mpesaEnabled: hasMpesaPayment // Set based on payment methods
        },
        billingCycle: {
          frequency: billingFrequency,
          nextInvoiceDate: nextInvoiceDate ? nextInvoiceDate.toISOString() : null,
          autoSend
        },
        status,
        reminders: selectedReminderId ? [{
          reminderId: selectedReminderId,
          status: 'Pending'
        }] : []
      };
  
      // Add M-Pesa details if enabled
      if (hasMpesaPayment) {
        updateData.financialTerms.mpesaDetails = {
          businessNumber: levySettings.accountNumber || "",
          accountNumber: levySettings.accountNumber || "",
          phoneNumber: levySettings.accountNumber || ""
        };
      }
  
      // Also add the required fields at the root level for validation compatibility
      updateData.monthlyRent = parseFloat(monthlyRent);
      updateData.paymentDueDate = parseInt(paymentDueDate);
      updateData.securityDeposit = parseFloat(securityDeposit);
      updateData.depositMonths = parseInt(leaseData.financialTerms?.depositMonths || 2);
      updateData.frequency = billingFrequency;
  
      console.log('Updating lease with data:', JSON.stringify(updateData, null, 2));
  
      const response = await makeRequest2(
        `${update_lease}/${facilityId}/${leaseData._id}`,
        "PUT",
        updateData
      );
  
      if (response.success) {
        toastify("Lease agreement updated successfully", "success");
        onUpdate();
        onHide();
      } else if (response.error && response.error.includes("201")) {
        // Handle 201 status code as success
        toastify("Lease agreement updated successfully", "success");
        onUpdate();
        onHide();
      } else {
        throw new Error(response.error || "Failed to update lease agreement");
      }
    } catch (error) {
      toastify(error.message || "Error updating lease agreement", "error");
    } finally {
      setLoading(false);
    }
  };

  const renderCurrencySection = () => (
    <div className="col-12 md:col-6">
      <label className="form-label">Currency <span className="text-danger">*</span></label>
      {availableCurrencies.length > 0 ? (
        <Select
          value={selectedCurrency}
          onChange={(selected) => setSelectedCurrency(selected)}
          options={availableCurrencies.map(curr => ({
            value: curr._id,
            label: `${curr.currencyName} (${curr.currencyShortCode})`,
            currencyShortCode: curr.currencyShortCode
          }))}
          isSearchable={false}
          className="react-select"
          classNamePrefix="select"
          placeholder="Select currency"
        />
      ) : (
        <div className="alert alert-warning p-2">
          <i className="ti ti-alert-circle me-2"></i>
          No currencies available
        </div>
      )}
    </div>
  );

  const renderMonetaryInput = (label, value, onChange, required = true) => (
    <div className="col-12 md:col-6 field">
      <label className="form-label">
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <div className="p-inputgroup">
        {selectedCurrency && (
          <span className="p-inputgroup-addon">
            {selectedCurrency.currencyShortCode}
          </span>
        )}
        <InputText
          type="number"
          value={value}
          onChange={onChange}
          className={!value && required ? 'p-invalid' : ''}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "90vw", maxWidth: "1200px" }}
      header="Edit Lease Agreement"
      modal
      className="p-fluid"
      footer={
        <div>
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
            disabled={loading}
          />
          <Button
            label="Save"
            icon="pi pi-check"
            className="p-button-primary"
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      }
      onHide={onHide}
    >
      <div className="grid">
        {/* Basic Information Section */}
        <div className="col-12 mb-4">
          <div className="surface-card p-4 shadow-2 border-round">
            <h5 className="mb-4">Basic Information</h5>
            <div className="grid">
               {/* Add currency section */}
               {renderCurrencySection()}

              <div className="col-12 md:col-6">
                <label className="form-label">Unit</label>
                <Dropdown
                  value={unitNumber}
                  options={units}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select Unit"
                  disabled={true}
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Landlord</label>
                <Dropdown
                  value={landlordId}
                  options={landlords}
                  optionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  optionValue="_id"
                  placeholder="Select Landlord"
                  disabled={true}
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Tenant</label>
                <Dropdown
                  value={tenant}
                  options={tenants}
                  optionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  optionValue="_id"
                  placeholder="Select Tenant"
                  disabled={true}
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Status</label>
                <Dropdown
                  value={status}
                  options={[
                    { label: 'Active', value: 'Active' },
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Expired', value: 'Expired' },
                    { label: 'Terminated', value: 'Terminated' }
                  ]}
                  onChange={(e) => setStatus(e.value)}
                  placeholder="Select Status"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Lease Terms Section */}
        <div className="col-12">
          <div className="card">
            <h5>Lease Terms</h5>
            <div className="grid">
              <div className="col-12 md:col-6">
                <label className="form-label">Start Date</label>
                <Calendar
                  value={startDate}
                  onChange={(e) => handleDateChange(e.value, "start")}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">End Date</label>
                <Calendar
                  value={endDate}
                  onChange={(e) => handleDateChange(e.value, "end")}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Lease Duration (Months)</label>
                <InputText value={leaseDuration} disabled />
              </div>
            </div>
          </div>
        </div>

        {/* Financial Terms Section */}
        <div className="col-12 mb-4">
          <div className="surface-card p-4 shadow-2 border-round">
            <div className="flex justify-content-between align-items-center mb-4">
              <h5 className="m-0">Financial Terms</h5>
            </div>
            <div className="grid">
              {renderMonetaryInput("Monthly Rent", monthlyRent, (e) => setMonthlyRent(e.target.value))}
              {renderMonetaryInput("Security Deposit", securityDeposit, (e) => setSecurityDeposit(e.target.value))}
              {renderMonetaryInput("Balance Brought Forward", balanceBroughtForward, (e) => setBalanceBroughtForward(e.target.value))}
              

              <div className="col-12 md:col-6">
                <label className="form-label">Payment Due Date</label>
                <Dropdown
                  value={paymentDueDate}
                  options={[
                    { label: '1st of the month', value: '1' },
                    { label: '5th of the month', value: '5' },
                    { label: '15th of the month', value: '15' },
                    { label: '30th of the month', value: '30' }
                  ]}
                  onChange={(e) => setPaymentDueDate(e.value)}
                  placeholder="Select Due Date"
                />
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="flex justify-content-between align-items-center mb-3">
                    <h6 className="m-0">Payment Methods</h6>
                    <Button
                      icon="ti ti-plus"
                      className="p-button-sm"
                      onClick={handleAddPaymentMethod}
                      label="Add Method"
                    />
                  </div>
                  
                  {paymentMethods.map((method, index) => (
                    <div key={index} className="p-3 border-1 surface-border border-round mb-3">
                      <div className="flex justify-content-between align-items-center mb-3">
                        <h6 className="m-0">Payment Method {index + 1}</h6>
                        <div className="flex gap-2">
                          <Button
                            icon="ti ti-star"
                            className={`p-button-sm ${method.isPrimary ? 'p-button-success' : 'p-button-outlined'}`}
                            onClick={() => handleSetPrimary(index)}
                            tooltip="Set as Primary"
                          />
                          <Button
                            icon="ti ti-trash"
                            className="p-button-sm p-button-danger"
                            onClick={() => handleRemovePaymentMethod(index)}
                            disabled={paymentMethods.length === 1}
                          />
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-12 mb-3">
                          <Dropdown
                            value={method.type}
                            options={[
                              { label: 'Bank Transfer', value: 'Bank Transfer' },
                              { label: 'Mobile Money', value: 'Mobile Money' },
                              { label: 'Cash', value: 'Cash' },
                              { label: 'Cheque', value: 'Cheque' }
                            ]}
                            onChange={(e) => handlePaymentMethodChange(index, "type", e.value)}
                            placeholder="Select Payment Method"
                          />
                        </div>

                        {method.type === "Bank Transfer" && (
                          <div className="grid col-12">
                            <div className="col-12 md:col-4">
                              <InputText
                                placeholder="Bank Name"
                                value={method.details?.bankName || ""}
                                onChange={(e) => handlePaymentMethodChange(index, "details", {
                                  ...method.details,
                                  bankName: e.target.value
                                })}
                              />
                            </div>
                            <div className="col-12 md:col-4">
                              <InputText
                                placeholder="Account Number"
                                value={method.details?.accountNumber || ""}
                                onChange={(e) => handlePaymentMethodChange(index, "details", {
                                  ...method.details,
                                  accountNumber: e.target.value
                                })}
                              />
                            </div>
                            <div className="col-12 md:col-4">
                              <InputText
                                placeholder="Branch"
                                value={method.details?.branch || ""}
                                onChange={(e) => handlePaymentMethodChange(index, "details", {
                                  ...method.details,
                                  branch: e.target.value
                                })}
                              />
                            </div>
                          </div>
                        )}

{method.type === "Mobile Money" && (
  <div className="grid col-12">
    <div className="col-12 mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <label className="form-label mb-0">Enable M-Pesa</label>
        <i 
          className={`ti ${method.details?.isMpesaEnabled ? 'ti-toggle-right text-primary' : 'ti-toggle-left text-danger'} fs-4`}
          onClick={() => handleMpesaToggle(index)}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
    
    {method.details?.isMpesaEnabled ? (
      <>
        <div className="col-12 md:col-4">
          <label className="form-label">Provider</label>
          <InputText
            value="M-Pesa"
            disabled
          />
        </div>
        <div className="col-12 md:col-4">
          <label className="form-label">Business Number</label>
          <InputText
            value={levySettings.accountNumber || ""}
            disabled
          />
        </div>
        <div className="col-12 md:col-4">
          <label className="form-label">Business Name</label>
          <InputText
            value={levySettings.bankName || ""}
            disabled
          />
        </div>
      </>
    ) : (
      <>
        <div className="col-12 md:col-4">
          <Dropdown
            value={method.details?.provider || ""}
            options={[
              { label: 'M-Pesa', value: 'Mpesa' },
              { label: 'Airtel Money', value: 'Airtel' }
            ]}
            onChange={(e) => handlePaymentMethodChange(index, "details", {
              ...method.details,
              provider: e.value
            })}
            placeholder="Select Provider"
          />
        </div>
        <div className="col-12 md:col-4">
          <InputText
            placeholder="Phone Number"
            value={method.details?.phoneNumber || ""}
            onChange={(e) => handlePaymentMethodChange(index, "details", {
              ...method.details,
              phoneNumber: e.target.value
            })}
          />
        </div>
        <div className="col-12 md:col-4">
          <InputText
            placeholder="Account Name"
            value={method.details?.accountName || ""}
            onChange={(e) => handlePaymentMethodChange(index, "details", {
              ...method.details,
              accountName: e.target.value
            })}
          />
        </div>
      </>
    )}
  </div>
)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Billing and Penalties Section */}
        <div className="col-12">
          <div className="card">
            <h5>Billing Cycle & Penalties</h5>
            <div className="grid">
              <div className="col-12 md:col-6">
                <label className="form-label">Billing Frequency</label>
                <Dropdown
                  value={billingFrequency}
                  options={[
                    { label: 'Monthly', value: 'Monthly' },
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Annually', value: 'Annually' }
                  ]}
                  onChange={(e) => setBillingFrequency(e.value)}
                  placeholder="Select Frequency"
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Next Invoice Date</label>
                <Calendar
                  value={nextInvoiceDate}
                  onChange={(e) => setNextInvoiceDate(e.value)}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Penalty</label>
                <Dropdown
                  value={selectedPenaltyId}
                  options={penalties}
                  optionLabel="name"
                  optionValue="_id"
                  onChange={(e) => setSelectedPenaltyId(e.value)}
                  placeholder="Select Penalty"
                />
              </div>

              <div className="col-12 md:col-6">
                <label className="form-label">Reminder</label>
                <Dropdown
                  value={selectedReminderId}
                  options={reminders}
                  optionLabel="name"
                  optionValue="_id"
                  onChange={(e) => setSelectedReminderId(e.value)}
                  placeholder="Select Reminder"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateLease;