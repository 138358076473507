import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  LineChart,
  Line,
  AreaChart,
  Area,
} from "recharts";

function BudgetManagement() {
  const [expenseType, setExpenseType] = useState("onetime");
  const [expenseCategory, setexpenseCategory] = useState("");
  const [dates, setDates] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleExpenseTypeChange = (e) => {
    setExpenseType(e.target.value);
    if (e.target.value === "onetime") {
      setDates([]);
    }
  };

  const addDate = () => {
    setDates([...dates, ""]);
  };

  const updateDate = (index, value) => {
    const newDates = [...dates];
    newDates[index] = value;
    setDates(newDates);
  };

  // Sample data for charts
  const monthlyData = [
    { month: "Jan", expenses: 12500, budget: 15000 },
    { month: "Feb", expenses: 14200, budget: 15000 },
    { month: "Mar", expenses: 13800, budget: 15000 },
    { month: "Apr", expenses: 15600, budget: 15000 },
    { month: "May", expenses: 14900, budget: 15000 },
    { month: "Jun", expenses: 13200, budget: 15000 },
  ];

  const removeDate = (index) => {
    const newDates = dates.filter((_, i) => i !== index);
    setDates(newDates);
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/expense_management"}>
                    Expense Management
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-1"
                                data-bs-toggle="tab"
                                href="#profile-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-notebook me-2"></i>Budget
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-2"
                                data-bs-toggle="tab"
                                href="#profile-2"
                                role="tab"
                                aria-selected="false"
                              >
                                <i className="ti ti-chart-line me-2"></i>Budget
                                Statistics
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="tab-content">
                        <div
                          className="tab-pane"
                          id="profile-1"
                          role="tabpanel"
                          aria-labelledby="profile-tab-1"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="row mb-3">
                                    <div className="col-md-3">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addExpenseModal"
                                      >
                                        Add New Budget
                                      </button>
                                    </div>
                                    <div className="col-md-3 ms-auto">
                                      <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchText}
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  {/* Add Expense Modal */}
                                  <div
                                    className="modal fade"
                                    id="addExpenseModal"
                                    tabIndex="-1"
                                    aria-labelledby="addExpenseModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="addExpenseModalLabel"
                                          >
                                            Add New Budget
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        <div className="modal-body">
                                          <form>
                                            <div className="mb-3">
                                              <label className="form-label">
                                                Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <label className="form-label">
                                                Amount
                                              </label>
                                              <input
                                                type="number"
                                                className="form-control"
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <label className="form-label">
                                                Category
                                              </label>
                                              <select
                                                className="form-select"
                                                value={expenseCategory}
                                                onChange={
                                                  handleExpenseTypeChange
                                                }
                                              >
                                                <option value="Category1">
                                                  Category1
                                                </option>
                                                <option value="Category2">
                                                  Category2
                                                </option>
                                              </select>
                                            </div>
                                            <div className="mb-3">
                                              <label className="form-label">
                                                Type
                                              </label>
                                              <select
                                                className="form-select"
                                                value={expenseType}
                                                onChange={
                                                  handleExpenseTypeChange
                                                }
                                              >
                                                <option value="onetime">
                                                  One Time
                                                </option>
                                                <option value="recurring">
                                                  Recurring
                                                </option>
                                              </select>
                                            </div>

                                            {expenseType === "onetime" ? (
                                              <div className="mb-3">
                                                <label className="form-label">
                                                  Date
                                                </label>
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                />
                                              </div>
                                            ) : (
                                              <div className="mb-3">
                                                <label className="form-label mb-3">
                                                  Recurring Dates
                                                </label>
                                                <div className="d-flex flex-column gap-3">
                                                  {dates.map((date, index) => (
                                                    <div
                                                      key={index}
                                                      className="d-flex align-items-center gap-3"
                                                    >
                                                      <input
                                                        type="date"
                                                        className="form-control"
                                                        value={date}
                                                        onChange={(e) =>
                                                          updateDate(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          removeDate(index)
                                                        }
                                                      >
                                                        Remove
                                                      </button>
                                                    </div>
                                                  ))}
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary w-auto"
                                                    onClick={addDate}
                                                  >
                                                    Add Date
                                                  </button>
                                                </div>
                                              </div>
                                            )}

                                            <div className="mb-3">
                                              <label className="form-label">
                                                Description
                                              </label>
                                              <textarea
                                                className="form-control"
                                                rows="3"
                                              ></textarea>
                                            </div>
                                          </form>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                          >
                                            Save Budget
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      emptyMessage="No budget found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="name"
                                        header="Name"
                                      ></Column>
                                      <Column
                                        field="amount"
                                        header="Amount"
                                      ></Column>
                                      <Column
                                        field="type"
                                        header="Type"
                                      ></Column>
                                      <Column
                                        field="date"
                                        header="Date"
                                      ></Column>
                                      <Column
                                        field="description"
                                        header="Description"
                                      ></Column>
                                      <Column
                                        field="category"
                                        header="Category"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="profile-2"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div
                                    style={{ height: "400px" }}
                                    className="mb-4"
                                  >
                                    <ResponsiveContainer
                                      width="100%"
                                      height="100%"
                                    >
                                      <LineChart data={monthlyData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                          type="monotone"
                                          dataKey="expenses"
                                          stroke="#0d6efd"
                                          name="Actual"
                                        />
                                        <Line
                                          type="monotone"
                                          dataKey="budget"
                                          stroke="#dc3545"
                                          name="Budget"
                                        />
                                      </LineChart>
                                    </ResponsiveContainer>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="card bg-primary text-white">
                                        <div className="card-body">
                                          <h6>Total Budget</h6>
                                          <h3>$90,000</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="card bg-success text-white">
                                        <div className="card-body">
                                          <h6>Used Budget</h6>
                                          <h3>$84,200</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="card bg-warning text-white">
                                        <div className="card-body">
                                          <h6>Remaining</h6>
                                          <h3>$5,800</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="card bg-info text-white">
                                        <div className="card-body">
                                          <h6>Variance</h6>
                                          <h3>6.4%</h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BudgetManagement;
