import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../component/layout";
import { getItem } from "../../../utils/localStorage";
import FacilityTab from "./FacilityTab";
import LandlordTab from "./LandlordTab";
import SMSTab from "./SMSTab";
import EmailTab from "./EmailTab";
import BankDetailsTab from "./BankDetailsTab";
import LevySettingsTab from "./LevySettingsTab";
import TaxManagementTab from "./TaxManagementTab";
import CurrencyManagementTab from "./CurrencyManagementTab";
import HandoverInspectionTab from "./HandoverInspectionTab";

const FacilitySettings = () => {
  const [facilityId, setFacilityId] = useState(null);

  useEffect(() => {
    const loadFacilityData = async () => {
      const id = await getItem("selectedFacilityId");
      setFacilityId(id);
    };
    loadFacilityData();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/settings_management/settings"}>
                    Settings
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body py-0">
              <ul
                className="nav nav-tabs profile-tabs"
                id="myTab"
                role="tablist"
              >

                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="facility-tab"
                    data-bs-toggle="tab"
                    href="#facility"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="ti ti-building me-2"></i>Facility
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="landlord-tab"
                    data-bs-toggle="tab"
                    href="#landlord"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="ti ti-user me-2"></i>Landlord
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="sms-tab"
                    data-bs-toggle="tab"
                    href="#sms"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-message me-2"></i>SMS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="email-tab"
                    data-bs-toggle="tab"
                    href="#email"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-mail me-2"></i>Email
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="bank-tab"
                    data-bs-toggle="tab"
                    href="#bank"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-building-bank me-2"></i>Bank Details
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="currency-tab"
                    data-bs-toggle="tab"
                    href="#currency"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-coins me-2"></i>Currency
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tax-tab"
                    data-bs-toggle="tab"
                    href="#tax"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-currency me-2"></i>Tax Management
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="levy-tab"
                    data-bs-toggle="tab"
                    href="#levy"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-settings me-2"></i>Invoice Settings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="inspection-tab"
                    data-bs-toggle="tab"
                    href="#inspection"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="ti ti-clipboard-check me-2"></i>Inspection Checklist
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="facility"
              role="tabpanel"
            >
              <FacilityTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="landlord" role="tabpanel">
              <LandlordTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="sms" role="tabpanel">
              <SMSTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="email" role="tabpanel">
              <EmailTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="bank" role="tabpanel">
              <BankDetailsTab facilityId={facilityId} />
            </div>
            
            <div className="tab-pane fade" id="currency" role="tabpanel">
              <CurrencyManagementTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="tax" role="tabpanel">
              <TaxManagementTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="levy" role="tabpanel">
              <LevySettingsTab facilityId={facilityId} />
            </div>

            <div className="tab-pane fade" id="inspection" role="tabpanel">
              <HandoverInspectionTab facilityId={facilityId} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FacilitySettings;