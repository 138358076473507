import React, { useState, useEffect } from 'react';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { Button } from 'primereact/button';

const BankDetailsTab = ({ facilityId }) => {
    const [bankDetails, setBankDetails] = useState({
        accountName: '',
        accountNumber: '',
        bankName: '',
        branchName: '',
        branchCode: '',
        bankCode: '',
        isDefault: false
    });
    const [bankList, setBankList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (facilityId) {
            fetchBankDetails();
        }
    }, [facilityId]);

    const fetchBankDetails = async () => {
        try {
            const response = await makeRequest2(`/api/bank-details/${facilityId}`, 'GET');
            if (response.success) {
                setBankList(response.data);
            }
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = isEditing
                ? `/api/bank-details/${facilityId}/${bankDetails._id}`
                : `/api/bank-details/${facilityId}`;

            const method = isEditing ? 'PUT' : 'POST';

            const response = await makeRequest2(url, method, bankDetails);

            if (response.success) {
                toastify(`Bank details ${isEditing ? 'updated' : 'added'} successfully`, 'success');
                fetchBankDetails();
                resetForm();
            } else {
                toastify(response.message || 'Operation failed', 'error');
            }
        } catch (error) {
            toastify('Error saving bank details', 'error');
        }
    };

    const handleEdit = (bank) => {
        setBankDetails(bank);
        setIsEditing(true);
    };

    const resetForm = () => {
        setBankDetails({
            accountName: '',
            accountNumber: '',
            bankName: '',
            branchName: '',
            branchCode: '',
            bankCode: '',
            isDefault: false
        });
        setIsEditing(false);
    };

    return (
        <div className="row p-3">
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5>{isEditing ? 'Update' : 'Add'} Bank Details</h5>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-3">
                                <label>Account Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={bankDetails.accountName}
                                    onChange={(e) => setBankDetails({ ...bankDetails, accountName: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Account Number*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={bankDetails.accountNumber}
                                    onChange={(e) => setBankDetails({ ...bankDetails, accountNumber: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Bank Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={bankDetails.bankName}
                                    onChange={(e) => setBankDetails({ ...bankDetails, bankName: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Branch Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={bankDetails.branchName}
                                    onChange={(e) => setBankDetails({ ...bankDetails, branchName: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Branch Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={bankDetails.branchCode}
                                    onChange={(e) => setBankDetails({ ...bankDetails, branchCode: e.target.value })}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Bank Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={bankDetails.bankCode}
                                    onChange={(e) => setBankDetails({ ...bankDetails, bankCode: e.target.value })}
                                />
                            </div>

                            <div className="form-check mt-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={bankDetails.isDefault}
                                    onChange={(e) => setBankDetails({ ...bankDetails, isDefault: e.target.checked })}
                                    id="defaultBank"
                                />
                                <label className="form-check-label" htmlFor="defaultBank">
                                    Set as Default Bank Account
                                </label>
                            </div>

                            <div className="mt-4 text-end">
                                {isEditing && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={resetForm}
                                    >
                                        Cancel
                                    </button>
                                )}
                                <button type="submit" className="btn btn-primary">
                                    {isEditing ? 'Update' : 'Add'} Bank Details
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5>Current Bank Details</h5>
                        <div className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th>Bank Name</th>
                                        <th>Account Number</th>
                                        <th>Default</th>
                                        <th className="text-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankList.length > 0 ? (
                                        bankList.map((bank) => (
                                            <tr key={bank._id}>
                                                <td>{bank.bankName}</td>
                                                <td>{bank.accountNumber}</td>
                                                <td>
                                                    {bank.isDefault ? (
                                                        <span className="badge bg-success">Yes</span>
                                                    ) : (
                                                        <span className="badge bg-secondary">No</span>
                                                    )}
                                                </td>
                                                <td className="text-end">
                                                    <Button
                                                        icon="ti ti-pencil"
                                                        className="p-button-rounded p-button-warning me-2"
                                                        onClick={() => handleEdit(bank)}
                                                        tooltip="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="text-center py-4">
                                                <div className="text-muted">
                                                    <i className="ti ti-inbox mb-2" style={{ fontSize: "24px" }}></i>
                                                    <p className="mb-0">No bank details available</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankDetailsTab;