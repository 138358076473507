import React, { useState, useEffect } from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";
import {
  editContract,
  getLevies,
  getCustomersURL,
  getUnitsForFacility,
  getContracts
} from "../../../../utils/urls";

const EditContract = ({
  visible,
  onHide,
  contractId,
  onSuccess,
  initialContract
}) => {
  // State management
  const [contractName, setContractName] = useState("");
  const [levy, setLevy] = useState("");
  const [customer, setCustomer] = useState("");
  const [unit, setUnit] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("Active");
  const [loading, setLoading] = useState(false);
  const [paymentFrequency, setPaymentFrequency] = useState("");

  // Data lists
  const [levies, setLevies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [units, setUnits] = useState([]);
  const [existingContracts, setExistingContracts] = useState([]);

  // Initialize form with contract data
  useEffect(() => {
    if (visible && initialContract) {
      console.log('Loading initial contract:', initialContract);

      setContractName(initialContract.contractName || "");
      setLevy(initialContract.levyId || "");
      setCustomer(initialContract.customerId || "");
      setUnit(initialContract.unitId || "");
      setAmount(initialContract.amount?.toString() || "");
      setStartDate(initialContract.startDate ? new Date(initialContract.startDate).toISOString().split('T')[0] : "");
      setEndDate(initialContract.endDate ? new Date(initialContract.endDate).toISOString().split('T')[0] : "");
      setStatus(initialContract.status || "Active");
      setPaymentFrequency(initialContract.paymentFrequency || "");

      loadInitialData();
    }
  }, [visible, initialContract]);

  const loadInitialData = async () => {
    if (visible) {
      await Promise.all([
        fetchLevies(),
        fetchCustomersForFacility(),
        fetchUnitsForFacility(),
        fetchExistingContracts()
      ]);
    }
  };

  // Fetch available levies
  const fetchLevies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getLevies}/${facilityId}`,
        "GET",
        {}
      );

      if (response.success) {
        let leviesData = [];

        if (Array.isArray(response.data)) {
          leviesData = response.data;
        } else if (response.data?.data && Array.isArray(response.data.data)) {
          leviesData = response.data.data;
        } else if (typeof response.data === 'object' && response.data !== null) {
          leviesData = [response.data];
        }

        const processedLevies = leviesData.map((levy) => ({
          _id: levy._id,
          levyName: levy.levyName || 'Untitled Levy',
          amount: levy.amount || 0,
          levyType: levy.levyType?.name || "N/A",
          collectionFrequency: levy.collectionFrequency || "Monthly"
        }));

        setLevies(processedLevies);
      } else {
        throw new Error(response.error || "Failed to fetch levies");
      }
    } catch (err) {
      console.error("Error in fetchLevies:", err);
      toastify(err.message || "Error fetching levies", "error");
      setLevies([]);
    }
  };

  // Fetch available customers for the facility
  const fetchCustomersForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getCustomersURL}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setCustomers(response.data);
      } else {
        throw new Error("Failed to fetch customers.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Fetch available units for the facility
  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        const filteredUnits = response.data.filter(unit =>
          unit.tenantId || unit.homeOwnerId
        );
        setUnits(filteredUnits);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Fetch existing contracts
  const fetchExistingContracts = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(`${getContracts}/${facilityId}`, "GET");
      if (response.success) {
        const contractsData = response.data?.contracts || response.data || [];
        // Filter out the current contract being edited
        const filteredContracts = contractsData.filter(contract => contract._id !== contractId);
        setExistingContracts(filteredContracts);
      }
    } catch (error) {
      console.error("Error fetching existing contracts:", error);
      setExistingContracts([]);
    }
  };

  // Get frequency text for display
  const getFrequencyText = () => {
    if (!levy || !levies.length) return "";
    const selectedLevy = levies.find(l => l._id === levy);
    return selectedLevy ? selectedLevy.collectionFrequency : "";
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");

      // Form validation
      if (!contractName || contractName.trim() === "") {
        toastify("Contract name is required.", "error");
        setLoading(false);
        return;
      }
      if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
        toastify("Amount must be a valid number.", "error");
        setLoading(false);
        return;
      }
      if (!startDate) {
        toastify("Start date is required.", "error");
        setLoading(false);
        return;
      }
      if (!endDate) {
        toastify("End date is required.", "error");
        setLoading(false);
        return;
      }

      // Check if dates are valid
      if (new Date(startDate) > new Date(endDate)) {
        toastify("Start date cannot be later than end date.", "error");
        setLoading(false);
        return;
      }

      const updatedData = {
        contractName: contractName.trim(),
        levyId: levy,
        customerId: customer,
        unitId: unit,
        amount: parseFloat(amount),
        startDate,
        endDate,
        status
      };

      console.log('Submitting updated contract data:', updatedData);

      const response = await makeRequest2(
        `${editContract}/${facilityId}/${contractId}`,
        "PUT",
        updatedData
      );

      if (response.success) {
        toastify("Contract updated successfully", "success");
        onHide();
        onSuccess();
      } else {
        toastify(response.error || "Failed to update contract", "error");
      }
    } catch (err) {
      console.error('Error updating contract:', err);
      toastify(err.message || "Error updating contract", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      header="Edit Contract"
      style={{ width: "60vw" }}
      visible={visible}
      onHide={onHide}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Contract Name</label>
            <InputText
              className="form-control"
              value={contractName}
              onChange={(e) => setContractName(e.target.value)}
            />
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Levy</label>
            <select
              className="form-control"
              value={levy}
              onChange={(e) => setLevy(e.target.value)}
            >
              <option value="">-- Select --</option>
              {levies.map((levyItem) => (
                <option key={levyItem._id} value={levyItem._id}>
                  {levyItem.levyName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Unit</label>
            <select
              className="form-control"
              value={unit}
              disabled
            >
              <option value="">-- Select --</option>
              {units.map((unitItem) => (
                <option key={unitItem._id} value={unitItem._id}>
                  {unitItem.name}
                </option>
              ))}
            </select>
            <small className="text-muted">Unit cannot be changed</small>
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Customer</label>
            <select
              className="form-control"
              value={customer}
              disabled
            >
              <option value="">-- Select --</option>
              {customers.map((customerItem) => (
                <option key={customerItem._id} value={customerItem._id}>
                  {`${customerItem.firstName} ${customerItem.lastName}`}
                </option>
              ))}
            </select>
            <small className="text-muted">Customer is tied to unit</small>
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Amount</label>
            <input
              type="number"
              className="form-control"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              disabled
              min="0"
              step="0.01"
            />
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Payment Frequency</label>
            <input
              type="text"
              className="form-control"
              value={getFrequencyText()}
              disabled
            />
            <small className="text-muted">This value is set in the levy configuration</small>
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Start Date</label>
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">End Date</label>
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div className="col-lg-6 sm-12 mb-6 ">
            <label className="form-label mb-2">Status</label>
            <div className="d-flex align-items-center">
              <i
                className={`ti ${status === "Inactive"
                  ? "ti-toggle-left text-danger"
                  : "ti-toggle-right text-primary"
                  }`}
                style={{ fontSize: "34px", cursor: "pointer" }}
                onClick={() =>
                  setStatus(
                    status === "Inactive" ? "Active" : "Inactive"
                  )
                }
              />
              <span className="ms-2">{status}</span>
            </div>
          </div>
        </div>

        <div className="text-end mt-4">
          <Button
            type="button"
            className="btn btn-outline-secondary me-2"
            onClick={onHide}
            disabled={loading}
          >
            Cancel
          </Button>
          &nbsp;
          <Button
            type="button"
            className="btn btn-primary"
            onClick={handleEditSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditContract;