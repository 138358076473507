import React, { useState, useEffect } from 'react';
import Layout from '../component/layout';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage'
import { makeRequest2 } from '../../../utils/makeRequest';
import { getCampaignsURL, updateCampaignURL } from '../../../utils/urls';

const Campaigns = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState('');
  const [name, setName] = useState('');
  const [objective, setObjective] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [channels, setChannels] = useState([]);
  const [status, setStatus] = useState('');

  const [editDialog, setEditDialog] = useState(false);

  const handleChannelChange = (channel) => {
    setChannels((prevChannels) =>
      prevChannels.includes(channel)
        ? prevChannels.filter((c) => c !== channel) // Remove if already selected
        : [...prevChannels, channel] // Add if not selected
    );
  };

  const getCampaigns = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const response = await makeRequest2(`${getCampaignsURL}/${facilityId}`, 'GET', {});
      console.log("response", response);

      if (response.success) {
        setCampaigns(response.data);
      } else {
        throw new Error(response.error);
      }
    }
    catch (err) {
      toastify(err.message, 'error');
    }
  }

  const handleUpdateCampaign = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const campaignData = {
        name,
        objective,
        description,
        startDate,
        endDate,
        targetAudience,
        channels
      }

      const response = await makeRequest2(`${updateCampaignURL}/${facilityId}/${campaignId}`, 'POST', campaignData);

      if (response.success) {
        toastify('Campaign updated successfully', 'success');
        setEditDialog(false);
        getCampaigns();
      } else {
        console.log(response.error);
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  }

  const allCampaignsCount = campaigns.length;
  const activeCampaignsCount = campaigns.filter(campaign => campaign.status === "Ongoing").length;
  const completedCampaignsCount = campaigns.filter(campaign => campaign.status === "Completed").length;

  const actionTemplate = (rowData) => {
    return (
      <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info mr-2"
        onClick={() => handleEdit(rowData)}
        tooltip="Edit"
      />
    );
  };

  const handleEdit = (rowData) => {
    setCampaignId(rowData._id);
    setName(rowData.name);
    setObjective(rowData.objective);
    setDescription(rowData.description);
    setStartDate(new Date(rowData.startDate).toISOString().split('T')[0]);
    setEndDate(new Date(rowData.endDate).toISOString().split('T')[0]);
    setTargetAudience(rowData.targetAudience);
    setChannels(rowData.channels);
    setStatus(rowData.status);

    setEditDialog(true);
  }


  const statusTemplate = (rowData) => {
    const statusStyles = {
      Draft: { color: '#28a745', backgroundColor: '#e9fbe9' },
      closed: { color: '#007bff', backgroundColor: '#e0f7ff' },
      Completed: { color: '#17a2b8', backgroundColor: '#e0f4f7' },
      cancelled: { color: '#dc3545', backgroundColor: '#fde9e9' },
      Ongoing: { color: '#ffc107', backgroundColor: '#fff8e1' },
    };

    const style = statusStyles[rowData.status] || {
      color: '#6c757d',
      backgroundColor: '#f1f1f1',
    };

    return (
      <span
        style={{
          ...style,
          padding: '0.5em 1em',
          borderRadius: '12px',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          display: 'inline-block',
        }}
      >
        {rowData.status}
      </span>
    );
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <Layout>

      <div className="row">
        <div className="col-sm-12">

          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add Campaign</h5>
                </div>

                <div className='card-body'>

                  <div className="row">

                    <div className="col-lg-12 col-xxl-12">
                      <div className="card">

                        <div className="card-body position-relative">

                          <div className="container mt-4">
                            {/* Header */}
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h1 className="h4">Campaign Dashboard</h1>
                              <button className="btn btn-primary d-flex align-items-center"
                                onClick={() => navigate('/facility/campaigns/add_campaign')}
                              >
                                <i className="bi bi-plus me-2"></i> New Campaign
                              </button>
                            </div>

                            {/* Overview Cards */}
                            <div className="row">
                              <div className="col-md-4">
                                <div className="card p-3 text-center">
                                  <h5 className='text-primary'>Total Campaigns</h5>
                                  <h2 className="fw-bold">{allCampaignsCount}</h2>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card p-3 text-center">
                                  <h5 className='text-info'>Active Campaigns</h5>
                                  <h2 className="fw-bold">{activeCampaignsCount}</h2>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card p-3 text-center">
                                  <h5 className='text-success'>Completed Campaigns</h5>
                                  <h2 className="fw-bold">{completedCampaignsCount}</h2>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive">
                            <DataTable value={campaigns} emptyMessage="No Campaigns found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                              <Column field="name" header="Name"></Column>
                              <Column field="description" header="Description" body={(rowData) =>
                                rowData.description.length > 50
                                  ? rowData.description.substring(0, 50) + "..."
                                  : rowData.description
                              }></Column>
                              <Column field="objective" header="Objective"></Column>
                              <Column field="targetAudience" header="Audience"></Column>
                              <Column field="channels" header="Channels" body={(rowData) => rowData.channels.join(", ")} ></Column>
                              <Column field="status" header="Status" body={statusTemplate}></Column>
                              <Column field="startDate" header="Start Date" body={(rowData) => new Date(rowData.startDate).toISOString().split('T')[0]} ></Column>
                              <Column field="endDate" header="End Date" body={(rowData) => new Date(rowData.endDate).toISOString().split('T')[0]} ></Column>
                              <Column header="Actions"
                                body={actionTemplate}
                              ></Column>
                            </DataTable>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>

        </div>
      </div>

      <Dialog
        header="Edit Campaign"
        visible={editDialog}
        style={{ width: '50vw' }}
        onHide={() => setEditDialog(false)}
      >
        <div className="container">

          <div className="mb-3">
            <label className="form-label">Campaign Name</label>
            <input type="text" className="form-control" name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required />
          </div>

          <div className="mb-3">
            <label className="form-label">Objective</label>
            <select className="form-select" name="objective"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              required>
              <option value="">Select Objective</option>
              <option value="Brand Awareness">Brand Awareness</option>
              <option value="Lead Generation">Lead Generation</option>
              <option value="Sales">Sales</option>
              <option value="Engagement">Engagement</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Description</label>
            <textarea className="form-control" name="description" rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required />
          </div>

          <div className="mb-3">
            <label className="form-label">Start Date</label>
            <input type="date" className="form-control" name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required />
          </div>

          <div className="mb-3">
            <label className="form-label">End Date</label>
            <input type="date" className="form-control" name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required />
          </div>


          <div className="mb-3">
            <label className="form-label">Target Audience</label>
            <select className="form-select" name="targetAudience"
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              required>
              <option value="">Select Target Audience</option>
              <option value="landlords">Landlords</option>
              <option value="tenants">Tenants</option>
              <option value="individuals">Individuals</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Marketing Channels</label>
            <div>
              {['Email', 'Social Media', 'SMS', 'Ads'].map((channel) => (
                <div key={channel} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={channel}
                    checked={channels.includes(channel)}
                    onChange={() => handleChannelChange(channel)}
                  />
                  <label className="form-check-label">{channel}</label>
                </div>
              ))}
            </div>
          </div>

          <div className='text-md-end'>
            <button type="submit" className="btn btn-primary" onClick={handleUpdateCampaign}>Update Campaign</button>
          </div>

        </div>
      </Dialog>


    </Layout >
  );
};

export default Campaigns;
