import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useFacilityStore = create(
  persist(
    (set) => ({
      facilityId: null,
      setFacilityId: (id) => set({ facilityId: id }),
      clearFacilityId: () => set({ facilityId: null }),
    }),
    {
      name: "facility-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
