import React, { useState, useEffect } from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { get_lease_documents } from "../../../../utils/urls";
import { backend_url } from "../../../../utils/urls";

const ViewLeaseDocuments = ({ leaseId, visible, onHide }) => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState('');

    useEffect(() => {
        if (visible && leaseId) {
            fetchDocuments();
        } else {
            // Reset state when dialog is closed
            setDocuments([]);
            setError(null);
        }
    }, [visible, leaseId]);

    const fetchDocuments = async () => {
        if (!leaseId) return;
        
        try {
            setLoading(true);
            setError(null);
            const facilityId = await getItem("selectedFacilityId");
            
            if (!facilityId) {
                throw new Error("Facility ID not found");
            }
            
            console.log(`Fetching documents for lease: ${leaseId} in facility: ${facilityId}`);
            
            try {
                // Using the actual API endpoint path from the routes file
                const response = await makeRequest2(`/api/app/lease_management/lease_agreements/get_documents/${facilityId}/${leaseId}`, "GET");
                console.log("Document API response:", response);

                if (response.success) {
                    let docsArray = [];
                    
                    if (Array.isArray(response.data)) {
                        docsArray = response.data;
                    } else if (response.data && Array.isArray(response.data.documents)) {
                        docsArray = response.data.documents;
                    } else if (response.data && response.data.leaseDocuments) {
                        docsArray = response.data.leaseDocuments;
                    } else if (response.leaseDocuments && Array.isArray(response.leaseDocuments)) {
                        docsArray = response.leaseDocuments;
                    }
                    
                    console.log("Processed documents array:", docsArray);
                    setDocuments(docsArray || []);
                } else {
                    throw new Error(response.error || response.message || "Failed to fetch lease documents");
                }
            } catch (error) {
                if (error.message && error.message.includes("404")) {
                    console.log("No documents found for this lease (404)");
                    setDocuments([]);
                    setError("No documents found for this lease");
                } else {
                    throw error;
                }
            }
        } catch (error) {
            console.error("Error fetching documents:", error);
            setError(error.message || "An unexpected error occurred");
            setDocuments([]);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (rowData) => {
        if (!rowData.uploadedAt) return 'N/A';
        
        try {
            return new Date(rowData.uploadedAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } catch (error) {
            console.error("Date formatting error:", error);
            return 'Invalid Date';
        }
    };

    const openFullScreen = (imageSrc) => {
        setIsFullScreen(true);
        setFullScreenImage(imageSrc);
    };

    const closeFullScreen = () => {
        setIsFullScreen(false);
    };

    const isImage = (file) => {
        return /\.(jpg|jpeg|png|gif)$/i.test(file);
    };

    // Function to open document in a new tab or in fullscreen for images
    const openDocument = (document) => {
        try {
            if (!document.fileUrl) {
                toastify("Document URL not available", "error");
                return;
            }
            
            console.log("Original document URL:", document.fileUrl);
            
            // Determine if we need to add the backend URL prefix
            let documentUrl = document.fileUrl;
            if (!documentUrl.startsWith('http')) {
                documentUrl = `${backend_url}/${documentUrl.replace(/^\//, '')}`;
            }
            
            console.log("Opening document URL:", documentUrl);
            
            // If it's an image, show in fullscreen mode
            if (isImage(document.fileName)) {
                openFullScreen(documentUrl);
            } else {
                // For PDFs and other documents, open in a new tab
                window.open(documentUrl, '_blank');
            }
        } catch (error) {
            console.error("Error opening document:", error);
            toastify("Failed to open document", "error");
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="d-flex gap-2">
                <Button
                    icon="ti ti-eye"
                    className="p-button-rounded p-button-info p-button-sm"
                    onClick={() => openDocument(rowData)}
                    tooltip="View Document"
                />
                <Button
                    icon="ti ti-download"
                    className="p-button-rounded p-button-success p-button-sm"
                    onClick={() => openDocument(rowData)}
                    tooltip="Download"
                />
            </div>
        );
    };

    const footer = (
        <div>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={onHide}
            />
        </div>
    );

    return (
        <>
            <Dialog
                header="Lease Documents"
                visible={visible}
                style={{ width: '70vw' }}
                footer={footer}
                onHide={onHide}
                dismissableMask
            >
                <div className="p-3">
                    {loading ? (
                        <div className="text-center p-4">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p className="mt-3">Loading documents...</p>
                        </div>
                    ) : documents.length === 0 ? (
                        <div className="text-center p-4">
                            <i className="ti ti-file-off" style={{ fontSize: '3rem', color: '#ccc' }}></i>
                            <p className="mt-3">No documents found for this lease</p>
                        </div>
                    ) : (
                        <DataTable
                            value={documents}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            stripedRows
                            emptyMessage="No documents found"
                        >
                            <Column field="fileName" header="Document Name" sortable />
                            <Column field="uploadedAt" header="Upload Date" body={formatDate} sortable />
                            <Column header="Actions" body={actionTemplate} />
                        </DataTable>
                    )}
                </div>
            </Dialog>

            {/* Fullscreen image viewer like in ticket view */}
            {isFullScreen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                    onClick={closeFullScreen}
                >
                    <div
                        style={{
                            width: '90vw',
                            height: '90vh',
                            aspectRatio: '16/9', // You can adjust this to your desired aspect ratio
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={fullScreenImage}
                            alt="Full-Screen Attachment"
                            style={{
                                width: '80%',
                                height: '90%',
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewLeaseDocuments;