// export const backend_url = "http://localhost:3050";
// export const backend_url = "https://api.sandbox.payserve.co.ke";
export const backend_url = "https://api.payserve.co.ke";

export const checkEmailAndPasswordURL = "/api/auth/check_email_and_password";
export const loginURL = "/api/auth/login";
export const forgotPasswordURL = "/api/auth/forgot_password";
export const resetPasswordURL = "/api/auth/reset_password";
export const codeVerificationURL = "/api/auth/otp";
export const resendCodeURL = "/api/auth/resend_code";
export const verifyOTPURL = "/api/auth/verify_otp";
export const checkTokenExpirationURL = "/api/auth/check_jwt_expiration";
export const addWaterConcentratorURL = "/api/core/water/add_concentrator";
export const getWaterConcentratorsURL = "/api/core/water/get_concentrators";
export const importWaterConcentratorsURL =
  "/api/core/water/import_concentrators";
export const addWaterMeterURL = "/api/core/water/add_water_meter";
export const getWaterMetersURL = "/api/core/water/get_water_meters";
export const importWaterMetersURL = "/api/core/water/import_water_meters";
export const confirmCompanyNameURL =
  "/api/core/company_management/confirm_company_name";
export const getCompaniesURL = "/api/core/company_management/get_companies";
export const addCompanyURL = "/api/core/company_management/add_company";
export const addExistingCompanyToUserURL =
  "/api/core/user_management/add_a_existing_company_to_user";
export const addNewCompanyToUserURL =
  "/api/core/user_management/add_a_new_company_to_user";
export const getCompanyUsersURL = "/api/core/user_management/get_company_users";
export const confirmUserEmailURL =
  "/api/core/user_management/confirm_user_email";
export const getSitesURL = "/api/core/site_management/get_sites";
export const addSiteURL = "/api/core/site_management/add_site";
export const getProjectsURL = "/api/core/project_management/get_projects";
export const getTransactionsURL = "";
export const getWaterMeterAccountURL = "";
export const getAPIMetricsURL = "/api/core/api_management/get_metrics";
export const getListOfFacilities =
  "/api/app/user_management/get_list_of_facilities";
export const getCompanyInformationURL =
  "/api/app/settings_management/get_company_information";
export const getFacilityUnits = "/api/app/unit_management/get_facility_units";
// export const getFacilityURL = "/api/app/unit_management/get_facility_units";
export const getFacilityURL = "/api/core/facility_management/get_facility";
export const getFacilities = "/api/app/unit_management/get_facilities";
export const getUnitsForFacility =
  "/api/app/facility_management/get_units_for_facility";
export const AddNewCustomerURL =
  "/api/app/customer_management/add_new_customer";
export const getCustomersURL = "/api/app/customer_management/get_customers";
export const getCustomerURL = "/api/app/customer_management/get_customer";
export const updateCustomer = '/api/app/customer_management/update_customer';
export const getCustomerUnits =
  "/api/app/customer_management/get_customer_units";
export const addUnoccupiedUnitToCustomer =
  "/api/app/customer_management/add_unoccupied_unit_to_customer";
export const finishDeactivationUrl =
  "/api/app/customer_management/deactivate_customer";

// Landlord
export const addLandlordURL = "/api/app/landlord_management/add_landlord";
export const getLandlordURL = "/api/app/landlord_management/get_landlord";
export const updateLandlordURL = "/api/app/landlord_management/update_landlord";
export const deleteLandlordURL = "/api/app/landlord_management/delete_landlord";
// end LAndlord

// Visitor and Access Routes
export const getVisitLogsForFacilityURL =
  "/api/app/visitor_management/get_visitor_logs";
export const searchByOtp = "/api/app/visitor_management/search_by_otp";
export const confirmQRDataURL = "/api/app/visitor_management/confirm_qr_data";
export const handleAllowVisitorURL =
  "/api/app/visitor_management/allow_verified_visitor";
export const confirmManualDataURL = "/api/app/visitor_management/manual_entry";
export const deliveryRegistrationURL =
  "/api/app/visitor_management/delivery_registration";
export const getDivisionArray = "/api/app/facility_management/get_divisions";
export const getUnitUrl = "/api/app/unit_management/get_unit";
export const updateUnit = "/api/app/unit_management/update_unit";
export const getUnitAssetsURL = "/api/app/unit_management/get_unit_assets";
export const addUnitAssetURL = "/api/app/unit_management/add_new_unit_asset";
export const deleteUnitAssetURL = "/api/app/unit_management/delete_unit_asset";
export const visitorPreRegistrationURL =
  "/api/app/visitor_management/visitor_pre_registration";
export const getVisitLogURL = "/api/app/visitor_management/get_visitor_log";
export const exitVisitLogURL = "/api/app/visitor_management/exit_visit_log";
export const allowVisitURL = "/api/app/visitor_management/allow_visit";
export const allowVisitorURL = "/api/app/visitor_management/allow_visitor";

export const EntryAndExitURL =
  "/api/app/entry_and_exit_management/add_entry_and_exit";
export const getEntriesAndExitsForFacility =
  "/api/app/entry_and_exit_management/get_entries_and_exits_for_facility";
export const editEntriesAndExitsForFacility =
  "/api/app/entry_and_exit_management/edit_entries_and_exits_for_facility";
export const deleteEntriesAndExitsForFacility =
  "/api/app/entry_and_exit_management/delete_entries_and_exits_for_facility";
export const disableEntriesAndExitsForFacility =
  "/api/app/entry_and_exit_management/disable_entries_and_exits_for_facility";

export const GuardRegistrationURL = "/api/app/guard_management/add_guard";
export const getFacilityGuards =
  "/api/app/guard_management/get_facility_guards";
export const getGuardTime = "/api/app/guard_management/get_guard_time";
export const editFacilityGuard =
  "/api/app/guard_management/edit_facility_guard";
export const deleteFacilityGuard =
  "/api/app/guard_management/delete_facility_guard";
export const disableFacilityGuard =
  "/api/app/guard_management/disable_facility_guard";
export const updateFacilityInfo =
  "/api/app/settings_management/update_facility";

// End visitor and access routes

export const addLevyType = "/api/app/levy_management/add_levy_type";
export const getLevyTypes = "/api/app/levy_management/get_levy_types";
export const deleteLevyType = "/api/app/levy_management/delete_levy_type";
export const editLevyType = "/api/app/levy_management/edit_levy_type";

export const addLevy = "/api/app/levy_management/add_levy";
export const editLevy = "/api/app/levy_management/edit_levy";
export const deleteLevy = "/api/app/levy_management/delete_levy";
export const disableLevy = "/api/app/levy_management/disable_levy";
export const getLevies = "/api/app/levy_management/get_levies";

export const addLevySettings = "/api/app/levy_management/add_levy_settings";
export const showLevySettings = "/api/app/levy_management/show_levy_settings";
export const editLevySettings = "/api/app/levy_management/edit_levy_settings";

export const addContract = "/api/app/levy_management/add_contract";
export const getContracts = "/api/app/levy_management/get_contracts";
export const editContract = "/api/app/levy_management/edit_contract";
export const disableContract = "/api/app/levy_management/disable_contract";
export const deleteContract = "/api/app/levy_management/delete_contract";

export const addReminder = "/api/app/levy_management/reminders/add_reminder";
export const addPenalty = "/api/app/levy_management/penalty/add_penalty";
export const updatePenalty =
  "/api/app/levy_management/penalty/update_penalty_status";
export const updateReminder =
  "/api/app/levy_management/reminders/update_reminder_status";
export const deleteReminder =
  "/api/app/levy_management/reminders/delete_reminder";
export const deletePenalty = "/api/app/levy_management/penalty/delete_penalty";
export const facilityReminders =
  "/api/app/levy_management/get_facility_reminders";
export const facilityPenalties =
  "/api/app/levy_management/get_facility_penalties";
export const fetchFacilityPaymentDetails = "/api/core/payment_details/get_facility_payment_details";
export const getLeviesURL = "/api/app/levy_management/get_levies";
export const addTaxRate = "/api/app/settings_management/add_or_update_tax_rate";
export const getTaxRates = "/api/app/settings_management/get_tax_rates";
export const deleteTaxRate = "/api/app/settings_management/delete_tax_rate";

export const createTicketURL = "/api/app/ticket_management/create_ticket";
export const getTicketsURL = "/api/app/ticket_management/get_tickets";
export const getTicketURL = "/api/app/ticket_management/get_ticket";
export const closeTicketURL = "/api/app/ticket_management/close_ticket";
export const getTicketsReportsURL =
  "/api/app/ticket_management/get_ticket_reports";

export const createWorkOrderURL =
  "/api/app/work_order_management/create_work_order";
export const approveComplaintURL =
  "/api/app/ticket_management/approve_complaint";
export const finishReviewURL = "/api/app/ticket_management/finish_review";
export const getServices = "/api/app/vas_management/get_maintenance_services";
export const addNewService = "/api/app/vas_management/add_new_service";

export const getVasServices =
  "/api/app/vas_management/get_value_added_services";
export const addNewValueAddedService =
  "/api/app/vas_management/add_new_value_added_service";
export const getValueAddedServices =
  "/api/app/vas_management/get_value_added_services";
export const getVasVendors = "/api/app/vas_management/get_vas_vendors";
export const addVasVendor = "/api/app/vas_management/add_vas_vendor";
export const deleteVasVendor = "/api/app/vas_management/delete_vas_vendor";
export const updateVasVendor = "/api/app/vas_management/update_vas_vendor";
export const addServiceRequest = "/api/app/vas_management/add_service_request";
export const addServiceInvoice = "/api/app/vas_management/add_service_invoice";
export const updateServiceRequest =
  "/api/app/vas_management/update_service_request";
export const getServiceInvoicesByCustomer =
  "/api/app/vas_management/get_service_invoices_by_customer";
export const deleteValueAddedService =
  "/api/app/vas_management/delete_value_added_service";
export const getFacilityServiceRequests =
  "/api/app/vas_management/get_facility_service_requests";
export const assignWorkOrder = "/api/app/vas_management/assign_work_order";
export const getWorkOrders = "/api/app/vas_management/get_work_orders";
//All invoices
export const getVasInvoices = "/api/app/vas_management/get_vas_invoices";
//single invoice
export const getVasInvoice = "/api/app/vas_management/get_vas_invoice_by_id";
//settings management
export const addEmailSettings =
  "/api/app/settings_management/add_email_settings";
export const addSmsSettings = "/api/app/settings_management/add_sms_settings";
//Write endpoints
export const deleteWorkOrder = "/api/app/vas_management/delete_work_order";
export const getInvoice = "/api/app/levy_management/get_invoice_by_id";


// offline invoice
export const getOfflineInvoice = "/api/offline/levy_management/invoices/get_invoice_by_id";



export const deleteInvoice = "/api/app/levy_management/delete_invoice";
export const getFacilityInvoices =
  "/api/app/levy_management/get_facility_invoices";
export const getLeaseInvoice = "/api/app/levy_management/get_lease_invoices";
export const getsingleLeaseInvoice =
  "/api/app/levy_management/invoices/get_single_lease_invoice";


export const add_lease =
  "/api/app/lease_management/lease_agreements/add_lease_agreement";
export const update_lease =
  "/api/app/lease_management/lease_agreements/update_lease";
export const upload_lease_document = "/api/app/lease_management/document_management/upload_lease_document";
export const get_lease_documents =
  "/api/app/lease_management/lease_agreements/get_lease_documents";
export const get_leases =
  "/api/app/lease_management/lease_agreements/get_leases";
export const get_lease = "/api/app/lease_management/lease_agreements/get_lease";
export const delete_lease =
  "/api/app/lease_management/lease_agreements/delete_lease";
export const terminate_lease =
  "/api/app/lease_management/lease_agreements/terminate_lease";
export const create_lease_template =
  "/api/app/lease_management/lease_templates/create_lease_template";
export const renew_lease =
  "/api/app/lease_management/lease_agreements/renew_lease";
export const update_lease_status =
  "/api/app/lease_management/lease_agreements/update_lease_status";
export const add_lease_agreement =
  "/api/app/lease_management/lease_templates/add_lease_agreement";
export const get_lease_templates =
  "/api/app/lease_management/lease_templates/get_lease_templates";
export const get_lease_template =
  "/api/app/lease_management/lease_templates/get_lease_template";
export const update_lease_template =
  "/api/app/lease_management/lease_templates/update_lease_template";
export const delete_lease_template =
  "/api/app/lease_management/lease_templates/delete_lease_template";

export const create_lease_penalty =
  "/api/app/lease_management/lease_penalties/create_lease_penalty";
export const get_lease_penalty =
  "/api/app/lease_management/lease_penalties/get_single_penalty";
export const get_lease_penalties =
  "/api/app/lease_management/lease_penalties/get_lease_penalties";
export const update_lease_penalty =
  "/api/app/lease_management/lease_penalties/update_lease_penalty";
export const penalty_status =
  "/api/app/lease_management/lease_penalties/penalty_status";

export const create_lease_reminder =
  "/api/app/lease_management/lease_reminders/create_lease_reminder";
export const get_lease_reminders =
  "/api/app/lease_management/lease_reminders/get_lease_reminders";
export const get_lease_reminder =
  "/api/app/lease_management/lease_reminders/get_single_reminder";
export const update_lease_reminder =
  "/api/app/lease_management/lease_reminders/update_lease_reminder";
export const toggle_lease_reminder_status =
  "/api/app/lease_management/lease_reminders/reminder_status";

export const create_move_in_handover = "/api/app/handover_management/move_in_handover";
export const create_move_out_handover = "/api/app/handover_management/move_out_handover";
export const get_all_handovers = "/api/app/handover_management/get_all_handovers";
export const get_handover = "/api/app/handover_management/get_handover";
export const update_handover = "/api/app/handover_management/update_handover";
export const delete_handover = "/api/app/handover_management/delete_handover";
export const compare_handovers = "/api/app/handover_management/compare_handovers";

//home inspection
export const get_all_inspection_settings = "/api/app/handover_management/get_all_inspection_settings";
export const get_inspection_settings_by_id = "/api/app/handover_management/get_inspection_settings_by_id";
export const add_inspection_settings = "/api/app/handover_management/add_inspection_settings";
export const update_inspection_settings = "/api/app/handover_management/update_inspection_settings";
export const delete_inspection_settings = "/api/app/handover_management/delete_inspection_settings";
export const get_default_inspection_settings = "/api/app/handover_management/get_default_inspection_settings";
export const set_default_inspection_settings = "/api/app/handover_management/set_default_inspection_settings";
export const get_inspection_settings_by_category = "/api/app/handover_management/get_inspection_settings_by_category";


// Expense and Budget Management Routes

// Expense
export const addExpense = "/api/app/expense_management/add_expense";
export const getExpenses = "/api/app/expense_management/get_expenses";
export const deleteExpense = "/api/app/expense_management/delete_expense";
export const editExpense = "/api/app/expense_management/edit_expense";

// Budget
export const getBudgets = "/api/app/expense_management/get_budgets";
export const addBudget = "/api/app/expense_management/add_budget";
export const deleteBudget = "/api/app/expense_management/delete_budget";
export const editBudget = "/api/app/expense_management/edit_budget";
export const getBudget = "/api/app/expense_management/get_budget";

// Utilities
export const analog_base_url = "/api/app/analog-meters";
export const smart_meters_base_url = "/api/app/water-meters";
export const meters_readings_base_url = "/api/app/water-meters/readings";
export const customer_account_base_url = "/api/app/customer-account";

//Currencies
export const ADD_CURRENCY_URL = "/api/app/settings_management/add_currency";
export const GET_CURRENCIES_URL = "/api/app/settings_management/get_currencies";
export const DELETE_CURRENCY_URL = "/api/app/settings_management/delete_currency";
export const EDIT_CURRENCY_URL = "/api/app/settings_management/edit_currency";

// Water Meter Settings Urls
export const ADD_WATER_METER_SETTINGS_URL = "/api/app/settings_management/add-meter-settings";
export const GET_WATER_METER_SETTINGS_URL = "/api/app/settings_management/get-meter-settings";
export const UPDATE_WATER_METER_SETTINGS_URL = "/api/app/settings_management/update-settings";
export const getWaterInvoice = "/api/app/water-invoices/account";

//Payment management
export const getCashPayments = "/api/app/payment_management/cash/get_cash_payments";
export const approveCashPayment = "/api/app/payment_management/cash/approve_cash_payment";
export const rejectCashPayment = "/api/app/payment_management/cash/reject_cash_payment";
export const voidCashPayment = "/api/app/payment_management/cash/void_cash_payment";
export const recordCashPayment = "/api/app/payment_management/cash/record_cash_payment";
export const getUnpaidInvoices = '/api/app/payment_management/get_unpaid_invoices';
export const applyOverpayment = '/api/app/payment_management/apply_overpayment';
export const get_credit_invoices = '/api/app/payment_management/get_credit_invoices';


// campaign urls
export const addCampaignURL = "/api/app/campaign_management/add_campaign";
export const getCampaignsURL = "/api/app/campaign_management/get_campaigns";
export const updateCampaignURL = "/api/app/campaign_management/update_campaign";
export const getInvoiceReconciliation = '/api/app/payment_management/get_invoice_reconciliation';
