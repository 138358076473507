import React from 'react';
import ConfirmDialog from "../../component/confirmDialog";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";
import { disableContract as disableContractUrl } from "../../../../utils/urls";

const DisableContract = ({
  visible,
  onHide,
  contractId,
  isActivating,
  onSuccess
}) => {
  const handleStatusToggle = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const newStatus = isActivating ? "Active" : "Inactive";

      const response = await makeRequest2(
        `${disableContractUrl}/${facilityId}/${contractId}`,
        "POST",
        { status: newStatus }
      );

      if (response.success) {
        toastify(`Contract ${newStatus.toLowerCase()} successfully`, "success");
        onHide();
        onSuccess();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  return (
    <ConfirmDialog
      visible={visible}
      onHide={onHide}
      message={`Are you sure you want to ${isActivating ? 'activate' : 'deactivate'} this contract?`}
      onConfirm={handleStatusToggle}
    />
  );
};

export default DisableContract;