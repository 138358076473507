import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';

import { Dialog } from 'primereact/dialog';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../component/layout';
import { getVisitLogURL, exitVisitLogURL, getEntriesAndExitsForFacility, allowVisitURL, allowVisitorURL } from '../../../../utils/urls';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import formatISODate from '../../../../utils/formatDate';
import { getItem } from '../../../../utils/localStorage';
const statusStyles = {
    'Visit Confirmation': {
        color: '#ff9800',         // Orange
        backgroundColor: '#fff3e0' // Light Orange
    },
    'Scheduled': {
        color: '#4caf50',         // Green
        backgroundColor: '#e8f5e9' // Light Green
    },
    'Checked In': {
        color: '#2196f3',         // Blue
        backgroundColor: '#e3f2fd' // Light Blue
    },
    'Checked Out': {
        color: '#9c27b0',         // Purple
        backgroundColor: '#f3e5f5' // Light Purple
    },
    'Cancelled': {
        color: '#f44336',         // Red
        backgroundColor: '#ffebee' // Light Red
    }
};
const ViewWaitingList = () => {
    const navigate = useNavigate()
    const { visitLogId } = useParams();
    const [log, setLog] = useState({});
    const [visitor, setVisitor] = useState({});
    const [resident, setResident] = useState({})
    const [confirmOTPVisible, setConfirmOTPDialogVisible] = useState(false)
    const [carRegistration, setCarRegistration] = useState('');
    const [carMake, setCarMake] = useState('');
    const [carColor, setCarColor] = useState('');
    const [carOccupants, setCarOccupants] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [entryexists, setEntryExit] = useState([])
    const [entry, setEntry] = useState('')
    const [exit, setExit] = useState('')
    const [otp, setOTP] = useState('')
    const [user, setUser] = useState({})
    const [exitVisible, setExitVisible] = useState(false)
    const [allowVisitorVisible, setAllowVisitorVisible] = useState(false)
    const [visitorType, setVisitorType] = useState('');


    const getVisitorLog = async () => {
        try {
            const response = await makeRequest2(getVisitLogURL + '/' + visitLogId, 'GET', {});

            if (response.success) {
                setLog(response.data.visitLog)
                setVisitor(response.data.visitor)
                setResident(response.data.resident)
                setUser(response.data.user)

            } else {
                throw new Error(response.error);
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const handleExitButton = async () => {
        try {

            if (exit === '') {
                throw new Error('Exit Point is required')
            }
            else {
                const response = await makeRequest2(exitVisitLogURL + '/' + visitLogId, 'POST', {
                    exit
                });
                if (response.success) {
                    toastify(response.data)
                    getVisitorLog()
                    setExitVisible(false)
                }
                else {
                    throw new Error(response.error);
                }
            }

        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const fetchEntriesAndExitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setEntryExit(response.data);
            } else {
                throw new Error('Failed to fetch entries and exits')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const handleAllowVisitClick = async () => {
        try {
            if (entry === '') {
                throw new Error('Entry is required')
            }
            else if (otp === '') {
                throw new Error('OTP is required')
            }
            const response = await makeRequest2(allowVisitURL + '/' + visitLogId, 'POST', {
                carRegistration, carMake, carColor, carOccupants, idNumber, visitorType, entry, otp
            })

            if (response.success) {
                toastify('Successfully submitted', 'success')
                setConfirmOTPDialogVisible(false)
                getVisitorLog()
                setCarRegistration('')
                setCarMake('');
                setCarColor('');
                setCarOccupants('');
                setEntry('')
                setIdNumber('')
                setVisitorType('')
                setOTP('')
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }

    }
    const handleAllowVisitor = async () => {
        try {
            if (idNumber === '') {
                throw new Error('ID Number / Passport Number / Driving License is required')
            }
            else if (entry === '') {
                throw new Error('Entry is required')
            }
            else if (visitorType === '') {
                throw new Error('Visitor Type is required')
            }
            const response = await makeRequest2(allowVisitorURL + '/' + visitLogId, 'POST', {
                carRegistration, carMake, carColor, carOccupants, idNumber, visitorType, entry
            })

            if (response.success) {
                setAllowVisitorVisible(false)
                toastify('Successfully submitted', 'success')
                setConfirmOTPDialogVisible(false)
                getVisitorLog()
                setCarRegistration('')
                setCarMake('');
                setCarColor('');
                setCarOccupants('');
                setEntry('')
                setIdNumber('')
                setVisitorType('')
                navigate('/facility/visitor_access_management/home');

            }
            else {
                throw new Error(response.error)
            }

        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }
    const getCurrentAccessPoint = async () => {
        try {

            const value = await getItem('selectedEntryPoint');
            if (value) {
                const data = JSON.parse(value)
                setEntry(data)
            }


        }
        catch (err) {
            console.log(err.message)
        }
    }

    useEffect(() => {
        getVisitorLog()
        getCurrentAccessPoint()
        fetchEntriesAndExitsForFacility()
    }, [])
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Waiting List</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-3">

                                    <div className="card">
                                        <div className="card-body position-relative">
                                            <div className="text-center mt-3">
                                                <div className="chat-avtar d-inline-flex mx-auto">
                                                    <i className="ti ti-user me-2" style={{ fontSize: 100 }}></i>

                                                </div>
                                                <h5 className="mb-3"></h5>
                                                <span

                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '5px 10px',
                                                        borderRadius: '15px',
                                                        ...statusStyles[log.status] // Apply styles based on status
                                                    }}
                                                >
                                                    {log.status}
                                                </span>

                                                <hr className="my-3 border border-secondary-subtle" />

                                                {
                                                    visitor && <>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Visitor Name: </strong>{visitor.firstName} {visitor.lastName}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>ID Number: </strong>{visitor.idNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Phone Number: </strong>+254{visitor.phoneNumber}</p>
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    log.status === 'Checked In' &&
                                                    <button className="btn btn-danger" onClick={() => {
                                                        setExitVisible(true)
                                                    }}>Exit</button>
                                                }
                                                {
                                                    log.status === 'Scheduled' &&
                                                    <button className="btn btn-primary" onClick={() => {
                                                        setConfirmOTPDialogVisible(true)
                                                    }}>Allow Visit</button>
                                                }
                                                {
                                                    log.status === 'Visit Confirmation' &&
                                                    <button className="btn btn-primary" onClick={() => {
                                                        setAllowVisitorVisible(true)
                                                    }}>Allow Visitor</button>
                                                }





                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-md-9">
                                    <div className="card">
                                        <div className="card-body">
                                            <p style={{ fontSize: 20 }}>VISIT DETAILS</p>
                                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                <p className="mb-0"><strong>Visitor Name: </strong>{log.visitorName}</p>
                                            </div>
                                            {
                                                visitor &&
                                                <>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>ID Number / Passport Number / Driving License: </strong>{visitor.idNumber}</p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Phone Number: </strong>+254{visitor.phoneNumber}</p>
                                                    </div>
                                                </>
                                            }

                                            {
                                                log.startTime !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Visit Start Time: </strong>{formatISODate(log.startTime)}</p>
                                                </div>
                                            }
                                            {
                                                log.endTime !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Visit End Time: </strong>{formatISODate(log.endTime)}</p>
                                                </div>
                                            }
                                            {
                                                log.days !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Visit Days: </strong>{log.days}</p>
                                                </div>
                                            }
                                            {
                                                log.entryPoint !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Entry Point: </strong>{log.entryPoint}</p>
                                                </div>
                                            }
                                            {
                                                log.exitPoint !== null &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Exit Point: </strong>{log.exitPoint}</p>
                                                </div>
                                            }
                                            {
                                                log.vehicle &&
                                                <>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Registration Number: </strong>{log.vehicle.registration} </p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Make: </strong>{log.vehicle.make}</p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Color: </strong>{log.vehicle.color}</p>
                                                    </div>
                                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                        <p className="mb-0"><strong>Vehicle Occupants: </strong>{log.vehicle.occupants}</p>
                                                    </div>
                                                </>
                                            }
                                            {
                                                log.requestedBy !== null && user &&
                                                <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                    <p className="mb-0"><strong>Requested By: </strong>{user.fullName}</p>
                                                </div>
                                            }





                                            <p style={{ fontSize: 20 }}>RESIDENT DETAILS</p>
                                            {/* <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                <p className="mb-0"><strong>Resident: </strong><Link to={'/facility/customer_management/view_customer/' + resident._id}>{resident.firstName} {resident.lastName}</Link></p>
                                            </div> */}
                                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                <p className="mb-0"><strong>Unit: </strong>{log.houseNumber} </p>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>




                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                header={'Allow Visitor'}
                visible={allowVisitorVisible}
                onHide={() => {
                    if (allowVisitorVisible) setAllowVisitorVisible(false);
                }}
            >

                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card-body">
                                    <div className="container">



                                        <div className="row">

                                            <div className='col-sm-12'>
                                                <div className='card'>
                                                    <div className="card-header">
                                                        <h5>Vehicle Details</h5>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='container'>

                                                            <div className='row'>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label>Vehicle Reg Number</label>
                                                                        <input className="form-control mt-2" type="text" placeholder="Vehicle reg no"
                                                                            value={carRegistration} onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                setCarRegistration(value)
                                                                            }}
                                                                        ></input>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Vehicle Model</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={carMake}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setCarMake(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Vehicle Color</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={carColor}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setCarColor(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">No of Occupants</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={carOccupants}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setCarOccupants(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className='col-sm-12'>
                                                <div className='card'>
                                                    <div className="card-header">
                                                        <strong>Visitor Information</strong>
                                                    </div>

                                                    <div className='card-body'>
                                                        <div className='container'>

                                                            <div className='row'>


                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label>ID Number / Passport Number / Driving License</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control mt-2"
                                                                            value={idNumber}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setIdNumber(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label>Visitor Type<span style={{ color: 'red' }}>*</span></label>
                                                                        <select
                                                                            className="form-control mt-2"
                                                                            value={visitorType}
                                                                            onChange={(e) => { setVisitorType(e.target.value) }}
                                                                        >
                                                                            <option value="">-- Select --</option>
                                                                            <option value="Family">Family</option>
                                                                            <option value="Staff">Staff</option>
                                                                            <option value="Service Providers">Delivery Personnel / Cab Drivers / Garbage Collector</option>
                                                                            <option value="Government Officials/Agents">Government Officials / Agents</option>
                                                                            <option value="Student">Student</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                    </div>
                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary"
                                onClick={() => {
                                    handleAllowVisitor()
                                }}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog>
            <Dialog
                header={'Allow Visit'}
                visible={confirmOTPVisible}
                style={{ width: '30vw' }}
                onHide={() => {
                    if (confirmOTPVisible) setConfirmOTPDialogVisible(false);
                }}
            >

                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card-body">
                                    <div className="container">



                                        <div className="row">

                                            <div className='col-sm-12'>
                                                <div className='card'>
                                                    <div className="card-header">
                                                        <h5>Vehicle Details</h5>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='container'>

                                                            <div className='row'>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label>Vehicle Reg Number</label>
                                                                        <input className="form-control mt-2" type="text" placeholder="Vehicle reg no"
                                                                            value={carRegistration} onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                setCarRegistration(value)
                                                                            }}
                                                                        ></input>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Vehicle Model</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={carMake}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setCarMake(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Vehicle Color</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={carColor}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setCarColor(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">No of Occupants</label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={carOccupants}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setCarOccupants(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className='col-sm-12'>
                                                <div className='card'>

                                                    <div className='card-body'>
                                                        <div className='container'>

                                                            <div className='row'>

                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>Entry Point</label>
                                                                        <select
                                                                            className="form-control mt-2"
                                                                            value={entry}
                                                                            onChange={(e) => {
                                                                                setEntry(e.target.value)

                                                                            }}
                                                                        >
                                                                            <option value="">-- Select --</option>
                                                                            {
                                                                                entryexists.map((x) => {
                                                                                    if (x.purpose === 'entry/exit' || x.purpose === 'entry') {
                                                                                        if (x.disabled === false) {
                                                                                            return <option value={x.name}>{x.name}</option>
                                                                                        }
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                            }

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>Confirm OTP</label>
                                                                        <input type="number" className="form-control" value={otp} onChange={(e) => {
                                                                            setOTP(e.target.value)
                                                                        }}></input>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary"
                                onClick={() => {
                                    handleAllowVisitClick()
                                }}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog>
            <Dialog
                header={'Exit'}
                visible={exitVisible}
                style={{ width: '30vw' }}
                onHide={() => {
                    if (exitVisible) setExitVisible(false);
                }}
            >

                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card-body">
                                    <div className="container">




                                        <div className="row">

                                            <div className='col-sm-12'>
                                                <div className='card'>

                                                    <div className='card-body'>
                                                        <div className='container'>

                                                            <div className='row'>

                                                                <div className="col-sm-12">
                                                                    <div className="mb-3">
                                                                        <label>Exit Point</label>
                                                                        <select
                                                                            className="form-control mt-2"
                                                                            value={exit}
                                                                            onChange={(e) => {
                                                                                setExit(e.target.value)

                                                                            }}
                                                                        >
                                                                            <option value="">-- Select --</option>
                                                                            {
                                                                                entryexists.map((x) => {
                                                                                    if (x.purpose === 'entry/exit' || x.purpose === 'exit') {
                                                                                        if (x.disabled === false) {
                                                                                            return <option value={x.name}>{x.name}</option>
                                                                                        }
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                            }

                                                                        </select>
                                                                    </div>
                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary"
                                onClick={() => {
                                    handleExitButton()
                                }}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog>




        </Layout>
    );
};






export default ViewWaitingList;
