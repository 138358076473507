export const getLocation = () => {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            reject(new Error('Geolocation is not supported by this browser.'));
            return;
        }

        // Attempt to get the current position, which will prompt for permission if needed
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                resolve({ latitude, longitude });
            },
            (error) => {
                // Handle specific geolocation errors
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        reject(new Error('Location permission denied by the user.'));
                        break;
                    case error.POSITION_UNAVAILABLE:
                        reject(new Error('Location information is unavailable.'));
                        break;
                    case error.TIMEOUT:
                        reject(new Error('The request to get location timed out.'));
                        break;
                    default:
                        reject(new Error('An unknown error occurred while retrieving location.'));
                        break;
                }
            }
        );
    });
};
