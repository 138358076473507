import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TransactionsTab = ({ transactions, loading }) => {
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <DataTable
                            value={transactions}
                            loading={loading}
                            paginator
                            rows={10}
                            stripedRows
                        >
                            <Column field="id" header="Transaction ID" sortable />
                            <Column field="date" header="Date" body={(row) => new Date(row.date).toLocaleDateString()} sortable />
                            <Column field="amount" header="Amount" body={(row) => `$${row.amount.toFixed(2)}`} sortable />
                            <Column field="status" header="Status" sortable />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionsTab;