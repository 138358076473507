import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
import Layout from "../component/layout";
import { toastify } from "../../../utils/toast";
import { getItem } from "../../../utils/localStorage";
import { makeRequest2 } from "../../../utils/makeRequest";
import {
  create_move_in_handover,
  getUnitsForFacility,
  getCustomersURL
} from "../../../utils/urls";
import InspectionItemSelector from "./inspection_item_selector";

const CreateMoveInHandover = ({ isDialog = false, onSuccess }) => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const [showInspectionDialog, setShowInspectionDialog] = useState(false);
  const [facilityId, setFacilityId] = useState("");

  // New item form state
  const [newItem, setNewItem] = useState({
    name: "",
    category: "",
    description: "",
    condition: "Good",
    quantity: 1,
    serialNumber: "",
    notes: ""
  });

  const [handoverData, setHandoverData] = useState({
    unitId: "",
    customerId: "",
    handoverDate: new Date(),
    items: [],
    meterReadings: {
      electricity: { reading: 0, imageUrl: "" },
      water: { reading: 0, imageUrl: "" },
      gas: { reading: 0, imageUrl: "" }
    },
    keysHandedOver: 1,
    notes: "",
    attachments: [],
    signatures: {
      propertyManager: { signature: "", date: null },
      customer: { signature: "", date: null, agreement: false }
    },
    status: "Draft"
  });

  const categoryOptions = [
    { label: "Furniture", value: "Furniture" },
    { label: "Appliance", value: "Appliance" },
    { label: "Electronics", value: "Electronics" },
    { label: "Fixture", value: "Fixture" },
    { label: "Utility", value: "Utility" },
    { label: "Other", value: "Other" }
  ];

  const conditionOptions = [
    { label: "Excellent", value: "Excellent" },
    { label: "Good", value: "Good" },
    { label: "Fair", value: "Fair" },
    { label: "Poor", value: "Poor" },
    { label: "Damaged", value: "Damaged" },
    { label: "Non-functional", value: "Non-functional" }
  ];

  const statusOptions = [
    { label: "Draft", value: "Draft" },
    { label: "Completed", value: "Completed" }
  ];

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        // Get facility ID
        const facilityIdValue = await getItem("selectedFacilityId");
        if (!facilityIdValue) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Facility ID not found',
            life: 3000
          });
          setLoading(false);
          return;
        }
        setFacilityId(facilityIdValue);

        // Fetch units and customers in parallel for better performance
        const [unitsResponse, customersResponse] = await Promise.all([
          makeRequest2(`${getUnitsForFacility}/${facilityIdValue}`, "GET"),
          makeRequest2(`${getCustomersURL}/${facilityIdValue}`, "GET")
        ]);

        // Process units
        if (unitsResponse.success) {
          // Filter units to only include those with customers assigned
          const unitsWithCustomers = unitsResponse.data.filter(
            unit => unit.homeOwnerId || unit.tenantId
          );
          setUnits(unitsWithCustomers);
        } else {
          console.error("Failed to fetch units:", unitsResponse.error);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to fetch unit data',
            life: 3000
          });
        }

        // Process customers
        if (customersResponse.success) {
          setCustomers(customersResponse.data);
        } else {
          console.error("Failed to fetch customers:", customersResponse.error);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to fetch customer data',
            life: 3000
          });
        }
      } catch (error) {
        console.error("Error loading data:", error);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `Error loading data: ${error.message}`,
          life: 3000
        });
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHandoverData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleMeterReadingChange = (utility, value) => {
    setHandoverData(prev => ({
      ...prev,
      meterReadings: {
        ...prev.meterReadings,
        [utility]: {
          ...prev.meterReadings[utility],
          reading: value
        }
      }
    }));
  };

  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUnitSelect = (unitId) => {
    const selectedUnit = units.find(unit => unit._id === unitId);
    setHandoverData(prev => ({
      ...prev,
      unitId: unitId,
      // If unit has a tenant, prefer them over homeowner
      customerId: selectedUnit?.tenantId || selectedUnit?.homeOwnerId || ""
    }));
  };

  const handleAddItem = () => {
    if (!newItem.name || !newItem.category) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Item name and category are required',
        life: 3000
      });
      return;
    }

    setHandoverData(prev => ({
      ...prev,
      items: [...prev.items, { ...newItem }]
    }));

    setNewItem({
      name: "",
      category: "",
      description: "",
      condition: "Good",
      quantity: 1,
      serialNumber: "",
      notes: ""
    });

    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Item added successfully',
      life: 3000
    });
    
    setShowAddItemDialog(false);
  };

  const handleAddInspectionItems = (selectedItems) => {
    // Merge with existing items, avoiding duplicates by name + category
    const existingItems = [...handoverData.items];
    const newItems = [];
    
    selectedItems.forEach(newItem => {
      const existingIndex = existingItems.findIndex(
        item => item.name === newItem.name && item.category === newItem.category
      );
      
      if (existingIndex !== -1) {
        // Update existing item
        existingItems[existingIndex] = {
          ...existingItems[existingIndex],
          condition: newItem.condition,
          quantity: newItem.quantity
        };
      } else {
        // Add new item
        newItems.push(newItem);
      }
    });
    
    setHandoverData(prev => ({
      ...prev,
      items: [...existingItems, ...newItems]
    }));
    
    toast.current.show({
      severity: 'success',
      summary: 'Items Added',
      detail: `${newItems.length} new items added from inspection list`,
      life: 3000
    });
  };

  const handleRemoveItem = (index) => {
    setHandoverData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
    
    toast.current.show({
      severity: 'info',
      summary: 'Item Removed',
      detail: 'Item removed from handover',
      life: 2000
    });
  };

  const handleFileUpload = (event) => {
    try {
      const files = event.files;
      
      if (!files || files.length === 0) {
        toast.current.show({
          severity: 'error',
          summary: 'Upload Failed',
          detail: 'No files selected',
          life: 3000
        });
        return;
      }
      
      // Check file sizes
      const oversizedFiles = files.filter(file => file.size > 5000000); // 5MB limit
      if (oversizedFiles.length > 0) {
        toast.current.show({
          severity: 'error',
          summary: 'File Too Large',
          detail: `${oversizedFiles.length} file(s) exceed the 5MB size limit`,
          life: 3000
        });
        return;
      }
      
      // Process valid files
      const newAttachments = Array.from(files).map(file => {
        // Generate unique file ID
        const fileId = Date.now() + '-' + Math.random().toString(36).substr(2, 9);
        
        return {
          id: fileId,
          name: file.name,
          fileUrl: URL.createObjectURL(file),
          file: file,
          uploadDate: new Date().toISOString(),
          size: file.size
        };
      });
  
      setHandoverData(prev => ({
        ...prev,
        attachments: [...prev.attachments, ...newAttachments]
      }));
  
      // Clear the FileUpload component
      event.options.clear();
      
      toast.current.show({
        severity: 'success',
        summary: 'Files Uploaded',
        detail: `${files.length} file(s) uploaded successfully`,
        life: 3000
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Upload Failed',
        detail: error.message || 'An error occurred while uploading files',
        life: 3000
      });
    }
  };

  const handleRemoveAttachment = (index) => {
    setHandoverData(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  const handleClear = () => {
    setHandoverData({
      unitId: "",
      customerId: "",
      handoverDate: new Date(),
      items: [],
      meterReadings: {
        electricity: { reading: 0, imageUrl: "" },
        water: { reading: 0, imageUrl: "" },
        gas: { reading: 0, imageUrl: "" }
      },
      keysHandedOver: 1,
      notes: "",
      attachments: [],
      signatures: {
        propertyManager: { signature: "", date: null },
        customer: { signature: "", date: null, agreement: false }
      },
      status: "Draft"
    });
    
    toast.current.show({
      severity: 'info',
      summary: 'Form Cleared',
      detail: 'All form data has been cleared',
      life: 3000
    });
  };

  const validateForm = () => {
    if (!handoverData.unitId) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Please select a unit',
        life: 3000
      });
      return false;
    }

    if (!handoverData.customerId) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Please select a customer',
        life: 3000
      });
      return false;
    }

    if (!handoverData.handoverDate) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Handover date is required',
        life: 3000
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm() || isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      if (!facilityId) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Facility ID not found',
          life: 3000
        });
        return;
      }

      // Format the data for submission
      const formData = {
        ...handoverData,
        handoverDate: handoverData.handoverDate.toISOString()
      };

      const response = await makeRequest2(
        `${create_move_in_handover}/${facilityId}`,
        "POST",
        formData
      );

      if (response.success) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Move-in handover created successfully',
          life: 3000
        });
        
        if (isDialog) {
          onSuccess?.();
        } else {
          navigate("/facility/handover_management/handovers");
        }
      } else {
        console.error("API Error Response:", response);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response.error || 'Failed to create move-in handover',
          life: 5000
        });
      }
    } catch (error) {
      console.error("Error creating handover:", error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message || 'An unexpected error occurred',
        life: 5000
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Templates for DataTable
  const conditionTemplate = (rowData) => {
    const severityMap = {
      'Excellent': 'success',
      'Good': 'success',
      'Fair': 'warning',
      'Poor': 'warning',
      'Damaged': 'danger',
      'Non-functional': 'danger'
    };
    
    return (
      <Tag 
        value={rowData.condition} 
        severity={severityMap[rowData.condition] || 'info'}
      />
    );
  };
  
  const categoryTemplate = (rowData) => {
    return <Tag value={rowData.category} severity="info" />;
  };
  
  const quantityTemplate = (rowData) => {
    return <span className="font-semibold">{rowData.quantity}</span>;
  };
  
  const actionTemplate = (rowData, { rowIndex }) => {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger p-button-sm"
          onClick={() => handleRemoveItem(rowIndex)}
          tooltip="Remove Item"
        />
      </div>
    );
  };

  const renderInspectionItemsDialog = () => {
    return (
      <Dialog
        header="Select Items from Inspection List"
        visible={showInspectionDialog}
        style={{ width: '80vw', maxWidth: '1200px' }}
        onHide={() => setShowInspectionDialog(false)}
        modal
        resizable={false}
        className="p-dialog-custom"
        footer={
          <div className="text-right">
            <Button
              label="Close"
              icon="ti ti-times"
              className="p-button-text"
              onClick={() => setShowInspectionDialog(false)}
            />
          </div>
        }
      >
        <InspectionItemSelector
          facilityId={facilityId}
          onItemsSelected={handleAddInspectionItems}
          onClose={() => setShowInspectionDialog(false)}
        />
      </Dialog>
    );
  };

  const renderAddItemDialog = () => {
    return (
      <Dialog
        header="Add Inventory Item"
        visible={showAddItemDialog}
        style={{ width: '50vw', maxWidth: '800px' }}
        onHide={() => setShowAddItemDialog(false)}
        modal
        resizable={false}
        footer={
          <div className="text-end">
            <Button
              label="Cancel"
              icon="ti ti-times"
              className="p-button-text me-2"
              onClick={() => setShowAddItemDialog(false)}
            />
            <Button
              label="Add Item"
              icon="ti ti-plus"
              className="p-button-success"
              onClick={handleAddItem}
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="row">
            <div className="col-md-6 field mb-3">
              <label htmlFor="name" className="font-medium mb-2 d-block">Item Name*</label>
              <InputText
                id="name"
                name="name"
                value={newItem.name}
                onChange={handleNewItemChange}
                required
                className="w-100"
              />
            </div>
            <div className="col-md-6 field mb-3">
              <label htmlFor="category" className="font-medium mb-2 d-block">Category*</label>
              <Dropdown
                id="category"
                name="category"
                value={newItem.category}
                options={categoryOptions}
                onChange={handleNewItemChange}
                placeholder="Select a category"
                className="w-100"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 field mb-3">
              <label htmlFor="condition" className="font-medium mb-2 d-block">Condition</label>
              <Dropdown
                id="condition"
                name="condition"
                value={newItem.condition}
                options={conditionOptions}
                onChange={handleNewItemChange}
                className="w-100"
              />
            </div>
            <div className="col-md-6 field mb-3">
              <label htmlFor="quantity" className="font-medium mb-2 d-block">Quantity</label>
              <InputNumber
                id="quantity"
                name="quantity"
                value={newItem.quantity}
                onValueChange={(e) => handleNewItemChange({ target: { name: 'quantity', value: e.value } })}
                min={1}
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-secondary"
                incrementButtonClassName="p-button-secondary"
                incrementButtonIcon="ti ti-plus"
                decrementButtonIcon="ti ti-minus"
                className="w-100"
              />
            </div>
          </div>
          <div className="field mb-3">
            <label htmlFor="serialNumber" className="font-medium mb-2 d-block">Serial Number (if applicable)</label>
            <InputText
              id="serialNumber"
              name="serialNumber"
              value={newItem.serialNumber}
              onChange={handleNewItemChange}
              className="w-100"
            />
          </div>
          <div className="field mb-3">
            <label htmlFor="description" className="font-medium mb-2 d-block">Description</label>
            <InputTextarea
              id="description"
              name="description"
              value={newItem.description}
              onChange={handleNewItemChange}
              rows={3}
              autoResize
              className="w-100"
            />
          </div>
          <div className="field mb-3">
            <label htmlFor="notes" className="font-medium mb-2 d-block">Notes</label>
            <InputTextarea
              id="notes"
              name="notes"
              value={newItem.notes}
              onChange={handleNewItemChange}
              rows={2}
              autoResize
              className="w-100"
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const content = (
    <>
      <Toast ref={toast} />
      
      {loading ? (
        <div className="d-flex align-items-center justify-content-center py-5">
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
          <span className="ms-3 font-medium">Loading data...</span>
        </div>
      ) : (
        <div className="card-body">
          <Card className="mb-4">
            <div className="card-title mb-0 p-3 bg-light">
              <h5 className="mb-0">Basic Information</h5>
            </div>
            <Divider className="m-0" />
            <div className="p-4">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label font-medium mb-2">Unit*</label>
                  <Dropdown
                    className="w-100"
                    value={handoverData.unitId}
                    options={units.map(unit => ({
                      label: `${unit.name} (${unit.floorUnitNo})`,
                      value: unit._id
                    }))}
                    onChange={(e) => handleUnitSelect(e.value)}
                    placeholder="Select a unit"
                    filter
                    emptyFilterMessage="No units found"
                    emptyMessage="No units available"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label font-medium mb-2">Customer*</label>
                  <Dropdown
                    className="w-100"
                    value={handoverData.customerId}
                    options={customers.map(customer => ({
                      label: `${customer.firstName} ${customer.lastName}`,
                      value: customer._id
                    }))}
                    onChange={(e) => handleInputChange({
                      target: { name: 'customerId', value: e.value }
                    })}
                    placeholder="Select a customer"
                    disabled={!handoverData.unitId}
                    filter
                    emptyFilterMessage="No customers found"
                    emptyMessage="No customers available"
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="form-label font-medium mb-2">Handover Date*</label>
                  <Calendar
                    className="w-100"
                    value={handoverData.handoverDate}
                    onChange={(e) => handleInputChange({
                      target: { name: 'handoverDate', value: e.value }
                    })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>

                <div className="col-md-4 mb-3">
  <label className="form-label font-medium mb-2">Number of Keys</label>
  <InputNumber
    className="w-100"
    value={handoverData.keysHandedOver}
    onValueChange={(e) => handleInputChange({
      target: { name: 'keysHandedOver', value: e.value }
    })}
    min={0}
    showButtons
  />
</div>

                <div className="col-md-4 mb-3">
                  <label className="form-label font-medium mb-2">Status</label>
                  <Dropdown
                    className="w-100"
                    value={handoverData.status}
                    options={statusOptions}
                    onChange={(e) => handleInputChange({
                      target: { name: 'status', value: e.value }
                    })}
                    optionLabel="label"
                  />
                </div>
              </div>
            </div>
          </Card>

          <Card className="mb-4">
            <div className="card-title mb-0 p-3 bg-light">
              <h5 className="mb-0">Meter Readings</h5>
            </div>
            <Divider className="m-0" />
            <div className="p-4">
              <div className="row">
              <div className="col-md-4 mb-3">
  <label className="form-label font-medium mb-2">Electricity Reading</label>
  <InputNumber
    className="w-100"
    value={handoverData.meterReadings.electricity.reading}
    onValueChange={(e) => handleMeterReadingChange('electricity', e.value)}
    min={0}
    mode="decimal"
    showButtons
  />
</div>
<div className="col-md-4 mb-3">
  <label className="form-label font-medium mb-2">Water Reading</label>
  <InputNumber
    className="w-100"
    value={handoverData.meterReadings.water.reading}
    onValueChange={(e) => handleMeterReadingChange('water', e.value)}
    min={0}
    mode="decimal"
    showButtons
  />
</div>
<div className="col-md-4 mb-3">
  <label className="form-label font-medium mb-2">Gas Reading</label>
  <InputNumber
    className="w-100"
    value={handoverData.meterReadings.gas.reading}
    onValueChange={(e) => handleMeterReadingChange('gas', e.value)}
    min={0}
    mode="decimal"
    showButtons
  />
</div>
              </div>
            </div>
          </Card>

          <Card className="mb-4">
            <div className="card-title mb-0 p-3 bg-light d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Inventory Items</h5>
              <div className="d-flex gap-2">
                <Button
                  label="Add From Inspection List"
                  icon="ti ti-list"
                  className="p-button-info p-button-sm"
                  onClick={() => setShowInspectionDialog(true)}
                />
                <Button
                  label="Add Custom Item"
                  icon="ti ti-plus"
                  className="p-button-success p-button-sm"
                  onClick={() => setShowAddItemDialog(true)}
                />
              </div>
            </div>
            <Divider className="m-0" />
            <div className="p-0">
              <DataTable
                value={handoverData.items}
                emptyMessage={
                  <div className="text-center p-4">
                    <i className="ti ti-box-off mb-2" style={{ fontSize: '2rem' }}></i>
                    <p className="mb-0">No items added yet</p>
                    <small className="text-muted">Add items from the inspection list or create custom items</small>
                  </div>
                }
                stripedRows
                showGridlines
                className="mb-0"
                responsiveLayout="stack"
                breakpoint="960px"
              >
                <Column field="name" header="Name" style={{ width: '25%' }}></Column>
                <Column field="category" header="Category" body={categoryTemplate} style={{ width: '20%' }}></Column>
                <Column field="condition" header="Condition" body={conditionTemplate} style={{ width: '20%' }}></Column>
                <Column field="quantity" header="Quantity" body={quantityTemplate} style={{ width: '15%' }}></Column>
                <Column body={actionTemplate} header="Actions" style={{ width: '10%' }}></Column>
              </DataTable>
            </div>
            {handoverData.items.length > 0 && (
              <div className="p-3 bg-light text-end">
                <span className="badge bg-primary me-2">{handoverData.items.length}</span>
                <span>Items in inventory</span>
              </div>
            )}
          </Card>

          <Card className="mb-4">
  <div className="card-title mb-0 p-3 bg-light">
    <h5 className="mb-0">Attachments</h5>
  </div>
  <Divider className="m-0" />
  <div className="p-4">
    <FileUpload
      name="document"
      mode="advanced"
      multiple
      accept="image/*,application/pdf"
      maxFileSize={5000000}
      customUpload={true}
      uploadHandler={handleFileUpload}
      chooseLabel="Add Files"
      className="mb-3"
      emptyTemplate={
        <div className="p-d-flex p-ai-center p-dir-col p-4 text-center">
          <i className="pi pi-cloud-upload mb-2" style={{ fontSize: '2.5rem', color: 'var(--primary-color)' }}></i>
          <span className="font-medium mb-2">Drag and drop files here</span>
          <span className="text-muted">or click to browse</span>
          <small className="d-block mt-2 text-muted">Supported formats: Images, PDF (Max 5MB)</small>
        </div>
      }
    />
    {handoverData.attachments.length > 0 && (
      <div className="mt-3">
        <h6 className="font-medium mb-2">Uploaded Files:</h6>
        <div className="list-group">
          {handoverData.attachments.map((attachment, index) => (
            <div key={index} className="list-group-item d-flex justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  {attachment.name.endsWith('.pdf') ? (
                    <i className="ti ti-file-text text-danger" style={{ fontSize: '2rem' }}></i>
                  ) : (
                    <div className="position-relative" style={{ width: '50px', height: '50px' }}>
                      <img 
                        src={attachment.fileUrl} 
                        alt={attachment.name}
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'cover',
                          borderRadius: '4px'
                        }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <h6 className="mb-0">{attachment.name}</h6>
                  <small className="text-muted">
                    {attachment.file?.size ? `${(attachment.file.size / 1024).toFixed(2)} KB` : 'File uploaded'}
                  </small>
                </div>
              </div>
              <div className="d-flex gap-2">
                {attachment.fileUrl && (
                  <a 
                    href={attachment.fileUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-outline-primary"
                    title="View file"
                  >
                    <i className="ti ti-eye"></i>
                  </a>
                )}
                <Button
                  icon="ti ti-trash"
                  className="p-button-rounded p-button-danger p-button-sm"
                  onClick={() => handleRemoveAttachment(index)}
                  tooltip="Remove file"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
</Card>

          <Card className="mb-4">
            <div className="card-title mb-0 p-3 bg-light">
              <h5 className="mb-0">Notes</h5>
            </div>
            <Divider className="m-0" />
            <div className="p-4">
              <InputTextarea
                rows={5}
                className="w-100"
                value={handoverData.notes}
                onChange={(e) => handleInputChange({
                  target: { name: 'notes', value: e.target.value }
                })}
                placeholder="Enter any additional notes about the handover"
                autoResize
              />
            </div>
          </Card>

          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button
              type="button"
              label="Clear Form"
              icon="ti ti-eraser"
              className="p-button-outlined p-button-secondary me-2"
              onClick={handleClear}
              disabled={isSubmitting}
            />
            <Button
              type="button"
              label={isSubmitting ? "Creating..." : "Create Move-In Handover"}
              icon="ti ti-check"
              className="p-button-primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </div>
        </div>
      )}
    </>
  );

  if (isDialog) {
    return (
      <>
        {content}
        {renderAddItemDialog()}
        {renderInspectionItemsDialog()}
      </>
    );
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">
                    <i className="ti ti-home-2 me-1"></i>
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/handover_management">
                    <i className="ti ti-exchange me-1"></i>
                    Handover Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Create Move-In Handover</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Link to="#" onClick={() => navigate(-1)} className="btn btn-sm btn-outline-secondary me-2">
                  <i className="ti ti-arrow-narrow-left"></i> Back
                </Link>
                <h5 className="mb-0">Create Move-In Handover</h5>
              </div>
              {handoverData.unitId && handoverData.customerId && (
                <div className="d-flex align-items-center">
                  <span className="me-2">
                    {units.find(u => u._id === handoverData.unitId)?.name} - 
                    {customers.find(c => c._id === handoverData.customerId)?.firstName} 
                    {customers.find(c => c._id === handoverData.customerId)?.lastName}
                  </span>
                  <Tag 
                    value={handoverData.status} 
                    severity={handoverData.status === 'Draft' ? 'warning' : 'success'} 
                  />
                </div>
              )}
            </div>
          </div>
          {content}
        </div>
      </div>
      {renderAddItemDialog()}
      {renderInspectionItemsDialog()}
    </Layout>
  );
};

export default CreateMoveInHandover;