import { useState, useEffect } from 'react';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { addReminder } from '../../../../../utils/urls';
import { getItem } from '../../../../../utils/localStorage';
import { toastify } from '../../../../../utils/toast';

export const useReminderForm = (levies, module, onSubmitSuccess) => {
  const [type, setType] = useState('');
  const [selectedLevyId, setSelectedLevyId] = useState('');
  const [reminderData, setReminderData] = useState({
    name: '',
    type: '',
    time: '',
    day: '',
    frequency: '',
    scheduledDate: '',
    module: module || '', // Explicitly set module from module
    notificationTypes: [],
    message: '',
    isActive: true,
    processed: false
  });

  // Add useEffect to update module if moduleType changes
  useEffect(() => {
    setReminderData(prev => ({
      ...prev,
      module: module || ''
    }));
  }, [module]);

  const handleReminderSubmit = async (e) => {
    e.preventDefault();

    try {
      const facilityId = await getItem('selectedFacilityId');
      const url = addReminder + '/' + facilityId;

      // Get notification types from checked checkboxes
      const notificationTypesChecked = Array.from(
        document.querySelectorAll('input[name="notificationTypes"]:checked')
      ).map(checkbox => checkbox.value);

      // Prepare submission data with explicit module
      const submissionData = {
        ...reminderData,
        module: module,
        facilityId,
        notificationTypes: notificationTypesChecked,
        levyId: selectedLevyId,
        type,
      };

      // Remove unnecessary fields based on reminder type
      if (type === 'onetime') {
        delete submissionData.frequency;
        delete submissionData.day;
      } else if (type === 'recurring') {
        delete submissionData.scheduledDate;
      }

      // Validation
      const requiredFields = ['name', 'type', 'time', 'levyId', 'module', 'facilityId'];
      const missingFields = requiredFields.filter(field => !submissionData[field]);

      if (missingFields.length > 0) {
        toastify('Please fill in all required fields', 'error');
        return;
      }

      // Type-specific validations
      if (type === 'onetime' && !submissionData.scheduledDate) {
        toastify('Scheduled date is required for one-time reminders', 'error');
        return;
      }

      if (type === 'recurring' && (!submissionData.frequency || !submissionData.day)) {
        toastify('Frequency and day are required for recurring reminders', 'error');
        return;
      }

      const response = await makeRequest2(url, 'POST', submissionData);

      if (response.success) {
        toastify('Reminder added successfully', 'success');
        onSubmitSuccess();

        // Reset form state
        setReminderData({
          name: '',
          type: '',
          time: '',
          day: '',
          frequency: '',
          scheduledDate: '',
          module: module || '',
          notificationTypes: [],
          message: '',
          isActive: true,
          processed: false
        });
        setType('');
        setSelectedLevyId('');
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify('An error occured while adding reminder', 'error');
    }
  };

  const handleLevyChange = (levy) => {
    setSelectedLevyId(levy._id);
  };

  const handleTypeChange = (selectedType) => {
    setType(selectedType);
    // Reset conditional fields when type changes
    setReminderData(prev => ({
      ...prev,
      type: selectedType,
      frequency: selectedType === 'recurring' ? '' : undefined,
      scheduledDate: selectedType === 'onetime' ? '' : undefined,
      day: selectedType === 'recurring' ? '' : undefined
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;

    // Handle checkbox for notification types
    if (name === 'notificationTypes') {
      setReminderData(prev => {
        const currentNotificationTypes = prev.notificationTypes || [];
        const updatedNotificationTypes = checked
          ? [...currentNotificationTypes, value]
          : currentNotificationTypes.filter(type => type !== value);

        return {
          ...prev,
          notificationTypes: updatedNotificationTypes
        };
      });
    } else {
      setReminderData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  return {
    type,
    selectedLevyId,
    reminderData,
    handleReminderSubmit,
    handleLevyChange,
    handleTypeChange,
    handleInputChange
  };
};