import React from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const MeterInfoTab = ({ meterDetails }) => {
    if (!meterDetails) {
        return <div>Loading meter information...</div>;
    }

    const handleGetRealtimeData = () => {
        console.log('Getting realtime data...');
    };

    const handleCloseValve = () => {
        console.log('Closing valve...');
    };

    const statusBadgeTemplate = (status) => (
        <span className={`badge rounded-pill text-bg-${status === 'open' ? 'success' : 'danger'}`}>
            {status}
        </span>
    );

    const installedBadgeTemplate = (isInstalled) => (
        <span className={`badge rounded-pill text-bg-${isInstalled ? 'success' : 'danger'}`}>
            {isInstalled ? 'Installed' : 'Not Installed'}
        </span>
    );

    // Prepare a single row of meter details with keys matching columns.
    const detailsRow = {
        serialNumber: meterDetails.serialNumber || 'N/A',
        initialReading: `${meterDetails.initialReading || '0'} m³`,
        currentReading: `${meterDetails.currentReading || '0'} m³`,
        lastUpdated: meterDetails.updatedAt
            ? new Date(meterDetails.updatedAt).toLocaleString()
            : 'N/A',
        unit: meterDetails.UnitInfo?.name || 'N/A'
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    {/* Header with action buttons */}
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2">
                            <Button
                                label="Get Realtime Data"
                                className="btn btn-primary btn-sm"
                                onClick={handleGetRealtimeData}
                            />
                            <Button
                                label="Close Valve"
                                className="btn btn-danger btn-sm"
                                onClick={handleCloseValve}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        {/* Card views for Status and Installation */}
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h6>Status</h6>
                                        {statusBadgeTemplate(meterDetails.status)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h6>Installation</h6>
                                        {installedBadgeTemplate(meterDetails.isInstalled)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Horizontal Table displaying the rest of the meter details */}
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <DataTable
                                            value={[detailsRow]}
                                            emptyMessage="No meter details available."
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="serialNumber" header="Serial Number" />
                                            <Column field="initialReading" header="Initial Reading" />
                                            <Column field="currentReading" header="Current Reading" />
                                            <Column field="lastUpdated" header="Last Updated" />
                                            <Column field="unit" header="Unit" />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeterInfoTab;
