export const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", 
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", 
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", 
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the", 
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", 
    "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", 
    "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", 
    "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", 
    "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", 
    "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", 
    "Korea, South", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", 
    "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", 
    "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", 
    "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", 
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", 
    "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", 
    "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", 
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", 
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", 
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", 
    "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", 
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", 
    "Yemen", "Zambia", "Zimbabwe"
]
export  const unitTypes = [
"Studio",
"One Bedroom",
"Two Bedroom",
"Three Bedroom",
"Penthouse",
"Duplex",
"Loft",
"Townhouse",
"Condominium",
"Retail Space",
"Office Space",
"Warehouse",
"Industrial Space",
"Restaurant Space",
"Showroom",
"Medical Office",
"Co-working Space",
"Flex Space",
"Storage Unit",
"Laboratory",
"Data Center",
"Distribution Center",
"Exhibition Space",
];
export const areaMeasurements = [
{ name: "Square Meter", symbol: "m²" },
{ name: "Square Foot", symbol: "ft²" },
{ name: "Square Yard", symbol: "yd²" },
{ name: "Square Kilometer", symbol: "km²" },
{ name: "Square Mile", symbol: "mi²" },
{ name: "Acre", symbol: "ac" },
{ name: "Hectare", symbol: "ha" },
{ name: "Square Centimeter", symbol: "cm²" },
{ name: "Square Inch", symbol: "in²" },
{ name: "Square Millimeter", symbol: "mm²" }
];

export const currenciesData = [
    {"name": "Afghan Afghani", "code": "AFN"},
    {"name": "Albanian Lek", "code": "ALL"},
    {"name": "Algerian Dinar", "code": "DZD"},
    {"name": "Angolan Kwanza", "code": "AOA"},
    {"name": "Argentine Peso", "code": "ARS"},
    {"name": "Armenian Dram", "code": "AMD"},
    {"name": "Aruban Florin", "code": "AWG"},
    {"name": "Australian Dollar", "code": "AUD"},
    {"name": "Azerbaijani Manat", "code": "AZN"},
    {"name": "Bahamian Dollar", "code": "BSD"},
    {"name": "Bahraini Dinar", "code": "BHD"},
    {"name": "Bangladeshi Taka", "code": "BDT"},
    {"name": "Barbadian Dollar", "code": "BBD"},
    {"name": "Belarusian Ruble", "code": "BYN"},
    {"name": "Belize Dollar", "code": "BZD"},
    {"name": "Bermudian Dollar", "code": "BMD"},
    {"name": "Bhutanese Ngultrum", "code": "BTN"},
    {"name": "Bolivian Boliviano", "code": "BOB"},
    {"name": "Bosnia and Herzegovina Convertible Mark", "code": "BAM"},
    {"name": "Botswana Pula", "code": "BWP"},
    {"name": "Brazilian Real", "code": "BRL"},
    {"name": "British Pound", "code": "GBP"},
    {"name": "Brunei Dollar", "code": "BND"},
    {"name": "Bulgarian Lev", "code": "BGN"},
    {"name": "Burundian Franc", "code": "BIF"},
    {"name": "Cambodian Riel", "code": "KHR"},
    {"name": "Canadian Dollar", "code": "CAD"},
    {"name": "Cape Verdean Escudo", "code": "CVE"},
    {"name": "Cayman Islands Dollar", "code": "KYD"},
    {"name": "Central African CFA Franc", "code": "XAF"},
    {"name": "Chilean Peso", "code": "CLP"},
    {"name": "Chinese Yuan", "code": "CNY"},
    {"name": "Colombian Peso", "code": "COP"},
    {"name": "Comorian Franc", "code": "KMF"},
    {"name": "Congolese Franc", "code": "CDF"},
    {"name": "Costa Rican Colón", "code": "CRC"},
    {"name": "Croatian Kuna", "code": "HRK"},
    {"name": "Cuban Peso", "code": "CUP"},
    {"name": "Czech Koruna", "code": "CZK"},
    {"name": "Danish Krone", "code": "DKK"},
    {"name": "Djiboutian Franc", "code": "DJF"},
    {"name": "Dominican Peso", "code": "DOP"},
    {"name": "East Caribbean Dollar", "code": "XCD"},
    {"name": "Egyptian Pound", "code": "EGP"},
    {"name": "Eritrean Nakfa", "code": "ERN"},
    {"name": "Ethiopian Birr", "code": "ETB"},
    {"name": "Euro", "code": "EUR"},
    {"name": "Falkland Islands Pound", "code": "FKP"},
    {"name": "Fijian Dollar", "code": "FJD"},
    {"name": "Gambian Dalasi", "code": "GMD"},
    {"name": "Georgian Lari", "code": "GEL"},
    {"name": "Ghanaian Cedi", "code": "GHS"},
    {"name": "Gibraltar Pound", "code": "GIP"},
    {"name": "Guatemalan Quetzal", "code": "GTQ"},
    {"name": "Guernsey Pound", "code": "GGP"},
    {"name": "Guinean Franc", "code": "GNF"},
    {"name": "Guyanese Dollar", "code": "GYD"},
    {"name": "Haitian Gourde", "code": "HTG"},
    {"name": "Honduran Lempira", "code": "HNL"},
    {"name": "Hong Kong Dollar", "code": "HKD"},
    {"name": "Hungarian Forint", "code": "HUF"},
    {"name": "Icelandic Króna", "code": "ISK"},
    {"name": "Indian Rupee", "code": "INR"},
    {"name": "Indonesian Rupiah", "code": "IDR"},
    {"name": "Iranian Rial", "code": "IRR"},
    {"name": "Iraqi Dinar", "code": "IQD"},
    {"name": "Israeli New Shekel", "code": "ILS"},
    {"name": "Jamaican Dollar", "code": "JMD"},
    {"name": "Japanese Yen", "code": "JPY"},
    {"name": "Jersey Pound", "code": "JEP"},
    {"name": "Jordanian Dinar", "code": "JOD"},
    {"name": "Kazakhstani Tenge", "code": "KZT"},
    {"name": "Kenyan Shilling", "code": "KES"},
    {"name": "Kuwaiti Dinar", "code": "KWD"},
    {"name": "Kyrgyzstani Som", "code": "KGS"},
    {"name": "Lao Kip", "code": "LAK"},
    {"name": "Lebanese Pound", "code": "LBP"},
    {"name": "Lesotho Loti", "code": "LSL"},
    {"name": "Liberian Dollar", "code": "LRD"},
    {"name": "Libyan Dinar", "code": "LYD"},
    {"name": "Macanese Pataca", "code": "MOP"},
    {"name": "Macedonian Denar", "code": "MKD"},
    {"name": "Malagasy Ariary", "code": "MGA"},
    {"name": "Malawian Kwacha", "code": "MWK"},
    {"name": "Malaysian Ringgit", "code": "MYR"},
    {"name": "Maldivian Rufiyaa", "code": "MVR"},
    {"name": "Mauritanian Ouguiya", "code": "MRU"},
    {"name": "Mauritian Rupee", "code": "MUR"},
    {"name": "Mexican Peso", "code": "MXN"},
    {"name": "Moldovan Leu", "code": "MDL"},
    {"name": "Mongolian Tögrög", "code": "MNT"},
    {"name": "Moroccan Dirham", "code": "MAD"},
    {"name": "Mozambican Metical", "code": "MZN"},
    {"name": "Myanmar Kyat", "code": "MMK"},
    {"name": "Namibian Dollar", "code": "NAD"},
    {"name": "Nepalese Rupee", "code": "NPR"},
    {"name": "Netherlands Antillean Guilder", "code": "ANG"},
    {"name": "New Taiwan Dollar", "code": "TWD"},
    {"name": "New Zealand Dollar", "code": "NZD"},
    {"name": "Nicaraguan Córdoba", "code": "NIO"},
    {"name": "Nigerian Naira", "code": "NGN"},
    {"name": "North Korean Won", "code": "KPW"},
    {"name": "Norwegian Krone", "code": "NOK"},
    {"name": "Omani Rial", "code": "OMR"},
    {"name": "Pakistani Rupee", "code": "PKR"},
    {"name": "Panamanian Balboa", "code": "PAB"},
    {"name": "Papua New Guinean Kina", "code": "PGK"},
    {"name": "Paraguayan Guaraní", "code": "PYG"},
    {"name": "Peruvian Sol", "code": "PEN"},
    {"name": "Philippine Peso", "code": "PHP"},
    {"name": "Polish Złoty", "code": "PLN"},
    {"name": "Qatari Riyal", "code": "QAR"},
    {"name": "Romanian Leu", "code": "RON"},
    {"name": "Russian Ruble", "code": "RUB"},
    {"name": "Rwandan Franc", "code": "RWF"},
    {"name": "Saint Helena Pound", "code": "SHP"},
    {"name": "Salvadoran Colón", "code": "SVC"},
    {"name": "Samoan Tala", "code": "WST"},
    {"name": "São Tomé and Príncipe Dobra", "code": "STN"},
    {"name": "Saudi Riyal", "code": "SAR"},
    {"name": "Serbian Dinar", "code": "RSD"},
    {"name": "Seychellois Rupee", "code": "SCR"},
    {"name": "Sierra Leonean Leone", "code": "SLL"},
    {"name": "Singapore Dollar", "code": "SGD"},
    {"name": "Solomon Islands Dollar", "code": "SBD"},
    {"name": "Somali Shilling", "code": "SOS"},
    {"name": "South African Rand", "code": "ZAR"},
    {"name": "South Korean Won", "code": "KRW"},
    {"name": "South Sudanese Pound", "code": "SSP"},
    {"name": "Sri Lankan Rupee", "code": "LKR"},
    {"name": "Sudanese Pound", "code": "SDG"},
    {"name": "Surinamese Dollar", "code": "SRD"},
    {"name": "Swazi Lilangeni", "code": "SZL"},
    {"name": "Swedish Krona", "code": "SEK"},
    {"name": "Swiss Franc", "code": "CHF"},
    {"name": "Syrian Pound", "code": "SYP"},
    {"name": "Tajikistani Somoni", "code": "TJS"},
    {"name": "Tanzanian Shilling", "code": "TZS"},
    {"name": "Thai Baht", "code": "THB"},
    {"name": "Tongan Paʻanga", "code": "TOP"},
    {"name": "Trinidad and Tobago Dollar", "code": "TTD"},
    {"name": "Tunisian Dinar", "code": "TND"},
    {"name": "Turkish Lira", "code": "TRY"},
    {"name": "Turkmenistani Manat", "code": "TMT"},
    {"name": "Ugandan Shilling", "code": "UGX"},
    {"name": "Ukrainian Hryvnia", "code": "UAH"},
    {"name": "United Arab Emirates Dirham", "code": "AED"},
    {"name": "United States Dollar", "code": "USD"},
    {"name": "Uruguayan Peso", "code": "UYU"},
    {"name": "Uzbekistani Som", "code": "UZS"},
    {"name": "Vanuatu Vatu", "code": "VUV"},
    {"name": "Venezuelan Bolívar", "code": "VES"},
    {"name": "Vietnamese Đồng", "code": "VND"},
    {"name": "West African CFA Franc", "code": "XOF"},
    {"name": "Yemeni Rial", "code": "YER"},
    {"name": "Zambian Kwacha", "code": "ZMW"},
    {"name": "Zimbabwean Dollar", "code": "ZWL"}
  ]
