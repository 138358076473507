import BudgetManagement from "./components/BudgetManagement";
import ExpenseManagement from "./components/ExpenseManagement";
import ReportsAndAnalysis from "./components/ReportsAndAnalysis";

const expenseRoutes = [
  {
    path: "/facility/expense_management",
    element: <ExpenseManagement />,
  },
  {
    path: "/facility/expense_management/reports",
    element: <ReportsAndAnalysis />,
  },
  {
    path: "/facility/expense_management/budget",
    element: <BudgetManagement />,
  },
];

export default expenseRoutes;
