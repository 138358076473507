import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Layout from "../component/layout";
import { toastify } from "../../../utils/toast";
import { getItem } from "../../../utils/localStorage";
import { makeRequest2 } from "../../../utils/makeRequest";
import {
  create_move_out_handover,
  get_all_handovers,
  getUnitsForFacility,
  getCustomersURL,
  compare_handovers
} from "../../../utils/urls";

// Item comparison component for showing differences
const ItemComparisonTable = ({ moveInItems, currentItems, onUpdateItem }) => {
  const getConditionClass = (condition) => {
    switch (condition) {
      case 'Excellent':
      case 'Good':
        return 'bg-success';
      case 'Fair':
        return 'bg-warning';
      case 'Poor':
      case 'Damaged':
      case 'Non-functional':
        return 'bg-danger';
      default:
        return 'bg-secondary';
    }
  };

  const hasConditionChanged = (originalItem, currentItem) => {
    return originalItem.condition !== currentItem.condition;
  };

  const getConditionDifference = (originalCondition, currentCondition) => {
    const conditionValues = {
      'Excellent': 5,
      'Good': 4,
      'Fair': 3,
      'Poor': 2,
      'Damaged': 1,
      'Non-functional': 0
    };

    return (conditionValues[currentCondition] || 0) - (conditionValues[originalCondition] || 0);
  };

  const handleConditionChange = (index, newCondition) => {
    onUpdateItem(index, 'condition', newCondition);
  };

  const renderConditionSelector = (item, index) => {
    const originalItem = moveInItems.find(
      moveInItem => moveInItem.name === item.name && moveInItem.category === item.category
    ) || item;

    return (
      <div>
        <Dropdown
          value={item.condition}
          options={[
            { label: "Excellent", value: "Excellent" },
            { label: "Good", value: "Good" },
            { label: "Fair", value: "Fair" },
            { label: "Poor", value: "Poor" },
            { label: "Damaged", value: "Damaged" },
            { label: "Non-functional", value: "Non-functional" }
          ]}
          onChange={(e) => handleConditionChange(index, e.value)}
          className={hasConditionChanged(originalItem, item) ? 'p-inputtext-sm border-danger' : 'p-inputtext-sm'}
        />

        {hasConditionChanged(originalItem, item) && (
          <div className="mt-1">
            <small className={getConditionDifference(originalItem.condition, item.condition) < 0 ? 'text-danger' : 'text-success'}>
              Was: <span className={`badge ${getConditionClass(originalItem.condition)}`}>
                {originalItem.condition}
              </span>
            </small>
          </div>
        )}
      </div>
    );
  };

  const itemsToShow = currentItems.map(item => {
    const originalItem = moveInItems.find(
      moveInItem => moveInItem.name === item.name && moveInItem.category === item.category
    );

    return {
      ...item,
      isOriginal: !!originalItem,
      originalCondition: originalItem?.condition
    };
  });

  const missingItems = moveInItems.filter(originalItem =>
    !currentItems.some(
      item => item.name === originalItem.name && item.category === originalItem.category
    )
  );

  return (
    <div className="inventory-comparison mb-4">
      <h6>Current Inventory Items</h6>
      <DataTable
        value={itemsToShow}
        emptyMessage="No items added yet"
        className="mb-4"
        rowClassName={rowData => rowData.isOriginal ? '' : 'bg-light'}
      >
        <Column field="name" header="Name"></Column>
        <Column field="category" header="Category"></Column>
        <Column
          field="condition"
          header="Current Condition"
          body={(rowData, { rowIndex }) => renderConditionSelector(rowData, rowIndex)}
        ></Column>
        <Column field="quantity" header="Quantity"></Column>
        <Column
          field="isOriginal"
          header="Status"
          body={(rowData) =>
            rowData.isOriginal ?
              <span className="badge bg-info">From move-in</span> :
              <span className="badge bg-warning">Added at move-out</span>
          }
        ></Column>
        <Column
          body={(rowData, { rowIndex }) => (
            <Button
              icon="ti ti-trash"
              className="p-button-danger p-button-sm"
              onClick={() => onUpdateItem(rowIndex, 'delete')}
            />
          )}
          header="Actions"
          style={{ width: '100px' }}
        ></Column>
      </DataTable>

      {missingItems.length > 0 && (
        <>
          <h6 className="mt-4 text-danger">Missing Items (In move-in but not in move-out)</h6>
          <DataTable
            value={missingItems}
            className="mb-4"
            rowClassName="bg-danger-subtle"
          >
            <Column field="name" header="Name"></Column>
            <Column field="category" header="Category"></Column>
            <Column
              field="condition"
              header="Original Condition"
              body={(rowData) => (
                <span className={`badge ${getConditionClass(rowData.condition)}`}>
                  {rowData.condition}
                </span>
              )}
            ></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column
              body={(rowData) => (
                <Button
                  label="Add to Move-Out"
                  icon="ti ti-plus"
                  className="p-button-sm p-button-warning"
                  onClick={() => {
                    onUpdateItem(null, 'add', null, rowData);
                  }}
                />
              )}
              header="Actions"
            ></Column>
          </DataTable>
        </>
      )}
    </div>
  );
};

// Meter readings comparison component
const MeterReadingsComparison = ({
  meterReadings,
  onMeterReadingChange
}) => {
  const calculateUsage = (current, previous) => {
    const currentValue = parseFloat(current) || 0;
    const previousValue = parseFloat(previous) || 0;
    return Math.max(0, currentValue - previousValue).toFixed(2);
  };

  return (
    <div className="meter-readings-comparison">
      <h5 className="mt-4 mb-3">Meter Readings</h5>
      <div className="row mb-2 bg-light p-2">
        <div className="col-3"><strong>Utility</strong></div>
        <div className="col-3"><strong>Move-In Reading</strong></div>
        <div className="col-3"><strong>Move-Out Reading</strong></div>
        <div className="col-3"><strong>Usage</strong></div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-3">Electricity</div>
        <div className="col-3">
          <InputText
            className="w-100"
            value={meterReadings.electricity.previousReading}
            disabled
          />
        </div>
        <div className="col-3">
          <InputText
            className="w-100"
            type="number"
            value={meterReadings.electricity.reading}
            onChange={(e) => onMeterReadingChange('electricity', parseFloat(e.target.value) || 0)}
          />
        </div>
        <div className="col-3">
          <span className="badge bg-info">
            {calculateUsage(
              meterReadings.electricity.reading,
              meterReadings.electricity.previousReading
            )}
          </span>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-3">Water</div>
        <div className="col-3">
          <InputText
            className="w-100"
            value={meterReadings.water.previousReading}
            disabled
          />
        </div>
        <div className="col-3">
          <InputText
            className="w-100"
            type="number"
            value={meterReadings.water.reading}
            onChange={(e) => onMeterReadingChange('water', parseFloat(e.target.value) || 0)}
          />
        </div>
        <div className="col-3">
          <span className="badge bg-info">
            {calculateUsage(
              meterReadings.water.reading,
              meterReadings.water.previousReading
            )}
          </span>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-3">Gas</div>
        <div className="col-3">
          <InputText
            className="w-100"
            value={meterReadings.gas.previousReading}
            disabled
          />
        </div>
        <div className="col-3">
          <InputText
            className="w-100"
            type="number"
            value={meterReadings.gas.reading}
            onChange={(e) => onMeterReadingChange('gas', parseFloat(e.target.value) || 0)}
          />
        </div>
        <div className="col-3">
          <span className="badge bg-info">
            {calculateUsage(
              meterReadings.gas.reading,
              meterReadings.gas.previousReading
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

// Comparison summary component
const ComparisonSummary = ({ comparison, onApplySuggestedDeductions }) => {
  if (!comparison) return null;

  return (
    <div className="card mt-3 mb-4">
      <div className="card-header bg-light">
        <h5 className="card-title mb-0">Move-In/Move-Out Comparison</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <h6>Inventory Summary</h6>
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td>Total Move-In Items:</td>
                  <td><strong>{comparison.summary?.totalMoveInItems || 0}</strong></td>
                </tr>
                <tr>
                  <td>Missing Items:</td>
                  <td>
                    <span className={`badge ${comparison.missing?.length > 0 ? 'bg-danger' : 'bg-success'}`}>
                      {comparison.missing?.length || 0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Damaged Items:</td>
                  <td>
                    <span className={`badge ${comparison.damaged?.length > 0 ? 'bg-warning' : 'bg-success'}`}>
                      {comparison.damaged?.length || 0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Unchanged Items:</td>
                  <td>
                    <span className="badge bg-info">
                      {comparison.unchanged?.length || 0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>New Items:</td>
                  <td>
                    <span className="badge bg-primary">
                      {comparison.new?.length || 0}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-md-6">
            <h6>Utility Readings</h6>
            <table className="table table-sm">
              <tbody>
                {Object.entries(comparison.meterDifferences || {}).map(([utility, data]) => (
                  <tr key={utility}>
                    <td className="text-capitalize">{utility}:</td>
                    <td>
                      {data.moveInReading} → {data.moveOutReading}
                      {data.difference !== null && (
                        <span className={`ms-2 badge ${data.difference > 0 ? 'bg-info' : 'bg-success'}`}>
                          {data.difference > 0 ? '+' : ''}{data.difference.toFixed(2)}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>Keys:</td>
                  <td>
                    {comparison.keysDifference?.moveInKeys || 0} → {comparison.keysDifference?.moveOutKeys || 0}
                    {comparison.keysDifference?.difference !== undefined && (
                      <span className={`ms-2 badge ${comparison.keysDifference.difference < 0 ? 'bg-danger' : 'bg-success'}`}>
                        {comparison.keysDifference.difference > 0 ? '+' : ''}{comparison.keysDifference.difference}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {comparison.potentialDeductions > 0 && (
          <div className="alert alert-warning mt-3">
            <strong>Potential Deductions:</strong> ${comparison.potentialDeductions.toFixed(2)}
            <p className="mb-0 small">Based on {comparison.missing?.length || 0} missing and {comparison.damaged?.length || 0} damaged items</p>
            <Button
              label="Apply Suggested Deductions"
              className="p-button-sm p-button-warning mt-2"
              onClick={() => onApplySuggestedDeductions(comparison)}
            />
          </div>
        )}

        {comparison.duration?.days !== null && (
          <div className="text-muted mt-3">
            <small>Duration: {comparison.duration.days} days (from {new Date(comparison.duration.moveInDate).toLocaleDateString()} to {new Date(comparison.duration.moveOutDate).toLocaleDateString()})</small>
          </div>
        )}
      </div>
    </div>
  );
};

// Main component
const CreateMoveOutHandover = ({ isDialog = false, onSuccess }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [units, setUnits] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [moveInHandovers, setMoveInHandovers] = useState([]);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const [showDeductionDialog, setShowDeductionDialog] = useState(false);
  const [facilityId, setFacilityId] = useState("");
  const [selectedMoveInItems, setSelectedMoveInItems] = useState([]);
  const [isLoadingMoveIn, setIsLoadingMoveIn] = useState(false);
  const [comparisonData, setComparisonData] = useState(null);

  // New item form state
  const [newItem, setNewItem] = useState({
    name: "",
    category: "",
    description: "",
    condition: "Good",
    quantity: 1,
    serialNumber: "",
    notes: ""
  });

  // Deduction form state
  const [newDeduction, setNewDeduction] = useState({
    reason: "",
    amount: 0,
    description: ""
  });

  const [handoverData, setHandoverData] = useState({
    unitId: "",
    customerId: "",
    relatedHandoverId: "",
    handoverDate: new Date(),
    items: [],
    meterReadings: {
      electricity: { reading: 0, previousReading: 0, imageUrl: "" },
      water: { reading: 0, previousReading: 0, imageUrl: "" },
      gas: { reading: 0, previousReading: 0, imageUrl: "" }
    },
    keysHandedOver: 0,
    securityDeposit: {
      amount: 0,
      deductions: [],
      refundAmount: 0
    },
    notes: "",
    attachments: [],
    signatures: {
      propertyManager: { signature: "", date: null },
      customer: { signature: "", date: null, agreement: false }
    },
    status: "Draft"
  });

  const categoryOptions = [
    { label: "Furniture", value: "Furniture" },
    { label: "Appliance", value: "Appliance" },
    { label: "Electronics", value: "Electronics" },
    { label: "Fixture", value: "Fixture" },
    { label: "Utility", value: "Utility" },
    { label: "Other", value: "Other" }
  ];

  const conditionOptions = [
    { label: "Excellent", value: "Excellent" },
    { label: "Good", value: "Good" },
    { label: "Fair", value: "Fair" },
    { label: "Poor", value: "Poor" },
    { label: "Damaged", value: "Damaged" },
    { label: "Non-functional", value: "Non-functional" }
  ];

  const statusOptions = [
    { label: "Draft", value: "Draft" },
    { label: "Completed", value: "Completed" }
  ];

  // Load initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const facilityIdValue = await getItem("selectedFacilityId");
        setFacilityId(facilityIdValue);

        if (!facilityIdValue) {
          toastify("Facility ID not found", "error");
          return;
        }

        // Fetch units
        const unitsResponse = await makeRequest2(
          `${getUnitsForFacility}/${facilityIdValue}`,
          "GET"
        );

        if (unitsResponse.success) {
          // Filter units to only include those with customers assigned
          const unitsWithCustomers = unitsResponse.data.filter(
            unit => unit.homeOwnerId || unit.tenantId
          );
          setUnits(unitsWithCustomers);
        }

        // Fetch customers
        const customersResponse = await makeRequest2(
          `${getCustomersURL}/${facilityIdValue}`,
          "GET"
        );

        if (customersResponse.success) {
          setCustomers(customersResponse.data);
        }

        // Fetch move-in handovers
        const params = new URLSearchParams();
        params.append('handoverType', 'MoveIn');
        params.append('status', 'Completed');

        const handoversResponse = await makeRequest2(
          `${get_all_handovers}/${facilityIdValue}?${params.toString()}`,
          "GET"
        );

        if (handoversResponse.success && handoversResponse.data) {
          // Handle different possible response formats
          let handoversList = [];

          if (Array.isArray(handoversResponse.data)) {
            handoversList = handoversResponse.data;
          } else if (handoversResponse.data.handovers && Array.isArray(handoversResponse.data.handovers)) {
            handoversList = handoversResponse.data.handovers;
          } else if (typeof handoversResponse.data === 'object') {
            // Try to find arrays within the response
            Object.keys(handoversResponse.data).forEach(key => {
              if (Array.isArray(handoversResponse.data[key])) {
                handoversList = handoversResponse.data[key];
              }
            });
          }

          setMoveInHandovers(handoversList);
          console.log(`Loaded ${handoversList.length} move-in handovers`);
        }
      } catch (error) {
        toastify("Error loading data", "error");
      }
    };

    fetchInitialData();
  }, []);

  // Handle comparison with move-in handover
  const fetchComparisonData = async (moveInHandoverId) => {
    if (!moveInHandoverId || !facilityId) return null;

    try {
      console.log('Fetching comparison data');

      // Create a temporary handover object for comparison
      const moveOutData = {
        facilityId,
        unitId: handoverData.unitId,
        customerId: handoverData.customerId,
        relatedHandoverId: moveInHandoverId,
        handoverType: 'MoveOut',
        handoverDate: handoverData.handoverDate,
        items: handoverData.items,
        meterReadings: handoverData.meterReadings,
        keysHandedOver: handoverData.keysHandedOver,
        status: 'Draft'
      };

      // Get comparison data
      const response = await makeRequest2(
        `${compare_handovers}/${facilityId}/${moveInHandoverId}`,
        "POST",
        moveOutData
      );

      if (response.success && response.data) {
        console.log('Comparison data received:', response.data);
        const comparison = response.data.comparison;
        setComparisonData(comparison);

        return comparison;
      } else {
        console.error('Error from comparison endpoint:', response.error);
        return null;
      }
    } catch (error) {
      console.error("Error comparing handovers:", error);
      return null;
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHandoverData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle meter reading changes
  const handleMeterReadingChange = async (utility, value) => {
    setHandoverData(prev => ({
      ...prev,
      meterReadings: {
        ...prev.meterReadings,
        [utility]: {
          ...prev.meterReadings[utility],
          reading: value
        }
      }
    }));

    // Update comparison when meter readings change
    if (handoverData.relatedHandoverId) {
      await fetchComparisonData(handoverData.relatedHandoverId);
    }
  };

  // Handle security deposit changes
  const handleSecurityDepositChange = (value) => {
    const depositAmount = parseFloat(value) || 0;
    const totalDeductions = handoverData.securityDeposit.deductions.reduce(
      (sum, deduction) => sum + (parseFloat(deduction.amount) || 0), 0
    );

    setHandoverData(prev => ({
      ...prev,
      securityDeposit: {
        ...prev.securityDeposit,
        amount: depositAmount,
        refundAmount: depositAmount - totalDeductions
      }
    }));
  };

  // Handle new item form changes
  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle new deduction form changes
  const handleNewDeductionChange = (e) => {
    const { name, value } = e.target;
    setNewDeduction(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle unit selection
  const handleUnitSelect = (unitId) => {
    const selectedUnit = units.find(unit => unit._id === unitId);
    setHandoverData(prev => ({
      ...prev,
      unitId: unitId,
      // If unit has a tenant, prefer them over homeowner
      customerId: selectedUnit?.tenantId || selectedUnit?.homeOwnerId || ""
    }));

    // Filter move-in handovers for this unit
    const unitMoveInHandovers = moveInHandovers.filter(
      handover => handover.unitId?._id === unitId
    );

    // If there's only one move-in handover for this unit, select it automatically
    if (unitMoveInHandovers.length === 1) {
      handleMoveInHandoverSelect(unitMoveInHandovers[0]._id);
    }
  };

  // Handle move-in handover selection
  const handleMoveInHandoverSelect = async (handoverId) => {
    try {
      setIsLoadingMoveIn(true);
      setHandoverData(prev => ({
        ...prev,
        relatedHandoverId: handoverId
      }));

      // Find the selected move-in handover
      const selectedHandover = moveInHandovers.find(h => h._id === handoverId);

      if (selectedHandover) {
        console.log("Selected move-in handover:", selectedHandover);

        // Calculate initial deposit amount and refund amount (full refund by default)
        const depositAmount = 500; // This would typically be set based on policy or the original deposit amount

        // Set all data from the move-in handover
        setHandoverData(prev => ({
          ...prev,
          unitId: selectedHandover.unitId?._id || prev.unitId,
          customerId: selectedHandover.customerId?._id || prev.customerId,
          // Copy move-in handover date as a reference
          moveInDate: new Date(selectedHandover.handoverDate),
          // Copy all items from move-in with the same condition initially
          items: selectedHandover.items ? JSON.parse(JSON.stringify(selectedHandover.items)) : [],
          // Copy meter readings as a starting point for comparison
          meterReadings: {
            electricity: {
              reading: selectedHandover.meterReadings?.electricity?.reading || 0,
              previousReading: selectedHandover.meterReadings?.electricity?.reading || 0,
              imageUrl: ""
            },
            water: {
              reading: selectedHandover.meterReadings?.water?.reading || 0,
              previousReading: selectedHandover.meterReadings?.water?.reading || 0,
              imageUrl: ""
            },
            gas: {
              reading: selectedHandover.meterReadings?.gas?.reading || 0,
              previousReading: selectedHandover.meterReadings?.gas?.reading || 0,
              imageUrl: ""
            }
          },
          // Copy the number of keys handed over initially
          keysHandedOver: selectedHandover.keysHandedOver || 0,
          // Initialize security deposit
          securityDeposit: {
            amount: depositAmount,
            deductions: [],
            refundAmount: depositAmount
          },
          // Copy any notes from the move-in handover for reference
          notes: `Move-out handover based on move-in from ${new Date(selectedHandover.handoverDate).toLocaleDateString()}.\n\nOriginal move-in notes: ${selectedHandover.notes || 'None'}\n\nMove-out notes: `,
        }));

        // Store the original move-in items for reference and comparison
        setSelectedMoveInItems(selectedHandover.items ? JSON.parse(JSON.stringify(selectedHandover.items)) : []);

        // Fetch comparison data
        const comparison = await fetchComparisonData(handoverId);

        toastify("Move-in handover data loaded successfully", "success");
      } else {
        toastify("Move-in handover not found", "error");
      }
    } catch (error) {
      console.error("Error loading move-in handover details:", error);
      toastify("Error loading move-in handover details", "error");
    } finally {
      setIsLoadingMoveIn(false);
    }
  };

  // Handle adding an item
  const handleAddItem = async () => {
    if (!newItem.name || !newItem.category) {
      toastify("Item name and category are required", "error");
      return;
    }

    setHandoverData(prev => ({
      ...prev,
      items: [...prev.items, { ...newItem }]
    }));

    setNewItem({
      name: "",
      category: "",
      description: "",
      condition: "Good",
      quantity: 1,
      serialNumber: "",
      notes: ""
    });

    setShowAddItemDialog(false);

    // Update comparison
    if (handoverData.relatedHandoverId) {
      await fetchComparisonData(handoverData.relatedHandoverId);
    }
  };

  // Handle adding a deduction
  const handleAddDeduction = () => {
    if (!newDeduction.reason) {
      toastify("Deduction reason is required", "error");
      return;
    }

    if (newDeduction.amount <= 0) {
      toastify("Deduction amount must be greater than zero", "error");
      return;
    }

    const newDeductions = [...handoverData.securityDeposit.deductions, { ...newDeduction }];
    const totalDeductions = newDeductions.reduce((sum, d) => sum + (parseFloat(d.amount) || 0), 0);
    const refundAmount = (parseFloat(handoverData.securityDeposit.amount) || 0) - totalDeductions;

    setHandoverData(prev => ({
      ...prev,
      securityDeposit: {
        ...prev.securityDeposit,
        deductions: newDeductions,
        refundAmount: refundAmount >= 0 ? refundAmount : 0
      }
    }));

    setNewDeduction({
      reason: "",
      amount: 0,
      description: ""
    });

    setShowDeductionDialog(false);
  };

  // Handle updating an item
  const handleUpdateItem = async (index, action, value, itemToAdd) => {
    let updatedItems = [...handoverData.items];

    if (action === 'delete' && index !== null) {
      // Remove the item
      updatedItems = updatedItems.filter((_, i) => i !== index);
    } else if (action === 'add' && itemToAdd) {
      // Add a missing item back to the list
      updatedItems.push(itemToAdd);
    } else if (index !== null && action === 'condition' && value) {
      // Update an item's condition
      updatedItems[index] = {
        ...updatedItems[index],
        condition: value
      };
    }

    setHandoverData(prev => ({
      ...prev,
      items: updatedItems
    }));

    // Update comparison
    if (handoverData.relatedHandoverId) {
      await fetchComparisonData(handoverData.relatedHandoverId);
    }
  };

  // Handle applying suggested deductions
  const handleApplySuggestedDeductions = (comparison) => {
    if (!comparison) return;

    const suggestedDeductions = [];

    // Add deductions for missing items
    if (comparison.missing && comparison.missing.length > 0) {
      comparison.missing.forEach(item => {
        suggestedDeductions.push({
          reason: `Missing item: ${item.name}`,
          amount: 50 * (item.quantity || 1), // Simple calculation
          description: `Item from move-in inventory not found at move-out (${item.category})`
        });
      });
    }

    // Add deductions for damaged items
    if (comparison.damaged && comparison.damaged.length > 0) {
      comparison.damaged.forEach(damaged => {
        const severityChange = Math.abs(damaged.severityChange || 1);
        suggestedDeductions.push({
          reason: `Damaged item: ${damaged.item.name}`,
          amount: 25 * severityChange * (damaged.item.quantity || 1), // Simple calculation
          description: `Condition changed from ${damaged.originalCondition} to ${damaged.item.condition}`
        });
      });
    }

    if (suggestedDeductions.length > 0) {
      // Calculate new refund amount
      const totalNewDeductions = suggestedDeductions.reduce((sum, d) => sum + (parseFloat(d.amount) || 0), 0);
      const existingDeductions = handoverData.securityDeposit.deductions.reduce((sum, d) => sum + (parseFloat(d.amount) || 0), 0);
      const totalDeductions = totalNewDeductions + existingDeductions;
      const refundAmount = Math.max(0, (parseFloat(handoverData.securityDeposit.amount) || 0) - totalDeductions);

      setHandoverData(prev => ({
        ...prev,
        securityDeposit: {
          ...prev.securityDeposit,
          deductions: [...prev.securityDeposit.deductions, ...suggestedDeductions],
          refundAmount: refundAmount
        }
      }));

      toastify(`Applied ${suggestedDeductions.length} suggested deductions`, "success");
    } else {
      toastify("No deductions to apply", "info");
    }
  };

  // Handle removing an item
  const handleRemoveItem = async (index) => {
    setHandoverData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));

    // Update comparison
    if (handoverData.relatedHandoverId) {
      await fetchComparisonData(handoverData.relatedHandoverId);
    }
  };

  // Handle removing a deduction
  const handleRemoveDeduction = (index) => {
    const newDeductions = handoverData.securityDeposit.deductions.filter((_, i) => i !== index);
    const totalDeductions = newDeductions.reduce((sum, d) => sum + (parseFloat(d.amount) || 0), 0);
    const refundAmount = (parseFloat(handoverData.securityDeposit.amount) || 0) - totalDeductions;

    setHandoverData(prev => ({
      ...prev,
      securityDeposit: {
        ...prev.securityDeposit,
        deductions: newDeductions,
        refundAmount: refundAmount >= 0 ? refundAmount : 0
      }
    }));
  };

  // Handle file upload
  const handleFileUpload = (event) => {
    const files = event.files;
    const newAttachments = Array.from(files).map(file => ({
      name: file.name,
      fileUrl: URL.createObjectURL(file),
      file: file
    }));

    setHandoverData(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...newAttachments]
    }));

    // Clear the FileUpload component
    event.options.clear();
  };

  // Handle removing an attachment
  const handleRemoveAttachment = (index) => {
    setHandoverData(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  // Handle form reset
  const handleClear = () => {
    setHandoverData({
      unitId: "",
      customerId: "",
      relatedHandoverId: "",
      handoverDate: new Date(),
      items: [],
      meterReadings: {
        electricity: { reading: 0, previousReading: 0, imageUrl: "" },
        water: { reading: 0, previousReading: 0, imageUrl: "" },
        gas: { reading: 0, previousReading: 0, imageUrl: "" }
      },
      keysHandedOver: 0,
      securityDeposit: {
        amount: 0,
        deductions: [],
        refundAmount: 0
      },
      notes: "",
      attachments: [],
      signatures: {
        propertyManager: { signature: "", date: null },
        customer: { signature: "", date: null, agreement: false }
      },
      status: "Draft"
    });
    setSelectedMoveInItems([]);
    setComparisonData(null);
  };

  // Form validation
  const validateForm = () => {
    if (!handoverData.unitId) {
      toastify("Please select a unit", "error");
      return false;
    }

    if (!handoverData.customerId) {
      toastify("Please select a customer", "error");
      return false;
    }

    if (!handoverData.relatedHandoverId) {
      toastify("Please select the related move-in handover", "error");
      return false;
    }

    if (!handoverData.handoverDate) {
      toastify("Handover date is required", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm() || isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      if (!facilityId) {
        toastify("Facility ID not found", "error");
        return;
      }

      // Format the data for submission
      const formData = {
        ...handoverData,
        handoverDate: handoverData.handoverDate.toISOString()
      };

      // Remove previousReading from meter readings as the backend doesn't expect it
      if (formData.meterReadings) {
        Object.keys(formData.meterReadings).forEach(key => {
          if (formData.meterReadings[key] && 'previousReading' in formData.meterReadings[key]) {
            const { previousReading, ...rest } = formData.meterReadings[key];
            formData.meterReadings[key] = rest;
          }
        });
      }

      // Remove any other temporary properties we added for UI purposes
      if ('moveInDate' in formData) {
        delete formData.moveInDate;
      }

      console.log('Submitting handover data:', formData);

      const response = await makeRequest2(
        `${create_move_out_handover}/${facilityId}`,
        "POST",
        formData
      );

      if (response.success) {
        toastify("Move-out handover created successfully", "success");

        if (isDialog) {
          onSuccess?.();
        } else {
          // Use the correct navigation path for your app
          navigate("/facility/handover_management/handovers", {
            state: { activeTab: 'moveOut' },
            replace: true
          });
        }
      } else {
        toastify(response.error || "Failed to create move-out handover", "error");
      }
    } catch (error) {
      toastify(error.message || "An unexpected error occurred", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render add item dialog
  const renderAddItemDialog = () => {
    return (
      <Dialog
        header="Add Inventory Item"
        visible={showAddItemDialog}
        style={{ width: '50vw' }}
        onHide={() => setShowAddItemDialog(false)}
        footer={
          <>
            <Button
              label="Cancel"
              icon="ti ti-times"
              className="p-button-text"
              onClick={() => setShowAddItemDialog(false)}
            />
            <Button
              label="Add Item"
              icon="ti ti-check"
              onClick={handleAddItem}
            />
          </>
        }
      >
        <div className="p-fluid">
          <div className="row">
            <div className="col-md-6 field mb-3">
              <label htmlFor="name">Item Name*</label>
              <InputText
                id="name"
                name="name"
                value={newItem.name}
                onChange={handleNewItemChange}
                required
              />
            </div>
            <div className="col-md-6 field mb-3">
              <label htmlFor="category">Category*</label>
              <Dropdown
                id="category"
                name="category"
                value={newItem.category}
                options={categoryOptions}
                onChange={handleNewItemChange}
                placeholder="Select a category"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 field mb-3">
              <label htmlFor="condition">Condition</label>
              <Dropdown
                id="condition"
                name="condition"
                value={newItem.condition}
                options={conditionOptions}
                onChange={handleNewItemChange}
              />
            </div>
            <div className="col-md-6 field mb-3">
              <label htmlFor="quantity">Quantity</label>
              <InputText
                id="quantity"
                name="quantity"
                type="number"
                value={newItem.quantity}
                onChange={handleNewItemChange}
                min="1"
              />
            </div>
          </div>
          <div className="field mb-3">
            <label htmlFor="serialNumber">Serial Number (if applicable)</label>
            <InputText
              id="serialNumber"
              name="serialNumber"
              value={newItem.serialNumber}
              onChange={handleNewItemChange}
            />
          </div>
          <div className="field mb-3">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              name="description"
              value={newItem.description}
              onChange={handleNewItemChange}
              rows={3}
            />
          </div>
          <div className="field mb-3">
            <label htmlFor="notes">Notes</label>
            <InputTextarea
              id="notes"
              name="notes"
              value={newItem.notes}
              onChange={handleNewItemChange}
              rows={2}
            />
          </div>
        </div>
      </Dialog>
    );
  };

  // Render add deduction dialog
  const renderAddDeductionDialog = () => {
    return (
      <Dialog
        header="Add Security Deposit Deduction"
        visible={showDeductionDialog}
        style={{ width: '40vw' }}
        onHide={() => setShowDeductionDialog(false)}
        footer={
          <>
            <Button
              label="Cancel"
              icon="ti ti-times"
              className="p-button-text"
              onClick={() => setShowDeductionDialog(false)}
            />
            <Button
              label="Add Deduction"
              icon="ti ti-check"
              onClick={handleAddDeduction}
            />
          </>
        }
      >
        <div className="p-fluid">
          <div className="field mb-3">
            <label htmlFor="reason">Reason*</label>
            <InputText
              id="reason"
              name="reason"
              value={newDeduction.reason}
              onChange={handleNewDeductionChange}
              required
            />
          </div>
          <div className="field mb-3">
            <label htmlFor="amount">Amount*</label>
            <InputNumber
              id="amount"
              name="amount"
              value={newDeduction.amount}
              onValueChange={(e) => handleNewDeductionChange({
                target: { name: 'amount', value: e.value }
              })}
              mode="currency"
              currency="USD"
              min={0}
              required
            />
          </div>
          <div className="field mb-3">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              name="description"
              value={newDeduction.description}
              onChange={handleNewDeductionChange}
              rows={3}
            />
          </div>
        </div>
      </Dialog>
    );
  };

  // The main form content
  const content = (
    <div className="card-body">
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Unit*</label>
          <Dropdown
            className="w-100"
            value={handoverData.unitId}
            options={units.map(unit => ({
              label: `${unit.name} (${unit.floorUnitNo})`,
              value: unit._id
            }))}
            onChange={(e) => handleUnitSelect(e.value)}
            placeholder="Select a unit"
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">Move-In Handover*</label>
          <Dropdown
            className="w-100"
            value={handoverData.relatedHandoverId}
            options={moveInHandovers
              .filter(h => !handoverData.unitId || h.unitId?._id === handoverData.unitId)
              .map(handover => ({
                label: `${handover.unitId?.name || 'Unknown'} - ${new Date(handover.handoverDate).toLocaleDateString()}`,
                value: handover._id
              }))}
            onChange={(e) => handleMoveInHandoverSelect(e.value)}
            placeholder="Select a move-in handover"
            disabled={!handoverData.unitId}
            loading={isLoadingMoveIn}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">Customer*</label>
          <Dropdown
            className="w-100"
            value={handoverData.customerId}
            options={customers.map(customer => ({
              label: `${customer.firstName} ${customer.lastName}`,
              value: customer._id
            }))}
            onChange={(e) => handleInputChange({
              target: { name: 'customerId', value: e.value }
            })}
            placeholder="Select a customer"
            disabled={!handoverData.unitId}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">Handover Date*</label>
          <Calendar
            className="w-100"
            value={handoverData.handoverDate}
            onChange={(e) => handleInputChange({
              target: { name: 'handoverDate', value: e.value }
            })}
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">Number of Keys Returned</label>
          <InputText
            className="w-100"
            type="number"
            value={handoverData.keysHandedOver}
            onChange={(e) => handleInputChange({
              target: { name: 'keysHandedOver', value: parseInt(e.target.value) || 0 }
            })}
            min="0"
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">Status</label>
          <Dropdown
            className="w-100"
            value={handoverData.status}
            options={statusOptions}
            onChange={(e) => handleInputChange({
              target: { name: 'status', value: e.value }
            })}
          />
        </div>
      </div>

      {/* Comparison summary if move-in handover is selected */}
      {handoverData.relatedHandoverId && comparisonData && (
        <ComparisonSummary
          comparison={comparisonData}
          onApplySuggestedDeductions={handleApplySuggestedDeductions}
        />
      )}

      {/* Meter readings comparison */}
      {handoverData.relatedHandoverId && (
        <MeterReadingsComparison
          meterReadings={handoverData.meterReadings}
          onMeterReadingChange={handleMeterReadingChange}
        />
      )}

      <h5 className="mt-4 mb-3">Security Deposit</h5>
      <div className="row">
        <div className="col-md-4 mb-3">
          <label className="form-label">Deposit Amount</label>
          <InputNumber
            className="w-100"
            value={handoverData.securityDeposit.amount}
            onValueChange={(e) => handleSecurityDepositChange(e.value)}
            mode="currency"
            currency="USD"
            min={0}
          />
        </div>
        <div className="col-md-4 mb-3">
          <label className="form-label">Total Deductions</label>
          <InputNumber
            className="w-100"
            value={handoverData.securityDeposit.deductions.reduce(
              (total, deduction) => total + (parseFloat(deduction.amount) || 0),
              0
            )}
            disabled
            mode="currency"
            currency="USD"
          />
        </div>
        <div className="col-md-4 mb-3">
          <label className="form-label">Refund Amount</label>
          <InputNumber
            className="w-100"
            value={handoverData.securityDeposit.refundAmount}
            disabled
            mode="currency"
            currency="USD"
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
        <h6 className="mb-0">Deductions</h6>
        <Button
          label="Add Deduction"
          icon="ti ti-plus"
          onClick={() => setShowDeductionDialog(true)}
        />
      </div>

      <DataTable
        value={handoverData.securityDeposit.deductions}
        emptyMessage="No deductions added yet"
        className="mb-4"
      >
        <Column field="reason" header="Reason"></Column>
        <Column
          field="amount"
          header="Amount"
          body={(rowData) => `$${parseFloat(rowData.amount).toFixed(2)}`}
        ></Column>
        <Column field="description" header="Description"></Column>
        <Column
          body={(rowData, { rowIndex }) => (
            <Button
              icon="ti ti-trash"
              className="p-button-danger p-button-sm"
              onClick={() => handleRemoveDeduction(rowIndex)}
            />
          )}
          header="Actions"
          style={{ width: '100px' }}
        ></Column>
      </DataTable>

      {/* Item comparison if move-in handover is selected */}
      {handoverData.relatedHandoverId ? (
        <div>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h5 className="mb-0">Inventory Comparison</h5>
            <Button
              label="Add New Item"
              icon="ti ti-plus"
              onClick={() => setShowAddItemDialog(true)}
            />
          </div>

          <ItemComparisonTable
            moveInItems={selectedMoveInItems}
            currentItems={handoverData.items}
            onUpdateItem={handleUpdateItem}
          />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h5 className="mb-0">Inventory Items</h5>
            <Button
              label="Add Item"
              icon="ti ti-plus"
              onClick={() => setShowAddItemDialog(true)}
            />
          </div>

          <DataTable
            value={handoverData.items}
            emptyMessage="No items added yet"
            className="mb-4"
          >
            <Column field="name" header="Name"></Column>
            <Column field="category" header="Category"></Column>
            <Column
              field="condition"
              header="Condition"
              body={(rowData) => (
                <span className={`badge ${rowData.condition === 'Excellent' || rowData.condition === 'Good' ? 'bg-success' :
                    rowData.condition === 'Fair' ? 'bg-warning' : 'bg-danger'
                  }`}>
                  {rowData.condition}
                </span>
              )}
            ></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column
              body={(rowData, { rowIndex }) => (
                <Button
                  icon="ti ti-trash"
                  className="p-button-danger p-button-sm"
                  onClick={() => handleRemoveItem(rowIndex)}
                />
              )}
              header="Actions"
              style={{ width: '100px' }}
            ></Column>
          </DataTable>
        </div>
      )}

      <h5 className="mt-4 mb-3">Attachments</h5>
      <FileUpload
        mode="advanced"
        multiple
        accept="image/*,application/pdf"
        maxFileSize={5000000}
        customUpload={true}
        uploadHandler={handleFileUpload}
        emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
        className="mb-3"
      />

      {handoverData.attachments.length > 0 && (
        <div className="mb-4">
          <h6>Uploaded Files:</h6>
          <ul className="list-group">
            {handoverData.attachments.map((attachment, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                {attachment.name}
                <Button
                  icon="ti ti-times"
                  className="p-button-danger p-button-sm"
                  onClick={() => handleRemoveAttachment(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <h5 className="mt-4 mb-3">Notes</h5>
      <InputTextarea
        rows={5}
        className="w-100"
        value={handoverData.notes}
        onChange={(e) => handleInputChange({
          target: { name: 'notes', value: e.target.value }
        })}
        placeholder="Enter any additional notes about the handover"
      />

      <div className="text-end mt-4">
        <Button
          type="button"
          className="btn btn-outline-secondary me-2"
          onClick={handleClear}
          disabled={isSubmitting}
        >
          Clear
        </Button>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Creating..." : "Create Move-Out Handover"}
        </Button>
      </div>
    </div>
  );

  // If used as a dialog, only return the form content
  if (isDialog) {
    return (
      <>
        {content}
        {renderAddItemDialog()}
        {renderAddDeductionDialog()}
      </>
    );
  }

  // Full page layout
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/handover_management">
                    Handover Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Create Move-Out Handover</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Create Move-Out Handover</h5>
            </div>
            {content}
          </div>
        </div>
      </div>
      {renderAddItemDialog()}
      {renderAddDeductionDialog()}
    </Layout>
  );
};

export default CreateMoveOutHandover;