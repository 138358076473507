import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import Layout from '../../component/layout';
import { Button } from 'primereact/button';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { confirmManualDataURL, confirmQRDataURL, getEntriesAndExitsForFacility, handleAllowVisitorURL } from '../../../../utils/urls';
import { getItem } from '../../../../utils/localStorage';
import { getLocation } from '../../../../utils/getLocation';

function VerifyCode() {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false); // Controls whether scanning is active
    const [entries, setEntries] = useState([])
    const [entry, setEntry] = useState('');
    const [type, setType] = useState('');
    const [vehicle, setVehicle] = useState({});
    const [customer, setCustomer] = useState({});
    const [unitName, setUnitName] = useState('');
    const [visitLog, setVisitorLog] = useState({})
    const [member, setMember] = useState({})
    const [visitorDetailsVisible, setVisitorDetailsVisible] = useState(false)

    const [uniqueCode, setUniqueCode] = useState('');

    const handleManualVerification = async () => {
        try {
            if (uniqueCode === '') {
                throw new Error('Unique Code is required.');
            }
            if (entry.name === '') {
                throw new Error('Entry Point is required.');
            }
            // Get latitude and longitude from the browser's Geolocation API
            const { latitude: lat, longitude: long } = await getLocation();
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(confirmManualDataURL + '/' + value, 'POST', {
                uniqueCode: uniqueCode,
                entry: entry.name,
                lat: lat,
                long: long,
                entryPointId: entry._id
            });
            if (response.success) {
                toastify(response.data, 'success');
                setVisitorDetailsVisible(true)
                
                if (response.data.type === "Family Member") {
                    setMember(response.data.member)
                    setCustomer(response.data.customer)
                    setUnitName(response.data.unitName);
                }
                else if (response.data.type === "Staff") {
                    setMember(response.data.member)
                    setCustomer(response.data.customer)
                    setUnitName(response.data.unitName);
                }
                else if (response.data.type === "Vehicle") {
                    setVehicle(response.data.vehicleData.vehicles[0]);
                    setUnitName(response.data.unitName);
                    setCustomer(response.data.customer)
                }
                else if (response.data.type === "Resident") {
                    setCustomer(response.data.customer)
                    setUnitName(response.data.unitName)
                }
                else if (response.data.type === "Visitor") {
                    setVisitorLog(response.data.visitLog)
                }
                setType(response.data.type)
                setSuccess(true);
            } else {
                toastify(response.error, 'error');
                setError(true);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };
    const handleAllowVisitor = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(handleAllowVisitorURL + '/' + value, 'POST', {
                type, vehicle, customer, visitLog, member, entry: entry.name, unitName
            });
            if (response.success) {
                setVisitorDetailsVisible(false)
                setUniqueCode('');
                toastify('Access Granted Successfully', 'success');
                navigate('/facility/visitor_access_management/home');

            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error');
        }
    }
    const fetchEntriesAndExitsForFacility = async () => {
        try {

            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});
            if (response.success) {
                setEntries(response.data);
            } else {
                throw new Error('Failed to fetch entries and exits')
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const getCurrentAccessPoint = async () => {
        try {
            const value = await getItem('selectedEntryPoint');
            if (value) {
                let data;
                if (typeof value === 'string') {
                    try {
                        data = JSON.parse(value);
                    } catch (err) {
                        console.error("Error parsing selectedEntryPoint:", err.message);
                        return; // Exit if parsing fails
                    }
                } else {
                    data = value; // Already an object
                }
                setEntry(data);
            }
        } catch (err) {
            console.log(err.message);
        }
    };
    
    useEffect(() => {
        fetchEntriesAndExitsForFacility();
        getCurrentAccessPoint()

    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Verify Code</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Verify Code</h5>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">



                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            {/* <label>Entry Point<span style={{ color: 'red' }}>*</span></label>
                                                            <input className="form-control" value={entry} disabled></input>
                                                         */}
                                                            {/* <select
                                                                className="form-control mt-2"
                                                                value={entry}
                                                                onChange={(e) => setEntry(e.target.value)}
                                                            >
                                                                <option value=""> -- Select --</option>
                                                                {
                                                                    entries.map((entryItem) => (
                                                                        <option key={entryItem._id} value={entryItem.name}>{entryItem.name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>

                                                    {/* Manual Input Field for Customer Number */}
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            <label>Enter Code</label>
                                                            <input
                                                                type="number"
                                                                className="form-control mt-2"
                                                                value={uniqueCode}
                                                                onChange={(e) => setUniqueCode(e.target.value)}
                                                            />
                                                        </div>
                                                        <button className="btn btn-primary mt-2" onClick={handleManualVerification}>
                                                            Verify
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header={type} visible={visitorDetailsVisible} style={{ width: '50vw' }} onHide={() => { if (!visitorDetailsVisible) return; setVisitorDetailsVisible(false); }}>

                <div className="row">
                    {
                        type === 'Family Member' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Staff' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Vehicle' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Resident' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Visitor' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{visitLog.houseNumber}</strong></p>
                            </div>
                        </div>
                    }

                    <button className="btn btn-primary" onClick={handleAllowVisitor}>Allow Visit</button>

                </div>

            </Dialog>
        </Layout>
    );
}

export default VerifyCode;
