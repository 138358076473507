import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import ConfirmDialog from "../component/confirmDialog";
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage';
import { addLandlordURL, getLandlordURL, updateLandlordURL, deleteLandlordURL } from '../../../utils/urls';

const FacilityTab = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [landlord, setLandlord] = useState([]);
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [selectedLandlord, setSelectedLandlord] = useState(null);
    const [selectedLandlordId, setSelectedLandlordId] = useState(null);

    const [updateLandlordDialog, setUpdateLandlordDialog] = useState(false);


    const handleAddLandlord = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const userData = {
                fullName,
                phoneNumber,
                email
            }

            const response = await makeRequest2(addLandlordURL + '/' + facilityId, 'POST', userData);

            if (response.success) {
                toastify('Landlord added successfully', 'success');
                setFullName('');
                setEmail('');
                setPhoneNumber('');
                getLandlord();
            }
            else {
                throw new Error(response.error)
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }

    const getLandlord = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            console.log("URL", getLandlordURL);

            const response = await makeRequest2(getLandlordURL + '/' + facilityId, "GET", {});
            console.log("Response", response)

            if (response.success) {
                setLandlord(response.data.data);
                console.log("Landlord", response.data);
            } else {
                throw new Error(response.error)
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const handleEditClick = (landlord) => {
        setSelectedLandlord(landlord);
        setSelectedLandlordId(landlord._id);
        setFullName(landlord.fullName);
        setEmail(landlord.email);
        setPhoneNumber(landlord.phoneNumber);
        setUpdateLandlordDialog(true);
    };

    const handleUpdateLandlord = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(updateLandlordURL + '/' + facilityId + '/' + selectedLandlordId, 'POST', {
                fullName,
                email,
                phoneNumber
            })

            if (response.success) {
                toastify('Landlord updated successfully', 'success');
                setUpdateLandlordDialog(false)
                getLandlord();
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const handleDeleteClick = (landlord) => {
        setSelectedLandlord(landlord); // Store the landlord to be deleted
        setSelectedLandlordId(landlord._id);
        setConfirmDialogVisible(true); // Show the confirmation dialog
    };

    const confirmDelete = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const response = await makeRequest2(deleteLandlordURL + '/' + facilityId + '/' + selectedLandlordId, "POST", {});
            console.log("Response", response)
            if (response.success) {
                toastify('Landlord deleted successfully', 'success');
                setConfirmDialogVisible(false);
                getLandlord();
                handleClear();
            } else {
                throw new Error(response.error)
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }

    const handleClear = ()=>{
         setFullName('');
         setEmail('');
         setPhoneNumber('');
    }

    useEffect(() => {
        getLandlord();
    }, []);


    return (
        <div className="card">
            <div className="mb-4">
                {landlord.length > 0 ? (
                    landlord.map((item) => (
                        <div key={item._id} className="mb-3 border-0">
                            <div className="card-body">
                                <div className="row">
                                    {/* Left side with name and role */}
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
                                                style={{ width: "60px", height: "60px" }}
                                            >
                                                {item.fullName.charAt(0)}
                                            </div>
                                            <div className="ms-3">
                                                <h4 className="mb-0">{item.fullName}</h4>
                                                <span className="text-muted">{item.type}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Right side with contact details */}
                                    <div className="col-md-6">
                                        <div className="row g-2">
                                            {/* Email */}
                                            <div className="col-sm-6">
                                                <div className="bg-light rounded p-3">
                                                    <small className="text-muted d-block">Email</small>
                                                    <span className="fw-medium text-break">{item.email}</span>
                                                </div>
                                            </div>

                                            {/* Phone */}
                                            <div className="col-sm-6">
                                                <div className="bg-light rounded p-3">
                                                    <small className="text-muted d-block">Phone</small>
                                                    <span className="fw-medium">{item.phoneNumber}</span>
                                                </div>
                                            </div>

                                            {/* Action Buttons */}
                                            <div className="col-12 mt-3">
                                                <div className="d-flex justify-content-end gap-2">
                                                    <button className="btn btn-success"
                                                        onClick={() => handleEditClick(item)}
                                                    >Edit</button>
                                                    <button className="btn btn-primary"
                                                        onClick={() => handleDeleteClick(item)}
                                                    >Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    // Show the Add Landlord form if no landlord exists
                    <div className="card-body">
                        <h5>Add Landlord</h5>
                        <div className="form-group mt-3">
                            <label>Fullname</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Fullname"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mt-4 text-end">
                            <button type="submit" className="btn btn-primary" onClick={handleAddLandlord}>
                                Add Landlord
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onHide={() => setConfirmDialogVisible(false)}
                message={`Are you sure you want to delete this Landlord?`}
                onConfirm={confirmDelete}
            />

            <Dialog
                header="Update Landlord"
                visible={updateLandlordDialog}
                onHide={() => setUpdateLandlordDialog(false)}
            >
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Full Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>

                    <div className="col-12 mb-3">
                        <label className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="col-12 mb-3">
                        <label className="form-label">Phone Number</label>
                        <input
                            type="text"
                            className="form-control"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>

                    <div className="col-12 d-flex justify-content-end gap-2">
                        <button className="btn btn-secondary" onClick={() => setUpdateLandlordDialog(false)}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={handleUpdateLandlord}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </Dialog>

        </div>

    );
};

export default FacilityTab;