import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../component/layout";

function MeterTypes() {
  const navigate = useNavigate();

  const handleMeterClick = (type) => {
    if (type === "water") {
      navigate("/facility/utility_management/meter_management/water_meters");
    } else if (type === "power") {
      // For now, do nothing for power meters
      // Optionally, you could add an alert or another message here.
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Meters Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            {/* Water Meter Card */}
            <div className="col-md-6">
              <div className="pc-component">
                <div className="card mb-2 text-center" style={{ position: "relative" }}>
                  <span className="badge bg-light-primary">
                    <i className="ti ti-droplet f-50 mt-3"></i>
                  </span>
                  <div
                    className="card-body"
                    onClick={() => handleMeterClick("water")}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="card-title">Water Meters</h5>
                    <p className="card-text">
                      <span className="badge bg-light-primary">
                        Manage Water Meters
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Power Meter Card */}
            <div className="col-md-6">
              <div className="pc-component">
                <div className="card mb-2 text-center" style={{ position: "relative" }}>
                  {/* Red badge at the top right */}
                  <div style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    backgroundColor: "red",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "0 0 0 5px",
                    fontSize: "0.8rem"
                  }}>
                    Coming Soon
                  </div>
                  <span className="badge bg-light-primary">
                    <i className="ti ti-recharging f-50 mt-3"></i>
                  </span>
                  <div
                    className="card-body"
                    onClick={() => handleMeterClick("power")}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="card-title">Power Meters</h5>
                    <p className="card-text">
                      <span className="badge bg-light-primary">
                        Coming Soon
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MeterTypes;
