import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, InputText } from "primereact";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import {
  getLevies,
  getCustomersURL,
  getUnitsForFacility,
  addContract,
  getContracts
} from "../../../../utils/urls";

const AddMultipleContract = () => {
  const navigate = useNavigate();

  const [contractName, setContractName] = useState("");
  const [levy, setLevy] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [status, setStatus] = useState("Active");
  const [selectedLevy, setSelectedLevy] = useState(null);

  // States for multiple selection
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedCustomerUnits, setSelectedCustomerUnits] = useState({});
  const [customerUnits, setCustomerUnits] = useState({});

  // Data states
  const [levies, setLevies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);

  const [loading, setLoading] = useState(false);
  const [existingContracts, setExistingContracts] = useState([]);


  const fetchExistingContracts = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(`${getContracts}/${facilityId}`, "GET");
      if (response.success) {
        const contractsData = response.data?.contracts || response.data || [];
        setExistingContracts(contractsData);
      }
    } catch (error) {
      console.error("Error fetching existing contracts:", error);
      setExistingContracts([]);
    }
  };

  const getFacilitySettings = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      // Try to get facility default currency and payment method
      const response = await makeRequest2(
        `/api/facilities/${facilityId}/settings`,
        "GET",
        {}
      );

      if (response.success) {
        return {
          currencyId: response.data.defaultCurrencyId || facilityId,
          paymentMethodId: response.data.defaultPaymentMethodId || facilityId
        };
      }

      return {
        currencyId: facilityId,
        paymentMethodId: facilityId
      };
    } catch (error) {
      console.error("Error getting facility settings:", error);
      const facilityId = await getItem("selectedFacilityId");

      return {
        currencyId: facilityId,
        paymentMethodId: facilityId
      };
    }
  };

  const fetchLevies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getLevies}/${facilityId}`,
        "GET",
        {}
      );

      console.log("Levies Response:", response);

      if (response.success) {
        // Ensure we're working with an array
        let leviesData;

        if (Array.isArray(response.data)) {
          leviesData = response.data;
        } else if (Array.isArray(response.data?.data)) {
          leviesData = response.data.data;
        } else if (
          typeof response.data === "object" &&
          response.data !== null
        ) {
          leviesData = [response.data];
        } else {
          console.error("Unexpected data structure:", response.data);
          leviesData = [];
        }

        const processedLevies = leviesData.map((levy) => {
          return {
            _id: levy._id,
            levyName: levy.levyName || "Untitled Levy",
            amount: levy.amount || 0,
            levyType: {
              name: levy.levyType?.name || "N/A",
              _id: levy.levyType?._id || null,
            },
            description: levy.description || "",
            status: levy.status || "Active",
            currencyId: levy.currencyId || null,
            paymentMethodId: levy.paymentMethodId || null,
            collectionFrequency: levy.collectionFrequency || "Monthly",
            levyApplicant: levy.levyApplicant || "home owner" // Default to home owner if not specified
          };
        });

        console.log("Processed levies:", processedLevies);
        setLevies(processedLevies);
      } else {
        throw new Error(response.error || "Failed to fetch levies");
      }
    } catch (err) {
      console.error("Error in fetchLevies:", err);
      toastify(err.message || "Error fetching levies", "error");
      setLevies([]);
    }
  };

  // Fetch available customers for the facility
  const fetchCustomersForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      // Fetch both customers and units simultaneously
      const [customersResponse, unitsResponse] = await Promise.all([
        makeRequest2(`${getCustomersURL}/${facilityId}`, "GET", {}),
        makeRequest2(`${getUnitsForFacility}/${facilityId}`, "GET", {}),
      ]);

      if (customersResponse.success && unitsResponse.success) {
        // Normalize customer types to lowercase for better matching
        const normalizedCustomers = customersResponse.data.map(customer => ({
          ...customer,
          customerType: customer.customerType?.trim().toLowerCase() || ""
        }));

        // Filter units to include only those with either homeOwnerId or tenantId
        const validUnits = unitsResponse.data.filter(unit =>
          (unit.homeOwnerId && unit.homeOwnerId !== '') ||
          (unit.tenantId && unit.tenantId !== '')
        );

        // Create a map of units by customer IDs
        const unitsByCustomer = validUnits.reduce((acc, unit) => {
          // Check customer references in the unit (homeOwner, tenant)
          if (unit.homeOwnerId) {
            if (!acc[unit.homeOwnerId]) acc[unit.homeOwnerId] = [];
            acc[unit.homeOwnerId].push({ ...unit, customerRole: "homeOwner" });
          }
          if (unit.tenantId) {
            if (!acc[unit.tenantId]) acc[unit.tenantId] = [];
            acc[unit.tenantId].push({ ...unit, customerRole: "tenant" });
          }
          return acc;
        }, {});

        // Add unit information to customers
        const customersWithUnits = normalizedCustomers.map((customer) => ({
          ...customer,
          assignedUnits: unitsByCustomer[customer._id] || [],
        }));

        console.log("Fetched customers with units:", customersWithUnits);
        setCustomers(customersWithUnits);
        setUnits(validUnits); // Set filtered units
      } else {
        throw new Error(
          customersResponse.error ||
          unitsResponse.error ||
          "Failed to fetch customers or units."
        );
      }
    } catch (error) {
      console.error("Error in fetchCustomersForFacility:", error);
      toastify(error.message || "Error fetching customers and units", "error");
      setCustomers([]);
      setUnits([]);
    }
  };

  // Fetch available units for the facility
  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET",
        {}
      );

      if (response.success) {
        // Filter units to only include those with either homeOwnerId or tenantId
        const validUnits = response.data.filter(unit =>
          (unit.homeOwnerId && unit.homeOwnerId !== '') ||
          (unit.tenantId && unit.tenantId !== '')
        );

        setUnits(validUnits);
        console.log(`Filtered ${response.data.length - validUnits.length} units without customers`);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Helper function to determine the appropriate customer ID based on levy applicant
  const determineCustomerId = (unit, levyApplicant) => {
    if (!unit) return null;
    
    const levyApplicantLower = levyApplicant?.toLowerCase() || "home owner";
    
    // For tenant-targeted levies
    if (levyApplicantLower === "tenant" || levyApplicantLower === "tenants") {
      // Prefer tenant if available
      if (unit.tenantId) {
        return unit.tenantId;
      }
      // Fall back to homeowner if tenant not available (with warning in logs)
      if (unit.homeOwnerId) {
        console.warn(`No tenant found for unit ${unit.name}, using homeowner as fallback`);
        return unit.homeOwnerId;
      }
    } 
    // For homeowner-targeted levies (default case)
    else {
      // Prefer homeowner if available
      if (unit.homeOwnerId) {
        return unit.homeOwnerId;
      }
      // Fall back to tenant if homeowner not available (with warning in logs)
      if (unit.tenantId) {
        console.warn(`No homeowner found for unit ${unit.name}, using tenant as fallback`);
        return unit.tenantId;
      }
    }
    
    // No valid customer found
    return null;
  };

  const handleContractSubmit = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
  
      // Basic validation
      if (!contractName?.trim()) {
        toastify("Contract name is required", "error");
        setLoading(false);
        return;
      }
      if (!levy) {
        toastify("Please select a levy", "error");
        setLoading(false);
        return;
      }
      if (selectedUnits.length === 0) {
        toastify("Please select at least one unit", "error");
        setLoading(false);
        return;
      }
      if (!amount || isNaN(parseFloat(amount))) {
        toastify("Valid amount is required", "error");
        setLoading(false);
        return;
      }
      if (!startDate || !endDate) {
        toastify("Start and end dates are required", "error");
        setLoading(false);
        return;
      }
      if (new Date(startDate) > new Date(endDate)) {
        toastify("Start date cannot be after end date", "error");
        setLoading(false);
        return;
      }
      if (!paymentFrequency) {
        toastify("Payment frequency is required", "error");
        setLoading(false);
        return;
      }
  
      // Get selected levy details
      if (!selectedLevy) {
        toastify("Selected levy not found", "error");
        setLoading(false);
        return;
      }
  
      // Get facility settings for fallback values
      const settings = await getFacilitySettings();
  
      // Create contract requests
      const contractRequests = [];
      const skippedUnits = [];
      const customerMissingUnits = [];
  
      // Log the levy applicant we're going to use
      console.log(`Creating contracts with levy applicant: ${selectedLevy.levyApplicant || "home owner"}`);
  
      for (const unitId of selectedUnits) {
        const unit = units.find(u => u._id === unitId);
        if (!unit) {
          console.error(`Unit not found: ${unitId}`);
          continue;
        }
  
        // Check for existing contract
        const hasExistingContract = existingContracts.some(contract =>
          contract.unitId === unitId &&
          contract.levyId === levy &&
          contract.status === 'Active'
        );
  
        if (hasExistingContract) {
          console.warn(`Unit ${unit.name} already has an active contract for this levy`);
          skippedUnits.push(`${unit.name} (already has active contract)`);
          continue;
        }
  
        // Determine customer ID to use based on levy applicant
        const customerId = determineCustomerId(unit, selectedLevy.levyApplicant);
  
        if (!customerId) {
          console.error(`No valid customer found for unit: ${unit.name}`);
          customerMissingUnits.push(unit.name);
          continue;
        }
  
        // Format start and end dates properly
        const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
        const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
  
        // Create contract data
        contractRequests.push({
          contractName: contractName.trim(),
          levyId: levy,
          customerId: customerId,
          unitId: unitId,
          amount: parseFloat(amount),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          paymentFrequency: paymentFrequency || selectedLevy.collectionFrequency || "Monthly",
          status: status,
          facilityId: facilityId,
          // Use values from levy if available, otherwise use facility settings
          currency: selectedLevy.currencyId || settings.currencyId || facilityId,
          paymentMethodId: selectedLevy.paymentMethodId || settings.paymentMethodId || facilityId
        });
      }
  
      if (contractRequests.length === 0) {
        let errorMessage = "No valid contracts to create.";
        if (skippedUnits.length > 0) {
          errorMessage += ` ${skippedUnits.length} units skipped (already have contracts).`;
        }
        if (customerMissingUnits.length > 0) {
          errorMessage += ` ${customerMissingUnits.length} units missing ${selectedLevy.levyApplicant || "home owner"}.`;
        }
        toastify(errorMessage, "warning");
        setLoading(false);
        return;
      }
  
      console.log(`Creating ${contractRequests.length} contracts...`);
  
      // Process contracts one at a time for better error tracking
      let successCount = 0;
      let failures = [];
  
      for (const contractData of contractRequests) {
        try {
          const response = await makeRequest2(`${addContract}/${facilityId}`, "POST", contractData);
  
          if (response.success) {
            successCount++;
          } else {
            failures.push({
              unit: units.find(u => u._id === contractData.unitId)?.name || contractData.unitId,
              error: response.error || "Unknown error"
            });
          }
        } catch (error) {
          console.error("Error creating contract:", error);
          failures.push({
            unit: units.find(u => u._id === contractData.unitId)?.name || contractData.unitId,
            error: error.message || "Request failed"
          });
        }
      }
  
      // Handle results based on success/failure count
      if (successCount > 0) {
        let message = `Successfully created ${successCount} contract(s)`;
        if (skippedUnits.length > 0) {
          message += `. ${skippedUnits.length} units were skipped (already have contracts).`;
        }
        toastify(message, "success");
        
        if (failures.length > 0) {
          console.error("Some contracts failed:", failures);
          toastify(`${failures.length} contracts failed. See console for details.`, "warning");
        }
        
        handleClear();
        navigate("/facility/levy_management/contracts");
      } else {
        toastify("Failed to create any contracts. See console for details.", "error");
        console.error("All contracts failed:", failures);
      }
    } catch (error) {
      console.error("Contract submission error:", error);
      toastify(error.message || "Error creating contracts", "error");
    } finally {
      setLoading(false);
    }
  };

  // Clear form
  const handleClear = () => {
    setContractName("");
    setLevy("");
    setSelectedLevy(null);
    setSelectedCustomers([]);
    setSelectedCustomerUnits({});
    setSelectedUnits([]);
    setAmount("");
    setStartDate("");
    setEndDate("");
    setPaymentFrequency("");
    setStatus("Active");
  };

  const UnitSelection = ({ units, onUnitSelect, selectedUnits, levyApplicant }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedDivision, setSelectedDivision] = useState("");
    const [selectedFloor, setSelectedFloor] = useState("");

    // Custom state for our own dropdown behavior
    const [openDivision, setOpenDivision] = useState(null);
    const [openFloors, setOpenFloors] = useState({});

    // Track if the unit panel is open
    const [isUnitPanelOpen, setIsUnitPanelOpen] = useState(false);
    const [currentUnits, setCurrentUnits] = useState([]);
    const [currentFloorName, setCurrentFloorName] = useState("");

    // Get unique divisions
    const divisions = [...new Set(units.map(unit => unit.division))].sort();

    // Get floors for selected division
    const floors = selectedDivision
      ? [...new Set(units
        .filter(unit => unit.division === selectedDivision)
        .map(unit => unit.floorUnitNo.split('/')[0]))].sort()
      : [];

    // Filter units based on search and selections, and also levy applicant requirement
    const filteredUnits = units.filter(unit => {
      const matchesSearch = searchTerm === "" ||
        unit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        unit.floorUnitNo.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDivision = selectedDivision === "" || unit.division === selectedDivision;
      const matchesFloor = selectedFloor === "" || unit.floorUnitNo.split('/')[0] === selectedFloor;
      
      // Check if unit has appropriate customer type based on levy applicant
      const hasMatchingCustomer = determineCustomerId(unit, levyApplicant) !== null;
      
      return matchesSearch && matchesDivision && matchesFloor && hasMatchingCustomer;
    });

    // Group units for the dropdown display
    const groupedUnits = filteredUnits.reduce((acc, unit) => {
      const division = unit.division;
      const floor = unit.floorUnitNo.split('/')[0];

      if (!acc[division]) {
        acc[division] = {};
      }
      if (!acc[division][floor]) {
        acc[division][floor] = [];
      }
      acc[division][floor].push(unit);
      return acc;
    }, {});

    // Toggle division dropdown
    const toggleDivision = (division) => {
      setOpenDivision(prev => prev === division ? null : division);
      // Close any open floors when toggling divisions
      if (openDivision !== division) {
        setOpenFloors({});
      }
    };

    // Toggle floor dropdown
    const toggleFloor = (division, floor) => {
      const floorKey = `${division}-${floor}`;
      setOpenFloors(prev => {
        const newState = { ...prev };
        newState[floorKey] = !prev[floorKey];
        return newState;
      });

      // Show the units panel regardless of whether the floor is being opened or closed
      setCurrentUnits(groupedUnits[division][floor]);
      setCurrentFloorName(`${division} - Floor ${floor}`);
      setIsUnitPanelOpen(true);
    };

    const handleSelectAll = (units, e) => {
      // Ensure the event doesn't propagate
      if (e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
      }

      // Get all unit IDs to toggle
      const unitIds = units.map(unit => unit._id);

      // Check if all units are already selected
      const allSelected = units.every(unit => selectedUnits.includes(unit._id));

      // Update selection
      if (allSelected) {
        // Remove all these units
        const newSelection = selectedUnits.filter(id => !unitIds.includes(id));
        onUnitSelect(newSelection);
      } else {
        // Add any missing units
        const newSelection = [...new Set([...selectedUnits, ...unitIds])];
        onUnitSelect(newSelection);
      }
    };

    // Handle single unit selection with strict event stopping
    const handleSingleUnitSelect = (unit, e) => {
      // Ensure the event doesn't propagate
      if (e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
      }

      // Toggle selection for this unit without closing anything
      if (selectedUnits.includes(unit._id)) {
        const newSelection = selectedUnits.filter(id => id !== unit._id);
        onUnitSelect(newSelection);
      } else {
        const newSelection = [...selectedUnits, unit._id];
        onUnitSelect(newSelection);
      }

      // Critical: Keep the panel open by resetting the state
      setTimeout(() => {
        if (isUnitPanelOpen) {
          setIsUnitPanelOpen(true);
        }
      }, 10);
    };

    // Function to safely handle clicks without propagation
    const stopEvent = (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
      }
      return false;
    };

    // This function keeps the panel open - critical fix
    const keepPanelOpen = () => {
      if (isUnitPanelOpen) {
        setIsUnitPanelOpen(true);
      }
    };

    // Get the customer role icon and label
    const getCustomerRoleInfo = (unit) => {
      const applicantType = levyApplicant?.toLowerCase() || "home owner";
      const hasHomeOwner = unit.homeOwnerId && unit.homeOwnerId !== '';
      const hasTenant = unit.tenantId && unit.tenantId !== '';
      
      let primaryIcon = '';
      let fallbackIcon = '';
      
      if (applicantType === "tenant" || applicantType === "tenants") {
        primaryIcon = hasTenant ? '👤 Tenant (Primary)' : '';
        fallbackIcon = hasHomeOwner ? '🏠 Home Owner (Fallback)' : '';
      } else {
        primaryIcon = hasHomeOwner ? '🏠 Home Owner (Primary)' : '';
        fallbackIcon = hasTenant ? '👤 Tenant (Fallback)' : '';
      }
      
      return (
        <small className="d-block text-muted">
          {primaryIcon}
          {primaryIcon && fallbackIcon ? ' | ' : ''}
          {fallbackIcon}
        </small>
      );
    };

    return (
      <div className="col-12 mb-4">
        <div className="card">
          <div className="card-header bg-light">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h6 className="mb-0">
                  Select Units 
                  {levyApplicant && (
                    <small className="text-muted ms-2">
                      (This levy applies to {levyApplicant.toLowerCase() === "tenant" ? "tenants" : "home owners"})
                    </small>
                  )}
                </h6>
              </div>
              <div className="col-auto">
                <span className="badge bg-primary">
                  {selectedUnits.length} units selected
                </span>
              </div>
            </div>

            <div className="row g-2">
              <div className="col-md-4">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="ti ti-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search units..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={selectedDivision}
                  onChange={(e) => {
                    setSelectedDivision(e.target.value);
                    setSelectedFloor("");
                  }}
                >
                  <option value="">All Blocks/Divisions</option>
                  {divisions.map(division => (
                    <option key={division} value={division}>{division}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={selectedFloor}
                  onChange={(e) => setSelectedFloor(e.target.value)}
                  disabled={!selectedDivision}
                >
                  <option value="">All Floors</option>
                  {floors.map(floor => (
                    <option key={floor} value={floor}>Floor {floor}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-outline-secondary w-100"
                  onClick={() => {
                    setSearchTerm("");
                    setSelectedDivision("");
                    setSelectedFloor("");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="custom-dropdown-tree px-2">
              {Object.entries(groupedUnits).length > 0 ? (
                Object.entries(groupedUnits).map(([division, floors]) => (
                  <div key={division} className="custom-dropdown mb-2">
                    {/* Division header */}
                    <div
                      className="custom-dropdown-toggle d-flex justify-content-between align-items-center"
                      onClick={() => toggleDivision(division)}
                    >
                      <span>
                        {division}
                        <small className="text-muted ms-2">
                          ({Object.values(floors).flat().filter(unit =>
                            selectedUnits.includes(unit._id)).length} selected)
                        </small>
                      </span>
                      <div
                        className="form-check me-2"
                        onClick={stopEvent}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={Object.values(floors).flat().every(unit =>
                            selectedUnits.includes(unit._id))}
                          onChange={(e) => {
                            stopEvent(e);
                            handleSelectAll(Object.values(floors).flat(), e);
                          }}
                          onClick={stopEvent}
                        />
                      </div>
                    </div>

                    {/* Division content (floors) */}
                    {openDivision === division && (
                      <div className="custom-dropdown-menu">
                        {Object.entries(floors).map(([floor, units]) => {
                          const floorKey = `${division}-${floor}`;
                          return (
                            <div key={floor} className="custom-dropdown-item">
                              <div className="d-flex align-items-center">
                                <div
                                  className="form-check me-2"
                                  onClick={stopEvent}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={units.every(unit => selectedUnits.includes(unit._id))}
                                    onChange={(e) => {
                                      stopEvent(e);
                                      handleSelectAll(units, e);
                                    }}
                                    onClick={stopEvent}
                                  />
                                </div>
                                <button
                                  className="custom-floor-toggle"
                                  onClick={() => toggleFloor(division, floor)}
                                >
                                  Floor {floor}
                                  <small className="text-muted ms-2">
                                    ({units.filter(unit => selectedUnits.includes(unit._id)).length} selected)
                                  </small>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="alert alert-info">
                  {!levyApplicant ? (
                    "Please select a levy first to see available units."
                  ) : (
                    `No units with assigned ${levyApplicant.toLowerCase() === "tenant" ? "tenants" : "home owners"} found. Please assign the appropriate customer type to units first.`
                  )}
                </div>
              )}
            </div>

            {/* Fixed position units panel */}
            {isUnitPanelOpen && (
              <div
                className="units-panel"
                onFocus={keepPanelOpen}
                onBlur={keepPanelOpen}
                onMouseDown={keepPanelOpen}
                onMouseUp={keepPanelOpen}
                onClick={keepPanelOpen}
              >
                <div className="units-panel-header">
                  <h6>{currentFloorName}</h6>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={(e) => {
                      stopEvent(e);
                      setIsUnitPanelOpen(false);
                    }}
                  ></button>
                </div>
                <div className="units-panel-body">
                  {currentUnits.map(unit => (
                    <div
                      key={unit._id}
                      className="unit-item"
                      onClick={keepPanelOpen}
                    >
                      <div
                        className="form-check"
                        onClick={stopEvent}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`unit-${unit._id}`}
                          checked={selectedUnits.includes(unit._id)}
                          onChange={(e) => {
                            handleSingleUnitSelect(unit, e);
                            // Force panel to stay open
                            setTimeout(() => keepPanelOpen(), 0);
                          }}
                          onClick={(e) => {
                            stopEvent(e);
                            // Keep focus in the panel
                            e.target.focus();
                          }}
                        />
                        <label
                          className="form-check-label w-100"
                          htmlFor={`unit-${unit._id}`}
                          onClick={(e) => {
                            stopEvent(e);
                            handleSingleUnitSelect(unit, e);
                            // Force panel to stay open
                            setTimeout(() => keepPanelOpen(), 0);
                          }}
                        >
                          {unit.name} - {unit.floorUnitNo}
                          {getCustomerRoleInfo(unit)}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <style>{`
          /* Custom dropdown styles that don't rely on Bootstrap dropdowns */
          .custom-dropdown-tree {
            position: relative;
          }
          
          .custom-dropdown {
            margin-bottom: 0.5rem;
          }
          
          .custom-dropdown-toggle {
            padding: 0.5rem 1rem;
            background-color: #f8f9fa;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            cursor: pointer;
            font-weight: 500;
          }
          
          .custom-dropdown-toggle:hover {
            background-color: #e9ecef;
          }
          
          .custom-dropdown-menu {
            padding: 0.5rem;
            background-color: white;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            margin-top: 0.25rem;
            margin-bottom: 0.5rem;
          }
          
          .custom-dropdown-item {
            padding: 0.5rem;
            border-radius: 0.25rem;
          }
          
          .custom-dropdown-item:hover {
            background-color: #f8f9fa;
          }
          
          .custom-floor-toggle {
            background: none;
            border: none;
            padding: 0.25rem 0.5rem;
            width: 100%;
            text-align: left;
            cursor: pointer;
          }
          
          .custom-floor-toggle:hover {
            color: #0d6efd;
          }
          
          /* Units panel (fixed position to avoid dropdown issues) */
          .units-panel {
            position: fixed;
            top: 20%;
            right: 20px;
            bottom: 20%;
            width: 350px;
            background: white;
            border: 1px solid rgba(0,0,0,.15);
            border-radius: 0.25rem;
            box-shadow: 0 2px 5px rgba(0,0,0,0.15);
            z-index: 9999;
            display: flex;
            flex-direction: column;
            /* Force the panel to stay open */
            opacity: 1 !important;
            visibility: visible !important;
          }
          
          .units-panel-header {
            padding: 1rem;
            border-bottom: 1px solid #dee2e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          
          .units-panel-header h6 {
            margin: 0;
          }
          
          .units-panel-body {
            padding: 1rem;
            overflow-y: auto;
            flex-grow: 1;
          }
          
          .unit-item {
            padding: 0.5rem 0;
            border-bottom: 1px solid #f1f1f1;
          }
          
          .unit-item:last-child {
            border-bottom: none;
          }
          
          .form-check-label {
            cursor: pointer;
            display: block;
          }
          
          /* Important: ensure the checkbox stays clickable without affecting panel */
          .form-check-input {
            position: relative;
            z-index: 10;
            pointer-events: auto !important;
          }
          
          /* Ensure clicks on the panel itself don't close it */
          .units-panel * {
            pointer-events: auto !important;
          }
          
          @media (max-width: 768px) {
            .units-panel {
              width: 90%;
              left: 5%;
              right: 5%;
            }
          }
        `}</style>
      </div>
    );
  };

  // Load data on component mount
  useEffect(() => {
    fetchLevies();
    fetchCustomersForFacility();
    fetchUnitsForFacility();
    fetchExistingContracts();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/levy_management/levies/levy_list">
                    Levy Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Add Multiple Contracts</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Contracts</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="form-label">Contract Name</label>
                    <InputText
                      className="form-control"
                      value={contractName}
                      onChange={(e) => setContractName(e.target.value)}
                    />
                  </div>

                  {/* Levy Selection */}
                  <div className="col-sm-6">
                    <label className="form-label">Levy</label>
                    <select
                      className="form-control"
                      value={levy}
                      onChange={(e) => {
                        const selectedLevyId = e.target.value;
                        setLevy(selectedLevyId);

                        // Find and set amount from selected levy
                        const levyObj = levies.find(
                          (l) => l._id === selectedLevyId
                        );

                        if (levyObj) {
                          setSelectedLevy(levyObj);
                          setAmount(levyObj.amount?.toString() || "0");
                          console.log("Selected Levy:", levyObj); // Debug log
                        } else {
                          setSelectedLevy(null);
                          setAmount("");
                        }
                        
                        // Clear selected units when levy changes
                        setSelectedUnits([]);
                      }}
                    >
                      <option value="">-- Select Levy --</option>
                      {levies.map((levy) => (
                        <option key={levy._id} value={levy._id}>
                          {levy.levyName} ({levy.levyType?.name || "N/A"}) - 
                          For {levy.levyApplicant?.toLowerCase() === "tenant" ? "Tenants" : "Home Owners"}
                        </option>
                      ))}
                    </select>
                    {levies.length === 0 && (
                      <small className="text-muted fst-italic">
                        No levies available. Click here to{" "}
                        <Link to="/facility/levy_management/levies/add_levy">
                          add a levy
                        </Link>
                      </small>
                    )}
                  </div>

                  <UnitSelection
                    units={units}
                    onUnitSelect={setSelectedUnits}
                    selectedUnits={selectedUnits}
                    levyApplicant={selectedLevy?.levyApplicant}
                  />
                  
                  <div className="col-sm-6">
                    <label className="form-label">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      value={amount}
                      readOnly
                    />
                  </div>
                  
                  <div className="col-sm-6">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  
                  <div className="col-sm-6">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  
                  <div className="col-sm-6">
                    <label className="form-label">Payment Frequency</label>
                    <select
                      className="form-control mt-2"
                      value={paymentFrequency}
                      onChange={(e) => setPaymentFrequency(e.target.value)}
                    >
                      <option value="">-- Select --</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Semi-Annually">Semi-Annually</option>
                      <option value="Annually">Annually</option>
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">Status</label>
                    <div className="mt-2 d-flex align-items-center">
                      <i
                        className={`ti ${status === "Inactive"
                          ? "ti-toggle-left text-danger"
                          : "ti-toggle-right text-primary"
                          }`}
                        style={{ fontSize: "34px", cursor: "pointer" }}
                        onClick={() =>
                          setStatus(
                            status === "Inactive" ? "Active" : "Inactive"
                          )
                        }
                      />
                      <span className="ms-2">{status}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary me-2"
                  onClick={handleClear}
                  disabled={loading}
                >
                  Clear
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleContractSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddMultipleContract;