import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { update_lease_template } from '../../../../../utils/urls';
import { getItem } from '../../../../../utils/localStorage';
import { toastify } from '../../../../../utils/toast';

const EditButton = ({ template, onEdit }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [templateName, setTemplateName] = useState(template.name);
    const [templateContent, setTemplateContent] = useState(template.templateContent);
    const [templateDescription, setTemplateDescription] = useState(template.description);

    // React Quill toolbar configuration
    const quillModules = {
        toolbar: [
            [{ font: [] }, { size: [] }], // Font and size
            ["bold", "italic", "underline", "strike"], // Formatting
            [{ color: [] }, { background: [] }], // Text color and background color
            [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            [{ indent: "-1" }, { indent: "+1" }], // Indentation
            [{ align: [] }], // Text alignment
            ["link", "image", "video"], // Multimedia
            ["blockquote", "code-block"], // Blockquote and code block
            ["clean"], // Clear formatting
        ],
    };

    const handleEdit = async () => {
        setLoading(true);
        try {
            const facilityId = await getItem('selectedFacilityId');
            
            if (!templateName || !templateContent || !templateDescription) {
                throw new Error('All fields are required');
            }

            const response = await makeRequest2(
                `${update_lease_template}/${facilityId}/${template._id}`,
                'POST',
                {
                    name: templateName,
                    description: templateDescription,
                    templateContent: templateContent
                }
            );

            if (response.success) {
                toastify('Template updated successfully!', 'success');
                onEdit(); // Refresh the parent component
                setShowDialog(false);
            } else {
                throw new Error(response.error || 'Failed to update template');
            }
        } catch (error) {
            toastify(error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const footerContent = (
        <div>
            <Button 
                label="Cancel" 
                icon="pi pi-times" 
                className="p-button-text" 
                onClick={() => setShowDialog(false)}
                disabled={loading}
            />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                className="p-button-primary" 
                onClick={handleEdit}
                loading={loading}
            />
        </div>
    );

    return (
        <>
            <Button
                icon="ti ti-edit"
                className="p-button-rounded p-button-info p-mr-2"
                onClick={() => setShowDialog(true)}
                tooltip="Edit"
            />

            <Dialog 
                visible={showDialog} 
                onHide={() => setShowDialog(false)}
                header="Edit Lease Template"
                footer={footerContent}
                style={{ width: '70vw' }}
                modal
                className="p-fluid"
            >
                <div className="p-grid p-fluid">
                    <div className="p-field mb-3">
                        <label htmlFor="templateName" className="mb-2">Template Name</label>
                        <InputText
                            id="templateName"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            className="w-full"
                        />
                    </div>

                    <div className="p-field mb-3">
                        <label htmlFor="templateDescription" className="mb-2">Description</label>
                        <InputText
                            id="templateDescription"
                            value={templateDescription}
                            onChange={(e) => setTemplateDescription(e.target.value)}
                            className="w-full"
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="templateContent" className="mb-2">Template Content</label>
                        <div style={{ height: '400px' }}>
                            <ReactQuill
                                theme="snow"
                                value={templateContent}
                                onChange={setTemplateContent}
                                modules={quillModules} // Add the toolbar configuration
                                style={{ height: '350px' }}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default EditButton;
