import AssetManagement from "./components/AssetManagement";
import StockAndSpareManagement from "./components/StockAndSpareParts";
import ServiceVendor from "./components/service_vendor";
import EmployeeManagement from "./components/employee_management";
import WorkOrderManagement from "./components/work_order_management";

const maintenanceRouter = [
  {
    path: "/facilitiy/maintenance/asset",
    element: <AssetManagement />,
  },
  {
    path: "/facilitiy/maintenance/stock_and_spare_parts",
    element: <StockAndSpareManagement />,
  },
  {
    path: "/facilitiy/maintenance/sla",
    element: <ServiceVendor />,
  },
  {
    path: "/facilitiy/maintenance/employee_management",
    element: <EmployeeManagement />,
  },
  {
    path: "/facility/maintenance/work_order_management",
    element: <WorkOrderManagement />,
  },
];

export default maintenanceRouter;
