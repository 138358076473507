import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { updateFacilityInfo, getCompanyInformationURL, addLandlordURL } from '../../../utils/urls';
import { getItem } from '../../../utils/localStorage';

const FacilityTab = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [logoPreview, setLogoPreview] = useState('');
    const [image, setImage] = useState(null);

    const [facilityLogo, setFacilityLogo] = useState(() => localStorage.getItem('selectedFacilityLogo') || "");


    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');




    const fetchCompanyInformation = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getCompanyInformationURL}/${facilityId}`, 'GET');

            if (response.success) {
                console.log("Success");
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }

    const getFacilityLogo = async () => {
        try {
            const facilityFromState = location.state?.facility;

            if (facilityFromState?.logo) {
                const fullLogoUrl = `${process.env.REACT_APP_BASE_URL}/${facilityFromState.logo}`;
                localStorage.setItem('selectedFacilityLogo', fullLogoUrl);
                setFacilityLogo(fullLogoUrl);
                setLogoPreview(fullLogoUrl);
            } else {
                const storedLogo = localStorage.getItem('selectedFacilityLogo');
                if (storedLogo) {
                    setFacilityLogo(storedLogo);
                    setLogoPreview(storedLogo);
                } else {
                    setFacilityLogo("");
                    setLogoPreview("");
                }
            }
        } catch (error) {
            console.error("Error fetching facility logo:", error);
            setFacilityLogo("");
            setLogoPreview("");
        }
    };

    const handleUpdateLogo = async () => {
        if (!image) {
            return toastify("Please select an image to upload.", "error");
        }

        try {
            const formData = new FormData();
            formData.append("image", image);
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${updateFacilityInfo}/${facilityId}`, 'POST', formData);

            if (response.success) {
                const updatedLogoUrl = `${process.env.REACT_APP_BASE_URL}/${response.data.logo}`;
                setImage("");

                // Update state
                setFacilityLogo(updatedLogoUrl);
                setLogoPreview(updatedLogoUrl);

                // Update local storage
                localStorage.setItem("selectedFacilityLogo", updatedLogoUrl);

                navigate("/app/facilities");

                toastify("Facility logo updated successfully.", "success");
            } else {
                toastify("Failed to update logo.", "error");
            }
        } catch (error) {
            console.error("Error updating facility logo:", error);
            toastify("An error occurred while updating the logo.", "error");
        }
    };


    useEffect(() => {
        getFacilityLogo();
        fetchCompanyInformation();
    }, []);

    return (
        <div className="card">
            <div className="card-body">
                <h5>Facility Settings</h5>
                <div className="form-group mt-3">
                    <label>Logo</label>
                    <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setImage(e.target.files[0])}
                        accept="image/*"
                    />
                    {(logoPreview || facilityLogo) && (
                        <img
                            src={logoPreview || facilityLogo}
                            alt="Facility Logo"
                            className="mt-2"
                            style={{ maxWidth: '200px' }}
                        />
                    )}
                </div>
                <div className="mt-4 text-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleUpdateLogo}
                    >
                        Update Facility Logo
                    </button>
                </div>
            </div>

        </div>
    );
};

export default FacilityTab;