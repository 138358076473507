import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { smart_meters_base_url ,meters_readings_base_url} from "../../../../../utils/urls";
import { toastify } from '../../../../../utils/toast';
import Layout from '../../../component/layout';

// Import tab components
import MeterInfoTab from './view_meter_tabs/meterInfoTab';
import ReadingHistoryTab from './view_meter_tabs/readingHistoryTab';
import CustomerDetailsTab from './view_meter_tabs/customerDetailsTab';
import TransactionsTab from './view_meter_tabs/transactionsTab';

function ViewWaterMeter() {
    const navigate = useNavigate();
    const { meterId } = useParams();
    const [activeTab, setActiveTab] = useState('meter-info');
    const [meterDetails, setMeterDetails] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [readings, setReadings] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    // Use a single date field instead of startDate/endDate:
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        if (meterId) {
            const today = new Date().toISOString().split('T')[0]; // "YYYY-MM-DD"
            setSelectedDate(today);
            fetchMeterDetails();
            fetchReadings(today);
            // Optionally: fetchTransactions();
        }
    }, [meterId]);

    // Fetch meter details using the meterId
    const fetchMeterDetails = async () => {
    try {
        setLoading(true);
        const response = await makeRequest2(
            `${smart_meters_base_url}/details/${meterId}`,
            'GET',
            null
        );
        console.log('API Response:', response);
        if (response.success) {
            // Extract the meter object from the response
            const meterData = response.data.meter;
            setMeterDetails(meterData);

            // Set customer info if available
            if (meterData.CustomerInfo) {
                setCustomer(meterData.CustomerInfo);
            }
        } else {
            toastify('Failed to fetch meter details', 'error');
        }
    } catch (error) {
        toastify('Error fetching meter details', 'error');
    } finally {
        setLoading(false);
    }
};

    // Fetch meter reading history using the selectedDate.
    // In fetchReadings(), modify to properly handle the response:
    const fetchReadings = async (dateParam) => {
        if (!meterId) return;
        const filterDate = dateParam || selectedDate;
        if (!filterDate) {
            toastify('Please select a date', 'error');
            return;
        }
        try {
            setLoading(true);
            const url = `${meters_readings_base_url}/${meterId}?date=${filterDate}`;
            const response = await makeRequest2(url, 'GET', null);

            

            // Add this to debug
            console.log('Readings response:', response);


            if (response.success) {
                // The controller returns { readings: [...] }
                setReadings(response.data.readings || []);
            } else {
                toastify('Failed to fetch meter reading history', 'error');
                // Set empty array to avoid undefined errors
                setReadings([]);
            }
        } catch (error) {
            console.error('Reading fetch error:', error);
            toastify('Error fetching meter reading history', 'error');
            setReadings([]);
        } finally {
            setLoading(false);
        }
    };

    // (Optional) Fetch transactions if needed.
    const fetchTransactions = async () => {
        // Your implementation here.
    };

    // Update the single date value.
    const handleDateChange = (value) => {
        setSelectedDate(value);
    };

    const handleFilter = () => {
        fetchReadings();
    };

    const handleExport = (format) => {
        toastify(`Exporting in ${format} format`, 'info');
    };

    return (
        <Layout>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <Link
                                to="/facility/utility_management/meter_management/water_meters"
                                state={{ activeTab: "smart-meters" }}
                            >
                                <i className="ti ti-arrow-narrow-left"></i> Back
                            </Link>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'meter-info' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('meter-info')}
                                        type="button"
                                    >
                                        <i className="ti ti-dashboard me-2"></i>Meter Info
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'readings' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('readings')}
                                        type="button"
                                    >
                                        <i className="ti ti-list-numbers me-2"></i>Reading History
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'customer' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('customer')}
                                        type="button"
                                    >
                                        <i className="ti ti-users me-2"></i>Account Details
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'transactions' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('transactions')}
                                        type="button"
                                    >
                                        <i className="ti ti-cash me-2"></i>Transactions
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content" style={{ paddingTop: '2rem' }}>
                                {activeTab === 'meter-info' && (
                                    <MeterInfoTab meterDetails={meterDetails} />
                                )}
                                {activeTab === 'readings' && (
                                    <ReadingHistoryTab
                                        readings={readings}
                                        loading={loading}
                                        date={selectedDate}
                                        onDateChange={handleDateChange}
                                        onFilter={handleFilter}
                                        onExport={handleExport}
                                    />
                                )}
                                {activeTab === 'customer' && (
                                    <CustomerDetailsTab customer={customer} />
                                )}
                                {activeTab === 'transactions' && (
                                    <TransactionsTab
                                        transactions={transactions}
                                        loading={loading}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ViewWaterMeter;
