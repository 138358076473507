import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toastify } from "../../../../../utils/toast";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import { get_lease_templates } from "../../../../../utils/urls";
import { getItem } from "../../../../../utils/localStorage";
import Layout from "../../../component/layout";
import EditButton from "./edit_template";
// import DeleteButton from "./delete_template";
import CreateLeaseTemplate from "./create_lease_template";

const LeaseTemplateManagement = () => {
  const [templates, setTemplates] = useState([]);
  const [filteredLeaseTemplates, setFilteredLeaseTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [addTemplateModalVisible, setAddTemplateModalVisible] = useState(false);

  // Fetch lease templates from API
  const fetchLeaseTemplates = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${get_lease_templates}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success && response.data) {
        setTemplates(response.data);
        setFilteredLeaseTemplates(response.data);
      } else {
        toastify("Failed to fetch lease templates.", "error");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Handle search input
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = templates.filter((template) =>
      template.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredLeaseTemplates(filtered);
  };

  // Handle delete template
  // const handleDeleteTemplate = (templateId) => {
  //   setTemplates((prevTemplates) =>
  //     prevTemplates.filter((template) => template._id !== templateId)
  //   );
  //   setFilteredLeaseTemplates((prevFiltered) =>
  //     prevFiltered.filter((template) => template._id !== templateId)
  //   );
  // };

  // Action body template for edit and delete buttons
  const actionBodyTemplate = (rowData) => (
    <div className="d-flex justify-content-start align-items-center">
      <EditButton template={rowData} onEdit={fetchLeaseTemplates} />
      {/* <DeleteButton templateId={rowData._id} onDelete={handleDeleteTemplate} /> */}
    </div>
  );

  useEffect(() => {
    fetchLeaseTemplates();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Lease Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">
                    Lease Templates
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Lease Template Management</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <input
                    className="form-control"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-9 mb-3 text-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddTemplateModalVisible(true)}
                  >
                    Add Lease Template
                  </button>
                </div>
              </div>

              <DataTable
                value={filteredLeaseTemplates}
                emptyMessage="No templates found."
                paginator
                rows={5}
                stripedRows
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="name" header="Template Name" />
                <Column field="description" header="Description" />
                <Column body={actionBodyTemplate} header="Actions" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <CreateLeaseTemplate
        visible={addTemplateModalVisible}
        onHide={() => setAddTemplateModalVisible(false)}
        onTemplateCreated={fetchLeaseTemplates}
      />
    </Layout>
  );
};

export default LeaseTemplateManagement;