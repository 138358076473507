import React, { useState } from 'react';
import Layout from '../component/layout';
import { Link, useNavigate } from 'react-router-dom';
import { toastify } from '../../../utils/toast';
import {getItem} from '../../../utils/localStorage'
import { makeRequest2 } from '../../../utils/makeRequest';
import { addCampaignURL } from '../../../utils/urls';


const AddCampaign = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [objective, setObjective] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [channels, setChannels] = useState([]);
    const [message, setMessage] = useState('');

    const handleChannelChange = (channel) => {
        setChannels((prevChannels) =>
            prevChannels.includes(channel)
                ? prevChannels.filter((c) => c !== channel) // Remove if already selected
                : [...prevChannels, channel] // Add if not selected
        );
    };
    

    const handleSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const campaignData = {
                name,
                objective,
                description,
                startDate,
                endDate,
                targetAudience,
                channels,
                message
            }
            console.log("campaignData", campaignData);
            const response = await makeRequest2(`${addCampaignURL}/${facilityId}`, 'POST', campaignData);
            console.log("response", response);

            if (response.success) {
                toastify('Campaign added successfully', 'success');
                handleClear();
            } else {
                console.log(response.error);
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    }

    const handleClear = () => {
        setName('');
        setObjective('');
        setDescription('');
        setStartDate('');
        setEndDate('');
        setTargetAudience('');
        setMessage('');
        setChannels

        (['', '', '', '']);
    }

    return (
        <Layout>
            <div>
                <div className="card">
                    <div className="card-header">
                        <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">


                        <div className="row">

                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Add Campaign</h5>
                                    </div>

                                    <div className='card-body'>

                                        <div className="row">

                                            <div className="col-lg-12 col-xxl-12">
                                                <div className="card">

                                                    <div className="card-body position-relative">

                                                        <div className="container mt-4">

                                                                <div className="mb-3">
                                                                    <label className="form-label">Campaign Name</label>
                                                                    <input type="text" className="form-control" name="name"
                                                                        value={name}
                                                                        onChange={(e) => setName(e.target.value)}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Objective</label>
                                                                    <select className="form-select" name="objective"
                                                                        value={objective}
                                                                        onChange={(e) => setObjective(e.target.value)}
                                                                        required>
                                                                        <option value="">Select Objective</option>
                                                                        <option value="Brand Awareness">Brand Awareness</option>
                                                                        <option value="Lead Generation">Lead Generation</option>
                                                                        <option value="Sales">Sales</option>
                                                                        <option value="Engagement">Engagement</option>
                                                                    </select>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Description</label>
                                                                    <textarea className="form-control" name="description" rows="4"
                                                                        value={description}
                                                                        onChange={(e) => setDescription(e.target.value)}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Start Date</label>
                                                                    <input type="date" className="form-control" name="startDate"
                                                                        value={startDate}
                                                                        onChange={(e) => setStartDate(e.target.value)}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">End Date</label>
                                                                    <input type="date" className="form-control" name="endDate"
                                                                        value={endDate}
                                                                        onChange={(e) => setEndDate(e.target.value)}
                                                                        required />
                                                                </div>


                                                                <div className="mb-3">
                                                                    <label className="form-label">Target Audience</label>
                                                                    <select className="form-select" name="targetAudience"
                                                                        value={targetAudience}
                                                                        onChange={(e) => setTargetAudience(e.target.value)}
                                                                        required>
                                                                        <option value="">Select Target Audience</option>
                                                                        <option value="landlords">Landlords</option>
                                                                        <option value="tenants">Tenants</option>
                                                                        {/* <option value="individuals">Individuals</option> */}
                                                                    </select>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Marketing Channels</label>
                                                                    <div>
                                                                        {['SMS'].map((channel) => (
                                                                            <div key={channel} className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value={channel}
                                                                                    checked={channels.includes(channel)}
                                                                                    onChange={() => handleChannelChange(channel)}
                                                                                />
                                                                                <label className="form-check-label">{channel}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Message</label>
                                                                    <textarea className="form-control" name="description" rows="4"
                                                                        value={message}
                                                                        onChange={(e) => setMessage(e.target.value)}
                                                                        required />
                                                                </div>

                                                                <div className='text-md-end'>
                                                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Create Campaign</button>
                                                                </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </Layout>
    );
};

export default AddCampaign;
