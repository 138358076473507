import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
import WorkOrdersTab from './work_orders_tab';
import FacilityServiceRequestsTab from './facility_service_requests_tab';
import {
    getWorkOrders,
    deleteWorkOrder,
    getFacilityServiceRequests,
    getVasServices,
    getFacilityUnits
} from '../../../utils/urls';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';

function Services() {
    const [workOrders, setWorkOrders] = useState([]);
    const [facilityServiceRequests, setFacilityServiceRequests] = useState([]);
    const [services, setServices] = useState([]);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchWorkOrders = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const fId = typeof facilityId === 'object' ? facilityId.$oid : facilityId;
            const url = `${getWorkOrders}/${fId}`;
            const response = await makeRequest2(url, 'GET');

            if (response.success) {
                const orders = response.data.workOrders || response.data || [];
                setWorkOrders(orders);
            } else {
                toastify(response.message || 'Unable to fetch work orders', 'error');
            }
        } catch (err) {
            console.error('Error fetching work orders:', err);
            toastify(err.message || 'Error fetching work orders', 'error');
        }
    };

    const fetchFacilityServiceRequests = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const url = `${getFacilityServiceRequests}/${facilityId}`;
            const response = await makeRequest2(url, 'GET');

            if (response.success) {
                const requests = response.data?.serviceRequests || [];
                setFacilityServiceRequests(requests);
            } else {
                toastify(response.message || 'Unable to fetch facility service requests', 'error');
            }
        } catch (err) {
            console.error('Error fetching facility service requests:', err);
            toastify(err.message || 'Error fetching facility service requests', 'error');
        } finally {
            setLoading(false);
        }
    };

    const fetchServices = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `${getVasServices}/${facilityId}`,
                'GET'
            );

            if (response.success) {
                const serviceOptions = response.data.services.map(service => ({
                    label: service.serviceName,
                    value: service._id
                }));
                setServices(serviceOptions);
            } else {
                toastify('Failed to fetch services', 'error');
            }
        } catch (err) {
            console.error('Error fetching services:', err);
            toastify(err.message || 'Error fetching services', 'error');
        }
    };

    const fetchUnits = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            if (!facilityId) {
                throw new Error('Facility ID not found');
            }

            const response = await makeRequest2(
                `${getFacilityUnits}/${facilityId}`,
                'GET'
            );

            if (response.success) {
                // Use only occupiedUnits
                const occupiedUnits = response.data.occupiedUnits || [];

                // Transform units to match the hook's expected format
                const unitOptions = occupiedUnits.map(unit => ({
                    value: unit._id,
                    label: `${unit.name} (${unit.unitType})`,
                    name: unit.name,
                    unitType: unit.unitType,
                    residentId: unit.residentId,
                    tenantId: unit.tenantId,
                    homeOwnerId: unit.homeOwnerId,
                    // Spread the entire unit object to preserve all original data
                    ...unit
                }));
                setUnits(unitOptions);
            } else {
                throw new Error('Failed to fetch units');
            }
        } catch (err) {
            console.error('Error fetching units:', err);
            toastify(err.message || 'Error fetching units', 'error');
        }
    };

    const handleDeleteWorkOrder = async (workOrderId) => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const fId = typeof facilityId === 'object' ? facilityId.$oid : facilityId;
            const url = `${deleteWorkOrder}/${fId}/${workOrderId}`;

            const response = await makeRequest2(url, 'DELETE');

            if (response.success) {
                toastify('Work Order deleted successfully', 'success');
                await fetchWorkOrders();
            } else {
                toastify(response.message || 'Failed to delete work order', 'error');
            }
        } catch (err) {
            console.error('Error deleting work order:', err);
            toastify(err.message || 'Error deleting work order', 'error');
        }
    };

    useEffect(() => {
        const initializeData = async () => {
            setLoading(true);
            try {
                await Promise.all([
                    fetchWorkOrders(),
                    fetchFacilityServiceRequests(),
                    fetchServices(),
                    fetchUnits()
                ]);
            } catch (error) {
                console.error('Error initializing data:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/app/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="">Vas</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="">Services</Link>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="card">
                <div className="card-body py-0">
                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                id="facility-service-requests-tab"
                                data-bs-toggle="tab"
                                href="#facility-service-requests"
                                role="tab"
                                aria-selected="true"
                            >
                                <i className="fa fa-bell mx-2"></i> Service Requests
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                id="workorders-tab"
                                data-bs-toggle="tab"
                                href="#workorders"
                                role="tab"
                                aria-selected="false"
                            >
                                <i className="ti ti-clipboard me-2"></i> Work Orders
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="tab-content">
                <div className="tab-pane fade show active" id="facility-service-requests" role="tabpanel">
                    <FacilityServiceRequestsTab
                        facilityServiceRequests={facilityServiceRequests}
                        refreshRequests={fetchFacilityServiceRequests}
                        refreshWorkOrders={fetchWorkOrders}
                        services={services}
                        units={units}
                    />
                </div>
                <div className="tab-pane fade" id="workorders" role="tabpanel">
                    <WorkOrdersTab
                        workOrders={workOrders}
                        onDeleteWorkOrder={handleDeleteWorkOrder}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default Services;