import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { makeRequest2 } from "../../../../utils/makeRequest";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Layout from "../../component/layout";
import styles from "./waterInvoice.module.css";

// API endpoint URLs
import {
  getWaterInvoice,
  showLevySettings,
  getCompanyInformationURL,
} from "../../../../utils/urls";

// Using Zustand (or another global store) to retrieve the facility ID
import { useFacilityStore } from "../../../../app/z-store/store";

const WaterInvoicePage = () => {
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentSettings, setPaymentSettings] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);

  const location = useLocation();
  const { invoiceId } = location.state || {};
  const toast = useRef(null);
  const facilityId = useFacilityStore((state) => state.facilityId);

  useEffect(() => {
    if (invoiceId && facilityId) {
      fetchInvoiceData();
      fetchCompanyDetails();
    } else {
      setLoading(false);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Missing invoice ID or facility ID",
      });
    }
    // eslint-disable-next-line
  }, [invoiceId, facilityId]);

  const fetchInvoiceData = async () => {
    try {
      console.log(`Fetching invoice with ID: ${invoiceId} for facility: ${facilityId}`);
      const response = await makeRequest2(
        `${getWaterInvoice}/${invoiceId}?facilityId=${facilityId}`,
        "GET",
        {}
      );
      console.log("Invoice API response:", response);
      
      // Handle nested data structure
      if (response.success) {
        // Check if data is nested one more level deep
        const invoiceData = response.data?.data?.invoice || response.data?.invoice;
        
        if (invoiceData) {
          console.log("Setting invoice data:", invoiceData);
          setInvoice(invoiceData);
        } else {
          console.error("Invoice data structure is unexpected:", response);
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Invoice data has an unexpected structure",
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to fetch invoice data",
        });
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false);
    }
  };
  
  const fetchCompanyDetails = async () => {
    try {
      const response = await makeRequest2(
        `${getCompanyInformationURL}/${facilityId}`,
        "GET",
        {}
      );
      console.log("Company details response:", response);
      
      // Handle potentially nested structure here too
      if (response.success) {
        const companyData = response.data?.data || response.data;
        if (companyData) {
          setCompanyDetails(companyData);
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to fetch company details",
        });
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "An unexpected error occurred",
      });
    }
  };

  const handleDownloadPDF = async () => {
    const element = document.getElementById("printable-water-invoice");
    if (!element) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "PDF element not found",
      });
      return;
    }
    
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `Water_Invoice_${invoice?.invoiceNumber || "unknown"}_${timestamp}.pdf`;

    try {
      setLoading(true);
      toast.current?.show({
        severity: "info",
        summary: "Processing",
        detail: "Generating PDF...",
      });

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");

      pdf.save(filename);

      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "PDF downloaded successfully!",
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  if (!invoice) {
    return (
      <Layout>
        <Toast ref={toast} />
        <div className={styles.pageContainer}>
          <div className={styles.errorMessage}>
            No invoice data available. Please check the invoice ID and try again.
          </div>
          <Link
            to="/facility/utility_management/meter_management/water_meters"
            state={{ activeTab: "invoices" }}
          >
            <Button label="Back to Invoices" className={styles.backButton} />
          </Link>
        </div>
      </Layout>
    );
  }

  // Format currency amount
  const formatCurrency = (amount) => {
    const symbol = invoice?.currency?.symbol || "KSh";
    return `${symbol} ${Number(amount).toFixed(2)}`;
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className={styles.pageContainer}>
        {/* Action buttons */}
        <div className={styles.actionBar}>
          <Link
            to="/facility/utility_management/meter_management/water_meters"
            state={{ activeTab: "invoices" }}
          >
            <i className="ti ti-arrow-narrow-left"></i> Back
          </Link>
          <Button
            label="Download PDF"
            onClick={handleDownloadPDF}
            className={styles.downloadButton}
          />
        </div>
        {/* Actual invoice section */}
        <div
          id="printable-water-invoice"
          className={styles.invoiceWrapper}
        >
          {/* Header / Title */}
          <div className={styles.invoiceHeader}>
            <h1 className={styles.invoiceTitle}>WATER INVOICE</h1>
            <div className={styles.invoiceInfo}>
              <div>
                <span className={styles.infoLabel}>
                  Invoice #:
                </span>{" "}
                <span>
                  {invoice.invoiceNumber || "N/A"}
                </span>
              </div>
              <div>
                <span className={styles.infoLabel}>Date:</span>{" "}
                <span>
                  {formatDate(invoice.dateIssued)}
                </span>
              </div>
              <div>
                <span className={styles.infoLabel}>
                  Due Date:
                </span>{" "}
                <span>
                  {formatDate(invoice.dueDate)}
                </span>
              </div>
            </div>
          </div>

          {/* Company & Customer Section */}
          <div className={styles.companyCustomerSection}>
            <div className={styles.companyBlock}>
              <h2 className={styles.sectionHeading}>From</h2>
              <p className={styles.companyName}>
                {companyDetails?.name || invoice?.FacilityInfo?.name || "N/A"}
              </p>
              <p>{companyDetails?.address || invoice?.FacilityInfo?.location || "N/A"}</p>
              <p>{companyDetails?.country || "Kenya"}</p>
            </div>
            <div className={styles.customerBlock}>
              <h2 className={styles.sectionHeading}>Bill To</h2>
              <p className={styles.customerName}>
                {invoice?.CustomerInfo?.fullName || "N/A"}
              </p>
              <p>Unit: {invoice.unitName || "N/A"}</p>
              <p>Account: {invoice.accountNumber || "N/A"}</p>
            </div>
          </div>

          {/* Water Meter Details */}
          <div className={styles.meterDetails}>
            <h2 className={styles.sectionHeading}>
              Meter Details
            </h2>
            <table className={styles.meterTable}>
              <thead>
                <tr>
                  <th>Meter #</th>
                  <th>Current Reading</th>
                  <th>Previous Reading</th>
                  <th>Usage</th>
                  <th>Water Charge</th>
                  <th>Sewer Charge</th>
                  <th>Total Bill</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {invoice.meterNumber || "N/A"}
                  </td>
                  <td>
                    {invoice.meterReadings?.currentReading || 0}
                  </td>
                  <td>
                    {invoice.meterReadings?.previousReading || 0}
                  </td>
                  <td>
                    {invoice.meterReadings?.usage || 0} units
                  </td>
                  <td>
                    {formatCurrency(invoice.charges?.waterCharge || 0)}
                  </td>
                  <td>
                    {formatCurrency(invoice.charges?.sewerCharge || 0)}
                  </td>
                  <td>
                    {formatCurrency(invoice.charges?.totalMonthlyBill || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Consumption & Notes */}
          <div className={styles.consumptionBlock}>
            <p>
              <span className={styles.infoLabel}>
                Consumption Period:
              </span>{" "}
              {invoice.consumptionPeriod ? (
                `${formatDate(invoice.consumptionPeriod.startDate)} - ${formatDate(invoice.consumptionPeriod.endDate)}`
              ) : (
                "N/A"
              )}
            </p>
            <p>
              <span className={styles.infoLabel}>
                Billing Type:
              </span>{" "}
              {invoice.billingType ? invoice.billingType.charAt(0).toUpperCase() + invoice.billingType.slice(1) : "N/A"}
            </p>
            <p>
              <span className={styles.infoLabel}>
                Payment Status:
              </span>{" "}
              <span className={styles[`status${invoice.status || "Pending"}`] || ""}>
                {invoice.status || "Pending"}
              </span>
            </p>
            <p>
              <span className={styles.infoLabel}>
                Invoice Note:
              </span>{" "}
              {invoice.invoiceNote || "N/A"}
            </p>
          </div>

          {/* Payment Details / Footer */}
          <div className={styles.footerSection}>
            <h2 className={styles.sectionHeading}>
              Payment Details
            </h2>
            <p>
              <strong>Bank:</strong>{" "}
              {paymentSettings?.bankName || "N/A"}
            </p>
            <p>
              <strong>Account:</strong>{" "}
              {paymentSettings?.accountNumber || "N/A"}
            </p>
            <p>
              <strong>Terms & Conditions:</strong>{" "}
              {paymentSettings?.termsAndConditions || "Payment is due within 10 days of invoice date"}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WaterInvoicePage;