import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact";
import { Link } from "react-router-dom";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { toastify } from "../../../../utils/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";

import Layout from "../../component/layout";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../app/z-store/store";
import DutyRosterCalendar from "./DutyRosterCalendar";

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [dutyRoster, setDutyRoster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [showDutyRosterModal, setShowDutyRosterModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDutyRoster, setSelectedDutyRoster] = useState(null);
  const [employeeFormData, setEmployeeFormData] = useState({
    name: "",
    contact: "",
    phoneNumber: "",
    service: [],
  });
  const [dutyRosterFormData, setDutyRosterFormData] = useState({
    name: "",
    phone: "",
    activity: "",
    time: "",
    schedule: [],
  });

  const [selectedDates, setSelectedDates] = useState([]);

  const [activeTab, setActiveTab] = useState("employees");

  const toast = useRef(null);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  useEffect(() => {
    if (selectedFacilityId) {
      fetchEmployees(selectedFacilityId);
      fetchDutyRoster(selectedFacilityId);
    }
  }, [selectedFacilityId]);

  const fetchEmployees = async (currentFacilityId) => {
    try {
      setLoading(true);
      const response = await makeRequest2(
        `/api/employees/${currentFacilityId}`,
        "GET",
        null
      );

      if (response.success) {
        const employeeData = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.employees)
          ? response.data.employees
          : [];
        setEmployees(employeeData);
        setError(null);
      } else {
        toastify("Failed to fetch staff records", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDutyRoster = async (currentFacilityId) => {
    try {
      setLoading(true);
      const response = await makeRequest2(
        `/api/duty_roster/${currentFacilityId}`,
        "GET",
        null
      );

      // Modify response handling to be more flexible
      if (response.success) {
        // Try multiple possible data locations
        const rosterData =
          response.data?.entries ||
          response.data?.dutyRoster ||
          (Array.isArray(response.data) ? response.data : []);

        setDutyRoster(rosterData);
        setError(null);
      } else {
        toastify("Failed to fetch duty roster entries", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const filteredEmployees = employees.filter((employee) =>
    Object.values(employee).some((value) =>
      value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
    )
  );

  const filteredDutyRoster = dutyRoster.filter((roster) =>
    Object.values(roster).some((value) =>
      value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
    )
  );

  const handleEmployeeInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEmployeeModalOpen = (employee = null) => {
    if (employee) {
      setSelectedEmployee(employee);
      setEmployeeFormData({
        name: employee.name,
        contact: employee.contact,
        phoneNumber: employee.phoneNumber,
        service: employee.service,
      });
    } else {
      setSelectedEmployee(null);
      setEmployeeFormData({
        name: "",
        contact: "",
        phoneNumber: "",
        service: [],
      });
    }
    setShowEmployeeModal(true);
  };

  const handleDutyRosterModalOpen = (roster = null) => {
    if (selectedFacilityId) {
      fetchEmployees(selectedFacilityId); // Fetch employees for the selected facility
    }

    if (roster) {
      // Editing existing roster entry
      setSelectedDutyRoster(roster);

      const matchedEmployee = employees.find(
        (emp) => emp._id === roster.employeeId
      );

      setDutyRosterFormData({
        employeeId: roster.employeeId,
        name: matchedEmployee ? matchedEmployee.name : roster.name,
        phone: matchedEmployee ? matchedEmployee.phoneNumber : roster.phone,
        activity: roster.activity,
        day: roster.day,
        time: roster.time,
      });
    } else {
      // Adding new roster entry
      setSelectedDutyRoster(null);
      setDutyRosterFormData({
        employeeId: null,
        name: "",
        phone: "",
        activity: "",
        day: "",
        time: "",
      });
    }

    setShowDutyRosterModal(true);
  };

  const handleDutyRosterEmployeeSelect = (selectedEmployeeId) => {
    const selectedEmp = employees.find((emp) => emp._id === selectedEmployeeId);
    if (selectedEmp) {
      setDutyRosterFormData((prev) => ({
        ...prev,
        employeeId: selectedEmp._id,
        name: selectedEmp.name,
        phone: selectedEmp.phoneNumber,
      }));
    }
  };

  const handleEmployeeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response;
      if (selectedEmployee) {
        response = await makeRequest2(
          `/api/employees/${selectedFacilityId}/${selectedEmployee._id}`,
          "PUT",
          employeeFormData
        );
      } else {
        response = await makeRequest2(
          `/api/employees/${selectedFacilityId}`,
          "POST",
          employeeFormData
        );
      }

      if (response.success) {
        toastify(
          selectedDutyRoster
            ? "Staff updated successfully"
            : "Staff added successfully",
          "success"
        );

        fetchEmployees(selectedFacilityId);
        setShowEmployeeModal(false);
      } else {
        toastify("An error occured", "error");
      }
    } catch (err) {
      toastify("An error occured", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDutyRosterSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Prepare data for submission with schedule
      const submissionData = {
        employeeId: dutyRosterFormData.employeeId,
        name: dutyRosterFormData.name,
        phone: dutyRosterFormData.phone,
        activity: dutyRosterFormData.activity,
        time: dutyRosterFormData.time,
        schedule: selectedDates.map((date) => ({
          date: date,
          status: "pending",
        })),
      };

      let response;
      if (selectedDutyRoster) {
        response = await makeRequest2(
          `/api/duty_roster/${selectedFacilityId}/${selectedDutyRoster._id}`,
          "PUT",
          submissionData
        );
      } else {
        response = await makeRequest2(
          `/api/duty_roster/${selectedFacilityId}`,
          "POST",
          submissionData
        );
      }

      if (response.success) {
        toastify(
          selectedDutyRoster
            ? "Duty roster entry updated successfully"
            : "Duty roster entry added successfully",
          "success"
        );

        fetchDutyRoster(selectedFacilityId);
        setShowDutyRosterModal(false);
      } else {
        toastify("An error occurred", "error");
      }
    } catch (err) {
      toastify("An error occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (rosterId, scheduleId, newStatus) => {
    try {
      const response = await makeRequest2(
        `/api/duty_roster/${selectedFacilityId}/${rosterId}/schedule/${scheduleId}`,
        "PATCH",
        { status: newStatus }
      );

      if (response.success) {
        toastify("Status updated successfully", "success");
        fetchDutyRoster(selectedFacilityId);
      } else {
        toastify("Failed to update status", "error");
      }
    } catch (err) {
      toastify("Failed to update status", "error");
    }
  };

  const handleEmployeeDelete = async (employeeId) => {
    confirmDialog({
      message: "Are you sure you want to delete this employee?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        const response = await makeRequest2(
          `/api/employees/${selectedFacilityId}/${employeeId}`,
          "DELETE",
          null
        );

        if (response.success) {
          toastify("Staff deleted successfully", "success");
          fetchEmployees(selectedFacilityId);
        } else {
          toastify("Failed to delete staff", "error");
        }
      },
    });
  };

  const handleDutyRosterDelete = async (rosterId) => {
    confirmDialog({
      message: "Are you sure you want to delete this duty roster entry?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/duty_roster/${selectedFacilityId}/${rosterId}`,
            "DELETE",
            null
          );

          if (response.success) {
            toastify("Duty roster entry deleted successfully", "success");
            fetchDutyRoster(selectedFacilityId);
          } else {
            toastify("Failed to delte duty roster entry", "error");
          }
        } catch (err) {
          toastify("Failed to delte duty roster entry", "error");
        }
      },
    });
  };

  const employeeActionTemplate = (rowData) => (
    <div>
      <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info p-mr-2"
        onClick={() => handleEmployeeModalOpen(rowData)}
        tooltip="Edit"
      ></Button>
      <Button
        icon="ti ti-trash"
        className="p-button-rounded p-button-danger mr-2"
        onClick={() => handleEmployeeDelete(rowData._id)}
        tooltip="Delete"
      ></Button>
    </div>
  );

  const dutyRosterActionTemplate = (rowData) => (
    <div>
      <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info p-mr-2"
        onClick={() => handleDutyRosterModalOpen(rowData)}
        tooltip="Edit"
      ></Button>
      <Button
        icon="ti ti-trash"
        className="p-button-rounded p-button-danger mr-2"
        onClick={() => handleDutyRosterDelete(rowData._id)}
        tooltip="Delete"
      ></Button>
    </div>
  );

  const renderHeader = () => (
    <div className="d-flex justify-content-between align-items-center">
      <input
        type="text"
        className="form-control w-25"
        placeholder={`Search ${
          activeTab === "employees" ? "employees" : "duty roster"
        }...`}
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
      <button
        className="btn btn-primary"
        onClick={() =>
          activeTab === "employees"
            ? handleEmployeeModalOpen()
            : handleDutyRosterModalOpen()
        }
      >
        {activeTab === 'employees' ? 'Add New Staff' : 'Add Duty Roster Entry'}
      </button>
    </div>
  );

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={""}>Staff Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activeTab === "employees" ? "active" : ""
                                }`}
                                onClick={() => setActiveTab("employees")}
                                href="#employees"
                              >
                                <i className="ti ti-users me-2"></i>
                                Staffs
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activeTab === "duty-roster" ? "active" : ""
                                }`}
                                onClick={() => setActiveTab("duty-roster")}
                                href="#duty-roster"
                              >
                                <i className="ti ti-calendar me-2"></i>
                                Duty Roster
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activeTab === "duty-stats" ? "active" : ""
                                }`}
                                onClick={() => setActiveTab("duty-stats")}
                                href="#duty-stats"
                              >
                                <i className="ti ti-chart-bar me-2"></i>
                                Duty Roster Calendar
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="tab-content">
                        <div
                          className={`tab-pane ${
                            activeTab === "employees" ? "show active" : ""
                          }`}
                          id="employees"
                          role="tabpanel"
                          aria-labelledby="employees-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={filteredEmployees}
                                      emptyMessage="No employees found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      loading={loading}
                                      header={renderHeader}
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="name"
                                        header="Staff Name"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="contact"
                                        header="Contact Email"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="phoneNumber"
                                        header="Phone Number"
                                        sortable
                                      ></Column>
                                      <Column
                                        body={employeeActionTemplate}
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane ${
                            activeTab === "duty-roster" ? "show active" : ""
                          }`}
                          id="duty-roster"
                          role="tabpanel"
                          aria-labelledby="duty-roster-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={filteredDutyRoster}
                                      emptyMessage="No duty roster entries found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      loading={loading}
                                      header={renderHeader}
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="name"
                                        header="Staff Name"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="activity"
                                        header="Activity"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="day"
                                        header="Day"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="time"
                                        header="Time"
                                        sortable
                                      ></Column>
                                      <Column
                                        body={dutyRosterActionTemplate}
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane ${
                            activeTab === "duty-stats" ? "show active" : ""
                          }`}
                          id="duty-stats"
                          role="tabpanel"
                          aria-labelledby="duty-stats-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div
                                className="card"
                                style={{ margin: 0, padding: 0 }}
                              >
                                <div className="card-body p-0">
                                  <DutyRosterCalendar dutyRoster={dutyRoster} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Employee Modal */}
      {showEmployeeModal && (
        <div
          className="modal d-block"
          tabIndex="-1"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedEmployee ? "Edit Employee" : "Add New Employee"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowEmployeeModal(false)}
                ></button>
              </div>
              <form onSubmit={handleEmployeeSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Staff Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={employeeFormData.name}
                      onChange={handleEmployeeInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Contact Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="contact"
                      value={employeeFormData.contact}
                      onChange={handleEmployeeInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phoneNumber"
                      value={employeeFormData.phoneNumber}
                      onChange={handleEmployeeInputChange}
                      placeholder="e.g., 0700000000 or +25470000000"
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setShowEmployeeModal(false)}
                  >
                    Clear
                  </Button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    ) : null}
                    {selectedEmployee ? "Update Employee" : "Add Employee"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Duty Roster Modal */}
      {showDutyRosterModal && (
        <div
          className="modal d-block"
          tabIndex="-1"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedDutyRoster
                    ? "Edit Duty Roster Entry"
                    : "Add New Duty Roster Entry"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDutyRosterModal(false)}
                ></button>
              </div>
              <form onSubmit={handleDutyRosterSubmit}>
                <div className="modal-body">
                  {/* Staff Selection */}
                  <div className="mb-3">
                    <label className="form-label">Select Staff</label>
                    <select
                      name="employeeId"
                      value={dutyRosterFormData.employeeId || ""}
                      onChange={(e) =>
                        handleDutyRosterEmployeeSelect(e.target.value)
                      }
                      className="form-control"
                      required
                    >
                      <option value="">Select Staff</option>
                      {employees.map((employee) => (
                        <option key={employee._id} value={employee._id}>
                          {employee.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Staff Name */}
                  <div className="mb-3">
                    <label className="form-label">Staff Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={dutyRosterFormData.name}
                      readOnly
                    />
                  </div>

                  {/* Phone Number */}
                  <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      value={dutyRosterFormData.phone}
                      readOnly
                    />
                  </div>

                  {/* Activity */}
                  <div className="mb-3">
                    <label className="form-label">Activity</label>
                    <input
                      type="text"
                      className="form-control"
                      name="activity"
                      value={dutyRosterFormData.activity}
                      onChange={(e) =>
                        setDutyRosterFormData((prev) => ({
                          ...prev,
                          activity: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>

                  {/* Time */}
                  <div className="form-group mb-4">
                    <label htmlFor="time">Time</label>
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      name="time"
                      value={dutyRosterFormData.time}
                      onChange={(e) =>
                        setDutyRosterFormData((prev) => ({
                          ...prev,
                          time: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>

                  {/* Date Selection */}
                  <div className="mb-3">
                    <label className="form-label">Select Dates</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const dateExists = selectedDates.some(
                          (date) =>
                            date.toDateString() === selectedDate.toDateString()
                        );
                        if (!dateExists) {
                          setSelectedDates([...selectedDates, selectedDate]);
                        }
                      }}
                    />

                    {/* Selected Dates Display */}
                    <div className="selected-dates mt-3">
                      <label className="form-label">Selected Dates:</label>
                      {selectedDates.length === 0 && (
                        <p className="text-muted">No dates selected</p>
                      )}
                      {selectedDates.map((date, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-2 p-2 border rounded"
                        >
                          <span className="flex-grow-1">
                            {new Date(date).toLocaleDateString()}
                          </span>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger ms-2"
                            onClick={() => {
                              setSelectedDates(
                                selectedDates.filter((_, i) => i !== index)
                              );
                            }}
                          >
                            <i className="ti ti-trash"></i> Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <Button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      setShowDutyRosterModal(false);
                      setSelectedDates([]);
                    }}
                  >
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      loading ||
                      !dutyRosterFormData.employeeId ||
                      selectedDates.length === 0 ||
                      !dutyRosterFormData.activity ||
                      !dutyRosterFormData.time
                    }
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    ) : null}
                    {selectedDutyRoster ? "Update" : "Add"} Duty Roster
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default EmployeeManagement;
