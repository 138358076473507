import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { makeRequest2 } from '../../../../../../utils/makeRequest';
import { toastify } from '../../../../../../utils/toast';
import { useFacilityStore } from '../../../../../../app/z-store/store';
import { analog_base_url } from '../../../../../../utils/urls';

const DashboardTab = () => {
  const [meterCounts, setMeterCounts] = useState({ open: 0, closed: 0, total: 0 });
  const [meterData, setMeterData] = useState({ chartData: [], meters: [] });
  const [monthlyConsumption, setMonthlyConsumption] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  useEffect(() => {
    const fetchMeterData = async () => {
      if (!selectedFacilityId) return;
      setIsLoading(true);
      try {
        const countResponse = await makeRequest2(
          `${analog_base_url}/count/${selectedFacilityId}`,
          'GET'
        );
        if (countResponse?.data?.data) {
          const { open, closed, total } = countResponse.data.data;
          setMeterCounts({
            open: open || 0,
            closed: closed || 0,
            total: total || 0,
          });
        }
        const consumptionResponse = await makeRequest2(
          `${analog_base_url}/consumption/${selectedFacilityId}/monthly`,
          'GET'
        );
        if (consumptionResponse?.data?.data?.total !== undefined) {
          setMonthlyConsumption(consumptionResponse.data.data.total);
        } else if (consumptionResponse?.data?.total !== undefined) {
          setMonthlyConsumption(consumptionResponse.data.total);
        } else {
          setMonthlyConsumption(0);
        }
        const metersResponse = await makeRequest2(
          `${analog_base_url}/details/${selectedFacilityId}`,
          'GET'
        );
        if (metersResponse?.data?.meters) {
          const meters = metersResponse.data.meters;
          const chartData = meters.map((meter) => ({
            meterNumber: meter.serialNumber || `Meter ${meter.id}`,
            consumption: meter.totalConsumption || 0,
            id: meter.id,
          }));
          setMeterData({
            chartData,
            meters,
          });
        }
      } catch (error) {
        toastify('Error fetching meter data', 'error');
        console.error('Error fetching meter data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMeterData();
  }, [selectedFacilityId]);

  const renderTooltip = (props) => {
    if (!props.active || !props.payload || !props.payload.length) return null;
    const { payload } = props;
    return (
      <div className="custom-tooltip bg-white p-2 border rounded shadow">
        <p className="mb-1">
          <strong>{payload[0].payload.meterNumber}</strong>
        </p>
        <p className="mb-0">
          Consumption: <strong>{payload[0].value} m³</strong>
        </p>
      </div>
    );
  };

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
          style={{ fontSize: '11px' }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className="tab-pane fade show active" role="tabpanel">
      <div className="row">
        <div className="col-md-3">
          <div className="card bg-light">
            <div className="card-body">
              <h6 className="text-primary">Open Meters</h6>
              <h3>{meterCounts.open}</h3>
              <p className="text-muted mb-0">Available for readings</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card bg-light">
            <div className="card-body">
              <h6 className="text-danger">Closed Meters</h6>
              <h3>{meterCounts.closed}</h3>
              <p className="text-muted mb-0">Disconnected meters</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card bg-light">
            <div className="card-body">
              <h6 className="text-info">Total Meters</h6>
              <h3>{meterCounts.total}</h3>
              <p className="text-muted mb-0">Installed in facility</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card bg-light">
            <div className="card-body">
              <h6 className="text-success">Monthly/Bulk Meter Consumption</h6>
              <h3>{(monthlyConsumption || 0).toFixed(2)} m³</h3>
              <p className="text-muted mb-0">Previous month usage</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Analog Meter Monthly Consumption (m³)</h5>
              <p className="text-muted">
                Total consumption for each meter in the previous month
              </p>
              {isLoading ? (
                <div className="text-center py-5">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : meterData.chartData && meterData.chartData.length > 0 ? (
                <div style={{ width: '100%', height: 500 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={meterData.chartData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 100 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="meterNumber"
                        tick={<CustomXAxisTick />}
                        height={80}
                        interval={0}
                      />
                      <YAxis domain={[0, 'auto']} />
                      <Tooltip content={renderTooltip} />
                      <Legend />
                      <Bar
                        dataKey="consumption"
                        name="Monthly Consumption (m³)"
                        fill="#8884d8"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="alert alert-info">No meter data available</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Analog Meter Details</h5>
              {isLoading ? (
                <div className="text-center py-3">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : meterData.meters && meterData.meters.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead className="table-light">
                      <tr>
                        <th>Serial Number</th>
                        <th>Status</th>
                        <th>Last Reading</th>
                        <th>Last Reading Date</th>
                        <th>Total Consumption</th>
                      </tr>
                    </thead>
                    <tbody>
                      {meterData.meters.map((meter) => (
                        <tr key={meter.id}>
                          <td>{meter.serialNumber || 'N/A'}</td>
                          <td>
                            <span className={`badge bg-${meter.status === 'OPEN' ? 'success' : 'danger'}`}>
                              {meter.status || 'N/A'}
                            </span>
                          </td>
                          <td>{meter.lastReading || '0.00'} m³</td>
                          <td>{meter.lastReadingDate || 'No readings'}</td>
                          <td>{meter.totalConsumption || '0.00'} m³</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="alert alert-info">No meters available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTab;
