import MeterTypes from "./meters_types";
import PowerMeterManagement from "./meter_management/power_meters/power_meters";
import WaterMeterManagement from "./meter_management/water_meters/water_meters";
import BillingManagement from "./billing_management/billing";
import UtilityReports from "./reports/report";
import ViewWaterMeter from "./meter_management/water_meters/view_water_meter";
import ViewPowerMeter from "./meter_management/power_meters/view_power_meter";
import ViewWaterCustomerAccount from "./meter_management/water_meters/view_customer_account";
import ViewPowerCustomerAccount from "./billing_management/view_power_customer_account";
import WaterMetersDashboard from "./meter_management/water_meters/tabs/dashboardTab"
import AddAnalogMeter from "./meter_management/water_meters/tabs/add_analog_meter";
import AnalogMeterHistoryTab from "./meter_management/water_meters/tabs/AnalogMeterHistoryTab";
import ViewAnalogMeter from "./meter_management/water_meters/ViewAnalogMeter";
import AnalogMeterManagement from "./meter_management/water_meters/tabs/AnalogMetersManagementTab";
import WaterInvoicePage from "./invoice_management/invoice_page";

const utilityRouter = [
  {
    path: "/facility/utility_management/meter_types",
    element: <MeterTypes />,
  },
  {
    path: "/facility/utility_management/meter_management/power_meters",
    element: <PowerMeterManagement />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters",
    element: <WaterMeterManagement />,
  },
  {
    path: "/facility/utility_management/meter_management/view_water_meter/:meterId",
    element: <ViewWaterMeter />,
  },
  {
    path: "/facility/utility_management/meter_management/view_power_meter",
    element: <ViewPowerMeter />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters/view_customer_account",
    element: <ViewWaterCustomerAccount />,
  },
  {
    path: "/facility/utility_management/meter_management/view_power_customer_account",
    element: <ViewPowerCustomerAccount />,
  },
  {
    path: "/facility/utility_management/billing",
    element: <BillingManagement />,
  },
  {
    path: "/facility/utility_management/reports",
    element: <UtilityReports />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters/dashboardTab",
    element: <WaterMetersDashboard />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters/addAnalogMeter",
    element: <AddAnalogMeter />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters/AnalogMeterHistoryTab",
    element: <AnalogMeterHistoryTab />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters/tabs/AnalogMetersManagementTab",
    element: <AnalogMeterManagement />,
  },
  {
    path: "/facility/utility_management/meter_management/water_meters/ViewAnalogMeter",
    element: <ViewAnalogMeter />,
  },
  {
    path: "/facility/utility_management/invoice_management/invoice_page",
    element: <WaterInvoicePage />,
  },
];

export default utilityRouter;
