import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { analog_base_url, customer_account_base_url } from "../../../../../utils/urls";
import { toastify } from '../../../../../utils/toast';
import Layout from '../../../component/layout';
import { getItem, clearStorage } from "../../../../../utils/localStorage";
import { useFacilityStore } from '../../../../../app/z-store/store';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function ViewAnalogWaterMeter() {
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);
    const navigate = useNavigate();
    const location = useLocation();
    const { meterId: paramMeterId } = useParams();
    const stateMeterId = location.state?.meterId;
    const meterId = paramMeterId || stateMeterId;

    const [meterDetails, setMeterDetails] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [readings, setReadings] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState('');
    const [activeSection, setActiveSection] = useState('meter-info');

    const [customerAccounts, setCustomerAccounts] = useState([]);

    const [showAssignCustomerModal, setShowAssignCustomerModal] = useState(false);
    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [selectedCustomerForAssignment, setSelectedCustomerForAssignment] = useState('');

    const [fullname, setFullname] = useState('');
    const [role, setRole] = useState('');

    // Modal states
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddReadingModal, setShowAddReadingModal] = useState(false);
    const [editForm, setEditForm] = useState({
        meterNumber: '',
        accountNumber: ''
    });
    const [newReading, setNewReading] = useState('');
    const [newReadBy, setNewReadBy] = useState('');

    useEffect(() => {
        if (meterId) {
            const today = new Date().toISOString().split('T')[0]; // "YYYY-MM-DD"
            setSelectedDate(today);
            fetchMeterDetails();
        } else {
            toastify('Meter ID not provided', 'error');
            navigate('/facility/utility_management/meter_management/water_meters');
        }
    }, [meterId]);

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const currentUserValue = await getItem("APPUSER");
                let currentUser;
                // Check if the value needs to be parsed
                if (typeof currentUserValue === "string") {
                    try {
                        currentUser = JSON.parse(currentUserValue);
                    } catch (err) {
                        console.error("Error parsing APPUSER:", err);
                    }
                } else {
                    currentUser = currentUserValue; // Assume it's already an object
                }

                // Safely access and set user details
                if (currentUser?.user) {
                    const fullName = currentUser.user.fullName;
                    const role = currentUser.user.role;
                    setFullname(fullName);
                    setRole(role);
                } else {
                    console.error("Invalid user structure:", currentUser);
                }
            } catch (err) {
                console.log(err.message);
            }
        };

        getCurrentUser();
    }, []);

    useEffect(() => {
        // Populate edit form when meterDetails are fetched
        if (meterDetails) {
            setEditForm({
                meterNumber: meterDetails.meterNumber || '',
                accountNumber: meterDetails.accountNumber || ''
            });
        }
    }, [meterDetails]);

    // Fetch available customers to assign
    const fetchAvailableCustomers = async () => {
        try {
            const response = await makeRequest2(
                `/api/app/customer_management/get_customers/${selectedFacilityId}`,
                'GET'
            );

            if (response.success) {
                const customers = response.data?.customers || response.data || [];

                // Filter out customers who are not already assigned to this meter
                const unassignedCustomers = customers.filter(customer =>
                    !customerAccounts.some(account => account.customerId === customer._id)
                );

                setAvailableCustomers(unassignedCustomers);
                setShowAssignCustomerModal(true);
            } else {
                toastify('Failed to fetch customers', 'error');
            }
        } catch (error) {
            console.error('Error fetching customers:', error);
            toastify('Error fetching customers', 'error');
        }
    };

    // Fetch meter details using the meterId
    const fetchMeterDetails = async () => {
        try {
            setLoading(true);
            const response = await makeRequest2(
                `${analog_base_url}/details/${selectedFacilityId}/${meterId}`,
                'GET'
            );

            if (response.success) {
                const meterData = response.data.meter;
                setMeterDetails(meterData);

                // Extract reading history if available
                if (meterData.readingHistory && meterData.readingHistory.length > 0) {
                    const formattedReadings = meterData.readingHistory.map((reading, index) => ({
                        id: index + 1,
                        date: new Date(reading.readingDate).toISOString().split('T')[0],
                        reading: reading.currentReading.toFixed(2),
                        previous: reading.previousReading.toFixed(2),
                        recordedBy: reading.readBy || 'Property Manager'
                    }));
                    setReadings(formattedReadings.reverse());
                }

                // Process customer info
                if (meterData.meterNumber) {
                    fetchCustomerAccountsByMeterNumber(meterData.meterNumber);
                }

                // Fetch transactions if needed
                fetchTransactions(meterData.customerId);
            } else {
                toastify('Failed to fetch meter details', 'error');
            }
        } catch (error) {
            console.error('Error fetching meter details:', error);
            toastify('Error fetching meter details', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Updated function to fetch customer accounts by meter number
    const fetchCustomerAccountsByMeterNumber = async (meterNumber) => {
        if (!meterNumber) return;
        try {
            const response = await makeRequest2(
                `/api/app/customer-account/get_accounts_by_meter/${selectedFacilityId}/${meterNumber}`,
                'GET'
            );

            // Check for response.data.data which is where the accounts array is
            if (response.data?.data && response.data.data.length > 0) {
                console.log("Customer accounts received:", response.data.data);

                // Sort accounts to ensure Active accounts appear first
                const sortedAccounts = [...response.data.data].sort((a, b) => {
                    // Active accounts first
                    if (a.status === 'Active' && b.status !== 'Active') return -1;
                    if (a.status !== 'Active' && b.status === 'Active') return 1;

                    // Then sort by most recently updated
                    return new Date(b.updatedAt || b.created_on) - new Date(a.updatedAt || a.created_on);
                });

                setCustomerAccounts(sortedAccounts);

                // Set a single customer for backward compatibility
                const activeAccount = sortedAccounts.find(acc => acc.status === 'Active') || sortedAccounts[0];
                setCustomer({
                    customerId: activeAccount.customerId || 'N/A',
                    name: activeAccount.customerName || 'N/A',
                    contactNumber: activeAccount.phoneNumber || 'N/A',
                    accountType: activeAccount.payment_type || 'N/A',
                    status: activeAccount.status || 'N/A',
                    updatedAt: activeAccount.updatedAt || activeAccount.created_on || 'N/A'
                });
            } else {
                console.warn('No customer accounts found for this meter:', response);
                setCustomerAccounts([]);
                setCustomer(null);
            }
        } catch (error) {
            console.error('Error fetching customer accounts:', error);
            setCustomerAccounts([]);
            setCustomer(null);
        }
    };

    // Fetch transactions related to this meter
    const fetchTransactions = async (customerId) => {
        if (!customerId) return;
        try {
            // Implement transactions fetch if endpoint is available
            setTransactions([]);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setTransactions([]);
        }
    };

    const handleDateChange = (value) => {
        setSelectedDate(value);
    };

    const handleFilter = () => {
        const filteredReadings = meterDetails?.readingHistory?.filter(reading => {
            const readingDate = new Date(reading.readingDate).toISOString().split('T')[0];
            return readingDate <= selectedDate;
        }) || [];

        const formattedReadings = filteredReadings.map((reading, index) => ({
            id: index + 1,
            date: new Date(reading.readingDate).toISOString().split('T')[0],
            reading: reading.currentReading.toFixed(2),
            previous: reading.previousReading.toFixed(2),
            recordedBy: reading.readBy || 'System'
        }));

        setReadings(formattedReadings.reverse());
    };

    // Handle submission of edited meter details
    const handleEditSubmit = async () => {
        try {
            setLoading(true);
            const updateData = { ...editForm };
            const response = await makeRequest2(
                `${analog_base_url}/${selectedFacilityId}/${meterId}`,
                'PUT',
                updateData
            );
            if (response.success) {
                toastify('Meter updated successfully', 'success');
                setShowEditModal(false);
                fetchMeterDetails();
            } else {
                toastify('Failed to update meter', 'error');
            }
        } catch (error) {
            console.error('Error updating meter:', error);
            toastify('Error updating meter', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Handle submission of a new reading
    const handleNewReadingSubmit = async () => {
        try {
            setLoading(true);
            const updateData = {
                currentReading: parseFloat(newReading),
                readBy: fullname || 'Property Manager'
            };
            const response = await makeRequest2(
                `${analog_base_url}/${selectedFacilityId}/${meterId}`,
                'PUT',
                updateData
            );
            if (response.success) {
                toastify('New reading recorded successfully', 'success');
                setShowAddReadingModal(false);
                setNewReading('');
                fetchMeterDetails();
            } else {
                toastify('Failed to record new reading', 'error');
            }
        } catch (error) {
            console.error('Error recording new reading:', error);
            toastify('Error recording new reading', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Toggle the meter status between open and closed
    const handleToggleMeterStatus = async () => {
        try {
            setLoading(true);
            const newStatus = meterDetails?.status === 'closed' ? 'open' : 'closed';
            const updateData = { status: newStatus };
            const response = await makeRequest2(
                `${analog_base_url}/${selectedFacilityId}/${meterId}`,
                'PUT',
                updateData
            );
            if (response.success) {
                toastify(`Meter status updated to ${newStatus}`, 'success');
                fetchMeterDetails();
            } else {
                toastify('Failed to update meter status', 'error');
            }
        } catch (error) {
            console.error('Error toggling meter status:', error);
            toastify('Error updating meter status', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Add this new function to your ViewAnalogWaterMeter component

    const handleGenerateBill = async () => {
        try {
            if (!meterDetails) {
                toastify('No meter details available', 'error');
                return;
            }

            // Create current year-month in YYYY-MM format
            const now = new Date();
            const yearMonth = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;

            // Get the current meter reading
            const meterReading = meterDetails.currentReading;

            // Check that we have all required data
            if (!meterDetails.meterNumber || !meterReading) {
                toastify('Missing meter number or reading', 'error');
                return;
            }

            setLoading(true);

            // Create payload according to backend requirements
            const payload = {
                meterNumber: meterDetails.meterNumber,
                yearMonth: yearMonth,
                meterReading: meterReading
            };

            // Make API call to generate bill
            const response = await makeRequest2(
                `${analog_base_url}/meter/billing/${selectedFacilityId}`,
                'POST',
                payload
            );

            if (response.success) {
                toastify('Bill and invoice generated successfully', 'success');
                // navigate(`/facility/invoices/view/${response.invoice._id}`);
            } else {
                toastify(response.error || 'Failed to generate bill', 'error');
            }
        } catch (error) {
            console.error('Error generating bill:', error);
            toastify('Error generating bill', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Stub functions for customer actions (to be implemented)
    const handleAssignCustomer = async () => {
        if (!selectedCustomerForAssignment) {
            toastify('Please select a customer', 'error');
            return;
        }

        try {
            // Find the selected customer
            const selectedCustomer = availableCustomers.find(
                customer => customer._id === selectedCustomerForAssignment
            );

            if (!selectedCustomer) {
                toastify('Customer not found', 'error');
                return;
            }

            // Prepare account data
            const accountData = {
                facilityId: selectedFacilityId,
                account_no: meterDetails.accountNumber,
                customerId: selectedCustomer._id,
                customerName: selectedCustomer.fullName ||
                    `${selectedCustomer.firstName || ''} ${selectedCustomer.lastName || ''}`.trim(),
                phoneNumber: selectedCustomer.phoneNumber || 'N/A',
                meterNumber: meterDetails.meterNumber,
                unitId: meterDetails.unitId,
                payment_type: "Postpaid",
                previousReading: meterDetails.previousReading || 0,
                currentReading: meterDetails.currentReading || 0,
                meter_id: meterDetails._id,
                status: 'Active'
            };

            // Create customer account
            const accountResponse = await makeRequest2(
                `${customer_account_base_url}/add`,
                'POST',
                accountData
            );

            if (accountResponse.success) {
                // Update meter with the new customer ID
                const meterUpdateResponse = await makeRequest2(
                    `${analog_base_url}/${selectedFacilityId}/${meterId}`,
                    'PUT',
                    {
                        customerId: selectedCustomer._id,
                        customerName: accountData.customerName
                    }
                );

                if (meterUpdateResponse.success) {
                    toastify('Customer assigned successfully', 'success');

                    // Refresh customer accounts
                    fetchCustomerAccountsByMeterNumber(meterDetails.meterNumber);

                    // Close the modal
                    setShowAssignCustomerModal(false);
                    setSelectedCustomerForAssignment('');

                    // Refetch meter details to ensure all data is up to date
                    fetchMeterDetails();
                } else {
                    toastify('Customer account created, but failed to update meter', 'warning');
                }
            } else {
                toastify(accountResponse.error || 'Failed to assign customer', 'error');
            }
        } catch (error) {
            console.error('Error assigning customer:', error);
            toastify('Error assigning customer', 'error');
        }
    };

    const exportToPDF = () => {
        // Create a new jsPDF instance
        const doc = new jsPDF();

        // Add title and meter information
        doc.setFontSize(18);
        doc.text('Water Meter Reading History', 14, 22);

        doc.setFontSize(10);
        doc.text(`Meter Number: ${meterDetails?.meterNumber || 'N/A'}`, 14, 30);
        doc.text(`Unit: ${meterDetails?.UnitInfo?.name || 'N/A'}`, 14, 36);
        doc.text(`Current Reading: ${meterDetails?.currentReading?.toFixed(2) || '0.00'} m³`, 14, 42);
        doc.text(`Date of Export: ${new Date().toLocaleDateString()}`, 14, 48);

        // Prepare data for table
        const tableColumn = ["#", "Date", "Reading (m³)", "Previous Reading (m³)", "Recorded By"];
        const tableRows = readings.map((reading, index) => [
            index + 1,
            reading.date,
            reading.reading,
            reading.previous,
            reading.recordedBy
        ]);

        // Add the table
        doc.autoTable({
            startY: 56,
            head: [tableColumn],
            body: tableRows,
            theme: 'striped',
            styles: { fontSize: 8 },
            headStyles: { fillColor: [40, 80, 120] }
        });

        // Save the PDF
        doc.save(`Water_Meter_Readings_${meterDetails?.meterNumber}_${new Date().toISOString().split('T')[0]}.pdf`);
    };

    const exportToCSV = () => {
        // Prepare CSV content
        const csvHeader = "No,Date,Reading (m³),Previous Reading (m³),Recorded By\n";
        const csvRows = readings.map((reading, index) =>
            `${index + 1},${reading.date},${reading.reading},${reading.previous},${reading.recordedBy}`
        ).join('\n');

        const csvContent = csvHeader + csvRows;

        // Create a Blob and download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        link.setAttribute("href", url);
        link.setAttribute("download", `Water_Meter_Readings_${meterDetails?.meterNumber}_${new Date().toISOString().split('T')[0]}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportHandler = (type) => {
        switch (type) {
            case 'pdf':
                exportToPDF();
                break;
            case 'csv':
                exportToCSV();
                break;
            default:
                toastify('Invalid export type', 'error');
        }
    };

    const handleActivateCustomer = async (accountId) => {
        try {
            // Ensure we have a facilityId
            if (!selectedFacilityId) {
                toastify('Facility ID is missing', 'error');
                return;
            }
    
            setLoading(true);
            const response = await makeRequest2(
                `${customer_account_base_url}/activate/${selectedFacilityId}/${meterId}`,
                'PUT',
                { accountId }
            );
    
            if (response.success) {
                toastify('Customer account activated successfully', 'success');
                
                // Refresh customer accounts
                fetchCustomerAccountsByMeterNumber(meterDetails.meterNumber);
    
                // Refetch meter details to ensure all data is up to date
                fetchMeterDetails();
            } else {
                toastify(response.error || 'Failed to activate account', 'error');
            }
        } catch (error) {
            console.error('Error activating customer:', error);
            toastify('Error activating customer', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDeactivateCustomer = async () => {
        if (!customer || customerAccounts.length === 0) {
            toastify('No customer account to deactivate', 'error');
            return;
        }

        try {
            // Find the active account to deactivate
            const activeAccount = customerAccounts.find(acc => acc.status === 'Active');

            if (!activeAccount) {
                toastify('No active account found', 'error');
                return;
            }

            // Deactivate the customer account
            const accountDeactivationResponse = await makeRequest2(
                `${customer_account_base_url}/deactivate/${meterId}`,
                'PUT',
                {
                    account_no: activeAccount.account_no,
                    status: 'Inactive'
                }
            );

            if (accountDeactivationResponse.success) {
                // Update meter to remove customer details
                const meterUpdateResponse = await makeRequest2(
                    `${analog_base_url}/${selectedFacilityId}/${meterId}`,
                    'PUT',
                    {
                        customerId: null,
                        customerName: null
                    }
                );

                if (meterUpdateResponse.success) {
                    toastify('Customer account deactivated successfully', 'success');

                    // Refresh customer accounts
                    fetchCustomerAccountsByMeterNumber(meterDetails.meterNumber);

                    // Refetch meter details to ensure all data is up to date
                    fetchMeterDetails();
                } else {
                    toastify('Account deactivated, but failed to update meter', 'warning');
                }
            } else {
                toastify(accountDeactivationResponse.error || 'Failed to deactivate account', 'error');
            }
        } catch (error) {
            console.error('Error deactivating customer:', error);
            toastify('Error deactivating customer', 'error');
        }
    };

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb mb-3">
                                <li className="breadcrumb-item">
                                    <Link to="/facility">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/facility/utility_management/meter_management/water_meters">Analog Meters Management</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>Water Meters</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div>
                                <Link
                                    to="/facility/utility_management/meter_management/water_meters"
                                    className="btn btn-sm btn-primary"
                                    state={{ activeTab: "analog-meters" }}
                                >
                                    <i className="ti ti-arrow-narrow-left"></i> Back
                                </Link>
                                <span className="ms-3 h5 mb-0">Analog Water Meter Details</span>
                            </div>
                        </div>
                    </div>

                    {/* Quick Actions Card */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0"><i className="ti ti-tool me-2"></i>Quick Actions</h5>
                        </div>
                        <div className="card-body">
                            <div className="d-flex flex-wrap gap-2">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setShowAddReadingModal(true)}
                                >
                                    <i className="ti ti-plus me-1"></i> Record New Reading
                                </button>
                                <button
                                    className="btn btn-success"
                                    onClick={handleGenerateBill}
                                >
                                    <i className="ti ti-receipt me-1"></i> Generate Bill
                                </button>
                                <button
                                    className="btn btn-info"
                                    onClick={() => setShowEditModal(true)}
                                >
                                    <i className="ti ti-pencil me-1"></i> Edit
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={handleToggleMeterStatus}
                                >
                                    <i className="ti ti-trash me-1"></i> {meterDetails?.status === 'closed' ? 'Open Meter' : 'Close Meter'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Horizontal Tabs */}
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'meter-info' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('meter-info')}
                                        type="button"
                                    >
                                        <i className="ti ti-dashboard me-2"></i>Meter Info
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'customer-info' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('customer-info')}
                                        type="button"
                                    >
                                        <i className="ti ti-users me-2"></i>Customer Info
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'readings' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('readings')}
                                        type="button"
                                    >
                                        <i className="ti ti-list-numbers me-2"></i>Reading History
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'transactions' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('transactions')}
                                        type="button"
                                    >
                                        <i className="ti ti-cash me-2"></i>Transactions
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeSection === 'consumption' ? 'active' : ''}`}
                                        onClick={() => setActiveSection('consumption')}
                                        type="button"
                                    >
                                        <i className="ti ti-list-numbers me-2"></i>Consumption History
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {loading ? (
                        <div className="card">
                            <div className="card-body text-center py-5">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-3">Loading meter information...</p>
                            </div>
                        </div>
                    ) : (
                        <div className="tab-content mt-4">
                            {/* Meter Information Section */}
                            {activeSection === 'meter-info' && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-bold">Serial Number:</td>
                                                            <td>{meterDetails?.meterNumber || 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Account Number:</td>
                                                            <td>{meterDetails?.accountNumber || 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Installation Date:</td>
                                                            <td>{meterDetails?.createdAt
                                                                ? new Date(meterDetails.createdAt).toLocaleDateString()
                                                                : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Status:</td>
                                                            <td>
                                                                <span className={`badge ${meterDetails?.status === 'open' ? 'bg-success' :
                                                                    meterDetails?.status === 'maintenance' ? 'bg-warning' :
                                                                        'bg-danger'}`}>
                                                                    {meterDetails?.status || 'N/A'}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-6">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-bold">Unit:</td>
                                                            <td>{meterDetails?.UnitInfo
                                                                ? (meterDetails.UnitInfo.name || meterDetails.UnitInfo.unitNumber)
                                                                : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Initial Reading:</td>
                                                            <td>{meterDetails?.initialReading?.toFixed(2) || '0.00'} m³</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Previous Reading:</td>
                                                            <td>{meterDetails?.previousReading?.toFixed(2) || '0.00'} m³</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-bold">Current Reading:</td>
                                                            <td>{meterDetails?.currentReading?.toFixed(2) || '0.00'} m³ (
                                                                {meterDetails?.lastReadingDate
                                                                    ? new Date(meterDetails.lastReadingDate).toLocaleDateString()
                                                                    : 'N/A'})
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Customer Information Section */}
                            {activeSection === 'customer-info' && (
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Customer Information</h5>
                                        <div>
                                            <button
                                                className="btn btn-primary me-2"
                                                onClick={fetchAvailableCustomers}
                                            >
                                                Assign Account
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={handleDeactivateCustomer}
                                                disabled={!customer || customerAccounts.length === 0}
                                            >
                                                Deactivate Account
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {customerAccounts && customerAccounts.length > 0 ? (
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Customer Name</th>
                                                            <th>Phone Number</th>
                                                            <th>Unit</th>
                                                            <th>Payment Type</th>
                                                            <th>Status</th>
                                                            <th>Last Updated</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {customerAccounts.map((account, index) => (
                                                            <tr key={account._id || index} className={account.status === 'Active' ? 'table-active' : ''}>
                                                                <td>{account.customerName || 'N/A'}</td>
                                                                <td>{account.phoneNumber || 'N/A'}</td>
                                                                <td>{meterDetails?.UnitInfo
                                                                    ? (meterDetails.UnitInfo.name || meterDetails.UnitInfo.unitNumber)
                                                                    : 'N/A'}</td>
                                                                <td>{account.payment_type || 'N/A'}</td>
                                                                <td>
                                                                    <span className={`badge ${account.status === 'Active' ? 'bg-success' :
                                                                        account.status === 'Inactive' ? 'bg-danger' : 'bg-warning'}`}>
                                                                        {account.status || 'N/A'}
                                                                    </span>
                                                                </td>
                                                                <td>{account.updatedAt ? new Date(account.updatedAt).toLocaleDateString() :
                                                                    account.created_on ? new Date(account.created_on).toLocaleDateString() : 'N/A'}</td>
                                                                <td>
                                                                    {account.status !== 'Active' && (
                                                                        <button
                                                                            className="btn btn-sm btn-success me-2"
                                                                            onClick={() => handleActivateCustomer(account._id)}
                                                                        >
                                                                            Activate
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="text-center py-4">
                                                <p>No customer accounts assigned to this meter.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Reading History Section */}
                            {activeSection === 'readings' && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text">Date</span>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={selectedDate}
                                                        onChange={(e) => handleDateChange(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={handleFilter}
                                                    >
                                                        Filter
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-end">
                                                <div className="btn-group" role="group">
                                                    <button
                                                        className="btn btn-outline-success"
                                                        onClick={() => exportHandler('pdf')}
                                                    >
                                                        <i className="ti ti-file-type-pdf me-2"></i>Export PDF
                                                    </button>
                                                    <button
                                                        className="btn btn-outline-primary"
                                                        onClick={() => exportHandler('csv')}
                                                    >
                                                        <i className="ti ti-file-type-csv me-2"></i>Export CSV
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Reading (m³)</th>
                                                        <th>Recorded By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {readings.length > 0 ? (
                                                        readings.map((reading, index) => (
                                                            <tr key={reading.id || index}>
                                                                <td>{index + 1}</td>
                                                                <td>{reading.date}</td>
                                                                <td>{reading.reading}</td>
                                                                <td>{reading.recordedBy}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">No reading history found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Transactions Section */}
                            {activeSection === 'transactions' && (
                                <div className="card">
                                    <div className="card-body">
                                        {transactions.length > 0 ? (
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Transaction ID</th>
                                                            <th>Date</th>
                                                            <th>Amount (KES)</th>
                                                            <th>Type</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.map((transaction, index) => (
                                                            <tr key={transaction.id || index}>
                                                                <td>{transaction.id}</td>
                                                                <td>{transaction.date}</td>
                                                                <td>{transaction.amount}</td>
                                                                <td>{transaction.type}</td>
                                                                <td>
                                                                    <span className={`badge ${transaction.status === 'Completed' ? 'bg-success' : 'bg-warning'}`}>
                                                                        {transaction.status}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="text-center py-4">
                                                <p>No transaction records found for this meter.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Assign Customer Modal */}
                            {showAssignCustomerModal && (
                                <div className="modal show d-block" tabIndex="-1">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Assign Customer to Meter</h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    onClick={() => {
                                                        setShowAssignCustomerModal(false);
                                                        setSelectedCustomerForAssignment('');
                                                    }}
                                                ></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label className="form-label">Select Customer</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedCustomerForAssignment}
                                                        onChange={(e) => setSelectedCustomerForAssignment(e.target.value)}
                                                    >
                                                        <option value="">Choose a customer</option>
                                                        {availableCustomers.map((customer) => (
                                                            <option key={customer._id} value={customer._id}>
                                                                {customer.fullName ||
                                                                    `${customer.firstName || ''} ${customer.lastName || ''}`.trim()}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={() => {
                                                        setShowAssignCustomerModal(false);
                                                        setSelectedCustomerForAssignment('');
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleAssignCustomer}
                                                    disabled={!selectedCustomerForAssignment}
                                                >
                                                    Assign Customer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Consumption History Section */}
                            {activeSection === 'consumption' && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text">Date</span>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={selectedDate}
                                                        onChange={(e) => handleDateChange(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={handleFilter}
                                                    >
                                                        Filter
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-end">
                                                <div className="btn-group">
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        onClick={() => toastify('Exporting CSV...', 'info')}
                                                    >
                                                        <i className="ti ti-file-export me-1"></i>Export CSV
                                                    </button>
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        onClick={() => toastify('Exporting PDF...', 'info')}
                                                    >
                                                        <i className="ti ti-file-export me-1"></i>Export PDF
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Consumption (m³)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {meterDetails?.readingHistory && meterDetails.readingHistory.length > 0 ? (
                                                        meterDetails.readingHistory
                                                            .sort((a, b) => new Date(b.readingDate) - new Date(a.readingDate))
                                                            .map((reading, index) => (
                                                                <tr key={reading._id || index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{new Date(reading.readingDate).toLocaleDateString()}</td>
                                                                    <td>{reading.consumption.toFixed(2)}</td>
                                                                </tr>
                                                            ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">No consumption history found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Edit Meter Details Modal */}
            {showEditModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Meter Details</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">Meter Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editForm.meterNumber}
                                            onChange={(e) => setEditForm({ ...editForm, meterNumber: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Account Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editForm.accountNumber}
                                            onChange={(e) => setEditForm({ ...editForm, accountNumber: e.target.value })}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleEditSubmit}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add New Reading Modal */}
            {showAddReadingModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Record New Reading</h5>
                                <button type="button" className="btn-close" onClick={() => setShowAddReadingModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">Current Reading</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={newReading}
                                            onChange={(e) => setNewReading(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowAddReadingModal(false)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleNewReadingSubmit}>Save Reading</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
}

export default ViewAnalogWaterMeter;
