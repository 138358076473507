import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getItem } from '../../../../utils/localStorage';
import { makeRequest2 } from '../../../../utils/makeRequest';
import {
  get_lease_reminders,
  get_lease_penalties,
  penalty_status,
  toggle_lease_reminder_status
} from '../../../../utils/urls';
import { toastify } from '../../../../utils/toast';
import Layout from '../../component/layout';
import EditPenalty from './edit_penalty';
import EditReminder from './edit_reminder';

const PenaltyReminderSelector = ({
  onPenaltySelect,
  onReminderSelect,
  selectedPenaltyId,
  selectedReminderId
}) => {
  const location = useLocation();
  
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'penalties');
  const [penalties, setPenalties] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      //console.log('Fetching with facility ID:', facilityId);

      if (!facilityId) {
        toastify("Facility ID not found in localStorage", "error");
        return;
      }

      try {
        //console.log('Fetching penalties...');
        const penaltiesRes = await makeRequest2(`${get_lease_penalties}/${facilityId}`, "GET");
        //console.log('Penalties response:', penaltiesRes);

        if (penaltiesRes.success) {
          // Check if data.data exists (nested data structure)
          const penaltiesData = penaltiesRes.data.data || penaltiesRes.data || [];
          //console.log('Processed penalties data:', penaltiesData);
          setPenalties(Array.isArray(penaltiesData) ? penaltiesData : []);
        } else {
          toastify(`Penalties error: ${penaltiesRes.error}`, 'error');
        }
      } catch (penaltyError) {
        //console.error('Penalties fetch error:', penaltyError);
        toastify(`Penalties fetch error: ${penaltyError.message}`, 'error');
      }

      try {
        //console.log('Fetching reminders...');
        const remindersRes = await makeRequest2(`${get_lease_reminders}/${facilityId}`, "GET");
        //console.log('Reminders response:', remindersRes);

        if (remindersRes.success) {
          // Check if data.data exists (nested data structure)
          const remindersData = remindersRes.data.data || remindersRes.data || [];
          //console.log('Processed reminders data:', remindersData);
          setReminders(Array.isArray(remindersData) ? remindersData : []);
        } else {
          toastify(`Reminders error: ${remindersRes.error}`, 'error');
        }
      } catch (reminderError) {
        //console.error('Reminders fetch error:', reminderError);
        toastify(`Reminders fetch error: ${reminderError.message}`, 'error');
      }
    } catch (error) {
      //console.error('General fetch error:', error);
      toastify(`Error: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowEditDialog(true);
  };

  const handleToggleStatus = async (item) => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      if (!facilityId) {
        toastify("Facility ID not found", "error");
        return;
      }

      const endpoint = activeTab === 'penalties' ? penalty_status : toggle_lease_reminder_status;

      const response = await makeRequest2(
        `${endpoint}/${facilityId}/${item._id}`,
        "PATCH",
        { isActive: !item.isActive }
      );

      if (response.success) {
        toastify(`${activeTab === 'penalties' ? 'Penalty' : 'Reminder'} status updated`, "success");
        fetchData(); // Refresh the data
      } else {
        toastify(response.error || 'Failed to update status', 'error');
      }
    } catch (error) {
      //console.error('Error toggling status:', error);
      toastify('Error updating status', 'error');
    }
  };

  // const actionBodyTemplate = (rowData) => {
  //   const isSelected = activeTab === 'penalties' ?
  //     rowData._id === selectedPenaltyId :
  //     rowData._id === selectedReminderId;

  //   return (
  //     <div className="d-flex gap-2">
  //       <Button
  //         icon={isSelected ? "ti ti-check" : "ti ti-plus"}
  //         className={`p-button-sm ${isSelected ? 'p-button-success' : 'p-button-secondary'}`}
  //         tooltip={isSelected ? "Selected" : "Select"}
  //         onClick={() => {
  //           if (activeTab === 'penalties') {
  //             onPenaltySelect(rowData._id);
  //           } else {
  //             onReminderSelect(rowData._id);
  //           }
  //         }}
  //       />
  //       <Button
  //         icon="ti ti-edit"
  //         className="p-button-sm p-button-warning"
  //         tooltip="Edit"
  //         onClick={() => handleEdit(rowData)}
  //       />
  //       <Button
  //         icon={rowData.isActive ? "ti ti-ban" : "ti ti-check"}
  //         className={`p-button-sm ${rowData.isActive ? 'p-button-danger' : 'p-button-success'}`}
  //         tooltip={rowData.isActive ? "Deactivate" : "Activate"}
  //         onClick={() => handleToggleStatus(rowData)}
  //       />
  //     </div>
  //   );
  // };


  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Lease Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Penalties and Reminders</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body py-0">
          <ul className="nav nav-tabs profile-tabs" role="tablist">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'penalties' ? 'active' : ''}`}
                onClick={() => setActiveTab('penalties')}
                role="tab"
                type="button"
              >
                <i className="ti ti-money me-2"></i>Penalties
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'reminders' ? 'active' : ''}`}
                onClick={() => setActiveTab('reminders')}
                role="tab"
                type="button"
              >
                <i className="ti ti-bell me-2"></i>Reminders
              </button>
            </li>
          </ul>

          <div className="tab-content">
            <div className={`tab-pane ${activeTab === 'penalties' ? 'show active' : ''}`}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0">Select Penalty</h6>
                <Link to="/facility/lease_management/lease_penalties/add_penalty" className="btn btn-primary btn-sm">
                  <i className="ti ti-plus"></i> Add New Penalty
                </Link>
              </div>
              <DataTable
                value={penalties}
                emptyMessage="No penalties found."
                stripedRows
                loading={loading}
                tableStyle={{ minWidth: '50rem' }}
                sortField="name"
                sortOrder={1}
              >
                <Column field="name" header="Name" sortable></Column>
                <Column field="type" header="Type" sortable></Column>
                <Column
                  field="amount"
                  header="Amount"
                  body={(rowData) => rowData.type === 'fixed' ? rowData.amount : `${rowData.percentage}%`}
                  sortable
                ></Column>
                <Column field="effectDays" header="Effect Days" sortable></Column>
                {/* <Column body={actionBodyTemplate} header="Actions"></Column> */}
              </DataTable>
            </div>

            <div className={`tab-pane ${activeTab === 'reminders' ? 'show active' : ''}`}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0">Select Reminder</h6>
                <Link to="/facility/lease_management/lease_penalties/add_reminder" className="btn btn-primary btn-sm">
                  <i className="ti ti-plus"></i> Add New Reminder
                </Link>
              </div>
              <DataTable
                value={reminders}
                emptyMessage="No reminders found."
                stripedRows
                loading={loading}
                tableStyle={{ minWidth: '50rem' }}
                sortField="name"
                sortOrder={1}
              >
                <Column field="name" header="Name" sortable></Column>
                <Column field="type" header="Type" sortable></Column>
                <Column
                  field="notificationTypes"
                  header="Notifications"
                  body={(rowData) => rowData.notificationTypes.join(', ')}
                ></Column>
                <Column field="time" header="Time" sortable></Column>
                {/* <Column body={actionBodyTemplate} header="Actions"></Column> */}
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={`Edit ${activeTab === 'penalties' ? 'Penalty' : 'Reminder'}`}
        visible={showEditDialog}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setShowEditDialog(false);
          setSelectedItem(null);
        }}
      >
        {activeTab === 'penalties' ? (
          <EditPenalty
            penalty={selectedItem}
            onClose={() => setShowEditDialog(false)}
            onSuccess={() => {
              setShowEditDialog(false);
              setSelectedItem(null);
              fetchData();
            }}
          />
        ) : (
          <EditReminder
            reminder={selectedItem}
            onClose={() => setShowEditDialog(false)}
            onSuccess={() => {
              setShowEditDialog(false);
              setSelectedItem(null);
              fetchData();
            }}
          />
        )}
      </Dialog>
    </Layout>
  );
};

export default PenaltyReminderSelector;