import React, { useState, useEffect } from "react";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import Select from "react-select";
import { Button } from "primereact/button";
import { currenciesData } from "../../../utils/data";
import { ADD_CURRENCY_URL, GET_CURRENCIES_URL, EDIT_CURRENCY_URL, DELETE_CURRENCY_URL } from '../../../utils/urls';

const CurrencyManagementTab = ({ facilityId }) => {
  const [currencyName, setCurrencyName] = useState("");
  const [currencyShortCode, setCurrencyShortCode] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [isDefaultCurrency, setIsDefaultCurrency] = useState(false);
  const [fetchedCurrencies, setFetchedCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingCurrency, setEditingCurrency] = useState(null);

  // Map over the currenciesData array
  const currencyOptions = currenciesData.map((currency) => ({
    value: currency.code,
    label: `${currency.name} (${currency.code})`,
    currencyName: currency.name,
    currencyShortCode: currency.code
  }));

  // When a currency is selected, auto-fill the name and code
  const handleCurrencySelect = (selected) => {
    setSelectedCurrency(selected);
    setCurrencyName(selected.currencyName);
    setCurrencyShortCode(selected.currencyShortCode);
  };

  const fetchCurrencies = async () => {
    try {
      const response = await makeRequest2(
        `${GET_CURRENCIES_URL}/${facilityId}`,
        "GET"
      );
      if (response.success && response.data) {
        const currencyData = response.data.data || response.data;
        const currenciesArray = Array.isArray(currencyData)
          ? currencyData
          : [currencyData];
        setFetchedCurrencies(currenciesArray);
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
      toastify("Error fetching currencies", "error");
    }
  };

  const handleCurrencySubmit = async () => {
    if (!currencyName || !currencyShortCode) {
      toastify("Please select a currency", "error");
      return;
    }

    if (!exchangeRate && !isDefaultCurrency) {
      toastify("Please enter an exchange rate", "error");
      return;
    }

    try {
      const data = {
        currencyName,
        currencyShortCode,
        exchangeRate: isDefaultCurrency ? 1 : parseFloat(exchangeRate),
        isDefaultCurrency
      };

      const response = await makeRequest2(
        `${ADD_CURRENCY_URL}/${facilityId}`,
        "POST",
        data
      );

      if (response?.success === true) {
        toastify("Currency added successfully", "success");
        await fetchCurrencies();
        resetForm();
      } else {
        const errorMessage = response?.message || response?.error || "Operation failed";
        toastify(errorMessage, "error");
        await fetchCurrencies();
      }
    } catch (error) {
      console.error("Submit error:", error);
      toastify(error.message || "Error submitting currency", "error");
      await fetchCurrencies();
    }
  };

  const handleDelete = async (currency) => {
    try {
      if (currency.isDefaultCurrency) {
        toastify("Cannot delete default currency", "error");
        return;
      }

      const response = await makeRequest2(
        `${DELETE_CURRENCY_URL}/${facilityId}/${currency._id}`,
        "DELETE"
      );

      if (response.success) {
        toastify("Currency deleted successfully", "success");
        await fetchCurrencies();
      } else {
        toastify(response.message || "Failed to delete currency", "error");
      }
    } catch (error) {
      console.error("Delete error:", error);
      toastify("Error deleting currency", "error");
    }
  };

  const resetForm = () => {
    setCurrencyName("");
    setCurrencyShortCode("");
    setExchangeRate("");
    setIsDefaultCurrency(false);
    setSelectedCurrency(null);
  };

  const handleEditSubmit = async () => {
    if (editingCurrency == null) return;

    try {
      const data = {
        isDefaultCurrency: editingCurrency.isDefaultCurrency,
        exchangeRate: editingCurrency.isDefaultCurrency
          ? 1
          : parseFloat(editingCurrency.exchangeRate)
      };

      const response = await makeRequest2(
        `${EDIT_CURRENCY_URL}/${facilityId}/${editingCurrency._id}`,
        "PUT",
        data
      );
      if (response.success) {
        toastify("Currency updated successfully", "success");
        await fetchCurrencies();
        setShowEditModal(false);
        setEditingCurrency(null);
      } else {
        toastify(response.message || "Failed to update currency", "error");
      }
    } catch (error) {
      console.error("Edit error:", error);
      toastify("Error updating currency", "error");
    }
  };

  useEffect(() => {
    if (facilityId) {
      fetchCurrencies();
    }
  }, [facilityId]);

  // Set exchange rate to 1 if currency is default
  useEffect(() => {
    if (isDefaultCurrency) {
      setExchangeRate("1");
    }
  }, [isDefaultCurrency]);

  return (
    <div className="row p-3">
      {/* Add Currency Section */}
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h5>Add Currency</h5>

            <div className="mt-2">
              <label>Search and Select Currency:</label>
              <Select
                className="mt-2"
                options={currencyOptions}
                value={selectedCurrency}
                onChange={handleCurrencySelect}
                placeholder="Search for a currency..."
                isSearchable={true}
              />
            </div>

            <div className="mt-2">
              <label>Currency Name:</label>
              <input
                required
                className="form-control mt-2"
                type="text"
                value={currencyName}
                onChange={(e) => setCurrencyName(e.target.value)}
                placeholder="Currency name will auto-fill"
                disabled
              />
            </div>

            <div className="mt-2">
              <label>Currency Code:</label>
              <input
                required
                className="form-control mt-2"
                type="text"
                value={currencyShortCode}
                onChange={(e) => setCurrencyShortCode(e.target.value)}
                placeholder="Currency code will auto-fill"
                disabled
              />
            </div>

            <div className="mt-2">
              <label>Exchange Rate:</label>
              <input
                required
                className="form-control mt-2"
                type="number"
                min="0.001"
                step="0.001"
                value={exchangeRate}
                onChange={(e) => setExchangeRate(e.target.value)}
                placeholder="Enter exchange rate"
                disabled={isDefaultCurrency}
              />
              <small className="text-muted">
                Rate relative to the default currency
              </small>
            </div>

            <div className="mt-2 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="defaultCurrency"
                checked={isDefaultCurrency}
                onChange={(e) => setIsDefaultCurrency(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="defaultCurrency">
                Set as default currency
              </label>
            </div>

            <div className="mt-4" style={{ textAlign: "right" }}>
              <button className="btn btn-primary" onClick={handleCurrencySubmit}>
                Add Currency
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Current Currencies Section */}
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h5 className="mb-4">Current Currencies</h5>
            <div className="table-responsive">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">CURRENCY NAME</th>
                    <th scope="col">CODE</th>
                    <th scope="col">EXCHANGE RATE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col" className="text-end">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchedCurrencies.length > 0 ? (
                    fetchedCurrencies.map((currency) => (
                      <tr key={currency._id}>
                        <td>{currency.currencyName}</td>
                        <td>{currency.currencyShortCode}</td>
                        <td>{currency.exchangeRate || (currency.isDefaultCurrency ? '1' : 'N/A')}</td>
                        <td>
                          {currency.isDefaultCurrency ? (
                            <span className="badge bg-success">Default</span>
                          ) : (
                            <span className="badge bg-secondary">Secondary</span>
                          )}
                        </td>
                        <td className="text-end">
                          <Button
                            icon="ti ti-pencil"
                            className="p-button-rounded p-button-info me-2"
                            onClick={() => {
                              setEditingCurrency(currency);
                              setShowEditModal(true);
                            }}
                            tooltip="Edit"
                          />
                          {!currency.isDefaultCurrency && (
                            <Button
                              icon="ti ti-trash"
                              className="p-button-rounded p-button-danger"
                              onClick={() => handleDelete(currency)}
                              tooltip="Delete"
                            />
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        <div className="text-muted">
                          <i className="ti ti-inbox mb-2" style={{ fontSize: "24px" }}></i>
                          <p className="mb-0">No currencies available</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Currency Modal */}
      {showEditModal && editingCurrency && (
        <>
          <div className="modal show fade" style={{ display: "block" }} tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Currency</h5>
                  <button type="button" className="btn-close" onClick={() => setShowEditModal(false)}></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Currency Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editingCurrency.currencyName || ""}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Currency Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editingCurrency.currencyShortCode || ""}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Exchange Rate</label>
                    <input
                      type="number"
                      min="0.001"
                      step="0.001"
                      className="form-control"
                      value={editingCurrency.exchangeRate || ""}
                      onChange={(e) =>
                        setEditingCurrency({
                          ...editingCurrency,
                          exchangeRate: e.target.value
                        })
                      }
                      disabled={editingCurrency.isDefaultCurrency}
                    />
                    <small className="text-muted">
                      Rate relative to the default currency
                    </small>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="editDefaultCurrency"
                      checked={editingCurrency.isDefaultCurrency || false}
                      onChange={(e) => {
                        const isDefault = e.target.checked;
                        setEditingCurrency({
                          ...editingCurrency,
                          isDefaultCurrency: isDefault,
                          exchangeRate: isDefault ? "1" : editingCurrency.exchangeRate
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="editDefaultCurrency">
                      Set as default currency
                    </label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleEditSubmit}>
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Backdrop */}
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </div>
  );
};

export default CurrencyManagementTab;