import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../component/layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import {
  get_leases,
  getUnitsForFacility,
  update_lease_status,
  get_facility_penalties,
  upload_lease_document,
  get_lease_documents
} from "../../../../utils/urls";
import UpdateLease from "./edit_lease";
import TerminateLease from "./terminate_lease";
import PopulatedLeaseTemplate from "./lease_templates/populated_lease_template";
import UploadLeaseDocument from "./upload_lease_document";
import ViewLeaseDocuments from "./view_lease_documents";

const LeaseManagement = () => {
  const navigate = useNavigate();
  const [leases, setLeases] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [filteredLeases, setFilteredLeases] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [selectedLeaseData, setSelectedLeaseData] = useState(null);
  const [isTemplateVisible, setIsTemplateVisible] = useState(false);
  const [selectedViewLeaseId, setSelectedViewLeaseId] = useState(null);
  
  // Document management state
  const [uploadDocumentDialogVisible, setUploadDocumentDialogVisible] = useState(false);
  const [viewDocumentsDialogVisible, setViewDocumentsDialogVisible] = useState(false);
  const [selectedDocumentLeaseId, setSelectedDocumentLeaseId] = useState(null);

  const fetchLeases = useCallback(async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("No facility selected", "error");
        return;
      }

      const response = await makeRequest2(`${get_leases}/${facilityId}`, "GET");

      if (response?.success && Array.isArray(response.data.leaseAgreements)) {
        const processedLeases = response.data.leaseAgreements.map(lease => ({
          ...lease,
          landlordName: lease.landlordInfo?.fullName || "N/A",
          tenantName: lease.tenantInfo?.fullName || "N/A",
          unitName: lease.unitInfo?.unitName || "N/A",
          leaseTemplateName: lease.leaseTemplateName || "N/A",
          leaseStatus: calculateLeaseStatus(lease),
          status: calculateLeaseStatus(lease),
          hasDocuments: lease.leaseDocuments && lease.leaseDocuments.length > 0
        }));

        setLeases(processedLeases);
        setFilteredLeases(processedLeases);
      } else {
        throw new Error('Failed to fetch leases');
      }
    } catch (error) {
      toastify(error.message || "Error fetching leases", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEdit = useCallback((lease) => {
    setSelectedLeaseData({
      ...lease,
      // Add any additional formatting needed for the edit form
      leaseTerms: {
        ...lease.leaseTerms,
        startDate: lease.leaseTerms?.startDate?.split('T')[0],
        endDate: lease.leaseTerms?.endDate?.split('T')[0]
      }
    });
    setEditDialogVisible(true);
  }, []);

  const handleUpdateSuccess = useCallback(async () => {
    await fetchLeases();
    setEditDialogVisible(false);
    setSelectedLeaseData(null);
  }, [fetchLeases]);

  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET"
      );
      if (response.success) {
        setUnits(response.data);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    filterLeases(value, selectedStatus, selectedUnit);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    filterLeases(searchTerm, status, selectedUnit);
  };

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
    filterLeases(searchTerm, selectedStatus, unit);
  };

  const filterLeases = (search, status, unit) => {
    let filtered = [...leases];

    if (search) {
      filtered = filtered.filter(lease =>
        lease.tenantName.toLowerCase().includes(search.toLowerCase()) ||
        lease.landlordName.toLowerCase().includes(search.toLowerCase()) ||
        lease.unitName.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (status) {
      filtered = filtered.filter(lease => lease.leaseStatus === status);
    }

    if (unit) {
      filtered = filtered.filter(lease => lease.unitName === unit);
    }

    setFilteredLeases(filtered);
  };

  const calculateLeaseStatus = (lease) => {
    // Return the persisted status if it's Terminated or Pending
    if (lease.status === 'Terminated' || lease.status === 'Pending') {
      return lease.status;
    }

    // Check if lease is expired
    if (isLeaseExpired(lease.leaseTerms.endDate) && lease.status !== 'Expired') {
      // Update status in background without affecting the UI flow
      updateLeaseStatus(lease._id, 'Expired').catch(error => {
        console.error('Failed to update lease status:', error);
      });
      return 'Expired';
    }

    return lease.status || 'Active';
  };

  const isLeaseExpired = (endDate) => {
    return new Date(endDate) < new Date();
  };

  // Function to update lease status in the backend
  const updateLeaseStatus = async (leaseId, newStatus) => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        throw new Error("Facility ID not found");
      }

      const payload = {
        status: newStatus,
        leaseId: leaseId
      };

      const response = await makeRequest2(
        `${update_lease_status}/${facilityId}`,
        "PUT",
        payload
      );

      if (!response.success) {
        throw new Error(response.error || "Failed to update lease status");
      }

      return true;
    } catch (error) {
      console.error("Error updating lease status:", error);
      // Don't show toastify here since this might be called in background
      return false;
    }
  };

  const statusTemplate = (rowData) => {
    const statusStyles = {
      Active: { color: '#28a745', backgroundColor: '#e9fbe9' },
      Expired: { color: '#dc3545', backgroundColor: '#fde9e9' },
      Terminated: { color: '#6c757d', backgroundColor: '#f1f1f1' },
      Pending: { color: '#ffc107', backgroundColor: '#fff8e1' }
    };

    const style = statusStyles[rowData.leaseStatus] || {
      color: '#6c757d',
      backgroundColor: '#f1f1f1',
    };

    return (
      <span
        style={{
          ...style,
          padding: '0.5em 1em',
          borderRadius: '12px',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          display: 'inline-block',
        }}
      >
        {rowData.leaseStatus}
      </span>
    );
  };

  const documentTemplate = (rowData) => {
    // Check if the lease has documents
    const hasDocuments = rowData.leaseDocuments && rowData.leaseDocuments.length > 0;
  
    return (
      <div className="d-flex gap-2">
        {/* Upload button - only show for Pending leases */}
        {rowData.leaseStatus === 'Pending' && (
          <Button
            icon="ti ti-upload"
            className="p-button-rounded p-button-success"
            onClick={() => {
              setSelectedDocumentLeaseId(rowData._id);
              setUploadDocumentDialogVisible(true);
            }}
            tooltip="Upload Document"
          />
        )}
  
        {/* View Documents button - always show but style based on document existence */}
        <Button
          icon="ti ti-file"
          className={`p-button-rounded ${hasDocuments ? 'p-button-primary' : 'p-button-secondary'}`}
          onClick={() => {
            setSelectedDocumentLeaseId(rowData._id);
            setViewDocumentsDialogVisible(true);
          }}
          tooltip={hasDocuments ? "View Documents" : "No Documents"}
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex gap-2">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-warning"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
        />
        <TerminateLease
          leaseId={rowData._id}
          initialStatus={rowData.status}
          fetchLeases={fetchLeases}
        />
        <Button
          icon="ti ti-eye"
          className="p-button-rounded p-button-info"
          onClick={() => {
            setSelectedViewLeaseId(rowData._id);
            setIsTemplateVisible(true);
          }}
          tooltip="View Lease Template"
        />
      </div>
    );
  };

  useEffect(() => {
    fetchLeases();
    fetchUnitsForFacility();
  }, [fetchLeases]);

  const handleDocumentUploadSuccess = useCallback(async (leaseId) => {
    // Update the lease status to Active after successful document upload
    try {
      if (!leaseId) {
        console.error("No lease ID provided for status update");
        return;
      }
      
      console.log("Upload successful. Updating status for lease ID:", leaseId);
      
      // The backend already updates the status to Active upon document upload,
      // but we'll call it again to ensure consistency
      try {
        await updateLeaseStatus(leaseId, 'Active');
        console.log("Status update API call successful");
      } catch (statusError) {
        console.warn("Status may have already been updated by the upload process:", statusError);
      }
      
      toastify("Document uploaded and lease status updated to Active", "success");
    } catch (error) {
      console.error("Error handling upload success:", error);
      toastify("Document uploaded but failed to update lease status", "warning");
    }
    
    // Refresh the lease list
    console.log("Refreshing lease list after document upload");
    await fetchLeases();
    
    // Close the dialog and reset state
    setUploadDocumentDialogVisible(false);
    setSelectedDocumentLeaseId(null);
  }, [fetchLeases, updateLeaseStatus]);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Lease Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Leases</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Leases</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <input
                    className="form-control"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedStatus}
                    onChange={(e) => handleStatusChange(e.target.value)}
                  >
                    <option value="">All Statuses</option>
                    <option value="Active">Active</option>
                    <option value="Pending">Pending</option>
                    <option value="Expired">Expired</option>
                    <option value="Terminated">Terminated</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedUnit}
                    onChange={(e) => handleUnitChange(e.target.value)}
                  >
                    <option value="">All Units</option>
                    {units.map((unit) => (
                      <option key={unit._id} value={unit.name}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => navigate("/facility/lease_management/create_lease")}
                  >
                    Add Lease
                  </button>
                </div>
              </div>

              <DataTable
                value={filteredLeases}
                paginator
                rows={10}
                loading={loading}
                emptyMessage="No leases found"
                sortMode="multiple"
                removableSort
                stripedRows
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column field="landlordName" header="Landlord" sortable />
                <Column field="tenantName" header="Tenant" sortable />
                <Column field="unitName" header="Unit" sortable />
                <Column
                  field="leaseTerms.startDate"
                  header="Start Date"
                  sortable
                  body={(rowData) => new Date(rowData.leaseTerms.startDate).toLocaleDateString()}
                />
                <Column
                  field="leaseTerms.endDate"
                  header="End Date"
                  sortable
                  body={(rowData) => new Date(rowData.leaseTerms.endDate).toLocaleDateString()}
                />
                <Column
                  field="financialTerms.monthlyRent"
                  header="Monthly Rent"
                  sortable
                  body={(rowData) => {
                    const currencyCode = rowData.currency?.currencyShortCode || '';
                    const amount = rowData.financialTerms.monthlyRent.toLocaleString();
                    return `${currencyCode} ${amount}`;
                  }}
                />
                <Column field="leaseStatus" header="Status" body={statusTemplate} sortable />
                <Column header="Documents" body={documentTemplate} />
                <Column header="Actions" body={actionBodyTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      {/* Update Dialog */}
      <UpdateLease
        visible={editDialogVisible}
        onHide={() => {
          setEditDialogVisible(false);
          setSelectedLeaseData(null);
        }}
        leaseData={selectedLeaseData}
        onUpdate={handleUpdateSuccess}
      />

      {/* Template Dialog */}
      <PopulatedLeaseTemplate
        leaseId={selectedViewLeaseId}
        visible={isTemplateVisible}
        onHide={() => {
          setIsTemplateVisible(false);
          setSelectedViewLeaseId(null);
        }}
      />

      {/* Document Upload Dialog */}
      <UploadLeaseDocument
        leaseId={selectedDocumentLeaseId}
        visible={uploadDocumentDialogVisible}
        onHide={() => {
          setUploadDocumentDialogVisible(false);
          setSelectedDocumentLeaseId(null);
        }}
        onUploadSuccess={() => handleDocumentUploadSuccess(selectedDocumentLeaseId)}
      />

      {/* View Documents Dialog */}
      <ViewLeaseDocuments
        leaseId={selectedDocumentLeaseId}
        visible={viewDocumentsDialogVisible}
        onHide={() => {
          setViewDocumentsDialogVisible(false);
          setSelectedDocumentLeaseId(null);
        }}
      />
    </Layout>
  );
};

export default LeaseManagement;