import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../component/layout";
import { getItem } from "../../../utils/localStorage";
import { getFacilityUnits } from "../../../utils/urls";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { setItem } from "localforage";

function Dashboard() {
  const [occupiedUnits, setOccupiedUnits] = useState(0);
  const [notOccupiedUnits, setUnoccupiedUnits] = useState(0);

  const fetchUnitsCount = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      const response = await makeRequest2(
        `${getFacilityUnits}/${facilityId}`,
        "GET",
        {}
      );

      if (response.success) {
        const { occupiedUnits, notOccupiedUnits } = response.data;
        setOccupiedUnits(occupiedUnits.length);
        setUnoccupiedUnits(notOccupiedUnits.length);
      } else {
        throw new Error("Failed to fetch units count");
      }
    } catch (err) {
      console.error(err.message);
      toastify(err.message, "error");
    }
  };


  useEffect(() => {
    fetchUnitsCount();
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-0">Units</h6>
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-md-6 col-xxl-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 className="mb-1">{occupiedUnits}</h3>
                          <p className="text-muted mb-0">Occupied Units</p>
                        </div>
                        <div className="col-4 text-end">
                          <i className="ti ti-lock text-primary f-36"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 className="mb-1">{notOccupiedUnits}</h3>
                          <p className="text-muted mb-0">Unoccupied Units</p>
                        </div>
                        <div className="col-4 text-end">
                          <i className="ti ti-lock-open text-danger f-36"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Dashboard;
