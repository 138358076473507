import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from 'react-router-dom';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { toastify } from "../../../../utils/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useFacilityStore } from "../../../../app/z-store/store";
import Layout from "../../component/layout";
import { makeRequest2 } from "../../../../utils/makeRequest";

const ServiceVendorManagement = () => {
  const [vendors, setVendors] = useState([]);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('vendors');
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [errors, setErrors] = useState({});
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);
  const toast = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    service: "",
    phone: "",
    email: ""
  });

  const [assetFormData, setAssetFormData] = useState({
    vendorId: null,
    agreement: "",
    dates: [],
    assignedAssets: []
  });

  const validateVendorForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Vendor name is required";
    }

    if (!formData.service.trim()) {
      newErrors.service = "Service is required";
    }

    const phoneRegex = /^(?:\+254|0)\d{9}$/;
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must start with +254 or 0 followed by 9 digits";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email address is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please provide a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateAssetForm = () => {
    const newErrors = {};

    if (!assetFormData.agreement.trim()) {
      newErrors.agreement = "Agreement is required";
    }

    if (assetFormData.dates.length === 0) {
      newErrors.dates = "At least one service date is required";
    }

    if (!assetFormData.assignedAssets.length) {
      newErrors.assignedAssets = "At least one asset must be assigned";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (selectedFacilityId) {
      fetchVendors();
      fetchAssets();
    }
  }, [selectedFacilityId]);

  const fetchVendors = async () => {
    setLoading(true);
    try {
      const response = await makeRequest2(
        `/api/service_vendor/${selectedFacilityId}`,
        "GET"
      );

      if (response.success) {
        let vendorsData = response.data?.data || response.data || [];
        setVendors(vendorsData);
      } else {
        setVendors([]);
        toastify("Failed to fetch service vendors", 'error');
      }
    } catch (error) {
      setVendors([]);
      toastify("Failed to fetch service vendors", 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchAssets = async (vendorToEdit = null) => {
    try {
      const response = await makeRequest2(
        `/api/assets/${selectedFacilityId}`,
        "GET"
      );
  
      if (response.success && response.data) {
        let normalizedAssets = [];
        const data = response.data;
  
        if (Array.isArray(data)) {
          normalizedAssets = data;
        } else if (data.assets && Array.isArray(data.assets)) {
          normalizedAssets = data.assets;
        } else if (data.assets) {
          normalizedAssets = [data.assets];
        } else if (data._id) {
          normalizedAssets = [data];
        }
  
        // Get IDs of assets currently assigned to the vendor being edited
        const vendorAssignedAssetIds = vendorToEdit 
          ? (vendorToEdit.assignedAssets || []).map(asset => asset.value || asset._id) 
          : [];
  
        // Format assets, filtering for unassigned or vendor-specific assets
        const formattedAssets = normalizedAssets
          .filter(asset =>
            asset &&
            asset._id &&
            asset.name && 
            (!asset.assignedVendorId || // Unassigned asset
             (vendorToEdit && vendorAssignedAssetIds.includes(asset._id)) // Or asset assigned to current vendor
            )
          )
          .map(asset => ({
            label: asset.name,
            value: asset._id,
            selected: vendorToEdit && vendorAssignedAssetIds.includes(asset._id)
          }));
  
        setAssets(formattedAssets);
      } else {
        setAssets([]);
        toastify("Failed to fetch facility assets", "error");
      }
    } catch (error) {
      console.error("Asset fetch error:", error);
      toastify("Failed to fetch facility assets", "error");
      setAssets([]);
    }
  };

  const handleVendorSubmit = async (e) => {
    e.preventDefault();

    if (!validateVendorForm()) {
      toastify("Please fix the form errors", 'error');
      return;
    }

    try {
      setLoading(true);

      const method = selectedVendor ? "PUT" : "POST";
      const url = selectedVendor
        ? `/api/service_vendor/${selectedFacilityId}/${selectedVendor._id}`
        : `/api/service_vendor/${selectedFacilityId}`;

      const response = await makeRequest2(url, method, formData);

      if (response.success) {
        toastify(
          `Service Vendor ${selectedVendor ? 'updated' : 'added'} successfully`,
          'success'
        );
        await fetchVendors();
        setShowModal(false);
      } else {
        throw new Error(response.error || 'Failed to process vendor');
      }
    } catch (error) {
      toastify(error.message || `Failed to ${selectedVendor ? 'update' : 'add'} Service Vendor`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAssetSubmit = async (e) => {
    e.preventDefault();

    if (!validateAssetForm()) {
      toastify("Please fix the form errors", 'error');
      return;
    }

    try {
      setLoading(true);

      const submissionData = {
        ...assetFormData,
        dates: assetFormData.dates.map(date =>
          date instanceof Date ? date.toISOString() : new Date(date).toISOString()
        )
      };

      const response = await makeRequest2(
        `/api/service_vendor/${selectedFacilityId}/${assetFormData.vendorId}`,
        "PUT",
        submissionData
      );

      if (response.success) {
        toastify("Vendor assets and details updated successfully", 'success');
        await Promise.all([fetchVendors(), fetchAssets()]);
        setShowModal(false);
      } else {
        throw new Error(response.error || 'Failed to update vendor');
      }
    } catch (error) {
      toastify(error.message || 'Failed to update vendor', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (vendorId) => {
    confirmDialog({
      message: 'Are you sure you want to delete this vendor?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/service_vendor/${selectedFacilityId}/${vendorId}`,
            "DELETE"
          );
          if (response.success) {
            toastify("Service Vendor deleted successfully", 'success');
            await Promise.all([fetchVendors(), fetchAssets()]);
          }
        } catch (error) {
          toastify("Failed to delete Service Vendor", 'error');
        }
      }
    });
  };

  const renderVendorModal = () => (
    <div className="modal show d-block">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {selectedVendor ? "Edit Vendor" : "Add New Vendor"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowModal(false)}
            />
          </div>
          <form onSubmit={handleVendorSubmit} noValidate>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="name" className="form-label">Name *</label>
                  <input
                    type="text"
                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                    name="name"
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    required
                  />
                  {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="phone" className="form-label">Phone Number *</label>
                  <input
                    type="tel"
                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                    placeholder="+254XXXXXXXXX or 07XXXXXXXX"
                    required
                  />
                  {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="email" className="form-label">Email *</label>
                  <input
                    type="email"
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    name="email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    required
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="service" className="form-label">Service *</label>
                  <input
                    type="text"
                    className={`form-control ${errors.service ? 'is-invalid' : ''}`}
                    name="service"
                    value={formData.service}
                    onChange={(e) => setFormData(prev => ({ ...prev, service: e.target.value }))}
                    required
                  />
                  {errors.service && <div className="invalid-feedback">{errors.service}</div>}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  const renderAssetModal = (vendor) => (
    <div className="modal show d-block">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Assign Assets & Services for {vendor.name}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowModal(false)}
            />
          </div>
          <form onSubmit={handleAssetSubmit} noValidate>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="agreement" className="form-label">Agreement *</label>
                  <textarea
                    className={`form-control ${errors.agreement ? 'is-invalid' : ''}`}
                    name="agreement"
                    value={assetFormData.agreement}
                    onChange={(e) => setAssetFormData(prev => ({ ...prev, agreement: e.target.value }))}
                    rows="3"
                    required
                  />
                  {errors.agreement && <div className="invalid-feedback">{errors.agreement}</div>}
                </div>

                <div className="col-12 mb-3">
                  <label className="form-label">Service Dates *</label>
                  <div>
                    <input
                      type="date"
                      className={`form-control mb-2 ${errors.dates ? 'is-invalid' : ''}`}
                      name="dates"
                      onChange={(e) => {
                        const newDate = new Date(e.target.value);
                        setAssetFormData(prev => ({
                          ...prev,
                          dates: [...prev.dates, newDate]
                        }));
                      }}
                    />
                    {errors.dates && (
                      <div className="invalid-feedback">{errors.dates}</div>
                    )}
                    {assetFormData.dates.map((date, index) => (
                      <div key={index} className="badge bg-primary m-1">
                        {date.toLocaleDateString()}
                        <button
                          type="button"
                          onClick={() => {
                            setAssetFormData(prev => ({
                              ...prev,
                              dates: prev.dates.filter((_, i) => i !== index)
                            }));
                          }}
                          className="btn btn-sm text-white"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <label className="form-label">Assigned Assets *</label>
                  <div className={`border rounded p-3 ${errors.assignedAssets ? 'border-danger' : ''}`}>
                    <div className="row">
                      {assets.map(asset => (
                        <div key={asset.value} className="col-md-6 mb-2">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`asset-${asset.value}`}
                              checked={assetFormData.assignedAssets.includes(asset.value) || asset.selected}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setAssetFormData(prev => ({
                                  ...prev,
                                  assignedAssets: isChecked
                                    ? [...new Set([...prev.assignedAssets, asset.value])]
                                    : prev.assignedAssets.filter(id => id !== asset.value)
                                }));
                                if (errors.assignedAssets) {
                                  setErrors(prev => ({
                                    ...prev,
                                    assignedAssets: undefined
                                  }));
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`asset-${asset.value}`}
                            >
                              {asset.label}
                            </label>
                          </div>
                        </div>
                      ))}
                      {assets.length === 0 && <p>No unassigned assets available</p>}
                    </div>
                    {errors.assignedAssets && (
                      <div className="text-danger mt-2">{errors.assignedAssets}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  const renderMaintenanceModal = (vendor) => (
    <div className="modal show d-block">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Maintenance Details for {vendor.name}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <h6>Upcoming Maintenance Dates</h6>
                {vendor.dates && vendor.dates.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendor.dates
                        .filter(date => new Date(date) >= new Date())
                        .sort((a, b) => new Date(a) - new Date(b))
                        .slice(0, 5)
                        .map((date, index) => (
                          <tr key={index}>
                            <td>{new Date(date).toLocaleDateString()}</td>
                            <td>
                              {index === 0 ? 'Upcoming' : 'Scheduled'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No maintenance dates scheduled</p>
                )}
              </div>
              <div className="col-12">
                <h6>Service Details</h6>
                <dl className="row">
                  <dt className="col-sm-3">Vendor Name</dt>
                  <dd className="col-sm-9">{vendor.name}</dd>

                  <dt className="col-sm-3">Service Type</dt>
                  <dd className="col-sm-9">{vendor.service}</dd>

                  <dt className="col-sm-3">Contact</dt>
                  <dd className="col-sm-9">
                    {vendor.phone} | {vendor.email}
                  </dd>

                  <dt className="col-sm-3">Assigned Assets</dt>
                  <dd className="col-sm-9">
                    {vendor.assignedAssets && vendor.assignedAssets.length > 0
                      ? vendor.assignedAssets.map(asset => asset.label || asset.name).join(', ')
                      : 'No assets assigned'}
                  </dd>

                  <dt className="col-sm-3">Agreement</dt>
                  <dd className="col-sm-9">{vendor.agreement || 'No agreement details'}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleModalOpen = (vendor, tab) => {
    setSelectedVendor(vendor);

    if (tab === 'vendors') {
      setFormData({
        name: vendor.name || "",
        service: vendor.service || "",
        phone: vendor.phone || "",
        email: vendor.email || ""
      });
    } else if (tab === 'assets') {
      setAssetFormData({
        vendorId: vendor._id,
        agreement: vendor.agreement || "",
        dates: vendor.dates ? vendor.dates.map(date => new Date(date)) : [],
        assignedAssets: vendor.assignedAssets
          ? vendor.assignedAssets.map(asset => asset.value || asset._id)
          : []
      });
    }

    setShowModal(true);
  };

  const filteredVendors = vendors.filter(vendor =>
    Object.values(vendor)
      .some(value =>
        value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
      )
  );

  const maintenanceVendors = filteredVendors.filter(
    vendor => vendor.assignedAssets && vendor.assignedAssets.length > 0
  );

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Service Vendor Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Toast ref={toast} />
      <ConfirmDialog />

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body py-0">
              <ul className="nav nav-tabs profile-tabs" role="tablist">
                {['vendors', 'assets', 'maintenance'].map((tab) => (
                  <li key={tab} className="nav-item">
                    <a
                      className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                      onClick={() => setActiveTab(tab)}
                      href={`#${tab}`}
                      role="tab"
                    >
                      {tab === 'vendors' && <i className="ti ti-users me-2"></i>}
                      {tab === 'assets' && <i className="ti ti-building me-2"></i>}
                      {tab === 'maintenance' && <i className="ti ti-calendar me-2"></i>}
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="tab-content">
              {activeTab === 'vendors' && (
                <div className="tab-pane show active" id="vendors" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <input
                          type="text"
                          className="form-control w-25"
                          placeholder="Search vendors..."
                          value={searchKeyword}
                          onChange={(e) => setSearchKeyword(e.target.value)}
                        />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setSelectedVendor(null);
                            setFormData({
                              name: "",
                              service: "",
                              phone: "",
                              email: ""
                            });
                            setShowModal(true);
                          }}
                        >
                          Add New Vendor
                        </button>
                      </div>
                      <DataTable
                        value={filteredVendors}
                        paginator
                        rows={10}
                        loading={loading}
                        emptyMessage="No vendors found."
                      >
                        <Column field="name" header="Name" sortable />
                        <Column field="service" header="Service" sortable />
                        <Column field="phone" header="Phone" sortable />
                        <Column field="email" header="Email" sortable />
                        <Column
                          header="Actions"
                          body={(rowData) => (
                            <div>
                              <Button
                                icon="ti ti-edit"
                                className="p-button-rounded p-button-info p-mr-2"
                                onClick={() => handleModalOpen(rowData, 'vendors')}
                                tooltip="Edit"
                              />
                              <Button
                                icon="ti ti-trash"
                                className="p-button-rounded p-button-danger"
                                onClick={() => handleDelete(rowData._id)}
                                tooltip="Delete"
                              />
                            </div>
                          )}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'assets' && (
                <div className="tab-pane show active" id="assets" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <DataTable
                        value={filteredVendors}
                        paginator
                        rows={10}
                        loading={loading}
                        emptyMessage="No vendors found."
                      >
                        <Column field="name" header="Vendor Name" sortable />
                        <Column field="service" header="Service" sortable />
                        <Column
                          header="Assigned Assets"
                          body={(rowData) =>
                            rowData.assignedAssets
                              ? rowData.assignedAssets.map(asset => asset.label || asset.name).join(', ')
                              : 'No assets assigned'
                          }
                        />
                        <Column
                          header="Actions"
                          body={(rowData) => (
                            <Button
                              icon="ti ti-plus"
                              className="p-button-rounded p-button-success"
                              onClick={() => handleModalOpen(rowData, 'assets')}
                              tooltip="Assign Assets"
                            />
                          )}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'maintenance' && (
                <div className="tab-pane show active" id="maintenance" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <DataTable
                        value={maintenanceVendors}
                        paginator
                        rows={10}
                        loading={loading}
                        emptyMessage="No scheduled maintenance found."
                      >
                        <Column field="name" header="Vendor Name" sortable />
                        <Column field="service" header="Service Type" sortable />
                        <Column
                          header="Next Maintenance Date"
                          body={(rowData) => {
                            const upcomingDates = rowData.dates
                              ?.filter(date => new Date(date) >= new Date())
                              .sort((a, b) => new Date(a) - new Date(b));
                            return upcomingDates && upcomingDates.length > 0
                              ? new Date(upcomingDates[0]).toLocaleDateString()
                              : 'No upcoming dates';
                          }}
                        />
                        <Column
                          header="Actions"
                          body={(rowData) => (
                            <Button
                              icon="ti ti-eye"
                              className="p-button-rounded p-button-info"
                              onClick={() => {
                                setSelectedVendor(rowData);
                                setShowModal(true);
                              }}
                              tooltip="View Details"
                            />
                          )}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal && activeTab === 'vendors' && renderVendorModal()}
      {showModal && activeTab === 'assets' && renderAssetModal(selectedVendor)}
      {showModal && activeTab === 'maintenance' && renderMaintenanceModal(selectedVendor)}
    </Layout>
  );
};

export default ServiceVendorManagement;