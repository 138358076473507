import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const DutyRosterCalendar = ({ dutyRoster, onStatusUpdate }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const getDutiesForDate = (date) => {
    return dutyRoster.filter((duty) =>
      duty.schedule.some(
        (schedule) =>
          new Date(schedule.date).toDateString() === date.toDateString()
      )
    );
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const getTileContent = ({ date, view }) => {
    if (view === "month") {
      const duties = getDutiesForDate(date);
      if (duties.length > 0) {
        return (
          <div className="duty-indicator">
            <small>{duties.length} duties</small>
          </div>
        );
      }
    }
    return null;
  };

  const DutyRosterDetails = ({ duties, onStatusUpdate }) => {
    return (
      <div className="p-4">
        <h5>Duties for {duties[0]?.date}</h5>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Staff Name</th>
                <th>Activity</th>
                <th>Time</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {duties.map((duty) => (
                <tr key={duty._id}>
                  <td>{duty.name}</td>
                  <td>{duty.activity}</td>
                  <td>{duty.time}</td>
                  <td>{duty.status}</td>
                  <td>
                    <select
                      className="form-select"
                      value={duty.status}
                      onChange={(e) => onStatusUpdate(duty._id, e.target.value)}
                    >
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                      <option value="missed">Missed</option>
                      <option value="rescheduled">Rescheduled</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="duty-roster-calendar">
      <div className="row">
        <div className="col-md-6">
          <Calendar
            onChange={handleDateClick}
            value={selectedDate}
            tileContent={getTileContent}
            className="w-100"
          />
        </div>
        <div className="col-md-6">
          {selectedDate && (
            <div className="selected-date-duties">
              <DutyRosterDetails
                duties={getDutiesForDate(selectedDate)}
                onStatusUpdate={onStatusUpdate}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DutyRosterCalendar;
