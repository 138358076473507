import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import Layout from '../../../component/layout';
import DashboardTab from './tabs/dashboardTab';
import PowerMetersTab from './tabs/powerMetersTab';
import CustomersTab from './tabs/customersTab';
import TransactionsTab from './tabs/transactionsTab';
import SettingsTab from './tabs/settingsTab';

const PowerMeterManagement = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('dashboard');

    const meterTypes = [
        { label: 'Power Meters', value: 'power', path: '/facility/utility_management/meter_management/power_meters' },
        { label: 'Water Meters', value: 'water', path: '/facility/utility_management/meter_management/water_meters' }
    ];

    const handleMeterTypeChange = (value) => {
        const selectedType = meterTypes.find(type => type.value === value);
        navigate(selectedType.path, { replace: true });
    };

    const tabComponents = {
        dashboard: DashboardTab,
        'power-meters': PowerMetersTab,
        customers: CustomersTab,
        transactions: TransactionsTab,
        settings: SettingsTab
    };

    const TabComponent = tabComponents[activeTab];

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb mb-3">
                                <li className="breadcrumb-item">
                                    <Link to="/facility">Dashboard</Link>
                                </li>
                                {/* <li className="breadcrumb-item">
                                    <Link to="/facility/utility_management/meter_types">Utility Management</Link>
                                </li> */}
                                <li className="breadcrumb-item">
                                    <Link to="/facility/utility_management/meter_types">Meters Management</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>Power Meters</span>
                                </li>
                            </ul>
                            <div className="card">
                                <div className="card-header">
                                    <Link to="/facility/utility_management/meter_types" onClick={() => navigate(-1)}>
                                        <i className="ti ti-arrow-narrow-left"></i> Back
                                    </Link>
                                </div>
                            </div>
                            
                            <div className="float-end">
                                <Dropdown
                                    value="power"
                                    options={meterTypes}
                                    onChange={(e) => handleMeterTypeChange(e.value)}
                                    className="w-48"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <button 
                                        className={`nav-link ${activeTab === 'dashboard' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('dashboard')}
                                        type="button"
                                    >
                                        <i className="ti ti-dashboard me-2"></i>Dashboard
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button 
                                        className={`nav-link ${activeTab === 'power-meters' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('power-meters')}
                                        type="button"
                                    >
                                        <i className="ti ti-bolt me-2"></i>Power Meters
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button 
                                        className={`nav-link ${activeTab === 'customers' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('customers')}
                                        type="button"
                                    >
                                        <i className="ti ti-users me-2"></i>Customers
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button 
                                        className={`nav-link ${activeTab === 'transactions' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('transactions')}
                                        type="button"
                                    >
                                        <i className="ti ti-cash me-2"></i>Transactions
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button 
                                        className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('settings')}
                                        type="button"
                                    >
                                        <i className="ti ti-settings me-2"></i>Settings
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="tab-content">
                        <TabComponent />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PowerMeterManagement;