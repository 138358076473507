import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { powerMeters } from '../../../../../app/data/fake';

const PowerMetersTab = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );

    const powerStatusBodyTemplate = (rowData) => {
        return (
            <span className={`badge rounded-pill text-bg-${rowData.status === 'On' ? 'success' : 'danger'}`}>
                {rowData.status}
            </span>
        );
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="col-md-3 col-xs-12 mb-3">
                    <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="dt-responsive table-responsive">
                    <DataTable
                        value={powerMeters}
                        header={header}
                        emptyMessage="No power meters found."
                        sortMode="multiple"
                        paginator
                        rows={5}
                        stripedRows
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="meter_sn" header="Meter S/N"></Column>
                        <Column field="unit" header="Unit"></Column>
                        <Column field="meter_status" header="Meter Status"></Column>
                        <Column field="meter_type" header="Meter Type"></Column>
                        <Column field="consumption_type" header="Consumption Type"></Column>
                        <Column field="initial_reading" header="Initial V (kWh)"></Column>
                        <Column field="current_reading" header="Initila V (kWh)"></Column>
                        <Column field="status" header="Status" body={powerStatusBodyTemplate}></Column>
                        <Column field="last_updated" header="Last Updated"></Column>
                        <Column
                            header="Actions"
                            body={(rowData) => (
                                <Button
                                    icon="ti ti-eye"
                                    className="p-button-rounded p-button-text"
                                    // onClick={() => handleView(rowData)}
                                    tooltip="View Meter"
                                />
                            )}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default PowerMetersTab;