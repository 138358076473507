import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../component/layout'
import {toastify} from '../../../utils/toast'
import {getItem} from '../../../utils/localStorage'
import {makeRequest2} from '../../../utils/makeRequest'
import {createTicketURL} from '../../../utils/urls'

const RaiseTicket = () => {
    const [ticketType, setTicketType] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");
    const [image, setImage] = useState(null);
    const [date, setDate] = useState(new Date());

    const handleSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            // const userId = await getItem('userId');
            const userId = localStorage.getItem("userId");

            if (!subject) throw new Error('Please select a subject');
            if (!description) throw new Error('Please enter a description');

            // Create FormData
            const formData = new FormData();
            formData.append('ticketType', ticketType);
            formData.append('subject', subject);
            formData.append('description', description);
            formData.append('priority', priority);
            formData.append('date', date);
            formData.append('userId', userId);

            if (image) {
                console.log('Appending image:', image);
                formData.append('image', image);
            }

            for (let pair of formData.entries()) {
                console.log(pair[0] + ':', pair[1]);
            }

            const response = await makeRequest2(
                `${createTicketURL}/${userId}/${facilityId}`,
                'POST',
                formData,
            );

            console.log("response", response);

            if (response.success) {
                toastify('Ticket raised successfully', 'success');
                handleClear();
            } else {
                console.log(response.error);
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };





    const handleClear = () => {
        setTicketType('');
        setSubject('');
        setDescription('');
        setPriority('');
        setDate(new Date());
        setImage(null);
    }



    const renderDynamicFields = () => {
        const renderPrioritySection = () => (
            <div className="mb-3">
                <label className="form-label fw-bold">Priority</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="low"
                        value="Low"
                        onChange={(e) => setPriority(e.target.value)}
                        checked={priority === "Low"}
                    />
                    <label className="form-check-label" htmlFor="low">Low</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="medium"
                        value="Medium"
                        onChange={(e) => setPriority(e.target.value)}
                        checked={priority === "Medium"}
                    />
                    <label className="form-check-label" htmlFor="medium">Medium</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="high"
                        value="High"
                        onChange={(e) => setPriority(e.target.value)}
                        checked={priority === "High"}
                    />
                    <label className="form-check-label" htmlFor="high">High</label>
                </div>
            </div>
        );



        switch (ticketType) {
            case "Complaints":
                return (
                    <>
                        <div className="mb-4">
                            <label className="form-label fw-bold">
                                <i className="bi bi-tag"></i> Subject
                            </label>
                            <select
                                className="form-select"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            >
                                <option value="" disabled>-- Select a complaint type --</option>
                                <option value="Noise Levels">Noise Levels</option>
                                <option value="Cleanliness">Cleanliness</option>
                                <option value="Neighbour Issues">Neighbour Issues</option>
                                <option value="Parking">Parking</option>
                                <option value="Infestation">Infestation</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="form-label fw-bold">
                                <i className="bi bi-chat-dots"></i> Description
                            </label>
                            <textarea
                                className="form-control"
                                rows={5}
                                placeholder="Please describe your complaint in detail..."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>
                        {renderPrioritySection()}
                    </>
                );
            case "Maintenance":
                return (
                    <>
                        <div className="mb-4">
                            <label className="form-label fw-bold">
                                <i className="bi bi-tools"></i> Subject
                            </label>
                            <select
                                className="form-select"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            >
                                <option value="" disabled>-- Select a maintenance type --</option>
                                <option value="General Maintenance">General Maintenance</option>
                                <option value="Preventive Maintenance">Preventive Maintenance</option>
                                <option value="Emergency Maintenance">Emergency Maintenance</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="form-label fw-bold">
                                <i className="bi bi-chat-dots"></i> Description
                            </label>
                            <textarea
                                className="form-control"
                                rows={5}
                                placeholder="Please describe the maintenance issue in detail..."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label className="form-label fw-bold">
                                <i className="bi bi-camera"></i> Upload Photo (Optional)
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                            <div className="form-text">Attach a photo for better understanding of the issue.</div>
                        </div>
                        {renderPrioritySection()}
                    </>
                );
            default:
                return null;
        }

    };

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/raise_ticket"}>Raise Ticket</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Raise a Ticket</h5>
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label className="form-label fw-bold">Ticket Type</label>
                                <select className="form-control"
                                    value={ticketType}
                                    onChange={(e) => setTicketType(e.target.value)}
                                >
                                    <option value="">-- Select --</option>
                                    <option value="Complaints">Complaints</option>
                                    <option value="Maintenance">Maintenance</option>
                                </select>
                            </div>

                            {renderDynamicFields()}
                        </div>

                        <div className="card-footer text-end btn-page">
                            <button className="btn btn-outline-secondary"
                                onClick={handleClear}
                            >Clear</button>
                            <button className="btn btn-primary"
                                onClick={handleSubmit}
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div>



        </Layout>
    )
}

export default RaiseTicket