import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { upload_lease_document } from "../../../../utils/urls";

const UploadLeaseDocument = ({ leaseId, visible, onHide, onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUpload = async (event) => {
    try {
      setUploading(true);
      setUploadProgress(0);
      
      const facilityId = await getItem("selectedFacilityId");
      
      if (!facilityId) {
        throw new Error("Facility ID not found");
      }

      if (!leaseId) {
        throw new Error("Lease ID is required");
      }

      const file = event.files[0];
      console.log("Uploading file:", file.name);
      
      // Create form data for file upload
      const formData = new FormData();
      formData.append("document", file); // Use "document" as the field name to match the backend
      
      // Set up an interval to simulate upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          return prev < 90 ? prev + 10 : prev;
        });
      }, 300);

      try {
        console.log(`Uploading to: ${upload_lease_document}/${facilityId}/${leaseId}`);
        
        // Use the correct route from utils/urls.js
        const response = await makeRequest2(
          `/upload_lease_document/${facilityId}/${leaseId}`, // Use actual route from backend
          "POST",
          formData,
          true // Set to true for multipart/form-data
        );

        clearInterval(progressInterval);
        setUploadProgress(100);

        console.log("Upload response:", response);

        if (response.success) {
          toastify("Document uploaded successfully", "success");
          
          // Call the onUploadSuccess callback with the leaseId
          if (onUploadSuccess) {
            setTimeout(() => {
              onUploadSuccess(leaseId);
            }, 500); // Small delay to ensure the progress bar completes
          }
        } else {
          throw new Error(response.error || "Failed to upload document");
        }
      } catch (error) {
        clearInterval(progressInterval);
        throw error;
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      toastify(error.message || "Error uploading document", "error");
      setUploadProgress(0);
    } finally {
      setUploading(false);
    }
  };

  const footer = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
        disabled={uploading}
      />
    </div>
  );

  return (
    <Dialog
      header="Upload Lease Document"
      visible={visible}
      style={{ width: "50vw" }}
      footer={footer}
      onHide={onHide}
      dismissableMask={!uploading}
      closable={!uploading}
    >
      <div className="p-fluid">
        <div className="card">
          <h5 className="mb-3">Upload Signed Lease Agreement</h5>
          <p className="text-muted mb-3">
            Upload the signed lease agreement document. This will automatically update the lease status to Active.
          </p>
          
          {uploading && (
            <div className="mb-3">
              <div className="progress">
                <div 
                  className="progress-bar progress-bar-striped progress-bar-animated" 
                  role="progressbar" 
                  style={{ width: `${uploadProgress}%` }}
                >
                  {uploadProgress}%
                </div>
              </div>
            </div>
          )}
          
          <FileUpload
            name="document" // Use "document" as the field name to match backend
            url="/api/fake-upload-url" // This is a placeholder, actual upload is handled by handleUpload
            accept="application/pdf, image/*"
            maxFileSize={5000000}
            customUpload={true}
            uploadHandler={handleUpload}
            auto
            chooseLabel="Select Document"
            uploadLabel="Upload"
            cancelLabel="Cancel"
            disabled={uploading}
            emptyTemplate={
              <div className="p-d-flex p-flex-column p-ai-center">
                <i className="ti ti-upload" style={{ fontSize: '2rem', color: '#ccc', marginBottom: '1rem' }}></i>
                <p className="text-center m-0">
                  Drag and drop a file here or click to select a file to upload.
                </p>
                <p className="text-center text-muted">Supported formats: PDF, JPG, PNG</p>
              </div>
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UploadLeaseDocument;