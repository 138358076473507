import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { create_lease_penalty } from "../../../../utils/urls";

const CreatePenalty = ({ isDialog = false, onSuccess }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [penaltyData, setPenaltyData] = useState({
    name: "",
    type: "percentage",
    effectDays: "1",
    percentage: "",
    amount: "",
    isActive: true
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPenaltyData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleClear = () => {
    setPenaltyData({
      name: "",
      type: "percentage",
      effectDays: "1",
      percentage: "",
      amount: "",
      isActive: true
    });
  };

  const validateForm = () => {
    if (!penaltyData.name.trim()) {
      toastify("Penalty name is required", "error");
      return false;
    }

    if (penaltyData.type === "percentage") {
      if (!penaltyData.percentage || 
          isNaN(penaltyData.percentage) || 
          Number(penaltyData.percentage) < 0 || 
          Number(penaltyData.percentage) > 100) {
        toastify("Please enter a valid percentage between 0 and 100", "error");
        return false;
      }
    }

    if (penaltyData.type === "fixed") {
      if (!penaltyData.amount || 
          isNaN(penaltyData.amount) || 
          Number(penaltyData.amount) <= 0) {
        toastify("Please enter a valid positive amount", "error");
        return false;
      }
    }

    if (![1, 3, 7].includes(Number(penaltyData.effectDays))) {
      toastify("Effect days must be either 1, 3, or 7", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm() || isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const facilityId = await getItem("selectedFacilityId");

      if (!facilityId) {
        toastify("Facility ID not found", "error");
        return;
      }

      const formData = {
        ...penaltyData,
        effectDays: Number(penaltyData.effectDays),
        percentage: penaltyData.type === "percentage" ? Number(penaltyData.percentage) : undefined,
        amount: penaltyData.type === "fixed" ? Number(penaltyData.amount) : undefined,
        module: 'lease',
        isActive: true
      };

      const response = await makeRequest2(
        `${create_lease_penalty}/${facilityId}`,
        "POST",
        formData
      );

      if (response.success) {
        toastify("Penalty created successfully", "success");
        if (isDialog) {
          onSuccess?.(); // Using optional chaining
        } else {
          navigate(-1);
        }
      } else {
        toastify(response.error || "Failed to create penalty", "error");
      }
    } catch (error) {
      toastify(error.message || "An unexpected error occurred", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const content = (
    <div className="card-body">
      <div className="row">
        <div className="col-sm-6">
          <label className="form-label">Penalty Name</label>
          <InputText
            className="form-control"
            name="name"
            value={penaltyData.name}
            onChange={handleInputChange}
            placeholder="Enter penalty name"
          />
        </div>

        <div className="col-sm-6">
          <label className="form-label">Penalty Type</label>
          <select
            className="form-control"
            name="type"
            value={penaltyData.type}
            onChange={handleInputChange}
          >
            <option value="percentage">Percentage</option>
            <option value="fixed">Fixed Amount</option>
          </select>
        </div>

        {penaltyData.type === "percentage" ? (
          <div className="col-sm-6 mt-3">
            <label className="form-label">Percentage (%)</label>
            <InputText
              type="number"
              className="form-control"
              name="percentage"
              value={penaltyData.percentage}
              onChange={handleInputChange}
              placeholder="Enter percentage"
              min="0"
              max="100"
            />
          </div>
        ) : (
          <div className="col-sm-6 mt-3">
            <label className="form-label">Fixed Amount</label>
            <InputText
              type="number"
              className="form-control"
              name="amount"
              value={penaltyData.amount}
              onChange={handleInputChange}
              placeholder="Enter amount"
              min="0"
            />
          </div>
        )}

        <div className="col-sm-6 mt-3">
          <label className="form-label">Effect Days</label>
          <select
            className="form-control"
            name="effectDays"
            value={penaltyData.effectDays}
            onChange={handleInputChange}
          >
            <option value="1">1 Day</option>
            <option value="3">3 Days</option>
            <option value="7">7 Days</option>
          </select>
        </div>
      </div>

      <div className="text-end mt-4">
        <Button
          type="button"
          className="btn btn-outline-secondary me-2"
          onClick={handleClear}
          disabled={isSubmitting}
        >
          Clear
        </Button>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Creating..." : "Create Penalty"}
        </Button>
      </div>
    </div>
  );

  if (isDialog) {
    return content;
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/lease_management/lease_penalties">
                    Lease Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Create Penalty</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Create New Penalty</h5>
            </div>
            {content}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreatePenalty;