import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation ,useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { smart_meters_base_url, customer_account_base_url } from "../../../../../utils/urls";
import { toastify } from '../../../../../utils/toast';
import Layout from '../../../component/layout';
import { useFacilityStore } from '../../../../../app/z-store/store';

function ViewCustomer() {
    const navigate = useNavigate();
    const { meterId } = useParams();
    const location = useLocation();
    const accountId = location.state?.accountId;
    const toast = useRef(null);
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);
    const defaultCustomerData = {
        name: 'N/A',
        id: 'N/A',
        accountNumber: 'N/A',
        meterNumber: 'N/A',
        status: 'N/A',
        phone: 'N/A',
        postpaidBalance: 0.0,
        prepaidBalance: 0.0,
        userType: 'N/A'
    };

    const [customerData, setCustomerData] = useState(defaultCustomerData);
    // activeTab now controls which type of invoices to fetch: 'postpaid' or 'prepaid'
    const [activeTab, setActiveTab] = useState('postpaid');
    const [loading, setLoading] = useState(false);

    // States for invoice table
    const [invoices, setInvoices] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState(null);
    const dt = useRef(null);

    const statusOptions = [
        { label: 'Pending', value: 'Pending' },
        { label: 'Paid', value: 'Paid' },
        { label: 'Partially Paid', value: 'Partially Paid' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Overdue', value: 'Overdue' },
        { label: 'Unpaid', value: 'Unpaid' }
    ];

    useEffect(() => {
        if (accountId) {
            fetchCustomerDataById(accountId);
        } else if (meterId) {
            fetchCustomerDataByMeterId(meterId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, meterId]);

    // Fetch invoices when the customer account number is available or filters change
    useEffect(() => {
        if (customerData.accountNumber !== 'N/A' && selectedFacilityId) {
            fetchCustomerInvoices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData.accountNumber, activeTab, lazyParams, statusFilter, selectedFacilityId]);

    const fetchCustomerDataById = async (id) => {
        try {
            setLoading(true);
            const response = await makeRequest2(
                `${customer_account_base_url}/get/${id}`,
                'GET',
                null
            );

            if (response.success) {
                const accountData = response.data.data;
                setCustomerData({
                    id: accountData._id || accountData.id,
                    name: accountData.customerName || 'N/A',
                    accountNumber: accountData.account_no || 'N/A',
                    meterNumber: accountData.meterNumber || 'N/A',
                    status: accountData.status || 'N/A',
                    phone: accountData.phoneNumber || 'N/A',
                    postpaidBalance: accountData.accountBalance || 0.0,
                    prepaidBalance: accountData.negativeBalance || 0.0,
                    userType: accountData.payment_type || 'N/A'
                });

                setActiveTab(
                    accountData.payment_type &&
                        accountData.payment_type.toLowerCase() === 'prepaid'
                        ? 'prepaid'
                        : 'postpaid'
                );
            } else {
                toastify('Error fetching customer data', 'error');
            }
        } catch (error) {
            toastify('Error fetching customer data', 'error');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomerDataByMeterId = async (meterId) => {
        try {
            setLoading(true);
            const response = await makeRequest2(
                `${smart_meters_base_url}/customer/${meterId}`,
                'GET',
                null
            );

            if (response.success) {
                const accountData = response.data;
                setCustomerData({
                    id: accountData._id || accountData.id,
                    name: accountData.customerName || 'N/A',
                    accountNumber: accountData.account_no || 'N/A',
                    meterNumber: accountData.meterNumber || 'N/A',
                    status: accountData.status || 'N/A',
                    phone: accountData.phoneNumber || 'N/A',
                    postpaidBalance: accountData.accountBalance || 0.0,
                    prepaidBalance: accountData.negativeBalance || 0.0,
                    userType: accountData.payment_type || 'N/A'
                });

                setActiveTab(
                    accountData.payment_type &&
                        accountData.payment_type.toLowerCase() === 'prepaid'
                        ? 'prepaid'
                        : 'postpaid'
                );
            } else {
                toastify('Error fetching customer data', 'error');
            }
        } catch (error) {
            toastify('Error fetching customer data', 'error');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomerInvoices = async () => {
        try {
            setLoading(true);
            const skip = lazyParams.first;
            const limit = lazyParams.rows;

            // Modified endpoint to include facilityId
            let endpoint = `/api/app/water-invoices/get/${customerData.accountNumber}?facilityId=${selectedFacilityId}&billingType=${activeTab}&limit=${limit}&skip=${skip}`;

            if (statusFilter) {
                endpoint += `&status=${statusFilter}`;
            }

            const response = await makeRequest2(endpoint, 'GET');
            if (response.success) {
                const invoicesData = response.data?.invoices || [];
                setInvoices(invoicesData);
                setTotalRecords(response.data?.totalCount || 0);
            } else {
                toastify('Failed to fetch customer invoices', 'error');
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
            toastify('Error loading customer invoices', 'error');
        } finally {
            setLoading(false);
        }
    };

    const onPage = (event) => {
        setLazyParams(event);
    };

    const handleView = (invoice) => {
        // Navigate to the invoice page with the invoice ID as state
        navigate("/facility/utility_management/invoice_management/invoice_page", { 
            state: { 
                invoiceId: invoice._id,
                invoiceNumber: invoice.invoiceNumber
            } 
        });
    };

    // Helpers for table formatting
    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            'Pending': 'bg-info',
            'Paid': 'bg-success',
            'Partially Paid': 'bg-warning',
            'Cancelled': 'bg-secondary',
            'Overdue': 'bg-danger',
            'Unpaid': 'bg-danger'
        };
        return (
            <span className={`badge ${statusColors[rowData.status] || 'bg-secondary'}`}>
                {rowData.status || 'unknown'}
            </span>
        );
    };

    const formatNumber = (value) => {
        return new Intl.NumberFormat('en-KE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    const invoiceAmountBodyTemplate = (rowData) => {
        return formatNumber(rowData.charges?.totalMonthlyBill || 0);
    };

    const amountPaidBodyTemplate = (rowData) => {
        return formatNumber(rowData.amountPaid || 0);
    };

    const balanceBodyTemplate = (rowData) => {
        const balance = (rowData.charges?.totalMonthlyBill || 0) - (rowData.amountPaid || 0);
        return formatNumber(balance);
    };

    const dateBodyTemplate = (rowData, field) => {
        return rowData[field] ? new Date(rowData[field]).toLocaleDateString() : 'N/A';
    };

    const exportCSV = () => {
        const header = [
            'Invoice No', 'Invoice Date', 'Invoice (KES)', 'Paid Amount (KES)',
            'Balance (KES)', 'Status', 'Due Date', 'Month'
        ];

        const data = invoices.map(invoice => [
            invoice.invoiceNumber,
            new Date(invoice.dateIssued).toLocaleDateString(),
            invoice.charges?.totalMonthlyBill || 0,
            invoice.amountPaid || 0,
            (invoice.charges?.totalMonthlyBill || 0) - (invoice.amountPaid || 0),
            invoice.status,
            new Date(invoice.dueDate).toLocaleDateString(),
            invoice.yearMonth
        ]);

        const csvContent = [
            header.join(','),
            ...data.map(row => row.map(cell =>
                typeof cell === 'string' && cell.includes(',')
                    ? `"${cell}"`
                    : cell
            ).join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute(
            'download',
            `Customer_Invoices_${customerData.accountNumber}_${new Date()
                .toISOString()
                .slice(0, 10)}.csv`
        );
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Invoices exported to CSV', life: 3000 });
    };

    // Optionally filter invoices client-side if needed (e.g., search)
    const filteredInvoices = searchTerm
        ? invoices.filter((invoice) =>
            Object.values(invoice).some((value) =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            ))
        : invoices;

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/facility/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/facility/utility_management/meter_management/water_meters">
                                        Water Meters
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>View Customer</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <Link
                                to="/facility/utility_management/meter_management/water_meters"
                                state={{ activeTab: "customers" }}
                            >
                                <i className="ti ti-arrow-narrow-left"></i> Back
                            </Link>
                        </div>
                    </div>

                    {loading && customerData.id === 'N/A' ? (
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* Customer Info Header */}
                            <div className="card mb-3">
                                <div className="card-body d-flex align-items-center">
                                    <div className="avatar avatar-xl bg-light-primary rounded-circle me-3">
                                        <i className="ti ti-user f-22"></i>
                                    </div>
                                    <div>
                                        <h4 className="mb-1">{customerData.name}</h4>
                                        <p className="mb-0">ACCOUNT NO: {customerData.accountNumber}</p>
                                        <p className="mb-0">METER NO: {customerData.meterNumber}</p>
                                        <p className="mb-0">PHONE: {customerData.phone}</p>
                                        <span className={`badge ${customerData.status.toLowerCase() === 'active' ? 'bg-success' : 'bg-danger'}`}>
                                            {customerData.status}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Balance and Action Buttons */}
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row g-3 mb-4">
                                        <div className="col-md-6">
                                            <div className="card mb-0">
                                                <div className="card-body">
                                                    <h6 className="mb-2">Postpaid Balance</h6>
                                                    <h3 className="mb-0">
                                                        KES {typeof customerData.postpaidBalance === "number"
                                                            ? customerData.postpaidBalance.toFixed(2)
                                                            : "0.00"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card mb-0">
                                                <div className="card-body">
                                                    <h6 className="mb-2">Prepaid Balance</h6>
                                                    <h3 className="mb-0">
                                                        KES {typeof customerData.prepaidBalance === "number"
                                                            ? customerData.prepaidBalance.toFixed(2)
                                                            : "0.00"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Active Billing Type Buttons */}
                            <div className="mb-3 text-center">
                                <Button
                                    label="Post Paid Invoices"
                                    onClick={() => setActiveTab('postpaid')}
                                    className={activeTab === 'postpaid' ? 'p-button-primary' : 'p-button-outlined'}
                                    style={{ marginRight: '0.5rem' }}
                                />
                                <Button
                                    label="Pre Paid Invoices"
                                    onClick={() => setActiveTab('prepaid')}
                                    className={activeTab === 'prepaid' ? 'p-button-primary' : 'p-button-outlined'}
                                />
                            </div>

                            {/* Invoice Table and Filters */}
                            <div className="card">
                                <div className="card-body">
                                    <Toast ref={toast} />
                                    <h5 className="card-title mb-3">Customer Invoices</h5>
                                    <div className="d-flex flex-column flex-md-row justify-content-between mb-3 gap-2">
                                        <div className="d-flex flex-column flex-md-row gap-2">
                                            <div className="col-md-4">
                                                <input
                                                    className="form-control"
                                                    placeholder="Search invoices..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <Dropdown
                                                    value={statusFilter}
                                                    options={statusOptions}
                                                    onChange={(e) => setStatusFilter(e.value)}
                                                    placeholder="Filter by Status"
                                                    className="w-100"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Button
                                                label="CSV"
                                                icon="ti ti-file-spreadsheet"
                                                className="p-button-success"
                                                onClick={exportCSV}
                                            />
                                        </div>
                                    </div>
                                    <div className="dt-responsive table-responsive">
                                        <DataTable
                                            ref={dt}
                                            value={filteredInvoices}
                                            emptyMessage="No invoices found for this customer."
                                            sortMode="multiple"
                                            paginator
                                            lazy
                                            first={lazyParams.first}
                                            rows={lazyParams.rows}
                                            totalRecords={totalRecords}
                                            onPage={onPage}
                                            loading={loading}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="invoiceNumber" header="Inv No" sortable />
                                            <Column
                                                field="dateIssued"
                                                header="Invoice Date"
                                                body={(rowData) => dateBodyTemplate(rowData, 'dateIssued')}
                                                sortable
                                            />
                                            <Column
                                                header="Invoice (KES)"
                                                body={invoiceAmountBodyTemplate}
                                                sortable
                                            />
                                            <Column
                                                field="amountPaid"
                                                header="Paid Amount (KES)"
                                                body={amountPaidBodyTemplate}
                                                sortable
                                            />
                                            <Column
                                                header="Balance (KES)"
                                                body={balanceBodyTemplate}
                                                sortable
                                            />
                                            <Column field="status" header="Status" body={statusBodyTemplate} sortable />
                                            <Column
                                                field="dueDate"
                                                header="Due Date"
                                                body={(rowData) => dateBodyTemplate(rowData, 'dueDate')}
                                                sortable
                                            />
                                            <Column field="yearMonth" header="Month" sortable />
                                            <Column
                                                header="Actions"
                                                body={(rowData) => (
                                                    <Button
                                                        icon="ti ti-eye"
                                                        className="p-button-rounded p-button-text"
                                                        onClick={() => handleView(rowData)}
                                                        tooltip="View Invoice"
                                                    />
                                                )}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default ViewCustomer;