import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { customers1 } from '../../../../../app/data/fake';

const CustomersTab = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );

    const customersBodyTemplate = (rowData) => {
        const statusColors = {
            'active': 'bg-success',
            'inactive': 'bg-warning',
            'suspended': 'bg-danger'
        };

        return (
            <span className={`badge ${statusColors[rowData.status]}`}>
                {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
            </span>
        );
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="col-md-3 col-xs-12 mb-3">
                    <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="dt-responsive table-responsive">
                    <DataTable
                        value={customers1}
                        header={header}
                        emptyMessage="No water customers found."
                        sortMode="multiple"
                        paginator
                        rows={5}
                        stripedRows
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="fullname" header="Fullname"></Column>
                        <Column field="phone" header="Phone Number"></Column>
                        <Column field="meter_no" header="Meter No"></Column>
                        <Column field="unit" header="Unit No"></Column>
                        <Column field="payment_type" header="Payment Type"></Column>
                        <Column field="acc_balance" header="Account Bal"></Column>
                        <Column field="neg_balance" header="Negative Bal"></Column>
                        <Column field="status" header="Status" body={customersBodyTemplate}></Column>
                        <Button
                            icon="ti ti-eye"
                            className="p-button-rounded p-button-text"
                            // onClick={() => handleView(rowData)}
                            tooltip="View Customer"
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default CustomersTab;