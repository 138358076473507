import { useState, useEffect, useCallback } from 'react';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getItem } from '../../../../utils/localStorage';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
    updateServiceRequest,
    assignWorkOrder,
    addServiceInvoice,
    getVasInvoices,
    getVasInvoice,
    getVasVendors,
    getVasServices,
    addServiceRequest,
    getFacilityUnits
} from '../../../../utils/urls';

export const useFacilityServiceRequests = (refreshRequests) => {
    // Service request states
    const [selectedFacilityServiceRequest, setSelectedFacilityServiceRequest] = useState(null);
    const [isServiceManagementModalVisible, setServiceManagementModalVisible] = useState(false);
    const [showApprovalMatrix, setShowApprovalMatrix] = useState(false);
    const [showServiceForm, setShowServiceForm] = useState(false);

    // Add request modal states
    const [isAddRequestModalVisible, setIsAddRequestModalVisible] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [brief, setBrief] = useState('');
    const [quotedPrice, setQuotedPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [units, setUnits] = useState([]);
    const [services, setServices] = useState([]);

    // Service provider states
    const [serviceProviderType, setServiceProviderType] = useState('');
    const [vendors, setVendors] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedAssignee, setSelectedAssignee] = useState(null);

    // Other states
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [invoices, setInvoices] = useState([]);

    // Form states
    const [unitManagementForm, setUnitManagementForm] = useState({
        amount: 0,
        frequency: '',
        rentPercentage: 0
    });

    const [commissionForm, setCommissionForm] = useState({
        type: '',
        amount: 0,
        percentageAmount: 0,
        rentAmount: 0
    });

    const [workOrderForm, setWorkOrderForm] = useState({
        description: '',
        pricing: 0,
        vendorId: '',
        serviceId: ''
    });

    // In the useFacilityServiceRequests hook

    // Initialize data and handle provider type changes
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const facilityId = await getItem('selectedFacilityId');
                if (!facilityId) {
                    throw new Error('Facility ID not found');
                }

                setLoading(true);

                // Only fetch initial data (services and units) when component mounts
                if (!services.length || !units.length) {
                    const [servicesResponse, unitsResponse] = await Promise.all([
                        makeRequest2(
                            `${getVasServices}/${facilityId}`,
                            'GET'
                        ),
                        makeRequest2(
                            `${getFacilityUnits}/${facilityId}`,
                            'GET'
                        )
                    ]);

                    if (servicesResponse.success) {
                        const serviceOptions = servicesResponse.data.services.map(service => ({
                            label: service.serviceName,
                            value: service._id
                        }));
                        setServices(serviceOptions);
                    } else {
                        console.error('Failed to fetch services:', servicesResponse.message);
                    }

                    if (unitsResponse.success) {

                        // More robust handling of units
                        const unitData = unitsResponse.data?.units || unitsResponse.data || [];

                        if (Array.isArray(unitData)) {
                            const unitOptions = unitData.map(unit => ({
                                value: unit._id,
                                label: unit.name
                            }));
                            setUnits(unitOptions);
                        } else {
                            console.error('Units is not an array:', unitData);
                            toastify('Error processing units data', 'error');
                        }
                    } else {
                        console.error('Failed to fetch units:', unitsResponse.message);
                        toastify(unitsResponse.message || 'Error fetching units', 'error');
                    }
                }

                // Handle provider type changes
                if (selectedService && serviceProviderType) {
                    if (serviceProviderType === 'external') {
                        await getVendorsForService(selectedService);
                    } else if (serviceProviderType === 'internal') {
                        await fetchEmployees();
                    }
                    // Reset assignee when changing provider type
                    setSelectedAssignee(null);
                }

            } catch (error) {
                console.error('Error in fetchInitialData:', error);
                toastify(error.message || 'Error fetching data', 'error');
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [serviceProviderType, selectedService]);

    const getVendorsForService = async (serviceId) => {
        try {
            if (!serviceId) {
                throw new Error('Service ID is required');
            }

            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');

            if (!facilityId) {
                throw new Error('Facility ID not found');
            }

            const response = await makeRequest2(`${getVasVendors}/${facilityId}`, 'GET');

            if (response?.success && Array.isArray(response.data?.vendors)) {
                const filteredVendors = response.data.vendors.filter(vendor => {
                    if (!vendor.offers || !Array.isArray(vendor.offers)) {
                        return false;
                    }
                    return vendor.offers.some(offer => offer.serviceId === serviceId);
                });

                const formattedVendors = filteredVendors.map(vendor => {
                    const serviceOffer = vendor.offers.find(o => o.serviceId === serviceId);
                    return {
                        value: vendor._id,
                        label: vendor.name,
                        pricing: serviceOffer?.amount || 0
                    };
                });

                setVendors(formattedVendors);
            } else {
                throw new Error(response?.message || 'Invalid response from vendors API');
            }
        } catch (error) {
            console.error('Error fetching vendors:', error);
            toastify('Error fetching vendors', 'error');
            setVendors([]);
        } finally {
            setLoading(false);
        }
    };

    // Invoice Management Functions
    const fetchInvoices = async () => {
        try {
            setLoading(true);
            setError(null);
            const facilityId = await getItem("selectedFacilityId");
            
            if (!facilityId) {
                throw new Error('Facility ID not found');
            }
            
            console.log('Fetching VAS invoices for facility:', facilityId);
            
            const response = await makeRequest2(
                `${getVasInvoices}/${facilityId}`,
                'GET'
            );
            
            console.log('Raw response from getVasInvoices:', response);

            // First, check if response is successful and has data
            if (!response.success) {
                throw new Error(response.error || 'Failed to fetch invoices');
            }
            
            // Handle different response structures
            let invoicesData = [];
            
            if (response.data?.data && Array.isArray(response.data.data)) {
                console.log('Found invoices array in response.data.data');
                invoicesData = response.data.data;
            } else if (response.data && Array.isArray(response.data)) {
                console.log('Found invoices array in response.data');
                invoicesData = response.data;
            } else {
                console.error('Unexpected response structure:', response);
                throw new Error('Invalid response format: invoices data not found');
            }
            
            console.log(`Processing ${invoicesData.length} invoices`);

            // Process the invoices data
            const formattedInvoices = invoicesData.map(invoice => {
                // Get customer name from customerInfo if available, or extract from raw data
                const customerName = invoice.customerInfo?.fullName || 
                                     invoice.customerName || 
                                     'Customer';
                
                // Format currency with symbol
                const currencySymbol = invoice.currency?.symbol || 'KSh';
                const amount = typeof invoice.amount === 'number' ? invoice.amount : 0;
                
                return {
                    _id: invoice._id,
                    invoiceNumber: invoice.invoiceNumber || 'N/A',
                    accountNumber: invoice.accountNumber || 'N/A',
                    customerName: customerName,
                    serviceName: invoice.serviceName || 'Service',
                    status: invoice.status || 'Pending',
                    amount: amount,
                    formattedAmount: formatCurrency(amount, currencySymbol),
                    formattedDate: formatDate(invoice.createdAt || invoice.dueDate),
                    rawData: invoice // Keep the raw data for reference
                };
            });
            
            console.log('Formatted invoices data:', formattedInvoices);
            setInvoices(formattedInvoices);
            return formattedInvoices;
        } catch (error) {
            console.error('Error fetching invoices:', error);
            setError(error.message || 'Failed to fetch invoices');
            toastify(error.message || 'Failed to fetch invoices', 'error');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const fetchSingleInvoice = async (facilityId, invoiceId) => {
        try {
            setLoading(true);
            const response = await makeRequest2(`${getVasInvoice}/${facilityId}/${invoiceId}`, "GET", {});

            console.log('Raw response from getVasInvoice:', response);

            if (response.success) {
                // Handle different response structures
                let invoiceData = null;
                
                if (response.data?.data) {
                    invoiceData = response.data.data;
                } else if (response.data?.invoice) {
                    invoiceData = response.data.invoice;
                } else if (response.data) {
                    invoiceData = response.data;
                }
                
                if (!invoiceData) {
                    throw new Error('Invoice data not found in response');
                }
                
                // Add formatted customer name
                const customerInfo = {
                    fullName: invoiceData.customerInfo?.fullName || 
                              invoiceData.customerName || 
                              'Customer'
                };
                
                return {
                    ...invoiceData,
                    customerInfo,
                    fullName: customerInfo.fullName
                };
            } else {
                throw new Error(response.message || "Failed to fetch invoice");
            }
        } catch (error) {
            console.error('Error fetching single invoice:', error);
            setError(error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const fetchCompanyDetails = async (facilityId) => {
        try {
            const response = await makeRequest2(
                `/api/facilities/${facilityId}/details`,
                "GET"
            );
            if (response.success) {
                return response.data;
            }
            throw new Error('Failed to fetch company details');
        } catch (error) {
            console.error("Failed to fetch company details:", error);
            throw error;
        }
    };

    const downloadInvoicePDF = async (elementId, invoice) => {
        try {
            setLoading(true);
            const element = document.getElementById(elementId);
            if (!element) {
                throw new Error('Element not found');
            }

            const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
            const filename = `Invoice_${invoice?.invoiceNumber || 'unknown'}_${timestamp}.pdf`;

            const canvas = await html2canvas(element, {
                scale: 2,
                useCORS: true,
                logging: false,
                backgroundColor: "#ffffff",
            });

            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a4",
            });

            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");

            if (imgHeight > pageHeight) {
                let remainingHeight = imgHeight;
                let position = -pageHeight;

                while (remainingHeight > 0) {
                    pdf.addPage();
                    position -= pageHeight;
                    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
                    remainingHeight -= pageHeight;
                }
            }

            pdf.save(filename);
            return true;
        } catch (error) {
            console.error('Error generating PDF:', error);
            toastify('Error generating PDF', 'error');
            throw error;
        } finally {
            setLoading(false);
        }
    };


    const fetchEmployees = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');

            if (!facilityId) {
                throw new Error('Facility ID not found');
            }

            const response = await makeRequest2(
                `/api/employees/${facilityId}`,
                'GET',
                null
            );

            if (response.success && response.data?.employees) {
                const employeesList = response.data.employees.map(employee => ({
                    value: employee._id,
                    label: employee.name,
                    ...employee
                }));
                setEmployees(employeesList);
            } else {
                throw new Error('Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error fetching employees:', error);
            toastify('Error fetching employees', 'error');
            setEmployees([]);
        } finally {
            setLoading(false);
        }
    };

    const handleVendorSelect = (value) => {
        const selectedVendor = vendors.find(v => v.value === value);

        // When an external vendor is selected, prefill the quoted price
        if (selectedVendor && serviceProviderType === 'external') {
            setQuotedPrice(selectedVendor.pricing || 0);
        }

        setWorkOrderForm(prev => ({
            ...prev,
            vendorId: value,
            pricing: selectedVendor?.pricing || 0
        }));
    };
    const handleAddServiceRequest = async () => {
        try {
            if (!selectedUnit || !selectedService || !brief ||
                !quotedPrice || !finalPrice || !serviceProviderType || !selectedAssignee) {
                toastify('Please fill in all required fields', 'error');
                return;
            }

            setLoading(true);

            // Retrieve facility ID
            const facilityId = await getItem('selectedFacilityId');
            if (!facilityId) {
                throw new Error('Facility ID is missing');
            }

            // Get the full unit details from the backend
            const unitDetailResponse = await makeRequest2(
                `${getFacilityUnits}/${facilityId}`,
                'GET'
            );

            let customerId = null;
            let unitName = ''; // Declare unitName variable

            if (unitDetailResponse.success) {
                // Find the specific unit details
                const occupiedUnits = unitDetailResponse.data.occupiedUnits || [];
                const specificUnitDetails = occupiedUnits.find(unit => unit._id === selectedUnit);

                // Get the unit name from specificUnitDetails
                unitName = specificUnitDetails?.name || ''; // Assign with default empty string

                // Try to find a customer ID
                customerId =
                    specificUnitDetails?.residentId ||
                    specificUnitDetails?.tenantId ||
                    specificUnitDetails?.homeOwnerId;
            }

            // Validate unit name
            if (!unitName) {
                throw new Error('Unit name not found');
            }

            // If still no customerId, prompt user to select a different unit
            if (!customerId) {
                throw new Error(`No customer associated with unit ${unitName}. 
                    Please select a unit with an associated resident, tenant, or home owner.`);
            }

            // Sanitize IDs
            const sanitizedFacilityId = sanitizeId(facilityId);
            const sanitizedCustomerId = sanitizeId(customerId);
            const sanitizedAssigneeId = sanitizeId(selectedAssignee);

            const data = {
                facilityId: sanitizedFacilityId,
                serviceId: selectedService,
                customerId: sanitizedCustomerId,
                unit: unitName,
                brief,
                amount: [quotedPrice, finalPrice],
                assigneeId: sanitizedAssigneeId,
                serviceProviderType,
                status: 'In Progress'
            };

            console.log('Submission Data:', data);

            const response = await makeRequest2(
                `${addServiceRequest}/${sanitizedFacilityId}`,
                'POST',
                data
            );

            const workOrderData = {
                facilityId,
                requesterId: customerId,
                description: `Work order for ${getServiceName(selectedService)}`,
                pricing: quotedPrice,
                assigneeId: sanitizedAssigneeId,
                type: 'unscheduled'
            };

            await makeRequest2(
                `${assignWorkOrder}/${facilityId}`,
                'POST',
                workOrderData
            );

            const invoiceData = {
                facilityId,
                serviceId: selectedService,
                customerId,
                amount: finalPrice,
                status: 'Pending'
            };

            await makeRequest2(
                `${addServiceInvoice}/${facilityId}`,
                'POST',
                invoiceData
            );

            if (response.success) {
                toastify('Service request added successfully', 'success');
                setIsAddRequestModalVisible(false);
                resetAddRequestForm();
                await refreshRequests();
            } else {
                throw new Error(response.error || 'Failed to add request');
            }
        } catch (error) {
            console.error('Full Error in handleAddServiceRequest:', error);
            toastify(error.message || 'Error adding service request', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Utility function to get service name
    const getServiceName = useCallback((serviceId) => {
        const service = services.find(service =>
            service.value.toString() === serviceId.toString()
        );
        return service ? service.label : 'Service not found';
    }, [services]);

    // Utility function to sanitize IDs
    const sanitizeId = (id) => {
        if (!id) return null;

        if (typeof id === 'object') {
            return id.$oid || id.toString();
        }

        return id.toString();
    };


    const resetAddRequestForm = () => {
        setSelectedUnit(null);
        setSelectedService(null);
        setBrief('');
        setQuotedPrice(0);
        setFinalPrice(0);
        setServiceProviderType('');
        setSelectedAssignee(null);
    };

    // Service request handlers
    const handleApprove = async () => {
        setShowApprovalMatrix(false);
        setShowServiceForm(true);
        await refreshRequests();
    };

    const handleReject = async (serviceId) => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            const data = {
                serviceRequestId: serviceId,
                status: 'Cancelled'
            };

            const url = updateServiceRequest + '/' + facilityId;
            await makeRequest2(url, 'POST', data);

            toastify('Request cancelled successfully', 'success');
            setShowApprovalMatrix(false);
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error rejecting request', 'error');
        }
    };

    const handleServiceManagementClick = async (rowData) => {
        setSelectedFacilityServiceRequest(rowData);
        setWorkOrderForm(prev => ({
            ...prev,
            serviceId: rowData.serviceId
        }));

        try {
            await getVendorsForService(rowData.serviceId);
        } catch (error) {
            toastify('Error loading vendors', 'error');
        }

        setServiceManagementModalVisible(true);
        setShowApprovalMatrix(true);
        setShowServiceForm(false);
    };

    // Form submission handlers
    const handleUnitManagementSubmit = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            const totalRent = calculateTotalRent(unitManagementForm.rentAmount, unitManagementForm.frequency);
            const unitAmount = calculateAmount(totalRent, unitManagementForm.percentage);

            const invoiceData = {
                facilityId,
                serviceId: selectedFacilityServiceRequest._id,
                customerId: selectedFacilityServiceRequest.customerId,
                amount: unitAmount,
                status: 'Pending'
            };

            const serviceData = {
                serviceRequestId: selectedFacilityServiceRequest._id,
                status: 'Completed'
            };

            const serviceUrl = updateServiceRequest + '/' + facilityId;
            await makeRequest2(serviceUrl, 'POST', serviceData);

            const invoiceUrl = addServiceInvoice + '/' + facilityId;
            await makeRequest2(invoiceUrl, 'POST', invoiceData);

            toastify('Unit management service processed successfully', 'success');
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error processing unit management', 'error');
            console.error('Error:', error);
        }
    };

    const handleCommissionSubmit = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            let commissionAmount;

            if (commissionForm.type === 'fixed') {
                commissionAmount = commissionForm.amount;
            } else {
                commissionAmount = (commissionForm.rentAmount * (commissionForm.percentageAmount / 100)).toFixed(2);
            }

            const invoiceData = {
                facilityId,
                serviceId: selectedFacilityServiceRequest._id,
                customerId: selectedFacilityServiceRequest.customerId,
                amount: commissionAmount,
                status: 'Pending'
            };

            const serviceData = {
                serviceRequestId: selectedFacilityServiceRequest._id,
                status: 'Completed'
            };

            const serviceUrl = updateServiceRequest + '/' + facilityId;
            await makeRequest2(serviceUrl, 'POST', serviceData);

            const invoiceUrl = addServiceInvoice + '/' + facilityId;
            await makeRequest2(invoiceUrl, 'POST', invoiceData);

            toastify('Commission service processed successfully', 'success');
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error processing commission', 'error');
            console.error('Error:', error);
        }
    };

    const handleWorkOrderSubmit = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");

            const serviceData = {
                serviceRequestId: selectedFacilityServiceRequest._id,
                assigneeId: workOrderForm.vendorId,
                amount: workOrderForm.pricing,
                status: 'Awaiting'
            };

            const serviceUrl = updateServiceRequest + '/' + facilityId;
            await makeRequest2(serviceUrl, 'POST', serviceData);

            toastify('Service request awaiting approval', 'success');
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error assigning work order', 'error');
            console.error('Error:', error);
        }
    };

    // Utility functions
    const calculateTotalRent = (monthlyRent, frequency) => {
        const frequencyMultipliers = {
            'monthly': 1,
            'bimonthly': 2,
            'quarterly': 3,
            'half_annually': 6,
            'annually': 12
        };

        return monthlyRent * (frequencyMultipliers[frequency] || 1);
    };

    const calculateAmount = (totalRent, percentage) => {
        return (totalRent * (percentage / 100)).toFixed(2);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString();
    };

    const formatTime = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleTimeString();
    };

    const formatCurrency = (value, symbol = 'KSh') => {
        if (value === undefined || value === null) return `${symbol} 0.00`;
        
        return `${symbol} ${parseFloat(value).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWorkOrderForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return {
        states: {
            selectedFacilityServiceRequest,
            isServiceManagementModalVisible,
            showApprovalMatrix,
            showServiceForm,
            unitManagementForm,
            commissionForm,
            workOrderForm,
            loading,
            invoices,
            error,
            vendors,
            isAddRequestModalVisible,
            units,
            services,
            selectedUnit,
            selectedService,
            brief,
            quotedPrice,
            finalPrice,
            serviceProviderType,
            employees,
            selectedAssignee
        },
        setters: {
            setSelectedFacilityServiceRequest,
            setServiceManagementModalVisible,
            setShowApprovalMatrix,
            setShowServiceForm,
            setUnitManagementForm,
            setCommissionForm,
            setWorkOrderForm,
            setVendors,
            setIsAddRequestModalVisible,
            setSelectedUnit,
            setSelectedService,
            setBrief,
            setQuotedPrice,
            setFinalPrice,
            setServiceProviderType,
            setSelectedAssignee
        },
        handlers: {
            handleApprove,
            handleReject,
            handleUnitManagementSubmit,
            handleCommissionSubmit,
            handleWorkOrderSubmit,
            handleInputChange,
            handleServiceManagementClick,
            handleVendorSelect,
            handleAddServiceRequest,
            getVendorsForService,
            fetchEmployees,
            downloadInvoicePDF,
            fetchCompanyDetails,
            fetchSingleInvoice,
            fetchInvoices
        },
        utils: {
            formatCurrency,
            formatDate,
            formatTime
        }
    };
};

export default useFacilityServiceRequests;