import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const ReadingHistoryTab = ({ readings, loading, date, onDateChange, onFilter, onExport }) => {
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            {/* Single Date Filter Section */}
                            <div className="col-md-4">
                                <label>Select Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={date}
                                    onChange={(e) => onDateChange(e.target.value)}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-end">
                                <Button 
                                    label="Filter" 
                                    onClick={onFilter} 
                                    className="btn btn-primary btn-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <DataTable
                            value={readings}
                            loading={loading}
                            paginator
                            rows={10}
                            stripedRows
                        >
                            <Column field="reading" header="Reading (m³)" sortable />
                            <Column
                                field="date"
                                header="Date"
                                body={(row) => new Date(row.date).toLocaleDateString()}
                                sortable
                            />
                            <Column
                                field="time"
                                header="Time"
                                body={(row) => new Date(row.time).toLocaleTimeString()}
                                sortable
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReadingHistoryTab;
