import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage'
import { makeRequest2 } from '../../../../utils/makeRequest'
import { toastify } from '../../../../utils/toast'
import { deliveryRegistrationURL } from '../../../../utils/urls'


const Deliveries = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [houseNumber, setHouseNumber] = useState('');

    const handleDeliverySubmit = async() => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const deliveryData = {
                firstName,
                lastName,
                phoneNumber,
                idNumber,
                houseNumber
            };

            if (!deliveryData.firstName) throw new Error('First name is required.');
            if (!deliveryData.lastName) throw new Error('Last name is required.');
            if (!deliveryData.phoneNumber) throw new Error('Phone number is required.');
            if (!deliveryData.idNumber) throw new Error('ID number is required.');
            if (!deliveryData.houseNumber) throw new Error('House number is required.');

            const response = await makeRequest2(deliveryRegistrationURL + '/' + facilityId, 'POST', deliveryData);

            if (response.success) {
                toastify('Delivery Registered successfully', 'success');
                handleClear();
                navigate('/facility/visitor_access_management/home');
            }
            else {
                throw new Error(response.error);
            }

        } catch (err) {
            toastify(err.message, 'error')
        }
    }

    const handleClear = () => {
        setFirstName('');
        setLastName('');
        setIdNumber('');
        setHouseNumber('');
    }

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Delivery Registration</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-header">
                    <h5>Deliveries</h5>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card-body">
                            <div className="container">

                                <div className="card">
                                    <div className="card-header">
                                        <h5>Delivery Information</h5>
                                    </div>

                                    <div className="card-body">
                                        <div className='container'>
                                            <div className='row'>
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>First Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="First name"
                                                            value={firstName} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setFirstName(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="Last name"
                                                            value={lastName} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setLastName(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Phone Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="Last name"
                                                            value={phoneNumber} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setPhoneNumber(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>


                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Id Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="ID number"
                                                            value={idNumber} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setIdNumber(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>House Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="House number"
                                                            value={houseNumber} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setHouseNumber(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="text-end mt-2">
                                    <button type="button" className="btn btn-outline-secondary" onClick={handleClear}>Clear</button>
                                    &nbsp;
                                    <button type="button" className="btn btn-primary" onClick={handleDeliverySubmit}>Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </Layout>
    )
}

export default Deliveries