import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import Layout from "../../component/layout";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getInvoiceReconciliation } from "../../../../utils/urls";
import { toastify } from "../../../../utils/toast";

const styles = {
    pageContainer: {
        minHeight: '100vh',
        backgroundColor: '#f9fafb',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        justifyContent: 'space-between',
    },
    actionCard: {
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        padding: '1rem 1.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
    },
    actionCardLeft: {
        display: 'flex',
        alignItems: 'center'
    },
    actionCardRight: {
        display: 'flex',
        gap: '0.5rem'
    },
    backLink: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        color: '#4b5563',
        textDecoration: 'none',
        fontWeight: '500'
    },
    receiptContainer: {
        width: '800px',
        margin: '0 auto',
        backgroundColor: 'white',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        minHeight: '1123px',  // A4 height in pixels at 96 DPI
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',  // Pushes footer down if the content is short
    },
    header: {
        backgroundColor: '#2196f3',
        padding: '1.5rem',
        color: 'white',
        textAlign: 'center',
        width: '100%',  // Ensures full width
    },
    headerTitle: {
        fontSize: '1.5rem',
        fontWeight: '600',
        marginBottom: '0.5rem'
    },
    headerSubtitle: {
        fontSize: '1rem',
        opacity: '0.9',
        lineHeight: '1.5'
    },
    content: {
        padding: '1.5rem',
        minHeight: '600px',
        position: 'relative',
        flex: '1',  // Allows the content to grow and push the footer down
    },
    detailsGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #e5e7eb',
        padding: '0.5rem 0',
        marginBottom: '0.5rem',
        lineHeight: '1.5'
    },
    label: {
        color: '#4b5563',
        fontSize: '0.875rem',
        fontWeight: '500',
        flex: '1'
    },
    value: {
        color: '#1f2937',
        fontSize: '0.875rem',
        textAlign: 'right',
        flex: '1'
    },
    section: {
        marginBottom: '1.5rem'
    },
    sectionTitle: {
        color: '#1f2937',
        fontSize: '1rem',
        fontWeight: '600',
        marginBottom: '1rem',
        textAlign: 'center'
    },
    paymentDetails: {
        borderTop: '1px solid #e5e7eb',
        paddingTop: '1rem',
        marginTop: '1rem'
    },
    statusLabel: {
        fontWeight: '600',
        fontSize: '0.875rem',
        flex: '1'
    },
    statusValue: {
        color: '#d97706',
        fontWeight: '600',
        textAlign: 'right',
        fontSize: '0.875rem',
        flex: '1'
    },
    footer: {
        paddingTop: '2rem',
        marginTop: 'auto',  // Ensures it stays at the bottom if the content is short
        textAlign: 'center',
        color: '#6b7280',
        fontSize: '0.875rem',
        lineHeight: '1.8',
        borderTop: '1px solid #e5e7eb',
    },
    dataTable: {
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '100%',
        fontSize: '0.875rem'
    }
};

const PaymentReceipt = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);
    const [invoicesData, setInvoicesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalApplied, setTotalApplied] = useState(0);

    const { receipt, invoice, companyDetails } = location.state || {};

    useEffect(() => {
        if (receipt && invoice) {
            fetchClearedInvoices();
        }
    }, [receipt, invoice]);

    const fetchClearedInvoices = async () => {
        setLoading(true);
        try {
            // Check if we have facilityId from the invoice
            const facilityId = invoice.facility?.id;
            if (!facilityId) {
                throw new Error('Facility ID not found in invoice data');
            }

            // Construct URL with query parameters directly
            let apiUrl = `${getInvoiceReconciliation}/${facilityId}?paymentReference=${receipt.paymentReference}`;

            // Add optional parameters if available
            if (invoice.invoiceNumber) {
                apiUrl += `&sourceInvoice=${invoice.invoiceNumber}`;
            }
            if (receipt.type) {
                apiUrl += `&sourceType=${receipt.type}`;
            }

            console.log('Fetching reconciliation data with URL:', apiUrl);

            // Make the API request using your utility function - passing null for the data parameter
            // since we're including query params in the URL directly
            const response = await makeRequest2(
                apiUrl,
                "GET",
                null
            );

            // Handle successful response
            if (response && response.success) {
                console.log('Reconciliation data received:', response);

                // Extract invoice data from response
                const invoices = response.invoices || [];
                setTotalApplied(response.totalApplied || receipt.amount);

                if (invoices.length > 0) {
                    // Transform invoice data for display
                    const formattedInvoices = invoices.map(inv => ({
                        invoiceNumber: inv.invoiceNumber,
                        date: inv.issueDate,
                        amount: inv.amountPaid,
                        balance: inv.remainingBalance || 0
                    }));
                    setInvoicesData(formattedInvoices);
                } else {
                    // Fallback to using current invoice if no cleared invoices found
                    setInvoicesData([{
                        invoiceNumber: invoice.invoiceNumber,
                        date: invoice.issueDate,
                        amount: receipt.amount,
                        balance: receipt.remainingBalance || 0
                    }]);
                }
            } else {
                // Handle API success but with error in response
                throw new Error(response.error || 'Failed to fetch cleared invoices');
            }
        } catch (error) {
            console.error('Error fetching cleared invoices:', error);
            toastify('Could not retrieve all cleared invoices. Showing available data.', "warn");

            // Fallback data
            setInvoicesData([{
                invoiceNumber: invoice.invoiceNumber,
                date: invoice.issueDate,
                amount: receipt.amount,
                balance: receipt.remainingBalance || 0
            }]);
            setTotalApplied(receipt.amount);
        } finally {
            setLoading(false);
        }
    };

    if (!receipt || !invoice) {
        return (
            <Layout>
                <div style={styles.pageContainer}>
                    <div className="text-center">
                        <h2>Receipt not found</h2>
                        <Button
                            icon="pi pi-arrow-left"
                            onClick={() => navigate(-1)}
                            className="p-button-text mt-4"
                            label="Go Back"
                        />
                    </div>
                </div>
            </Layout>
        );
    }

    const handleDownloadPDF = async () => {
        const element = document.getElementById('printable-receipt');
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const filename = `Receipt_${receipt.paymentReference}_${timestamp}.pdf`;

        try {
            toast.current.show({
                severity: 'info',
                summary: 'Processing',
                detail: 'Generating PDF...',
            });

            const canvas = await html2canvas(element, {
                scale: 2,
                useCORS: true,
                logging: false,
                backgroundColor: '#ffffff',
                height: element.clientHeight,
                width: element.clientWidth,
                windowWidth: element.scrollWidth,
                windowHeight: element.scrollHeight
            });

            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            // A4 dimensions in mm
            const pageWidth = 210;
            const pageHeight = 297;

            // Calculate dimensions to fit content
            const contentWidth = 170; // Slightly smaller for better margins
            const contentHeight = (canvas.height * contentWidth) / canvas.width;

            // Center on page
            const x = (pageWidth - contentWidth) / 2;
            const y = 15; // Smaller top margin

            pdf.addImage(imgData, 'JPEG', x, y, contentWidth, contentHeight, '', 'FAST');
            pdf.save(filename);

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'PDF downloaded successfully!',
            });
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to generate PDF. Please try again.',
            });
        }
    };

    const formatDate = (date) => {
        if (!date) return 'N/A';
        return new Date(date).toLocaleDateString();
    };

    const formatAmount = (amount, currency) => {
        if (amount === undefined || amount === null) return '-';
        if (!currency) return `Ksh ${parseFloat(amount).toFixed(2)}`; // Default to Ksh if no currency is available
        return `${currency.code} ${parseFloat(amount).toFixed(2)}`;
    };

    // Template for invoice date column
    const dateTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    // Template for amount column
    const amountTemplate = (rowData) => {
        return formatAmount(rowData.amount, invoice.currency);
    };

    // Template for balance column
    const balanceTemplate = (rowData) => {
        return formatAmount(rowData.balance, invoice.currency);
    };

    return (
        <Layout>
            <div style={styles.pageContainer}>
                <Toast ref={toast} />

                {/* Action Card */}
                <div style={styles.actionCard}>
                    <div style={styles.actionCardLeft}>
                        <Link to="#" onClick={() => navigate(-1)} style={styles.backLink}>
                            <i className="ti ti-arrow-narrow-left"></i>
                            <span>Back</span>
                        </Link>
                    </div>
                    <div style={styles.actionCardRight}>
                        <Button
                            label="Download Receipt"
                            icon="pi pi-download"
                            onClick={handleDownloadPDF}
                            className="btn btn-primary"
                        />
                    </div>
                </div>

                {/* Receipt Content */}
                <div style={styles.receiptContainer}>
                    <div id="printable-receipt">
                        {/* Receipt Header */}
                        <div style={styles.header}>
                            <div style={styles.headerTitle}>PAYMENT RECEIPT</div>
                            <div style={styles.headerSubtitle}>{companyDetails?.name || 'TEST Company'}</div>
                            <div style={styles.headerSubtitle}>{companyDetails?.address || 'Nairobi, Kenya'}</div>
                        </div>

                        {/* Content */}
                        <div style={styles.content}>
                            {/* Receipt Details */}
                            <div style={styles.section}>
                                <div style={styles.detailsGrid}>
                                    <span style={styles.label}>Receipt No:</span>
                                    <span style={styles.value}>RCP-{receipt.paymentReference}</span>
                                </div>
                                <div style={styles.detailsGrid}>
                                    <span style={styles.label}>Date:</span>
                                    <span style={styles.value}>{formatDate(receipt.date)}</span>
                                </div>
                                <div style={styles.detailsGrid}>
                                    <span style={styles.label}>Payment Ref:</span>
                                    <span style={styles.value}>{receipt.paymentReference}</span>
                                </div>
                            </div>

                            {/* Customer Details */}
                            <div style={styles.section}>
                                <div style={styles.sectionTitle}>Received From:</div>
                                <div style={{ textAlign: 'center', fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                                    {invoice.client.firstName} {invoice.client.lastName}
                                </div>
                                <div style={{ textAlign: 'center', fontSize: '1rem' }}>
                                    Unit: {invoice.unit.name}
                                </div>
                            </div>

                            {/* Cleared Invoices DataTable */}
                            <div style={styles.section}>
                                <div style={styles.sectionTitle}>Invoices Cleared by This Payment</div>
                                <div style={styles.dataTable}>
                                    {loading ? (
                                        <div className="flex justify-content-center">
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" />
                                        </div>
                                    ) : (
                                        <DataTable value={invoicesData} size="small" stripedRows emptyMessage="No cleared invoices found">
                                            <Column field="invoiceNumber" header="Invoice Number" />
                                            <Column field="date" header="Date" body={dateTemplate} />
                                            <Column field="amount" header="Amount" body={amountTemplate} />
                                            <Column field="balance" header="Balance" body={balanceTemplate} />
                                        </DataTable>
                                    )}
                                </div>
                            </div>

                            {/* Payment Details */}
                            <div style={{ ...styles.section, ...styles.paymentDetails }}>
                                <div style={styles.detailsGrid}>
                                    <span style={styles.label}>Total Amount Paid:</span>
                                    <span style={styles.value}>{formatAmount(totalApplied || receipt.amount, invoice.currency)}</span>
                                </div>
                                <div style={styles.detailsGrid}>
                                    <span style={styles.statusLabel}>Payment Status:</span>
                                    <span style={styles.statusValue}>{receipt.paymentCompletion}</span>
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div style={styles.footer}>
                            <p>Thank you for your payment!</p>
                            <p>This is a computer generated receipt.</p>
                            {/* <p>Payment Method: {receipt.type}</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PaymentReceipt;