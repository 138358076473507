import React, { useState, useEffect } from 'react';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { addEmailSettings } from '../../../utils/urls';
import { Button } from 'primereact/button';

const EmailTab = ({ facilityId }) => {
    const [emailSettings, setEmailSettings] = useState({
        user: '',
        from: '',
        host: '',
        port: '',
        auth: {
            user: '',
            pass: ''
        }
    });
    const [emailList, setEmailList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (facilityId) {
            fetchEmailSettings();
        }
    }, [facilityId]);

    const fetchEmailSettings = async () => {
        try {
            const response = await makeRequest2(`${addEmailSettings}/${facilityId}`, 'GET');
            if (response.success) {
                setEmailList(Array.isArray(response.data) ? response.data : [response.data].filter(Boolean));
            }
        } catch (error) {
            console.error('Error fetching email settings:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = `${addEmailSettings}/${facilityId}${isEditing ? `/${emailSettings._id}` : ''}`;
            const method = isEditing ? 'PUT' : 'POST';

            const response = await makeRequest2(url, method, emailSettings);

            if (response.success) {
                toastify(`Email settings ${isEditing ? 'updated' : 'added'} successfully`, 'success');
                fetchEmailSettings();
                resetForm();
            } else {
                toastify(response.message || 'Operation failed', 'error');
            }
        } catch (error) {
            toastify('Error saving email settings', 'error');
        }
    };

    const handleEdit = (email) => {
        setEmailSettings(email);
        setIsEditing(true);
    };

    const resetForm = () => {
        setEmailSettings({
            user: '',
            from: '',
            host: '',
            port: '',
            auth: {
                user: '',
                pass: ''
            }
        });
        setIsEditing(false);
    };

    return (
        <div className="row p-3">
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5>{isEditing ? 'Update' : 'Add'} Email Settings</h5>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-3">
                                <label>User*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={emailSettings.user}
                                    onChange={(e) => setEmailSettings({ ...emailSettings, user: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>From Email*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={emailSettings.from}
                                    onChange={(e) => setEmailSettings({ ...emailSettings, from: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Host*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={emailSettings.host}
                                    onChange={(e) => setEmailSettings({ ...emailSettings, host: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Port*</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={emailSettings.port}
                                    onChange={(e) => setEmailSettings({ ...emailSettings, port: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Auth Username*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={emailSettings.auth.user}
                                    onChange={(e) => setEmailSettings({
                                        ...emailSettings,
                                        auth: { ...emailSettings.auth, user: e.target.value }
                                    })}
                                    required
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Auth Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={emailSettings.auth.pass}
                                    onChange={(e) => setEmailSettings({
                                        ...emailSettings,
                                        auth: { ...emailSettings.auth, pass: e.target.value }
                                    })}
                                    required
                                />
                            </div>

                            <div className="mt-4 text-end">
                                {isEditing && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={resetForm}
                                    >
                                        Cancel
                                    </button>
                                )}
                                <button type="submit" className="btn btn-primary">
                                    {isEditing ? 'Update' : 'Add'} Email Settings
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5>Current Email Settings</h5>
                        <div className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>From Email</th>
                                        <th>Host</th>
                                        <th className="text-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailList.length > 0 ? (
                                        emailList.map((email) => (
                                            <tr key={email._id}>
                                                <td>{email.user}</td>
                                                <td>{email.from}</td>
                                                <td>{email.host}</td>
                                                <td className="text-end">
                                                    <Button
                                                        icon="ti ti-pencil"
                                                        className="p-button-rounded p-button-warning me-2"
                                                        onClick={() => handleEdit(email)}
                                                        tooltip="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="text-center py-4">
                                                <div className="text-muted">
                                                    <i className="ti ti-inbox mb-2" style={{ fontSize: "24px" }}></i>
                                                    <p className="mb-0">No email settings available</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailTab;