import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, InputText } from "primereact";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import {
  getLevies,
  getCustomersURL,
  getUnitsForFacility,
  addContract,
  getContracts
} from "../../../../utils/urls";

const AddContract = () => {
  const navigate = useNavigate();

  // State management
  const [contractName, setContractName] = useState("");
  const [levy, setLevy] = useState("");
  const [customer, setCustomer] = useState("");
  const [unit, setUnit] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [balanceBroughtForward, setBalanceBroughtForward] = useState("0.00");
  const [status, setStatus] = useState("Active");
  const [loading, setLoading] = useState(false);

  // Data lists
  const [levies, setLevies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [units, setUnits] = useState([]);
  const [existingContracts, setExistingContracts] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [selectedLevy, setSelectedLevy] = useState(null);

  // Fetch existing contracts
  const fetchExistingContracts = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(`${getContracts}/${facilityId}`, "GET");
      if (response.success) {
        const contractsData = response.data?.contracts || response.data || [];
        setExistingContracts(contractsData);
      }
    } catch (error) {
      console.error("Error fetching existing contracts:", error);
      setExistingContracts([]);
    }
  };

  // Fetch available levies
  const fetchLevies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getLevies}/${facilityId}`,
        "GET",
        {}
      );

      if (response.success) {
        let leviesData = [];

        if (Array.isArray(response.data)) {
          leviesData = response.data;
        } else if (response.data?.data && Array.isArray(response.data.data)) {
          leviesData = response.data.data;
        } else if (typeof response.data === 'object' && response.data !== null) {
          leviesData = [response.data];
        }

        const processedLevies = leviesData.map((levy) => ({
          _id: levy._id,
          levyName: levy.levyName || 'Untitled Levy',
          amount: levy.amount || 0,
          levyType: levy.levyType?.name || "N/A",
          collectionFrequency: levy.collectionFrequency || "Monthly",
          levyApplicant: levy.levyApplicant || "home owner" // Default to home owner if not specified
        }));

        console.log("Fetched levies with applicant info:", processedLevies);
        setLevies(processedLevies);
      } else {
        throw new Error(response.error || "Failed to fetch levies");
      }
    } catch (err) {
      console.error("Error in fetchLevies:", err);
      toastify(err.message || "Error fetching levies", "error");
      setLevies([]);
    }
  };

  // Fetch available customers for the facility
  const fetchCustomersForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getCustomersURL}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        // Ensure customer data is normalized
        const normalizedCustomers = response.data.map(customer => ({
          ...customer,
          customerType: customer.customerType?.trim().toLowerCase() || ""
        }));
        
        console.log("Fetched customers:", normalizedCustomers);
        setCustomers(normalizedCustomers);
      } else {
        throw new Error("Failed to fetch customers.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Fetch available units for the facility
  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        const filteredUnits = response.data.filter(unit =>
          unit.tenantId || unit.homeOwnerId
        );
        
        console.log("Fetched units:", filteredUnits);
        setUnits(filteredUnits);
        
        // Initially set available units to all valid units
        updateAvailableUnits(filteredUnits, existingContracts, levy);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Update available units based on levy selection and existing contracts
  const updateAvailableUnits = (unitsList, contractsList, selectedLevyId) => {
    if (!selectedLevyId) {
      setAvailableUnits(unitsList);
      return;
    }

    // Filter out units that already have an active contract for the selected levy
    const filteredUnits = unitsList.filter(unit => {
      const hasExistingContract = contractsList.some(contract =>
        contract.unitId === unit._id &&
        contract.levyId === selectedLevyId &&
        contract.status === 'Active'
      );
      
      return !hasExistingContract;
    });

    setAvailableUnits(filteredUnits);
  };

  const handleUnitSelect = (unitId) => {
    setUnit(unitId);

    if (!unitId) {
      setCustomer("");
      return;
    }

    if (!selectedLevy) {
      toastify("Please select a levy first", "error");
      setUnit("");
      return;
    }

    const selectedUnit = units.find(u => u._id === unitId);
    if (!selectedUnit) return;

    console.log("Selected unit:", selectedUnit);
    console.log("Selected levy applicant:", selectedLevy.levyApplicant);
    
    // Check if this unit already has an active contract for the selected levy
    const hasExistingContract = existingContracts.some(contract =>
      contract.unitId === unitId &&
      contract.levyId === levy &&
      contract.status === 'Active'
    );

    if (hasExistingContract) {
      toastify("This unit already has an active contract for this levy type", "error");
      setUnit("");
      return;
    }
    
    // Determine the correct customer based on levy applicant
    let preferredCustomerId = null;
    let foundCustomer = null;
    
    // Get the correct customer ID based on levy applicant
    // Convert levy applicant to lowercase for case-insensitive comparison
    const levyApplicantLower = selectedLevy.levyApplicant.toLowerCase();
    
    if (levyApplicantLower === "tenant" || levyApplicantLower === "tenants") {
      // Tenant should be preferred
      if (selectedUnit.tenantId) {
        preferredCustomerId = selectedUnit.tenantId;
        foundCustomer = customers.find(c => c._id === preferredCustomerId);
        
        if (foundCustomer) {
          console.log("Found tenant for unit:", foundCustomer);
          setCustomer(foundCustomer._id);
          return;
        }
      }
      // If no tenant but the levy is for tenants, check if we should use homeowner as fallback
      if (selectedUnit.homeOwnerId) {
        preferredCustomerId = selectedUnit.homeOwnerId;
        foundCustomer = customers.find(c => c._id === preferredCustomerId);
        
        if (foundCustomer) {
          console.log("No tenant found, using homeowner as fallback:", foundCustomer);
          toastify("No tenant found for this unit. Using homeowner instead.", "warning");
          setCustomer(foundCustomer._id);
          return;
        }
      }
    } else {
      // Default case: Home owner should be preferred
      if (selectedUnit.homeOwnerId) {
        preferredCustomerId = selectedUnit.homeOwnerId;
        foundCustomer = customers.find(c => c._id === preferredCustomerId);
        
        if (foundCustomer) {
          console.log("Found homeowner for unit:", foundCustomer);
          setCustomer(foundCustomer._id);
          return;
        }
      }
      // If no homeowner but the levy is for homeowners, check if we should use tenant as fallback
      if (selectedUnit.tenantId) {
        preferredCustomerId = selectedUnit.tenantId;
        foundCustomer = customers.find(c => c._id === preferredCustomerId);
        
        if (foundCustomer) {
          console.log("No homeowner found, using tenant as fallback:", foundCustomer);
          toastify("No homeowner found for this unit. Using tenant instead.", "warning");
          setCustomer(foundCustomer._id);
          return;
        }
      }
    }

    // If we get here, no appropriate customer was found
    setCustomer("");
    toastify(`No ${levyApplicantLower === "tenant" ? "tenant" : "homeowner"} found for this unit`, "error");
  };

  const handleContractSubmit = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");

      // Form validation
      if (!contractName || contractName.trim() === "") {
        toastify("Service Agreement name is required.", "error");
        setLoading(false);
        return;
      }
      if (!levy) {
        toastify("Levy is required.", "error");
        setLoading(false);
        return;
      }
      if (!customer) {
        toastify("Customer is required.", "error");
        setLoading(false);
        return;
      }
      if (!unit) {
        toastify("Unit is required.", "error");
        setLoading(false);
        return;
      }
      if (!amount || isNaN(amount)) {
        toastify("Amount must be a valid number.", "error");
        setLoading(false);
        return;
      }
      if (!startDate) {
        toastify("Start date is required.", "error");
        setLoading(false);
        return;
      }
      if (!endDate) {
        toastify("End date is required.", "error");
        setLoading(false);
        return;
      }

      // Check if dates are valid
      if (new Date(startDate) > new Date(endDate)) {
        toastify("Start date cannot be later than end date.", "error");
        setLoading(false);
        return;
      }

      // Get the selected levy's payment frequency
      const paymentFrequency = selectedLevy?.collectionFrequency || "Monthly";

      const contractData = {
        contractName,
        levyId: levy,
        customerId: customer,
        unitId: unit,
        amount: parseFloat(amount),
        startDate,
        endDate,
        status,
        facilityId,
        balanceBroughtForward: parseFloat(balanceBroughtForward) || 0,
        // Include payment frequency to satisfy backend validation
        paymentFrequency
      };

      console.log("Sending contract data:", contractData);

      // Send the data to the backend
      const response = await makeRequest2(
        `${addContract}/${facilityId}`,
        "POST",
        contractData
      );

      if (response.success) {
        toastify("Service Agreement added successfully", "success");
        handleClear();
        navigate("/facility/levy_management/contracts");
      } else {
        console.error("Error response:", response.error);
        toastify(response.error || "Failed to add contract.", "error");
      }
    } catch (err) {
      console.error("Error adding contract:", err);
      toastify(
        err.message ||
        "An unexpected error occurred while adding the contract.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  // Clear form
  const handleClear = () => {
    setContractName("");
    setLevy("");
    setCustomer("");
    setUnit("");
    setAmount("");
    setStartDate("");
    setEndDate("");
    setBalanceBroughtForward("0.00");
    setStatus("Active");
  };

  // Load data on component mount
  useEffect(() => {
    fetchLevies();
    fetchCustomersForFacility();
    fetchUnitsForFacility();
    fetchExistingContracts();
  }, []);

  // Update available units when levy or contracts change
  useEffect(() => {
    updateAvailableUnits(units, existingContracts, levy);
  }, [levy, existingContracts, units]);

  // Get frequency text for display
  const getFrequencyText = () => {
    if (!levy) return "";
    return selectedLevy ? selectedLevy.collectionFrequency : "";
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/levy_management/levies/levy_list">
                    Levy Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Add New Service Agreement</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Service Agreement</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Service Agreement Name</label>
                    <InputText
                      className="form-control"
                      value={contractName}
                      onChange={(e) => setContractName(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Levy</label>
                    <select
                      className="form-control"
                      value={levy}
                      onChange={(e) => {
                        const selectedLevyId = e.target.value;
                        setLevy(selectedLevyId);
                        
                        // Find the selected levy
                        const levyObj = levies.find((l) => l._id === selectedLevyId);
                        setSelectedLevy(levyObj);
                        
                        // Set amount from the selected levy
                        setAmount(levyObj ? levyObj.amount : "");
                        
                        // When levy changes, clear unit selection
                        setUnit("");
                        setCustomer("");
                      }}
                    >
                      <option value="">-- Select --</option>
                      {levies.map((levy) => (
                        <option key={levy._id} value={levy._id}>
                          {levy.levyName} ({levy.levyApplicant === "tenant" ? "Tenant" : "Home Owner"})
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Unit</label>
                    <select
                      className="form-control"
                      value={unit}
                      onChange={(e) => handleUnitSelect(e.target.value)}
                      disabled={!levy}
                    >
                      <option value="">-- Select --</option>
                      {availableUnits.map((unit) => (
                        <option key={unit._id} value={unit._id}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                    {!levy && <small className="text-muted">Please select a levy first</small>}
                    {availableUnits.length === 0 && levy && (
                      <small className="text-danger">
                        No available units found for this levy. All units already have active contracts.
                      </small>
                    )}
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Customer</label>
                    <select
                      className="form-control"
                      value={customer}
                      disabled
                    >
                      <option value="">-- Select --</option>
                      {customers.map((customer) => (
                        <option key={customer._id} value={customer._id}>
                          {`${customer.firstName} ${customer.lastName}`}
                          {customer.customerType && ` (${customer.customerType})`}
                        </option>
                      ))}
                    </select>
                    {selectedLevy && (
                      <small className="text-muted">
                        This levy applies to {selectedLevy.levyApplicant === "tenant" ? "tenants" : "home owners"}
                      </small>
                    )}
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      value={amount}
                      disabled
                    />
                    <small className="text-muted">This value is set in the levy configuration</small>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Payment Frequency (from levy)</label>
                    <input
                      type="text"
                      className="form-control"
                      value={getFrequencyText()}
                      disabled
                    />
                    <small className="text-muted">This value is set in the levy configuration</small>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Balance Brought Forward</label>
                    <input
                      type="text"
                      className="form-control"
                      value={balanceBroughtForward}
                      onChange={(e) => setBalanceBroughtForward(e.target.value)}
                      placeholder="0.00"
                    />
                    <small className="text-muted">Enter any existing balance to be carried over</small>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label className="form-label mb-2">Status</label>
                    <div className="d-flex align-items-center">
                      <i
                        className={`ti ${status === "Inactive"
                          ? "ti-toggle-left text-danger"
                          : "ti-toggle-right text-primary"
                          }`}
                        style={{ fontSize: "34px", cursor: "pointer" }}
                        onClick={() =>
                          setStatus(
                            status === "Inactive" ? "Active" : "Inactive"
                          )
                        }
                      />
                      <span className="ms-2">{status}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                  disabled={loading}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleContractSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddContract;