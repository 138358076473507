import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button, InputText } from 'primereact';
import { toastify } from '../../../../utils/toast';
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { Card } from "primereact/card";

import Layout from "../../component/layout";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../app/z-store/store";

const AssetManagement = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    serialNumber: "",
    dateBought: "",
    insuranceStatus: "",
  });
  const [assetStats, setAssetStats] = useState({
    totalAssets: 0,
    insuredAssets: 0,
    uninsuredAssets: 0,
    averageAge: 0,
  });
  const toast = useRef(null);

  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  useEffect(() => {
    if (selectedFacilityId) {
      fetchAssets(selectedFacilityId);
    }
  }, [selectedFacilityId]);

  // Calculate asset statistics
  const calculateStats = () => {
    const totalAssets = assets.length;
    const insuredAssets = assets.filter(
      (asset) => asset.insuranceStatus === "Insured"
    ).length;
    const uninsuredAssets = totalAssets - insuredAssets;

    const ageSum = assets.reduce((sum, asset) => {
      const ageInDays =
        (new Date() - new Date(asset.dateBought)) / (1000 * 60 * 60 * 24);
      return sum + ageInDays;
    }, 0);

    const averageAge =
      assets.length > 0 ? Math.round(ageSum / assets.length) : 0;

    setAssetStats({
      totalAssets,
      insuredAssets,
      uninsuredAssets,
      averageAge,
    });
  };

  // Fetch assets
  // Modify fetchAssets to accept facilityId as parameter
  const fetchAssets = async (currentFacilityId) => {
    try {
      setLoading(true);
      const idToUse = currentFacilityId || selectedFacilityId;

      if (!idToUse) {
        throw new Error("No facility ID available");
      }

      const response = await makeRequest2(
        `/api/assets/${idToUse}`,
        "GET",
        null
      );

      if (response.success) {
        // Ensure we're setting an array
        const assetData = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.assets)
            ? response.data.assets
            : [];
        setAssets(assetData);
        setError(null);
      } else {
        setError(response.error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (err) {
      setError("Failed to fetch assets");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err.message || "Failed to fetch assets",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (assets && assets.length > 0) {
      calculateStats();
    }
  }, [assets]);

  const filteredAssets = Array.isArray(assets)
    ? assets.filter((asset) =>
      Object.values(asset).some((value) =>
        value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
      )
    )
    : [];
  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Open modal for add/edit
  const handleModalOpen = (asset = null) => {
    if (asset) {
      setSelectedAsset(asset);
      setFormData({
        name: asset.name,
        serialNumber: asset.serialNumber,
        dateBought: asset.dateBought.split("T")[0],
        insuranceStatus: asset.insuranceStatus,
      });
    } else {
      setSelectedAsset(null);
      setFormData({
        name: "",
        serialNumber: "",
        dateBought: "",
        insuranceStatus: "",
      });
    }
    setShowModal(true);
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response;
      if (selectedAsset) {
        response = await makeRequest2(
          `/api/assets/${selectedFacilityId}/${selectedAsset._id}`,
          "PUT",
          formData
        );
      } else {
        response = await makeRequest2(
          `/api/assets/${selectedFacilityId}`,
          "POST",
          formData
        );
      }

      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: selectedAsset
            ? "Asset updated successfully"
            : "Asset added successfully",
        });
        fetchAssets();
        setShowModal(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle delete
  const handleDelete = async (assetId) => {
    confirmDialog({
      message: "Are you sure you want to delete this asset?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/assets/${selectedFacilityId}/${assetId}`,
            "DELETE",
            null
          );

          if (response.success) {
            toastify('Asset deleted successfully', 'success');
            fetchAssets();
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
      },
    });
  };

  // Action buttons template
  const actionTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info p-mr-2"
          onClick={() => handleModalOpen(rowData)}
          tooltip="Edit"
        >
        </Button>
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => handleDelete(rowData._id)}
          tooltip="Delete"
        >
        </Button>
      </div>
    );
  };

  // Header template with search and add button
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <input
          type="text"
          className="form-control w-25"
          placeholder="Search assets..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
        <button className="btn btn-primary" onClick={() => handleModalOpen()}>
          Add New Asset
        </button>
      </div>
    );
  };

  const renderAssetStatistics = () => {
    // First, add validation for the data
    if (!assetStats.totalAssets) {
      return (
        <div className="row">
          <div className="col-12">
            <Card className="p-4">
              <p className="text-center">No assets data available</p>
            </Card>
          </div>
        </div>
      );
    }

    // Ensure the data is properly formatted for the pie chart
    const pieChartData = [
      { name: "Insured", value: assetStats.insuredAssets || 0 },
      { name: "Uninsured", value: assetStats.uninsuredAssets || 0 },
    ];

    return (
      <div className="row">
        <div className="col-md-4 mb-4">
          <Card title="Total Assets">
            <div className="text-center">
              <p className="text-3xl font-bold">{assetStats.totalAssets}</p>
            </div>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card title="Insured Assets">
            <div className="text-center">
              <p className="text-3xl font-bold text-green-600">
                {assetStats.insuredAssets}
              </p>
            </div>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card title="Average Age (Days)">
            <div className="text-center">
              <p className="text-3xl font-bold text-blue-600">
                {assetStats.averageAge}
              </p>
            </div>
          </Card>
        </div>
        <div className="col-md-12">
          <Card title="Insurance Status Distribution">
            <div style={{ width: "100%", height: "300px" }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    label={({ name, percent }) =>
                      `${name}: ${(percent * 100).toFixed(0)}%`
                    }
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => `${value} assets`} />
                  <Legend verticalAlign="bottom" height={36} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={""}>Asset Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="available-assets-tab"
                                data-bs-toggle="tab"
                                href="#available-assets"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="attach_money me-2"></i>
                                Available Assets
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="asset-statistics-tab"
                                data-bs-toggle="tab"
                                href="#asset-statistics"
                                role="tab"
                                aria-selected="false"
                              >
                                <i className="ti ti-report-money me-2"></i>
                                Asset Statistics
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="available-assets"
                          role="tabpanel"
                          aria-labelledby="available-assets-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={filteredAssets}
                                      emptyMessage="No assets found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      loading={loading}
                                      header={renderHeader}
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="name"
                                        header="Asset Name"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="serialNumber"
                                        header="Serial Number"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="dateBought"
                                        header="Date Bought"
                                        sortable
                                        body={(rowData) =>
                                          new Date(
                                            rowData.dateBought
                                          ).toLocaleDateString()
                                        }
                                      ></Column>
                                      <Column
                                        field="insuranceStatus"
                                        header="Insurance Status"
                                        sortable
                                      ></Column>
                                      <Column
                                        body={actionTemplate}
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane"
                          id="asset-statistics"
                          role="tabpanel"
                          aria-labelledby="asset-statistics-tab"
                        >
                          {/* Asset Statistics Content */}
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Asset Statistics</h5>
                                </div>
                                <div className="card-body">
                                  {renderAssetStatistics()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add/Edit Modal */}
      {showModal && (
        <div
          className="modal d-block"
          tabIndex="-1"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedAsset ? "Edit Asset" : "Add New Asset"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Asset Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Serial Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="serialNumber"
                      value={formData.serialNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date Bought</label>
                    <input
                      type="date"
                      className="form-control"
                      name="dateBought"
                      value={formData.dateBought}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Insurance Status</label>
                    <select
                      className="form-select"
                      name="insuranceStatus"
                      value={formData.insuranceStatus}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="Insured">Insured</option>
                      <option value="Not Insured">Not Insured</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    ) : null}
                    {selectedAsset ? "Update Asset" : "Add Asset"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AssetManagement;
