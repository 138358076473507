import { createBrowserRouter } from "react-router-dom";
// // import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES

import Login from "../components/authentication/login";
import ForgotPassword from "../components/authentication/forgotPassword";
import CheckEmail from "../components/authentication/checkEmail";
import CodeVerification from "../components/authentication/codeVerification";
import ResetPassword from "../components/authentication/resetPassword";
import Error404Page from "../components/error/Error404Page";

// APP
import Dashboard from "../components/app/dashboard/dashboard";
import Facilities from "../components/app/facility_management/facilities";
import AddNewFacility from "../components/app/facility_management/add_new_facility";
import Customers from "../components/app/customer_management/customer_management";
import AddNewCustomers from "../components/app/customer_management/add_new_customer";
import ImportCustomers from "../components/app/customer_management/import_customers";

import CampaignManagement from "../components/app/compaign_management/campaign_management";
import TicketManagement from "../components/app/ticket_management/ticket_management";
import SurveyManagement from "../components/app/survey_management/survey_management";
import Setting from "../components/app/settings/setting";

// FACILITY
import DashboardFacility from "../components/facility/dashboard/dashboard";
import UnitManagement from "../components/facility/unit_management/unit_management";
import ImportUnits from "../components/facility/unit_management/import_units";
import AddNewUnit from "../components/facility/unit_management/add_new_unit";
import LeaseManagement from "../components/facility/lease_management/lease/lease_management";
import CreateLease from "../components/facility/lease_management/lease/create_lease";
import LeaseTemplates from "../components/facility/lease_management/lease/lease_templates/lease_template_management";
import CreateLeaseTemplate from "../components/facility/lease_management/lease/lease_templates/create_lease_template";
import LeaseInvoices from "../components/facility/lease_management/lease_invoice/lease_invoices";
import LeaseInvoicePage from "../components/facility/lease_management/lease_invoice/InvoiceManagement";
import ViewCustomer from "../components/facility/customer_management/view_customer";
import ViewLeaseTemplate from "../components/facility/lease_management/lease/view_lease";
import LeasePenalties from "../components/facility/lease_management/penalty_reminders/PenaltyReminderSelector";
import LeaseReports from "../components/facility/lease_management/reports/reports";
import AddLeasePenalty from "../components/facility/lease_management/penalty_reminders/add_penalty"
import AddLeaseReminder from "../components/facility/lease_management/penalty_reminders/add_reminder"

import CustomerManagement from "../components/facility/customer_management/customers";
import AddCustomer from "../components/facility/customer_management/add_customer";
import LevyManagement from "../components/facility/levy_management/levies/levy_list";
import AddLevy from "../components/facility/levy_management/levies/add_levy";
import LevyContract from "../components/facility/levy_management/contracts/levy_contract";
import AddContract from "../components/facility/levy_management/contracts/add_contract";
import AddContracts from "../components/facility/levy_management/contracts/add_multiple_contracts";
import LevyInvoicingManagement from "../components/facility/levy_management/Invoicing/levy_invoicing";
import LevyReports from "../components/facility/levy_management/reports/reports";
import PaymentReceipt from "../components/facility/levy_management/receipts/invoiceReceipt";
import FacilityPayments from "../components/facility/payment_management/facility_payment";

// import VisitorRegistration from "../components/facility/visitor_access_management/visitor_registration/visitor_registration";
import GuardList from "../components/facility/visitor_access_management/guard_management/guard_registration";
import EntriesAndExists from "../components/facility/visitor_access_management/entires_and_exisits/entries_and_exists";
import DigitalLogs from "../components/facility/visitor_access_management/digital_logs/digital_logs";
import AccessControl from "../components/facility/visitor_access_management/access_control/access_control";
import Tickets from "../components/facility/ticket_management/tickets";
import ViewTicket from "../components/facility/ticket_management/view_ticket";
import StartReview from "../components/facility/ticket_management/start_review";
import ServiceManagement from "../components/facility/value_added_services/service_list";
import maintenanceRouter from "../components/facility/maintenance_management/routes";
import utilityRouter from "../components/facility/utility_management/routes";
import ServiceVendor from "../components/facility/value_added_services/service_vendors";
import VasInvoices from "../components/facility/value_added_services/vas_invoices";
import VasInvoicePage from "../components/facility/value_added_services/components/invoice_page";
import InvoicePage from "../components/facility/levy_management/Invoicing/invoice_management/InvoicePage";
import LevySettings from "../components/facility/levy_management/levy_settings/settings";
import FacilitySettings from "../components/facility/settings_management/settings";
import RegisterGuard from "../components/facility/visitor_access_management/guard_management/register_guard";
import AddEntryAndExit from "../components/facility/visitor_access_management/entires_and_exisits/add_entry_and_exit";
import AccessPoints from "../components/app/access_points/access_points";
import VisitorManagementHome from "../components/facility/visitor_access_management/home/home";
import ScanQRCode from "../components/facility/visitor_access_management/qrcode/scan_qr_code";
import VerifyCode from "../components/facility/visitor_access_management/verifycode/verify_code";
import GateRegistration from "../components/facility/visitor_access_management/visitor_registration/gate_registration";
import Deliveries from "../components/facility/visitor_access_management/visitor_registration/deliveries";
import WaitingList from "../components/facility/visitor_access_management/wait_list/waiting_list";
import ViewWaitingList from "../components/facility/visitor_access_management/wait_list/view_waiting_list";
import UnitListing from "../components/facility/booking_management/unit_listing";
import HandoverManagement from "../components/facility/handover_management/handover_management";
import CreateMoveInHandover from "../components/facility/handover_management/create_move_in_handover";
import CreateMoveOutHandover from "../components/facility/handover_management/create_move_out_handover";

import GetDigitalLog from "../components/facility/visitor_access_management/digital_logs/get_digital_log";
import ViewUnit from "../components/facility/unit_management/view_unit";
import expenseRoutes from "../components/facility/expense_and_budget_mgmnt/expense_and_budget.routes";
import StaffNotification from "../components/facility/ticket_management/staff_notification";
import BookingManager from "../components/facility/booking_management/booking_manager";
import RaiseTicket from "../components/facility/ticket_management/raise_ticket";
import Campaigns from "../components/facility/campaign_management/campaigns";
import AddCampaign from "../components/facility/campaign_management/add_campaign";
import UniversalInvoicePage from "../components/facility/levy_management/Invoicing/offline_invoice/invoice_management/UniversalInvoicePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgot_password",
    element: <ForgotPassword />,
  },
  {
    path: "/check_email",
    element: <CheckEmail />,
  },
  {
    path: "/code_verification",
    element: <CodeVerification />,
  },
  {
    path: "/reset_password/:userId",
    element: <ResetPassword />,
  },
  {
    path: "/app/",
    element: <Dashboard />,
  },
  {
    path: "/app/facilities",
    element: <Facilities />,
  },
  {
    path: "/app/add_new_facility",
    element: <AddNewFacility />,
  },
  {
    path: "/app/campaign_management",
    element: <CampaignManagement />,
  },
  {
    path: "/app/customer_management",
    element: <Customers />,
  },
  {
    path: "/app/customer_management/add_new_customer",
    element: <AddNewCustomers />,
  },
  {
    path: "/app/customer_management/import_customers",
    element: <ImportCustomers />,
  },
  {
    path: "/app/ticket_management",
    element: <TicketManagement />,
  },

  {
    path: "/app/survey_management",
    element: <SurveyManagement />,
  },
  // {
  //   path: "/app/settings",
  //   element: <Setting />,
  // },
  // {
  //   path: "/facility/settings",
  //   element: <Settings />,
  // },
  {
    path: "/facility/",
    element: <DashboardFacility />,
  },
  {
    path: "/facility/settings_management/settings",
    element: <FacilitySettings />,
  },
  {
    path: "/facility/unit_management",
    element: <UnitManagement />,
  },
  {
    path: "/facility/unit_management/add_new_unit",
    element: <AddNewUnit />,
  },
  {
    path: "/facility/unit_management/import_units",
    element: <ImportUnits />,
  },
  {
    path: "/facility/customer_management/customers",
    element: <CustomerManagement />,
  },
  {
    path: "/facility/customer_management/view_customer/:customerId",
    element: <ViewCustomer />,
  },
  {
    path: "/facility/customer_management/add_new_customer",
    element: <AddCustomer />,
  },
  {
    path: "/facility/lease_management/leases",
    element: <LeaseManagement />,
  },
  {
    path: "/facility/lease_management/create_lease",
    element: <CreateLease />,
  },
  {
    path: "/facility/lease_management/lease_templates",
    element: <LeaseTemplates />,
  },
  {
    path: "/facility/booking_management/create_lease_templates",
    element: <CreateLeaseTemplate />,
  },

  {
    path: "/facility/lease_management/lease/view_lease/:leaseId",
    element: <ViewLeaseTemplate />,
  },

  {
    path: "/facility/lease_management/lease_penalties",
    element: <LeasePenalties />,
  },

  {
    path: "/facility/lease_management/lease_invoices",
    element: <LeaseInvoices />,
  },

  {
    path: "/facilitiy/lease_management/reports",
    element: <LeaseReports />,
  },

  {
    path: "/facility/lease_management/lease_penalties/add_penalty",
    element: <AddLeasePenalty />,
  },

  {
    path: "/facility/lease_management/lease_penalties/add_reminder",
    element: <AddLeaseReminder />,
  },

  {
    path: "/facility/levy_management/",
    element: <LevyManagement />,
  },
  {
    path: "/facility/levy_management/receipt/:receiptId",
    element: <PaymentReceipt />,
  },
  {
    path: "/facility/payment_management/:facilityId",
    element: <FacilityPayments />,
  },
  {
    path: "/facility/levy_management/invoice/:invoiceId",
    element: <InvoicePage />,
  },
  {
    path: "/facility/lease_management/invoice/:invoiceId",
    element: <LeaseInvoicePage />,
  },

  {
    path: "/facility/levy_management/levies/add_levy",
    element: <AddLevy />,
  },
  {
    path: "/facility/levy_management/contracts",
    element: <LevyContract />,
  },
  {
    path: "/facility/levy_management/contracts/add_contract",
    element: <AddContract />,
  },
  {
    path: "/facility/levy_management/contracts/add_multiple_contracts",
    element: <AddContracts />,
  },
  {
    path: "/facilitiy/levy_management/levy_invoicing",
    element: <LevyInvoicingManagement />,
  },
  {
    path: "/facilitiy/levy_management/reports",
    element: <LevyReports />,
  },
  {
    path: "/app/access_points",
    element: <AccessPoints />,
  },
  {
    path: "/facility/visitor_access_management/home",
    element: <VisitorManagementHome />,
  },
  // {
  //   path: "facilitiy/visitor_management/visitor_registration",
  //   element: <VisitorRegistration />,
  // },
  {
    path: "facility/visitor_access_management/guard_list",
    element: <GuardList />,
  },
  {
    path: "/facility/visitor_access_management/guard_management/register_guard",
    element: <RegisterGuard />,
  },
  {
    path: "facility/visitor_access_management/digital_logs",
    element: <DigitalLogs />,
  },
  {
    path: "facility/visitor_access_management/view_digital_log/:visitLogId",
    element: <GetDigitalLog />,
  },
  {
    path: "/facility/visitor_access_management/entries_and_exits",
    element: <EntriesAndExists />,
  },
  {
    path: "/facility/visitor_access_management/entries_and_exits/add_entry_and_exit",
    element: <AddEntryAndExit />,
  },
  {
    path: "/facility/visitor_access_management/scan_qr_code",
    element: <ScanQRCode />,
  },
  {
    path: "/facility/visitor_access_management/verify_code",
    element: <VerifyCode />,
  },
  {
    path: "/facility/visitor_access_management/visitor_registration/gate_registration",
    element: <GateRegistration />,
  },
  {
    path: "/facility/visitor_access_management/visitor_registration/deliveries",
    element: <Deliveries />,
  },
  {
    path: "/facility/visitor_access_management/waiting_list",
    element: <WaitingList />,
  },
  {
    path: "/facility/visitor_access_management/view_waiting_list/:visitLogId",
    element: <ViewWaitingList />,
  },
  {
    path: "facilitiy/visitor_management/access_control",
    element: <AccessControl />,
  },
  {
    path: "/app/survey_management",
    element: <SurveyManagement />,
  },
  {
    path: "/app/settings",
    element: <Setting />,
  },
  // {
  //   path: "/facility/settings",
  //   element: <Settings />,
  // },
  {
    path: "/facility/",
    element: <DashboardFacility />,
  },
  {
    path: "/facility/unit_management",
    element: <UnitManagement />,
  },
  {
    path: "/facility/unit_management/view_unit/:unitId",
    element: <ViewUnit />,
  },
  {
    path: "/facility/unit_management/add_new_unit",
    element: <AddNewUnit />,
  },
  {
    path: "/facility/unit_management/import_units",
    element: <ImportUnits />,
  },
  {
    path: "/facility/customer_management/customers",
    element: <CustomerManagement />,
  },
  {
    path: "/facility/customer_management/add_new_customer",
    element: <AddCustomer />,
  },
  {
    path: "/facility/lease_management/",
    element: <LeaseManagement />,
  },

  {
    path: "/facilitiy/lease_management/reports",
    element: <LeaseReports />,
  },
  {
    path: "/facility/levy_management/",
    element: <LevyManagement />,
  },
  {
    path: "/facility/levy_management/levies/add_levy",
    element: <AddLevy />,
  },
  {
    path: "/facility/levy_management/contracts",
    element: <LevyContract />,
  },
  {
    path: "/facility/levy_management/contracts/add_contract",
    element: <AddContract />,
  },
  {
    path: "/facilitiy/levy_management/levy_invoicing",
    element: <LevyInvoicingManagement />,
  },
  {
    path: "/facilitiy/levy_management/reports",
    element: <LevyReports />,
  },
  {
    path: "/facility/levy_management/settings",
    element: <LevySettings />,
  },
  {
    path: "facility/ticket_management/tickets",
    element: <Tickets />,
  },
  {
    path: "facility/ticket_management/raise_ticket",
    element: <RaiseTicket />,
  },
  {
    path: "facility/ticket_management/view_ticket/:ticketId",
    element: <ViewTicket />,
  },
  {
    path: "facility/ticket_management/start_review/:ticketId",
    element: <StartReview />,
  },
  {
    path: "facility/ticket_management/staff_notification/:ticketId",
    element: <StaffNotification />,
  },
  {
    path: "/facility/value_added_services/service_list",
    element: <ServiceManagement />,
  },
  {
    path: "/facility/value_added_services/service_vendors",
    element: <ServiceVendor />,
  },
  {
    path: "/facility/value_added_services/vas_invoices",
    element: <VasInvoices />,
  },
  {
    path: "/facility/value_added_services/invoice/:invoiceId",
    element: <VasInvoicePage />,
  },
  {
    path: "/facility/booking_management/unit_listing",
    element: <UnitListing />,
  },
  {
    path: "/facility/booking_management/booking_manager",
    element: <BookingManager />,
  },
  {
    path: "/facility/handover_management/handovers/",
    element: <HandoverManagement />,
  },
  {
    path: "/facility/handover_management/add_move_in_handover",
    element: <CreateMoveInHandover />,
  },
  {
    path: "/facility/handover_management/add_move_out_handover",
    element: <CreateMoveOutHandover />,
  },
  {
    path: "/facility/campaigns",
    element: <Campaigns />,
  },
  {
    path: "/facility/campaigns/add_campaign",
    element: <AddCampaign />,
  },

  // Universal invoice route that supports both levy and lease invoices
  {
    path: "/invoice/:facilityId/:invoiceId/:type?",
    element: <UniversalInvoicePage />,
  },

  //Kept the page for backward compatibility
  // {
  //   path: "/facility/levy_management/offline_invoice/:facilityId/:invoiceId/:type?",
  //   element: <UniversalInvoicePage />,
  // },

  ...maintenanceRouter,
  ...expenseRoutes,
  ...utilityRouter,
  {
    path: "*",
    element: <Error404Page />,
  },
]);
