import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Layout from "../../../../component/layout";
import { toastify } from "../../../../../../utils/toast";
import { makeRequest2 } from "../../../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../../../app/z-store/store";
import { customer_account_base_url } from "../../../../../../utils/urls";

const AddAnalogMeter = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);
  const [meterNumber, setMeterNumber] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [currentReading, setCurrentReading] = useState(0);
  const customerType = "postpaid";
  const [customers, setCustomers] = useState([]);
  const [units, setUnits] = useState([]);

  const formatCustomerName = (customer) => {
    if (customer.firstName && customer.lastName) {
      return `${customer.firstName} ${customer.lastName}`;
    } else if (customer.fullName) {
      return customer.fullName;
    } else if (customer.name) {
      return customer.name;
    }
    return "Unnamed Customer";
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedFacilityId) {
        toastify("No facility selected", "error");
        return;
      }
      try {
        setIsLoading(true);
        const [customersRes, unitsRes] = await Promise.all([
          makeRequest2(
            `/api/app/customer_management/get_customers/${selectedFacilityId}`,
            "GET"
          ),
          makeRequest2(
            `/api/app/unit_management/get_facility_units/${selectedFacilityId}`,
            "GET"
          )
        ]);

        if (customersRes.success) {
          const customersData =
            customersRes.data?.customers || customersRes.data || [];
          setCustomers(customersData);
        }
        if (unitsRes.success) {
          const unitsData = unitsRes.data?.units || unitsRes.data || [];
          setUnits(unitsData);
        }
      } catch (error) {
        toastify("Error loading data", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedFacilityId]);

  const handleSubmit = async () => {
    if (!selectedFacilityId) {
      toastify("No facility selected", "error");
      return;
    }
    try {
      if (!meterNumber.trim()) {
        toastify("Meter Number is required", "error");
        return;
      }
      if (!unitId) {
        toastify("Unit is required", "error");
        return;
      }
      setIsSubmitting(true);

      const analogMeterData = {
        meterNumber: meterNumber.trim(),
        customerId,
        unitId,
        initialReading: Number(currentReading),
        currentReading: Number(currentReading),
        customerType: customerId ? "postpaid" : null,
        status: "open"
      };

      const meterResponse = await makeRequest2(
        `/api/app/analog-meters/${selectedFacilityId}`,
        "POST",
        analogMeterData
      );

      if (!meterResponse.success) {
        toastify(meterResponse.error || "Failed to add analog meter", "error");
        setIsSubmitting(false);
        return;
      }

      const createdMeter = meterResponse.data.data;

      // Only create account if a customer is selected
      if (customerId) {
        try {
          const selectedCustomer = customers.find(
            (cust) => cust._id === customerId
          );
          if (!selectedCustomer) {
            toastify("Customer information not found", "error");
            setIsSubmitting(false);
            return;
          }

          const accountData = {
            facilityId: selectedFacilityId,
            account_no: createdMeter.accountNumber,
            customerId,
            customerName: selectedCustomer
              ? (
                  selectedCustomer.fullName ||
                  `${selectedCustomer.firstName || ""} ${
                    selectedCustomer.lastName || ""
                  }`
                ).trim()
              : "Unknown",
            phoneNumber: selectedCustomer.phoneNumber || "N/A",
            meterNumber: createdMeter.meterNumber,
            unitId: createdMeter.unitId,
            payment_type: "Postpaid",
            previousReading: 0,
            currentReading: Number(currentReading),
            meter_id: createdMeter._id
          };

          const accountResponse = await makeRequest2(
            `${customer_account_base_url}/add`,
            "POST",
            accountData
          );

          if (!accountResponse.success) {
            toastify(
              "Analog meter added but account creation failed: " +
                (accountResponse.error || "Unknown error"),
              "warning"
            );
          } else {
            toastify(
              "Analog meter and account added successfully",
              "success"
            );
          }
        } catch (accountError) {
          toastify(
            "Error creating account: " +
              (accountError.message || "Unknown error"),
            "error"
          );
        }
      } else {
        toastify("Analog meter added successfully", "success");
      }

      handleClear();
      navigate("/facility/utility_management/meter_management/water_meters", {
        state: { activeTab: "analog-meters" }
      });
    } catch (error) {
      toastify(
        "An unexpected error occurred: " +
          (error.message || "Unknown error"),
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClear = () => {
    setMeterNumber("");
    setCustomerId("");
    setUnitId("");
    setCurrentReading(0);
  };

  if (!selectedFacilityId) {
    return (
      <Layout>
        <div className="text-center p-5">
          Please select a facility first
        </div>
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout>
        <div className="text-center p-5">Loading data...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/utility_management/meter_management/water_meters">
                    Analog Meters
                  </Link>
                </li>
                <li className="breadcrumb-item">Add New Analog Meter</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link
                to="/facility/utility_management/meter_management/water_meters"
                state={{ activeTab: "analog-meters" }}
              >
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Analog Meter</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="form-label">
                      Meter Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <InputText
                      className="form-control"
                      value={meterNumber}
                      onChange={(e) => setMeterNumber(e.target.value)}
                      placeholder="Enter meter number"
                      disabled={isSubmitting}
                    />
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">Customer (Optional)</label>
                    <select
                      className="form-control"
                      value={customerId}
                      onChange={(e) => setCustomerId(e.target.value)}
                      disabled={isSubmitting}
                    >
                      <option value="">Select Customer (Optional)</option>
                      {customers.map((customer) => (
                        <option key={customer._id} value={customer._id}>
                          {formatCustomerName(customer)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">
                      Unit<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={unitId}
                      onChange={(e) => setUnitId(e.target.value)}
                      disabled={isSubmitting}
                    >
                      <option value="">Select Unit</option>
                      {units.map((unit) => (
                        <option key={unit._id} value={unit._id}>
                          {unit.unitNumber || unit.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label className="form-label">Current Reading</label>
                    <input
                      type="number"
                      className="form-control"
                      value={currentReading}
                      onChange={(e) => setCurrentReading(e.target.value)}
                      step="0.01"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                  disabled={isSubmitting}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddAnalogMeter;