import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getItem } from '../../../../utils/localStorage';
import Layout from '../../component/layout';
import ReusableDialog from '../../component/reusableDialog';
import ConfirmDialog from '../../component/confirmDialog';
import PenaltyForm from './components/penaltyForm';
import ReminderForm from './components/reminderForm';
import { useSettingsData } from './hooks/useSettings';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { deletePenalty, deleteReminder, updatePenalty, updateReminder, } from '../../../../utils/urls';

function LevySettings() {
    const {
        reminders = [],
        penalties = [],
        levies,
        refetchReminders,
        refetchPenalties
    } = useSettingsData();

    const [activeTab, setActiveTab] = useState('penalty');
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [moduleType] = useState('levy_management');
    const [selectedItem, setSelectedItem] = useState(null);

    // New state for toggle and delete confirmation modals
    const [toggleConfirmationVisible, setToggleConfirmationVisible] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({
        type: '',
        item: null,
        action: null
    });

    // Filter Penalties based on search query
    const filteredPenalties = useMemo(() => {
        return penalties.filter(penalty =>
            (penalty.name && penalty.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (penalty.type && penalty.type.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (penalty.amount && penalty.amount.toString().includes(searchQuery))
        );
    }, [penalties, searchQuery]);

    // Filter Reminders based on search query
    const filteredReminders = useMemo(() => {
        return reminders.filter(reminder =>
            (reminder.name && reminder.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (reminder.type && reminder.type.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    }, [reminders, searchQuery]);

    const handleShowDialog = (type, item = null) => {
        setDialogType(type);
        setSelectedItem(item);
        setVisibleDialog(true);
    };

    const handleHideDialog = () => {
        setVisibleDialog(false);
        setDialogType(null);
        setSelectedItem(null);
    };

    const handleDelete = async () => {
        try {
            const { type, item } = confirmationDetails;
            const facilityId = await getItem('selectedFacilityId');

            const url = type === 'penalty'
                ? `${deletePenalty}/${facilityId}/${item._id}`
                : `${deleteReminder}/${facilityId}/${item._id}`;

            const response = await makeRequest2(url, 'DELETE');

            if (response.success) {
                toastify(`${type} deleted successfully`, 'success');
                if (type === 'penalty') {
                    refetchPenalties();
                } else {
                    refetchReminders();
                }
            } else {
                toastify(`Failed to delete ${type}`, 'error');
                console.error('Delete response:', response);
            }
        } catch (error) {
            console.error('Delete error:', error);
            toastify(`Error deleting ${confirmationDetails.type}`, 'error');
        } finally {
            setDeleteConfirmationVisible(false);
        }
    };

    const handleToggleActive = async () => {
        try {
            const { type, item } = confirmationDetails;
            const facilityId = await getItem('selectedFacilityId');
            const url =
                type === 'penalty'
                    ? `${updatePenalty}/${facilityId}/${item._id}`
                    : `${updateReminder}/${facilityId}/${item._id}`;

            const response = await makeRequest2(url, 'PUT', { isActive: !item.isActive });

            if (response.success) {
                toastify(`Status updated`, 'success');
                type === 'penalty' ? refetchPenalties() : refetchReminders();
                setToggleConfirmationVisible(false);
            } else {
                toastify(`Failed to update status`, 'error');
            }
        } catch (error) {
            console.error(`Error updating ${confirmationDetails.type} status:`, error);
            toastify(`Error updating ${confirmationDetails.type} status`, 'error');
        }
    };

    const showDeleteConfirmation = (rowData, type) => {
        setConfirmationDetails({ type, item: rowData, action: handleDelete });
        setDeleteConfirmationVisible(true);
    };

    const showToggleConfirmation = (rowData, type) => {
        setConfirmationDetails({ type, item: rowData, action: handleToggleActive });
        setToggleConfirmationVisible(true);
    };



    const actionBodyTemplate = (rowData, type) => {
        return (
            <div className="d-flex gap-2">
                {/* Delete Button */}
                {/* <Button
                    icon="ti ti-trash"
                    className="p-button-rounded p-button-danger mr-2"
                    onClick={() => {
                        showDeleteConfirmation(rowData, type)
                    }}
                    tooltip="Delete"
                /> */}
                {/* Enable/Disable Button */}
                <Button
                    icon={`ti ${rowData.isActive ? "ti-lock-open" : "ti-lock"}`}
                    className="p-button-rounded p-button-warning"
                    tooltip={rowData.isActive ? "Disable" : "Enable"}
                    onClick={() => showToggleConfirmation(rowData, type)}
                />
            </div>
        );
    };

    const renderPenaltiesTable = () => (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                        <div className="card-body position-relative">
                            <div className="row">
                                <div className="col-md-3 col-xs-12 mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-9 col-xs-12 mb-3">
                                    <div style={{ float: 'right' }}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => handleShowDialog('penalty')}
                                        >
                                            Add New Penalty
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dt-responsive table-responsive">
                                        <DataTable
                                            value={moduleType === 'levy_management' ? filteredPenalties : []}
                                            emptyMessage="No Penalties found."
                                            sortMode="multiple"
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="name" header="Name" />
                                            <Column field="type" header="Type" />
                                            <Column field="effectDays" header="Effect Days" />
                                            <Column
                                                field="percentage"
                                                header="Percentage"
                                                body={(rowData) => rowData.percentage ? `${rowData.percentage}%` : 'N/A'}
                                            />
                                            <Column
                                                field="amount"
                                                header="Amount"
                                                sortable
                                                body={(rowData) => rowData.amount || 'N/A'}
                                            />
                                            <Column
                                                header="Actions"
                                                body={(rowData) => actionBodyTemplate(rowData, 'penalty')}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderRemindersTable = () => (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                        <div className="card-body position-relative">
                            <div className="row">
                                <div className="col-md-3 col-xs-12 mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-9 col-xs-12 mb-3">
                                    <div style={{ float: 'right' }}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => handleShowDialog('reminder')}
                                        >
                                            Add New Reminder
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dt-responsive table-responsive">
                                        <DataTable
                                            value={moduleType === 'levy_management' ? filteredReminders : []}
                                            emptyMessage="No Reminders found."
                                            sortMode="multiple"
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="name" header="Name" />
                                            <Column field="type" header="Type" />
                                            <Column field="time" header="Time" />
                                            <Column field="day" header="Day" body={(rowData) => rowData.type === 'onetime' ? 'N/A' : rowData.day} />
                                            <Column
                                                header="Actions"
                                                body={(rowData) => actionBodyTemplate(rowData, 'reminder')}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // This function renders the content of the dialog based on type (penalty or reminder)
    const renderDialogContent = () => {
        switch (dialogType) {
            case 'penalty':
                return (
                    <PenaltyForm
                        levies={levies}
                        module={moduleType}
                        onSubmitSuccess={() => {
                            refetchPenalties();
                            handleHideDialog();
                        }}
                    />
                );
            case 'reminder':
                return (
                    <ReminderForm
                        levies={levies}
                        module={moduleType}
                        onSubmitSuccess={() => {
                            refetchReminders();
                            handleHideDialog();
                        }}
                    />
                );
            default:
                return null;
        }
    };


    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/levy_management"}>Levy Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Settings</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'penalty' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('penalty')}
                                        href="#profile-1"
                                        role="tab"
                                    >
                                        <i className="fas fa-flag-checkered me-2"></i>Penalties
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'reminder' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('reminder')}
                                        href="#profile-2"
                                        role="tab"
                                    >
                                        <i className="fas fa-backward me-2"></i>Reminders
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="tab-content">
                                <div
                                    className={`tab-pane ${activeTab === 'penalty' ? 'show active' : ''}`}
                                    id="profile-1"
                                    role="tabpanel"
                                >
                                    {renderPenaltiesTable()}
                                </div>
                                <div
                                    className={`tab-pane ${activeTab === 'reminder' ? 'show active' : ''}`}
                                    id="profile-2"
                                    role="tabpanel"
                                >
                                    {renderRemindersTable()}
                                </div>
                            </div>
                            {/* Custom Confirm Dialog for Deletion */}
                            <ConfirmDialog
                                visible={deleteConfirmationVisible}
                                onHide={() => setDeleteConfirmationVisible(false)}
                                message={`Are you sure you want to delete this ${confirmationDetails.type}?`}
                                onConfirm={handleDelete}
                            />

                            {/* Custom Confirm Dialog for Toggle Status */}
                            <ConfirmDialog
                                visible={toggleConfirmationVisible}
                                onHide={() => setToggleConfirmationVisible(false)}
                                message={`Are you sure you want to ${confirmationDetails.item?.isActive ? 'disable' : 'enable'} this ${confirmationDetails.type}?`}
                                onConfirm={handleToggleActive}
                            />
                            <ReusableDialog
                                header={
                                    dialogType === 'penalty'
                                        ? 'Add New Penalty'
                                        : dialogType === 'reminder'
                                            ? 'Add New Reminder'
                                            : ''
                                }
                                visible={visibleDialog}
                                onHide={handleHideDialog}
                            >
                                {renderDialogContent()}
                            </ReusableDialog>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LevySettings;
