import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toastify } from '../../../utils/toast';
import { makeRequest } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { getTicketURL } from '../../../utils/urls';


const StaffNotification = () => {
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState('');

    const openFullScreen = (imageSrc) => {
        setIsFullScreen(true);
        setFullScreenImage(imageSrc);
    };

    const closeFullScreen = () => {
        setIsFullScreen(false);
    };

    const isImage = (file) => {
        return /\.(jpg|jpeg|png|gif)$/i.test(file);
    };

    const getTicket = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest(`${getTicketURL}/${facilityId}/${ticketId}`, 'GET', {});

            if (response.success) {
                const fetchedTicket = response.data.ticket;

                const ticketWithCustomerInfo = {
                    ...fetchedTicket,
                    fullName: fetchedTicket.CustomerInfo?.fullName,
                    phoneNumber: fetchedTicket.CustomerInfo?.phoneNumber,
                    requester: fetchedTicket.CustomerInfo?.requester,
                };

                setTicket(ticketWithCustomerInfo);
                console.log('Ticket:', ticketWithCustomerInfo);

            } else {
                throw new Error(response.message || 'Failed to fetch ticket details');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    useEffect(() => {
        getTicket();
    }, [])

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="card shadow-sm">
                            <div className="card-header text-center py-3 h4">
                                🎟️ Ticket Details
                            </div>
                            <div className="card-body px-4 py-3">
                                <div className="row g-3">
                                    {/* Customer Name */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-user-circle me-2"></i>Raised By:
                                        </h6>
                                        <p className="mb-1">{ticket.fullName}</p>
                                    </div>

                                    {/* Customer Phone */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-phone-alt me-2"></i>Phone Number:
                                        </h6>
                                        <p className="mb-1">0{ticket.phoneNumber}</p>
                                    </div>

                                    {/* Ticket Type */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-clipboard-list me-2"></i>Type:
                                        </h6>
                                        <p className="mb-1">{ticket.ticketType}</p>
                                    </div>

                                    {/* Subject */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-tags me-2"></i>Subject:
                                        </h6>
                                        <p className="mb-1">{ticket.subject}</p>
                                    </div>

                                    {/* Priority */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-tags me-2"></i>Priority:
                                        </h6>
                                        <p className="mb-1">{ticket.priority || 'N/A'}</p>
                                    </div>

                                    {/* Date */}
                                    <div className="col-md-6">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-calendar-alt me-2"></i>Date:
                                        </h6>
                                        <p className="mb-1">{new Date(ticket.date).toLocaleString()}</p>
                                    </div>

                                    {/* Description */}
                                    <div className="col-md-12">
                                        <h6 className="fw-bold text-muted">
                                            <i className="fas fa-info-circle me-2"></i>Description:
                                        </h6>
                                        <p className="mb-1" style={{ textAlign: 'justify' }}>
                                            {ticket.description}
                                        </p>
                                    </div>

                                    {/* Attachments */}
                                    {ticket.image && (
                                        <div className="col-md-12 text-center">
                                            <h6 className="fw-bold text-muted">
                                                <i className="fas fa-image me-2"></i>Attachments:
                                            </h6>
                                            <div className="d-flex justify-content-center flex-wrap gap-3">
                                                <div style={{ textAlign: 'center' }}>
                                                    <h6 className="fw-bold mb-2">Attachment</h6>
                                                    <img
                                                        src={`${process.env.REACT_APP_BASE_URL}/${ticket.image}`}
                                                        alt="Ticket Attachment"
                                                        className="img-fluid rounded shadow-sm"
                                                        style={{
                                                            height: '200px',
                                                            width: '200px',
                                                            objectFit: 'cover',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            openFullScreen(
                                                                `${process.env.REACT_APP_BASE_URL}/${ticket.image}`
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Full-Screen Image View */}
                                    {isFullScreen && (
                                        <div
                                            style={{
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                width: '100vw',
                                                height: '100vh',
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                zIndex: 9999,
                                            }}
                                            onClick={closeFullScreen}
                                        >
                                            <div style={{ maxWidth: '90%', maxHeight: '90%' }}>
                                                <img
                                                    src={fullScreenImage}
                                                    alt="Full-Screen Attachment"
                                                    className="img-fluid"
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* Close Button */}
                                    <div className="text-center mt-4">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => window.close()}
                                        >
                                            Close Page
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default StaffNotification