import React, { useState, useEffect } from "react";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { fetchFacilityPaymentDetails } from "../../../utils/urls";

const LevySettingsTab = ({ facilityId }) => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const url = `${fetchFacilityPaymentDetails}/${facilityId}`;
      const response = await makeRequest2(url, "GET");
      console.log("Response:", response);

      if (response.success && response.data?.length > 0) {
        // Transform the data for DataTable
        const formattedData = response.data.map(item => ({
          shortCode: item.shortCode,
          module: item.module
        }));
        setSettings(formattedData);
      } else {
        setSettings([]);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      toastify("Error fetching payment details", "error");
      setSettings([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (facilityId) {
      fetchSettings();
    }
  }, [facilityId]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title mb-4">Payment Integration Details</h5>
        <DataTable
          value={settings}
          loading={loading}
          showGridlines
          stripedRows
          responsiveLayout="scroll"
          emptyMessage="No payment details found"
        >
          <Column
            field="Bank"
            header="Bank Name"
            sortable
            style={{ minWidth: '200px' }}
            body={() => 'MPESA'} // Display 'MPESA' for every row
          />
          <Column
            field="shortCode"
            header="PayBill"
            sortable
            style={{ minWidth: '200px' }}
          />
          <Column
            field="module"
            header="Module"
            sortable
            style={{ minWidth: '200px' }}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default LevySettingsTab;