import React, { useState, useEffect } from 'react';
import Layout from '../component/layout'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact";
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { getTicketURL, approveComplaintURL } from '../../../utils/urls';


const ViewTicket = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();

  const [isReviewDialogOpen, setReviewDialogOpen] = useState(false);

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [reviewText, setReviewText] = useState('');

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState('');

  const openFullScreen = (imageSrc) => {
    setIsFullScreen(true);
    setFullScreenImage(imageSrc);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
  };

  const isImage = (file) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(file);
  };



  const [ticket, setTicket] = useState(null);


  const getTicket = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const response = await makeRequest2(`${getTicketURL}/${facilityId}/${ticketId}`, 'GET', {});

      if (response.success) {
        const fetchedTicket = response.data.ticket;

        // Attach RequesterInfo to the ticket
        const ticketWithRequesterInfo = {
          ...fetchedTicket,
          fullName: fetchedTicket.RequesterInfo?.fullName || 'Unknown',
          phoneNumber: fetchedTicket.RequesterInfo?.phoneNumber || '',
          email: fetchedTicket.RequesterInfo?.email || '',
          requester: fetchedTicket.RequesterInfo?.requester || '',
        };

        setTicket(ticketWithRequesterInfo);
      } else {
        throw new Error(response.message || 'Failed to fetch ticket details');
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };





  const handleSubmitReview = async (ticketId, reviewText) => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const response = await makeRequest2(`${approveComplaintURL}/${facilityId}/${ticketId}`, 'POST', {
        review: reviewText,
      });

      if (response.success) {
        toastify("Review sent successfully", 'success');
        setReviewDialogOpen(false);
      } else {
        toastify(response.error, 'error');
      }
    } catch (err) {
      toastify('Failed to send review', 'error');
    }
  };

  const handleAction = (ticket) => {
    setSelectedTicket(ticket);
    setReviewDialogOpen(true);
  };


  useEffect(() => {
    getTicket();
  }, [])

  // Show a loading message while ticket data is being fetched
  if (!ticket) {
    return <div>Loading...</div>;
  }


  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"facility/ticket_management/tickets"}>Tickets</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>View Ticket</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">

              <div className="card">
                <div className="card-header">
                  <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                </div>
              </div>

              <div className="card">
                <div className="card-body py-0">


                  <div
                    className="card-header text-center py-3 h4">
                    🎟️ Ticket Details
                  </div>

                  <div className="card-body p-4">
                    <div className="row mb-4">
                      {/** Raised By */}
                      <div className="col-md-6 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-user-circle me-2"></i> Raised By:
                        </h5>
                        <div className="d-flex align-items-center gap-2">
                          <p style={{ fontSize: '1.2rem', margin: 0 }}>{ticket.fullName}</p>
                          {ticket.isStaff && <span className="badge bg-success">Staff</span>}
                        </div>
                      </div>




                      {/** Phone Number */}
                      <div className="col-md-6 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-phone-alt me-2"></i> Phone Number:
                        </h5>
                        <p style={{ fontSize: '1.2rem' }}>
                          {ticket.phoneNumber ? `0${ticket.phoneNumber}` : 'N/A'}
                        </p>
                      </div>



                      {/** Ticket Type */}
                      <div className="col-md-6 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-clipboard-list me-2"></i>Type:
                        </h5>
                        <p style={{ fontSize: '1.2rem' }}>{ticket.ticketType}</p>
                      </div>

                      {/** Subject */}
                      <div className="col-md-6 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-tags me-2"></i>Subject:
                        </h5>
                        <p style={{ fontSize: '1.2rem' }}>{ticket.subject}</p>
                      </div>


                      {/** Priority */}
                      <div className="col-md-6 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-tags me-2"></i>Priority:
                        </h5>
                        <p style={{ fontSize: '1.2rem' }}>{ticket.priority || 'N/A'}</p>
                      </div>

                      {/** Date */}
                      <div className="col-md-6 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-calendar-alt me-2"></i>Date:
                        </h5>
                        <p style={{ fontSize: '1.2rem' }}>{new Date(ticket.date).toLocaleString()}</p>
                      </div>

                      {/** Description */}
                      <div className="col-md-12 mb-3">
                        <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                          <i className="fas fa-info-circle me-2"></i>Description:
                        </h5>
                        <p style={{ fontSize: '1.2rem', textAlign: 'justify' }}>{ticket.description}</p>
                      </div>

                      {/** Image */}

                      {ticket.status === 'on hold' ? (
                        <div className="col-md-12 text-center mb-4">
                          <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                            <i className="fas fa-image me-2"></i>Attachments:
                          </h5>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: '20px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {ticket.image && (
                              <div style={{ textAlign: 'center' }}>
                              {console.log("Image URL:", `${process.env.REACT_APP_BASE_URL}/${ticket.image}`)}

                                <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Before Assessment</h6>
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}/${ticket.image}`}
                                  alt="Original Ticket Attachment"
                                  className="img-fluid rounded shadow"
                                  style={{
                                    height: '300px',
                                    width: '300px',
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                    transition: 'transform 0.3s ease',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => openFullScreen(`${process.env.REACT_APP_BASE_URL}/${ticket.image}`)}
                                />
                              </div>
                            )}

                            {ticket.assessImage && (
                              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>After Assessment</h6>
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}/${ticket.assessImage}`}
                                  alt="After Assessment Attachment"
                                  className="img-fluid rounded shadow"
                                  style={{
                                    height: '300px',
                                    width: '300px',
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                    transition: 'transform 0.3s ease',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => openFullScreen(`${process.env.REACT_APP_BASE_URL}/${ticket.assessImage}`)}
                                />
                              </div>
                            )}

                            {ticket.costAttachment && (
                              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Cost Attachment</h6>
                                <a
                                  href={`${process.env.REACT_APP_BASE_URL}/${ticket.costAttachment}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary"
                                >
                                  View Cost Attachment
                                </a>
                              </div>
                            )}

                          </div>
                        </div>
                      ) : (
                        ticket.image ? (
                          <div className="col-md-12 text-center mb-4">
                            <h5 style={{ fontWeight: 'bold', color: '#495057' }}>
                              <i className="fas fa-image me-2"></i>Attachment:
                            </h5>
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/${ticket.image}`}
                              alt="Ticket Attachment"
                              className="img-fluid rounded shadow"
                              style={{
                                height: '300px',
                                width: '300px',
                                borderRadius: '10px',
                                objectFit: 'cover',
                                transition: 'transform 0.3s ease',
                                cursor: 'pointer',
                              }}
                              onClick={() => openFullScreen(`${process.env.REACT_APP_BASE_URL}/${ticket.image}`)}
                            />
                          </div>
                        ) : (
                          <p>No attachment available.</p>
                        )
                      )}



                      {isFullScreen && (
                        <div
                          style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999,
                          }}
                          onClick={closeFullScreen}
                        >
                          <div
                            style={{
                              width: '90vw',
                              height: '90vh',
                              aspectRatio: '16/9', // You can adjust this to your desired aspect ratio
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              src={fullScreenImage}
                              alt="Full-Screen Attachment"
                              style={{
                                width: '80%',
                                height: '90%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </div>
                      )}



                    </div>

                    {/** Buttons */}
                    <div className="d-flex justify-content-center gap-3">
                      {ticket.ticketType === 'Complaints' ? (
                        <>{
                          ticket.reviewed ? (
                            <span className="text-muted">
                              Review Completed
                            </span>
                          ) : (
                            <button
                              className="btn btn-outline-success btn-lg"
                              style={{ padding: '0.75em 1.5em' }}
                              onClick={() => handleAction(ticket)}
                            >
                              <i className="fas fa-check-circle me-2"></i> Review Ticket
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {ticket.reviewed ? (
                            <span className="text-muted">
                              Review Completed
                              <br></br>
                              {ticket.status === 'closed' && ticket.closingReason && (
                                <><strong style={{ color: 'red' }}>Reason for Closing Ticket:</strong> {ticket.closingReason}</>
                              )}
                            </span>
                          ) : (
                            <button
                              className="btn btn-outline-success btn-lg"
                              style={{ padding: '0.75em 1.5em' }}
                              onClick={() => navigate(`/facility/ticket_management/start_review/${ticket._id}`, {
                                state: { requester: ticket.requester, description: ticket.description },
                              })}
                            >
                              <i className="fas fa-check-circle me-2"></i> Start Review
                            </button>
                          )}
                        </>
                      )}
                    </div>

                  </div>



                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



      <Dialog
        header={`Submit Review`}
        style={{ width: '70vw' }}
        visible={isReviewDialogOpen}
        onHide={() => {
          if (!isReviewDialogOpen) return;
          setReviewDialogOpen(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="mb-4">
              <label className="form-label">Review</label>
              <textarea
                className="form-control"
                rows={5}
                placeholder="Leave a review here"
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              ></textarea>
            </div>

            <div className="mt-4" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={() => handleSubmitReview(ticketId, reviewText)}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>

    </Layout >
  )
}

export default ViewTicket