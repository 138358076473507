import React, { useState, useEffect } from "react";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  get_all_inspection_settings,
  add_inspection_settings,
  update_inspection_settings
} from "../../../utils/urls";

const HandoverInspectionTab = ({ facilityId }) => {
    const [inspectionItems, setInspectionItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [categories, setCategories] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editItemId, setEditItemId] = useState(null);

    // Default conditions list
    const defaultConditions = ["Excellent", "Good", "Fair", "Poor", "Damaged", "Non-functional"];

    const [newItem, setNewItem] = useState({
        name: "",
        category: "",
        subcategory: "",
        description: "",
        possibleConditions: ["Excellent", "Good", "Fair", "Poor", "Damaged", "Non-functional"],
        defaultCondition: "Good",
        isRequired: true,
        defaultQuantity: 1,
        active: true
    });

    // Fetch all inspection items
    const fetchInspectionItems = async () => {
        if (!facilityId) return;

        try {
            setLoading(true);
            const response = await makeRequest2(
                `${get_all_inspection_settings}/${facilityId}`,
                "GET"
            );
            setLoading(false);

            if (response.success) {
                // Ensure data is an array
                const items = Array.isArray(response.data)
                    ? response.data
                    : (response.data?.data || []);

                setInspectionItems(items);

                // Extract categories from items
                const allCategories = [...new Set(items.map(item => item.category))];
                setCategories(allCategories);
            } else {
                toastify("Failed to fetch inspection items", "error");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching inspection items:", error);
            toastify("Failed to fetch inspection items", "error");
        }
    };

    // Set default condition - we don't need to select/deselect conditions anymore
    const setAsDefault = (condition) => {
        setNewItem({
            ...newItem,
            defaultCondition: condition
        });
    };

    // Handle editing an item
    const handleEdit = (rowData) => {
        setIsEditing(true);
        setEditItemId(rowData._id);
        setNewItem({
            name: rowData.name,
            category: rowData.category,
            subcategory: rowData.subcategory || "",
            description: rowData.description || "",
            possibleConditions: defaultConditions,
            defaultCondition: rowData.defaultCondition || "Good",
            isRequired: rowData.isRequired !== undefined ? rowData.isRequired : true,
            defaultQuantity: rowData.defaultQuantity || 1,
            active: rowData.active !== undefined ? rowData.active : true
        });
        setModalOpen(true);
    };

    // Add or update inspection item
    const saveInspectionItem = async () => {
        if (!newItem.name || !newItem.category) {
            toastify("Item name and category are required", "error");
            return;
        }

        // We always have conditions now, so we don't need this check anymore

        try {
            setLoading(true);

            // Prepare data for API - match the schema structure
            const itemData = {
                name: newItem.name,
                category: newItem.category,
                subcategory: newItem.subcategory || null,
                description: newItem.description || "",
                possibleConditions: newItem.possibleConditions,
                defaultCondition: newItem.defaultCondition,
                isRequired: newItem.isRequired,
                defaultQuantity: newItem.defaultQuantity,
                active: true
            };

            console.log("Sending data to API:", itemData);

            let response;
            if (isEditing) {
                response = await makeRequest2(
                    `${update_inspection_settings}/${facilityId}/${editItemId}`,
                    "PUT",
                    itemData
                );
            } else {
                response = await makeRequest2(
                    `${add_inspection_settings}/${facilityId}`,
                    "POST",
                    itemData
                );
            }

            setLoading(false);

            // Check for various success conditions including 201 status code
            if (response.success || (response.error && response.error.includes('201'))) {
                toastify(`Item ${isEditing ? 'updated' : 'added'} successfully`, "success");
                setModalOpen(false);
                // Reset form
                setNewItem({
                    name: "",
                    category: "",
                    subcategory: "",
                    description: "",
                    possibleConditions: ["Excellent", "Good", "Fair", "Poor", "Damaged", "Non-functional"],
                    defaultCondition: "Good",
                    isRequired: true,
                    defaultQuantity: 1,
                    active: true
                });
                setIsEditing(false);
                setEditItemId(null);
                fetchInspectionItems();
            } else {
                console.error("API Error:", response);
                toastify(response.error || `Failed to ${isEditing ? 'update' : 'add'} item`, "error");
            }
        } catch (error) {
            setLoading(false);
            console.error(`Error ${isEditing ? 'updating' : 'adding'} item:`, error);
            toastify(error.message || `Failed to ${isEditing ? 'update' : 'add'} item`, "error");
        }
    };

    // Filter items by category
    const filteredItems = selectedCategory === "All"
        ? inspectionItems
        : inspectionItems.filter(item => item.category === selectedCategory);

    useEffect(() => {
        if (facilityId) {
            fetchInspectionItems();
        }
    }, [facilityId]);

    if (!facilityId) {
        return <div className="card">Please select a facility</div>;
    }

    // Render conditions with checkboxes in a row
    const conditionsTemplate = (rowData) => {
        if (!Array.isArray(rowData.possibleConditions) || rowData.possibleConditions.length === 0) {
            return <span className="text-muted">No conditions</span>;
        }

        return (
            <div className="d-flex flex-wrap gap-3">
                {rowData.possibleConditions.map((condition, index) => (
                    <div key={index} className="d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input me-2"
                            readOnly
                            disabled
                            checked={condition === rowData.defaultCondition}
                            style={{ cursor: 'not-allowed', opacity: 0.7 }}
                        />
                        <span className={condition === rowData.defaultCondition ? "fw-bold" : "text-muted"}>
                            {condition}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    // Render actions column
    const actionsTemplate = (rowData) => {
        return (
            <div className="d-flex gap-2 justify-content-end">
                <Button
                    icon="ti ti-edit"
                    className="p-button-rounded p-button-info p-button-sm"
                    onClick={() => handleEdit(rowData)}
                    tooltip="Edit"
                    style={{ width: '2.5rem', height: '2.5rem' }}
                />
            </div>
        );
    };

    // Empty template
    const emptyTemplate = () => {
        return (
            <div className="text-center py-4">
                <div className="text-muted">
                    <i className="ti ti-inbox mb-2" style={{ fontSize: "24px" }}></i>
                    <p className="mb-0">No inspection items found</p>
                    <p>Add your first item to get started</p>
                </div>
            </div>
        );
    };

    return (
        <div className="row p-3">
            {/* Inspection Items List */}
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5>Handover Inspection Items</h5>
                            <button className="btn btn-primary" onClick={() => {
                                setIsEditing(false);
                                setEditItemId(null);
                                setNewItem({
                                    name: "",
                                    category: "",
                                    subcategory: "",
                                    description: "",
                                    possibleConditions: ["Excellent", "Good", "Fair", "Poor", "Damaged", "Non-functional"],
                                    defaultCondition: "Good",
                                    isRequired: true,
                                    defaultQuantity: 1,
                                    active: true
                                });
                                setModalOpen(true);
                            }}>
                                <i className="ti ti-plus me-1"></i> Add New Item
                            </button>
                        </div>

                        <DataTable
                            value={Array.isArray(filteredItems) ? filteredItems : []}
                            loading={loading && inspectionItems.length === 0}
                            emptyMessage={emptyTemplate}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="p-datatable-sm"
                        >
                            <Column field="name" header="NAME" sortable></Column>
                            <Column header="CONDITIONS" body={conditionsTemplate}></Column>
                            <Column body={actionsTemplate} header="ACTIONS" style={{ width: '80px' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            {/* Add/Edit Item Modal */}
            {modalOpen && (
                <>
                    <div className="modal show fade" style={{ display: "block" }} tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{isEditing ? 'Edit' : 'Add'} Inspection Item</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setModalOpen(false)}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="form-label">Item Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={newItem.name}
                                                onChange={(e) =>
                                                    setNewItem({ ...newItem, name: e.target.value })
                                                }
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Category <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select"
                                                value={newItem.category}
                                                onChange={(e) =>
                                                    setNewItem({ ...newItem, category: e.target.value })
                                                }
                                                required
                                            >
                                                <option value="">Select Category</option>
                                                <option value="Furniture">Furniture</option>
                                                <option value="Appliance">Appliance</option>
                                                <option value="Electronics">Electronics</option>
                                                <option value="Fixture">Fixture</option>
                                                <option value="Utility">Utility</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Description</label>
                                            <textarea
                                                className="form-control"
                                                value={newItem.description || ""}
                                                onChange={(e) =>
                                                    setNewItem({ ...newItem, description: e.target.value })
                                                }
                                                rows="2"
                                            ></textarea>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Default Condition <span className="text-danger">*</span></label>
                                            <p className="small text-muted mb-2">Select which condition should be the default</p>

                                            <div className="d-flex flex-wrap gap-3 mt-3">
                                                {defaultConditions.map((condition, index) => (
                                                    <div key={index} className="form-check me-3">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="defaultCondition"
                                                            id={`default-${index}`}
                                                            checked={newItem.defaultCondition === condition}
                                                            onChange={() => setAsDefault(condition)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`default-${index}`}>
                                                            {condition}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="form-check mb-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={newItem.isRequired}
                                                onChange={(e) =>
                                                    setNewItem({ ...newItem, isRequired: e.target.checked })
                                                }
                                                id="isRequired"
                                            />
                                            <label className="form-check-label" htmlFor="isRequired">
                                                Required Item
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={saveInspectionItem}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                Saving...
                                            </>
                                        ) : (
                                            isEditing ? "Update Item" : "Save Item"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Backdrop */}
                    <div className="modal-backdrop fade show"></div>
                </>
            )}
        </div>
    );
};

export default HandoverInspectionTab;