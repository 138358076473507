import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { makeRequest2 } from './makeRequest';
import { recordCashPayment } from './urls';
import { toastify } from './toast';
import { v4 as uuidv4 } from 'uuid';

/**
 * CashPaymentModal Component - A reusable modal for recording cash payments
 * 
 * @param {Object} props
 * @param {boolean} props.visible - Controls modal visibility
 * @param {function} props.onHide - Function to call when hiding the modal
 * @param {Object} props.invoice - The invoice data
 * @param {string} props.facilityId - The ID of the facility
 * @param {function} props.onSuccess - Callback on successful payment
 * @param {function} props.onError - Callback on payment error
 * @returns {JSX.Element}
 */
const CashPaymentModal = ({
    visible,
    onHide,
    invoice,
    facilityId,
    onSuccess,
    onError
}) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        amount: 0,
        receiptNumber: `CASH-${uuidv4().substring(0, 8)}`,
        paymentMethod: 'Cash',
        paymentDate: new Date().toISOString().split('T')[0],
        notes: '',
        currencyId: '',
        checkNumber: '',
        bankName: '',
        transferReference: ''
    });

    // Update amount when invoice changes
    useEffect(() => {
        if (invoice) {
            setFormData(prev => ({
                ...prev,
                amount: invoice.totalAmount - (invoice.amountPaid || 0),
                currencyId: invoice?.currency?.id || ''
            }));
        }
    }, [invoice]);

    // Reset form when modal is opened/closed
    useEffect(() => {
        if (visible) {
            // Generate a new receipt number whenever the modal is opened
            setFormData(prev => ({
                ...prev,
                receiptNumber: `CASH-${uuidv4().substring(0, 8)}`,
                paymentDate: new Date().toISOString().split('T')[0],
                notes: '',
                checkNumber: '',
                bankName: '',
                transferReference: ''
            }));
        }
    }, [visible]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (!facilityId || !invoice?._id) {
                throw new Error('Missing required payment details');
            }

            // Validate amount
            if (parseFloat(formData.amount) <= 0) {
                throw new Error('Payment amount must be greater than zero');
            }

            // Create payment payload
            const payload = {
                invoiceNumber: invoice.invoiceNumber,
                amount: parseFloat(formData.amount),
                receiptNumber: formData.receiptNumber,
                paymentMethod: formData.paymentMethod,
                paymentDate: formData.paymentDate,
                notes: formData.notes,
                currencyId: formData.currencyId || invoice?.currency?.id
            };

            console.log('Payment payload:', payload);

            // Add payment method specific details
            if (formData.paymentMethod === 'Check') {
                payload.checkNumber = formData.checkNumber;
                payload.bankName = formData.bankName;
            } else if (formData.paymentMethod === 'Bank Transfer') {
                payload.transferReference = formData.transferReference;
                payload.bankName = formData.bankName;
            }

            // Make API request
            const response = await makeRequest2(
                `${recordCashPayment}/${facilityId}`,
                'POST',
                payload
            );

            if (!response.success) {
                throw new Error(response.message || 'Failed to record payment');
            }

            toastify('Cash payment recorded successfully. Pending approval.', 'success');

            if (onSuccess) {
                onSuccess(response.payment);
            }

            // Close modal after successful submission
            setTimeout(() => {
                onHide();
            }, 2000);
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Payment recording failed. Please try again.';

            toastify(errorMessage, 'error');

            if (onError) {
                onError(error);
            }
        } finally {
            setLoading(false);
        }
    };

    const getRemainingBalance = () => {
        if (!invoice) return 0;
        return (invoice.totalAmount || 0) - (invoice.amountPaid || 0);
    };

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header="Record Invoice Payment"
            modal
            className="p-fluid"
            style={{ width: '450px' }}
            dismissableMask={!loading}
            closeOnEscape={!loading}
        >
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label htmlFor="amount">Amount ({invoice?.currency?.code || 'KES'})</label>
                    <input
                        type="number"
                        className="form-control"
                        id="amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleInputChange}
                        disabled={loading}
                        required
                        step="0.01"
                        min="0.01"
                    />
                    <small className="text-muted">
                        Balance due: {invoice?.currency?.code || 'KES'} {getRemainingBalance().toFixed(2)}
                    </small>
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="receiptNumber">Receipt Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="receiptNumber"
                        name="receiptNumber"
                        value={formData.receiptNumber}
                        onChange={handleInputChange}
                        disabled={loading}
                        required
                    />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="paymentDate">Payment Date</label>
                    <input
                        type="date"
                        className="form-control"
                        id="paymentDate"
                        name="paymentDate"
                        value={formData.paymentDate}
                        onChange={handleInputChange}
                        disabled={loading}
                        required
                        max={new Date().toISOString().split('T')[0]}
                    />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="paymentMethod">Payment Method</label>
                    <select
                        className="form-select"
                        id="paymentMethod"
                        name="paymentMethod"
                        value={formData.paymentMethod}
                        onChange={handleInputChange}
                        disabled={loading}
                        required
                    >
                        <option value="Cash">Cash</option>
                        <option value="Check">Check</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                    </select>
                </div>

                {formData.paymentMethod === 'Check' && (
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="checkNumber">Check Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkNumber"
                                name="checkNumber"
                                value={formData.checkNumber}
                                onChange={handleInputChange}
                                disabled={loading}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="bankName">Bank Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="bankName"
                                name="bankName"
                                value={formData.bankName}
                                onChange={handleInputChange}
                                disabled={loading}
                                required
                            />
                        </div>
                    </>
                )}

                {formData.paymentMethod === 'Bank Transfer' && (
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="bankName">Bank Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="bankName"
                                name="bankName"
                                value={formData.bankName}
                                onChange={handleInputChange}
                                disabled={loading}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="transferReference">Transfer Reference</label>
                            <input
                                type="text"
                                className="form-control"
                                id="transferReference"
                                name="transferReference"
                                value={formData.transferReference}
                                onChange={handleInputChange}
                                disabled={loading}
                                required
                            />
                        </div>
                    </>
                )}

                <div className="form-group mb-3">
                    <label htmlFor="notes">Notes</label>
                    <InputTextarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        value={formData.notes}
                        onChange={handleInputChange}
                        disabled={loading}
                        rows={3}
                        autoResize={false}
                    />
                </div>

                <div className="d-flex justify-content-end mt-4">
                    <Button
                        label="Cancel"
                        icon="pi pi-times"
                        onClick={onHide}
                        className="p-button-text btn btn-secondary me-2"
                        disabled={loading}
                        type="button"
                    />
                    <Button
                        label={loading ? 'Processing...' : 'Record Payment'}
                        icon="pi pi-check"
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export default CashPaymentModal;