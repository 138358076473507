import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getItem, clearStorage } from '../../../utils/localStorage';

const InactivityLogout = () => {
    const dispatch = useDispatch();
    const timeoutId = useRef(null); // Use useRef to store the timeout ID

    const HandleLogOut = async () => {
        await clearStorage();
        window.location.href = '/';
    }

    const resetTimer = () => {
        // Clear the existing timeout
        clearTimeout(timeoutId.current);
        // Set a new timeout
        timeoutId.current = setTimeout(() => {
            // Check if "Remember Me" is checked
            const remembered = localStorage.getItem('rememberMe');
            if (remembered && JSON.parse(remembered)) {
                // If "Remember Me" is checked, do not log out
                return;
            }
            // Dispatch logout action
            dispatch(HandleLogOut());
        }, 300000); // 300000 ms = 5 minutes
    };

    useEffect(() => {
        // Set the initial timer
        resetTimer();

        // Add event listeners for user activity
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);
        window.addEventListener('scroll', resetTimer);

        // Cleanup function to remove event listeners and clear timeout
        return () => {
            clearTimeout(timeoutId.current);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('scroll', resetTimer);
        };
    }, []); // Empty dependency array to run only on mount/unmount

    return null; // This component does not render anything
}

export default InactivityLogout;