import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { transactions } from '../../../../../app/data/fake';

const TransactionsTab = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );

    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            'paid': 'bg-success',
            'partial': 'bg-warning',
            'unpaid': 'bg-danger'
        };
    
        return (
            <span className={`badge ${statusColors[rowData.status]}`}>
                {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
            </span>
        );
    };
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-KE', {
            style: 'currency',
            currency: 'KES'
        }).format(value);
    };


    return (
        <div className="card">
            <div className="card-body">
                <div className="col-md-3 col-xs-12 mb-3">
                    <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="dt-responsive table-responsive">
                    <DataTable
                        value={transactions}
                        header={header}
                        emptyMessage="No transactions found."
                        sortMode="multiple"
                        paginator
                        rows={5}
                        stripedRows
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="transaction_ref" header="Transaction Ref"></Column>
                        <Column field="customer" header="Customer"></Column>
                        <Column field="unit" header="Unit No"></Column>
                        <Column field="payment_method" header="Payment Method"></Column>
                        <Column field="total_amount" header="Total Amount"></Column>
                        <Column field="added_on" header="Added On"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default TransactionsTab;