import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { inputLoginUserName, inputLoginPassword, updateSpinner } from '../../features/authentication/authenticationReducer';
import { getItem, removeItem } from '../../utils/localStorage';
import { makeRequest } from '../../utils/makeRequest';
import { toastify } from '../../utils/toast';
import { checkEmailAndPasswordURL } from '../../utils/urls';

function Login() {
  const loginUserName = useSelector((state) => state.authenticationReducer.loginUserName);
  const loginPassword = useSelector((state) => state.authenticationReducer.loginPassword);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const HandleLogin = async () => {
    try {
      dispatch(updateSpinner(true));
      if (loginUserName === '') {
        toastify('User Name is required', 'error');
        dispatch(updateSpinner(false));
      } else if (loginPassword === '') {
        toastify('Password is required', 'error');
        dispatch(updateSpinner(false));
      }
      const body = {
        userName: loginUserName,
        password: loginPassword,
        platform: 'App'
      };
      const response = await makeRequest(checkEmailAndPasswordURL, 'POST', body);
      if (response.success) {
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
        // Check if "Remember Me" is checked
        if (rememberMe) {
          // Store username in local storage
          localStorage.setItem('rememberedUser ', JSON.stringify({ userName: loginUserName }));
        }

        dispatch(updateSpinner(false));
        navigate('/code_verification');
      } else {
        toastify(response.error, 'error');
        dispatch(updateSpinner(false));
      }
    } catch (err) {
      toastify(err.message, 'error');
      dispatch(updateSpinner(false));
    }
  };

  const checkUserExistInLocalStorage = async () => {
    const userExist = await getItem('APPUSER');
    if (userExist) {
      navigate('/app/facilities');
    } else {
      removeItem('APPUSER');
      dispatch(inputLoginUserName(''));
      dispatch(inputLoginPassword(''));
    }
  };

  useEffect(() => {
    checkUserExistInLocalStorage();

    // Check for remembered user
    const rememberedUser = localStorage.getItem('rememberedUser ');
    const rememberedMe = localStorage.getItem('rememberMe');

    if (rememberedUser) {
      const { userName } = JSON.parse(rememberedUser);
      dispatch(inputLoginUserName(userName)); // Populate the username input
      if (rememberedMe) {
        setRememberMe(JSON.parse(rememberedMe)); // Set the checkbox state based on local storage
      }
    }
  }, []);

  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v2">
         
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <div className="text-center">
                  <Link to={'#'}>
                    <img src="/assets/images/PayServeLogoFinal.png" alt="img" style={{ width: 300,marginBottom:10 }} /></Link>
                </div>

                <h3 className="text-center f-w-500 mb-3"><b>Login</b></h3>
                <div className="mb-3">
                  <input type="email" className="form-control" id="floatingInput" value={loginUserName} onChange={(e) => {
                    dispatch(inputLoginUserName(e.target.value));
                  }} placeholder="Username" />
                </div>
                <div className="mb-3 position-relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(e) => dispatch(inputLoginPassword(e.target.value))}
                />
                <button
                  type="button"
                  className="btn btn-link position-absolute end-0 top-0 mt-1 me-2"
                  onClick={() => setShowPassword((prev) => !prev)}
                  aria-label="Toggle password visibility"
                >
                  <i className={`fas ${showPassword === false ? 'fa-eye' : 'fa-eye-slash'}`}></i>

                </button>
              </div>
              <div className="d-flex mt-1 justify-content-between align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember Me
                  </label>
                </div>
                <h6 className="text-secondary f-w-400 mb-0">
                  <Link to="/forgot_password">Forgot Password?</Link>
                </h6>
              </div>
                <div className="d-grid mt-4">
                  <button type="button" className="btn btn-primary" onClick={HandleLogin}>Login</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
