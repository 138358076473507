import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { get_all_inspection_settings } from "../../../utils/urls";

const InspectionItemSelector = ({ facilityId, onItemsSelected, onClose }) => {
  const [inspectionItems, setInspectionItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);

  // Fetch all inspection items
  const fetchInspectionItems = async () => {
    if (!facilityId) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Facility ID is required',
        life: 3000
      });
      return;
    }

    try {
      setLoading(true);
      const response = await makeRequest2(
        `${get_all_inspection_settings}/${facilityId}`,
        "GET"
      );
      setLoading(false);

      if (response.success) {
        // Ensure data is an array
        const items = Array.isArray(response.data)
          ? response.data
          : response.data?.data || [];

        // Filter out inactive items
        const activeItems = items.filter(item => item.active !== false);
        setInspectionItems(activeItems);

        // Extract unique categories for filtering
        const uniqueCategories = [...new Set(activeItems.map(item => item.category))];
        setCategories(uniqueCategories.map(category => ({
          label: category,
          value: category
        })));

        if (activeItems.length === 0) {
          toast.current.show({
            severity: 'info', 
            summary: 'No Items', 
            detail: 'No inspection items are available. Please create some from the Inspection Settings tab.',
            life: 5000
          });
        }
      } else {
        console.error("Failed to fetch inspection items:", response.error);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch inspection items',
          life: 3000
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching inspection items:", error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `Error loading inspection items: ${error.message}`,
        life: 3000
      });
    }
  };

  useEffect(() => {
    fetchInspectionItems();
  }, [facilityId]);

  const handleAddSelectedItems = () => {
    if (selectedItems.length === 0) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select at least one item',
        life: 3000
      });
      return;
    }

    // Format the selected items for the handover schema
    const formattedItems = selectedItems.map(item => ({
      name: item.name,
      category: item.category,
      description: item.description || "",
      condition: item.defaultCondition || "Good",
      quantity: item.defaultQuantity || 1,
      serialNumber: "",
      notes: ""
    }));

    onItemsSelected(formattedItems);
    setSelectedItems([]);
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${formattedItems.length} items selected for handover`,
      life: 3000
    });
  };

  // Filter by category
  const filteredItems = selectedCategory
    ? inspectionItems.filter(item => item.category === selectedCategory)
    : inspectionItems;

  // Get severity level for conditions
  const getSeverity = (condition) => {
    switch (condition) {
      case 'Excellent':
        return 'success';
      case 'Good':
        return 'success';
      case 'Fair':
        return 'warning';
      case 'Poor':
        return 'warning';
      case 'Damaged':
        return 'danger';
      case 'Non-functional':
        return 'danger';
      default:
        return 'info';
    }
  };
  
  // Render the default condition badge
  const conditionTemplate = (rowData) => {
    return (
      <Tag 
        value={rowData.defaultCondition || 'Good'} 
        severity={getSeverity(rowData.defaultCondition)}
      />
    );
  };

  // Category template
  const categoryTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.category}
        severity="info"
        style={{ backgroundColor: getCategoryColor(rowData.category) }}
      />
    );
  };

  // Get pseudo-random color based on category name
  const getCategoryColor = (category) => {
    const colors = {
      'Furniture': '#4F6F52',
      'Appliance': '#3F72AF',
      'Electronics': '#533B4D',
      'Fixture': '#98473E',
      'Utility': '#567189',
      'Other': '#7D7463'
    };
    
    return colors[category] || '#9E9E9E';
  };

  // Render quantity template
  const quantityTemplate = (rowData) => {
    return <span className="font-semibold">{rowData.defaultQuantity || 1}</span>;
  };

  // Description template with truncation
  const descriptionTemplate = (rowData) => {
    if (!rowData.description) return <span className="text-gray-400">No description</span>;
    
    const description = rowData.description;
    if (description.length > 50) {
      return (
        <div className="text-truncate" style={{ maxWidth: "250px" }} title={description}>
          {description}
        </div>
      );
    }
    
    return description;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  if (loading && inspectionItems.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" />
        <span className="ms-3 font-medium">Loading inspection items...</span>
      </div>
    );
  }

  // Header with search and filter
  const header = (
    <div className="d-flex flex-wrap justify-content-between align-items-center py-2">
      <div className="position-relative">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search items..."
            className="p-inputtext-sm"
          />
        </span>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Dropdown
          value={selectedCategory}
          options={[{ label: "All Categories", value: null }, ...categories]}
          onChange={(e) => setSelectedCategory(e.value)}
          placeholder="Filter by category"
          className="p-inputtext-sm"
        />
        <Button
          label={`Add Selected (${selectedItems.length})`}
          icon="pi pi-plus"
          onClick={handleAddSelectedItems}
          disabled={selectedItems.length === 0}
          className="p-button-sm p-button-success"
        />
        <Button
          icon="pi pi-refresh"
          className="p-button-sm p-button-outlined p-button-secondary"
          onClick={() => fetchInspectionItems()}
          tooltip="Refresh list"
          disabled={loading}
        />
        <Button
          icon="pi pi-file-excel"
          className="p-button-sm p-button-outlined p-button-success"
          onClick={exportCSV}
          tooltip="Export to CSV"
          disabled={inspectionItems.length === 0}
        />
      </div>
    </div>
  );

  const emptyMessage = (
    <div className="text-center py-5">
      <div className="text-muted">
        <i className="ti ti-clipboard-off mb-3" style={{ fontSize: "2.5rem" }}></i>
        <h5 className="text-secondary">No inspection items found</h5>
        <p className="small">Try adjusting your search or create new inspection items first</p>
        <Button 
          label="Refresh List" 
          icon="pi pi-refresh" 
          className="p-button-sm p-button-outlined mt-2"
          onClick={() => fetchInspectionItems()}
        />
      </div>
    </div>
  );

  return (
    <div className="card p-0 m-0 border-0">
      <Toast ref={toast} />
      
      <DataTable
        ref={dt}
        value={filteredItems}
        selection={selectedItems}
        onSelectionChange={(e) => setSelectedItems(e.value)}
        selectionMode="multiple"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        emptyMessage={emptyMessage}
        header={header}
        dataKey="_id"
        loading={loading}
        responsiveLayout="stack"
        breakpoint="960px"
        globalFilter={globalFilter}
        scrollable
        scrollHeight="calc(80vh - 200px)"
        className="p-datatable-sm p-datatable-gridlines"
        filterDisplay="menu"
        stripedRows
      >
        <Column selectionMode="multiple" style={{ width: '3rem' }} />
        <Column field="name" header="Name" sortable style={{ minWidth: '200px' }} />
        <Column header="Category" body={categoryTemplate} sortable style={{ minWidth: '120px' }} />
        <Column header="Condition" body={conditionTemplate} sortable style={{ minWidth: '120px' }} />
        <Column header="Quantity" body={quantityTemplate} sortable style={{ minWidth: '100px' }} />
        <Column header="Description" body={descriptionTemplate} style={{ minWidth: '250px' }} />
      </DataTable>
    </div>
  );
};

export default InspectionItemSelector;