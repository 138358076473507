import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";
import ConfirmDialog from "../../component/confirmDialog";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";
import { makeRequest2 } from "../../../../utils/makeRequest";
import {
  addLevyType,
  editLevyType,
  getLevyTypes,
  getLevies,
  editLevy,
  deleteLevy,
  disableLevy,
  facilityReminders,
  facilityPenalties
} from "../../../../utils/urls";

const INVOICE_DAY_OPTIONS = [
  { value: "5days", label: "5 days to end of month" },
  { value: "3days", label: "3 days to end of month" },
  { value: "1day", label: "1 day to end of month" },
  { value: "endmonth", label: "End of month" }
];

const DUE_DATE_OPTIONS = [
  { value: "1st", label: "1st of next month" },
  { value: "5th", label: "5th of next month" },
  { value: "10th", label: "10th of next month" }
];

const COLLECTION_FREQUENCY_OPTIONS = [
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Semi-annually", label: "Semi-annually" },
  { value: "Annually", label: "Annually" }
];

const NOTIFICATION_TYPES = [
  { value: "SMS", label: "SMS" },
  { value: "EMAIL", label: "Email" }
];

const OVERDUE_REMINDER_OPTIONS = [
  { value: 1, label: "1 day after due date" },
  { value: 3, label: "3 days after due date" },
  { value: 7, label: "7 days after due date" }
];

const LEVY_APPLICANT_OPTIONS = [
  { value: "home owner", label: "Home Owner" },
  { value: "tenant", label: "Tenant" }
];


function LevyManagement() {
  const navigate = useNavigate();

  // State declarations
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [levies, setLevies] = useState([]);
  const [filteredLevies, setFilteredLevies] = useState([]);
  const [levyType, setLevyType] = useState("");
  const [levyTypes, setLevyTypes] = useState([]);
  const [editingLevyType, setEditingLevyType] = useState(null);
  const [editedLevyTypeName, setEditedLevyTypeName] = useState("");
  const [collectionFrequency, setCollectionFrequency] = useState("");
  const [invoiceDay, setInvoiceDay] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [levyId, setLevyId] = useState(null);
  const [levyName, setLevyName] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedRowData, setSelectedRowData] = useState({});
  const [confirmAction, setConfirmAction] = useState("");
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);
  const [selectedLevyId, setSelectedLevyId] = useState(null);
  const [levyApplicant, setLevyApplicant] = useState("");

  // Reminder states
  const [reminderTime, setReminderTime] = useState("09:00");
  const [reminderInvoiceDate, setReminderInvoiceDate] = useState(true);
  const [reminderDueDate, setReminderDueDate] = useState(false);
  const [overdueEnabled, setOverdueEnabled] = useState(false);
  const [overdueDays, setOverdueDays] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState(["SMS", "EMAIL"]);
  const [reminderMessage, setReminderMessage] = useState("");

  // Penalty states
  const [penaltyName, setPenaltyName] = useState("");
  const [penaltyType, setPenaltyType] = useState("");
  const [penaltyEffectDays, setPenaltyEffectDays] = useState("");
  const [penaltyPercentage, setPenaltyPercentage] = useState("");
  const [penaltyAmount, setPenaltyAmount] = useState("");
  const [penaltyActive, setPenaltyActive] = useState(true);

  // Check for levy types before navigation
  const checkLevyTypesBeforeNavigation = () => {
    if (!levyTypes || levyTypes.length === 0) {
      toastify("Please add at least one levy type before creating a levy", "warn");
      document.getElementById('profile-tab-5').click();
      return;
    }
    navigate("/facility/levy_management/levies/add_levy");
  };

  const handleEditLevyType = async (levyTypeId) => {
    try {
      const facilityId = await getItem('selectedFacilityId');

      const response = await makeRequest2(
        `${editLevyType}/${facilityId}/${levyTypeId}`,
        'PUT',
        { name: editedLevyTypeName }
      );

      if (response.success) {
        toastify("Levy Type updated successfully", 'success');
        setEditingLevyType(null);
        setEditedLevyTypeName("");
        fetchLevyTypes();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };

  const handleInputSearch = (value) => {
    setSearchTerm(value);
    if (!value) {
      setFilteredLevies(levies);
      return;
    }
    const filtered = levies.filter((levy) =>
      Object.values(levy).some((field) =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredLevies(filtered);
  };

  const fetchLevyTypes = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const response = await makeRequest2(getLevyTypes + '/' + facilityId, 'GET', {});

      if (response.success) {
        const levyTypesData = Array.isArray(response.data) ? response.data :
          (response.data?.data ? response.data.data : []);
        setLevyTypes(levyTypesData);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.error('Error fetching levy types:', err);
      toastify(err.message, 'error');
    }
  };

  const LevyTypeSubmit = async () => {
    try {
      if (!levyType.trim()) {
        toastify("Please enter a levy type name", "warning");
        return;
      }

      const facilityId = await getItem("selectedFacilityId");
      const levyTypeData = levyType;

      const response = await makeRequest2(
        addLevyType + "/" + facilityId,
        "POST",
        levyTypeData
      );
      if (response.success) {
        toastify("Levy type added successfully", "success");
        setLevyType("");
        fetchLevyTypes();
        // Switch to levy list tab
        document.getElementById('profile-tab-1').click();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const fetchLevies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      // Fetch levies, reminders and penalties in parallel
      const [leviesResponse, remindersResponse, penaltiesResponse] = await Promise.all([
        makeRequest2(`${getLevies}/${facilityId}`, "GET", {}),
        makeRequest2(`${facilityReminders}/${facilityId}/levy`, "GET", {}),
        makeRequest2(`${facilityPenalties}/${facilityId}/levy`, "GET", {})
      ]);

      // Handle the levies response
      if (!leviesResponse.success) {
        throw new Error(leviesResponse.error || 'Failed to fetch levies');
      }

      // Get the levies data
      const leviesData = leviesResponse.data?.data || leviesResponse.data || [];
      const dataArray = Array.isArray(leviesData) ? leviesData : [];

      // Get reminders and penalties, ensuring we get arrays
      const reminders = Array.isArray(remindersResponse.data?.data) ? remindersResponse.data.data :
        Array.isArray(remindersResponse.data) ? remindersResponse.data : [];

      const penalties = Array.isArray(penaltiesResponse.data?.data) ? penaltiesResponse.data.data :
        Array.isArray(penaltiesResponse.data) ? penaltiesResponse.data : [];

      // Map reminders and penalties to levies
      const leviesWithAssociations = dataArray.map(levy => {
        const associatedReminder = reminders.find(reminder =>
          reminder?.moduleId &&
          reminder.moduleId.toString() === levy._id.toString()
        );

        const associatedPenalty = penalties.find(penalty =>
          penalty?.moduleId &&
          penalty.moduleId.toString() === levy._id.toString()
        );

        return {
          ...levy,
          levyType: typeof levy.levyType === 'object' ? levy.levyType?.name : levy.levyType,
          reminder: associatedReminder || null,
          penalty: associatedPenalty || null
        };
      });

      setLevies(leviesWithAssociations);
      setFilteredLevies(leviesWithAssociations);

    } catch (err) {
      console.error('Error fetching levies:', err);
      toastify(err.message, "error");
      setLevies([]);
      setFilteredLevies([]);
    }
  };

  const actionBodyTemplate = (rowData) => {
    const isDisabled = rowData.disabled;

    return (
      <div className="actions">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
        />
        <Button
          icon={`ti ${isDisabled ? "ti-lock-open" : "ti-lock"}`}
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setSelectedLevyId(rowData._id);
            setConfirmAction(isDisabled ? "enable" : "disable");
            setSelectedDisableStatus(isDisabled);
            setConfirmDialogVisible(true);
          }}
          tooltip={isDisabled ? "Enable" : "Disable"}
        />
      </div>
    );
  };

  const handleEdit = (rowData) => {
    try {
      // Basic levy information
      setLevyId(rowData._id);
      setLevyName(rowData.levyName || "");
      setAmount(rowData.amount?.toString() || "");
      setCollectionFrequency(rowData.collectionFrequency || "");
      setInvoiceDay(rowData.invoiceDay || "");
      setDueDate(rowData.dueDate || "");
      setLevyApplicant(rowData.levyApplicant || "");

      // Extract levy type ID with careful checks
      let levyTypeData;
      if (rowData.levyType) {
        if (typeof rowData.levyType === 'object') {
          levyTypeData = rowData.levyType;
        } else {
          // Find the levy type from the levy types list
          const foundLevyType = levyTypes.find(lt => lt.name === rowData.levyType);
          levyTypeData = foundLevyType;
        }
      }

      if (!levyTypeData) {
        console.error('Could not find valid levy type');
        toastify('Error: Invalid levy type', 'error');
        return;
      }

      // Handle reminder settings
      const reminderData = rowData.reminder;
      if (reminderData) {
        setReminderTime(reminderData.time || "09:00");
        setReminderInvoiceDate(reminderData.remindOn?.invoiceDate ?? true);
        setReminderDueDate(reminderData.remindOn?.dueDate ?? false);
        setOverdueEnabled(reminderData.remindOn?.afterOverdue?.enabled ?? false);

        // Ensure overdueDays is always an array
        const days = reminderData.remindOn?.afterOverdue?.days || [];
        setOverdueDays(Array.isArray(days) ? days : []);

        // Ensure notificationTypes is always an array
        const types = reminderData.notificationTypes || [];
        setNotificationTypes(Array.isArray(types) ? types : ["SMS", "EMAIL"]);

        setReminderMessage(reminderData.message || "");
      } else {
        // Set default reminder values
        setReminderTime("09:00");
        setReminderInvoiceDate(true);
        setReminderDueDate(false);
        setOverdueEnabled(false);
        setOverdueDays([]);
        setNotificationTypes(["SMS", "EMAIL"]);
        setReminderMessage("");
      }

      // Handle penalty settings
      const penaltyData = rowData.penalty;
      if (penaltyData) {
        // Make sure we're setting string values for form fields
        setPenaltyName(penaltyData.name || "");
        setPenaltyType(penaltyData.type || "");

        // For numeric fields, ensure we're setting them as strings for form inputs
        setPenaltyEffectDays(penaltyData.effectDays?.toString() || "");

        // Handle percentage based on penalty type
        if (penaltyData.type === 'percentage') {
          setPenaltyPercentage(penaltyData.percentage?.toString() || "");
          setPenaltyAmount(""); // Clear the other field
        }
        // Handle fixed amount based on penalty type
        else if (penaltyData.type === 'fixed') {
          setPenaltyAmount(penaltyData.amount?.toString() || "");
          setPenaltyPercentage(""); // Clear the other field
        }
        // If no type is specified, clear both fields
        else {
          setPenaltyPercentage("");
          setPenaltyAmount("");
        }

        // Set the active state
        setPenaltyActive(penaltyData.isActive !== false); // Default to true if not specified
      } else {
        // Set default penalty values
        setPenaltyName("");
        setPenaltyType("");
        setPenaltyEffectDays("");
        setPenaltyPercentage("");
        setPenaltyAmount("");
        setPenaltyActive(true);
      }

      // Store complete row data
      setSelectedRowData({
        ...rowData,
        levyType: levyTypeData, // Store the complete levy type object
        reminder: reminderData || null,
        penalty: penaltyData || null
      });

      setEditDialogVisible(true);
    } catch (error) {
      console.error('Error in handleEdit:', error);
      toastify('Error preparing levy data for editing', 'error');
    }
  };

  // Add or update these validation functions
  const validateNumericField = (value, fieldName) => {
    if (value === "") return true; // Allow empty fields
    const num = parseFloat(value);
    if (isNaN(num)) {
      toastify(`${fieldName} must be a valid number`, "error");
      return false;
    }
    if (num < 0) {
      toastify(`${fieldName} cannot be negative`, "error");
      return false;
    }
    return true;
  };

  const validatePercentage = (value) => {
    if (value === "") return true;
    const num = parseFloat(value);
    if (isNaN(num) || num < 0 || num > 100) {
      toastify("Percentage must be between 0 and 100", "error");
      return false;
    }
    return true;
  };


  const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);
    if (confirmAction === "enable" || confirmAction === "disable") {
      await handleDisable(selectedLevyId, selectedDisableStatus);
    }
  };

  const HandleEditSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      const validationError = validateLevyForm();
      if (validationError) {
        toastify(validationError, "error");
        return;
      }

      // Construct reminder document
      const reminderPayload = {
        name: `${levyName} Reminder`,
        type: 'standard',
        module: 'levy',
        moduleId: levyId, // This is the specific levy's ID
        remindOn: {
          invoiceDate: reminderInvoiceDate,
          dueDate: reminderDueDate,
          afterOverdue: {
            enabled: overdueEnabled,
            days: overdueDays
          }
        },
        time: reminderTime || '09:00',
        isActive: true,
        processed: false,
        notificationTypes: notificationTypes,
        message: reminderMessage || '',
        facilityId: facilityId,
        timezone: "UTC"
      };

      // Construct penalty document
      const penaltyPayload = penaltyType ? {
        name: penaltyName,
        type: penaltyType,
        effectDays: penaltyEffectDays ? parseInt(penaltyEffectDays) : 0,
        percentage: penaltyType === 'percentage' ? (penaltyPercentage ? parseFloat(penaltyPercentage) : 0) : null,
        amount: penaltyType === 'fixed' ? (penaltyAmount ? parseFloat(penaltyAmount) : 0) : null,
        module: 'levy',
        moduleId: levyId,
        isActive: penaltyActive,
        facilityId: facilityId
      } : null;

      const updatedData = {
        levyName,
        levyType: selectedRowData.levyType._id, // Just the levy type ID
        amount: parseFloat(amount),
        collectionFrequency,
        invoiceDay,
        dueDate,
        levyApplicant, // Use the state value directly from the form input
        reminder: reminderPayload,
        penalty: penaltyPayload,
        reminderId: selectedRowData.reminder?._id,
        penaltyId: selectedRowData.penalty?._id
      };

      console.log("Sending updated data:", updatedData); // For debugging

      const response = await makeRequest2(
        `${editLevy}/${facilityId}/${levyId}`,
        "PUT",
        updatedData
      );

      if (response.success) {
        toastify("Levy edited successfully", "success");
        handleClear();
        setEditDialogVisible(false);
        fetchLevies();
      } else {
        throw new Error(response.error || 'Failed to update levy');
      }
    } catch (err) {
      console.error('Error in HandleEditSubmit:', err);
      toastify(err.message, "error");
    }
  };



  const validateLevyForm = () => {
    if (!levyName.trim()) return "Levy name is required";
    if (!amount || parseFloat(amount) <= 0) return "Valid amount is required";
    if (!collectionFrequency) return "Collection frequency is required";
    if (!invoiceDay) return "Invoice day is required";
    if (!dueDate) return "Due date is required";
    if (!levyApplicant) return "Levy applicant is required";

    if (penaltyType) {
      if (!penaltyName) return "Penalty name is required";

      const effectDaysValue = parseInt(penaltyEffectDays);
      if (isNaN(effectDaysValue) || effectDaysValue < 0) return "Valid effect days are required";

      if (penaltyType === 'percentage') {
        const percentageValue = parseFloat(penaltyPercentage);
        if (isNaN(percentageValue) || percentageValue <= 0 || percentageValue > 100)
          return "Valid penalty percentage is required (1-100)";
      } else if (penaltyType === 'fixed') {
        const amountValue = parseFloat(penaltyAmount);
        if (isNaN(amountValue) || amountValue <= 0)
          return "Valid penalty amount is required";
      }
    }

    return null;
  };


  const handleDisable = async (levyId, currentStatus) => {
    const action = currentStatus ? "enable" : "disable";

    try {
      const facilityId = await getItem("selectedFacilityId");
      const newDisabledStatus = !currentStatus;

      const response = await makeRequest2(
        `${disableLevy}/${facilityId}/${levyId}`,
        "POST",
        { disabled: newDisabledStatus }
      );

      if (response.success) {
        toastify(`Levy ${action}d successfully`, "success");
        fetchLevies();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleClear = () => {
    setLevyName("");
    setAmount("");
    setCollectionFrequency("");
    setInvoiceDay("");
    setDueDate("");
    setLevyApplicant("");

    // Clear reminder settings
    setReminderTime("09:00");
    setReminderInvoiceDate(true);
    setReminderDueDate(false);
    setOverdueEnabled(false);
    setOverdueDays([]);
    setNotificationTypes(["SMS", "EMAIL"]);
    setReminderMessage("");

    // Clear penalty settings
    setPenaltyName("");
    setPenaltyType("");
    setPenaltyEffectDays("");
    setPenaltyPercentage("");
    setPenaltyAmount("");
    setPenaltyActive(true);
  };
  const statusTemplate = (rowData) => {
    const isDisabled = rowData.disabled;
    return (
      <span
        style={{
          fontWeight: "bold",
          color: isDisabled ? "#ff4d4d" : "#4caf50",
          padding: "5px 10px",
          borderRadius: "15px",
          backgroundColor: isDisabled ? "#ffebeb" : "#e8f5e9",
        }}
      >
        {isDisabled ? "Disabled" : "Enabled"}
      </span>
    );
  };

  useEffect(() => {
    fetchLevyTypes();
    fetchLevies();
  }, []);

  return (
    <Layout>
      {/* Header Section */}
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={""}>Levy Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="card">
        <div className="card-body py-0">
          <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="profile-tab-1"
                data-bs-toggle="tab"
                href="#profile-1"
                role="tab"
                aria-selected="true"
              >
                <i className="ti ti-chart-pie me-2"></i>Levy List
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-5"
                data-bs-toggle="tab"
                href="#profile-5"
                role="tab"
                aria-selected="true"
              >
                <i className="ti ti-book me-2"></i>Levy Types
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {/* Levy List Tab */}
        <div
          className="tab-pane show active"
          id="profile-1"
          role="tabpanel"
          aria-labelledby="profile-tab-1"
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Levies</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                      <div className="card">
                        <div className="card-body position-relative">
                          <div className="row">
                            <div className="col-md-3 col-xs-12 mb-3">
                              <input
                                className="form-control"
                                placeholder="Search here"
                                value={searchTerm}
                                onChange={(e) => handleInputSearch(e.target.value)}
                              />
                            </div>
                            <div className="col-md-9 col-xs-12 mb-3">
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary"
                                  onClick={checkLevyTypesBeforeNavigation}
                                >
                                  Add Levy
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="dt-responsive table-responsive">
                                <DataTable
                                  value={filteredLevies}
                                  emptyMessage="No levies found."
                                  sortMode="multiple"
                                  paginator
                                  rows={5}
                                  stripedRows
                                  tableStyle={{ minWidth: "50rem" }}
                                  scrollable
                                >
                                  <Column field="levyName" header="Name" />
                                  <Column field="levyType" header="Levy Type" />
                                  <Column field="amount" header="Amount" />
                                  <Column field="levyApplicant" header="Applicant" />
                                  <Column field="collectionFrequency" header="Frequency" />
                                  <Column field="invoiceDay" header="Invoice Day" />
                                  <Column field="dueDate" header="Due Date" />
                                  <Column body={statusTemplate} header="Status" />
                                  <Column body={actionBodyTemplate} header="Actions" />
                                </DataTable>
                                <ConfirmDialog
                                  visible={isConfirmDialogVisible}
                                  onHide={() => setConfirmDialogVisible(false)}
                                  message={`Are you sure you want to ${confirmAction} this Levy?`}
                                  onConfirm={handleConfirmAction}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Levy Types Tab */}
      <div
        className="tab-pane fade"
        id="profile-5"
        role="tabpanel"
        aria-labelledby="profile-tab-5"
      >
        <div className="card">
          <div className="card-header">
            <h5>Levy Types</h5>

          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="setting-1" role="tabpanel">
              <div className="row p-10 mt-3">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="mt-2">
                        <label>Add New Levy Type:</label>
                        <div style={{
                          backgroundColor: '#f0fdf4',
                          padding: '1rem',
                          fontSize: '0.875rem',
                          fontStyle: 'italic',
                          color: '#4b5563',
                          borderRadius: '0.5rem'
                        }}>
                          Levy types help categorize different kinds of levies (e.g., Service Charge, Maintenance Fee, Security Fee).
                          These categories must be set up before creating specific levies.
                        </div>
                        <input
                          className="form-control mt-2"
                          type="text"
                          value={levyType}
                          placeholder="Enter levy type name"
                          onChange={(e) => {
                            setLevyType(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary"
                          onClick={LevyTypeSubmit}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h6>Existing Levy Types</h6>
                    </div>
                    <div className="card-body">
                      <ul className="list-group">
                        {levyTypes && levyTypes.length > 0 ? (
                          levyTypes.map((levyType, index) => (
                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                              {editingLevyType === levyType._id ? (
                                <div className="d-flex w-100">
                                  <input
                                    className="form-control me-2"
                                    type="text"
                                    value={editedLevyTypeName}
                                    onChange={(e) => setEditedLevyTypeName(e.target.value)}
                                  />
                                  <button
                                    className="btn btn-outline-secondary mx-1"
                                    onClick={() => {
                                      setEditingLevyType(null);
                                      setEditedLevyTypeName("");
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleEditLevyType(levyType._id)}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <span>{index + 1}. {levyType.name}</span>
                                  <button
                                    className="btn btn-link p-0"
                                    onClick={() => {
                                      setEditingLevyType(levyType._id);
                                      setEditedLevyTypeName(levyType.name);
                                    }}
                                  >
                                    <i className="ti ti-edit f-18 text-primary"></i>
                                  </button>
                                </>
                              )}
                            </li>
                          ))
                        ) : (
                          <li className="list-group-item text-center">
                            <i className="ti ti-info-circle me-2"></i>
                            No levy types found. Add your first levy type to get started.
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Levy Dialog */}
      <Dialog
        header="Edit Levy"
        style={{ width: "50vw" }}
        visible={editDialogVisible}
        onHide={() => {
          if (!editDialogVisible) return;
          setEditDialogVisible(false);
          handleClear();
        }}
      >
      
          <div className="col-md-12 mt-3">
            {/* Basic Levy Information */}
            <div className="mb-4">
              <label className="form-label">Name<span className="text-danger">*</span></label>
              <input
                className="form-control"
                type="text"
                placeholder="Levy Name"
                value={levyName}
                onChange={(e) => setLevyName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="form-label">Amount<span className="text-danger">*</span></label>
              <input
                className="form-control"
                type="number"
                placeholder="Levy Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">
                Levy Applicant<span className="text-danger">*</span> <em>(who pays)</em>
              </label>
              <select
                className="form-control"
                value={levyApplicant}
                onChange={(e) => setLevyApplicant(e.target.value)}
              >
                <option value="">Select</option>
                {LEVY_APPLICANT_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="form-label">Collection Frequency<span className="text-danger">*</span></label>
              <select
                className="form-control"
                value={collectionFrequency}
                onChange={(e) => setCollectionFrequency(e.target.value)}
              >
                <option value="">Select</option>
                {COLLECTION_FREQUENCY_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="form-label">Invoice Day<span className="text-danger">*</span></label>
              <select
                className="form-control"
                value={invoiceDay}
                onChange={(e) => setInvoiceDay(e.target.value)}
              >
                <option value="">Select</option>
                {INVOICE_DAY_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="form-label">Due Date<span className="text-danger">*</span></label>
              <select
                className="form-control"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              >
                <option value="">Select</option>
                {DUE_DATE_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Reminder Settings Section */}
            <div className="col-12">
              <hr className="my-4" />
              <h6 className="mb-4">Reminder Settings</h6>
            </div>

            <div className="mb-4">
              <label className="form-label">Reminder Time</label>
              <input
                type="time"
                className="form-control"
                value={reminderTime}
                onChange={(e) => setReminderTime(e.target.value)}
              />
              <small className="text-muted">Default: 9:00 AM</small>
            </div>

            <div className="mb-4">
              <label className="form-label">Send Reminders On</label>
              <div className="d-flex flex-column gap-2">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="invoiceDate"
                    checked={reminderInvoiceDate}
                    onChange={(e) => setReminderInvoiceDate(e.target.checked)}
                  />
                  <label className="form-check-label ms-2" htmlFor="invoiceDate">
                    Invoice Generation Date
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="dueDate"
                    checked={reminderDueDate}
                    onChange={(e) => setReminderDueDate(e.target.checked)}
                  />
                  <label className="form-check-label ms-2" htmlFor="dueDate">
                    Due Date
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="form-label">Notification Methods</label>
              <div className="d-flex gap-3">
                {NOTIFICATION_TYPES.map(type => (
                  <div key={type.value} className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`notification-${type.value}`}
                      checked={notificationTypes.includes(type.value)}
                      onChange={() => {
                        setNotificationTypes(prev =>
                          prev.includes(type.value)
                            ? prev.filter(t => t !== type.value)
                            : [...prev, type.value]
                        );
                      }}
                    />
                    <label className="form-check-label ms-2" htmlFor={`notification-${type.value}`}>
                      {type.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-4">
              <label className="form-label">Overdue Reminder</label>
              <div className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="overdueEnabled"
                  checked={overdueEnabled}
                  onChange={(e) => setOverdueEnabled(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="overdueEnabled">
                  Enable Overdue Reminder
                </label>
              </div>

              {overdueEnabled && (
                <select
                  className="form-control mt-2"
                  value={overdueDays[0] || ""}
                  onChange={(e) => {
                    const value = e.target.value ? [parseInt(e.target.value)] : [];
                    setOverdueDays(value);
                  }}
                >
                  <option value="">Select days after due date</option>
                  {OVERDUE_REMINDER_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            </div>

            {/* Penalty Settings Section */}
            <div className="col-12">
              <hr className="my-4" />
              <h6 className="mb-4">Penalty Settings</h6>
            </div>

            <div className="mb-4">
              <label className="form-label">Penalty Name</label>
              <input
                type="text"
                className="form-control"
                value={penaltyName}
                onChange={(e) => setPenaltyName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="form-label">Penalty Type</label>
              <select
                className="form-control"
                value={penaltyType}
                onChange={(e) => setPenaltyType(e.target.value)}
              >
                <option value="">Select</option>
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed Amount</option>
              </select>
            </div>

            {/* Replace these input fields in your Dialog component */}

            {penaltyType === 'percentage' && (
              <div className="mb-4">
                <label className="form-label">Percentage</label>
                <input
                  type="number"
                  className="form-control"
                  value={penaltyPercentage}
                  onChange={(e) => setPenaltyPercentage(e.target.value)} // Use e.target.value directly, not parseFloat
                  min="0"
                  max="100"
                  step="0.01"
                />
              </div>
            )}

            {penaltyType === 'fixed' && (
              <div className="mb-4">
                <label className="form-label">Fixed Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={penaltyAmount}
                  onChange={(e) => setPenaltyAmount(e.target.value)} // Use e.target.value directly, not parseFloat
                  min="0"
                  step="0.01"
                />
              </div>
            )}

            <div className="mb-4">
              <label className="form-label">Effect Days</label>
              <input
                type="number"
                className="form-control"
                value={penaltyEffectDays}
                onChange={(e) => setPenaltyEffectDays(e.target.value)} // Use e.target.value directly, not parseInt
                min="1"
              />
              <small className="text-muted">Number of days after which the penalty takes effect</small>
            </div>

            <div className="mb-4">
              <label className="form-label">Status</label>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="penaltyActive"
                  checked={penaltyActive}
                  onChange={(e) => setPenaltyActive(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="penaltyActive">
                  Active
                </label>
              </div>
            </div>

            <div className="d-flex justify-content-end gap-2 mt-4">
              <button
                className="btn btn-outline-secondary"
                onClick={handleClear}
              >
                Clear
              </button>
              <button
                className="btn btn-primary"
                onClick={HandleEditSubmit}
              >
                Save Changes
              </button>
            </div>
          </div>
      </Dialog>
    </Layout >
  );
}

export default LevyManagement;