import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { makeRequest2 } from '../../../../../../utils/makeRequest';
import { toastify } from '../../../../../../utils/toast';
import { useFacilityStore } from '../../../../../../app/z-store/store';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { analog_base_url } from "../../../../../../utils/urls";

const AnalogMetersManagementTab = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [meters, setMeters] = useState([]);
    const [loading, setLoading] = useState(true);
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);

    // Edit Modal
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedMeter, setSelectedMeter] = useState(null);
    const [meterFormData, setMeterFormData] = useState({
        initialReading: 0,
        previousReading: 0,
        currentReading: 0,
        customerId: '',
        unitId: ''
    });

    // Import Modal
    const [showImportModal, setShowImportModal] = useState(false);
    const [importFile, setImportFile] = useState(null);

    // States for customer and unit options (used in the edit modal)
    const [customers, setCustomers] = useState([]);
    const [units, setUnits] = useState([]);

    // Helper function to format customer name
    const formatCustomerName = (customer) => {
        if (customer.firstName && customer.lastName) {
            return `${customer.firstName} ${customer.lastName}`;
        } else if (customer.fullName) {
            return customer.fullName;
        } else if (customer.name) {
            return customer.name;
        }
        return 'Unnamed Customer';
    };

    useEffect(() => {
        if (selectedFacilityId) {
            fetchMeters();
            fetchCustomersAndUnits();
        }
    }, [selectedFacilityId]);

    const fetchMeters = async () => {
        try {
            setLoading(true);
            const response = await makeRequest2(
                `${analog_base_url}/${selectedFacilityId}`,
                'GET'
            );

            if (response.success) {
                const metersData = response.data?.meters || [];
                setMeters(metersData);
            } else {
                toastify('Failed to fetch analog meters', 'error');
            }
        } catch (error) {
            console.error('Error fetching meters:', error);
            toastify('Error loading meters', 'error');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomersAndUnits = async () => {
        try {
            const [customersRes, unitsRes] = await Promise.all([
                makeRequest2(
                    `/api/app/customer_management/get_customers/${selectedFacilityId}`,
                    'GET'
                ),
                makeRequest2(
                    `/api/app/unit_management/get_facility_units/${selectedFacilityId}`,
                    'GET'
                )
            ]);

            if (customersRes.success) {
                const customersData = customersRes.data?.customers || [];
                setCustomers(customersData);
            } else {
                toastify('Failed to fetch customers', 'error');
            }

            if (unitsRes.success) {
                const unitsData = unitsRes.data?.units || [];
                setUnits(unitsData);
            } else {
                toastify('Failed to fetch units', 'error');
            }
        } catch (error) {
            console.error('Error fetching customers/units:', error);
            toastify('Error fetching customers and units', 'error');
        }
    };
    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            'maintenance': 'bg-info',
            'open': 'bg-success',
            'closed': 'bg-danger',
            'faulty': 'bg-danger'
        };
        return (
            <span className={`badge ${statusColors[rowData.status] || 'bg-secondary'}`}>
                {rowData.status || 'unknown'}
            </span>
        );
    };

    const handleMeterInputChange = (e) => {
        const { name, value } = e.target;
        setMeterFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // ----- VIEW METER LOGIC -----
    const handleViewMeter = (meter) => {
        // Pass the meter ID to the View Analog Meter Dashboard
        navigate('/facility/utility_management/meter_management/water_meters/ViewAnalogMeter', {
            state: { meterId: meter._id }
        });
    };

    const handleMeterSubmit = async (e) => {
        e.preventDefault();
        if (!selectedMeter) return; // safety check

        setLoading(true);
        try {
            const response = await makeRequest2(
                `${analog_base_url}/${selectedFacilityId}/${selectedMeter._id}`,
                'PUT',
                meterFormData
            );

            if (response.success) {
                toastify('Meter updated successfully', 'success');
                fetchMeters();
                setShowEditModal(false);
            } else {
                toastify('Failed to update meter', 'error');
            }
        } catch (error) {
            console.error('Error updating meter:', error);
            toastify('Error updating meter', 'error');
        } finally {
            setLoading(false);
        }
    };

    // ----- TABLE ACTIONS -----
    const actionBodyTemplate = (rowData) => (
        <div>
            <Button
                icon="ti ti-eye"
                className="p-button-rounded p-button-eye p-mr-2"
                onClick={() => handleViewMeter(rowData)}
                tooltip="View Meter"
            />
        </div>
    );

    const formatReading = (value) => {
        return value ? `${Number(value).toFixed(2)} m³` : '0.00 m³';
    };

    // ----- SEARCH FILTER -----
    const filteredMeters = meters.filter((meter) =>
        Object.values(meter).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // ----- IMPORT MODAL LOGIC -----
    const handleImportModalOpen = () => {
        setShowImportModal(true);
    };

    const handleImportModalClose = () => {
        setShowImportModal(false);
        setImportFile(null);
    };

    // ----- GENERATE AND DOWNLOAD FACILITY-SPECIFIC TEMPLATE -----
    const generateCSVTemplate = () => {
        // Get current year and month
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const yearMonth = `${year}-${month}`;

        // CSV header
        let csvContent = "serial_number,unit_name,year_month,meter_reading\n";

        // Add each meter's data
        meters.forEach(meter => {
            const unitName = meter.UnitInfo ? (meter.UnitInfo.name || meter.UnitInfo.unitNumber) : 'N/A';
            const meterNumber = meter.meterNumber || 'Unknown';

            // Add empty meter_reading field to be filled by user
            csvContent += `${meterNumber},"${unitName}",${yearMonth},\n`;
        });

        return csvContent;
    };

    const handleTemplateDownload = () => {
        const csvContent = generateCSVTemplate();
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `current_meter_readings_template.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setImportFile(e.target.files[0]);
        }
    };

    const handleImportSubmit = async (e) => {
        e.preventDefault();

        if (!importFile) {
            toastify('Please select a CSV file', 'error');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', importFile); // Make sure field name matches multer setup

            // No need to modify makeRequest2 as it already detects FormData
            const response = await makeRequest2(
                `/api/app/analog-meters/import/${selectedFacilityId}`,
                'POST',
                formData
            );

            if (response.success) {
                toastify('Meter readings imported successfully', 'success');
                fetchMeters(); // Refresh the meter list
                setShowImportModal(false);
                setImportFile(null);
            } else {
                toastify(response.error || 'Failed to import meter readings', 'error');
            }
        } catch (error) {
            console.error('Error importing CSV:', error);
            toastify('Error importing CSV', 'error');
        }
    };

    // If no facility selected, show a prompt
    if (!selectedFacilityId) {
        return (
            <div className="card">
                <div className="card-body text-center">
                    Please select a facility first
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-body">
                {/* Top Right Buttons */}
                <div style={{ float: 'right' }}>
                    <button
                        className="btn btn-primary"
                        onClick={() =>
                            navigate('/facility/utility_management/meter_management/water_meters/addAnalogMeter')
                        }
                    >
                        Add New Meter
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-primary"
                        onClick={handleImportModalOpen}
                    >
                        Import Meter Readings
                    </button>
                </div>

                {/* Search Input */}
                <div className="col-md-3 col-xs-12 mb-3">
                    <input
                        className="form-control"
                        placeholder="Search meters..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {/* Data Table */}
                <div className="dt-responsive table-responsive">
                    <DataTable
                        value={filteredMeters}
                        emptyMessage="No analog meters found."
                        sortMode="multiple"
                        paginator
                        rows={5}
                        stripedRows
                        loading={loading}
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="meterNumber" header="Meter S/N" sortable />
                        <Column
                            field="UnitInfo"
                            header="Unit"
                            sortable
                            body={(rowData) =>
                                rowData.UnitInfo
                                    ? rowData.UnitInfo.name || rowData.UnitInfo.unitNumber
                                    : 'N/A'
                            }
                        />
                        <Column
                            field="CustomerInfo"
                            header="Customer"
                            sortable
                            body={(rowData) =>
                                rowData.CustomerInfo
                                    ? formatCustomerName(rowData.CustomerInfo)
                                    : 'N/A'
                            }
                        />
                        <Column
                            field="initialReading"
                            header="Initial Reading"
                            sortable
                            body={(rowData) => formatReading(rowData.initialReading)}
                        />
                        <Column
                            field="previousReading"
                            header="Previous Reading"
                            sortable
                            body={(rowData) => formatReading(rowData.previousReading)}
                        />
                        <Column
                            field="currentReading"
                            header="Current Reading"
                            sortable
                            body={(rowData) => formatReading(rowData.currentReading)}
                        />
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable />
                        <Column
                            header="Actions"
                            body={actionBodyTemplate}
                            style={{ width: '10rem' }}
                        />
                    </DataTable>
                </div>

                {/* Confirm Dialog (for deletion) */}
                <ConfirmDialog />

                {/* Edit Meter Modal */}
                {showEditModal && (
                    <div
                        className="modal d-block"
                        tabIndex="-1"
                        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Meter</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowEditModal(false)}
                                    ></button>
                                </div>
                                <form onSubmit={handleMeterSubmit}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Initial Reading (m³)
                                            </label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                className="form-control"
                                                name="initialReading"
                                                value={meterFormData.initialReading}
                                                onChange={handleMeterInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Current Reading (m³)
                                            </label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                className="form-control"
                                                name="currentReading"
                                                value={meterFormData.currentReading}
                                                onChange={handleMeterInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Customer</label>
                                            <select
                                                className="form-control"
                                                name="customerId"
                                                value={meterFormData.customerId}
                                                onChange={handleMeterInputChange}
                                            >
                                                <option value="">Select Customer</option>
                                                {customers.map((customer) => (
                                                    <option key={customer._id} value={customer._id}>
                                                        {formatCustomerName(customer)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Unit</label>
                                            <select
                                                className="form-control"
                                                name="unitId"
                                                value={meterFormData.unitId}
                                                onChange={handleMeterInputChange}
                                            >
                                                <option value="">Select Unit</option>
                                                {units.map((unit) => (
                                                    <option key={unit._id} value={unit._id}>
                                                        {unit.unitNumber || unit.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowEditModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <span className="spinner-border spinner-border-sm me-1"></span>
                                            ) : null}
                                            Update Meter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}

                {/* Import Meter Readings Modal */}
                {showImportModal && (
                    <div
                        className="modal d-block"
                        tabIndex="-1"
                        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Import Current Meter Readings</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleImportModalClose}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        <button
                                            type="button"
                                            className="btn btn-link p-0"
                                            onClick={handleTemplateDownload}
                                        >
                                            Download facility meters template (.csv)
                                        </button>
                                    </p>
                                    <div className="alert alert-info">
                                        <strong>Template Info</strong>
                                        <ul>
                                            <li>The CSV contains all the facility's current meters</li>
                                            <li>Only fill in the empty <strong>meter_reading</strong> column</li>
                                            <li>Year-month is pre-filled with the current month ({new Date().getFullYear()}-{String(new Date().getMonth() + 1).padStart(2, '0')})</li>
                                        </ul>
                                    </div>

                                    <form onSubmit={handleImportSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Upload Completed CSV File</label>
                                            <input
                                                type="file"
                                                accept=".csv"
                                                className="form-control"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="text-end">
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary me-2"
                                                onClick={handleImportModalClose}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={loading || !importFile}
                                            >
                                                {loading ? (
                                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                                ) : null}
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AnalogMetersManagementTab;