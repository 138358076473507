// components/lease/penalties/EditPenalty.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { 
  get_lease_penalty,
  update_lease_penalty,
  penalty_status 
} from "../../../../utils/urls";

const EditPenalty = () => {
  const navigate = useNavigate();
  const { penaltyId } = useParams();

  const [loading, setLoading] = useState(true);
  const [penaltyData, setPenaltyData] = useState({
    name: "",
    type: "percentage",
    effectDays: "1",
    percentage: "",
    amount: "",
    isActive: true
  });

  useEffect(() => {
    fetchPenaltyData();
  }, [penaltyId]);

  const fetchPenaltyData = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${get_lease_penalty}/${facilityId}/${penaltyId}`,
        "GET"
      );

      if (response.success) {
        const penalty = response.data;
        setPenaltyData({
          name: penalty.name,
          type: penalty.type,
          effectDays: penalty.effectDays.toString(),
          percentage: penalty.type === 'percentage' ? penalty.percentage?.toString() : '',
          amount: penalty.type === 'fixed' ? penalty.amount?.toString() : '',
          isActive: penalty.isActive
        });
      } else {
        toastify("Failed to fetch penalty details", "error");
        navigate(-1);
      }
    } catch (error) {
      //console.error("Error fetching penalty:", error);
      toastify(error.message || "An unexpected error occurred", "error");
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPenaltyData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!penaltyData.name.trim()) {
      toastify("Penalty name is required", "error");
      return false;
    }

    if (penaltyData.type === "percentage") {
      if (!penaltyData.percentage || 
          isNaN(penaltyData.percentage) || 
          Number(penaltyData.percentage) < 0 || 
          Number(penaltyData.percentage) > 100) {
        toastify("Please enter a valid percentage between 0 and 100", "error");
        return false;
      }
    }

    if (penaltyData.type === "fixed") {
      if (!penaltyData.amount || 
          isNaN(penaltyData.amount) || 
          Number(penaltyData.amount) <= 0) {
        toastify("Please enter a valid positive amount", "error");
        return false;
      }
    }

    if (![1, 3, 7].includes(Number(penaltyData.effectDays))) {
      toastify("Effect days must be either 1, 3, or 7", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const facilityId = await getItem("selectedFacilityId");

      const formData = {
        ...penaltyData,
        effectDays: Number(penaltyData.effectDays),
        percentage: penaltyData.type === "percentage" ? Number(penaltyData.percentage) : undefined,
        amount: penaltyData.type === "fixed" ? Number(penaltyData.amount) : undefined,
      };

      const response = await makeRequest2(
        `${update_lease_penalty}/${facilityId}/${penaltyId}`,
        "PUT",
        formData
      );

      if (response.success) {
        toastify("Penalty updated successfully", "success");
        navigate(-1);
      } else {
        toastify(response.error || "Failed to update penalty", "error");
      }
    } catch (error) {
      //console.error("Error updating penalty:", error);
      toastify(error.message || "An unexpected error occurred", "error");
    }
  };

  const handleToggleStatus = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      
      const response = await makeRequest2(
        `${penalty_status}/${facilityId}/${penaltyId}`,
        "PATCH",
        { isActive: !penaltyData.isActive }
      );

      if (response.success) {
        setPenaltyData(prev => ({
          ...prev,
          isActive: !prev.isActive
        }));
        toastify(`Penalty ${!penaltyData.isActive ? 'activated' : 'deactivated'} successfully`, "success");
      } else {
        toastify(response.error || "Failed to update penalty status", "error");
      }
    } catch (error) {
      //console.error("Error toggling penalty status:", error);
      toastify(error.message || "An unexpected error occurred", "error");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/lease_management/penalties">
                    Lease Penalties
                  </Link>
                </li>
                <li className="breadcrumb-item">Edit Penalty</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Edit Penalty</h5>
              <Button
                type="button"
                className={`btn ${penaltyData.isActive ? 'btn-danger' : 'btn-success'}`}
                onClick={handleToggleStatus}
              >
                {penaltyData.isActive ? 'Deactivate' : 'Activate'} Penalty
              </Button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <label className="form-label">Penalty Name</label>
                  <InputText
                    className="form-control"
                    name="name"
                    value={penaltyData.name}
                    onChange={handleInputChange}
                    placeholder="Enter penalty name"
                  />
                </div>

                <div className="col-sm-6">
                  <label className="form-label">Penalty Type</label>
                  <select
                    className="form-control"
                    name="type"
                    value={penaltyData.type}
                    onChange={handleInputChange}
                  >
                    <option value="percentage">Percentage</option>
                    <option value="fixed">Fixed Amount</option>
                  </select>
                </div>

                {penaltyData.type === "percentage" ? (
                  <div className="col-sm-6 mt-3">
                    <label className="form-label">Percentage (%)</label>
                    <InputText
                      type="number"
                      className="form-control"
                      name="percentage"
                      value={penaltyData.percentage}
                      onChange={handleInputChange}
                      placeholder="Enter percentage"
                      min="0"
                      max="100"
                    />
                  </div>
                ) : (
                  <div className="col-sm-6 mt-3">
                    <label className="form-label">Fixed Amount</label>
                    <InputText
                      type="number"
                      className="form-control"
                      name="amount"
                      value={penaltyData.amount}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                      min="0"
                    />
                  </div>
                )}

                <div className="col-sm-6 mt-3">
                  <label className="form-label">Effect Days</label>
                  <select
                    className="form-control"
                    name="effectDays"
                    value={penaltyData.effectDays}
                    onChange={handleInputChange}
                  >
                    <option value="1">1 Day</option>
                    <option value="3">3 Days</option>
                    <option value="7">7 Days</option>
                  </select>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Update Penalty
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditPenalty;