import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { jsPDF } from "jspdf";
import { getContracts, fetchFacilityPaymentDetails, getCustomersURL } from "../../../../utils/urls";

import EditContract from './edit_contract';
import DisableContract from './disable_contract';

const ContractManagement = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [filterType, setFilterType] = useState("all");
  const [loading, setLoading] = useState(false);

  // Edit state
  const [contractId, setContractId] = useState(null);
  const [contractName, setContractName] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [currency, setCurrency] = useState("");
  const [selectedContract, setSelectedContract] = useState(null);
  const [editDialogVisible, setEditDialogVisible] = useState(false);

  // Disable/Enable state
  const [confirmAction, setConfirmAction] = useState("");
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);

  const isExpired = (endDate) => {
    return new Date(endDate) < new Date();
  };

  const fetchContracts = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify(
          "No facility selected. Please select a facility first.",
          "error"
        );
        setLoading(false);
        return;
      }

      const response = await makeRequest2(
        `${getContracts}/${facilityId}`,
        "GET"
      );

      if (response?.success) {
        // Get contracts data 
        const contractsData = response.data?.contracts || response.data || [];
        console.log("Fetched contracts:", contractsData);

        // Format dates for display
        const enrichedContracts = contractsData.map(contract => ({
          ...contract,
          isExpired: isExpired(contract.endDate),
          formattedStartDate: new Date(contract.startDate).toISOString().split("T")[0],
          formattedEndDate: new Date(contract.endDate).toISOString().split("T")[0]
        }));

        // Sort contracts by status
        const sortedContracts = enrichedContracts.sort((a, b) => {
          const getStatusPriority = (contract) => {
            if (contract.status === "Inactive") return 2;
            if (contract.isExpired) return 1;
            return 0;
          };
          return getStatusPriority(a) - getStatusPriority(b);
        });

        setContracts(sortedContracts);
        setFilteredContracts(sortedContracts);
      } else {
        setContracts([]);
        setFilteredContracts([]);
      }
    } catch (error) {
      console.error("Error fetching contracts:", error);
      toastify("Error fetching contracts", "error");
      setContracts([]);
      setFilteredContracts([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    // Filter by contract name, customer name, or unit name
    const filtered = contracts.filter((contract) =>
      contract.contractName?.toLowerCase().includes(term.toLowerCase()) ||
      contract.customerName?.toLowerCase().includes(term.toLowerCase()) ||
      contract.unitName?.toLowerCase().includes(term.toLowerCase())
    );
    
    setFilteredContracts(filtered);
  };

  const handleFilterChange = (e) => {
    const filterValue = e.target.value;
    setFilterType(filterValue);

    let filtered = [...contracts];

    if (searchTerm) {
      filtered = filtered.filter((contract) =>
        contract.contractName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contract.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contract.unitName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    switch (filterValue) {
      case "active":
        filtered = filtered.filter(
          (contract) =>
            !isExpired(contract.endDate) && contract.status === "Active"
        );
        break;
      case "inactive":
        filtered = filtered.filter(
          (contract) => contract.status === "Inactive"
        );
        break;
      case "expired":
        filtered = filtered.filter((contract) => isExpired(contract.endDate));
        break;
      default:
        // Just apply the existing search filter
        break;
    }

    // Always sort the filtered results
    filtered.sort((a, b) => {
      const getStatusPriority = (contract) => {
        if (contract.status === "Inactive") return 2;
        if (isExpired(contract.endDate)) return 1;
        return 0;
      };
      return getStatusPriority(a) - getStatusPriority(b);
    });

    setFilteredContracts(filtered);
  };

  const handleEdit = async (rowData) => {
    console.log('Editing contract:', rowData); // Debug log

    try {
      // Fetch facility payment details for this contract
      const facilityId = await getItem("selectedFacilityId");
      const paymentDetailsResponse = await makeRequest2(
        `${fetchFacilityPaymentDetails}/${facilityId}`,
        "GET"
      );

      if (paymentDetailsResponse.success && paymentDetailsResponse.data) {
        // Find matching payment detail
        const paymentDetail = paymentDetailsResponse.data.find(detail =>
          detail._id === rowData.paymentMethodId
        );

        console.log('Found payment detail:', paymentDetail); // Debug log

        // Set all the contract data
        setContractId(rowData._id);
        setContractName(rowData.contractName);
        setAmount(rowData.amount);
        setStartDate(new Date(rowData.startDate).toISOString().split("T")[0]);
        setEndDate(new Date(rowData.endDate).toISOString().split("T")[0]);
        setPaymentFrequency(rowData.paymentFrequency);
        setCurrency(rowData.currency);

        // Create enriched contract data with payment details
        const contractData = {
          ...rowData,
          paymentMethodId: rowData.paymentMethodId,
          shortCode: paymentDetail ? paymentDetail.shortCode : null
        };

        console.log('Setting contract data:', contractData); // Debug log
        setSelectedContract(contractData);
        setEditDialogVisible(true);
      }
    } catch (error) {
      console.error('Error fetching payment details:', error);
      // Still open dialog even if payment details fetch fails
      setContractId(rowData._id);
      setContractName(rowData.contractName);
      setAmount(rowData.amount);
      setStartDate(new Date(rowData.startDate).toISOString().split("T")[0]);
      setEndDate(new Date(rowData.endDate).toISOString().split("T")[0]);
      setPaymentFrequency(rowData.paymentFrequency);
      setCurrency(rowData.currency);
      setSelectedContract(rowData);
      setEditDialogVisible(true);
    }
  };

  const handleClear = () => {
    setContractName("");
    setAmount("");
    setStartDate("");
    setEndDate("");
    setPaymentFrequency("");
    setCurrency("");
    setSelectedContract(null);
  };

  const dateTemplate = (rowData, field) => {
    if (!rowData[field]) return '-';
    
    try {
      const date = new Date(rowData[field]);
      return date.toISOString().split("T")[0];
    } catch (err) {
      console.warn(`Error formatting date for ${field}:`, err);
      return String(rowData[field]) || '-';
    }
  };

  const statusTemplate = (rowData) => {
    const isExpired = new Date(rowData.endDate) < new Date();
    const isInactive = rowData.status === "Inactive";

    let color, bgColor, status;

    if (isExpired) {
      color = "#ff8c00";
      bgColor = "#fff3e6";
      status = "Expired";
    } else if (isInactive) {
      color = "#ff4d4d";
      bgColor = "#ffebeb";
      status = "Inactive";
    } else {
      color = "#4caf50";
      bgColor = "#e8f5e9";
      status = "Active";
    }

    return (
      <span
        style={{
          fontWeight: "bold",
          color: color,
          padding: "5px 10px",
          borderRadius: "15px",
          backgroundColor: bgColor,
        }}
      >
        {status}
      </span>
    );
  };

  // Template for customer and unit columns
  const customerTemplate = (rowData) => {
    return rowData.customerName || '-';
  };

  const unitTemplate = (rowData) => {
    return rowData.unitName || '-';
  };

  const levyTemplate = (rowData) => {
    return rowData.levyName || '-';
  };

  const amountTemplate = (rowData) => {
    const currencyCode = rowData.currencyCode || '';
    return `${currencyCode} ${rowData.amount?.toLocaleString() || '0'}`;
  };

  const actionBodyTemplate = (rowData) => {
    const isInactive = rowData.status === "Inactive";
    const isExpired = new Date(rowData.endDate) < new Date();
  
    return (
      <div className="actions d-flex flex-nowrap justify-content-center" style={{ gap: '0.25rem', minWidth: '120px' }}>
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info p-button-sm"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
          style={{ width: '3rem', height: '3rem' }}
        />
        {!isExpired && (
          <Button
            icon={`ti ${isInactive ? 'ti-lock-open' : 'ti-lock'}`}
            className="p-button-rounded p-button-warning p-button-sm"
            onClick={() => {
              setSelectedContractId(rowData._id);
              setConfirmAction(isInactive ? "activate" : "deactivate");
              setSelectedDisableStatus(isInactive);
              setConfirmDialogVisible(true);
            }}
            tooltip={isInactive ? "Activate" : "Deactivate"}
            style={{ width: '3rem', height: '3rem' }}
          />
        )}
        <Button
          icon="ti ti-download"
          className="p-button-rounded p-button-success p-button-sm"
          onClick={() => downloadContract(rowData)}
          tooltip="Download Contract"
          style={{ width: '3rem', height: '3rem' }}
        />
      </div>
    );
  };

  const downloadContract = async (contract) => {
    const facilityId = await getItem("selectedFacilityId");
    if (!facilityId) {
      console.error("No facility selected.");
      toastify("No facility selected. Please select a facility first.", "error");
      return;
    }

    try {
      
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const margin = 20;
      
      try {
        // Add a blue header
        doc.setFillColor(0, 51, 102);
        doc.rect(0, 0, pageWidth, 40, 'F');
        
        // Title
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(22);
        doc.setFont('helvetica', 'bold');
        doc.text("LEVY CONTRACT AGREEMENT", pageWidth/2, 25, { align: "center" });
        
        // Reference number
        doc.setFontSize(10);
        doc.text(`Contract Reference: ${contract._id}`, pageWidth/2, 35, { align: "center" });
        
        // Reset text color for the rest of the document
        doc.setTextColor(0, 0, 0);
        
        // Contract details section
        let y = 50;
        
        // Section: Contract Information
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Contract Information", margin, y);
        y += 2;
        
        // Decorative line under section title
        doc.setDrawColor(0, 51, 102);
        doc.setLineWidth(0.5);
        doc.line(margin, y, pageWidth - margin, y);
        y += 10;
        
        // Display contract fields
        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        
        const fields = [
          { label: "Contract Name:", value: contract.contractName || "-" },
          { label: "Start Date:", value: new Date(contract.startDate).toLocaleDateString() },
          { label: "End Date:", value: new Date(contract.endDate).toLocaleDateString() },
          { label: "Status:", value: isExpired(contract.endDate) ? "Expired" : contract.status }
        ];
        
        fields.forEach(field => {
          doc.setFont("helvetica", "bold");
          doc.text(field.label, margin, y);
          doc.setFont("helvetica", "normal");
          doc.text(String(field.value || "-"), margin + 60, y);
          y += 7;
        });
        
        // Section: Levy Information
        y += 5;
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Levy Information", margin, y);
        y += 2;
        
        // Decorative line
        doc.setDrawColor(0, 51, 102);
        doc.setLineWidth(0.5);
        doc.line(margin, y, pageWidth - margin, y);
        y += 10;
        
        // Levy fields
        doc.setFontSize(11);
        const levyFields = [
          { label: "Levy Name:", value: contract.levyName || "-" },
          { label: "Levy Type:", value: contract.levyType || "-" },
          { label: "Applicant Type:", value: contract.levyApplicant || "-" },
          { label: "Amount:", value: contract.currencyCode ? 
            `${contract.currencyCode} ${contract.amount?.toLocaleString()}` : 
            `${contract.amount?.toLocaleString()}` 
          },
          { label: "Payment Frequency:", value: contract.paymentFrequency || "-" }
        ];
        
        levyFields.forEach(field => {
          doc.setFont("helvetica", "bold");
          doc.text(field.label, margin, y);
          doc.setFont("helvetica", "normal");
          doc.text(String(field.value || "-"), margin + 60, y);
          y += 7;
        });
        
        // Section: Customer Information
        y += 5;
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Customer Information", margin, y);
        y += 2;
        
        // Decorative line
        doc.setDrawColor(0, 51, 102);
        doc.setLineWidth(0.5);
        doc.line(margin, y, pageWidth - margin, y);
        y += 10;
        
        // Customer fields
        doc.setFontSize(11);
        const customerFields = [
          { label: "Customer Name:", value: contract.customerName || "-" },
          { label: "Customer Type:", value: contract.customerType || "-" },
          { label: "Unit:", value: contract.unitName || "-" },
          { label: "Unit Location:", value: contract.division && contract.floorUnitNo ? 
            `${contract.division}, ${contract.floorUnitNo}` : "-" 
          }
        ];
        
        customerFields.forEach(field => {
          doc.setFont("helvetica", "bold");
          doc.text(field.label, margin, y);
          doc.setFont("helvetica", "normal");
          doc.text(String(field.value || "-"), margin + 60, y);
          y += 7;
        });
        
        // Terms and Conditions Section
        // y += 5;
        // doc.setFontSize(16);
        // doc.setFont("helvetica", "bold");
        // doc.text("Terms and Conditions", margin, y);
        // y += 2;
        
        // // Decorative line
        // doc.setDrawColor(0, 51, 102);
        // doc.setLineWidth(0.5);
        // doc.line(margin, y, pageWidth - margin, y);
        // y += 10;
        
        // // Terms content
        // doc.setFontSize(10);
        // doc.setFont("helvetica", "normal");
        
        // const termsText = [
        //   "1. The customer agrees to pay the specified amount according to the payment frequency.",
        //   "2. This contract is valid from the start date to the end date specified above.",
        //   "3. The facility management reserves the right to terminate the contract in case of payment defaults.",
        //   "4. Any disputes arising from this contract shall be resolved through arbitration.",
        //   "5. This contract is subject to the laws and regulations of the jurisdiction where the facility is located."
        // ];
        
        // termsText.forEach(term => {
        //   doc.text(term, margin, y, { maxWidth: pageWidth - (margin * 2) });
        //   y += 7;
        // });
        
        // Signatures Section
        y = pageHeight - 80;
        
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Signatures", margin, y);
        y += 2;
        
        // Decorative line
        doc.setDrawColor(0, 51, 102);
        doc.setLineWidth(0.5);
        doc.line(margin, y, pageWidth - margin, y);
        y += 10;
        
        // Signature lines
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(margin, y, margin + 70, y);
        doc.line(pageWidth - margin - 70, y, pageWidth - margin, y);
        
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text("Customer Signature", margin, y + 5);
        doc.text("Facility Representative", pageWidth - margin - 70, y + 5);
        
        doc.text("Date: ________________", margin, y + 15);
        doc.text("Date: ________________", pageWidth - margin - 70, y + 15);
        
        // Footer
        doc.setFontSize(8);
        doc.setTextColor(100, 100, 100);
        doc.text("This document is electronically generated.", pageWidth/2, pageHeight - 15, { align: "center" });
        doc.text(`Generated on ${new Date().toLocaleDateString()}`, pageWidth/2, pageHeight - 10, { align: "center" });
        
        // Format filename
        const contractName = contract.contractName?.replace(/[^a-zA-Z0-9]/g, "_") || "contract";
        const filename = `${contractName}_${contract._id.substring(0, 8)}.pdf`;
        doc.save(filename);
        
        toastify("Contract downloaded successfully", "success");
      } catch (pdfError) {
        console.error("PDF generation error:", pdfError);
        throw new Error("Error creating PDF document: " + pdfError.message);
      }
    } catch (error) {
      console.error("Error generating contract PDF:", error);
      toastify("Error generating contract PDF: " + (error.message || "Unknown error"), "error");
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/facility/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Levy Management</li>
                <li className="breadcrumb-item">Levy Contracts</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Levy Contracts</h5>
            </div>
            <div className="card-body">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <input
                        className="form-control"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <select
                        className="form-control"
                        value={filterType}
                        onChange={handleFilterChange}
                      >
                        <option value="all">All Contracts</option>
                        <option value="active">Active Contracts</option>
                        <option value="inactive">Inactive Contracts</option>
                        <option value="expired">Expired Contracts</option>
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="d-flex justify-content-end gap-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => navigate("/facility/levy_management/contracts/add_multiple_contracts")}
                        >
                          Add Multiple Contracts
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => navigate("/facility/levy_management/contracts/add_contract")}
                        >
                          Add Contract
                        </button>
                      </div>
                    </div>
                  </div>

                  <DataTable
                    value={filteredContracts}
                    emptyMessage="No contracts found."
                    paginator
                    rows={5}
                    stripedRows
                    tableStyle={{ minWidth: "50rem" }}
                    sortMode="multiple"
                    removableSort
                    defaultSortOrder={-1}
                  >
                    <Column
                      field="contractName"
                      header="Name"
                      sortable
                      sortField="contractName"
                    />
                    <Column
                      field="startDate"
                      header="Starting Date"
                      sortable
                      sortField="startDate"
                      body={(rowData) => dateTemplate(rowData, "startDate")}
                    />
                    <Column
                      field="endDate"
                      header="Ending Date"
                      sortable
                      sortField="endDate"
                      body={(rowData) => dateTemplate(rowData, "endDate")}
                    />
                    <Column
                      field="status"
                      header="Status"
                      body={statusTemplate}
                      sortable
                      sortField="status"
                    />
                    <Column
                      body={actionBodyTemplate}
                      header="Actions"
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditContract
        visible={editDialogVisible}
        onHide={() => setEditDialogVisible(false)}
        contractId={contractId}
        contractName={contractName}
        setContractName={setContractName}
        amount={amount}
        setAmount={setAmount}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        paymentFrequency={paymentFrequency}
        setPaymentFrequency={setPaymentFrequency}
        currency={currency}
        setCurrency={setCurrency}
        handleClear={handleClear}
        onSuccess={fetchContracts}
        initialContract={selectedContract}
      />

      {(confirmAction === "activate" || confirmAction === "deactivate") && (
        <DisableContract
          visible={isConfirmDialogVisible}
          onHide={() => setConfirmDialogVisible(false)}
          contractId={selectedContractId}
          isActivating={confirmAction === "activate"}
          onSuccess={fetchContracts}
        />
      )}
    </Layout>
  );
};

export default ContractManagement;