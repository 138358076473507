import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

// Reusable Select Component
const FormSelect = ({
    id,
    label,
    value,
    onChange,
    options,
    placeholder = 'Select...',
    disabled = false,
    required = false,
    additionalClassName = ''
}) => (
    <div className="mb-4">
        <label htmlFor={id} className="block mb-2">{label} {required && '*'}</label>
        <select
            id={id}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            className={`form-select w-full p-2 border rounded ${additionalClassName}`}
            required={required}
            disabled={disabled}
        >
            <option value="">{placeholder}</option>
            {options.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label} {option.pricing ? `(${option.formattedPricing})` : ''}
                </option>
            ))}
        </select>
    </div>
);

const AddServiceRequestForm = ({
    visible,
    onHide,
    units = [],
    services = [],
    selectedUnit,
    selectedService,
    setSelectedUnit,
    setSelectedService,
    onSubmit,
    brief,
    setBrief,
    quotedPrice,
    setQuotedPrice,
    finalPrice,
    setFinalPrice,
    serviceProviderType,
    setServiceProviderType,
    vendors = [],
    employees = [],
    selectedAssignee,
    setSelectedAssignee,
    loading = false,
    formatCurrency
}) => {
    // Automatically prefill quoted price when vendor changes
    useEffect(() => {
        if (serviceProviderType === 'external' && selectedAssignee) {
            const selectedVendor = vendors.find(v => v.value === selectedAssignee);
            if (selectedVendor) {
                setQuotedPrice(selectedVendor.pricing || 0);
            }
        }
    }, [selectedAssignee, serviceProviderType, vendors]);

    // Handler for assignee selection
    const handleAssigneeSelection = (value) => {
        setSelectedAssignee(value);
    };

    // Determine assignee options based on provider type
    const assigneeOptions = serviceProviderType === 'internal'
        ? employees.map(employee => ({
            ...employee,
            label: employee.contact
                ? `${employee.label} - ${employee.contact}`
                : employee.label
        }))
        : vendors.map(vendor => ({
            ...vendor,
            formattedPricing: vendor.pricing ? formatCurrency(vendor.pricing) : ''
        }));

    return (
        <Dialog
            header="Add Service Request"
            visible={visible}
            onHide={onHide}
            style={{ width: '40vw', maxWidth: '500px' }}
            modal
            className="p-fluid"
        >
            <div className="flex flex-col gap-4">
                {/* Unit Selection */}
                <FormSelect
                    id="unit"
                    label="Unit"
                    value={selectedUnit}
                    onChange={setSelectedUnit}
                    options={units}
                    placeholder="Select Unit"
                    required
                />

                {/* Service Selection */}
                <FormSelect
                    id="service"
                    label="Service"
                    value={selectedService}
                    onChange={setSelectedService}
                    options={services}
                    placeholder="Select Service"
                    required
                />

                {/* Service Provider Type */}
                <FormSelect
                    id="providerType"
                    label="Service Provider Type"
                    value={serviceProviderType}
                    onChange={(value) => {
                        setServiceProviderType(value);
                        setSelectedAssignee(null);
                        // Reset quoted and final prices when changing provider type
                        setQuotedPrice(0);
                        setFinalPrice(0);
                    }}
                    options={[
                        { value: 'internal', label: 'Internal Employee' },
                        { value: 'external', label: 'External Vendor' }
                    ]}
                    placeholder="Select Provider Type"
                    required
                />

                {/* Assignee Selection */}
                {selectedService && serviceProviderType && (
                    <FormSelect
                        id="assignee"
                        label={serviceProviderType === 'internal'
                            ? 'Select Employee'
                            : 'Select Vendor'}
                        value={selectedAssignee}
                        onChange={handleAssigneeSelection}
                        options={assigneeOptions}
                        placeholder={loading
                            ? `Loading ${serviceProviderType === 'internal' ? 'employees' : 'vendors'}...`
                            : `Select ${serviceProviderType === 'internal' ? 'Employee' : 'Vendor'}`}
                        disabled={loading}
                        required
                        additionalClassName={
                            (!loading && assigneeOptions.length === 0)
                                ? 'border-red-500'
                                : ''
                        }
                    />
                )}

                {/* Error Messages */}
                {!loading && serviceProviderType === 'external' && vendors.length === 0 && (
                    <div className="text-sm text-red-500 -mt-2 mb-4">
                        No vendors available for this service
                    </div>
                )}
                {!loading && serviceProviderType === 'internal' && employees.length === 0 && (
                    <div className="text-sm text-red-500 -mt-2 mb-4">
                        No employees available
                    </div>
                )}

                {/* Brief Description */}
                <div className="mb-4">
                    <label htmlFor="brief" className="block mb-2">Brief Description *</label>
                    <textarea
                        id="brief"
                        value={brief}
                        onChange={(e) => setBrief(e.target.value)}
                        required
                        className="form-control w-full p-2 border rounded"
                        rows={4}
                        placeholder="Enter description..."
                    />
                </div>

                {/* Quoted Price */}
                <div className="mb-4">
                    <label htmlFor="quotedPrice" className="block mb-2">Quoted Price (KSH) *</label>
                    <InputNumber
                        id="quotedPrice"
                        value={quotedPrice}
                        onValueChange={(e) => setQuotedPrice(e.value)}
                        mode="currency"
                        currency="KSH"
                        locale="en-US"
                        required
                        className="w-full"
                        disabled={serviceProviderType === 'external'} // Make read-only for external vendors
                    />
                </div>

                {/* Final Price */}
                <div className="mb-4">
                    <label htmlFor="finalPrice" className="block mb-2">Final Price (KSH) *</label>
                    <InputNumber
                        id="finalPrice"
                        value={finalPrice}
                        onValueChange={(e) => setFinalPrice(e.value)}
                        mode="currency"
                        currency="KSH"
                        locale="en-US"
                        required
                        className="w-full"
                    />
                </div>

                {/* Submit Button */}
                <div className="d-flex justify-content-end gap-2">
                    <button
                        className="btn btn-primary px-4 py-2"
                        onClick={onSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit Request'}
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default AddServiceRequestForm;