import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { makeRequest2 } from "../../../../utils/makeRequest";
import { toastify } from "../../../../utils/toast";
import styles from "./invoice.module.css";
import Layout from "../../../facility/component/layout";
import { useFacilityStore } from "../../../../app/z-store/store";
import { useFacilityServiceRequests } from "../hooks/use_facility_service_requests";
import { getCompanyInformationURL } from "../../../../utils/urls";
// Import the CashPaymentModal from utils folder
import CashPaymentModal from "../../../../utils/cashPaymentModal";

const VasInvoicePage = () => {
  // State management
  const [invoice, setInvoice] = useState({
    invoiceNumber: "",
    issueDate: new Date(),
    dueDate: new Date(),
    status: "Pending",
    unit: "",
    fullName: "",
    subTotal: 0,
    tax: 0,
    amount: 0,
    invoiceNote: "Payment is due within 30 days",
  });
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [facilityLogo, setFacilityLogo] = useState("");
  const [showReceiptsDialog, setShowReceiptsDialog] = useState(false);
  const [showCashPaymentModal, setShowCashPaymentModal] = useState(false);

  // Hooks
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const facilityId = useFacilityStore((state) => state.facilityId);

  // Destructuring with proper structure
  const {
    handlers: { fetchSingleInvoice, downloadInvoicePDF },
  } = useFacilityServiceRequests();

  useEffect(() => {
    if (invoiceId && facilityId) {
      fetchInvoiceData();
      fetchCompanyDetails();
      getFacilityLogo();
    }
  }, [invoiceId, facilityId]);

  // Fetch facility logo
  const getFacilityLogo = async () => {
    try {
      const storedLogo = localStorage.getItem("selectedFacilityLogo");
      if (storedLogo) {
        setFacilityLogo(storedLogo);
      } else {
        setFacilityLogo(""); // Set empty string if no logo exists
      }
    } catch (error) {
      console.error("Error fetching facility logo:", error);
      setFacilityLogo(""); // Set empty string on error
    }
  };

  const fetchInvoiceData = async () => {
    try {
      setLoading(true);
      console.log("Fetching invoice data for:", { facilityId, invoiceId });

      // First, try using the hook's fetchSingleInvoice
      const invoiceData = await fetchSingleInvoice(facilityId, invoiceId);
      console.log("Fetched invoice data:", invoiceData);

      // If the hook method fails or returns empty data, try direct API call
      if (!invoiceData || Object.keys(invoiceData).length === 0) {
        const response = await makeRequest2(
          `/api/vas/invoice/${facilityId}/${invoiceId}`,
          "GET"
        );

        if (response.success && response.data) {
          console.log("Fallback API response:", response.data);
          setInvoice(mapInvoiceData(response.data.data || response.data));
        } else {
          throw new Error("Failed to fetch invoice data");
        }
      } else {
        // Use the data from the hook
        setInvoice(mapInvoiceData(invoiceData));
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
      toastify(error.message || "Failed to fetch invoice data", "error");
    } finally {
      setLoading(false);
    }
  };

  // Map API response to a consistent invoice object structure
  const mapInvoiceData = (data) => {
    if (!data) return {};

    console.log("Mapping invoice data:", data);

    // Extract customer name from different possible locations
    const customerName =
      data.customerInfo?.fullName ||
      data.fullName ||
      (data.client?.firstName && data.client?.lastName ? 
        data.client.firstName + ' ' + data.client.lastName : 
        'Customer');

    // Get currency information
    const currencySymbol = data.currency?.symbol || 'KSh';
    const currencyCode = data.currency?.code || 'KES';

    // Get amounts, handling different formats
    const amount = parseFloat(data.amount || 0);
    const subTotal = parseFloat(data.subTotal || 0);
    const tax = parseFloat(data.tax || 0);
    const amountPaid = parseFloat(data.amountPaid || 0);

    // Map to consistent structure
    return {
      _id: data._id,
      invoiceNumber: data.invoiceNumber || 'N/A',
      accountNumber: data.accountNumber || 'N/A',
      issueDate: data.issueDate || data.createdAt || new Date(),
      dueDate: data.dueDate || new Date(),
      status: data.status || 'Pending',
      unit: data.unit || 'N/A',
      fullName: customerName,
      subTotal: subTotal,
      tax: tax,
      amount: amount,
      totalAmount: amount, // For consistency with other invoice pages
      amountPaid: amountPaid,
      balance: amount - amountPaid,
      serviceName: data.serviceName || 'Service Fee',
      invoiceNote: data.invoiceNote || "Payment is due within 30 days",
      currency: {
        symbol: currencySymbol,
        code: currencyCode
      },
      items: data.items || [
        {
          description: data.serviceName || 'Service Fee',
          quantity: 1,
          unitPrice: subTotal
        }
      ],
      reconciliationHistory: data.reconciliationHistory || [],
      paymentDetails: data.paymentDetails || {},
      client: {
        firstName: customerName.split(' ')[0] || '',
        lastName: customerName.split(' ').slice(1).join(' ') || ''
      }
    };
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await makeRequest2(
        `${getCompanyInformationURL}/${facilityId}`,
        "GET"
      );

      if (response.success) {
        console.log("Company details:", response.data);
        setCompanyDetails(response.data);
      } else {
        throw new Error(response.message || "Failed to fetch company details");
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
      toastify(error.message, "error");
    }
  };

  const handleDownloadPDF = async () => {
    try {
      setLoading(true);
      toastify("Generating PDF...", "info");
      
      const element = document.getElementById("printable-invoice");
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff"
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4"
      });

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let position = 0;
      
      // Add first page
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
      
      // Add additional pages if needed
      let heightLeft = imgHeight - pageHeight; // Changed from const to let
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
        heightLeft -= pageHeight;
      }
      
      pdf.save(`Invoice_${invoice.invoiceNumber}_${new Date().toISOString().slice(0, 10)}.pdf`);
      toastify("PDF downloaded successfully!", "success");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toastify("Failed to generate PDF. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  // Handle cash payment success
  const handleCashPaymentSuccess = (payment) => {
    // Refresh invoice data to reflect the new payment
    fetchInvoiceData();
  };

  // Handle cash payment error
  const handleCashPaymentError = (error) => {
    console.error("Cash payment error:", error);
  };

  // View Receipt function
  const viewReceipt = (paymentData) => {
    navigate(`/facility/levy_management/receipt/${paymentData._id}`, {
      state: {
        receipt: paymentData,
        invoice: invoice,
        companyDetails: companyDetails
      }
    });
  };

  // Action template for receipt table
  const actionBodyTemplate = (rowData) => {
    const isMpesa = rowData.type?.toUpperCase() === 'MPESA';
    return (
      <Button
        icon="fas fa-eye"
        className="p-button-sm p-button-text"
        onClick={() => viewReceipt(rowData)}
        disabled={!isMpesa}
        tooltip={isMpesa ? "View Receipt" : "Receipt not available"}
        tooltipOptions={{ position: 'left' }}
      />
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      return 'N/A';
    }
  };

  const formatAmount = (amount) => {
    if (amount === undefined || amount === null) return '0.00';
    const currencyCode = invoice?.currency?.code || 'KES';
    return `${currencyCode} ${parseFloat(amount).toFixed(2)}`;
  };

  // Balance calculation methods (consistent with other invoice pages)
  const hasBalanceBroughtForward = () => {
    return false; // VAS invoices typically don't have balance brought forward
  };

  // Calculate total balance 
  const calculateTotalBalance = () => {
    if (!invoice) return 0;
    return invoice.amount || 0;
  };

  // Calculate total due
  const calculateTotalDue = () => {
    if (!invoice) return 0;
    const totalAmount = invoice.amount || 0;
    const amountPaid = invoice.amountPaid || 0;
    return totalAmount - amountPaid;
  };

  // Check if invoice is fully paid
  const isInvoiceFullyPaid = invoice?.status?.toLowerCase() === 'paid';

  if (loading) {
    return (
      <Layout>
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span className="ms-2">Loading invoice details...</span>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.pageContainer}>
        {/* Action Buttons */}
        <div className={styles.actionCard}>
          <div className={styles.actionCardLeft}>
            <Link to="#" onClick={() => navigate(-1)} className={styles.backLink}>
              <i className="ti ti-arrow-narrow-left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className={styles.actionCardRight}>
            <Button
              label="Receipts"
              onClick={() => setShowReceiptsDialog(true)}
              className="mr-2 btn btn-primary"
            />
            <Button
              label="Cash Payment"
              onClick={() => setShowCashPaymentModal(true)}
              className="mx-2 btn btn-success"
              disabled={isInvoiceFullyPaid || loading}
              tooltip={isInvoiceFullyPaid ? "Invoice is fully paid" : "Record a cash payment"}
            />
            <Button
              label="Download Invoice"
              onClick={handleDownloadPDF}
              className="mr-2 btn btn-primary"
              disabled={loading}
            />
          </div>
        </div>

        {/* Cash Payment Modal */}
        <CashPaymentModal
          visible={showCashPaymentModal}
          onHide={() => setShowCashPaymentModal(false)}
          invoice={invoice}
          facilityId={facilityId}
          onSuccess={handleCashPaymentSuccess}
          onError={handleCashPaymentError}
        />

        {/* Receipts Dialog */}
        <Dialog
          header="Payment Receipts"
          visible={showReceiptsDialog}
          style={{ width: '50vw' }}
          onHide={() => setShowReceiptsDialog(false)}
          resizable={false}
        >
          <DataTable
            value={invoice?.reconciliationHistory || []}
            responsiveLayout="scroll"
            emptyMessage="No payment records found"
            className="p-datatable-sm"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
          >
            <Column
              field="date"
              header="Date"
              body={(rowData) => formatDate(rowData.date)}
              sortable
            />
            <Column
              field="amount"
              header="Amount"
              body={(rowData) => formatAmount(rowData.amount)}
              sortable
            />
            <Column
              field="type"
              header="Type"
              sortable
            />
            <Column
              field="paymentReference"
              header="Reference"
              sortable
            />
            <Column
              field="paymentCompletion"
              header="Status"
              sortable
              body={(rowData) => (
                <span className={`badge ${
                  rowData.paymentCompletion === 'Completed' ? 'bg-success' :
                  rowData.paymentCompletion === 'Partial' ? 'bg-warning' :
                  rowData.paymentCompletion === 'Overpaid' ? 'bg-info' : 'bg-secondary'
                }`}>
                  {rowData.paymentCompletion}
                </span>
              )}
            />
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: '4rem' }}
            />
          </DataTable>
        </Dialog>

        {/* Invoice Content */}
        <div className={styles.invoiceContainer}>
          <div id="printable-invoice" className={styles.invoiceContent}>
            <div
              className={styles.statusBanner}
              data-status={invoice?.status?.toLowerCase()}
            >
              <span>{invoice?.status}</span>
            </div>

            <div className={styles.invoiceHeader}>
              <div className={styles.invoiceHeaderContent}>
                {/* Invoice title now above the logo */}
                <div className={styles.headerLeft}>
                  <h1 className={styles.invoiceTitleInHeader} style={{ marginTop: "-20px", marginLeft: "60%", color: "white", fontSize: "28px", fontWeight: "600" }}>INVOICE</h1>
                  {facilityLogo && (
                    <div className={styles.logoContainer} style={{ marginTop: "0", marginLeft: "60%" }}>
                      <img
                        src={facilityLogo}
                        alt="Company Logo"
                        className={styles.companyLogo}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.headerDetails}>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE NO.</span>
                    <span className={styles.value}>{invoice.invoiceNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>ACCOUNT NO.</span>
                    <span className={styles.value}>{invoice.accountNumber}</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>INVOICE DATE</span>
                    <span className={styles.value}>
                      {formatDate(invoice.issueDate)}
                    </span>
                  </div>
                  <div className={styles.detailRow}>
                    <span className={styles.label}>DUE DATE</span>
                    <span className={styles.value}>
                      {formatDate(invoice.dueDate)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.invoiceBody}>
              <div className={styles.addressSection}>
                <div className={styles.addressLayout}>
                  <div className={styles.fromSection}>
                    <h2 className={styles.sectionTitle}>FROM</h2>
                    <div className={styles.addressContent}>
                      <div>{companyDetails?.name || 'Company Name'}</div>
                      <div>{companyDetails?.address || 'Company Address'}</div>
                      <div>{companyDetails?.country || 'Country'}</div>
                    </div>
                  </div>
                  <div className={styles.billToSection}>
                    <h2 className={styles.sectionTitle}>BILL TO</h2>
                    <div className={styles.addressContent}>
                      <div>{invoice.fullName || `${invoice.client?.firstName || ''} ${invoice.client?.lastName || ''}`}</div>
                      <div>Unit: {invoice.unit || 'N/A'}</div>
                    </div>
                  </div>
                  <div className={styles.totalSection}>
                    <h2 className={styles.sectionTitle}>TOTAL DUE</h2>
                    <div className={styles.totalAmount}>
                      {formatAmount(calculateTotalDue())}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.itemsGrid}>
                <div className={styles.gridHeader}>
                  <span className={styles.columnHeader}>DESCRIPTION</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>UNIT PRICE</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>QTY</span>
                  <span className={`${styles.columnHeader} ${styles.right}`}>AMOUNT</span>
                </div>

                {invoice.items && invoice.items.map((item, index) => (
                  <div key={index} className={styles.gridRow}>
                    <span className={styles.value}>{item.description}</span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.unitPrice)}
                    </span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {item.quantity}
                    </span>
                    <span className={`${styles.value} ${styles.right}`}>
                      {formatAmount(item.unitPrice * item.quantity)}
                    </span>
                  </div>
                ))}

                <div className={styles.calculations}>
                  <div className={styles.calculationsGrid}>
                    {/* Invoice Calculation Section */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>SUBTOTAL</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice.subTotal)}
                      </span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>
                        TAX {invoice.tax > 0 ? `(${((invoice.tax / invoice.subTotal) * 100).toFixed(0)}%)` : '(0%)'}
                      </span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice.tax)}
                      </span>
                    </div>
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>INVOICE TOTAL</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice.amount)}
                      </span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Total Balance */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>TOTAL BALANCE</span>
                      <span className={styles.calcValue}>
                        {formatAmount(calculateTotalBalance())}
                      </span>
                    </div>

                    {/* Divider */}
                    <div className={styles.divider}></div>

                    {/* Amount Paid */}
                    <div className={styles.calculationsRow}>
                      <span className={styles.calcLabel}>PAYMENT</span>
                      <span className={styles.calcValue}>
                        {formatAmount(invoice.amountPaid)}
                      </span>
                    </div>

                    {/* Total Due */}
                    <div className={`${styles.calculationsRow} ${styles.balance}`}>
                      <span className={styles.calcLabel}>TOTAL DUE</span>
                      <span className={styles.calcValue}>
                        {formatAmount(calculateTotalDue())}
                      </span>
                    </div>
                  </div>

                  <div className={styles.totalRow}>
                    <span className={styles.totalLabel}>TOTAL DUE</span>
                    <span className={styles.totalValue}>
                      {formatAmount(calculateTotalDue())}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.footer} style={{ backgroundColor: '#f0f0f0' }}>
                <div className="row">
                  <div className="col-sm-6 mt-2 px-5">
                    {/* Payment Details on Left Margin */}
                    <div className={styles.paymentDetails}>
                      <h6 className={styles.detailsTitle}>Payment Details:</h6>
                      
                      {invoice.paymentDetails?.paymentMethod && invoice.paymentDetails.paymentMethod.includes(" - ") ? (
                        <>
                          <h6 className={styles.detailsText}>Bank: Mpesa</h6>
                          <h6 className={styles.detailsText}>
                            Paybill: {invoice.paymentDetails.paymentMethod.split(" - ")[1]}
                          </h6>
                        </>
                      ) : (
                        <h6 className={styles.detailsText}>
                          Please use account number {invoice.accountNumber} for payment.
                        </h6>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    {/* Signature Placeholder on Right Margin */}
                    <div className={styles.signatureSection}>
                      <div className={styles.signatureBox}>
                        <p style={{ textAlign: 'center', marginTop: '20px' }}>___________________________</p>
                        <p style={{ textAlign: 'center' }}>Authorized Signature</p>
                      </div>
                    </div>
                  </div>
                </div>

                {invoice.paymentDetails?.paymentMethod && invoice.paymentDetails.paymentMethod.includes(" - ") && (
                  <div className="row">
                    <div className="col-12 pt-2">
                      <div className={styles.centeredText}>
                        <p style={{ textAlign: 'center' }}>
                          To complete your payment, kindly use the paybill number
                          <strong> {invoice.paymentDetails.paymentMethod.split(" - ")[1]} </strong>,
                          followed by the account number <strong> {invoice.accountNumber} </strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VasInvoicePage;