import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { makeRequest2 } from '../../../../../../utils/makeRequest';
import { toastify } from '../../../../../../utils/toast';
import { useFacilityStore } from '../../../../../../app/z-store/store';
import { useNavigate } from 'react-router-dom';

const InvoicesTab = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 0,
    });
    const [statusFilter, setStatusFilter] = useState(null);
    const selectedFacilityId = useFacilityStore((state) => state.facilityId);
    const dt = useRef(null);
    const toast = useRef(null);

    const statusOptions = [
        { label: 'Pending', value: 'Pending' },
        { label: 'Paid', value: 'Paid' },
        { label: 'Partially Paid', value: 'Partially Paid' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Overdue', value: 'Overdue' },
        { label: 'Unpaid', value: 'Unpaid' }
    ];

    useEffect(() => {
        if (selectedFacilityId) {
            fetchInvoices();
        }
    }, [selectedFacilityId, lazyParams, statusFilter]);

    const fetchInvoices = async () => {
        try {
            setLoading(true);
            const skip = lazyParams.first;
            const limit = lazyParams.rows;
            let queryParams = `limit=${limit}&skip=${skip}`;
            if (statusFilter) {
                queryParams += `&status=${statusFilter}`;
            }
            const response = await makeRequest2(
                `/api/app/water-invoices/${selectedFacilityId}?${queryParams}`,
                'GET'
            );
            if (response.success) {
                const invoicesData = response.data?.invoices || [];
                setInvoices(invoicesData);
                setTotalRecords(response.data?.totalCount || 0);
            } else {
                toastify('Failed to fetch water invoices', 'error');
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
            toastify('Error loading water invoices', 'error');
        } finally {
            setLoading(false);
        }
    };

    const onPage = (event) => {
        setLazyParams(event);
    };

    const statusBodyTemplate = (rowData) => {
        const statusColors = {
            'Pending': 'bg-info',
            'Paid': 'bg-success',
            'Partially Paid': 'bg-warning',
            'Cancelled': 'bg-secondary',
            'Overdue': 'bg-danger',
            'Unpaid': 'bg-danger'
        };
        return (
            <span className={`badge ${statusColors[rowData.status] || 'bg-secondary'}`}>
                {rowData.status || 'unknown'}
            </span>
        );
    };

    const formatNumber = (value) => {
        return new Intl.NumberFormat('en-KE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    const invoiceAmountBodyTemplate = (rowData) => {
        return formatNumber(rowData.charges.totalMonthlyBill);
    };

    const amountPaidBodyTemplate = (rowData) => {
        return formatNumber(rowData.amountPaid);
    };

    const balanceBodyTemplate = (rowData) => {
        const balance = rowData.charges.totalMonthlyBill - rowData.amountPaid;
        return formatNumber(balance);
    };

    const dateBodyTemplate = (rowData, field) => {
        return new Date(rowData[field]).toLocaleDateString();
    };

    const handleView = (invoice) => {
        // Navigate to the invoice page with the invoice ID as state
        navigate("/facility/utility_management/invoice_management/invoice_page", { 
            state: { 
                invoiceId: invoice._id,
                invoiceNumber: invoice.invoiceNumber
            } 
        });
    };

    const customerUnitBodyTemplate = (rowData) => {
        const customerName = rowData.CustomerInfo ? rowData.CustomerInfo.fullName : 'N/A';
        const unitName = rowData.unitName || 'N/A';
        return (
            <div>
                <div className="fw-bold">{customerName}</div>
                <div className="small text-muted">{unitName}</div>
            </div>
        );
    };

    const filteredInvoices = searchTerm
        ? invoices.filter((invoice) =>
            Object.values(invoice).some((value) =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            ) ||
            (invoice.CustomerInfo &&
                invoice.CustomerInfo.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : invoices;

    const exportCSV = () => {
        const header = [
            'Invoice No', 'Invoice Date', 'Customer', 'Unit', 
            'Invoice (KES)', 'Paid Amount (KES)', 'Balance (KES)', 
            'Status', 'Due Date'
        ];
        const data = filteredInvoices.map(invoice => [
            invoice.invoiceNumber,
            new Date(invoice.dateIssued).toLocaleDateString(),
            invoice.CustomerInfo ? invoice.CustomerInfo.fullName : 'N/A',
            invoice.unitName || 'N/A',
            invoice.charges.totalMonthlyBill,
            invoice.amountPaid,
            invoice.charges.totalMonthlyBill - invoice.amountPaid,
            invoice.status,
            new Date(invoice.dueDate).toLocaleDateString()
        ]);
        const csvContent = [
            header.join(','),
            ...data.map(row => row.map(cell => 
                typeof cell === 'string' && cell.includes(',') 
                    ? `"${cell}"`
                    : cell
            ).join(','))
        ].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `Water_Invoices_${new Date().toISOString().slice(0, 10)}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Invoices exported to CSV', life: 3000 });
    };

    if (!selectedFacilityId) {
        return (
            <div className="card">
                <div className="card-body text-center">
                    Please select a facility first
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="card-body">
                <div className="d-flex flex-column flex-md-row justify-content-between mb-3 gap-2">
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <div className="col-md-4">
                            <input
                                className="form-control"
                                placeholder="Search invoices..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="col-md-3">
                            <Dropdown 
                                value={statusFilter}
                                options={statusOptions}
                                onChange={(e) => setStatusFilter(e.value)}
                                placeholder="Filter by Status"
                                className="w-100"
                            />
                        </div>
                    </div>
                    <div className="d-flex gap-2">
                        <Button 
                            label="CSV" 
                            icon="ti ti-file-spreadsheet" 
                            className="p-button-success" 
                            onClick={exportCSV} 
                        />
                    </div>
                </div>
                <div className="dt-responsive table-responsive">
                    <DataTable
                        ref={dt}
                        value={filteredInvoices}
                        emptyMessage="No water invoices found."
                        sortMode="multiple"
                        paginator
                        lazy
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        onPage={onPage}
                        loading={loading}
                        stripedRows
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="invoiceNumber" header="Inv No" sortable />
                        <Column
                            field="dateIssued"
                            header="Invoice Date"
                            body={(rowData) => dateBodyTemplate(rowData, 'dateIssued')}
                            sortable
                        />
                        <Column
                            field="CustomerInfo.fullName"
                            header="Customer / Unit"
                            body={customerUnitBodyTemplate}
                            sortable
                        />
                        <Column
                            header="Invoice (KES)"
                            body={invoiceAmountBodyTemplate}
                            sortable
                        />
                        <Column
                            field="amountPaid"
                            header="Paid Amount (KES)"
                            body={amountPaidBodyTemplate}
                            sortable
                        />
                        <Column
                            header="Balance (KES)"
                            body={balanceBodyTemplate}
                            sortable
                        />
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable />
                        <Column
                            field="dueDate"
                            header="Due Date"
                            body={(rowData) => dateBodyTemplate(rowData, 'dueDate')}
                            sortable
                        />
                        <Column field="yearMonth" header="Month" sortable />
                        <Column
                            header="Actions"
                            body={(rowData) => (
                                <Button
                                    icon="ti ti-eye"
                                    className="p-button-rounded p-button-text"
                                    onClick={() => handleView(rowData)}
                                    tooltip="View Invoice"
                                />
                            )} 
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default InvoicesTab;