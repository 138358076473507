import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../../component/layout';
import DashboardTab from './tabs/dashboardTab';
// import SmartMetersTab from './tabs/smartMetersTab';
import AnalogMetersTab from './tabs/analogMetersTab';
import InvoicesTab from './tabs/invoicesTab';
import CustomersTab from './tabs/customersTab';
import TransactionsTab from './tabs/transactionsTab';
import SettingsTab from './tabs/settingsTab';

const WaterMeterManagement = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // Initialize the activeTab with location.state.activeTab if available, else default to 'dashboard'
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'dashboard');

    const tabComponents = {
        dashboard: DashboardTab,
        // 'smart-meters': SmartMetersTab,
        'analog-meters': AnalogMetersTab,
        invoices: InvoicesTab,
        customers: CustomersTab,
        transactions: TransactionsTab,
        settings: SettingsTab
    };

    const TabComponent = tabComponents[activeTab];

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb mb-3">
                                <li className="breadcrumb-item">
                                    <Link to="/facility">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/facility/utility_management/meter_types">Meters Management</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>Water Meters</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'dashboard' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('dashboard')}
                                        type="button"
                                    >
                                        <i className="ti ti-dashboard me-2"></i>Dashboard
                                    </button>
                                </li>
                                {/* <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'smart-meters' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('smart-meters')}
                                        type="button"
                                    >
                                        <i className="ti ti-droplet me-2"></i>Smart Water Meters
                                    </button>
                                </li> */}
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'analog-meters' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('analog-meters')}
                                        type="button"
                                    >
                                        <i className="ti ti-gauge me-2"></i>Analog Water Meters
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'invoices' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('invoices')}
                                        type="button"
                                    >
                                        <i className="ti ti-file-invoice me-2"></i>Invoices
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'customers' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('customers')}
                                        type="button"
                                    >
                                        <i className="ti ti-users me-2"></i>Accounts
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'transactions' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('transactions')}
                                        type="button"
                                    >
                                        <i className="ti ti-cash me-2"></i>Transactions
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('settings')}
                                        type="button"
                                    >
                                        <i className="ti ti-settings me-2"></i>Settings
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="tab-content">
                        <TabComponent />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default WaterMeterManagement;
