import React, { useState, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { makeRequest2 } from "../../../../../../utils/makeRequest";
import { toastify } from "../../../../../../utils/toast";
import {
  ADD_WATER_METER_SETTINGS_URL,
  GET_WATER_METER_SETTINGS_URL,
  UPDATE_WATER_METER_SETTINGS_URL,
} from "../../../../../../utils/urls";
import { useFacilityStore } from "../../../../../../app/z-store/store";

const SettingsTab = () => {
  const facilityId = useFacilityStore((state) => state.facilityId);

  const [formData, setFormData] = useState({
    minAmount: 0,
    maxAmount: 10000,
    gracePeriod: 10,
    invoiceDay: 3, 
    enforcePayment: "",
    minimumPaymentAmount: 0,
    tariff: "", 
    tariffAmount: 0,
    tariffAmountSmart: 0,
    fixedTariffAmount: 0,
    meterLoan: 0, // Added meter loan field
    notifications: false,
    dailyNotifications: false,
    weeklyNotifications: false,
    allNotifications: false,
    // New fields for Other Charges
    otherCharges: "",
    sewerageCharge: 0,
    fixedCharge: 0,
    vatPercentage: 0,
  });

  // Load current settings when facilityId changes.
  useEffect(() => {
    if (facilityId) {
      fetchSettings();
    }
  }, [facilityId]);

  const fetchSettings = async () => {
    try {
      const response = await makeRequest2(
        `${GET_WATER_METER_SETTINGS_URL}/${facilityId}`,
        "GET"
      );
      if (response.success && response.data) {
        // Update form data with the fetched settings.
        setFormData(response.data);
      }
    } catch (error) {
      toastify("Error fetching settings", "error");
    }
  };

  const handleSave = async () => {
    try {
      let response;
      // If settings already exist (using an _id field), update; otherwise, add new settings.
      if (formData._id) {
        response = await makeRequest2(
          `${UPDATE_WATER_METER_SETTINGS_URL}/${facilityId}`,
          "PUT",
          formData
        );
      } else {
        response = await makeRequest2(
          `${ADD_WATER_METER_SETTINGS_URL}/${facilityId}`,
          "POST",
          formData
        );
      }
      if (response.success) {
        toastify("Settings saved successfully", "success");
      } else {
        toastify(response.error || response.message || "Failed to save settings", "error");
      }
    } catch (error) {
      toastify("Error saving settings", "error");
    }
  };

  // Handle the "All Notifications" checkbox
  const handleAllNotificationsChange = (checked) => {
    setFormData({
      ...formData,
      allNotifications: checked,
      dailyNotifications: checked,
      weeklyNotifications: checked
    });
  };

  // Handle individual notification type changes
  const handleNotificationTypeChange = (type, checked) => {
    const updatedFormData = { ...formData, [type]: checked };
    
    // If both daily and weekly are checked, set allNotifications to true
    if (type === 'dailyNotifications' || type === 'weeklyNotifications') {
      updatedFormData.allNotifications = 
        (type === 'dailyNotifications' ? checked : formData.dailyNotifications) && 
        (type === 'weeklyNotifications' ? checked : formData.weeklyNotifications);
    }
    
    setFormData(updatedFormData);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title mb-0">Billing Settings</h4>
      </div>
      <div className="card-body">
        {/* Redesigned layout with full-width sections and headers above inputs */}
        <div className="settings-container">
          {/* Billing Amounts Section */}
          <h5 className="section-header mb-3">Billing Amounts</h5>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Minimum Amount (KES)</label>
                <InputNumber
                  className="w-100"
                  value={formData.minAmount}
                  onChange={(e) => setFormData({ ...formData, minAmount: e.value })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Maximum Amount (KES)</label>
                <InputNumber
                  className="w-100"
                  value={formData.maxAmount}
                  onChange={(e) => setFormData({ ...formData, maxAmount: e.value })}
                />
              </div>
            </div>
          </div>

          {/* Billing Schedule Section */}
          <h5 className="section-header mb-3">Billing Schedule</h5>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Grace Period (Days)</label>
                <InputNumber
                  className="w-100"
                  value={formData.gracePeriod}
                  onChange={(e) => setFormData({ ...formData, gracePeriod: e.value })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Invoice Day (Days before month end)</label>
                <InputNumber
                  className="w-100"
                  value={formData.invoiceDay}
                  onChange={(e) => setFormData({ ...formData, invoiceDay: e.value })}
                  min={0}
                  max={30}
                  tooltip="Number of days before the end of the month when invoices are generated"
                  tooltipOptions={{ position: 'top' }}
                />
              </div>
            </div>
          </div>

          {/* Payment Enforcement Section */}
          <h5 className="section-header mb-3">Payment Enforcement</h5>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Enforce Minimum Payment</label>
                <select
                  className="form-control w-100"
                  value={formData.enforcePayment}
                  onChange={(e) => setFormData({ ...formData, enforcePayment: e.target.value })}
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            {formData.enforcePayment === "yes" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label fw-bold">Minimum Payment Amount (KES)</label>
                  <InputNumber
                    className="w-100"
                    value={formData.minimumPaymentAmount}
                    onChange={(e) => setFormData({ ...formData, minimumPaymentAmount: e.value })}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Tariff Section */}
          <h5 className="section-header mb-3">Tariff Settings</h5>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Enable Tariff</label>
                <select
                  className="form-control w-100"
                  value={formData.tariff}
                  onChange={(e) => setFormData({ ...formData, tariff: e.target.value })}
                >
                  <option value="">Select Tariff</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            {formData.tariff === "yes" && (
              <>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label fw-bold">Tariff Amount (Per Unit m³) Analog Meter</label>
                    <InputNumber
                      className="w-100"
                      value={formData.tariffAmount}
                      onChange={(e) => setFormData({ ...formData, tariffAmount: e.value })}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label className="form-label fw-bold">Tariff Amount (Per Unit m³) Smart Meters</label>
                    <InputNumber
                      className="w-100"
                      value={formData.tariffAmountSmart}
                      onChange={(e) => setFormData({ ...formData, tariffAmountSmart: e.value })}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label className="form-label fw-bold">Fixed Amount (Per Meter) (Monthly)</label>
                    <InputNumber
                      className="w-100"
                      value={formData.fixedTariffAmount}
                      onChange={(e) => setFormData({ ...formData, fixedTariffAmount: e.value })}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label className="form-label fw-bold">Meter Loan Amount (KES)</label>
                    <InputNumber
                      className="w-100"
                      value={formData.meterLoan}
                      onChange={(e) => setFormData({ ...formData, meterLoan: e.value })}
                      tooltip="Monthly amount charged for meter financing"
                      tooltipOptions={{ position: 'top' }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {/* New Other Charges Section */}
          <h5 className="section-header mb-3">Other Charges</h5>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label fw-bold">Enable Other Charges</label>
                <select
                  className="form-control w-100"
                  value={formData.otherCharges}
                  onChange={(e) => setFormData({ ...formData, otherCharges: e.target.value })}
                >
                  <option value="">Select Option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            
            {formData.otherCharges === "yes" && (
              <>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label fw-bold">Sewerage Charge (KES)</label>
                    <InputNumber
                      className="w-100"
                      value={formData.sewerageCharge}
                      onChange={(e) => setFormData({ ...formData, sewerageCharge: e.value })}
                    />
                  </div>
                </div>
                
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label className="form-label fw-bold">Fixed Charge (KES)</label>
                    <InputNumber
                      className="w-100"
                      value={formData.fixedCharge}
                      onChange={(e) => setFormData({ ...formData, fixedCharge: e.value })}
                    />
                  </div>
                </div>
                
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label className="form-label fw-bold">VAT (Percentage %)</label>
                    <InputNumber
                      className="w-100"
                      value={formData.vatPercentage}
                      onChange={(e) => setFormData({ ...formData, vatPercentage: e.value })}
                      min={0}
                      max={100}
                      suffix="%"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Notifications Section */}
          <h5 className="section-header mb-3">Notifications</h5>
          <div className="row mb-4">
            <div className="col-12">
              <div className="form-group">
                <div className="d-flex align-items-center">
                  <Checkbox
                    inputId="notifications"
                    checked={formData.notifications}
                    onChange={(e) => setFormData({ ...formData, notifications: e.checked })}
                  />
                  <label htmlFor="notifications" className="ms-2 mb-0 fw-bold">
                    Enable Notifications
                  </label>
                </div>
              </div>
            </div>
            
            {/* Show notification period options when notifications are enabled */}
            {formData.notifications && (
              <div className="col-12 mt-3">
                <div className="notification-periods p-3 border rounded">
                  <h6 className="mb-3">Notification Periods</h6>
                  
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex align-items-center">
                      <Checkbox
                        inputId="allNotifications"
                        checked={formData.allNotifications}
                        onChange={(e) => handleAllNotificationsChange(e.checked)}
                      />
                      <label htmlFor="allNotifications" className="ms-2 mb-0">
                        Select All
                      </label>
                    </div>
                    
                    <div className="d-flex align-items-center">
                      <Checkbox
                        inputId="dailyNotifications"
                        checked={formData.dailyNotifications}
                        onChange={(e) => handleNotificationTypeChange('dailyNotifications', e.checked)}
                      />
                      <label htmlFor="dailyNotifications" className="ms-2 mb-0">
                        Daily Reminders
                      </label>
                    </div>
                    
                    <div className="d-flex align-items-center">
                      <Checkbox
                        inputId="weeklyNotifications"
                        checked={formData.weeklyNotifications}
                        onChange={(e) => handleNotificationTypeChange('weeklyNotifications', e.checked)}
                      />
                      <label htmlFor="weeklyNotifications" className="ms-2 mb-0">
                        Weekly Reminders
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Save Button */}
          <div className="row mt-4">
            <div className="col-12">
              <Button
                label="Save Settings"
                className="btn btn-primary w-100"
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;