import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { makeRequest2 } from '../../../../../../utils/makeRequest';
import { toastify } from '../../../../../../utils/toast';
import { useFacilityStore } from '../../../../../../app/z-store/store';

const AnalogMeterHistoryTab = () => {
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);
  const [searchTerm, setSearchTerm] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');

  const statusOptions = [
    { label: 'All Statuses', value: 'all' },
    { label: 'Pending', value: 'pending' },
    { label: 'Reviewed', value: 'reviewed' },
    { label: 'Billed', value: 'billed' }
  ];

  useEffect(() => {
    if (selectedFacilityId) fetchHistoryData();
  }, [selectedFacilityId]);

  useEffect(() => {
    if (Array.isArray(historyData) && historyData.length > 0) {
      const reviewedMeters = historyData
        .filter(item => item.status === 'reviewed' || item.status === 'billed')
        .map(item => item.meterNumber);
      setSelectedMeters(reviewedMeters);
      const pendingMetersCount = historyData.filter(item => item.status === 'pending').length;
      setSelectAll(pendingMetersCount === 0 && historyData.length > 0);
    } else {
      setSelectedMeters([]);
      setSelectAll(false);
    }
  }, [historyData]);

  const fetchHistoryData = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(
        `/api/app/analog-meters/get-history/${selectedFacilityId}`,
        'GET'
      );
      if (response.success) {
        setHistoryData(response.data?.data || []);
      } else {
        toastify('Failed to fetch analog meter history', 'error');
      }
    } catch (error) {
      toastify('Error fetching analog meter history', 'error');
      setHistoryData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleMeterSelection = (meterId, isChecked) => {
    if (isChecked) {
      setSelectedMeters([...selectedMeters, meterId]);
    } else {
      setSelectedMeters(selectedMeters.filter(id => id !== meterId));
    }
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allMeterIds = filteredHistory.map(item => item.meterNumber);
      setSelectedMeters(allMeterIds);
    } else {
      setSelectedMeters([]);
    }
  };

  const handleCompleteReview = () => {
    const metersToUpdate = filteredHistory
      .filter(item => item.status === 'pending' && selectedMeters.includes(item.meterNumber))
      .map(item => item.meterNumber);
    if (metersToUpdate.length === 0) {
      toastify('No pending meters selected to approve', 'warning');
      return;
    }
    confirmDialog({
      message: `Are you sure you want to mark ${metersToUpdate.length} selected meter readings as reviewed?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          setLoading(true);
          const response = await makeRequest2(
            `/api/app/analog-meters/update-meters/${selectedFacilityId}`,
            'PUT',
            { meterNumbers: metersToUpdate }
          );
          if (response.success) {
            toastify(response.message || 'Billing status updated successfully', 'success');
            fetchHistoryData();
          } else {
            toastify(response.error || 'Failed to update billing status', 'error');
          }
        } catch (error) {
          toastify('Error updating billing status', 'error');
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleStatusFilterChange = (e) => setStatusFilter(e.value);

  const filteredHistory = Array.isArray(historyData)
    ? historyData.filter((item) => {
        const meterNumber = (item.meterNumber || '').toLowerCase();
        const yearMonth = (item.yearMonth || '').toLowerCase();
        const customerName = (item.customerInfo || '').toLowerCase();
        const textFilterPassed =
          meterNumber.includes(searchTerm.toLowerCase()) ||
          yearMonth.includes(searchTerm.toLowerCase()) ||
          customerName.includes(searchTerm.toLowerCase());
        const statusFilterPassed = statusFilter === 'all' || item.status === statusFilter;
        return textFilterPassed && statusFilterPassed;
      })
    : [];

  const pendingSelectedCount = filteredHistory.filter(
    item => item.status === 'pending' && selectedMeters.includes(item.meterNumber)
  ).length;

  const formatReading = (value) =>
    value !== null && value !== undefined ? `${Number(value).toFixed(2)} m³` : '0.00 m³';

  const avgUsageBodyTemplate = (rowData) => {
    const avgUsage = rowData.threeMonthAvgUsage || 0;
    const previousMonthsCount = rowData.previousMonthsCount || 0;
    const tooltip =
      previousMonthsCount > 0
        ? `Based on ${previousMonthsCount} previous month${previousMonthsCount > 1 ? 's' : ''}`
        : 'No previous data available';
    const currentUsage = parseFloat(rowData.totalUsage) || 0;
    const percentDiff = avgUsage > 0 ? ((currentUsage - avgUsage) / avgUsage) * 100 : 0;
    const isHighUsage = percentDiff > 20;
    const isLowUsage = percentDiff < -20;
    return (
      <div className="d-flex align-items-center" title={tooltip}>
        <span className={`me-2 ${isHighUsage ? 'text-danger' : isLowUsage ? 'text-success' : ''}`}>
          {formatReading(avgUsage)}
        </span>
        {previousMonthsCount === 0 && (
          <i className="ti ti-info-circle text-muted" style={{ fontSize: '0.75rem' }} />
        )}
        {previousMonthsCount > 0 && isHighUsage && (
          <i className="ti ti-arrow-up text-danger" style={{ fontSize: '0.75rem' }} />
        )}
        {previousMonthsCount > 0 && isLowUsage && (
          <i className="ti ti-arrow-down text-success" style={{ fontSize: '0.75rem' }} />
        )}
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    const statusColors = {
      pending: 'bg-warning',
      reviewed: 'bg-info',
      billed: 'bg-success'
    };
    return (
      <span className={`badge ${statusColors[rowData.status] || 'bg-secondary'}`}>
        {rowData.status || 'unknown'}
      </span>
    );
  };

  const totalUsageBodyTemplate = (rowData) => {
    const currentUsage = parseFloat(rowData.totalUsage) || 0;
    const avgUsage = parseFloat(rowData.threeMonthAvgUsage) || 0;
    const previousMonthsCount = rowData.previousMonthsCount || 0;
    if (previousMonthsCount === 0 || avgUsage === 0) return formatReading(currentUsage);
    const percentDiff = ((currentUsage - avgUsage) / avgUsage) * 100;
    const diffClass = percentDiff > 20 ? 'text-danger' : percentDiff < -20 ? 'text-success' : '';
    return (
      <div className="d-flex align-items-center">
        <span className={diffClass}>{formatReading(currentUsage)}</span>
        <small className="ms-2 text-muted">
          ({percentDiff > 0 ? '+' : ''}{percentDiff.toFixed(1)}%)
        </small>
      </div>
    );
  };

  const selectionTemplate = (rowData) => {
    const isReviewed = rowData.status === 'reviewed' || rowData.status === 'billed';
    return (
      <div title={isReviewed ? 'Already reviewed/billed' : ''}>
        <Checkbox
          checked={selectedMeters.includes(rowData.meterNumber)}
          onChange={(e) => handleMeterSelection(rowData.meterNumber, e.checked)}
          disabled={isReviewed}
        />
      </div>
    );
  };

  const headerCheckboxTemplate = () => {
    const pendingMeters = filteredHistory.filter(item => item.status === 'pending');
    const allPendingSelected =
      pendingMeters.length > 0 &&
      pendingMeters.every(item => selectedMeters.includes(item.meterNumber));
    return (
      <Checkbox
        checked={allPendingSelected}
        onChange={handleSelectAllChange}
        disabled={pendingMeters.length === 0}
      />
    );
  };

  if (!selectedFacilityId) {
    return (
      <div className="card">
        <div className="card-body text-center">
          Please select a facility to view meter history.
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <ConfirmDialog />
      <div className="card-body">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <div className="d-flex col-md-7 col-xs-12 gap-2 mb-2 mb-md-0">
            <div className="col-md-6">
              <input
                className="form-control"
                placeholder="Search meter number, customer, year-month..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <Dropdown
                value={statusFilter}
                options={statusOptions}
                onChange={handleStatusFilterChange}
                className="w-100"
                placeholder="Filter by Status"
              />
            </div>
          </div>
          <div className="col-md-5 col-xs-12 d-flex justify-content-end align-items-center">
            <span className="me-2">
              Pending meters to approve: {pendingSelectedCount} of{' '}
              {filteredHistory.filter(item => item.status === 'pending').length}
            </span>
            <button
              className="btn btn-primary"
              onClick={handleCompleteReview}
              disabled={pendingSelectedCount === 0}
            >
              Approve Selected Readings
            </button>
          </div>
        </div>
        <div className="dt-responsive table-responsive">
          <DataTable
            value={filteredHistory}
            emptyMessage="No meter readings found."
            sortMode="multiple"
            paginator
            rows={5}
            stripedRows
            loading={loading}
            tableStyle={{ minWidth: '50rem' }}
            stateStorage="session"
            stateKey="analog-meter-history-state"
          >
            <Column header={headerCheckboxTemplate} body={selectionTemplate} style={{ width: '3rem' }} />
            <Column field="meterNumber" header="Meter Number" sortable />
            <Column field="customerInfo" header="Customer" sortable />
            <Column field="unitInfo" header="Unit" sortable />
            <Column
              field="previousReading"
              header="Previous Reading"
              body={(rowData) => formatReading(rowData.previousReading)}
              sortable
            />
            <Column
              field="currentReading"
              header="Current Reading"
              body={(rowData) => formatReading(rowData.currentReading)}
              sortable
            />
            <Column field="totalUsage" header="Total Usage" body={totalUsageBodyTemplate} sortable />
            <Column
              field="threeMonthAvgUsage"
              header="3-Month Average"
              body={avgUsageBodyTemplate}
              sortable
              style={{ backgroundColor: '#f8f9fa' }}
            />
            <Column field="yearMonth" header="Year/Month" sortable />
            <Column field="status" header="Status" body={statusBodyTemplate} sortable />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default AnalogMeterHistoryTab;
