import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload } from 'primereact/fileupload';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { get_handover, update_handover } from '../../../utils/urls';
import { toastify } from '../../../utils/toast';
import Layout from '../component/layout';

const EditHandover = ({ id: propId, location: propLocation, onClose }) => {
  // Use props if provided, otherwise use router hooks
  const routeParams = useParams();
  const routeLocation = useLocation();
  const navigate = useNavigate();

  // Prioritize props over router values
  const id = propId || routeParams.id;
  const location = propLocation || routeLocation;

  const fileUploadRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [handover, setHandover] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  // Form data
  const [formData, setFormData] = useState({
    handoverDate: null,
    status: '',
    keysHandedOver: 0,
    notes: '',
    meterReadings: {
      electricity: { reading: '' },
      water: { reading: '' },
      gas: { reading: '' }
    },
    items: [],
    attachments: []
  });

  // For inventory items editing
  const [editingItem, setEditingItem] = useState(null);
  const [showItemDialog, setShowItemDialog] = useState(false);
  const [tempItem, setTempItem] = useState({
    name: '',
    category: '',
    condition: '',
    quantity: 1,
    notes: ''
  });

  // For security deposit (move-out only)
  const [securityDeposit, setSecurityDeposit] = useState({
    amount: 0,
    deductions: [],
    refundAmount: 0
  });

  // For deduction editing
  const [editingDeduction, setEditingDeduction] = useState(null);
  const [showDeductionDialog, setShowDeductionDialog] = useState(false);
  const [tempDeduction, setTempDeduction] = useState({
    reason: '',
    amount: 0
  });

  // Options for dropdowns
  const statusOptions = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Disputed', value: 'Disputed' },
    { label: 'Resolved', value: 'Resolved' }
  ];

  const conditionOptions = [
    { label: 'Excellent', value: 'Excellent' },
    { label: 'Good', value: 'Good' },
    { label: 'Fair', value: 'Fair' },
    { label: 'Poor', value: 'Poor' },
    { label: 'Damaged', value: 'Damaged' }
  ];

  const categoryOptions = [
    { label: 'Furniture', value: 'Furniture' },
    { label: 'Appliances', value: 'Appliances' },
    { label: 'Electronics', value: 'Electronics' },
    { label: 'Fixtures', value: 'Fixtures' },
    { label: 'Other', value: 'Other' }
  ];

  useEffect(() => {
    const fetchHandover = async () => {
      try {
        setLoading(true);

        // Get handover either from state or fetch from API
        let handoverData = location.state?.handover;

        if (!handoverData && id) {
          const response = await makeRequest2(`${get_handover}/${id}`, "GET");
          if (response?.success && response.data) {
            handoverData = response.data;
          } else {
            toastify("Could not fetch handover details", "error");
            handleClose();
            return;
          }
        }

        if (!handoverData) {
          toastify("No handover data found", "error");
          handleClose();
          return;
        }

        setHandover(handoverData);

        // Initialize form data
        const newFormData = {
          handoverDate: handoverData.handoverDate ? new Date(handoverData.handoverDate) : new Date(),
          status: handoverData.status || 'Draft',
          keysHandedOver: handoverData.keysHandedOver || 0,
          notes: handoverData.notes || '',
          meterReadings: {
            electricity: { reading: handoverData.meterReadings?.electricity?.reading || '' },
            water: { reading: handoverData.meterReadings?.water?.reading || '' },
            gas: { reading: handoverData.meterReadings?.gas?.reading || '' }
          },
          items: handoverData.items || [],
          attachments: handoverData.attachments || []
        };

        setFormData(newFormData);

        // Initialize security deposit for move-out handovers
        if (handoverData.handoverType === 'MoveOut' && handoverData.securityDeposit) {
          setSecurityDeposit({
            amount: handoverData.securityDeposit.amount || 0,
            deductions: handoverData.securityDeposit.deductions || [],
            refundAmount: handoverData.securityDeposit.refundAmount || 0
          });
        }
      } catch (error) {
        console.error('Error fetching handover details:', error);
        toastify(`Error: ${error.message || 'Failed to fetch handover details'}`, 'error');
        handleClose();
      } finally {
        setLoading(false);
      }
    };

    fetchHandover();
  }, [id, location.state]);

  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleMeterReadingChange = (type, value) => {
    setFormData(prev => ({
      ...prev,
      meterReadings: {
        ...prev.meterReadings,
        [type]: { reading: value }
      }
    }));
  };

  const handleSecurityDepositChange = (field, value) => {
    setSecurityDeposit(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const calculateRefundAmount = () => {
    const totalDeductions = securityDeposit.deductions.reduce(
      (sum, deduction) => sum + (parseFloat(deduction.amount) || 0),
      0
    );

    const refundAmount = Math.max(
      (parseFloat(securityDeposit.amount) || 0) - totalDeductions,
      0
    ).toFixed(2);

    setSecurityDeposit(prev => ({
      ...prev,
      refundAmount
    }));
  };

  // Item handling
  const openNewItemDialog = () => {
    setEditingItem(null);
    setTempItem({
      name: '',
      category: '',
      condition: '',
      quantity: 1,
      notes: ''
    });
    setShowItemDialog(true);
  };

  const openEditItemDialog = (item, index) => {
    setEditingItem(index);
    setTempItem({ ...item });
    setShowItemDialog(true);
  };

  const handleItemChange = (field, value) => {
    setTempItem(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const saveItem = () => {
    // Validate item
    if (!tempItem.name || !tempItem.condition) {
      toastify("Name and condition are required for inventory items", "warning");
      return;
    }

    const newItems = [...formData.items];

    if (editingItem !== null) {
      // Update existing item
      newItems[editingItem] = { ...tempItem };
    } else {
      // Add new item
      newItems.push({ ...tempItem });
    }

    setFormData(prev => ({
      ...prev,
      items: newItems
    }));

    setShowItemDialog(false);
  };

  const deleteItem = (index) => {
    const newItems = [...formData.items];
    newItems.splice(index, 1);

    setFormData(prev => ({
      ...prev,
      items: newItems
    }));
  };

  // Deduction handling
  const openNewDeductionDialog = () => {
    setEditingDeduction(null);
    setTempDeduction({
      reason: '',
      amount: 0
    });
    setShowDeductionDialog(true);
  };

  const openEditDeductionDialog = (deduction, index) => {
    setEditingDeduction(index);
    setTempDeduction({ ...deduction });
    setShowDeductionDialog(true);
  };

  const handleDeductionChange = (field, value) => {
    setTempDeduction(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const saveDeduction = () => {
    // Validate deduction
    if (!tempDeduction.reason || !tempDeduction.amount) {
      toastify("Reason and amount are required for deductions", "warning");
      return;
    }

    const newDeductions = [...securityDeposit.deductions];

    if (editingDeduction !== null) {
      // Update existing deduction
      newDeductions[editingDeduction] = { ...tempDeduction };
    } else {
      // Add new deduction
      newDeductions.push({ ...tempDeduction });
    }

    setSecurityDeposit(prev => ({
      ...prev,
      deductions: newDeductions
    }));

    setShowDeductionDialog(false);

    // Recalculate refund amount
    setTimeout(calculateRefundAmount, 0);
  };

  const deleteDeduction = (index) => {
    const newDeductions = [...securityDeposit.deductions];
    newDeductions.splice(index, 1);

    setSecurityDeposit(prev => ({
      ...prev,
      deductions: newDeductions
    }));

    // Recalculate refund amount
    setTimeout(calculateRefundAmount, 0);
  };

  const handleClose = () => {
    setDisplayDialog(false);

    // If onClose prop is provided, call it
    if (onClose) {
      setTimeout(() => onClose(), 300);
    } else {
      // Otherwise, use router navigation
      setTimeout(() => navigate('/facility/handover_management', {
        state: { activeTab: handover?.handoverType === 'MoveIn' ? 'moveIn' : 'moveOut' }
      }), 300);
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate form
      if (!formData.handoverDate) {
        toastify("Handover date is required", "warning");
        return;
      }

      setSubmitting(true);

      // Prepare payload
      const payload = {
        ...formData,
        handoverDate: formData.handoverDate.toISOString(),
        // Add security deposit for move-out handovers
        ...(handover?.handoverType === 'MoveOut' ? { securityDeposit } : {})
      };

      // Send request
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("No facility selected", "error");
        return;
      }

      const response = await makeRequest2(`${update_handover}/${facilityId}/${id}`, "PUT", payload);

      if (response?.success) {
        toastify("Handover updated successfully", "success");
        handleClose();
      } else {
        throw new Error(response?.error || "Failed to update handover");
      }
    } catch (error) {
      console.error('Error updating handover:', error);
      toastify(`Error: ${error.message || 'Failed to update handover'}`, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  const renderBasicInfoTab = () => {
    return (
      <div className="p-fluid">
        <div className="mb-3">
          <div className="field">
            <label htmlFor="handoverDate">Handover Date</label>
            <Calendar
              id="handoverDate"
              value={formData.handoverDate}
              onChange={(e) => handleFormChange('handoverDate', e.value)}
              showIcon
              dateFormat="dd/mm/yy"
              className="w-100"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="field">
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              value={formData.status}
              options={statusOptions}
              onChange={(e) => handleFormChange('status', e.value)}
              placeholder="Select Status"
              className="w-100"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="field">
            <label htmlFor="keysHandedOver">Keys Handed Over</label>
            <InputNumber
              id="keysHandedOver"
              value={formData.keysHandedOver}
              onValueChange={(e) => handleFormChange('keysHandedOver', e.value)}
              min={0}
              max={20}
              className="w-100"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="field">
            <label htmlFor="notes">Notes</label>
            <InputTextarea
              id="notes"
              value={formData.notes}
              onChange={(e) => handleFormChange('notes', e.target.value)}
              rows={5}
              className="w-100"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMeterReadingsTab = () => {
    return (
      <div className="p-fluid">
        <div className="mb-3">
          <div className="field">
            <label htmlFor="electricityReading">Electricity Meter Reading</label>
            <InputNumber
              id="electricityReading"
              value={formData.meterReadings.electricity.reading}
              onValueChange={(e) => handleMeterReadingChange('electricity', e.value)}
              className="w-100"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="field">
            <label htmlFor="waterReading">Water Meter Reading</label>
            <InputNumber
              id="waterReading"
              value={formData.meterReadings.water.reading}
              onValueChange={(e) => handleMeterReadingChange('water', e.value)}
              className="w-100"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="field">
            <label htmlFor="gasReading">Gas Meter Reading</label>
            <InputNumber
              id="gasReading"
              value={formData.meterReadings.gas.reading}
              onValueChange={(e) => handleMeterReadingChange('gas', e.value)}
              className="w-100"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInventoryTab = () => {
    const actionTemplate = (rowData, { rowIndex }) => {
      return (
        <div className="d-flex gap-2">
          <Button
            icon="pi pi-pencil"
            className="p-button-sm p-button-warning"
            onClick={() => openEditItemDialog(rowData, rowIndex)}
          />
          <Button
            icon="pi pi-trash"
            className="p-button-sm p-button-danger"
            onClick={() => deleteItem(rowIndex)}
          />
        </div>
      );
    };

    const conditionTemplate = (rowData) => {
      if (!rowData.condition) return null;

      const conditionClass = {
        'Excellent': 'bg-success',
        'Good': 'bg-success',
        'Fair': 'bg-warning',
        'Poor': 'bg-danger',
        'Damaged': 'bg-danger'
      };

      return (
        <span className={`badge ${conditionClass[rowData.condition] || 'bg-secondary'}`}>
          {rowData.condition}
        </span>
      );
    };

    return (
      <div>
        <div className="mb-3 d-flex justify-content-end">
          <Button
            label="Add Item"
            icon="pi pi-plus"
            onClick={openNewItemDialog}
          />
        </div>

        <DataTable
          value={formData.items}
          stripedRows
          emptyMessage="No inventory items added"
        >
          <Column field="name" header="Item" />
          <Column field="category" header="Category" />
          <Column field="condition" header="Condition" body={conditionTemplate} />
          <Column field="quantity" header="Quantity" />
          <Column field="notes" header="Notes" />
          <Column body={actionTemplate} header="Actions" style={{ width: '8rem' }} />
        </DataTable>

        <Dialog
          header={editingItem !== null ? "Edit Item" : "Add New Item"}
          visible={showItemDialog}
          style={{ width: '500px' }}
          modal
          onHide={() => setShowItemDialog(false)}
          footer={(
            <div>
              <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setShowItemDialog(false)} />
              <Button label="Save" icon="pi pi-check" onClick={saveItem} />
            </div>
          )}
        >
          <div className="p-fluid">
            <div className="mb-3">
              <div className="field">
                <label htmlFor="itemName">Item Name*</label>
                <InputText
                  id="itemName"
                  value={tempItem.name}
                  onChange={(e) => handleItemChange('name', e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="field">
                <label htmlFor="itemCategory">Category</label>
                <Dropdown
                  id="itemCategory"
                  value={tempItem.category}
                  options={categoryOptions}
                  onChange={(e) => handleItemChange('category', e.value)}
                  placeholder="Select Category"
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="field">
                <label htmlFor="itemCondition">Condition*</label>
                <Dropdown
                  id="itemCondition"
                  value={tempItem.condition}
                  options={conditionOptions}
                  onChange={(e) => handleItemChange('condition', e.value)}
                  placeholder="Select Condition"
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="field">
                <label htmlFor="itemQuantity">Quantity</label>
                <InputNumber
                  id="itemQuantity"
                  value={tempItem.quantity}
                  onValueChange={(e) => handleItemChange('quantity', e.value)}
                  min={1}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="field">
                <label htmlFor="itemNotes">Notes</label>
                <InputTextarea
                  id="itemNotes"
                  value={tempItem.notes}
                  onChange={(e) => handleItemChange('notes', e.target.value)}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const renderAttachmentsTab = () => {
    return (
      <div>
        <div className="mb-3">
          <FileUpload
            ref={fileUploadRef}
            name="attachments[]"
            multiple
            accept="image/*,application/pdf"
            maxFileSize={5000000}
            emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
            chooseLabel="Browse"
            uploadLabel="Upload"
            cancelLabel="Cancel"
            className="w-100"
          />
          <small className="text-muted">Maximum file size: 5MB</small>
        </div>

        <h5>Current Attachments</h5>
        {formData.attachments && formData.attachments.length > 0 ? (
          <ul className="list-group">
            {formData.attachments.map((attachment, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                {attachment.name || `Attachment ${index + 1}`}
                <div>
                  {attachment.fileUrl && (
                    <a href={attachment.fileUrl} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary me-2">
                      View
                    </a>
                  )}
                  <Button
                    icon="pi pi-trash"
                    className="p-button-sm p-button-danger"
                    onClick={() => {
                      const newAttachments = [...formData.attachments];
                      newAttachments.splice(index, 1);
                      setFormData(prev => ({ ...prev, attachments: newAttachments }));
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-muted">No attachments uploaded</p>
        )}
      </div>
    );
  };

  const renderSecurityDepositTab = () => {
    if (handover?.handoverType !== 'MoveOut') {
      return <p>Security deposit is only applicable for move-out handovers.</p>;
    }

    const actionTemplate = (rowData, { rowIndex }) => {
      return (
        <div className="d-flex gap-2">
          <Button
            icon="pi pi-pencil"
            className="p-button-sm p-button-warning"
            onClick={() => openEditDeductionDialog(rowData, rowIndex)}
          />
          <Button
            icon="pi pi-trash"
            className="p-button-sm p-button-danger"
            onClick={() => deleteDeduction(rowIndex)}
          />
        </div>
      );
    };

    return (
      <div>
        <div className="mb-4">
          <div className="field">
            <label htmlFor="depositAmount">Original Deposit Amount</label>
            <InputNumber
              id="depositAmount"
              value={securityDeposit.amount}
              onValueChange={(e) => {
                handleSecurityDepositChange('amount', e.value);
                setTimeout(calculateRefundAmount, 0);
              }}
              mode="currency"
              currency="USD"
              locale="en-US"
              className="w-100"
            />
          </div>
        </div>

        <h5>Deductions</h5>
        <div className="mb-3 d-flex justify-content-end">
          <Button
            label="Add Deduction"
            icon="pi pi-plus"
            onClick={openNewDeductionDialog}
          />
        </div>

        <DataTable
          value={securityDeposit.deductions}
          stripedRows
          emptyMessage="No deductions added"
        >
          <Column field="reason" header="Reason" />
          <Column
            field="amount"
            header="Amount"
            body={(rowData) => `${parseFloat(rowData.amount || 0).toFixed(2)}`}
          />
          <Column body={actionTemplate} header="Actions" style={{ width: '8rem' }} />
        </DataTable>

        <div className="mt-4 p-3 bg-light border rounded">
          <div className="row">
            <div className="col-md-4">
              <strong>Original Deposit:</strong>
            </div>
            <div className="col-md-8 text-end">
              ${parseFloat(securityDeposit.amount || 0).toFixed(2)}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Total Deductions:</strong>
            </div>
            <div className="col-md-8 text-end">
              -${securityDeposit.deductions.reduce((sum, deduction) => sum + (parseFloat(deduction.amount) || 0), 0).toFixed(2)}
            </div>
          </div>
          <div className="row fw-bold mt-2 pt-2 border-top">
            <div className="col-md-4">
              <strong>Refund Amount:</strong>
            </div>
            <div className="col-md-8 text-end">
              ${parseFloat(securityDeposit.refundAmount || 0).toFixed(2)}
            </div>
          </div>
        </div>

        <Dialog
          header={editingDeduction !== null ? "Edit Deduction" : "Add New Deduction"}
          visible={showDeductionDialog}
          style={{ width: '500px' }}
          modal
          onHide={() => setShowDeductionDialog(false)}
          footer={(
            <div>
              <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeductionDialog(false)} />
              <Button label="Save" icon="pi pi-check" onClick={saveDeduction} />
            </div>
          )}
        >
          <div className="p-fluid">
            <div className="mb-3">
              <div className="field">
                <label htmlFor="deductionReason">Reason*</label>
                <InputText
                  id="deductionReason"
                  value={tempDeduction.reason}
                  onChange={(e) => handleDeductionChange('reason', e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="field">
                <label htmlFor="deductionAmount">Amount*</label>
                <InputNumber
                  id="deductionAmount"
                  value={tempDeduction.amount}
                  onValueChange={(e) => handleDeductionChange('amount', e.value)}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  min={0}
                  required
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const dialogFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-secondary"
        onClick={handleClose}
        disabled={submitting}
      />
      <Button
        label="Save Changes"
        icon="pi pi-save"
        onClick={handleSubmit}
        loading={submitting}
      />
    </div>
  );

  return (
    <Layout>
      <Dialog
        header={`Edit ${handover?.handoverType === 'MoveIn' ? 'Move-In' : 'Move-Out'} Handover`}
        visible={displayDialog}
        style={{ width: '90vw', maxWidth: '1200px' }}
        onHide={handleClose}
        footer={dialogFooter}
        maximizable
      >
        {loading ? (
          <div className="text-center p-5">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
            <p className="mt-3">Loading handover details...</p>
          </div>
        ) : (
          <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
            <TabPanel header="Basic Info">
              {renderBasicInfoTab()}
            </TabPanel>
            <TabPanel header="Meter Readings">
              {renderMeterReadingsTab()}
            </TabPanel>
            <TabPanel header="Inventory">
              {renderInventoryTab()}
            </TabPanel>
            <TabPanel header="Attachments">
              {renderAttachmentsTab()}
            </TabPanel>
            {handover?.handoverType === 'MoveOut' && (
              <TabPanel header="Security Deposit">
                {renderSecurityDepositTab()}
              </TabPanel>
            )}
          </TabView>
        )}
      </Dialog>
    </Layout>
  );
};

export default EditHandover;