import React from 'react';
import { Dialog } from 'primereact/dialog';
import CreatePenalty from './add_penalty';
import CreateReminder from './add_reminder';

const QuickCreateDialog = ({ 
  visible, 
  type, 
  onHide, 
  onSuccess 
}) => {
  return (
    <Dialog
      header={`Create New ${type === 'penalty' ? 'Penalty' : 'Reminder'}`}
      visible={visible}
      style={{ width: '80vw', maxWidth: '900px' }}
      modal
      onHide={onHide}
      className="p-fluid"
    >
      {type === 'penalty' ? (
        <CreatePenalty 
          isDialog={true}
          onSuccess={() => {
            onSuccess();
            onHide();
          }}
        />
      ) : (
        <CreateReminder 
          isDialog={true}
          onSuccess={() => {
            onSuccess();
            onHide();
          }}
        />
      )}
    </Dialog>
  );
};

export default QuickCreateDialog;