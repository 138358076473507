
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { getVisitLogsForFacilityURL, searchByOtp } from '../../../../utils/urls';
import { filterObjectsByAllProperties } from '../../../../utils/filterSearch';
import formatISODate from '../../../../utils/formatDate';

const WaitingList = () => {

  const [visitLogs, setVisitLogs] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [userRole, setRole] = useState("")


  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem('APPUSER')
      if (currentUserValue) {
        const currentUser = JSON.parse(currentUserValue)
        const role = currentUser.user.role
        setRole(role)
        fetchVisitLogs(role);
      }
    }
    catch (err) {
      console.log(err.message)
    }
  }

  const fetchVisitLogs = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId')
      const response = await makeRequest2(getVisitLogsForFacilityURL + '/' + facilityId, 'GET', {});

      if (response.success) {
        let logs = response.data.data;

        // Filter logs to show only "checked in" status for guards
        logs = logs.filter(log => log.status === 'Visit Confirmation' || log.status === 'Declined' || log.status === 'Checked In');

        setVisitLogs(logs);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error')
    }
  };

  
  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchVisitLogs()
      } else {
        const results = await filterObjectsByAllProperties(visitLogs, value);
        setVisitLogs(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };




  const statusTemplate = (rowData) => {
    const status = rowData.status
    const statusStyles = {
      'Visit Confirmation': {
        color: '#ff9800',         // Orange
        backgroundColor: '#fff3e0' // Light Orange
      },
      'Scheduled': {
        color: '#4caf50',         // Green
        backgroundColor: '#e8f5e9' // Light Green
      },
      'Checked In': {
        color: '#2196f3',         // Blue
        backgroundColor: '#e3f2fd' // Light Blue
      },
      'Checked Out': {
        color: '#9c27b0',         // Purple
        backgroundColor: '#f3e5f5' // Light Purple
      },
      'Cancelled': {
        color: '#f44336',         // Red
        backgroundColor: '#ffebee' // Light Red
      }
    };



    return (
      <span
        style={{
          fontWeight: 'bold',
          padding: '5px 10px',
          borderRadius: '15px',
          ...statusStyles[status] // Apply styles based on status
        }}
      >

        {status === 'Checked In' ? 'Allowed' : status}
      </span>
    );



  };
  const HandeFilter = async (status) => {
    try {
      if (status === 'All') {
        fetchVisitLogs()
      }
      else {
        const results = await visitLogs.filter((x) => { return x.status === status })
        setVisitLogs(results);
      }



    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const nameTemplate = (rowData) => {
    if (!rowData.startTime) {
      return `...`;
    }

    const startTime = new Date(rowData.startTime);
    const currentTime = new Date();
    const diffInMs = currentTime - startTime;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    // If more than 5 minutes, show a label indicating it
    if (diffInMinutes > 3) {
      return <Link to={'/facility/visitor_access_management/view_waiting_list/' + rowData._id}>
        {rowData.visitorName}
      </Link>
    }

    // Otherwise, display the exact time difference
    return rowData.visitorName

  }
  const timerTemplate = (rowData) => {
    if (!rowData.startTime) {
      return `...`;
    }

    const startTime = new Date(rowData.startTime);
    const currentTime = new Date();
    const diffInMs = currentTime - startTime;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    // If more than 3 minutes, show a label indicating it
    if (diffInMinutes > 3) {
      if(rowData.status === 'Visit Confirmation'){
        return <span style={{ color: 'red', fontWeight: 'bold' }}>More than 3 minutes</span>;
      }

    }



    // Otherwise, display the exact time difference
    if (rowData.status === 'Visit Confirmation') {
      return `${diffInMinutes} min(s) ago`;
    }
    else {
      return `.....`
    }

  };
  useEffect(() => {
    fetchVisitLogs()
    getCurrentUser()
    const interval = setInterval(() => {
      fetchVisitLogs();
    }, 30000); // 30 seconds

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [])


  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Waiting List</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="card">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchTerm}
                                        onChange={(e) => { handleInputSearch(e.target.value) }}
                                    />
                                </div> */}
                <div className="col-3">
                  <input
                    className="form-control"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={(e) => { handleInputSearch(e.target.value) }}
                  />
                </div>
                <div className="col-3" >
                  <div className="btn-group-dropdown" >
                    <button
                      className="btn btn-outline-default dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ti ti-filter"></i>
                    </button>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('All') }}>
                        All
                      </Link>
                      <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Visit Confirmation') }}>
                        Visit Confirmation
                      </Link>
                      <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Declined') }}>
                        Declined
                      </Link>
                      <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Checked In') }}>
                        Checked In
                      </Link>


                    </div>
                  </div>
                </div>
                <div className="col-4">


                </div>
                <div className="col-2">
                  <button className="btn btn-primary" onClick={()=>{
                    fetchVisitLogs()
                  }}>Refresh List</button>

                </div>

                <div className="col-sm-12 mt-4">
                  <DataTable
                    value={visitLogs}

                    emptyMessage="No Waiting List Found."
                    sortMode="multiple"
                    paginator
                    rows={10}
                    stripedRows
                    tableStyle={{ minWidth: '50rem' }}
                  >

                    <Column header="Visitor Name" body={nameTemplate} />
                    <Column field="houseNumber" header="House No" />
                    <Column field="status" header="Status" body={statusTemplate} sortable />
                    <Column header="Timer" body={timerTemplate} />

                  </DataTable>

                </div>


              </div>
            </div>

          </div>


        </div>
      </div>

    </Layout>
  )
}


export default WaitingList