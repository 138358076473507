import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { filterObjectsByAllProperties } from '../../../../utils/filterSearch';
import ConfirmDialog from '../../component/confirmDialog';
import { getFacilityGuards, editFacilityGuard, getEntriesAndExitsForFacility, deleteFacilityGuard, disableFacilityGuard } from '../../../../utils/urls';

function GuardRegistration() {
  const navigate = useNavigate()

  const [guard, setGuard] = useState('')
  const [editDialogVisible, setEditDialogVisible] = useState(false);

  const [guardId, setGuardId] = useState(null);


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [entryPoint, setEntryPoint] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState('')

  const [entryPoints, setEntryPoints] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [selectedGuardId, setSelectedGuardId] = useState(null);

  const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status

  const fetchEntriesAndExitsForFacility = async () => {
    try {
      const value = await getItem('selectedFacilityId')
      const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

      if (response.success) {
        setEntryPoints(response.data);
      } else {
        throw new Error('Failed to fetch entries and exits')
      }
    } catch (error) {
      console.log(error.message)
      toastify(error.message, 'error');
    }
  };

  const fetchGuardsForFacility = async () => {
    try {
      const value = await getItem('selectedFacilityId');
      const response = await makeRequest2(`${getFacilityGuards}/${value}`, 'GET', {});

      if (response.success) {
        // Add full name to each guard
        const guardsWithFullName = response.data.map((guard) => ({
          ...guard,
          fullName: `${guard.firstName} ${guard.lastName}`,
        }));

        // Sort guards by createdAt in descending order (newest first)
        const sortedGuards = guardsWithFullName.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setGuard(sortedGuards);
      } else {
        throw new Error('Failed to fetch guards');
      }
    } catch (error) {
      console.log(error.message);
      toastify(error.message, 'error');
    }
  };



  const actionTemplate = (rowData) => {
    const status = rowData.status; // Check if the row is disabled

    return (
      <div className="actions">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            setSelectedGuardId(rowData._id);
            setConfirmAction('delete');  // Set action to delete
            setConfirmDialogVisible(true);
          }}
          tooltip="Delete"
          disabled={status === 'On Duty' ? true : false}
        />
        {/* <Button
          icon={`ti ${status !== 'On Duty' ? true : false ? 'ti-lock-open' : 'ti-lock'}`}
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setSelectedGuardId(rowData._id);
            setConfirmAction(status !== 'On Duty' ? true : false ? 'enable' : 'disable');  // Set action to enable/disable
            setSelectedDisableStatus(status !== 'On Duty' ? true : false);
            setConfirmDialogVisible(true);
          }}
          tooltip="Disable"
        /> */}
      </div>
    );
  };
  const phoneNumberTemplate = (rowData) => {
    const phoneNumber = rowData.phoneNumber;
    return '+254' + phoneNumber
  }


  const handleEdit = (rowData) => {
    setGuardId(rowData._id);
    setFirstName(rowData.firstName);
    setLastName(rowData.lastName);
    setPhoneNumber(rowData.phoneNumber);
    setEntryPoint(rowData.entryPoint);
    setStartTime(rowData.startTime);
    setEndTime(rowData.endTime);
    setStatus(rowData.status);
    setEditDialogVisible(true);  // Show the edit dialog
  };

  const HandleEditSubmit = async () => {
    try {
      const updatedData = {
        firstName,
        lastName,
        phoneNumber,
        startTime,
        endTime,
        status
      };

      const response = await makeRequest2(`${editFacilityGuard}/${guardId}`, 'POST', updatedData);

      if (response.success) {
        toastify('Guard updated successfully', 'success');
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEntryPoint('');
        setStartTime('');
        setEndTime('');
        setEditDialogVisible(false);
        fetchGuardsForFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, 'error');
    }
  };

  const handleDelete = async (guardId) => {
    try {
      const response = await makeRequest2(deleteFacilityGuard + '/' + guardId, 'DELETE');

      if (response.success) {
        toastify(response.data, 'success');
        fetchGuardsForFacility();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };

  const handleDisable = async (guardId, currentStatus) => {
    const action = currentStatus ? 'enable' : 'disable';

    try {
      const newDisabledStatus = !currentStatus;

      const response = await makeRequest2(
        `${disableFacilityGuard}/${guardId}`,
        'PATCH',
        { disabled: newDisabledStatus }
      );

      if (response.success) {
        toastify(response.data, 'success');
        fetchGuardsForFacility();  // Refresh the data
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };

  const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);

    if (confirmAction === 'delete') {
      // Handle delete logic here
      await handleDelete(selectedGuardId);
    } else if (confirmAction === 'enable' || confirmAction === 'disable') {
      // Handle enable/disable logic here
      await handleDisable(selectedGuardId, selectedDisableStatus);
    }
  };

  const statusTemplate = (rowData) => {
    const status = rowData.status
    const statusStyles = {
      'Off Duty': {
        color: '#ff9800',         // Orange
        backgroundColor: '#fff3e0' // Light Orange
      },
      'On Duty': {
        color: '#4caf50',         // Green
        backgroundColor: '#e8f5e9' // Light Green
      },
      'On Break': {
        color: '#2196f3',         // Blue
        backgroundColor: '#e3f2fd' // Light Blue
      },
      'Absent': {
        color: '#f44336',         // Red
        backgroundColor: '#ffebee' // Light Red
      },
      'Sick Leave': {
        color: '#9c27b0',         // Purple
        backgroundColor: '#f3e5f5' // Light Purple
      },
      'Suspended': {
        color: '#795548',         // Brown
        backgroundColor: '#efebe9' // Light Brown
      }
    };


    return (
      <span
        style={{
          fontWeight: 'bold',
          padding: '5px 10px',
          borderRadius: '15px',
          ...statusStyles[status] // Apply styles based on status
        }}
      >
        {status}
      </span>
    );



  };


  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value);
      if (value === '') {
        fetchGuardsForFacility()
      } else {
        const results = await filterObjectsByAllProperties(guard, value);
        setGuard(results);
      }
    } catch (error) {
      toastify(error.message, 'error');
    }
  };


  useEffect(() => {

    fetchGuardsForFacility();
    fetchEntriesAndExitsForFacility();

  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor & Access Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Guard Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Guard Management</h5>
                </div>
                <div className='card-body'>
                  <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                      <div className="card">
                        <div className="card-body position-relative">
                          <div className="row">
                            <div className="col-md-3 col-xs-12 mb-3">
                              <input
                                className="form-control"
                                placeholder="Search here"
                                value={searchTerm}
                                onChange={(e) => { handleInputSearch(e.target.value) }}
                              />
                            </div>

                            <div className="col-md-9 col-xs-12 mb-3">
                              <div style={{ float: 'right' }}>
                                <button className='btn btn-primary' onClick={() => navigate('/facility/visitor_access_management/guard_management/register_guard')}>Add Guard</button>
                                &nbsp;
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="dt-responsive table-responsive">
                                <DataTable
                                  value={guard}
                                  emptyMessage="No Guards Found."
                                  sortMode="multiple"
                                  paginator
                                  rows={5}
                                  stripedRows
                                  tableStyle={{ minWidth: '50rem' }}
                                >
                                  <Column field="fullName" header="Full Name" />
                                  <Column header="Phone Number" body={phoneNumberTemplate} />
                                  <Column field="email" header="Email" />
                                  <Column field="startTime" header="Start Time" />
                                  <Column field="endTime" header="End Time" />
                                  <Column body={statusTemplate} header="Status" />
                                  <Column body={actionTemplate} header="Actions" />
                                </DataTable>
                                {/* Confirm dialog */}
                                <ConfirmDialog
                                  visible={isConfirmDialogVisible}
                                  onHide={() => setConfirmDialogVisible(false)}
                                  message={`Are you sure you want to ${confirmAction} this guard?`}
                                  onConfirm={handleConfirmAction}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog header={`Edit Guard Information`} visible={editDialogVisible}  onHide={() => { if (!editDialogVisible) return; setEditDialogVisible(false); }}>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div >
              <label className="form-label">First Name<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="mb-3 form-control"
                value={firstName}
                onChange={(e) => {
                  const value = e.target.value;
                  setFirstName(value)
                }}
              />
            </div>

            <div >
              <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="mb-3 form-control"
                value={lastName}
                onChange={(e) => {
                  const value = e.target.value;
                  setLastName(value)
                }}
              />
            </div>

            <div >
              <label className="form-label">Mobile Number<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                className="mb-3 form-control"
                placeholder="e.g. +254 712 345 678"
                value={phoneNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  setPhoneNumber(value)
                }}
              />
            </div>


            <div >
              <label className="form-label">Start Time<span style={{ color: 'red' }}>*</span></label>
              <input
                type="time"
                className="mb-3 form-control"
                value={startTime}
                onChange={(e) => {
                  const value = e.target.value;
                  setStartTime(value)
                }}
              />
            </div>

            <div >
              <label className="form-label">End Time<span style={{ color: 'red' }}>*</span></label>
              <input
                type="time"
                className="mb-3 form-control"
                value={endTime}
                onChange={(e) => {
                  const value = e.target.value;
                  setEndTime(value)
                }}
              />
            </div>
            <div className="mt-2">
              <label>Status</label>
              <select className="form-control" type="text" value={status} onChange={(e) => {
                const value = e.target.value;
                setStatus(value)
              }}>
                <option value="">-- Select --</option>
                <option value='On Duty'>On Duty</option>
                <option value='Off Duty'>Off Duty</option>
                <option value='On Break'>On Break</option>
                <option value='Absent'>Absent</option>
                <option value='Sick Leave'>Sick Leave</option>
                <option value='Suspended'>Suspended</option>
              </select>

            </div>
            <div>

            </div>

            <div className='mt-4' style={{ float: 'right' }}>
              <button className="btn btn-primary" onClick={HandleEditSubmit}>Submit</button>
            </div>

          </div>

        </div>

      </Dialog>

    </Layout>
  )
}


export default GuardRegistration