import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

export const frequencyOptions = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Bimonthly', value: 'bimonthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Half Annually', value: 'half_annually' },
    { label: 'Annually', value: 'annually' }
];

export const commissionTypeOptions = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' }
];

export const typeOptions = [
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'Unscheduled', value: 'unscheduled' },
];

export const statusOptions = [
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Cancelled', value: 'Cancelled' }
];

const serviceProviderOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' }
];


export const statusTemplate = (rowData) => {
    const statusStyles = {
        'Pending': { color: '#ffc107', backgroundColor: '#fff8e1' },
        'In Progress': { color: '#17a2b8', backgroundColor: '#e0f4f7' },
        'Completed': { color: '#28a745', backgroundColor: '#e9fbe9' },
        'Cancelled': { color: '#dc3545', backgroundColor: '#fde9e9' }
    };

    const style = statusStyles[rowData.status] || {
        color: '#6c757d',
        backgroundColor: '#f1f1f1'
    };

    return (
        <span
            style={{
                ...style,
                padding: '0.5em 1em',
                borderRadius: '12px',
                fontWeight: 'bold',
                fontSize: '0.9rem',
                display: 'inline-block'
            }}
        >
            {rowData.status}
        </span>
    );
};

export const WorkOrderForm = ({
    form,
    setForm,
    handleInputChange,
    onSubmit,
    setShowModal,
    vendors,
    employees,
    handleVendorSelect,
    serviceProviderType,
    setServiceProviderType,
    quotedPrice,
    setQuotedPrice,
    finalPrice,
    setFinalPrice,
    fetchEmployees
}) => {
    useEffect(() => {
        if (serviceProviderType === 'internal') {
            fetchEmployees();
        }
    }, [serviceProviderType]);

    const handleProviderChange = (e) => {
        setServiceProviderType(e.target.value);
        // Reset form values when changing provider type
        setForm(prev => ({
            ...prev,
            vendorId: '',
            pricing: 0
        }));
        setQuotedPrice(0);
    };

    return (
        <div className="dialog-body">
            <div className="mb-3">
                <label className="form-label">Service Provider Type</label>
                <select
                    className="form-select w-100"
                    value={serviceProviderType}
                    onChange={handleProviderChange}
                    required
                >
                    <option value="">Select provider type</option>
                    {serviceProviderOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            {serviceProviderType === 'external' && (
                <div className="mb-3">
                    <label className="form-label">Vendor</label>
                    <select
                        className="form-select w-100"
                        value={form.vendorId}
                        onChange={(e) => handleVendorSelect(e.target.value)}
                        required
                    >
                        <option value="">Select a vendor</option>
                        {vendors.map((vendor) => (
                            <option key={vendor.value} value={vendor.value}>
                                {vendor.label}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {serviceProviderType === 'internal' && (
                <div className="mb-3">
                    <label className="form-label">Employee</label>
                    <select
                        className="form-select w-100"
                        value={form.employeeId}
                        onChange={(e) => setForm(prev => ({ ...prev, employeeId: e.target.value }))}
                        required
                    >
                        <option value="">Select an employee</option>
                        {employees?.map((employee) => (
                            <option key={employee.value} value={employee.value}>
                                {employee.label}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                    className="form-control w-100"
                    name="description"
                    value={form.description}
                    onChange={handleInputChange}
                    placeholder="Enter description"
                    required
                    rows={4}
                />
            </div>

            <div className="mb-3">
                <label className="form-label">Quoted Price</label>
                <InputNumber
                    className="w-100"
                    value={quotedPrice}
                    onValueChange={(e) => setQuotedPrice(e.value)}
                    mode="currency"
                    currency="KSH"
                    placeholder="Enter quoted price"
                    min={0}
                    required
                    disabled={serviceProviderType === 'external' && form.vendorId} // Disabled if external vendor is selected
                />
            </div>

            <div className="mb-3">
                <label className="form-label">Final Price</label>
                <InputNumber
                    className="w-100"
                    value={finalPrice}
                    onValueChange={(e) => setFinalPrice(e.value)}
                    mode="currency"
                    currency="KSH"
                    placeholder="Enter final price"
                    min={0}
                    required
                />
            </div>

            <div className="d-flex justify-content-end gap-2">
                <Button
                    label="Cancel"
                    className="btn btn-outline-primary"
                    onClick={() => setShowModal(false)}
                />
                <Button
                    label="Submit Work Order"
                    icon="pi pi-check"
                    className="btn btn-primary"
                    onClick={onSubmit}
                    disabled={
                        !serviceProviderType ||
                        (serviceProviderType === 'external' && !form.vendorId) ||
                        (serviceProviderType === 'internal' && !form.employeeId) ||
                        !form.description ||
                        !quotedPrice ||
                        !finalPrice
                    }
                />
            </div>
        </div>
    );
};

export const CommissionForm = ({ form, setForm, onSubmit, setShowModal }) => (
    <div className="dialog-body">
        <div className="mb-3">
            <label className="form-label">Commission Type</label>
            <select
                className="form-select w-100"
                value={form.type || ""}
                onChange={(e) =>
                    setForm((prev) => ({
                        ...prev,
                        type: e.target.value,
                    }))
                }
                required
            >
                <option value="" disabled hidden>
                    Select Commission Type
                </option>
                {commissionTypeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>

        {form.type === 'fixed' ? (
            <div className="mb-3">
                <label className="form-label">Amount</label>
                <InputNumber
                    className="w-100"
                    value={form.amount}
                    onValueChange={(e) => setForm(prev => ({
                        ...prev,
                        amount: e.value
                    }))}
                    mode="currency"
                    currency="KSH"
                    placeholder="Enter amount"
                    min={0}
                    required
                />
            </div>
        ) : form.type === 'percentage' && (
            <>
                <div className="mb-3">
                    <label className="form-label">Percentage Amount</label>
                    <InputNumber
                        className="w-100"
                        value={form.percentageAmount}
                        onValueChange={(e) => setForm(prev => ({
                            ...prev,
                            percentageAmount: e.value
                        }))}
                        suffix="%"
                        min={0}
                        max={100}
                        placeholder="Enter percentage"
                        required
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Rent Amount</label>
                    <InputNumber
                        className="w-100"
                        value={form.rentAmount}
                        onValueChange={(e) => setForm(prev => ({
                            ...prev,
                            rentAmount: e.value
                        }))}
                        placeholder="Rent per month"
                        min={0}
                        required
                    />
                </div>
            </>
        )}

        <div className="d-flex justify-content-end gap-2">
            <Button
                label="Cancel"
                className="btn btn-outline-primary"
                onClick={() => setShowModal(false)}
            />
            <Button
                label="Submit"
                icon="pi pi-check"
                className="btn btn-primary"
                onClick={onSubmit}
            />
        </div>
    </div>
);

export const UnitManagementForm = ({ form, setForm, onSubmit, setShowModal }) => (
    <div className="dialog-body">
        <div className="mb-3">
            <label className="form-label">Percentage</label>
            <InputNumber
                className="w-100"
                value={form.percentage}
                onValueChange={(e) => setForm(prev => ({
                    ...prev,
                    percentage: e.value
                }))}
                suffix="%"
                min={0}
                max={100}
                placeholder="Enter percentage"
                required
            />
        </div>

        <div className="mb-3">
            <label className="form-label">Rent Amount</label>
            <InputNumber
                className="w-100"
                value={form.rentAmount}
                onValueChange={(e) => setForm(prev => ({
                    ...prev,
                    rentAmount: e.value
                }))}
                placeholder="Rent per month"
                min={0}
                required
            />
        </div>

        <div className="mb-3">
            <label className="form-label">Frequency</label>
            <select
                className="form-select w-100"
                value={form.frequency || ""}
                onChange={(e) =>
                    setForm((prev) => ({
                        ...prev,
                        frequency: e.target.value,
                    }))
                }
                required
            >
                <option value="" disabled hidden>
                    Select Frequency
                </option>
                {frequencyOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>

        <div className="d-flex justify-content-end gap-2">
            <Button
                label="Cancel"
                className="btn btn-outline-primary"
                onClick={() => setShowModal(false)}
            />
            <Button
                label="Submit"
                icon="pi pi-check"
                className="btn btn-primary"
                onClick={onSubmit}
            />
        </div>
    </div>
);

const ServiceForms = {
    CommissionForm,
    UnitManagementForm,
    WorkOrderForm,
    statusTemplate,
    frequencyOptions,
    commissionTypeOptions,
    statusOptions,
    typeOptions
};

export default ServiceForms;